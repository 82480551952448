import axios from 'axios';

export const request1 = (options) => {
  return new Promise((resolve, reject) => {
    // create an axios instance
    const service = axios.create({
      baseURL: process.env.VUE_APP_BASE_API1, // api 的 base_url
      timeout: 80000, // 请求超时时间
    });
    service.interceptors.request.use(
      (config) => {
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
    service.interceptors.response.use(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    // 请求处理
    service(options)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default request1;
