<template>
   <div class="row dimensions" style="margin:0 -30px;">
    <div class="col-md-9" style="padding:0">
      <card class="card-map">
        <div id="map"></div>
        <menu-map :map="map" :mapDefault="mapdefaultSetting" showDisaster="false" showWeather="true" area="2"></menu-map>
        <div class="radio-group">
          <div v-for="(item,idx) in types" @click="changeType(item.id)">
            <label :for='item.id'><input type="radio" :id="item.id" :value="item.id" v-model="curType" > {{item.value}}</label>
          </div>
        </div>
        <div class="map_data_msg" v-if="mapinfo">
          <div class="map_data_legend">{{mapinfo.title}}</div>
          <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
            <div
              class="item"
              v-for="(item,index) in mapinfo.names"
              :key="index"
            >
              <span :style="'background: '+mapinfo.colors[index]+''"></span>
              {{item}}
            </div>
          </div>
        </div>
        <div class="show_list">
          <a class="up_show" v-on:click="showList">
            <i
              class="now-ui-icons"
              :class="[ show_list ? 'arrows-1_minimal-down':'arrows-1_minimal-up']"
            ></i>
          </a>
          <a class="download ml-auto"  :href="downloadUrl">
            <i class="now-ui-icons arrows-1_cloud-download-93"></i>
          </a>
          <span>
            数据列表
            <!-- <i>{{total}}</i> -->
          </span>
        </div>
        <div
          class="show_list_record show_fire_list_record"
          v-show="show_list"
          ref="show_list_record"
        >
          <el-table :data="data" max-height="600" :span-method="objectSpanMethod">
            <el-table-column v-for="(item,index) in listheader" min-width="150" :label="item" :key="index" >
              <template slot-scope="scope">
               <span>{{scope.row[index]}}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </card>
    </div>
    <div class="col-md-3" id="customStyle">
      <el-collapse v-model="activeNames">
        <el-collapse-item title="信息图表" name="2">
          <div class="col-md-12">
            <div class="chart-area" id="box-chart" style="height:300px"></div>
          </div>
          <div class="col-md-12">
            <div class="chart-area" id="box-chart2" style="height:300px"></div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="key-infos">
     
    </div>
    <div class="radar-desc" v-show="showDesc" ref="radarDescPop">
      
    </div>
  </div>
</template>
<script>
import mapboxgl from "mapbox-gl";
import MenuMap from "src/components/menumapFire3";
import geobuf from "geobuf";
import Pbf from "pbf";
import MiniMap from "src/mapbox/mapboxgl-minimap.js";
import { TabPane, Tabs, Switch } from "src/components";
import { Radio } from 'src/components/index';
import { Input, Button, Select, Option, Cascader, Collapse, CollapseItem, Table, TableColumn } from "element-ui";
import { consts } from "src/util/consts.js";
import {
  getDimensionsChart1 as getChart1,
  getDimensionsChart2 as getChart2,
  getDimensionsList as getList1,
  //getBasicFullList as getList1,
  getFireStreetGeo as getGeo1,
  getFireBasicStreetGeo as getStreetGeo,
  getDimensionsInfo as getInfo,
  getDimensionsShowMap as getMapInfo,
  getDimensionsMapData as getMapData,
  getDimensionsRadarData as getMapRadarData,
} from "src/api/fire.js";
import {getHideawaylGeo} from 'src/api/cityinfo.js';
import { buildBarOption,buildBarStackYValueOption, buildPieOption, buildBarSideOption } from "src/util/chart.js";
import { storeMapOptions,getMapOptions,parseJsonToString } from "src/util/common.js";
import { Pagination as NPagination } from "src/components";

import * as THREE from "three";

export default {
  components: {
    MenuMap,
    TabPane,
    Tabs,
    [Cascader.name]: Cascader,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    NPagination,
    [Switch.name]: Switch,
    Radio
  },
  data() {
    return {
      mid: 0, //模块id
      types: [], //左上角切换radio
      curType: 0,
      activeNames: ['2','3'],
      activeName: "first",
      selects: {
        town_id: 1,
        street_id: 0,
        ids: {},
        filter_street_id: 0,//地图筛选的street_id
      },
      props: { multiple: true },
      options1: [],
      options2: [],
      keyword: "",
      total: 0,
      data: [],
      page: 1,
      map: {},
      city_id: localStorage.getItem("city_id") || 1,
      show_list: false,
      show_list_height: 0,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 20
      },
      last_updated: consts.last_updated,
      mapdefaultSetting: {},
      statistics:{
      },
      downloadUrl: '',
      options: [
        //{name: '用电量',selected:[],data: []},
      ],
      mapinfo: null,
      listheader: [],
      values: null,
      tempy: [],
      tempx: [],
      curStreetDay: [],//地图当前街道图标显示天数
      showDesc:false,
    };
  },

  mounted() {
    let _this = this;
    //localStorage.setItem('curStroke', 'street');
    //this.fillStreets(this.city_id);
    this.mid = this.$route.params.dmid || 1;
    this.curType = this.mid;
    this.setTipContent()
    this.showChart(this.city_id);
    
    this.initMap(this.city_id);
    this.map.on('style.load', function(){
      getMapInfo(_this.buildParams()).then(res => {
        _this.mapinfo = res.data;
        _this.fillList1() //初始化list，不随筛选改变
        _this.reloadData()
      });
      
    })
    
    
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    // street (){
    //   return this.selects.street
    // }
  },
  watch:{
    
    "selects.street"(val) {
      //街道切换选社区
      this.fillCommunities(this.city_id, val);
    },
    // "mid"(val){
    //   if(val == 1) {
    //     if(localStorage.getItem('curStroke') == 'street') {
    //       //特殊页面
    //       this.$router.push({path: '/fire/building/history/alarm'})
    //     }
    //     if(localStorage.getItem('curStroke') == 'community'){
    //       //量产
    //       this.$router.push({path: '/fire/building/tp?mid='+val})
    //     }
    //   } else {
    //         this.$router.push({path: '/fire/building/tp?mid='+val})
    //   }

      
    // },
  },
  methods: {
    // 初始化
    initMap(city_id) {
      this.mapdefaultSetting = {
        center: consts.mapCenter[this.city_id-1],
        zoom: consts.mapDefaultZoom,
        pitch: 0,
        city_id: this.city_id
      }
      mapboxgl.accessToken = consts.mapboxAccessToken;
      var origin = [-95.97299, 36.15031, 0];
      let map_options = getMapOptions();
      //console.log(map_options);
      let zoom = map_options?map_options.zoom:12;
      let center = map_options?[map_options.lng,map_options.lat]:consts.mapCenter[city_id - 1];
      //console.log(center);
      var map = new mapboxgl.Map({
        container: "map",
        style: consts.mapBoxStyle,
        //center: [-96, 37.8],
        center: center,
        zoom: zoom,
        pitch: 0,
        bearing: 0,
        attributionControl:false
      });
      this.map = map;
      //定位图标
      map.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl
        })
      );
      this.map.addControl(new mapboxgl.GeolocateControl({
          positionOptions: {
              enableHighAccuracy: true
          },
          trackUserLocation: true
      }));  
      //控制图层
      var nav = new mapboxgl.NavigationControl();
      this.map.addControl(nav, 'top-right');
      //全屏按钮
      this.map.addControl(new mapboxgl.FullscreenControl({
        container: document.querySelector('body')
        })
      );
      //小地图
      //https://github.com/brendanmatkin/mapboxgl-minimap
      map.on("style.load", function () {
        // Possible position values are 'bottom-left', 'bottom-right', 'top-left', 'top-right'
        map.addControl(new MiniMap({
          id: "mapboxgl-minimap",
          width: "200px",
          height: "150px",
          //style: "mapbox://styles/mapbox/streets-v10",
          style: consts.mapBoxStyle,
          fillColor: "#f58220",
          zoomAdjust: null
          }),
          'bottom-right');
      });
      
      //记录地图设置
      map.on('move', function() {
          storeMapOptions({
            lng: map.getCenter().lng,
            lat: map.getCenter().lat,
            zoom: map.getZoom()
          });
      });
       
    },
    showList() {
      this.show_list = !this.show_list;
    },
    donwloadList() {},
    showChart(city_id) {
      var self = this;
      var _this = this;
      var container = document.querySelector('.el-collapse');
      var box = document.getElementById("box-chart");
      var myChart = this.$echarts.init(box);
      box.style.width = container.offsetWidth + "px";
      getChart1({
          "city_id": this.city_id,
          "town_id": 1,
          "module_id": this.mid
      }).then(res => {
        var chart_data = res.data.data;
        var chart_name = res.data.title;
        var colors = res.data.colors

        chart_data.map((item,idx) => {
          item.itemStyle = {color: colors[idx]}
        })
        var option = buildPieOption(chart_name, chart_data);
        option.tooltip = {
          trigger: 'item',
          formatter: "{a} <br/>{b} : {c} ({d}%)",
          position:function(p){ //其中p为当前鼠标的位置
            return [p[0] - 40, p[1] + 10];
          },
          extraCssText:'width:160px;white-space:inherit;'
        };
        myChart.setOption(option);
       
      });

      var box2 = document.getElementById("box-chart2");
      var myChart2 = this.$echarts.init(box2);
      box2.style.width = container.offsetWidth + "px";

      getChart2({
          "city_id": this.city_id,
          "town_id": 1,
          "module_id": this.mid
      }).then(res => {
        var chart_data = res.data.data;
        var chart_name = res.data.title;

        chart_data.forEach(item=>{
          item.value = item.value
        })
        var option = buildBarOption(chart_name, chart_data, "name");
        option.series[0].itemStyle.normal.label = {show: true, position: 'top', textStyle: {color: '#ffffff'}};
        option.tooltip.position = function(p){ //其中p为当前鼠标的位置
            return [p[0] - 40, p[1] + 10];
          }
         
          option.series[0].barWidth = 25;
        myChart2.setOption(option);
       
      });
      getMapRadarData({
          "city_id": this.city_id,
          "town_id": 1,
          "module_id": this.mid
        }).then(res => {
          this.mapchartData = res.data.radar_data;
          this.mapchartDimensionData = res.data.dimension_data;
        })



      myChart.resize(); 
      myChart2.resize(); 

      window.onresize = function() {
        document.querySelectorAll('.chart-area').forEach((item,idx)=>{
          item.style.width = document.querySelector('.el-collapse').offsetWidth+'px';
        })
        myChart.resize(); 
        myChart2.resize(); 
      }
    },
    handleChange1(value){
      //右侧下拉change1
      this.selects.filter_street_id = 0; //地图筛选的街道先清空
      this.selects.town_id = 1
      this.selects.street_id = value[2] || 0
      this.reloadData();
      
    },
    handleChange2(value){
      //右侧下拉change2
      let _this = this;
      this.selects.filter_street_id = 0; //地图筛选的街道先清空
      this.selects.ids = {}
      value.forEach((item,idx)=>{
        if(typeof _this.selects.ids[item[2]] == 'undefined') {
          _this.selects.ids[item[2]] = '';
        }
        _this.selects.ids[item[2]] += item[3]+'-'
      })
      this.reloadData();
    },
    
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      let returnobj = null;
      let returnobj2 = null;
      let _this = this;
      if (columnIndex == 0) {
        this.col1IdxArr.forEach((item,idx)=>{
          if (rowIndex == item) {
            returnobj =  {
              rowspan: _this.col1Arr[idx],
              colspan: 1
            };
            
          } else {
            returnobj =  {
              rowspan: 0,
              colspan: 0
            };
          }
        })
        return returnobj;

      }
      if (columnIndex == 1) {
        let hasColspan = false;
        this.col2IdxArr.forEach((item,idx)=>{
          if(!hasColspan) {
            returnobj2 =  {
              rowspan: 0,
              colspan: 0
            };
          }
          if (rowIndex == item) {
            hasColspan = true;
            returnobj2 =  {
              rowspan: _this.col2Arr[idx],
              colspan: 1
            };
          }
        })
        return returnobj2;
      }
    },
    fillList1(city_id, page) {
      let _this = this;
      let params = this.buildParams();
      //params.page = page;
      this.col1Arr = []; //第一列数量和
      this.col2Arr = []; //第二列数量和
      this.col1IdxArr = []; //第一列序号
      this.col2IdxArr = []; //第二列序号
      this.col1Last = ''; //第一行最后一项
      this.col2Last = ''; //第二行最后一项
      getList1(params).then(res => {
        //this.data = res.data;
        let header = res.data.header;
        let body = res.data.body;
        let data = [];
        if(body) {
          body.forEach((item, idx)=>{
            let newitem = {}
            item.forEach((subitem, subidx)=>{
              newitem[subidx] = subitem
            })
            data.push(newitem)
          })
        }
        
        this.listheader = header;
        this.data = data;



        this.data.forEach((item,idx)=>{
          if(item['0'] == _this.col1Last) {
            _this.col1Arr[_this.col1Arr.length-1]++;
          } else {
            _this.col1Arr.push(1);
            _this.col1IdxArr.push(idx);
            _this.col1Last = item['0']
          }

          if(item['1'] == _this.col2Last) {
            _this.col2Arr[_this.col2Arr.length-1]++;
          } else {
            _this.col2Arr.push(1);
            _this.col2IdxArr.push(idx);
            _this.col2Last = item['1']
          }

        })
      });
      // getExport(params).then(res => {
      //   const filename = res.headers["content-disposition"];
      //   const blob = new Blob([res.data]);
      //   var downloadElement = document.querySelector(".download");
      //   var href = window.URL.createObjectURL(blob);
      //   downloadElement.href = href;
      //   downloadElement.download = decodeURIComponent(filename.split("filename=")[1]);
      //   document.body.appendChild(downloadElement);
      //   downloadElement.click();
      //   document.body.removeChild(downloadElement);
      //   window.URL.revokeObjectURL(href); 
        
      // });
      
      // getStatistics1(params).then(res => {
      //   this.statistics = res.data;
      // });
      this.downloadUrl = consts.requestUrl + consts.exportFire2Dimensions + parseJsonToString(params)

    },
    
    fill2Map(city_id) {
      let params = this.buildParams();
      params.module_id = 1;
      getMapData(params).then(res => {
        getGeo1(params).then(res2 => {
            let data = res2;

            let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
            this.addBlockmapLayer(this.map, 'block',geoJson, res.data.map_data);
            this.fillPointLayer()
        });
      });
    },
    fillCommunities(city_id, street_id) {
      if(this.selects.data_category == 'people') {
        getCommunities(city_id, street_id).then(res => {
          let data = res.data;
          data.unshift({ id: 0, name: "社区" });
          this.selects.communities = data;
        });
      } else if(this.selects.data_category == 'hideaway') {
        getCommunitiesHideaway(city_id, street_id).then(res => {
          let data = res.data;
          data.unshift({ id: 0, name: "社区" });
          this.selects.communities = data;
        });
      } else if(this.selects.data_category == 'hospital') {
        getCommunitiesHospital(city_id, street_id).then(res => {
          let data = res.data;
          data.unshift({ id: 0, name: "社区" });
          this.selects.communities = data;
        });
      } else if(this.selects.data_category == 'warehouse') {
        getCommunitiesWarehouse(city_id, street_id).then(res => {
          let data = res.data;
          data.unshift({ id: 0, name: "社区" });
          this.selects.communities = data;
        });
      }
      
    },
    fillBlockmapLayer(city_id){
      let params = this.buildParams();
      getPeopleGeo(params).then(data => {
        let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        this.peoplegeoJson = geoJson;
        this.addBlockmapLayer(this.map, 'people', geoJson);
      });
    },
    addBlockmapLayer(map, type, geojson, mapdata) {
      let _this = this;
      this.removeMapLayer(map, type);

      let source_name = this.getMapSourceName(type);
      this.source_name =  source_name
      let layer_id = this.getMapLayerId(type);
      if(typeof geojson.features == 'undefined') return;
      geojson.features.forEach((item,idx)=>{
        item.properties.value = mapdata[item.properties.id]
      })

      //let source_name_stroke = source_name+'_stroke';
      map.off('click', layer_id, this.mapClickEvent);
      let layer_id_stroke = layer_id+'_stroke';
      map.addSource(source_name, {
        type: "geojson",
        data: geojson,
        generateId: true
      });
      let colorExpress = '';
      let strokeColor = '#000000'
      let fillOpacity = 1;
      colorExpress = [
        "step",
        ["get", "value"],
      ]
      // this.mapinfo.values.forEach((item, idx)=>{
      //   colorExpress.push(_this.mapinfo.colors[idx],item)
      // })
      // colorExpress.push("rgba(255,255,255,0)")
       if(!this.values) {
        this.values = [].concat(this.mapinfo.values);
       }
        this.values.forEach((item, idx)=>{
          colorExpress.push(_this.mapinfo.colors[idx], item)
        })
        colorExpress.push('rgba(255,255,255,0)')
      map.addLayer({
        id: layer_id,
        type: "fill",
        source: source_name,
        layout: {},
        paint: {
          "fill-color": colorExpress,
          "fill-opacity": fillOpacity,
        }
      });
      // if(type == 'region') {
      //   colorExpress = '#f96332'
      //   fillOpacity = 0.8
      //   strokeColor = '#ffffff'
      // }
      // if(type != 'region') {
      //   map.addLayer({
      //     id: layer_id,
      //     type: "fill",
      //     source: source_name,
      //     layout: {},
      //     paint: {
      //       "fill-color": colorExpress,
      //       "fill-opacity": fillOpacity
      //     }
      //   });
      // }
      //描边
      map.addLayer({
        id: layer_id_stroke,
        type: "line",
        source: source_name,
        layout: {},
        paint: {
          "line-color": strokeColor,
          "line-width": 1
        }
      });
      this.hoveredStateId = null;
      // map.on('mousemove', layer_id, (e) => {
      //   if (e.features.length > 0) {
      //     if (hoveredStateId !== null) {
      //       map.setFeatureState(
      //         { source: source_name, id: hoveredStateId },
      //         { hover: false }
      //       );
      //     }
      //     hoveredStateId = e.features[0].id;
      //     map.setFeatureState(
      //       { source: source_name, id: hoveredStateId },
      //       { hover: true }
      //     );
      //   }
      // });
       
      // // When the mouse leaves the state-fill layer, update the feature state of the
      // // previously hovered feature.
      // map.on('mouseleave', layer_id, () => {
      //   if (hoveredStateId !== null) {
      //     map.setFeatureState(
      //       { source: source_name, id: hoveredStateId },
      //       { hover: false }
      //     );
      //   }
      //   hoveredStateId = null;
      // });
      map.on('click', layer_id, this.mapClickEvent);
       
     
    },
    mapClickEvent(e){
      let _this = this;
      if (e.features.length > 0) {
          if (_this.hoveredStateId !== null) {
            _this.map.setFeatureState(
              { source: _this.source_name, id: _this.hoveredStateId },
              { hover: false }
            );
          }
          if(e.features[0].id == _this.hoveredStateId) {
            _this.hoveredStateId = null
            _this.selects.filter_street_id = 0
            _this.fillList1()
            return
          };

          _this.hoveredStateId = e.features[0].id;
          _this.map.setFeatureState(
            { source: _this.source_name, id: _this.hoveredStateId },
            { hover: true }
          );
          //筛选列表
          _this.selects.filter_street_id = e.features[0].properties.street_id
          _this.fillList1()

        }
    },
    reloadData(type) {
      //this.fillList1()
      this.fill2Map(this.city_id);
    },
    setTipContent() {
      let _this = this;
      let html = ''
      
      getInfo({
        "module_id": this.mid,
      }).then(res => {
        document.getElementById('left-tip-content').innerHTML = res.data.info;
        this.types = res.data.types
      });
      
    },
    buildParams() {
      let params = { city_id: this.city_id };
      params.town_id = this.selects.town_id;
      if(this.selects.filter_street_id === 0) {
        //地图没有筛选街道,用select的街道
        params.street_id = this.selects.street_id;
      } else {
        //地图点击了街道
        params.street_id = this.selects.filter_street_id;
      }
      //params.street_id = this.selects.street_id;
      params.module_id = this.mid;

      Object.assign(params, {
          "c8":"0",
          "c10":"0",
          "c16":"0",
          "c17":"0",
          "c27":"0",
          "c28":"0",
          "c38":"0",
          "c39":"0",
          "c41":"0",
      },this.selects.ids)
      //Object.assign(params, this.selects.ids)

      return params;
    },
    changePage: function(value) {
      if (value != this.page) {
          this.loadData(this.selects.data_category, value);
      }
      this.page = value;
    },
    fillPointLayer(){
      let params = this.buildParams();
      getStreetGeo(params).then(data => {
        let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        //this.addBlockmapLayer(this.map,'region')
        this.addPointLayerToMap(this.map, geoJson);
      });
      
    },
    makePointImageWarehouse(map, type){
      let size = 150;
      switch(type){
        case 1:
          size = 80;
        break;
        case 2:
          size = 120;
        break;
        case 3:
          size = 160;
        break;
      }
      //point type
      let pulsingDot  = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // get rendering context for the map canvas when layer is added to the map
        onAdd: function() {
          var canvas = document.createElement("canvas");
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext("2d");
        },

        // called once before every frame where the icon will be used
        render: function() {
          var duration = 1000;
          var t = (performance.now() % duration) / duration;

          var radius = (size / 2) * 0.3;
          var outerRadius = (size / 2) * 0.7 * t + radius;
          var context = this.context;

          // draw outer circle
          context.clearRect(0, 0, this.width, this.height);
          context.beginPath();
          context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2
          );
          switch(type){
            case 1:
              context.fillStyle = "rgba(255, 192, 0," + (1 - t) + ")";
            break;
            case 2:
              context.fillStyle = "rgba(237, 125, 49," + (1 - t) + ")";
            break;
            case 3:
              context.fillStyle = "rgba(255, 0, 0," + (1 - t) + ")";
            break;
          }
          
          context.fill();

          // draw inner circle
          context.beginPath();
          context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
          switch(type){
            case 1:
              context.fillStyle = "rgba(255, 192, 0, 1)";
            break;
            case 2:
              context.fillStyle = "rgba(237, 125, 49, 1)";
            break;
            case 3:
              context.fillStyle = "rgba(255, 100, 100, 1)";
            break;
          }
          
          context.strokeStyle = "white";
          context.lineWidth = 2 + 4 * (1 - t);
          context.fill();
          //context.stroke();
          // update this image's data with data from the canvas
          this.data = context.getImageData(0, 0, this.width, this.height).data;

          // continuously repaint the map, resulting in the smooth animation of the dot
          map.triggerRepaint();

          // return `true` to let the map know that the image was updated
          return true;
        }
      }
      map.addImage("warehouse-dot-"+type, pulsingDot, { pixelRatio: 2 });
    },
    makePointImageHospital(map, type, capacity){
      let size = 150;
      switch(capacity){
         case 1:
            size = 80;
          break;
          case 2:
            size = 120;
          break;
          case 3:
            size = 160;
          break;
      }
      //point type
      let pulsingDot  = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // get rendering context for the map canvas when layer is added to the map
        onAdd: function() {
          var canvas = document.createElement("canvas");
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext("2d");
        },

        // called once before every frame where the icon will be used
        render: function() {
          var duration = 1000;
          var t = (performance.now() % duration) / duration;

          var radius = (size / 2) * 0.3;
          var outerRadius = (size / 2) * 0.7 * t + radius;
          var context = this.context;

          // draw outer circle
          context.clearRect(0, 0, this.width, this.height);
          context.beginPath();
          context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2
          );
          switch(type){
            case 1: //综合医院
              context.fillStyle = "rgba(255, 0, 0," + (1 - t) + ")";
            break;
            case 2: //社区医院
              context.fillStyle = "rgba(237, 125, 49," + (1 - t) + ")";
            break;
          }
          
          context.fill();

          // draw inner circle
          context.beginPath();
          context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
          switch(type){
            case 1:
              context.fillStyle = "rgba(255, 100, 100, 1)";
            break;
            case 2:
              context.fillStyle = "rgba(237, 125, 49, 1)";
            break;
          }
          
          context.strokeStyle = "white";
          context.lineWidth = 2 + 4 * (1 - t);
          context.fill();
          //context.stroke();
          // update this image's data with data from the canvas
          this.data = context.getImageData(0, 0, this.width, this.height).data;

          // continuously repaint the map, resulting in the smooth animation of the dot
          map.triggerRepaint();

          // return `true` to let the map know that the image was updated
          return true;
        }
      }
      map.addImage("hospital-dot-"+type+"-"+capacity, pulsingDot, { pixelRatio: 2 });
    },
    makePointImageHideaway(map, type){
      let size = 150;
      switch(type){
        case 1:
          size = 80;
        break;
        case 2:
          size = 120;
        break;
        case 3:
          size = 160;
        break;
      }
      //point type
      let pulsingDot  = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // get rendering context for the map canvas when layer is added to the map
        onAdd: function() {
          var canvas = document.createElement("canvas");
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext("2d");
        },

        // called once before every frame where the icon will be used
        render: function() {
          var duration = 1000;
          var t = (performance.now() % duration) / duration;

          var radius = (size / 2) * 0.3;
          var outerRadius = (size / 2) * 0.7 * t + radius;
          var context = this.context;

          // draw outer circle
          context.clearRect(0, 0, this.width, this.height);
          context.beginPath();
          context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2
          );
          switch(type){
            case 1:
              context.fillStyle = "rgba(255, 192, 0," + (1 - t) + ")";
            break;
            case 2:
              context.fillStyle = "rgba(237, 125, 49," + (1 - t) + ")";
            break;
            case 3:
              context.fillStyle = "rgba(255, 0, 0," + (1 - t) + ")";
            break;
          }
          
          context.fill();

          // draw inner circle
          context.beginPath();
          context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
          switch(type){
            case 1:
              context.fillStyle = "rgba(255, 192, 0, 1)";
            break;
            case 2:
              context.fillStyle = "rgba(237, 125, 49, 1)";
            break;
            case 3:
              context.fillStyle = "rgba(255, 100, 100, 1)";
            break;
          }
          
          context.strokeStyle = "white";
          context.lineWidth = 2 + 4 * (1 - t);
          context.fill();
          //context.stroke();
          // update this image's data with data from the canvas
          this.data = context.getImageData(0, 0, this.width, this.height).data;

          // continuously repaint the map, resulting in the smooth animation of the dot
          map.triggerRepaint();

          // return `true` to let the map know that the image was updated
          return true;
        }
      }
      map.addImage("hideaway-dot-"+type, pulsingDot, { pixelRatio: 2 });
    },
    addPointLayerToMap2(map, geojson) {
      //let type = this.selects.data_category

      var layer = map.getLayer('points');
      if (layer) {
        map.removeLayer('points');
        map.removeSource('points');
      }
      
      if(typeof geojson.features == "undefined") return;
      let imgIcon = [];
      if(!map.hasImage("hideaway-dot-1")) {
          this.makePointImageHideaway(map, 1) //1000-2000
          this.makePointImageHideaway(map, 2) //2000-3000
          this.makePointImageHideaway(map, 3) //>3000
          
        }
        geojson.features.forEach((item,idx)=>{
          if(item.properties.persons <= 2000){
            item.properties.type = 1
          } else if(item.properties.persons <= 3000){
            item.properties.type = 2
          } else {
            item.properties.type = 3
          }
        })
        imgIcon = ["concat", "hideaway-dot-", ["get","type"]];
      
       

      map.addSource("points", {
        type: "geojson",
        data: geojson
       }
      );
      map.addLayer({
        id: "points",
        type: "symbol",
        source: "points",
        paint: {
          "text-color": "#ffffff"
        },
        layout: {
          "icon-image": imgIcon,
          "text-field": ["get", "name"],
          "text-size": 12,
          "text-offset": [0, -3],
          "text-anchor": "top"
        }
      });
    },
    addPointLayerToMap(map, geojson) {
      let _this = this;
      //let type = this.selects.data_category

      var layer = map.getLayer('points');
      if (layer) {
        map.removeLayer('points');
        map.removeSource('points');
      }
      
      if(typeof geojson.features == "undefined") return;
      let imgIcon = [];
      if(!map.hasImage("hideaway-dot-1")) {
          this.makePointImageHideaway(map, 1) //1000-2000
          this.makePointImageHideaway(map, 2) //2000-3000
          this.makePointImageHideaway(map, 3) //>3000
          
        }
        geojson.features.forEach((item,idx)=>{
          if(item.properties.persons <= 2000){
            item.properties.type = 1
          } else if(item.properties.persons <= 3000){
            item.properties.type = 2
          } else {
            item.properties.type = 3
          }
        })
        imgIcon = ["concat", "hideaway-dot-", ["get","type"]];
      
       

      map.addSource("points", {
        type: "geojson",
        data: geojson,
        generateId: true,
       }
      );
      map.addLayer({
        id: "points",
        type: "symbol",
        source: "points",
        paint: {
          "text-color": "#ffffff"
        },
        layout: {
          //"icon-image": imgIcon,
          "text-field": ["get", "name"],
          "text-size": 12,
          "text-offset": [0, 0],
          "text-anchor": "top"
        }
      });
      map.on('click', 'points', (e) => {
          //if(this.lastpopid == e.features[0].id) return;
          let barwidth = document.querySelector('.sidebar').clientWidth
          if(this.popup) {
            this.popup.remove()
          }
          this.lastpopid = e.features[0].id
          this.popup = new mapboxgl.Popup({closeOnClick: false,className: 'map-popup',anchor:'center'})
          .setLngLat(e.lngLat)
          .setMaxWidth("none")
          .setHTML('<div class="map-chart-area-wrapper"><div class="map-chart-title"></div><div class="map-chart-area" id="map-chart"></div><div class="map-chart-area map-chart-area-1" id="map-chart-sub"></div><div class="map-chart-area map-chart-area-2" id="map-chart-sub"></div><div class="map-chart-area map-chart-area-3" id="map-chart-sub"></div><div class="map-chart-area map-chart-area-4" id="map-chart-sub"></div><div class="map-chart-area map-chart-area-5" id="map-chart-sub"></div><div class="map-chart-area map-chart-area-6" id="map-chart-sub"></div></div>')
          .addTo(this.map);
          

          var box = document.getElementById("map-chart");
          var boxtitle = document.querySelector(".map-chart-title");
          //var subbox = document.getElementById("map-chart-sub");
          //subbox.style.display = 'none';
          //var subChart = this.$echarts.init(subbox);
          // var subbox1 = document.querySelector(".map-chart-area-1");
          // var subbox2 = document.querySelector(".map-chart-area-2");
          // var subbox3 = document.querySelector(".map-chart-area-3");
          // var subbox4 = document.querySelector(".map-chart-area-4");
          // var subbox5 = document.querySelector(".map-chart-area-5");
          // var subbox6 = document.querySelector(".map-chart-area-6");
          // subbox1.style.display = 'none';
          // subbox2.style.display = 'none';
          // subbox3.style.display = 'none';
          // subbox4.style.display = 'none';
          // subbox5.style.display = 'none';
          // subbox6.style.display = 'none';

          var myChart = this.$echarts.init(box);
          // var subChart1 = this.$echarts.init(subbox1);
          // var subChart2 = this.$echarts.init(subbox2);
          // var subChart3 = this.$echarts.init(subbox3);
          // var subChart4 = this.$echarts.init(subbox4);
          // var subChart5 = this.$echarts.init(subbox5);
          // var subChart6 = this.$echarts.init(subbox6);
           var option = {
            title: {
              text: '',
              textStyle: {
                  fontSize: 14,
                  color: "#fff"
              }
            },
            radar: {
              // shape: 'circle',
              indicator: [],
              splitNumber: 1,
              radius: 80,
              axisName: {
                  color: '#fff',
                 // formatter: function(params){
                 //   return params.value
                 // },
                }
            },
            series: [
              {
                type: 'radar',
                data: [
                  {
                    value: [],
                    areaStyle: {
                      color: 'rgba(255, 228, 52, 0.6)'
                    },
                     label: {
                      show: false,
                      color: '#fff',
                      fontSize: 10,
                      formatter: function (params) {
                        return params.value;
                      }
                    }
                  },
                  
                ]
              }
            ]
          };
          var suboption = {
            title: {
              text: '',
              textStyle: {
                  fontSize: 14,
                  color: "#fff"
              }
            },
            radar: {
              // shape: 'circle',
              indicator: [],
              splitNumber: 1,
              radius: 50,
              axisName: {
                  color: '#fff',
                 // formatter: function(params){
                 //   return params.value
                 // },
                }
            },
            series: [
              {
                type: 'radar',
                data: [
                  {
                    value: [],
                    areaStyle: {
                      color: 'rgba(255, 228, 52, 0.6)'
                    },
                     label: {
                      show: false,
                      color: '#fff',
                      fontSize: 10,
                      formatter: function (params) {
                        return params.value;
                      }
                    }
                  },
                  
                ]
              }
            ]
          };
          let formatterValue = [];

          this.mapchartData[e.features[0].properties.id].forEach((item,idx)=>{
            let subbox = document.querySelector(".map-chart-area-"+(idx+1))
            //subbox.style.display = 'none';
            let subChart = this.$echarts.init(subbox);

            //option.title.text = e.features[0].properties.name;
            boxtitle.innerHTML = e.features[0].properties.name;
            option.radar.indicator.push({ name: item.name, max: 100 })
            
            formatterValue.push(item.value)
            option.series[0].data[0].value.push(item.value)
            // let node = document.createElement('div')
            // node.className = "radar-" +this.mapchartData[e.features[0].properties.id].length + " radar-title-"+(idx+1)
            // node.setAttribute('data-id',(idx+1))
            
            //suboption.title.text = item.name;
            suboption.radar.indicator = [];
            suboption.series[0].data[0].value = [];
            item.items.forEach((subitem,subidx)=>{
              let str = subitem.name;
              let str1 = ''; 
              let arr1 = str.split("，");
             

              let node = document.createElement('div')
              node.className = "radar-" +item.items.length + " radar-title-"+(subidx+1)
              node.setAttribute('data-id',idx+'-'+subidx)
              subbox.appendChild(node)
              if(arr1.length==2) {
                node.onmousemove = function(e){
                  _this.showDesc = true;
                  _this.$refs.radarDescPop.style.left = (e.x-barwidth+10)+'px'
                  _this.$refs.radarDescPop.style.top = (e.y)+'px'
                  _this.$refs.radarDescPop.innerHTML = arr1[1].replaceAll(/“/gi,"“<span>").replaceAll(/”/gi,"</span>”")
                }
                node.onmouseleave = function(e){
                  _this.showDesc = false;
                }
              }
              
             
              

              suboption.radar.indicator.push({ name: arr1[0], max: 100 })
              suboption.series[0].data[0].value.push(subitem.value)
            })
            let subcenterNode = document.createElement('div')
            subcenterNode.className = "node-center"
            subcenterNode.innerHTML = formatterValue[idx]
            subbox.appendChild(subcenterNode)
            suboption.series[0].data[0].areaStyle.color = _this.getColor(formatterValue[idx])
            subChart.setOption(suboption);

            
            

          })
          option.radar.axisName.formatter = function(params){
            if(params == '社会和保障维度'){
              return params+ ': ' +formatterValue[0]
            } else if(params == '基础设施维度'){
              return params+ ': ' +formatterValue[1]
            } else if(params == '环境和生态维度') {
              return params+ ': ' +formatterValue[2]
            } else if(params == '工程和技术维度') {
              return params+ ': ' +formatterValue[3]
            } else if(params == '组织和制度维度') {
              return params+ ': ' +formatterValue[4]
            } else if(params == '经济和建设维度') {
              return params+ ': ' +formatterValue[5]
            }
          }
          option.series[0].data[0].areaStyle.color = _this.getColor(this.mapchartDimensionData[e.features[0].properties.id])
          myChart.setOption(option);
          let maincenterNode = document.createElement('div')
          maincenterNode.className = "node-center"
          maincenterNode.innerHTML = this.mapchartDimensionData[e.features[0].properties.id]
          document.querySelector('#map-chart').appendChild(maincenterNode)

          







             

          });

        // When the mouse leaves the state-fill layer, update the feature state of the
        // previously hovered feature.
        // map.on('mouseleave', 'points', () => {
        //     if(this.popup) {
         //    this.popup.remove()
         //  }
        // });
    },
    getColor(score){
      if(score>=90) {
        //浅蓝色
        return "#4387f8"
      } else if(score>=80) {
        //浅绿色
        return "#a9f87e"
      } else if(score>=70) {
        //浅黄色
        return "#f8f47e"
      } else if(score>=60) {
        //浅橙色
        return "#f9b266"
      } else {
        //浅红色
        return "#f95353"
      }
    },
    getMapLayerId(type) {
        let layer_id = type+'-layer';
        return layer_id;
    },
    getMapSourceName(type) {
      let source_name = type+"-data";
      return source_name;
    },
    removeMapLayer(map, type) {
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      if(type == 'points') {
        source_name = 'points';
        layer_id = 'points'
      }
      var layer = map.getLayer(layer_id);
      let layer_point_id = layer_id + "point";
      //描边
      var layer_stroke = map.getLayer(layer_id+'_stroke');
      if(layer_stroke){
          map.removeLayer(layer_id+'_stroke');
        }
      if (layer) {
        map.removeLayer(layer_id);
      }
      if(map.getSource(source_name)) {
        map.removeSource(source_name);
      } 
    },
    // changeRegin(type){
    //   //切换街道社区
    //   if(type == 'street'){
    //     //街道
    //     this.$router.push({
    //       path: '/fire/building/history/alarm'
    //     })
    //   } else if(type=='community') {
    //     //社区
    //     this.$router.push({
    //       path: '/fire/building/tp?mid='+this.mid
    //     })
    //   }
    // },
    changeType(val){
      //切换左上角列表
      if(val == 1) {
        if(localStorage.getItem('curStroke') == 'street') {
          //特殊页面
          this.$router.push({path: '/fire/building/alarm/1'})
        }
        if(localStorage.getItem('curStroke') == 'community'){
          //量产
          this.$router.push({path: '/fire/building/tp'+val})
        }
      } else {
            this.$router.push({path: '/fire/building/tp'+val})
      }
    }
  },
    
};
</script>
<style lang="scss" >
  .el-table__row td {
    border-right: 1px solid #eee;
  }
  .el-table__body-wrapper {
    background: #394761;
  }
  .options1 .el-cascader .el-input__inner{
    height: 50px;
  }
   .dimensions #map-chart {
      /*width: 300px;
      height: 320px;*/
      width: 420px;
      height: 420px;
      position: relative;
      left: 380px;
      top: 190px;
      
    }
    /*.dimensions #map-chart-sub {
      
      width: 450px;
      height: 450px;
      position: absolute;
      left: 0;
      top: 0;
      
    }*/
    .dimensions .map-chart-area-1 {
      width: 420px;
      height: 420px;
      position: absolute;
      left: 390px;
      top: -80px; 
    }
    .dimensions .map-chart-area-2 {
      width: 420px;
      height: 420px;
      position: absolute;
      left: 0px;
      top: 70px; 
    }
    .dimensions .map-chart-area-3 {
      width: 420px;
      height: 420px;
      position: absolute;
      left: 0px;
      top: 330px; 
    }
    .dimensions .map-chart-area-4 {
      width: 420px;
      height: 420px;
      position: absolute;
      left: 390px;
      top: 470px; 
    }
    .dimensions .map-chart-area-5 {
      width: 420px;
      height: 420px;
      position: absolute;
      left: 800px;
      top: 330px; 
    }
    .dimensions .map-chart-area-6 {
      width: 420px;
      height: 420px;
      position: absolute;
      left: 800px;
      top: 70px; 
    }
    
    #map-chart-sub {
      /*margin-top: 10px;*/
    }

    .dimensions .map-popup .mapboxgl-popup-content {
      width: 1200px;
      height: 800px;
      /*width: 400px;
      height: 400px;*/
      
    }
    .dimensions .map-popup .map-chart-area-wrapper {
      /*width: 1200px;
      height: 800px;*/
    }
    
    .dimensions .map-popup .map-chart-area {
      /*background: rgba(38, 46, 93, 0.9);
      border: 2px solid #04bbc7;
      border-radius: 6px;*/
     
    }

    .dimensions .btn-back {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
    .dimensions .node-center {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding-top: 25px;
    }
    .dimensions .node-center:before {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background: #ccc;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .radar-6,.radar-5,.radar-3 {
      width: 150px;
      height: 30px;
      position: absolute;
    }
    .radar-6.radar-title-1 {left: 50%; top: 113px; transform: translate(-50%);}
    .radar-6.radar-title-2 {left: 0; top: 153px;}
    .radar-6.radar-title-3 {left: 0; top: 220px;}
    .radar-6.radar-title-4 {left: 50%; top: 260px; transform: translate(-50%);}
    .radar-6.radar-title-5 {left: 250px; top: 220px;}
    .radar-6.radar-title-6 {left: 250px; top: 153px;}

    .radar-5.radar-title-1 {left: 50%; top: 113px; transform: translate(-50%);}
    .radar-5.radar-title-2 {left: 0; top: 163px;}
    .radar-5.radar-title-3 {left: 30px; top: 240px;}
    .radar-5.radar-title-4 {left: 230px; top: 240px; }
    .radar-5.radar-title-5 {left: 230px; top: 163px;}

    .radar-3.radar-title-1 {left: 50%; top: 113px; transform: translate(-50%);}
    .radar-3.radar-title-2 {left: 0; top: 220px;}
    .radar-3.radar-title-3 {left: 240px; top: 220px;}
   
  /*.el-table__body-wrapper::-webkit-scrollbar{z-index:11;width:6px}
  .el-table__body-wrapper::-webkit-scrollbar:horizontal{height:6px}
  .el-table__body-wrapper::-webkit-scrollbar-thumb{border-radius:5px;width:6px;background:#b4bccc}
  .el-table__body-wrapper::-webkit-scrollbar-corner,.el-table__body-wrapper::-webkit-scrollbar-track{background:#fff}
  .el-table__body-wrapper::-webkit-scrollbar-track-piece{background:#fff;width:6px}*/
.radar-desc {
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  position: absolute;
  z-index: 20;
  color: #fff;
  font-size: 12px;
  span {
    color: red;
  }
}
.dimensions .map-chart-title {
  font-size: 16px;
  position: absolute;
  left: 20px;
  top: 20px;
}
</style>
<style lang="scss" scoped>
.card-map {
  min-height: 350px;
  height: calc(100vh - 48px);
  margin-bottom: 0;

  position: relative;
  .map-wrapper{
    width: 100%;
    height: calc(100vh - 100px);
    position: relative;
    & > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  #map {
    width: 100%;
    height: calc(100vh - 100px);
  }
  .radio-group {
    position: absolute;
    left: 10px;
    top: 10px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding: 10px;
  }
  .radio-group div {
    padding: 0 10px;
  }
   .radio-group div input {
    vertical-align: -1px;
    padding-right: 2px;
   }
  .map_data_msg {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    // color: #02f4fe;
    color: #fff;
    padding: 10px;
    width: auto;
    .map_data_legend {
      margin-bottom: 5px;
    }
    .map_data_msg_wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .item {
        // width: 80px;
        line-height: 15px;
        padding: 5px 0;
      }
      .item span {
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 3px;
        vertical-align: middle;
        margin-left: 0;
      }
      .item-circle span {
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 100%;
        vertical-align: middle;
        margin-left: 0;
        position: relative;
        margin-right: 7px;
      }
      .item-circle span:after {
        content: '';
        position: absolute;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        border: 1px solid rgba(255,255,255, 0.5);
        left: -5px;
        top: -5px;
      }
      .map_data_msg_inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 10px;
      }
    }
    .map_data_msg_wrapper_vertical {
      flex-direction: column;
      .item {
        width: 130px;
      }
    }
  }
}
.main-panel {
  // height: calc(100vh - 130px);
  min-height: 350px;
}
.right-block {
  height: calc(100vh - 290px);
  min-height: 350px;
  overflow: hidden;
}
.tab-scroller {
  height: calc(100vh - 470px);
  min-height: 350px;
  overflow-y: auto;
}

.show_list {
  width: 100%;
  height: 50px;
  margin-top: 8px;
}
.show_list span {
  height: 32px;
  line-height: 36px;
  margin-left: 32px;
  font-size: 14px;
}
.show_list span i {
  height: 16px;
  padding: 2px 12px;
  background: #ffb236;
  margin: 0 4px;
  border-radius: 16px;
}
.show_list .up_show {
  display: block;
  float: left;
  font-size: 32px;
  cursor: pointer;
}
.show_list .download {
  display: block;
  float: right;
  font-size: 32px;
  cursor: pointer;
}
.footer {
  padding-top: 0;
}
.extended-forms .el-select {
  width: 100%;
  margin-bottom: 30px;
}

.extended-forms .progress {
  margin-bottom: 30px;
}
@import url("https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css");
.coordinates {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 5px 10px;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  display: none;
}
ul.nav__items > li:last-of-type a {
  margin-right: 0 !important;
}
.card-body .tab-content .tab-scroller {
  min-height: auto;
}

.chart-area {
  width: 100%;
}
.right-block .tab-pane {
    height: calc(100vh - 360px);
}
.radar-desc {
  background: rgba(0, 0, 0, 0.8);
  padding: 10px;
  position: absolute;
  z-index: 20;
  color: #fff;
  font-size: 12px;
  width: 200px;
  span {
    color: red;
  }
}



</style>
