<template>
  <div class="row" style="margin:0 -30px;">
    <div class="col-md-9" style="padding:0">
      <card class="card-map">
        <!-- <div class="mask">
          <img src="img/loading.png" class="loading">
        </div>-->
        <div id="map"></div>
        <menu-map :map="map" :mapDefault="mapdefaultSetting" showDisaster="false"></menu-map>
        <div class="radio-group">
          <div v-for="(item, idx) in selects.radioList">
            <label :for='item.value'>{{ item.label }}<input type="radio" :id="item.value" :value="item.value"
                v-model="selects.historyDataType"> {{ item.desc }}</label>
          </div>
        </div>
        <div class="map_data_msg">
          <div class="map_data_legend">{{ options[0].name }}</div>
          <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
            <div class="item" v-for="(item, index) in options[0].data" :key="index">
              <span :style="'background: ' + colors[0][index] + ''"></span>
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="show_list">
          <a class="up_show" v-on:click="showList">
            <i class="now-ui-icons" :class="[show_list ? 'arrows-1_minimal-down' : 'arrows-1_minimal-up']"></i>
          </a>
          <a class="download ml-auto" :href="downloadUrl">
            <i class="now-ui-icons arrows-1_cloud-download-93"></i>
          </a>
          <span>
            数据列表
            <!-- <i>{{total}}</i> -->
          </span>
        </div>
        <div class="show_list_record" v-show="show_list" ref="show_list_record">
          <el-table :data="data" :span-method="objectSpanMethod" max-height="600">
            <el-table-column min-width="100" label="城区" property="town_name"></el-table-column>
            <el-table-column min-width="150" label="街道" property="street_name"></el-table-column>
            <el-table-column min-width="150" label="社区" property="community_name"></el-table-column>
            <el-table-column min-width="150" label="第一产业生产总值" property="industry1"></el-table-column>
            <el-table-column min-width="150" label="第二产业生产总值" property="industry2"></el-table-column>
            <el-table-column min-width="150" label="第三产业生产总值" property="industry3"></el-table-column>
            <el-table-column min-width="150" label="生产总值" property="population"></el-table-column>
            <el-table-column min-width="150" label="产业结构指数" property="rate"></el-table-column>
          </el-table>

          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class>
              <p class="card-category">显示： {{ from + 1}} - {{ to }} 总数： {{ total }}</p>
            </div>
            <n-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage"
              :total="total" v-on:input="changePage"></n-pagination>
          </div>
        </div>
      </card>
    </div>
    <div class="col-md-3" id="customStyle">
      <el-collapse v-model="activeNames">
        <el-collapse-item title="信息图表" name="2">
          <div class="col-md-12">
            <div class="chart-area" id="box-chart" style="height:300px"></div>
          </div>
          <div class="col-md-12">
            <div class="chart-area" id="box-chart2" style="height:300px"></div>
          </div>
        </el-collapse-item>
        <el-collapse-item title="数据筛选" name="3">
          <div class="col-md-12 options1">
            <el-cascader :options="options1" placeholder="行政区划" clearable @change="handleChange1">
            </el-cascader>
          </div>
          <div class="col-md-12">
            <el-cascader :options="options2" :props="props" placeholder="基本数据" clearable @change="handleChange2">
            </el-cascader>
          </div>
        </el-collapse-item>
      </el-collapse>



    </div>
    <div class="key-infos">
      <!-- <div class="key-info" v-show="selects.data_category == 'people'">
        总人口：{{statistics.people.total_population}}，性别比：{{statistics.people.rate}}，平均年龄：{{statistics.people.age_avg}}
      </div>
      <div class="key-info" v-show="selects.data_category == 'hideaway'">
        {{statistics.hideaways.count}}个避难所，总容量{{statistics.hideaways.persons}}人
      </div>
      <div class="key-info" v-show="selects.data_category == 'hospital'">
        {{statistics.hospital.general_count}}家综合医院，{{statistics.hospital.community_count}}家社区医院，总床位数{{statistics.hospital.beds}}个
      </div>
      <div class="key-info" v-show="selects.data_category == 'warehouse'">
        {{statistics.warehouse.count}}个应急储备仓库，总面积{{statistics.warehouse.area}}平米
      </div> -->
    </div>
  </div>
</template>
<script>
import mapboxgl from "mapbox-gl";
import MenuMap from "src/components/MenumapFire";
import geobuf from "geobuf";
import Pbf from "pbf";
import MiniMap from "src/mapbox/mapboxgl-minimap.js";
import { TabPane, Tabs, Switch } from "src/components";
import { Radio } from 'src/components/index';
import { Input, Button, Select, Option, Cascader, Collapse, CollapseItem, Table, TableColumn } from "element-ui";
import { consts } from "src/util/consts.js";
import {
  getTowns as getOptions1,
  getStreets as getOptions2,
  getFireIndustry1 as getOptions3,
  getFireIndustry2 as getOptions4,
  getFireIndustry3 as getOptions5,
  getFireIndustryPopulation as getOptions6,
  getFireIndustryRates as getOptions7,
  getFireIndustryChart1 as getChart1,
  getFireIndustryChart2 as getChart2,
  getFireIndustryList as getList1,
  getFireIndustryGeojson as getGeo1,
} from "src/api/fire.js";
import { buildBarOption, buildPieOption, buildBarSideOption } from "src/util/chart.js";
import { storeMapOptions, getMapOptions, parseJsonToString } from "src/util/common.js";
import { Pagination as NPagination } from "src/components";

import * as THREE from "three";

export default {
  components: {
    MenuMap,
    TabPane,
    Tabs,
    [Cascader.name]: Cascader,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    NPagination,
    [Switch.name]: Switch,
    Radio
  },
  data() {
    return {
      activeNames: ['2', '3'],
      activeName: "first",
      selects: {
        historyDataType: 'industry',
        radioList: [
          { 'value': 'population', 'label': '人口统计：', 'desc': '人口密度' },
          { 'value': 'produce', 'label': '生产统计：', 'desc': '人均生产总值' },
          { 'value': 'land', 'label': '建筑用地：', 'desc': '建筑用地属性指数' },
          { 'value': 'industry', 'label': '产业结构：', 'desc': '产业结构指数' },
          { 'value': 'power', 'label': '用电统计：', 'desc': '用电量' },
          { 'value': 'unitrisk', 'label': '重要单位：', 'desc': '火灾高危单位密度' },
          { 'value': 'unitkey', 'label': '　　　　　', 'desc': ' 消防重点单位密度' },
        ],
        town_id: 1,
        street_id: 0,
        industry1_ids: 0,
        industry2_ids: 0,
        industry3_ids: 0,
        rate_ids: 0,
        population_ids: 0,
      },
      props: { multiple: true },
      options1: [],
      options2: [],
      keyword: "",
      total: 0,
      data: [],
      page: 1,
      map: {},
      color_category: "struct",
      city_id: localStorage.getItem("city_id") || 1,
      show_list: false,
      show_list_height: 0,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 20
      },
      last_updated: consts.last_updated,
      mapdefaultSetting: {},
      statistics: {
      },
      downloadUrl: '',
      colors: [
        ['#afb8d2', '#96a6bd', '#7286a4', '#54678c'],
      ],
      options: [
        { name: '产业结构指数', selected: [], data: [] },
      ],
    };
  },

  mounted() {
    let _this = this;
    localStorage.setItem('curStroke', 'street');
    this.fillStreets(this.city_id);
    this.showChart(this.city_id);

    this.initMap(this.city_id);
    this.map.on('style.load', function () {
      _this.reloadData()
    })
  },

  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    // street (){
    //   return this.selects.street
    // }
  },
  watch: {
    // street(val){
    //   this.fillCommunities(this.city_id,this.selects.street)
    // },
    "selects.street"(val) {
      //街道切换选社区
      this.fillCommunities(this.city_id, val);
    },
    "selects.historyDataType"(val) {
      this.$router.push({
        path: '/fire/building/' + val
      })
    },
  },
  methods: {
    // 初始化
    initMap(city_id) {
      this.mapdefaultSetting = {
        center: consts.mapCenter[this.city_id - 1],
        zoom: consts.mapDefaultZoom,
        pitch: 0,
        city_id: this.city_id
      }
      mapboxgl.accessToken = consts.mapboxAccessToken;
      var origin = [-95.97299, 36.15031, 0];
      let map_options = getMapOptions();
      //console.log(map_options);
      let zoom = map_options ? map_options.zoom : 12;
      let center = map_options ? [map_options.lng, map_options.lat] : consts.mapCenter[city_id - 1];
      //console.log(center);
      var map = new mapboxgl.Map({
        container: "map",
        style: consts.mapBoxStyle,
        //center: [-96, 37.8],
        center: center,
        zoom: zoom,
        pitch: 0,
        bearing: 0,
        attributionControl: false
      });
      this.map = map;
      //定位图标
      map.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl
        })
      );
      this.map.addControl(new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      }));
      //控制图层
      var nav = new mapboxgl.NavigationControl();
      this.map.addControl(nav, 'top-right');
      //全屏按钮
      this.map.addControl(new mapboxgl.FullscreenControl({
        container: document.querySelector('body')
      })
      );
      //小地图
      //https://github.com/brendanmatkin/mapboxgl-minimap
      map.on("style.load", function () {
        // Possible position values are 'bottom-left', 'bottom-right', 'top-left', 'top-right'
        map.addControl(new MiniMap({
          id: "mapboxgl-minimap",
          width: "200px",
          height: "150px",
          //style: "mapbox://styles/mapbox/streets-v10",
          style: consts.mapBoxStyle,
          fillColor: "#f58220",
          zoomAdjust: null
        }),
          'bottom-right');
      });

      //记录地图设置
      map.on('move', function () {
        storeMapOptions({
          lng: map.getCenter().lng,
          lat: map.getCenter().lat,
          zoom: map.getZoom()
        });
      });

    },
    showList() {
      this.show_list = !this.show_list;
    },
    donwloadList() { },
    showChart(city_id) {
      var self = this;
      var container = document.querySelector('.el-collapse');
      var box = document.getElementById("box-chart");
      var myChart = this.$echarts.init(box);
      box.style.width = container.offsetWidth + "px";
      getChart1(city_id, 1).then(res => {
        var chart_data = res.data;
        var chart_name = "产业结构指数街道统计";
        var colors = self.colors[0]

        chart_data.map((item, idx) => {
          item.itemStyle = { color: self.colors[0][idx] }
        })
        var option = buildPieOption(chart_name, chart_data);
        myChart.setOption(option);

      });

      var box2 = document.getElementById("box-chart2");
      var myChart2 = this.$echarts.init(box2);
      box2.style.width = container.offsetWidth + "px";

      getChart2(city_id, 1).then(res => {
        var chart_data = res.data;
        var chart_name = "上城区各街道产业结构指数";

        chart_data.forEach(item => {
          item.color = '#55ac68'
        })
        chart_data.map((item, idx) => {
          //['#ffff35','#c84d21','#ab0030','#780934','#481638'],
          if (item.value > 0.6) {
            item.color = '#54678c'
          } else if (item.value > 0.4) {
            item.color = '#7286a4'
          } else if (item.value > 0.2) {
            item.color = '#96a6bd'
          } else {
            item.color = '#afb8d2'
          }
          delete item.level;
        })
        var option = buildBarOption(chart_name, chart_data, "name");
        myChart2.setOption(option);

      });


      myChart.resize();
      myChart2.resize();

      window.onresize = function () {
        document.querySelectorAll('.chart-area').forEach((item, idx) => {
          item.style.width = document.querySelector('.el-collapse').offsetWidth + 'px';
        })
        myChart.resize();
        myChart2.resize();
      }
    },
    handleChange1(value) {
      //右侧下拉change1
      this.selects.town_id = value[1] || 1
      this.selects.street_id = value[2] || 0
      this.reloadData();

    },
    handleChange2(value) {
      //右侧下拉change2
      let _this = this;
      let list1 = [];
      let list2 = [];
      let list3 = [];
      let list4 = [];
      let list5 = [];

      this.selects.industry1_ids = ''
      this.selects.industry2_ids = ''
      this.selects.industry3_ids = ''
      this.selects.population_ids = ''
      this.selects.rate_ids = ''
      value.forEach((item, idx) => {
        if (item[2] == 1) {
          list1.push(item[3])
        }
        if (item[2] == 2) {
          list2.push(item[3])
        }
        if (item[2] == 3) {
          list3.push(item[3])
        }
        if (item[2] == 4) {
          list2.push(item[3])
        }
        if (item[2] == 5) {
          list3.push(item[3])
        }
      })
      if (list1.length) {
        _this.selects.industry1_ids = list1.join('-')
      } else {
        _this.selects.industry1_ids = 0
      }
      if (list2.length) {
        _this.selects.industry2_ids = list2.join('-')
      } else {
        _this.selects.industry2_ids = 0
      }
      if (list3.length) {
        _this.selects.industry3_ids = list3.join('-')
      } else {
        _this.selects.industry3_ids = 0
      }
      if (list4.length) {
        _this.selects.population_ids = list4.join('-')
      } else {
        _this.selects.population_ids = 0
      }
      if (list5.length) {
        _this.selects.rate_ids = list5.join('-')
      } else {
        _this.selects.rate_ids = 0
      }


      this.reloadData();
    },
    fillStreets(city_id) {
      let _this = this;
      _this.options1 = [{
        value: 1,
        label: "城区",
        children: []
      }]
      getOptions1(city_id).then(res => {
        let data = res.data;
        //data.unshift({ id: 0, name: "街道" });
        //this.selects.towns = data;
        data.forEach((item, idx) => {
          _this.options1[0].children.push({
            value: item.id,
            label: item.name,
            children: []
          })

          getOptions2(city_id, item.id).then(res2 => {
            let data = res2.data;
            data.forEach((subitem, subidx) => {
              _this.options1[0].children[idx].children.push({
                value: subitem.id,
                label: subitem.name,
              })
            })
          });
        })
      });
    },
    fillBasic() {
      let _this = this;
      _this.options2 = [{
        value: 1,
        label: "区域概况信息",
        children: [
          {
            value: 1,
            label: "产业结构",
            children: [
              {
                value: 1,
                label: "第一产业生产总值",
                children: [

                ]
              },
              {
                value: 2,
                label: "第二产业生产总值",
                children: [

                ]
              },
              {
                value: 3,
                label: "第三产业生产总值",
                children: [

                ]
              },
              {
                value: 3,
                label: "生产总值",
                children: [

                ]
              },
              {
                value: 3,
                label: "产业结构指数",
                children: [

                ]
              },
            ]
          },
        ]
      }]
      getOptions3().then(res => {
        let data = res.data;
        data.forEach((item, idx) => {
          _this.options2[0]['children'][0]['children'][0]['children'].push({
            value: item.id,
            label: item.name,
          })
        })
      });
      getOptions4().then(res => {
        let data = res.data;
        data.forEach((item, idx) => {
          _this.options2[0]['children'][0]['children'][1]['children'].push({
            value: item.id,
            label: item.name,
          })
        })
      });
      getOptions5().then(res => {
        let data = res.data;
        data.forEach((item, idx) => {
          _this.options2[0]['children'][0]['children'][2]['children'].push({
            value: item.id,
            label: item.name,
          })
        })
      });
      getOptions6().then(res => {
        let data = res.data;
        data.forEach((item, idx) => {
          _this.options2[0]['children'][0]['children'][3]['children'].push({
            value: item.id,
            label: item.name,
          })
        })
      });
      getOptions7().then(res => {
        let data = res.data;
        this.options[0].data = data;
        data.forEach((item, idx) => {
          _this.options2[0]['children'][0]['children'][4]['children'].push({
            value: item.id,
            label: item.name,
          })
        })
      });
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      let returnobj = null;
      let returnobj2 = null;
      let _this = this;
      if (columnIndex == 0) {
        this.col1IdxArr.forEach((item, idx) => {
          if (rowIndex == item) {
            returnobj = {
              rowspan: _this.col1Arr[idx],
              colspan: 1
            };

          } else {
            returnobj = {
              rowspan: 0,
              colspan: 0
            };
          }
        })
        return returnobj;

      }
      if (columnIndex == 1) {
        let hasColspan = false;
        this.col2IdxArr.forEach((item, idx) => {
          if (!hasColspan) {
            returnobj2 = {
              rowspan: 0,
              colspan: 0
            };
          }
          if (rowIndex == item) {
            hasColspan = true;
            returnobj2 = {
              rowspan: _this.col2Arr[idx],
              colspan: 1
            };
          }
        })
        return returnobj2;
      }
    },
    fillList1(city_id, page) {
      let _this = this;
      let params = this.buildParams();
      //params.page = page;
      this.col1Arr = []; //第一列数量和
      this.col2Arr = []; //第二列数量和
      this.col1IdxArr = []; //第一列序号
      this.col2IdxArr = []; //第二列序号
      this.col1Last = ''; //第一行最后一项
      this.col2Last = ''; //第二行最后一项
      getList1(params).then(res => {
        this.data = res.data;

        this.data.forEach((item, idx) => {
          if (item.town_name == _this.col1Last) {
            _this.col1Arr[_this.col1Arr.length - 1]++;
          } else {
            _this.col1Arr.push(1);
            _this.col1IdxArr.push(idx);
            _this.col1Last = item.town_name
          }

          if (item.street_name == _this.col2Last) {
            _this.col2Arr[_this.col2Arr.length - 1]++;
          } else {
            _this.col2Arr.push(1);
            _this.col2IdxArr.push(idx);
            _this.col2Last = item.street_name
          }

        })
      });
      // getStatistics1(params).then(res => {
      //   this.statistics = res.data;
      // });
      this.downloadUrl = consts.requestUrl + consts.exportFireIndustry + parseJsonToString(params)

    },
    fill2Map(city_id) {
      let params = this.buildParams();
      //管道
      getGeo1(params).then(res => {
        let data = res;
        let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        this.addBlockmapLayer(this.map, 'block', geoJson);
      });

    },
    fillCommunities(city_id, street_id) {
      if (this.selects.data_category == 'people') {
        getCommunities(city_id, street_id).then(res => {
          let data = res.data;
          data.unshift({ id: 0, name: "社区" });
          this.selects.communities = data;
        });
      } else if (this.selects.data_category == 'hideaway') {
        getCommunitiesHideaway(city_id, street_id).then(res => {
          let data = res.data;
          data.unshift({ id: 0, name: "社区" });
          this.selects.communities = data;
        });
      } else if (this.selects.data_category == 'hospital') {
        getCommunitiesHospital(city_id, street_id).then(res => {
          let data = res.data;
          data.unshift({ id: 0, name: "社区" });
          this.selects.communities = data;
        });
      } else if (this.selects.data_category == 'warehouse') {
        getCommunitiesWarehouse(city_id, street_id).then(res => {
          let data = res.data;
          data.unshift({ id: 0, name: "社区" });
          this.selects.communities = data;
        });
      }

    },
    fillBlockmapLayer(city_id) {
      let params = this.buildParams();
      getPeopleGeo(params).then(data => {
        let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        this.peoplegeoJson = geoJson;
        this.addBlockmapLayer(this.map, 'people', geoJson);
      });
    },
    addBlockmapLayer(map, type, geojson) {
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      if (typeof geojson.features == 'undefined') return;
      //let source_name_stroke = source_name+'_stroke';
      let layer_id_stroke = layer_id + '_stroke';
      map.addSource(source_name, {
        type: "geojson",
        data: geojson
      });
      let colorExpress = '';
      let strokeColor = '#000000'
      let fillOpacity = 1;
      colorExpress = [
        "step",
        ["get", "rate"],
        "#afb8d2", 0.2,
        "#96a6bd", 0.4,
        "#7286a4", 0.6,
        "#54678c" // other
      ]
      if (type == 'region') {
        colorExpress = '#f96332'
        fillOpacity = 0.8
        strokeColor = '#ffffff'
      }
      if (type != 'region') {
        map.addLayer({
          id: layer_id,
          type: "fill",
          source: source_name,
          layout: {},
          paint: {
            "fill-color": colorExpress,
            "fill-opacity": fillOpacity
          }
        });
      }
      //描边
      map.addLayer({
        id: layer_id_stroke,
        type: "line",
        source: source_name,
        layout: {},
        paint: {
          "line-color": strokeColor,
          "line-width": 1
        }
      });
    },
    reloadData(type) {
      this.fillBasic()
      this.fillList1()
      this.fill2Map(this.city_id);
      this.setTipContent()

      //left-tip-content
      //this.fillBuildings(this.city_id, 1);
      //this.fillBuildingsMap(this.city_id, this.color_category);
      //this.loadData(this.selects.data_category);
      //this.handelMapLayer()
    },
    setTipContent() {
      let html = ''
      if (this.selects.data_category == 'people') {
        //html = '社区人口性别比定义：每个社区中的男性人口相对女性人口的比值'
        html = consts.tipsCityInfoPeople;
      } else if (this.selects.data_category == 'hideaway') {
        html = consts.tipsCityInfoHideaway;
      } else if (this.selects.data_category == 'hospital') {
        html = consts.tipsCityInfoHospital;
      } else if (this.selects.data_category == 'warehouse') {
        html = consts.tipsCityInfoWarehouse;
      }
      //console.log('3333',document.getElementById('left-tip-content'))
      document.getElementById('left-tip-content').innerHTML = html
    },
    buildParams() {
      let params = { city_id: this.city_id };
      params.town_id = this.selects.town_id;
      params.street_id = this.selects.street_id;
      params.industry1_ids = this.selects.industry1_ids;
      params.industry2_ids = this.selects.industry2_ids;
      params.industry3_ids = this.selects.industry3_ids;
      params.population_ids = this.selects.population_ids;
      params.rate_ids = this.selects.rate_ids;
      return params;
    },
    changePage: function (value) {
      if (value != this.page) {
        this.loadData(this.selects.data_category, value);
      }
      this.page = value;
    },
    fillPointLayer() {
      let params = this.buildParams();
      if (this.selects.data_category == 'hideaway') {
        getHideawaylGeo(params).then(data => {
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          //this.addBlockmapLayer(this.map,'region')
          this.addPointLayerToMap(this.map, geoJson);
        });
      } else if (this.selects.data_category == 'hospital') {
        getHospitalGeo(params).then(data => {
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          //this.addBlockmapLayer(this.map,'region')
          this.addPointLayerToMap(this.map, geoJson);
        });
      } else if (this.selects.data_category == 'warehouse') {
        getWarehouseGeo(params).then(data => {
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          //this.addBlockmapLayer(this.map,'region')
          this.addPointLayerToMap(this.map, geoJson);
        });
      }

    },
    makePointImageWarehouse(map, type) {
      let size = 150;
      switch (type) {
        case 1:
          size = 80;
          break;
        case 2:
          size = 120;
          break;
        case 3:
          size = 160;
          break;
      }
      //point type
      let pulsingDot = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // get rendering context for the map canvas when layer is added to the map
        onAdd: function () {
          var canvas = document.createElement("canvas");
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext("2d");
        },

        // called once before every frame where the icon will be used
        render: function () {
          var duration = 1000;
          var t = (performance.now() % duration) / duration;

          var radius = (size / 2) * 0.3;
          var outerRadius = (size / 2) * 0.7 * t + radius;
          var context = this.context;

          // draw outer circle
          context.clearRect(0, 0, this.width, this.height);
          context.beginPath();
          context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2
          );
          switch (type) {
            case 1:
              context.fillStyle = "rgba(255, 192, 0," + (1 - t) + ")";
              break;
            case 2:
              context.fillStyle = "rgba(237, 125, 49," + (1 - t) + ")";
              break;
            case 3:
              context.fillStyle = "rgba(255, 0, 0," + (1 - t) + ")";
              break;
          }

          context.fill();

          // draw inner circle
          context.beginPath();
          context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
          switch (type) {
            case 1:
              context.fillStyle = "rgba(255, 192, 0, 1)";
              break;
            case 2:
              context.fillStyle = "rgba(237, 125, 49, 1)";
              break;
            case 3:
              context.fillStyle = "rgba(255, 100, 100, 1)";
              break;
          }

          context.strokeStyle = "white";
          context.lineWidth = 2 + 4 * (1 - t);
          context.fill();
          //context.stroke();
          // update this image's data with data from the canvas
          this.data = context.getImageData(0, 0, this.width, this.height).data;

          // continuously repaint the map, resulting in the smooth animation of the dot
          map.triggerRepaint();

          // return `true` to let the map know that the image was updated
          return true;
        }
      }
      map.addImage("warehouse-dot-" + type, pulsingDot, { pixelRatio: 2 });
    },
    makePointImageHospital(map, type, capacity) {
      let size = 150;
      switch (capacity) {
        case 1:
          size = 80;
          break;
        case 2:
          size = 120;
          break;
        case 3:
          size = 160;
          break;
      }
      //point type
      let pulsingDot = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // get rendering context for the map canvas when layer is added to the map
        onAdd: function () {
          var canvas = document.createElement("canvas");
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext("2d");
        },

        // called once before every frame where the icon will be used
        render: function () {
          var duration = 1000;
          var t = (performance.now() % duration) / duration;

          var radius = (size / 2) * 0.3;
          var outerRadius = (size / 2) * 0.7 * t + radius;
          var context = this.context;

          // draw outer circle
          context.clearRect(0, 0, this.width, this.height);
          context.beginPath();
          context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2
          );
          switch (type) {
            case 1: //综合医院
              context.fillStyle = "rgba(255, 0, 0," + (1 - t) + ")";
              break;
            case 2: //社区医院
              context.fillStyle = "rgba(237, 125, 49," + (1 - t) + ")";
              break;
          }

          context.fill();

          // draw inner circle
          context.beginPath();
          context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
          switch (type) {
            case 1:
              context.fillStyle = "rgba(255, 100, 100, 1)";
              break;
            case 2:
              context.fillStyle = "rgba(237, 125, 49, 1)";
              break;
          }

          context.strokeStyle = "white";
          context.lineWidth = 2 + 4 * (1 - t);
          context.fill();
          //context.stroke();
          // update this image's data with data from the canvas
          this.data = context.getImageData(0, 0, this.width, this.height).data;

          // continuously repaint the map, resulting in the smooth animation of the dot
          map.triggerRepaint();

          // return `true` to let the map know that the image was updated
          return true;
        }
      }
      map.addImage("hospital-dot-" + type + "-" + capacity, pulsingDot, { pixelRatio: 2 });
    },
    makePointImageHideaway(map, type) {
      let size = 150;
      switch (type) {
        case 1:
          size = 80;
          break;
        case 2:
          size = 120;
          break;
        case 3:
          size = 160;
          break;
      }
      //point type
      let pulsingDot = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // get rendering context for the map canvas when layer is added to the map
        onAdd: function () {
          var canvas = document.createElement("canvas");
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext("2d");
        },

        // called once before every frame where the icon will be used
        render: function () {
          var duration = 1000;
          var t = (performance.now() % duration) / duration;

          var radius = (size / 2) * 0.3;
          var outerRadius = (size / 2) * 0.7 * t + radius;
          var context = this.context;

          // draw outer circle
          context.clearRect(0, 0, this.width, this.height);
          context.beginPath();
          context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2
          );
          switch (type) {
            case 1:
              context.fillStyle = "rgba(255, 192, 0," + (1 - t) + ")";
              break;
            case 2:
              context.fillStyle = "rgba(237, 125, 49," + (1 - t) + ")";
              break;
            case 3:
              context.fillStyle = "rgba(255, 0, 0," + (1 - t) + ")";
              break;
          }

          context.fill();

          // draw inner circle
          context.beginPath();
          context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
          switch (type) {
            case 1:
              context.fillStyle = "rgba(255, 192, 0, 1)";
              break;
            case 2:
              context.fillStyle = "rgba(237, 125, 49, 1)";
              break;
            case 3:
              context.fillStyle = "rgba(255, 100, 100, 1)";
              break;
          }

          context.strokeStyle = "white";
          context.lineWidth = 2 + 4 * (1 - t);
          context.fill();
          //context.stroke();
          // update this image's data with data from the canvas
          this.data = context.getImageData(0, 0, this.width, this.height).data;

          // continuously repaint the map, resulting in the smooth animation of the dot
          map.triggerRepaint();

          // return `true` to let the map know that the image was updated
          return true;
        }
      }
      map.addImage("hideaway-dot-" + type, pulsingDot, { pixelRatio: 2 });
    },
    addPointLayerToMap(map, geojson) {
      //let type = this.selects.data_category

      var layer = map.getLayer('points');
      if (layer) {
        map.removeLayer('points');
        map.removeSource('points');
      }

      if (typeof geojson.features == "undefined") return;
      let imgIcon = [];
      if (this.selects.data_category == 'hideaway') {
        //避难所
        if (!map.hasImage("hideaway-dot-1")) {
          this.makePointImageHideaway(map, 1) //1000-2000
          this.makePointImageHideaway(map, 2) //2000-3000
          this.makePointImageHideaway(map, 3) //>3000

        }
        geojson.features.forEach((item, idx) => {
          if (item.properties.persons <= 2000) {
            item.properties.type = 1
          } else if (item.properties.persons <= 3000) {
            item.properties.type = 2
          } else {
            item.properties.type = 3
          }
        })
        imgIcon = ["concat", "hideaway-dot-", ["get", "type"]];
      } else if (this.selects.data_category == 'hospital') {
        //医疗点
        if (!map.hasImage("hospital-dot-1-1")) {
          this.makePointImageHospital(map, 1, 1) //综合医院 //1000-2000
          this.makePointImageHospital(map, 1, 2) //综合医院 //2000-3000
          this.makePointImageHospital(map, 1, 3) //综合医院 //>3000
          this.makePointImageHospital(map, 2, 1) //社区医院 //1000-2000
          this.makePointImageHospital(map, 2, 2) //社区医院 //2000-3000
          this.makePointImageHospital(map, 2, 3) //社区医院 //>3000
        }
        geojson.features.forEach((item, idx) => {
          if (item.properties.persons <= 1000) {
            item.properties.capacity = 1
          } else if (item.properties.persons <= 2000) {
            item.properties.capacity = 2
          } else {
            item.properties.capacity = 3
          }
        })
        imgIcon = ["concat", "hospital-dot-", ["get", "category"], "-", ["get", "capacity"]];
      } else if (this.selects.data_category == 'warehouse') {
        //
        if (!map.hasImage("warehouse-dot-1")) {
          this.makePointImageWarehouse(map, 1) //<5000
          this.makePointImageWarehouse(map, 2) //5000-10000
          this.makePointImageWarehouse(map, 3) //>10000
        }
        geojson.features.forEach((item, idx) => {
          if (item.properties.area <= 5000) {
            item.properties.capacity = 1
          } else if (item.properties.area <= 10000) {
            item.properties.capacity = 2
          } else {
            item.properties.capacity = 3
          }
        })
        imgIcon = ["concat", "warehouse-dot-", ["get", "capacity"]];
      }



      map.addSource("points", {
        type: "geojson",
        data: geojson
      }
      );
      map.addLayer({
        id: "points",
        type: "symbol",
        source: "points",
        paint: {
          "text-color": "#ffffff"
        },
        layout: {
          "icon-image": imgIcon,
          "text-field": ["get", "name"],
          "text-size": 12,
          "text-offset": [0, -3],
          "text-anchor": "top"
        }
      });

      // this.removeMapLayer(map, type);
      // let source_name = this.getMapSourceName(type);
      // let layer_id = this.getMapLayerId(type);
      // let geojson_url,icon = "";
      // debugger;
      // switch (type) {
      //   case "hospital":
      //     //geojson_url = consts.url_geojson_hospital;
      //     geojson_url = this.hospitalGeojson;
      //     icon = "hospital-15";
      //     break;
      //   case "hideaway":
      //     geojson_url = consts.url_geojson_hideaway;
      //     icon = "campsite-15";
      //     break;
      //   case "warehouse":
      //     geojson_url = consts.url_geojson_warehouse;
      //     icon = "town-hall-15";
      //     break;
      // }
      // if (geojson_url == "") {
      //   return;
      // }
      // var styleLoadStatus = map.isStyleLoaded();
      // if (styleLoadStatus) {
      //   map.addSource(source_name, {
      //     type: "geojson",
      //     data: geojson_url
      //   });
      //   map.addLayer({
      //     id: layer_id,
      //     type: "symbol",
      //     source: source_name,
      //     paint: {
      //       "text-color": "#f96332"
      //     },
      //     layout: {
      //       // "icon-ignore-placement": true,
      //       // "icon-allow-overlap": true,
      //       // "text-allow-overlap": true,
      //       "icon-image": icon,
      //       "text-field": "{name}",
      //       //"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
      //       "text-offset": [0, 0.6],
      //       "text-anchor": "top"
      //     }
      //   });
      //   return;
      // }
      // map.on("load", function() {
      //   map.addSource(source_name, {
      //     type: "geojson",
      //     data: geojson_url
      //   });
      //   map.addLayer({
      //     id: layer_id,
      //     type: "symbol",
      //     source: source_name,
      //     paint: {
      //       "text-color": "#f96332"
      //     },
      //     layout: {
      //       "icon-image": icon,
      //       "text-field": "{name}",
      //       //"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
      //       "text-offset": [0, 0.6],
      //       "text-anchor": "top"
      //     }
      //   });
      // });
    },
    handelMapLayer() {
      this.fillStreets(this.city_id);
      this.fillBasic()
      this.setTipContent()
      //console.log(type);
      // this.selects.street = 0;
      // this.selects.community = 0;
      this.loadData(type);
    },
    getMapLayerId(type) {
      let layer_id = type + '-layer';
      return layer_id;
    },
    getMapSourceName(type) {
      let source_name = type + "-data";
      return source_name;
    },
    removeMapLayer(map, type) {
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      if (type == 'points') {
        source_name = 'points';
        layer_id = 'points'
      }
      var layer = map.getLayer(layer_id);
      let layer_point_id = layer_id + "point";
      //描边
      var layer_stroke = map.getLayer(layer_id + '_stroke');
      if (layer_stroke) {
        map.removeLayer(layer_id + '_stroke');
      }
      if (layer) {
        map.removeLayer(layer_id);
      }
      if (map.getSource(source_name)) {
        map.removeSource(source_name);
      }
    },
    loadData(type, page = 1) {
      // switch(type) {
      //   case 'people':
      //     this.fillPeopleData(page);
      //     break;
      //   case 'gender':
      //     this.fillGenderData(page);
      //     break;
      //   case 'hospital':
      //     this.fillHospitalsData(page);     
      //     break;  
      //   case 'hideaway':
      //     this.fillHideawaysData(page);
      //     break; 
      //   case 'warehouse':
      //     this.fillWarehousesData(page);
      //     break;                                  
      // }
    },
    loadRegionData() {
      // let params = this.buildParams();
      // getRegionGeo(params).then(data => {
      //   let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
      //   this.regionGeojson = geoJson;
      // });
    }
  },

};
</script>
<style lang="scss" >
.el-table__row td {
  border-right: 1px solid #eee;
}

.el-table__body-wrapper {
  background: #394761;
}

.options1 .el-cascader .el-input__inner {
  height: 50px;
}

/*.el-table__body-wrapper::-webkit-scrollbar{z-index:11;width:6px}
  .el-table__body-wrapper::-webkit-scrollbar:horizontal{height:6px}
  .el-table__body-wrapper::-webkit-scrollbar-thumb{border-radius:5px;width:6px;background:#b4bccc}
  .el-table__body-wrapper::-webkit-scrollbar-corner,.el-table__body-wrapper::-webkit-scrollbar-track{background:#fff}
  .el-table__body-wrapper::-webkit-scrollbar-track-piece{background:#fff;width:6px}*/
</style>
<style lang="scss" scoped>
.card-map {
  min-height: 350px;
  height: calc(100vh - 48px);
  margin-bottom: 0;

  position: relative;

  .map-wrapper {
    width: 100%;
    height: calc(100vh - 100px);
    position: relative;

    &>* {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  #map {
    width: 100%;
    height: calc(100vh - 100px);
  }

  .radio-group {
    position: absolute;
    left: 10px;
    top: 10px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding: 10px;
  }

  .radio-group div {
    padding: 0 10px;
  }

  .radio-group div input {
    vertical-align: -1px;
    padding-right: 2px;
  }

  .map_data_msg {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    // color: #02f4fe;
    color: #fff;
    padding: 10px;
    width: auto;

    .map_data_legend {
      margin-bottom: 5px;
    }

    .map_data_msg_wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .item {
        // width: 80px;
        line-height: 15px;
        padding: 5px 0;
      }

      .item span {
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 3px;
        vertical-align: middle;
        margin-left: 0;
      }

      .item-circle span {
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 100%;
        vertical-align: middle;
        margin-left: 0;
        position: relative;
        margin-right: 7px;
      }

      .item-circle span:after {
        content: '';
        position: absolute;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        border: 1px solid rgba(255, 255, 255, 0.5);
        left: -5px;
        top: -5px;
      }

      .map_data_msg_inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 10px;
      }
    }

    .map_data_msg_wrapper_vertical {
      flex-direction: column;

      .item {
        width: 130px;
      }
    }
  }
}

.main-panel {
  // height: calc(100vh - 130px);
  min-height: 350px;
}

.right-block {
  height: calc(100vh - 290px);
  min-height: 350px;
  overflow: hidden;
}

.tab-scroller {
  height: calc(100vh - 470px);
  min-height: 350px;
  overflow-y: auto;
}

.show_list {
  width: 100%;
  height: 50px;
  margin-top: 8px;
}

.show_list span {
  height: 32px;
  line-height: 36px;
  margin-left: 32px;
  font-size: 14px;
}

.show_list span i {
  height: 16px;
  padding: 2px 12px;
  background: #ffb236;
  margin: 0 4px;
  border-radius: 16px;
}

.show_list .up_show {
  display: block;
  float: left;
  font-size: 32px;
  cursor: pointer;
}

.show_list .download {
  display: block;
  float: right;
  font-size: 32px;
  cursor: pointer;
}

.footer {
  padding-top: 0;
}

.extended-forms .el-select {
  width: 100%;
  margin-bottom: 30px;
}

.extended-forms .progress {
  margin-bottom: 30px;
}

@import url("https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css");

.coordinates {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 5px 10px;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  display: none;
}

ul.nav__items>li:last-of-type a {
  margin-right: 0 !important;
}

.card-body .tab-content .tab-scroller {
  min-height: auto;
}

.chart-area {
  width: 100%;
}

.right-block .tab-pane {
  height: calc(100vh - 360px);
}

.el-table {
  position: absolute;
  bottom: 106px;
}
</style>
