import request from "@/util/request";
import request1 from "@/util/request1";

export function getTowns(city_id) {
  return request({
    url: "api/fire-alarm-streets/list-town?city_id=" + city_id,
    method: "get",
  });
}
export function getStreets(city_id, town_id) {
  return request({
    url:
      "api/fire-alarm-streets/list-street?city_id=" +
      city_id +
      "&town_id=" +
      town_id,
    method: "get",
  });
}
export function getQuantities() {
  return request({
    url: "api/fire-alarm-streets/list-quantity",
    method: "get",
  });
}
export function getRates() {
  return request({
    url: "api/fire-alarm-streets/list-rate",
    method: "get",
  });
}
export function getFireAlarmList(params) {
  return request({
    url: "api/fire-alarm-streets",
    method: "get",
    params: params,
  });
}
export function getFireAlarmChart1(city_id, town_id) {
  return request({
    url:
      "api/fire-alarm-streets/show?city_id=" + city_id + "&town_id=" + town_id,
    method: "get",
  });
}
export function getFireAlarmChart2(city_id, town_id) {
  return request({
    url:
      "api/fire-alarm-streets/show-rate?city_id=" +
      city_id +
      "&town_id=" +
      town_id,
    method: "get",
  });
}
export function getFireAlarmGeojson(params) {
  return request({
    url: "api/fire-alarm-streets/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

export function getDisasterQuantities() {
  return request({
    url: "api/fire-disaster-streets/list-quantity",
    method: "get",
  });
}
export function getDisasterRates() {
  return request({
    url: "api/fire-disaster-streets/list-rate",
    method: "get",
  });
}

export function getFireDisasterList(params) {
  return request({
    url: "api/fire-disaster-streets",
    method: "get",
    params: params,
  });
}
export function getFireDisasterChart1(city_id, town_id) {
  return request({
    url:
      "api/fire-disaster-streets/show?city_id=" +
      city_id +
      "&town_id=" +
      town_id,
    method: "get",
  });
}
export function getFireDisasterChart2(city_id, town_id) {
  return request({
    url:
      "api/fire-disaster-streets/show-rate?city_id=" +
      city_id +
      "&town_id=" +
      town_id,
    method: "get",
  });
}
export function getFireDisasterGeojson(params) {
  return request({
    url: "api/fire-disaster-streets/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

export function getDeathQuantities() {
  return request({
    url: "api/fire-death-streets/list-quantity",
    method: "get",
  });
}
export function getDeathRates() {
  return request({
    url: "api/fire-death-streets/list-rate",
    method: "get",
  });
}

export function getFireDeathList(params) {
  return request({
    url: "api/fire-death-streets",
    method: "get",
    params: params,
  });
}
export function getFireDeathChart1(city_id, town_id) {
  return request({
    url:
      "api/fire-death-streets/show?city_id=" + city_id + "&town_id=" + town_id,
    method: "get",
  });
}
export function getFireDeathChart2(city_id, town_id) {
  return request({
    url:
      "api/fire-death-streets/show-rate?city_id=" +
      city_id +
      "&town_id=" +
      town_id,
    method: "get",
  });
}
export function getFireDeathGeojson(params) {
  return request({
    url: "api/fire-death-streets/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

export function getInjuryQuantities() {
  return request({
    url: "api/fire-injury-streets/list-quantity",
    method: "get",
  });
}
export function getInjuryRates() {
  return request({
    url: "api/fire-injury-streets/list-rate",
    method: "get",
  });
}

export function getFireInjuryList(params) {
  return request({
    url: "api/fire-injury-streets",
    method: "get",
    params: params,
  });
}
export function getFireInjuryChart1(city_id, town_id) {
  return request({
    url:
      "api/fire-injury-streets/show?city_id=" + city_id + "&town_id=" + town_id,
    method: "get",
  });
}
export function getFireInjuryChart2(city_id, town_id) {
  return request({
    url:
      "api/fire-injury-streets/show-rate?city_id=" +
      city_id +
      "&town_id=" +
      town_id,
    method: "get",
  });
}
export function getFireInjuryGeojson(params) {
  return request({
    url: "api/fire-injury-streets/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

export function getLossQuantities() {
  return request({
    url: "api/fire-loss-streets/list-quantity",
    method: "get",
  });
}
export function getLossRates() {
  return request({
    url: "api/fire-loss-streets/list-rate",
    method: "get",
  });
}

export function getFireLossList(params) {
  return request({
    url: "api/fire-loss-streets",
    method: "get",
    params: params,
  });
}
export function getFireLossChart1(city_id, town_id) {
  return request({
    url:
      "api/fire-loss-streets/show?city_id=" + city_id + "&town_id=" + town_id,
    method: "get",
  });
}
export function getFireLossChart2(city_id, town_id) {
  return request({
    url:
      "api/fire-loss-streets/show-rate?city_id=" +
      city_id +
      "&town_id=" +
      town_id,
    method: "get",
  });
}
export function getFireLossGeojson(params) {
  return request({
    url: "api/fire-loss-streets/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

export function getFirePopulationPopulation() {
  return request({
    url: "api/fire-population-streets/list-population",
    method: "get",
  });
}
export function getFirePopulationRates() {
  return request({
    url: "api/fire-population-streets/list-rate",
    method: "get",
  });
}
export function getFirePopulationQuantities() {
  return request({
    url: "api/fire-population-streets/list-quantity",
    method: "get",
  });
}
export function getFirePopulationGenderRates() {
  return request({
    url: "api/fire-population-streets/list-gender-rate  ",
    method: "get",
  });
}

export function getFirePopulationList(params) {
  return request({
    url: "api/fire-population-streets",
    method: "get",
    params: params,
  });
}
export function getFirePopulationChart1(city_id, town_id) {
  return request({
    url:
      "api/fire-population-streets/show?city_id=" +
      city_id +
      "&town_id=" +
      town_id,
    method: "get",
  });
}
export function getFirePopulationChart2(city_id, town_id) {
  return request({
    url:
      "api/fire-population-streets/show-rate?city_id=" +
      city_id +
      "&town_id=" +
      town_id,
    method: "get",
  });
}
export function getFirePopulationGeojson(params) {
  return request({
    url: "api/fire-population-streets/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

export function getFireProduceQuantities() {
  return request({
    url: "api/fire-produce-streets/list-quantity",
    method: "get",
  });
}

export function getFireProduceRates() {
  return request({
    url: "api/fire-produce-streets/list-rate",
    method: "get",
  });
}

export function getFireProduceList(params) {
  return request({
    url: "api/fire-produce-streets",
    method: "get",
    params: params,
  });
}
export function getFireProduceChart1(city_id, town_id) {
  return request({
    url:
      "api/fire-produce-streets/show?city_id=" +
      city_id +
      "&town_id=" +
      town_id,
    method: "get",
  });
}
export function getFireProduceChart2(city_id, town_id) {
  return request({
    url:
      "api/fire-produce-streets/show-rate?city_id=" +
      city_id +
      "&town_id=" +
      town_id,
    method: "get",
  });
}
export function getFireProduceGeojson(params) {
  return request({
    url: "api/fire-produce-streets/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

export function getFireLandPopulation() {
  return request({
    url: "api/fire-land-streets/list-population",
    method: "get",
  });
}
export function getFireLandRates() {
  return request({
    url: "api/fire-land-streets/list-rate",
    method: "get",
  });
}
export function getFireLandQuantities() {
  return request({
    url: "api/fire-land-streets/list-quantity",
    method: "get",
  });
}
export function getFireLandList(params) {
  return request({
    url: "api/fire-land-streets",
    method: "get",
    params: params,
  });
}
export function getFireLandChart1(city_id, town_id) {
  return request({
    url:
      "api/fire-land-streets/show?city_id=" + city_id + "&town_id=" + town_id,
    method: "get",
  });
}
export function getFireLandChart2(city_id, town_id) {
  return request({
    url:
      "api/fire-land-streets/show-rate?city_id=" +
      city_id +
      "&town_id=" +
      town_id,
    method: "get",
  });
}
export function getFireLandGeojson(params) {
  return request({
    url: "api/fire-land-streets/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

export function getFireIndustry1() {
  return request({
    url: "api/fire-industry-streets/list-industry1",
    method: "get",
  });
}
export function getFireIndustry2() {
  return request({
    url: "api/fire-industry-streets/list-industry2",
    method: "get",
  });
}
export function getFireIndustry3() {
  return request({
    url: "api/fire-industry-streets/list-industry3",
    method: "get",
  });
}
export function getFireIndustryPopulation() {
  return request({
    url: "api/fire-industry-streets/list-population",
    method: "get",
  });
}
export function getFireIndustryRates() {
  return request({
    url: "api/fire-industry-streets/list-rate",
    method: "get",
  });
}
export function getFireIndustryList(params) {
  return request({
    url: "api/fire-industry-streets",
    method: "get",
    params: params,
  });
}
export function getFireIndustryChart1(city_id, town_id) {
  return request({
    url:
      "api/fire-industry-streets/show?city_id=" +
      city_id +
      "&town_id=" +
      town_id,
    method: "get",
  });
}
export function getFireIndustryChart2(city_id, town_id) {
  return request({
    url:
      "api/fire-industry-streets/show-rate?city_id=" +
      city_id +
      "&town_id=" +
      town_id,
    method: "get",
  });
}
export function getFireIndustryGeojson(params) {
  return request({
    url: "api/fire-industry-streets/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

export function getFirePowerRates() {
  return request({
    url: "api/fire-power-streets/list-rate",
    method: "get",
  });
}
export function getFirePowerList(params) {
  return request({
    url: "api/fire-power-streets",
    method: "get",
    params: params,
  });
}
export function getFirePowerChart1(city_id, town_id) {
  return request({
    url:
      "api/fire-power-streets/show?city_id=" + city_id + "&town_id=" + town_id,
    method: "get",
  });
}
export function getFirePowerChart2(city_id, town_id) {
  return request({
    url:
      "api/fire-power-streets/show-rate?city_id=" +
      city_id +
      "&town_id=" +
      town_id,
    method: "get",
  });
}
export function getFirePowerGeojson(params) {
  return request({
    url: "api/fire-power-streets/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

export function getFireUnitRiskPopulation() {
  return request({
    url: "api/fire-unit-risk-streets/list-population",
    method: "get",
  });
}
export function getFireUnitRiskRates() {
  return request({
    url: "api/fire-unit-risk-streets/list-rate",
    method: "get",
  });
}
export function getFireUnitRiskList(params) {
  return request({
    url: "api/fire-unit-risk-streets",
    method: "get",
    params: params,
  });
}
export function getFireUnitRiskChart1(city_id, town_id) {
  return request({
    url:
      "api/fire-unit-risk-streets/show?city_id=" +
      city_id +
      "&town_id=" +
      town_id,
    method: "get",
  });
}
export function getFireUnitRiskChart2(city_id, town_id) {
  return request({
    url:
      "api/fire-unit-risk-streets/show-rate?city_id=" +
      city_id +
      "&town_id=" +
      town_id,
    method: "get",
  });
}
export function getFireUnitRiskGeojson(params) {
  return request({
    url: "api/fire-unit-risk-streets/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

export function getFireUnitKeyPopulation() {
  return request({
    url: "api/fire-unit-key-streets/list-population",
    method: "get",
  });
}
export function getFireUnitKeyRates() {
  return request({
    url: "api/fire-unit-key-streets/list-rate",
    method: "get",
  });
}
export function getFireUnitKeyList(params) {
  return request({
    url: "api/fire-unit-key-streets",
    method: "get",
    params: params,
  });
}
export function getFireUnitKeyChart1(city_id, town_id) {
  return request({
    url:
      "api/fire-unit-key-streets/show?city_id=" +
      city_id +
      "&town_id=" +
      town_id,
    method: "get",
  });
}
export function getFireUnitKeyChart2(city_id, town_id) {
  return request({
    url:
      "api/fire-unit-key-streets/show-rate?city_id=" +
      city_id +
      "&town_id=" +
      town_id,
    method: "get",
  });
}
export function getFireUnitKeyGeojson(params) {
  return request({
    url: "api/fire-unit-key-streets/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

export function getRoute(params) {
  return request({
    url: "restapi.amap.com/v3/direction/driving?key=cf8bbb591d619e6c38d6c52fd44fd51e&origin=116.481028,39.989643&destination=116.434446,39.90816&originid=&destinationid=&extensions=base&strategy=0&waypoints=116.357483,39.907234&avoidpolygons=&avoidroad=",
    method: "get",
  });
}

export function getStreetsHideaway(city_id) {
  return request({
    url: "api/hideaways/street?city_id=" + city_id,
    method: "get",
  });
}
export function getStreetsHospital(city_id) {
  return request({
    url: "api/serious-injuries/street?city_id=" + city_id + "&category=0",
    method: "get",
  });
}
export function getStreetsWarehouse(city_id) {
  return request({
    url: "api/warehouses/street?city_id=" + city_id,
    method: "get",
  });
}

export function getCommunities(city_id, street_id) {
  return request({
    url: "api/communities?city_id=" + city_id + "&street_id=" + street_id,
    method: "get",
  });
}
export function getCommunitiesHideaway(city_id, street_id) {
  return request({
    url:
      "api/hideaways/community?city_id=" + city_id + "&street_id=" + street_id,
    method: "get",
  });
}
export function getCommunitiesHospital(city_id, street_id) {
  return request({
    url:
      "api/serious-injuries/community?city_id=" +
      city_id +
      "&street_id=" +
      street_id +
      "&category=0",
    method: "get",
  });
}
export function getCommunitiesWarehouse(city_id, street_id) {
  return request({
    url:
      "api/warehouses/community?city_id=" + city_id + "&street_id=" + street_id,
    method: "get",
  });
}

export function getList(params) {
  return request({
    url: "api/buildings",
    method: "get",
    params: params,
  });
}

export function getGeoJson(params) {
  params.format = "geobuf";
  return request({
    url: "api/buildings-geo-json",
    method: "get",
    responseType: "arraybuffer",
    params: params,
  });
}
//加固比例-百分比列表
export function getReinforceList(params) {
  // return request({
  //   url: 'api/buildings-geo-json',
  //   method: 'get',
  //   params: params
  // })
}

//避难所列表
export function getShelters(city_id) {
  return request({
    url: "api/shelters/list?city_id=" + city_id,
    method: "get",
  });
  // return new Promise(function(resolve, reject){
  //   let data = {"code":0,"data":[
  //     {"id":1, "避难所ID": "避难所1 (S1)", "方案1": 0, "方案2": 0, "方案3": 0, "方案4": 0, "方案5": 0, "方案6": 0, "方案7": 0, "方案8": 0, "方案9": 0, "方案10": 0, "方案11": 0, "方案12": 0, "方案13": 0, "方案14": 0, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":2, "避难所ID": "避难所2 (S2)", "方案1": 0, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 0, "方案16": 0, "方案17": 0, "方案18": 0, "方案19": 0, "方案20": 0, "方案21": 0, "方案22": 0, "方案23": 0, "方案24": 0, "方案25": 0, "方案26": 0, "方案27": 0, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":3, "避难所ID": "避难所3 (S3)", "方案1": 1, "方案2": 0, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 0, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 0, "方案29": 0, "方案30": 0, "方案31": 0, "方案32": 0, "方案33": 0, "方案34": 0, "方案35": 0, "方案36": 0, "方案37": 0, "方案38": 0, "方案39": 0, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":4, "避难所ID": "避难所4 (S4)", "方案1": 1, "方案2": 1, "方案3": 0, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 0, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 0, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 0, "方案41": 0, "方案42": 0, "方案43": 0, "方案44": 0, "方案45": 0, "方案46": 0, "方案47": 0, "方案48": 0, "方案49": 0, "方案50": 0, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":5, "避难所ID": "避难所5 (S5)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 0, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 0, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 0, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 0, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 0, "方案52": 0, "方案53": 0, "方案54": 0, "方案55": 0, "方案56": 0, "方案57": 0, "方案58": 0, "方案59": 0, "方案60": 0, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":6, "避难所ID": "避难所6 (S6)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 0, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 0, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 0, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 0, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 0, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 0, "方案62": 0, "方案63": 0, "方案64": 0, "方案65": 0, "方案66": 0, "方案67": 0, "方案68": 0, "方案69": 0, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":7, "避难所ID": "避难所7 (S7)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 0, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 0, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 0, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 0, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 0, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 0, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 0, "方案71": 0, "方案72": 0, "方案73": 0, "方案74": 0, "方案75": 0, "方案76": 0, "方案77": 0, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":8, "避难所ID": "避难所8 (S8)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 0, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 0, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 0, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 0, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 0, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 0, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 0, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 0, "方案79": 0, "方案80": 0, "方案81": 0, "方案82": 0, "方案83": 0, "方案84": 0, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":9, "避难所ID": "避难所9 (S9)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 0, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 0, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 0, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 0, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 0, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 0, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 0, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 0, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 0, "方案86": 0, "方案87": 0, "方案88": 0, "方案89": 0, "方案90": 0, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":10, "避难所ID": "避难所10 S(10)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 0, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 0, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 0, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 0, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 0, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 0, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 0, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 0, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 0, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 0, "方案92": 0, "方案93": 0, "方案94": 0, "方案95": 0, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":11, "避难所ID": "避难所11 S(11)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 0, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 0, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 0, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 0, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 0, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 0, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 0, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 0, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 0, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 0, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 0, "方案97": 0, "方案98": 0, "方案99": 0, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":12, "避难所ID": "避难所12 S(12)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 0, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 0, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 0, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 0, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 0, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 0, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 0, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 0, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 0, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 0, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 0, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 0, "方案101": 0, "方案102": 0, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":13, "避难所ID": "避难所13 S(13)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 0, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 0, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 0, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 0, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 0, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 0, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 0, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 0, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 0, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 0, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 0, "方案98": 1, "方案99": 1, "方案100": 0, "方案101": 1, "方案102": 1, "方案103": 0, "方案104": 0, "方案105": 1, "方案106": 1},
  //     {"id":14, "避难所ID": "避难所14 S(14)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 0, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 0, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 0, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 0, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 0, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 0, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 0, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 0, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 0, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 0, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 0, "方案99": 1, "方案100": 1, "方案101": 0, "方案102": 1, "方案103": 0, "方案104": 1, "方案105": 0, "方案106": 1}
  //     ]};
  //   resolve(data);
  // })
}
//获取避难所方案
export function getSheltersPlan(city_id, params) {
  //params:00111111111111 方案1
  return request({
    url: "api/shelters/decision?city_id=" + city_id + "&decision=" + params,
    method: "get",
  });
  // return new Promise(function(resolve, reject){
  //   let data = {"code":0,"data":1};
  //   resolve(data);
  // })
}

//获取医疗点
export function getRescueList(city_id, type) {
  var category = 1;
  if (type == "slight") category = 0;
  //0：轻伤；1：重伤

  return request({
    url:
      "api/serious-injuries/list?city_id=" + city_id + "&category=" + category,
    method: "get",
  });
  //type 'serious'重伤or'slight'轻伤
  // return new Promise(function(resolve, reject){
  //   let data = {"code":0,"data":[
  //     {"id":1, "医疗点ID": "医疗点1 (S1)", "方案1": 0, "方案2": 0, "方案3": 0, "方案4": 0, "方案5": 0, "方案6": 0, "方案7": 0, "方案8": 0, "方案9": 0, "方案10": 0, "方案11": 0, "方案12": 0, "方案13": 0, "方案14": 0, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":2, "医疗点ID": "医疗点2 (S2)", "方案1": 0, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 0, "方案16": 0, "方案17": 0, "方案18": 0, "方案19": 0, "方案20": 0, "方案21": 0, "方案22": 0, "方案23": 0, "方案24": 0, "方案25": 0, "方案26": 0, "方案27": 0, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":3, "医疗点ID": "医疗点3 (S3)", "方案1": 1, "方案2": 0, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 0, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 0, "方案29": 0, "方案30": 0, "方案31": 0, "方案32": 0, "方案33": 0, "方案34": 0, "方案35": 0, "方案36": 0, "方案37": 0, "方案38": 0, "方案39": 0, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":4, "医疗点ID": "医疗点4 (S4)", "方案1": 1, "方案2": 1, "方案3": 0, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 0, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 0, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 0, "方案41": 0, "方案42": 0, "方案43": 0, "方案44": 0, "方案45": 0, "方案46": 0, "方案47": 0, "方案48": 0, "方案49": 0, "方案50": 0, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":5, "医疗点ID": "医疗点5 (S5)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 0, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 0, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 0, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 0, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 0, "方案52": 0, "方案53": 0, "方案54": 0, "方案55": 0, "方案56": 0, "方案57": 0, "方案58": 0, "方案59": 0, "方案60": 0, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":6, "医疗点ID": "医疗点6 (S6)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 0, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 0, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 0, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 0, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 0, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 0, "方案62": 0, "方案63": 0, "方案64": 0, "方案65": 0, "方案66": 0, "方案67": 0, "方案68": 0, "方案69": 0, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":7, "医疗点ID": "医疗点7 (S7)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 0, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 0, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 0, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 0, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 0, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 0, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 0, "方案71": 0, "方案72": 0, "方案73": 0, "方案74": 0, "方案75": 0, "方案76": 0, "方案77": 0, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":8, "医疗点ID": "医疗点8 (S8)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 0, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 0, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 0, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 0, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 0, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 0, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 0, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 0, "方案79": 0, "方案80": 0, "方案81": 0, "方案82": 0, "方案83": 0, "方案84": 0, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":9, "医疗点ID": "医疗点9 (S9)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 0, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 0, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 0, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 0, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 0, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 0, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 0, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 0, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 0, "方案86": 0, "方案87": 0, "方案88": 0, "方案89": 0, "方案90": 0, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":10, "医疗点ID": "医疗点10 S(10)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 0, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 0, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 0, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 0, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 0, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 0, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 0, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 0, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 0, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 0, "方案92": 0, "方案93": 0, "方案94": 0, "方案95": 0, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":11, "医疗点ID": "医疗点11 S(11)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 0, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 0, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 0, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 0, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 0, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 0, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 0, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 0, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 0, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 0, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 0, "方案97": 0, "方案98": 0, "方案99": 0, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":12, "医疗点ID": "医疗点12 S(12)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 0, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 0, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 0, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 0, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 0, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 0, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 0, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 0, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 0, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 0, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 0, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 0, "方案101": 0, "方案102": 0, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":13, "医疗点ID": "医疗点13 S(13)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 0, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 0, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 0, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 0, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 0, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 0, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 0, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 0, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 0, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 0, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 0, "方案98": 1, "方案99": 1, "方案100": 0, "方案101": 1, "方案102": 1, "方案103": 0, "方案104": 0, "方案105": 1, "方案106": 1},
  //     {"id":14, "医疗点ID": "医疗点14 S(14)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 0, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 0, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 0, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 0, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 0, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 0, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 0, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 0, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 0, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 0, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 0, "方案99": 1, "方案100": 1, "方案101": 0, "方案102": 1, "方案103": 0, "方案104": 1, "方案105": 0, "方案106": 1}
  //     ]};
  //   resolve(data);
  // })
}

//获取医疗点方案
export function getRescuePlan(city_id, params) {
  //params:00111111111111 方案1
  return new Promise(function (resolve, reject) {
    let data = { code: 0, data: 1 };
    resolve(data);
  });
}

//获取2.3.1灾前建筑加固列表
export function getReinforcePrioritiesList(params) {
  return request({
    url: "api/reinforcement-priorities",
    method: "get",
    params: params,
  });
}

//获取2.3.1灾前建筑加固Geojson
export function getReinforcePrioritiesGeojson(params) {
  return request({
    url: "api/reinforcement-priorities/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

//获取2.3.1灾后建筑加固Geojson
export function getReinforceaAfterGeojson(params) {
  return request({
    url: "api/reinforcement-effects/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

//2.3.2 避难所街道
export function getShelterStreets(city_id) {
  return request({
    url: "api/shelters/street?city_id=" + city_id,
    method: "get",
  });
}

//2.3.2 避难所社区
export function getShelterCommunities(city_id, street_id) {
  return request({
    url:
      "api/shelters/community?city_id=" + city_id + "&street_id=" + street_id,
    method: "get",
  });
}

//2.3.2 避难所列表
export function getShelterList(params) {
  return request({
    url: "api/shelters",
    method: "get",
    params: params,
  });
}

//2.3.2 避难所geojson
export function getShelterGeojson(params) {
  return request({
    url: "api/shelters/geo-shelter",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

//2.3.2 避难所飞线geojson
export function getShelterLineGeojson(params) {
  return request({
    url: "api/shelters/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

//2.3.2 避难所block geojson
export function getShelterBlockGeojson(params) {
  return request({
    url: "api/shelters/geo-village",
    method: "get",
    params: params,
    //responseType: 'arraybuffer'
  });
}

//2.3.3 医疗点街道
export function getRescueStreets(city_id, category) {
  return request({
    url:
      "api/serious-injuries/street?city_id=" +
      city_id +
      "&category=" +
      category,
    method: "get",
  });
}

//2.3.3 医疗点社区
export function getRescueCommunities(city_id, street_id, category) {
  return request({
    url:
      "api/serious-injuries/community?city_id=" +
      city_id +
      "&street_id=" +
      street_id +
      "&category=" +
      category,
    method: "get",
  });
}

//2.3.3 重伤医疗点列表
export function getSeriousList(params) {
  return request({
    url: "api/serious-injuries",
    method: "get",
    params: params,
  });
}

//2.3.3 重伤医疗点geojson
export function getSeriousGeojson(params) {
  return request({
    url: "api/serious-injuries/geo-hospital",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

//2.3.3 重伤医疗点飞线geojson
export function getSeriousLineGeojson(params) {
  return request({
    url: "api/serious-injuries/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

//2.3.3 重伤医疗点block geojson
export function getSeriousBlockGeojson(params) {
  return request({
    url: "api/serious-injuries/geo-village",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

//2.3.3 轻伤医疗点列表
export function getSlightList(params) {
  return request({
    url: "api/minor-injuries",
    method: "get",
    params: params,
  });
}

//2.3.3 轻伤医疗点geojson
export function getSlightGeojson(params) {
  return request({
    url: "api/minor-injuries/geo-hospital",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

//2.3.3 轻伤医疗点飞线geojson
export function getSlightLineGeojson(params) {
  return request({
    url: "api/minor-injuries/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

//2.3.3 轻伤医疗点block geojson
export function getSlightBlockGeojson(params) {
  return request({
    url: "api/minor-injuries/geo-village",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

//2.3.4 恢复街道
export function getRestorationStreets(city_id) {
  return request({
    url: "api/community-restorations/street?city_id=" + city_id,
    method: "get",
  });
}

//2.3.4 恢复社区
export function getRestorationCommunities(city_id, street_id) {
  return request({
    url:
      "api/community-restorations/community?city_id=" +
      city_id +
      "&street_id=" +
      street_id,
    method: "get",
  });
}

//2.3.4 小区列表
export function getRestorationCommunityList(params) {
  return request({
    url: "api/community-restorations",
    method: "get",
    params: params,
  });
}
//2.3.4 小区geojson
export function getRestorationCommunityGeojson(params) {
  return request({
    url: "api/community-restorations/geo",
    method: "get",
    params: params,
    //responseType: 'arraybuffer'
  });
}

//2.3.4 建筑列表
export function getRestorationBuildingList(params) {
  return request({
    url: "api/building-restorations",
    method: "get",
    params: params,
  });
}
//2.3.4 建筑geojson
export function getRestorationBuildingGeojson(params) {
  return request({
    url: "api/building-restorations/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

//2.3.4 工人数列表
export function getRestorationMaintenanceList(params) {
  return request({
    url: "api/maintenance-resources",
    method: "get",
    params: params,
  });
}
//2.3.4 工人geojson
export function getRestorationMaintenanceGeojson(params) {
  return request({
    url: "api/maintenance-resources/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

//2.2.1 列表
export function getQuakeList(params) {
  return request({
    url: "api/earthquake-analyses",
    method: "get",
    params: params,
  });
}
//2.3.4 geojson
export function getQuakeGeojson(params) {
  return request({
    url: "api/earthquake-analyses/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

//2.2.2 结构损伤
export function getPhysicalList(params) {
  return request({
    url: "api/structural-damages",
    method: "get",
    params: params,
  });
}
//2.2.2 结构损伤 geojson
export function getPhysicalGeojson(params) {
  return request({
    url: "api/structural-damages/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

//2.2.2 非结构损伤
export function getUnPhysicalList(params) {
  return request({
    url: "api/non-structural-damages",
    method: "get",
    params: params,
  });
}
//2.2.2 非结构损伤 geojson
export function getUnPhysicalGeojson(params) {
  return request({
    url: "api/non-structural-damages/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

//2.2.4 综合风险指数
export function getrisksList(params) {
  return request({
    url: "api/comprehensive-risks",
    method: "get",
    params: params,
  });
}
//2.2.4 综合风险指数 geojson
export function getrisksGeojson(params) {
  return request({
    url: "api/comprehensive-risks/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

//2.2.3 直接经济损失
export function getEconomicLossesList(params) {
  return request({
    url: "api/economic-losses",
    method: "get",
    params: params,
  });
}
//2.2.3 直接经济损失 geojson
export function getEconomicLossesGeojson(params) {
  return request({
    url: "api/economic-losses/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

//2.2.3 直接经济损失率
export function getEconomicLossesRateList(params) {
  return request({
    url: "api/economic-loss-rates",
    method: "get",
    params: params,
  });
}
//2.2.3 直接经济损失率 geojson
export function getEconomicLossesRateGeojson(params) {
  return request({
    url: "api/economic-loss-rates/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

//2.2.3 凌晨2点死伤
export function getmorningInjureList(params) {
  return request({
    url: "api/morning-casualties",
    method: "get",
    params: params,
  });
}
//2.2.3 凌晨2点死伤 geojson
export function getmorningInjureGeojson(params) {
  return request({
    url: "api/morning-casualties/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

//2.2.3 下午2点死伤
export function getAfternoonInjureList(params) {
  return request({
    url: "api/afternoon-casualties",
    method: "get",
    params: params,
  });
}
//2.2.3 下午2点死伤 geojson
export function getAfternoonInjureGeojson(params) {
  return request({
    url: "api/afternoon-casualties/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

//2.2.3 社区叠加图层 geojson
export function getInjureBlockGeojson(params) {
  return request({
    url: "api/afternoon-casualties/geo-population",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

//2.2.3 功能损失
export function getFunctionLossList(params) {
  return request({
    url: "api/functional-status",
    method: "get",
    params: params,
  });
}
//2.2.3 功能损失 geojson
export function getFunctionLossGeojson(params) {
  return request({
    url: "api/functional-status/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

//2.2.3 离家住户
export function getLeavehomeList(params) {
  return request({
    url: "api/leave-homes",
    method: "get",
    params: params,
  });
}
//2.2.3 离家住户 geojson
export function getLeavehomeGeojson(params) {
  return request({
    url: "api/leave-homes/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

//2.2.3 离家住户率
export function getLeavehomeRateList(params) {
  return request({
    url: "api/leave-home-rates",
    method: "get",
    params: params,
  });
}
//2.2.3 离家住户率 geojson
export function getLeavehomeRateGeojson(params) {
  return request({
    url: "api/leave-home-rates/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

//2.2.3 住户数叠加 geojson
export function getHouseTotalGeojson(params) {
  return request({
    url: "api/leave-homes/geo-household",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

//2.2.5 ff能用概率
export function getFFList(params) {
  return request({
    url: "api/functional-recovery-deductions",
    method: "get",
    params: params,
  });
}
//2.2.5 ff能用概率 geojson
export function getFFGeojson(params) {
  return request({
    url: "api/functional-recovery-deductions/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

//2.2.5 功能恢复时间
export function getRecoveryTimeList(params) {
  return request({
    url: "api/functional-recovery-times",
    method: "get",
    params: params,
  });
}
//2.2.5 功能恢复时间 geojson
export function getRecoveryTimeGeojson(params) {
  return request({
    url: "api/functional-recovery-times/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

//Statistics 2.1
export function getStructStatistics(params) {
  return request({
    url: "api/statistics/building-struct",
    method: "get",
    params: params,
  });
}
export function getCategoryStatistics(params) {
  return request({
    url: "api/statistics/building-category",
    method: "get",
    params: params,
  });
}
export function getQuakeLevelStatistics(params) {
  return request({
    url: "api/statistics/building-quake-level",
    method: "get",
    params: params,
  });
}
export function getBuildingYearStatistics(params) {
  return request({
    url: "api/statistics/building-year",
    method: "get",
    params: params,
  });
}
export function getRebuildCostStatistics(params) {
  return request({
    url: "api/statistics/building-rebuild-cost",
    method: "get",
    params: params,
  });
}
//2.2.1
export function getPgaStatistics(params) {
  return request({
    url: "api/statistics/building-pga",
    method: "get",
    params: params,
  });
}
//2.2.2
export function getStructuralDamageStatistics(params) {
  return request({
    url: "api/structural-damages/statistics",
    method: "get",
    params: params,
  });
}

export function getNonStructuralDamageStatistics(params) {
  return request({
    url: "api/non-structural-damages/statistics",
    method: "get",
    params: params,
  });
}

//2.2.3
export function getEconomicLossStatistics(params) {
  return request({
    url: "api/economic-losses/statistics",
    method: "get",
    params: params,
  });
}
export function getEconomicLossRateStatistics(params) {
  return request({
    url: "api/economic-loss-rates/statistics",
    method: "get",
    params: params,
  });
}
export function getMorningStatistics(params) {
  return request({
    url: "api/morning-casualties/statistics",
    method: "get",
    params: params,
  });
}
export function getAfternoonStatistics(params) {
  return request({
    url: "api/afternoon-casualties/statistics",
    method: "get",
    params: params,
  });
}
export function getTenantsStatistics(params) {
  return request({
    url: "api/leave-homes/statistics",
    method: "get",
    params: params,
  });
}
export function getTenantsRateStatistics(params) {
  return request({
    url: "api/leave-home-rates/statistics",
    method: "get",
    params: params,
  });
}
export function getFunctionalStatusStatistics(params) {
  return request({
    url: "api/functional-status/statistics",
    method: "get",
    params: params,
  });
}
//2.2.4
export function getRiskStatistics(params) {
  return request({
    url: "api/comprehensive-risks/statistics",
    method: "get",
    params: params,
  });
}

//2.2.5
export function getRecoveryShowStatistics(params) {
  return request({
    url: "api/functional-recovery-deductions/statistics",
    method: "get",
    params: params,
  });
}
export function getRecoveryTimeStatistics(params) {
  return request({
    url: "api/functional-recovery-times/statistics",
    method: "get",
    params: params,
  });
}

//2.3.1
export function getBeforeReinforceStatistics(params) {
  return request({
    url: "api/reinforcement-priorities/statistics",
    method: "get",
    params: params,
  });
}

//2.3.2
export function getShelterStatistics(params) {
  return request({
    url: "api/shelters/statistics",
    method: "get",
    params: params,
  });
}
//2.3.3
export function getSeriousStatistics(params) {
  return request({
    url: "api/serious-injuries/statistics",
    method: "get",
    params: params,
  });
}

export function getSlightStatistics(params) {
  return request({
    url: "api/minor-injuries/statistics",
    method: "get",
    params: params,
  });
}
//2.3.4
export function getRecoveryCommunitiesStatistics(params) {
  return request({
    url: "api/community-restorations/statistics",
    method: "get",
    params: params,
  });
}

export function getRecoveryBuildingstStatistics(params) {
  return request({
    url: "api/building-restorations/statistics",
    method: "get",
    params: params,
  });
}

export function getRecoveryResourcetStatistics(params) {
  return request({
    url: "api/building-restorations/statistics",
    method: "get",
    params: params,
  });
}

//搜索建筑id
export function getBuidingGeo(params) {
  params.format = "geobuf";
  return request({
    url: "api/buildings/geo",
    method: "get",
    responseType: "arraybuffer",
    params: params,
  });
}

//risk
export function getRiskOptions(params) {
  return request({
    url: "api/building-street-risks/list",
    method: "get",
    params: params,
  });
}

export function getRiskList(params) {
  return request({
    url: "api/building-street-risks",
    method: "get",
    params: params,
  });
}

export function getRiskGeojson(params) {
  return request({
    url: "api/building-street-risks/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

export function getRiskStatistics2(params) {
  return request({
    url: "api/building-street-risks/statistics",
    method: "get",
    params: params,
  });
}

export function getRiskChart(city_id) {
  return request({
    url: "api/building-street-risks/show?city_id=" + city_id,
    method: "get",
  });
}

//recovery update

export function getRecoveryStreet(city_id) {
  return request({
    url: "api/building-village-repairs/street?city_id=" + city_id,
    method: "get",
  });
}

export function getRecoveryCommunities(city_id, street_id) {
  return request({
    url:
      "api/building-village-repairs/community?city_id=" +
      city_id +
      "&street_id=" +
      street_id,
    method: "get",
  });
}

export function getRecoveryCommunityOptions(params) {
  return request({
    url: "api/building-village-repairs/list",
    method: "get",
    params: params,
  });
}

export function getRecoveryCommunityList(params) {
  return request({
    url: "api/building-village-repairs",
    method: "get",
    params: params,
  });
}

export function getRecoveryCommunityGeojson(params) {
  return request({
    url: "api/building-village-repairs/geo",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

export function getRecoveryCommunitiesBlockGeo(params) {
  return request({
    url: "api/building-village-repairs/geo-village",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

export function getRecoveryCommunityStatistics(params) {
  return request({
    url: "api/building-village-repairs/statistics",
    method: "get",
    params: params,
  });
}

export function getRecoveryCommunityChart(city_id, worker_number) {
  return request({
    url:
      "api/building-village-repairs/show?city_id=" +
      city_id +
      "&type=" +
      (parseInt(worker_number) == 20000 ? 1 : 2),
    method: "get",
  });
}

//recovery village resources
export function getRecoveryResourceOptions(params) {
  return request({
    url: "api/building-village-resources/list",
    method: "get",
    params: params,
  });
}

export function getRecoveryResourceList(params) {
  return request({
    url: "api/building-village-resources",
    method: "get",
    params: params,
  });
}

export function getRecoveryResourceGeojson(params) {
  return request({
    url: "api/building-village-resources/geo",
    //url: 'api/building-village-resources/geo-village',
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

export function getRecoveryResourceStatistics(params) {
  return request({
    url: "api/building-village-resources/statistics",
    method: "get",
    params: params,
  });
}

export function getRecoveryResourceChart(city_id, worker_number, day) {
  return request({
    url:
      "api/building-village-resources/show?city_id=" +
      city_id +
      "&type=" +
      (parseInt(worker_number) == 20000 ? 1 : 2) +
      "&in_day=" +
      parseInt(day),
    method: "get",
  });
}

//recovery building

export function getRecoveryBuildingOptions(params) {
  return request({
    url: "api/building-building-repairs/list",
    method: "get",
    params: params,
  });
}

export function getRecoveryBuildingList(params) {
  return request({
    url: "api/building-building-repairs",
    method: "get",
    params: params,
  });
}

export function getRecoveryBuildingGeojson(params) {
  return request({
    url: "api/building-building-repairs/geo",
    method: "get",
    params: params,
    //responseType: 'arraybuffer'
  });
}

export function getRecoveryBuildingOutlineGeojson(params) {
  return request({
    url: "api/building-building-repairs/geo-default",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

export function getRecoveryBuildingStatistics(params) {
  return request({
    url: "api/building-building-repairs/statistics",
    method: "get",
    params: params,
  });
}

export function getRecoveryBuildingChart(city_id, worker_number, day) {
  return request({
    url:
      "api/building-building-repairs/show?city_id=" +
      city_id +
      "&type=" +
      (parseInt(worker_number) == 20000 ? 1 : 2) +
      "&in_day=" +
      parseInt(day),
    method: "get",
  });
}

export function getRecoveryBuildingTotalChart(city_id, worker_number, day) {
  return request({
    url:
      "api/building-building-repairs/show-street?city_id=" +
      city_id +
      "&type=" +
      (parseInt(worker_number) == 20000 ? 1 : 2) +
      "&in_day=" +
      parseInt(day),
    method: "get",
  });
}

//function recovery

export function getFunctionRecoveryOptions(params) {
  return request({
    url: "api/building-function-recoveries/list",
    method: "get",
    params: params,
  });
}

export function getFunctionRecoveryList(params) {
  return request({
    url: "api/building-function-recoveries",
    method: "get",
    params: params,
  });
}

export function getFunctionRecoveryGeojson(params) {
  return request({
    url: "api/building-function-recoveries/geo",
    method: "get",
    params: params,
    //responseType: 'arraybuffer'
  });
}

export function getFunctionRecoveryOutlineGeojson(params) {
  return request({
    url: "api/building-function-recoveries/geo-default",
    method: "get",
    params: params,
    responseType: "arraybuffer",
  });
}

// export function getFunctionRecoveryStatistics(params) {
//   return request({
//     url: 'api/building-function-recoveries/statistics',
//     method: 'get',
//     params: params,
//   })
// }

export function getFunctionRecoveryChart(city_id, worker_number, day) {
  return request({
    url:
      "api/building-function-recoveries/show?city_id=" +
      city_id +
      "&type=" +
      (parseInt(worker_number) == 20000 ? 1 : 2) +
      "&in_day=" +
      parseInt(day),
    method: "get",
  });
}

export function getFunctionRecoveryStreetChart(city_id, worker_number, day) {
  return request({
    url:
      "api/building-function-recoveries/show-street?city_id=" +
      city_id +
      "&type=" +
      (parseInt(worker_number) == 20000 ? 1 : 2) +
      "&in_day=" +
      parseInt(day),
    method: "get",
  });
}

///////////////////

export function getBasicFilter(params) {
  //选项
  return request({
    url: "api/fire-basics/query-filter",
    method: "post",
    data: params,
  });
}
export function getEvaluationFilter(params) {
  //选项
  return request({
    url: "api/fire-evaluations/query-filter",
    method: "post",
    data: params,
  });
}
export function getBasicList(params) {
  //列表
  return request({
    url: "api/fire-basics",
    method: "post",
    data: params,
  });
}
export function getBasicCommunityFilter(params) {
  //列表
  return request({
    url: "api/fire-basics/query-filter-community",
    method: "post",
    data: params,
  });
}
export function getBasicFullList(params) {
  //列表all
  return request({
    url: "api/fire-basics/full",
    method: "post",
    data: params,
  });
}

export function getBasicChart1(params) {
  //饼图
  return request({
    url: "api/fire-basics/show",
    method: "post",
    data: params,
  });
}

export function getBasicChart2(params) {
  //柱图
  return request({
    url: "api/fire-basics/show-rate",
    method: "post",
    data: params,
  });
}

export function getBasicCommunityChart1(params) {
  //饼图社区
  return request({
    url: "api/fire-basics/show-community",
    method: "post",
    data: params,
  });
}

export function getBasicCommunityChart2(params) {
  //柱图社区
  return request({
    url: "api/fire-basics/show-community-rate",
    method: "post",
    data: params,
  });
}

export function getBasicExport(params) {
  return request({
    url: "api/fire-basics/export",
    method: "get",
    data: params,
  });
}
export function getBasicInfo(params) {
  return request({
    url: "api/fire-basics/module-info",
    method: "post",
    data: params,
  });
}
export function getFireStationList(params) {
  return request({
    url: "api/fire-stations",
    method: "post",
    data: params,
  });
}
export function getFireHallList(params) {
  return request({
    url: "api/fire-halls",
    method: "post",
    data: params,
  });
}

export function getFireShowMap(params) {
  return request({
    url: "api/fire-basics/show-map",
    method: "post",
    data: params,
  });
}
export function getFireShowCommunityMap(params) {
  return request({
    url: "api/fire-basics/show-community-map",
    method: "post",
    data: params,
  });
}
export function getFireEvaluationShowMap(params) {
  return request({
    url: "api/fire-evaluations/show-map",
    method: "post",
    data: params,
  });
}
export function getFireBasicGeo(params) {
  return request({
    url: "api/fire-basics/geo",
    method: "post",
    data: params,
    responseType: "arraybuffer",
  });
}
export function getFireEvaluationGeo(params) {
  return request({
    url: "api/fire-evaluations/geo",
    method: "post",
    data: params,
    responseType: "arraybuffer",
  });
}

export function getFireBasicCommunityGeo(params) {
  return request({
    url: "api/fire-basics/geo-community",
    method: "post",
    data: params,
    responseType: "arraybuffer",
  });
}

export function getFireBasicStreetGeo(params) {
  return request({
    url: "api/fire-basics/geo-street",
    method: "post",
    data: params,
    responseType: "arraybuffer",
  });
}
export function getFireBasicStationGeo(params) {
  //点
  return request({
    url: "api/fire-basics/geo-station",
    method: "post",
    data: params,
    responseType: "arraybuffer",
  });
}
export function getFireBasicHallGeo(params) {
  //点
  return request({
    url: "api/fire-basics/geo-hall",
    method: "post",
    data: params,
    responseType: "arraybuffer",
  });
}

//fire-evaluations
export function getEvaluationInfo(params) {
  return request({
    url: "api/fire-evaluations/module-info",
    method: "post",
    data: params,
  });
}

export function getEvaluationList(params) {
  //列表
  return request({
    url: "api/fire-evaluations",
    method: "post",
    data: params,
  });
}
export function getEvaluationChart1(params) {
  //饼图
  return request({
    url: "api/fire-evaluations/show",
    method: "post",
    data: params,
  });
}

export function getEvaluationChart2(params) {
  //柱图
  return request({
    url: "api/fire-evaluations/show-rate",
    method: "post",
    data: params,
  });
}

export function getFireStationGeo(params) {
  //轮廓
  return request({
    url: "api/fire-stations/geo",
    method: "post",
    data: params,
    responseType: "arraybuffer",
  });
}
export function getFireHallGeo(params) {
  //轮廓
  return request({
    url: "api/fire-halls/geo",
    method: "post",
    data: params,
    responseType: "arraybuffer",
  });
}
export function getEvaluationMapChart(params) {
  //评估里的图表
  return request({
    url: "api/fire-evaluations/chart",
    method: "post",
    data: params,
  });
}

export function getWeather(params) {
  //评估里的图表
  return request({
    url: "api/fire-evaluations/weather",
    method: "post",
    data: params,
  });
}

//fire-dimision
export function getDimensionsInfo(params) {
  return request({
    url: "api/fire-dimensions/module-info",
    method: "post",
    data: params,
  });
}

export function getDimensionsList(params) {
  //列表
  return request({
    url: "api/fire-dimensions",
    method: "post",
    data: params,
  });
}
export function getDimensionsChart1(params) {
  //图表
  return request({
    url: "api/fire-dimensions/show",
    method: "post",
    data: params,
  });
}
export function getDimensionsChart2(params) {
  //图表
  return request({
    url: "api/fire-dimensions/show-rate",
    method: "post",
    data: params,
  });
}
export function getDimensionsShowMap(params) {
  return request({
    url: "api/fire-dimensions/show-map",
    method: "post",
    data: params,
  });
}
export function getDimensionsMapData(params) {
  //地图显示
  return request({
    url: "api/fire-dimensions/map-data",
    method: "post",
    data: params,
  });
}
export function getDimensionsRadarData(params) {
  //地图显示
  return request({
    url: "api/fire-dimensions/radar-data",
    method: "post",
    data: params,
  });
}

//fire-station cost
export function getStationCostInfo(params) {
  return request({
    url: "api/fire-station-costs/module-info",
    method: "post",
    data: params,
  });
}

export function getStationCostList(params) {
  //列表
  return request({
    url: "api/fire-station-costs",
    method: "post",
    data: params,
  });
}
export function getStationCostMapData(params) {
  //地图显示
  return request({
    url: "api/fire-station-costs/map-data",
    method: "post",
    data: params,
  });
}
export function getStationCostChart(params) {
  //图表
  return request({
    url: "api/fire-station-costs/show",
    method: "post",
    data: params,
  });
}

export function getFireCommunityGeo(params) {
  //社区轮廓
  return request({
    url: "api/fire-divisions/geo-community",
    method: "post",
    data: params,
    responseType: "arraybuffer",
  });
}
export function getFireDivisionsStationGeo(params) {
  //消防站点
  return request({
    url: "api/fire-divisions/geo-station-spot",
    method: "post",
    data: params,
    responseType: "arraybuffer",
  });
}
export function getFireDivisionsGridsGeo(params) {
  //网格
  return request({
    url: "api/fire-divisions/geo-grid",
    method: "post",
    data: params,
    responseType: "arraybuffer",
  });
}

export function getFireStationGeoNew(params) {
  //轮廓
  return request({
    url: "api/fire-divisions/geo-station",
    method: "post",
    data: params,
    responseType: "arraybuffer",
  });
}
export function getFireStreetGeo(params) {
  //街道轮廓
  return request({
    url: "api/fire-divisions/geo-street",
    method: "post",
    data: params,
    responseType: "arraybuffer",
  });
}

//fire-station grids

export function getStationGridsInfo(params) {
  return request({
    url: "api/fire-station-grids/module-info",
    method: "post",
    data: params,
  });
}

export function getStationGridsList(params) {
  //列表
  return request({
    url: "api/fire-station-grids",
    method: "post",
    data: params,
  });
}
export function getStationGridsMapData(params) {
  //地图显示
  return request({
    url: "api/fire-station-grids/map-data",
    method: "post",
    data: params,
  });
}
export function getStationGridsMapGrid(params) {
  //地图显示
  return request({
    url: "api/fire-station-grids/map-grid",
    method: "post",
    data: params,
  });
}

export function getStationGridsChart(params) {
  //图表
  return request({
    url: "api/fire-station-grids/show",
    method: "post",
    data: params,
  });
}
export function getStationGridsShowMap(params) {
  return request({
    url: "api/fire-station-grids/show-map",
    method: "post",
    data: params,
  });
}

//fire-station total duration

export function getStationDurationInfo(params) {
  return request({
    url: "api/fire-station-total-durations/module-info",
    method: "post",
    data: params,
  });
}

export function getStationDurationList(params) {
  //列表
  return request({
    url: "api/fire-station-total-durations",
    method: "post",
    data: params,
  });
}
export function getStationDurationMapData(params) {
  //地图显示
  return request({
    url: "api/fire-station-total-durations/map-data",
    method: "post",
    data: params,
  });
}
export function getStationDurationMapGrid(params) {
  //地图显示
  return request({
    url: "api/fire-station-total-durations/map-grid",
    method: "post",
    data: params,
  });
}

export function getStationDurationChart(params) {
  //图表
  return request({
    url: "api/fire-station-total-durations/show",
    method: "post",
    data: params,
  });
}
export function getStationDurationShowMap(params) {
  return request({
    url: "api/fire-station-total-durations/show-map",
    method: "post",
    data: params,
  });
}

//fire-station layouts

export function getStationLayoutsInfo(params) {
  return request({
    url: "api/fire-station-layouts/module-info",
    method: "post",
    data: params,
  });
}

export function getStationLayoutsList(params) {
  //列表
  return request({
    url: "api/fire-station-layouts",
    method: "post",
    data: params,
  });
}
export function getStationLayoutsMapData(params) {
  //地图显示
  return request({
    url: "api/fire-station-layouts/map-data",
    method: "post",
    data: params,
  });
}
export function getStationLayoutsMapGrid(params) {
  //地图显示
  return request({
    url: "api/fire-station-layouts/map-grid",
    method: "post",
    data: params,
  });
}

export function getStationLayoutsChart(params) {
  //图表
  return request({
    url: "api/fire-station-layouts/show",
    method: "post",
    data: params,
  });
}
export function getStationLayoutsShowMap(params) {
  return request({
    url: "api/fire-station-layouts/show-map",
    method: "post",
    data: params,
  });
}

//fire vehicles

export function getVehiclesInfo(params) {
  return request({
    url: "api/fire-vehicles/module-info",
    method: "post",
    data: params,
  });
}

export function getVehiclesList(params) {
  //列表
  return request({
    url: "api/fire-vehicles",
    method: "post",
    data: params,
  });
}
export function getVehiclesMapData(params) {
  //地图显示
  return request({
    url: "api/fire-vehicles/map-data",
    method: "post",
    data: params,
  });
}

export function getVehiclesChart(params) {
  //图表
  return request({
    url: "api/fire-vehicles/show",
    method: "post",
    data: params,
  });
}
export function getVehiclesCommunityData(params) {
  return request({
    url: "api/fire-vehicles/community-data",
    method: "post",
    data: params,
  });
}

//fire equipments

export function getEquipmentsInfo(params) {
  return request({
    url: "api/fire-equipments/module-info",
    method: "post",
    data: params,
  });
}

export function getEquipmentsList(params) {
  //列表
  return request({
    url: "api/fire-equipments",
    method: "post",
    data: params,
  });
}
export function getEquipmentsMapData(params) {
  //地图显示
  return request({
    url: "api/fire-equipments/map-data",
    method: "post",
    data: params,
  });
}

export function getEquipmentsChart(params) {
  //图表
  return request({
    url: "api/fire-equipments/show",
    method: "post",
    data: params,
  });
}
export function getEquipmentsCommunityData(params) {
  return request({
    url: "api/fire-equipments/community-data",
    method: "post",
    data: params,
  });
}

/*weather*/
export function getWeather2(params) {
  return request({
    url: "api/fire-meteorologies/show",
    method: "post",
    data: params,
  });
}

/*decision risk*/
export function getDecisionRiskInfo(params) {
  return request({
    url: "api/fire-decision-risks/module-info",
    method: "post",
    data: params,
  });
}

export function getDecisionRiskList(params) {
  //列表
  return request({
    url: "api/fire-decision-risks",
    method: "post",
    data: params,
  });
}
export function getDecisionRiskStreet(params) {
  //geo
  return request({
    url: "api/fire-decision-risks/street",
    method: "post",
    data: params,
  });
}
export function getDecisionRiskDecision(params) {
  //选项
  return request({
    url: "api/fire-decision-risks/decision",
    method: "post",
    data: params,
  });
}
export function getDecisionRiskChart(params) {
  //图表
  return request({
    url: "api/fire-decision-risks/show",
    method: "post",
    data: params,
  });
}
export function getDecisionRiskShowMap(params) {
  return request({
    url: "api/fire-decision-risks/show-map",
    method: "post",
    data: params,
  });
}
export function getDecisionRiskMapData(params) {
  //地图显示
  return request({
    url: "api/fire-decision-risks/map-data",
    method: "post",
    data: params,
  });
}
export function getDecisionRiskRadarData(params) {
  //地图显示
  return request({
    url: "api/fire-decision-risks/radar-data",
    method: "post",
    data: params,
  });
}

export function getDecisionRiskCommunityData(params) {
  return request({
    url: "api/fire-decision-risks/community-data",
    method: "post",
    data: params,
  });
}

/*decision scores*/
export function getDecisionScoresInfo(params) {
  return request({
    url: "api/fire-decision-scores/module-info",
    method: "post",
    data: params,
  });
}

export function getDecisionScoresList(params) {
  //列表
  return request({
    url: "api/fire-decision-scores",
    method: "post",
    data: params,
  });
}

export function getDecisionScoresChart(params) {
  //图表
  return request({
    url: "api/fire-decision-scores/show",
    method: "post",
    data: params,
  });
}
export function getDecisionScoresShowMap(params) {
  return request({
    url: "api/fire-decision-scores/show-map",
    method: "post",
    data: params,
  });
}
export function getDecisionScoresMapData(params) {
  //地图显示
  return request({
    url: "api/fire-decision-scores/map-data",
    method: "post",
    data: params,
  });
}

/*decision duration*/
export function getDecisionDurationInfo(params) {
  return request({
    url: "api/fire-decision-durations/module-info",
    method: "post",
    data: params,
  });
}

export function getDecisionDurationList(params) {
  //列表
  return request({
    url: "api/fire-decision-durations",
    method: "post",
    data: params,
  });
}

export function getDecisionDurationChart(params) {
  //图表
  return request({
    url: "api/fire-decision-durations/show",
    method: "post",
    data: params,
  });
}

export function getDecisionDurationChart2(params) {
  //图表
  return request({
    url: "api/fire-decision-durations/show-rate",
    method: "post",
    data: params,
  });
}

export function getDecisionDurationChart3(params) {
  //图表
  return request({
    url: "api/fire-decision-durations/show-duration",
    method: "post",
    data: params,
  });
}

export function getDecisionDurationShowMap(params) {
  return request({
    url: "api/fire-decision-durations/show-map",
    method: "post",
    data: params,
  });
}
export function getDecisionDurationMapData(params) {
  //地图显示
  return request({
    url: "api/fire-decision-durations/map-data",
    method: "post",
    data: params,
  });
}

export function getDecisionDurationMapGrid(params) {
  //地图显示
  return request({
    url: "api/fire-decision-durations/grid-data",
    method: "post",
    data: params,
  });
}

/*decision total duration*/
export function getDecisionTotalDurationInfo(params) {
  return request({
    url: "api/fire-decision-total-durations/module-info",
    method: "post",
    data: params,
  });
}

export function getDecisionTotalDurationList(params) {
  //列表
  return request({
    url: "api/fire-decision-total-durations",
    method: "post",
    data: params,
  });
}

export function getDecisionTotalDurationChart(params) {
  //图表
  return request({
    url: "api/fire-decision-total-durations/show",
    method: "post",
    data: params,
  });
}

export function getDecisionTotalDurationChart2(params) {
  //图表
  return request({
    url: "api/fire-decision-total-durations/show-rate",
    method: "post",
    data: params,
  });
}

export function getDecisionTotalDurationChart3(params) {
  //图表
  return request({
    url: "api/fire-decision-total-durations/show-duration",
    method: "post",
    data: params,
  });
}

export function getDecisionTotalDurationShowMap(params) {
  return request({
    url: "api/fire-decision-total-durations/show-map",
    method: "post",
    data: params,
  });
}
export function getDecisionTotalDurationMapData(params) {
  //地图显示
  return request({
    url: "api/fire-decision-total-durations/map-data",
    method: "post",
    data: params,
  });
}

export function getDecisionTotalDurationMapGrid(params) {
  //地图显示
  return request({
    url: "api/fire-decision-total-durations/grid-data",
    method: "post",
    data: params,
  });
}

/*消防站布局优化 1个点*/
export function getDecisionOptimizationOnesInfo(params) {
  return request({
    url: "api/fire-decision-layout-optimization-ones/module-info",
    method: "post",
    data: params,
  });
}

export function getDecisionOptimizationOnesList(params) {
  //列表
  return request({
    url: "api/fire-decision-layout-optimization-ones",
    method: "post",
    data: params,
  });
}

export function getDecisionOptimizationOnesChart(params) {
  //图表
  return request({
    url: "api/fire-decision-layout-optimization-ones/show",
    method: "post",
    data: params,
  });
}

export function getDecisionOptimizationOnesChart2(params) {
  //图表
  return request({
    url: "api/fire-decision-layout-optimization-ones/show-rate",
    method: "post",
    data: params,
  });
}

export function getDecisionOptimizationOnesShowMap(params) {
  return request({
    url: "api/fire-decision-layout-optimization-ones/show-map",
    method: "post",
    data: params,
  });
}
export function getDecisionOptimizationOnesMapData(params) {
  //地图显示
  return request({
    url: "api/fire-decision-layout-optimization-ones/map-data",
    method: "post",
    data: params,
  });
}

export function getDecisionOptimizationOnesMapGrid(params) {
  //地图显示
  return request({
    url: "api/fire-decision-layout-optimization-ones/grid-data",
    method: "post",
    data: params,
  });
}

/*消防站布局优化 n个点*/
export function getDecisionOptimizationMoresInfo(params) {
  return request({
    url: "api/fire-decision-layout-optimization-mores/module-info",
    method: "post",
    data: params,
  });
}

export function getDecisionOptimizationMoresList(params) {
  //列表
  return request({
    url: "api/fire-decision-layout-optimization-mores",
    method: "post",
    data: params,
  });
}

export function getDecisionOptimizationMoresChart(params) {
  //图表
  return request({
    url: "api/fire-decision-layout-optimization-mores/show",
    method: "post",
    data: params,
  });
}

export function getDecisionOptimizationMoresChart2(params) {
  //图表
  return request({
    url: "api/fire-decision-layout-optimization-mores/show-rate",
    method: "post",
    data: params,
  });
}

export function getDecisionOptimizationMoresShowMap(params) {
  return request({
    url: "api/fire-decision-layout-optimization-mores/show-map",
    method: "post",
    data: params,
  });
}
export function getDecisionOptimizationMoresMapData(params) {
  //地图显示
  return request({
    url: "api/fire-decision-layout-optimization-mores/map-data",
    method: "post",
    data: params,
  });
}

export function getDecisionOptimizationMoresMapGrid(params) {
  //地图显示
  return request({
    url: "api/fire-decision-layout-optimization-mores/grid-data",
    method: "post",
    data: params,
  });
}

/*消防站布局优化 全覆盖要求*/
export function getDecisionOptimizationFullCoverInfo(params) {
  return request({
    url: "api/fire-decision-layout-optimization-full-coverages/module-info",
    method: "post",
    data: params,
  });
}

export function getDecisionOptimizationFullCoverList(params) {
  //列表
  return request({
    url: "api/fire-decision-layout-optimization-full-coverages",
    method: "post",
    data: params,
  });
}

export function getDecisionOptimizationFullCoverChart(params) {
  //图表
  return request({
    url: "api/fire-decision-layout-optimization-full-coverages/show",
    method: "post",
    data: params,
  });
}

export function getDecisionOptimizationFullCoverChart2(params) {
  //图表
  return request({
    url: "api/fire-decision-layout-optimization-full-coverages/show-rate",
    method: "post",
    data: params,
  });
}

export function getDecisionOptimizationFullCoverShowMap(params) {
  return request({
    url: "api/fire-decision-layout-optimization-full-coverages/show-map",
    method: "post",
    data: params,
  });
}
export function getDecisionOptimizationFullCoverMapData(params) {
  //地图显示
  return request({
    url: "api/fire-decision-layout-optimization-full-coverages/map-data",
    method: "post",
    data: params,
  });
}

export function getDecisionOptimizationFullCoverMapGrid(params) {
  //地图显示
  return request({
    url: "api/fire-decision-layout-optimization-full-coverages/grid-data",
    method: "post",
    data: params,
  });
}

/*消防人员和装备配备优化 消防车辆配备优化*/
export function getDecisionVehicleOptimizationInfo(params) {
  return request({
    url: "api/fire-decision-vehicle-optimizations/module-info",
    method: "post",
    data: params,
  });
}

export function getDecisionVehicleOptimizationList(params) {
  //列表
  return request({
    url: "api/fire-decision-vehicle-optimizations",
    method: "post",
    data: params,
  });
}

export function getDecisionVehicleOptimizationChart(params) {
  //图表
  return request({
    url: "api/fire-decision-vehicle-optimizations/show",
    method: "post",
    data: params,
  });
}

export function getDecisionVehicleOptimizationChart2(params) {
  //图表
  return request({
    url: "api/fire-decision-vehicle-optimizations/show-rate",
    method: "post",
    data: params,
  });
}

// export function getDecisionVehicleOptimizationShowMap(params) {
//   return request({
//     url: 'api/fire-decision-vehicle-optimizations/show-map',
//     method: 'post',
//     data: params,
//   })
// }
export function getDecisionVehicleOptimizationMapData(params) {
  //地图显示
  return request({
    url: "api/fire-decision-vehicle-optimizations/map-data",
    method: "post",
    data: params,
  });
}

// export function getDecisionVehicleOptimizationMapGrid(params) {
//   //地图显示
//   return request({
//     url: 'api/fire-decision-vehicle-optimizations/grid-data',
//     method: 'post',
//     data: params,
//   })
// }

export function getDecisionVehicleOptimizationCommunityData(params) {
  //地图显示
  return request({
    url: "api/fire-decision-vehicle-optimizations/community-data",
    method: "post",
    data: params,
  });
}

export function getDecisionVehicleOptimizationStations(params) {
  //表格
  return request({
    url: "api/fire-decision-vehicle-optimizations/stations",
    method: "post",
    data: params,
  });
}

/*消防人员和装备配备优化 消防人员配备优化*/
export function getDecisionPersonnelOptimizationInfo(params) {
  return request({
    url: "api/fire-decision-personnel-optimizations/module-info",
    method: "post",
    data: params,
  });
}

export function getDecisionPersonnelOptimizationList(params) {
  //列表
  return request({
    url: "api/fire-decision-personnel-optimizations",
    method: "post",
    data: params,
  });
}

export function getDecisionPersonnelOptimizationChart(params) {
  //图表
  return request({
    url: "api/fire-decision-personnel-optimizations/show",
    method: "post",
    data: params,
  });
}

export function getDecisionPersonnelOptimizationChart2(params) {
  //图表
  return request({
    url: "api/fire-decision-personnel-optimizations/show-rate",
    method: "post",
    data: params,
  });
}

export function getDecisionPersonnelOptimizationMapData(params) {
  //地图显示
  return request({
    url: "api/fire-decision-personnel-optimizations/map-data",
    method: "post",
    data: params,
  });
}

export function getDecisionPersonnelOptimizationCommunityData(params) {
  //地图显示
  return request({
    url: "api/fire-decision-personnel-optimizations/community-data",
    method: "post",
    data: params,
  });
}

export function getDecisionPersonnelOptimizationStations(params) {
  //表格
  return request({
    url: "api/fire-decision-personnel-optimizations/personnels",
    method: "post",
    data: params,
  });
}

/*消防人员和装备配备优化 消防装备配备优化*/
export function getDecisionEquipmentOptimizationInfo(params) {
  return request({
    url: "api/fire-decision-equipment-optimizations/module-info",
    method: "post",
    data: params,
  });
}

export function getDecisionEquipmentOptimizationList(params) {
  //列表
  return request({
    url: "api/fire-decision-equipment-optimizations",
    method: "post",
    data: params,
  });
}

export function getDecisionEquipmentOptimizationChart(params) {
  //图表
  return request({
    url: "api/fire-decision-equipment-optimizations/show",
    method: "post",
    data: params,
  });
}

export function getDecisionEquipmentOptimizationChart2(params) {
  //图表
  return request({
    url: "api/fire-decision-equipment-optimizations/show-rate",
    method: "post",
    data: params,
  });
}

export function getDecisionEquipmentOptimizationMapData(params) {
  //地图显示
  return request({
    url: "api/fire-decision-equipment-optimizations/map-data",
    method: "post",
    data: params,
  });
}

export function getDecisionEquipmentOptimizationCommunityData(params) {
  //地图显示
  return request({
    url: "api/fire-decision-equipment-optimizations/community-data",
    method: "post",
    data: params,
  });
}

export function getDecisionEquipmentOptimizationStations(params) {
  //表格
  return request({
    url: "api/fire-decision-equipment-optimizations/equipments",
    method: "post",
    data: params,
  });
}

export function getFireStationStrokeGeo(params) {
  return request({
    url: "api/fire-divisions/geo-station-outline",
    method: "post",
    data: params,
  });
}

/*新建不定值n个消防站以满足费用约束下的最佳平衡*/
export function getDecisionOptimizationBalancesInfo(params) {
  return request({
    url: "api/fire-decision-layout-optimization-balances/module-info",
    method: "post",
    data: params,
  });
}

export function getDecisionOptimizationBalancesList(params) {
  //列表
  return request({
    url: "api/fire-decision-layout-optimization-balances",
    method: "post",
    data: params,
  });
}

export function getDecisionOptimizationBalancesChart(params) {
  //图表
  return request({
    url: "api/fire-decision-layout-optimization-balances/show",
    method: "post",
    data: params,
  });
}

export function getDecisionOptimizationBalancesChart2(params) {
  //图表
  return request({
    url: "api/fire-decision-layout-optimization-balances/show-rate",
    method: "post",
    data: params,
  });
}

export function getDecisionOptimizationBalancesShowMap(params) {
  return request({
    url: "api/fire-decision-layout-optimization-balances/show-map",
    method: "post",
    data: params,
  });
}
export function getDecisionOptimizationBalancesMapData(params) {
  //地图显示
  return request({
    url: "api/fire-decision-layout-optimization-balances/map-data",
    method: "post",
    data: params,
  });
}

export function getDecisionOptimizationBalancesMapGrid(params) {
  //地图显示
  return request({
    url: "api/fire-decision-layout-optimization-balances/grid-data",
    method: "post",
    data: params,
  });
}

export function getDecisionLayoutOptimizationBalancesDefaultAamount(params) {
  return request({
    url: "api/fire-decision-layout-optimization-balances/default-amount",
    method: "post",
    data: params,
  });
}

export function getDecisionLayoutOptimizationBalancesComputeAamount(params) {
  return request({
    url: "api/fire-decision-layout-optimization-balances/compute-amount",
    method: "post",
    data: params,
  });
}

export function getDecisionForecastChartLine(params) {
  return request1({
    url: "dealed/countTimeAxis",
    method: "post",
    data: params,
  });
}
export function getDecisionForecastChartPie(params) {
  return request1({
    url: "dealed/countFirePic",
    method: "post",
    data: params,
  });
}
export function getDecisionForecastChartRiver(params) {
  return request1({
    url: "dealed/countRiveMap",
    method: "post",
    data: params,
  });
}

export function getPublicPbf(params) {
  return request1({
    url: "/dealed/downloadPbfOrJson",
    method: "get",
    responseType: "arraybuffer",
    params: params,
  });
}
export function getPublicJson(params) {
  return request1({
    url: "/dealed/downloadPbfOrJson",
    method: "get",
    params: params,
  });
}
