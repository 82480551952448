<template>
  <div class="row" style="margin:0 -30px;">
    <div class="col-md-9" style="padding:0">
      <card class="card-map">
        <div id="map"></div>
        <menu-map :map="map" :mapDefault="mapdefaultSetting" showDisaster="false"></menu-map>
        <div class="map_data_msg">
          <!-- <div class="map_data_legend"></div> -->
          <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
            <div
              class="item"
              v-for="(item,index) in options[0].data"
              :key="index"
            >
              <!-- <span :style="'background: '+item.color+''"></span> -->
              <img :src="figures[0][index]" class="figure"/>
              {{item.name}}
            </div>
          </div>
        </div>
        <div class="show_list">
          <a class="up_show" v-on:click="showList">
            <i
              class="now-ui-icons"
              :class="[ show_list ? 'arrows-1_minimal-down':'arrows-1_minimal-up']"
            ></i>
          </a>
          <a class="download"  :href="downloadUrl">
            <i class="now-ui-icons arrows-1_cloud-download-93"></i>
          </a>
          <span>
            数据列表
            <!-- <i>{{total}}</i> -->
          </span>
        </div>
        <div class="show_list_record" v-show="show_list" :style1="{'top':'-556px','height': '506px'}"
          ref="show_list_record">
          <tabs type="primary" v-model="activeTab">
            <tab-pane label="节点">
              <el-table :data="data1"  empty-text = "暂无数据！">
                <el-table-column min-width="150" label="节点ID" property="node_id"></el-table-column>
                <el-table-column min-width="150" label="节点名" property="name"></el-table-column>
                <el-table-column min-width="150" label="节点进流量" property="in_flow"></el-table-column>
                <el-table-column min-width="150" label="节点出流量" property="out_flow"></el-table-column>
                <el-table-column min-width="150" label="地址" property="crossing_road"></el-table-column>
              </el-table>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class>
                  <p class="card-category">显示： {{from1 + 1}} - {{to1}} 总数： {{total1}}</p>
                </div>
                <n-pagination
                  class="pagination-no-border"
                  v-model="pagination1.currentPage"
                  :per-page="pagination1.perPage"
                  :total="total1"
                  v-on:input="changePage1"
                ></n-pagination>
              </div>
            </tab-pane>

            <tab-pane label="道路">
              <el-table :data="data2"  empty-text = "暂无数据！">
                <el-table-column min-width="150" label="路网线路ID" property="line_id"></el-table-column>
                <el-table-column min-width="150" label="路网线路名" property="name"></el-table-column>
                <el-table-column min-width="150" label="道路名称" property="road_name"></el-table-column>
                <el-table-column min-width="150" label="道路等级" property="road_level"></el-table-column>
                <el-table-column min-width="150" label="道路长度" property="road_len"></el-table-column>
                <el-table-column min-width="150" label="自由流速" property="free_flow_speed"></el-table-column>
                <el-table-column min-width="150" label="通行能力" property="capacity"></el-table-column>
              </el-table>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class>
                  <p class="card-category">显示： {{from2 + 1}} - {{to2}} 总数： {{total2}}</p>
                </div>
                <n-pagination
                  class="pagination-no-border"
                  v-model="pagination2.currentPage"
                  :per-page="pagination2.perPage"
                  :total="total2"
                  v-on:input="changePage2"
                ></n-pagination>
              </div>
            </tab-pane>

            <tab-pane label="交通小区">
              <el-table :data="data3"  empty-text = "暂无数据！">
                <el-table-column min-width="150" label="交通小区ID" property="id"></el-table-column>
                <el-table-column min-width="150" label="交通小区名" property="name"></el-table-column>
              </el-table>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class>
                  <p class="card-category">显示： {{from3 + 1}} - {{to3}} 总数： {{total3}}</p>
                </div>
                <n-pagination
                  class="pagination-no-border"
                  v-model="pagination3.currentPage"
                  :per-page="pagination3.perPage"
                  :total="total3"
                  v-on:input="changePage3"
                ></n-pagination>
              </div>
            </tab-pane>
          </tabs>
        </div>


      </card>
    </div>
     <div class="col-md-3" id="customStyle">
      <el-collapse v-model="activeNames">
        <el-collapse-item title="信息图表" name="1">
          <div class="col-md-12">
              <p class="chart-total-msg">节点/道路/服务区 统计</p>
              <!-- <div class="chart-area" id="box-chart" style="height: 200px"></div> -->
              <div class="chart-item item-1">
                <img src="/img/icons/icon-chart-traffic-node.png" alt="">
                <div>
                  <span>{{chartitem[0].name}}</span>
                  <span>{{chartitem[0].value}}个</span>
                </div>
              </div>
              <div class="chart-item item-2">
                <img src="/img/icons/icon-chart-traffic-pipe.png" alt="">
                <div>
                  <span>{{chartitem[1].name}}</span>
                  <span>{{chartitem[1].value}}km</span>
                </div>
              </div>
              <div class="chart-item item-3">
                <img src="/img/icons/icon-chart-traffic-block.png" alt="">
                <div>
                  <span>{{chartitem[2].name}}</span>
                  <span>{{chartitem[2].value}}个</span>
                </div>
              </div>
            </div>
        </el-collapse-item>
        <el-collapse-item title="叠加图层" name="2">
          <div class="col-md-12 clearfix">
              <checkbox
                v-model="overLayer"
                label="建筑群落"
                v-on:input="!overLayer"
              >建筑群落</checkbox>
            </div>
        </el-collapse-item>
        <el-collapse-item title="数据筛选" name="3">
          <div class="col-md-12">
              <form method="get" action="/">
                <!-- <div class="row">
                  <div class="col-sm-10">
                    <el-select
                      class="select-default"
                      size="large"
                      placeholder="街道"
                      v-model="selects.street"
                      v-on:change="reloadData"
                    >
                      <el-option
                        v-for="option in selects.streets"
                        class="select-primary"
                        :value="option.id"
                        :label="option.name"
                        :key="option.id"
                      ></el-option>
                    </el-select>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-10">
                    <el-select
                      class="select-default"
                      size="large"
                      placeholder="社区"
                      v-model="selects.community"
                      v-on:change="reloadData"
                    >
                      <el-option
                        v-for="option in selects.communities"
                        class="select-primary"
                        :value="option.id"
                        :label="option.name"
                        :key="option.id"
                      ></el-option>
                    </el-select>
                  </div>
                </div> -->

                <div class="row">
                  <div class="col-sm-10">
                    <el-select
                      class="select-default"
                      size="large"
                      :placeholder="options[0].name"
                      v-model="options[0].selected"
                      v-on:change="reloadData"
                      multiple="" 
                    >
                      <el-option
                        v-for="(option,index) in options[0].data"
                        class="select-primary"
                        :value="option.id"
                        :label="option.name"
                        :key="option.id"
                      ></el-option>
                    </el-select>
                    <i class="el-icon-refresh-left" @click="clearAll(0)"></i>
                  </div>
                </div>
              </form>
            </div>
        </el-collapse-item>
      </el-collapse>

      
    </div>
    <div class="key-infos">
      <div class="key-info">
        节点{{statistics.node_count}}个，管线{{statistics.line_len}}km，服务区{{statistics.zone_count}}个
      </div>
    </div>
  </div>
</template>
<script>
import mapboxgl from "mapbox-gl";
import MenuMap from "src/components/Menumap";
import SearchArea from "src/components/SearchArea";
import MiniMap from "src/mapbox/mapboxgl-minimap.js";
import { TabPane, Tabs, Switch } from "src/components";
import geobuf from "geobuf";
import Pbf from "pbf";
import { Checkbox } from "src/components/index";
import { Input, Button, Select, Option, Collapse, CollapseItem, Table, TableColumn } from "element-ui";
import { consts } from "src/util/consts.js";
import {
  getStreets,
  getCommunities,
} from "src/api/building.js";
import {
  //getNodeTypeOptions as getOptions1,
  getCommonNodeGeojson as getNodeGeo,
  getCommonPipeGeojson as getPipeGeo,
  getCommonBlockGeojson as getBlockGeo,
  getBasicOptions as getOptions,
  getBasicNodeList as getList1,
  getBasicPipeList as getList2,
  getBasicBlockList as getList3,
  getBasicStatistics as getStatistics1,
  getBasicChart as getChart1,
  getBuildingGeojson,
  addHighLigthLayer
} from "src/api/earthquake/traffic.js";
import { 
  buildBarYOption,
  buildBarStackOption,
  buildlineOption,
  buildPieOption } from "src/util/chart.js";
import {
  storeMapOptions,
  getMapOptions,
  parseJsonToString,
} from "src/util/common.js";
import { Pagination as NPagination } from "src/components";
import * as THREE from "three";

export default {
  components: {
    MenuMap,
    SearchArea,
    TabPane,
    Tabs,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    NPagination,
    [Switch.name]: Switch,
    Checkbox,
  },
  data() {
    return {
      activeNames: ['1','2','3'],
      activeName: "first",
      tabs: {
        horizontal: "Profile",
        vertical: "Options",
        verticalPills: "Home",
        pageCategories: "Messages"
      },
      selects: {
        street: 0,
        streets: [{ id: 0, name: "街道" }],
        community: 0,
        communities: [{ id: 0, name: "社区" }],
        
      },
      keyword: "",
      total1: 0,
      total2: 0,
      total3: 0,
      data1: [],
      data2: [],
      data3: [],
      page: 1,
      map: {},
      color_category: "build_year",
      city_id: localStorage.getItem("city_id") || 1,
      show_list: false,
      show_list_height: 0,
      pagination1: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 20
      },
      pagination2: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 20
      },
      pagination3: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 20
      },
      last_updated: consts.last_updated,
      
      statistics: {
        year: 0
      },
      mapdefaultSetting: {},
      downloadUrl: '',
      overLayer: false, //叠加图层
      options: [
        {name: '数据类型',selected:[],data: []},
      ],
      colors: [
        ['#F5C243','#62BFF5','#D55328'],
      ],
      figures: [
        ['/img/icons/icon-light-green.png',  '/img/icons/icon-line-green.png','/img/icons/icon-block-green.png'],
      ],
      activeTab: '节点',
      chartitem: [
        {'name':'','value':''},
        {'name':'','value':''},
        {'name':'','value':''},
      ],
      // tab1: true,
      // tab2: true,
      // tab3: true,
      lineEventAdded: false,
      circleEventAdded: false,
      blockEventAdded: false,
      
     
      //colors: ['#D55328','#F5C243','#447DB3','#62BFF5','#70AD47','#94CFBF','#B22FB9','#9c0003']
    };
  },

  mounted() {
    //this.fillStreets(this.city_id);
    //this.fillCommunities(this.city_id, 0);
    this.showChart(this.city_id);
    this.fillFigure()
    //this.fillList(this.city_id, 1);
    this.initMap(this.city_id);
    this.setTipContent();
    this.map.on('style.load',()=>{
      //this.fillCommonGeo()
      this.reloadData()
      //this.fill2Map(this.city_id, this.color_category);
    })
  },
  computed: {
    to1() {
      let highBound = this.from1 + this.pagination1.perPage;
      if (this.total1 < highBound) {
        highBound = this.total1;
      }
      return highBound;
    },
    from1() {
      return this.pagination1.perPage * (this.pagination1.currentPage - 1);
    },
    to2() {
      let highBound = this.from2 + this.pagination2.perPage;
      if (this.total2 < highBound) {
        highBound = this.total2;
      }
      return highBound;
    },
    from2() {
      return this.pagination2.perPage * (this.pagination2.currentPage - 1);
    },
    to3() {
      let highBound = this.from3 + this.pagination3.perPage;
      if (this.total3 < highBound) {
        highBound = this.total3;
      }
      return highBound;
    },
    from3() {
      return this.pagination3.perPage * (this.pagination3.currentPage - 1);
    },
    street() {
      return this.selects.street;
    }
  },
  watch: {
    street(val) {
      this.fillCommunities(this.city_id, this.selects.street);
    },
    overLayer(val) {
        if(val){
            this.fillBuilding2Map(this.city_id,'building');
        } else {
            this.removeMapLayer(this.map, 'building')
        }
        
    },
    activeTab(val){
      let params = this.buildParams();
      if(val == '节点') {
        this.downloadUrl = consts.requestUrl + consts.exportTrafficBasic1 + parseJsonToString(params)
      } else if (val == '道路') {
         this.downloadUrl = consts.requestUrl + consts.exportTrafficBasic2 + parseJsonToString(params)
      } else if (val == '交通小区') {
         this.downloadUrl = consts.requestUrl + consts.exportTrafficBasic3 + parseJsonToString(params)
      }
    },
  },

  methods: {
    // 初始化
    initMap(city_id) {
      let _this = this;
      this.mapdefaultSetting = {
        center: consts.mapCenter[this.city_id-1],
        zoom: consts.mapDefaultZoom,
        pitch: 0,
        city_id: this.city_id
      }
      mapboxgl.accessToken = consts.mapboxAccessToken;
      let map_options = getMapOptions();
      //console.log(map_options);
      let zoom = map_options ? map_options.zoom : 12;
      let center = map_options
        ? [map_options.lng, map_options.lat]
        : consts.mapCenter[city_id - 1];
      var map = new mapboxgl.Map({
        container: "map",
        style: consts.mapBoxStyle,
        //center: [-87.61694, 41.86625],
        center: center,
        zoom: zoom,
        pitch: 0,
        bearing: 0,
        attributionControl: false
      });
      this.map = map;
      //定位图标
      map.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl
        })
      );
      this.map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: true
        })
      );
      //控制图层
      var nav = new mapboxgl.NavigationControl();
      this.map.addControl(nav, "top-right");
      //全屏按钮
      this.map.addControl(
        new mapboxgl.FullscreenControl({
          container: document.querySelector("body")
        })
      );
      //小地图
      //https://github.com/brendanmatkin/mapboxgl-minimap
      map.on("style.load", function() {
        // Possible position values are 'bottom-left', 'bottom-right', 'top-left', 'top-right'
        map.addControl(
          new MiniMap({
            id: "mapboxgl-minimap",
            width: "200px",
            height: "150px",
            //style: "mapbox://styles/mapbox/streets-v10",
            style: consts.mapBoxStyle,
            fillColor: "#f58220",
            zoomAdjust: null
          }),
          "bottom-right"
        );
        // map.loadImage('/img/icons/icon-red.png', function(error, image) {
        //     if (error) throw error;
        //     map.addImage('icon-1', image);
        // });
        _this.makePointImage(map, 1)
        map.loadImage('/img/icons/icon-benzhan.png', function(error, image) {
            if (error) throw error;
            map.addImage('icon-2', image);
        });
        // map.loadImage('/img/icons/icon-yellow.png', function(error, image) {
        //     if (error) throw error;
        //     map.addImage('icon-3', image);
        // });
        _this.makePointImage(map, 3)
        map.loadImage('/img/icons/icon-shuita.png', function(error, image) {
            if (error) throw error;
            map.addImage('icon-4', image);
        });
      });
      //记录地图设置
      map.on("move", function() {
        storeMapOptions({
          lng: map.getCenter().lng,
          lat: map.getCenter().lat,
          zoom: map.getZoom()
        });
      });
    },
    fillFigure(){
      //数据类型
      getOptions().then(res => {
        let data = res.data;
        this.options[0].data = data;
        this.options[0].data.forEach((item,idx)=>{
          item.icon = this.figures[0][idx]
          item.color = this.colors[0][idx]
        })
      });
    },
    fillCommonGeo(){
        let params = this.buildParams();
        getBlockGeo(params).then(res => {
          let data = res;
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addBlockLayerToMap(this.map, 'blockCommon', geoJson);    
        });
        getPipeGeo(params).then(res => {
          let data = res;
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addLineLayerToMap(this.map, 'pipeCommon', geoJson);    
        });
         getNodeGeo(params).then(res => {
          let data = res;
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addCircle2Map(this.map, 'nodeCommon', geoJson);    
        });
        
        
    },
    addBuildings2Map(map, geoJsonData, type) {
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      
      map.addSource(source_name, {
        type: "geojson",
        data: geoJsonData
      });
      map.addLayer({
        id: layer_id,
        type: "fill-extrusion",
        source: source_name,
        paint: {
          // See the Mapbox Style Specification for details on data expressions.
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions

          // Get the fill-extrusion-color from the source 'color' property.
          "fill-extrusion-color": "#789da5",

          // Get fill-extrusion-height from the source 'height' property.
          "fill-extrusion-height": ["get", "height"],

          // Get fill-extrusion-base from the source 'base_height' property.
          "fill-extrusion-base": ["get", "base_height"],

          // Make extrusions slightly opaque for see through indoor walls.
          "fill-extrusion-opacity": 0.8
        }
      });
    },
    makePointImage(map, type){
      let size = 150;
      switch(type){
        case 1: //红点
          size = 200;
        break;
        case 3:
          size = 100;
        break;
      }
      //point type
      let pulsingDot  = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // get rendering context for the map canvas when layer is added to the map
        onAdd: function() {
          var canvas = document.createElement("canvas");
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext("2d");
        },

        // called once before every frame where the icon will be used
        render: function() {
          var duration = 1000;
          var t = (performance.now() % duration) / duration;

          var radius = (size / 2) * 0.3;
          var outerRadius = (size / 2) * 0.7 * t + radius;
          var context = this.context;

          // draw outer circle
          if(type == 1) {
            context.clearRect(0, 0, this.width, this.height);
            context.beginPath();
            context.arc(
              this.width / 2,
              this.height / 2,
              outerRadius,
              0,
              Math.PI * 2
            );
            switch(type){
              case 1:
                context.fillStyle = "rgba(213, 83, 40," + (1 - t) + ")";
              break;
              case 3:
                context.fillStyle = "rgba(245, 194, 67, 1)";
                break;
            }
            
            context.fill();
          }

          // draw inner circle
          context.beginPath();
          context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
          switch(type){
            case 1:
              context.fillStyle = "rgba(213, 83, 40, 1)";
            break;
            case 3:
              context.fillStyle = "rgba(246, 194, 67, 1)";
            break;
          }
          
          context.strokeStyle = "white";
          context.lineWidth = 2 + 4 * (1 - t);
          context.fill();
          //context.stroke();
          // update this image's data with data from the canvas
          this.data = context.getImageData(0, 0, this.width, this.height).data;

          // continuously repaint the map, resulting in the smooth animation of the dot
          map.triggerRepaint();

          // return `true` to let the map know that the image was updated
          return true;
        }
      }
      map.addImage("icon-"+type, pulsingDot, { pixelRatio: 2 });
    },
    addPointLayerToMap(map,type, geojson) {
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      if(typeof geojson.features == 'undefined') return;
      //let geojson_url = consts.url_geojson_quake_history;
      if(!map.hasImage("dot-1")) {
        this.makePointImage(map, 1) //<4
        this.makePointImage(map, 2) //4-5
        this.makePointImage(map, 3) //5-6
        this.makePointImage(map, 4) //6-7
        this.makePointImage(map, 5) //>7
        
      }


      geojson.features.forEach((item,idx)=>{
        if(item.properties.level <= 4){
          item.properties.type = 1
        } else if(item.properties.level <= 5){
          item.properties.type = 2
        }else if(item.properties.level <= 6){
          item.properties.type = 3
        }else if(item.properties.level <= 7){
          item.properties.type = 4
        }else {
          item.properties.type = 5
        }
      })

      map.addSource(source_name, {
        type: "geojson",
        data: geojson
       }
      );
      map.addLayer({
        id: layer_id,
        type: "symbol",
        source: source_name,
        paint: {
          "text-color": "#ffffff"
        },
        layout: {
          "icon-image": ["concat", "dot-", ["get","type"]],
          "text-field": ["get", "region"],
          "text-size": 12,
          "text-offset": [0, -3],
          "text-anchor": "top"
        }
      });
    },
    addImageLayerToMap(map,type, geojson) {
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      if(typeof geojson.features == 'undefined') return;
      //let geojson_url = consts.url_geojson_quake_history;
      

      map.addSource(source_name, {
        type: "geojson",
        data: geojson
       }
      );
      map.addLayer({
        id: layer_id,
        type: "symbol",
        source: source_name,
        paint: {
          "text-color": "#ffffff",
          //"icon-opacity": 1,
        },
        layout: {
          "icon-image": ["concat", "icon-", ["get","type_id"]],
          "icon-size": 0.5,
          "text-field": ["get", "region"],
          "text-size": 12,
          "text-offset": [0, -3],
          "text-anchor": "top",
          "icon-allow-overlap": true,
          
        }
      });
    },
    addLineLayerToMap(map, type, geojson) {
      let _this = this;
      this.removeMapLayer(map,type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      let layer_bg_id = this.getMapLayerId(type)+'_bg';
      let linecolor = ''
      if(typeof geojson.features == 'undefined') return;

      map.addSource(source_name, {
          type: "geojson",
          data: geojson
        });
        //line cap: butt, round, square
        //line join :bevel, round, miter
        map.addLayer({
          id: layer_bg_id,
          type: "line",
          source: source_name,
          "layout": {
            "line-join": "round",
            "line-cap": "round"
          },
            "paint": {
            "line-color": "rgba(97,129,84,0.6)",
            "line-width": 12
          }
        });
        map.addLayer({
          id: layer_id,
          type: "line",
          source: source_name,
          "layout": {
            "line-join": "round",
            "line-cap": "round"
          },
            "paint": {
            "line-color": "#ade595",
            "line-width": 1
          }
        });
       this.setMapLayer()
       map.setPaintProperty(layer_bg_id, 'line-width', [
          "interpolate",
          ["exponential", 0.5],
          ["zoom"],
          10, //缩放比例
          2, //值
          12,
          12, //值
          22,
          12
        ]);
        
      if(!_this.lineEventAdded) {
         _this.lineEventAdded = true;
        this.map.on("click", layer_bg_id, function(e) {
          console.log('line click')
          if(e.features.length == 0) return;
          addHighLigthLayer('line',e.features[0], _this)
          var coordinates = e.features[0].geometry.coordinates.slice();
          var html = "路段编号："+e.features[0].properties.id+"<br>道路名称："+e.features[0].properties.road_name+"<br>道路等级："+e.features[0].properties.road_level+"<br>道路长度："+e.features[0].properties.road_len+"m<br>自由流速："+e.features[0].properties.free_flow_speed+"km/h<br>通行能力："+e.features[0].properties.capacity+"辆/小时<br>交通流量："+e.features[0].properties.flow+"辆/小时";
          _this.popup = new mapboxgl.Popup({closeOnClick: false,className: 'map-popup'})
          .setLngLat(e.lngLat)
          .setHTML(html)
          .addTo(_this.map);

            _this.popup.on('close', ()=>{
                _this.popup = null;
              
              if(_this.map.getLayer('highlight')) {
                _this.map.removeLayer('highlight');
                _this.map.removeSource('highlight');
              } 
            })

        });
      }
    },
    addBlockLayerToMap(map,type, geojson) {
      let _this = this;
      this.removeMapLayer(map,type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      let layer_id_stroke = layer_id+'_stroke';
      if(typeof geojson.features == 'undefined') return;
      map.addSource(source_name, {
        type: "geojson",
        data: geojson
      });
      map.addLayer({
        id: layer_id,
        type: "fill",
        source: source_name,
        'paint': {
          'fill-color': "#365030",
          'fill-opacity': 0.4
        }
      });
      //描边
      map.addLayer({
        id: layer_id_stroke,
        type: "line",
        source: source_name,
        layout: {},
        paint: {
          "line-color": '#618154',
          "line-width": 1
        }
      });
      this.setMapLayer()
      if(!_this.blockEventAdded) {
         _this.blockEventAdded = true;
        this.map.on("click", layer_id, function(e) {
          console.log('block click')
          if(e.features.length == 0) return;
          addHighLigthLayer('block',e.features[0], _this)
          var coordinates = e.features[0].geometry.coordinates.slice();
          var html = "交通小区ID："+e.features[0].properties.id+"<br>交通小区名："+e.features[0].properties.name+"<br>交通流量："+e.features[0].properties.flow+"辆/小时";
          _this.popup = new mapboxgl.Popup({closeOnClick: false,className: 'map-popup'})
          .setLngLat(e.lngLat)
          .setHTML(html)
          .addTo(_this.map);
          _this.popup.on('close', ()=>{
                _this.popup = null;
              
              if(_this.map.getLayer('highlight')) {
                _this.map.removeLayer('highlight');
                _this.map.removeSource('highlight');
              } 
            })

        });
      }
    },
    addCircle2Map(map,type, geoJsonData) {
      let _this = this;
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      let layer_bg_id = this.getMapLayerId(type)+'_bg';
      if(typeof geoJsonData.features == 'undefined') return;
      
        
      map.addSource(source_name, {
        type: "geojson",
        data: geoJsonData
      });
      map.addLayer({
        id: layer_bg_id,
        type: "circle",
        source: source_name,
        paint: {
          // make circles larger as the user zooms from z12 to z22
          'circle-radius': 6,
          // color circles by ethnicity, using a match expression
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-match
          'circle-color': "#ade595",
          'circle-stroke-color': '#000000',
          'circle-stroke-width': 1
        }
      });
      map.addLayer({
        id: layer_id,
        type: "circle",
        source: source_name,
        paint: {
          // make circles larger as the user zooms from z12 to z22
          'circle-radius': 2,
          // color circles by ethnicity, using a match expression
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-match
          'circle-color': "#ade595",
          'circle-stroke-color': '#000000',
          'circle-stroke-width': 0
        }
      });
      this.setMapLayer()
      map.setPaintProperty(layer_bg_id, 'circle-radius', [
        "interpolate",
        ["exponential", 0.5],
        ["zoom"],
        10, //缩放比例
        2, //值
        12,
        6, //值
        22,
        8
      ]);
      if(!_this.circleEventAdded) {
         _this.circleEventAdded = true;
         this.map.on("click", layer_bg_id, function(e) {
          console.log('circle click')
          
          if(e.features.length == 0) return;
          addHighLigthLayer('node',e.features[0], _this)
          var coordinates = e.features[0].geometry.coordinates.slice();
          var html = "节点ID："+e.features[0].properties.id+"<br>地址："+e.features[0].properties.crossing_road+"<br>节点进流量："+(e.features[0].properties.in_flow==0?'-':e.features[0].properties.in_flow+'辆/小时')+"<br>节点出流量："+(e.features[0].properties.out_flow==0?'-':e.features[0].properties.out_flow+'辆/小时');
          _this.popup = new mapboxgl.Popup({closeOnClick: false,className: 'map-popup'})
          .setLngLat(e.features[0].geometry.coordinates)
          .setHTML(html)
          .addTo(_this.map);

          _this.popup.on('close', ()=>{
                _this.popup = null;
              
              if(_this.map.getLayer('highlight')) {
                _this.map.removeLayer('highlight');
                _this.map.removeSource('highlight');
              } 
            })
        });
      }
      
      
      

    },
    setMapLayer(){
      if (this.map.getLayer(this.getMapLayerId('blockCommon')) && this.map.getLayer(this.getMapLayerId('pipeCommon'))  && this.map.getLayer(this.getMapLayerId('nodeCommon'))) {
          if (this.map.getLayer('stroke')) {
            this.map.moveLayer(this.getMapLayerId('nodeCommon'), 'stroke');
          }
          //block移到节点后
          this.map.moveLayer(this.getMapLayerId('blockCommon'), this.getMapLayerId('nodeCommon')+'_bg');
          this.map.moveLayer(this.getMapLayerId('blockCommon')+'_stroke', this.getMapLayerId('nodeCommon')+'_bg');
          // this.map.moveLayer(this.getMapLayerId('blockCommon'), this.getMapLayerId('nodeCommon'));
          // this.map.moveLayer(this.getMapLayerId('blockCommon')+'_stroke', this.getMapLayerId('nodeCommon'));


           //管道放到节点后
          this.map.moveLayer(this.getMapLayerId('pipeCommon')+'_bg', this.getMapLayerId('nodeCommon')+'_bg');
          this.map.moveLayer(this.getMapLayerId('pipeCommon'), this.getMapLayerId('nodeCommon')+'_bg')
          // this.map.moveLayer(this.getMapLayerId('pipeCommon')+'_bg', this.getMapLayerId('nodeCommon'));
          // this.map.moveLayer(this.getMapLayerId('pipeCommon'), this.getMapLayerId('nodeCommon'));
          //管道自己放到自己背景后
          //this.map.moveLayer(this.getMapLayerId('pipeCommon')+'_bg', this.getMapLayerId('pipeCommon'));

          
          //block放到管道后
          this.map.moveLayer(this.getMapLayerId('blockCommon'), this.getMapLayerId('pipeCommon')+'_bg');
          this.map.moveLayer(this.getMapLayerId('blockCommon')+'_stroke', this.getMapLayerId('pipeCommon')+'_bg');
          // this.map.moveLayer(this.getMapLayerId('blockCommon'), this.getMapLayerId('pipeCommon'));
          // this.map.moveLayer(this.getMapLayerId('blockCommon')+'_stroke', this.getMapLayerId('pipeCommon'));
          //block放到自己轮廓后
          this.map.moveLayer(this.getMapLayerId('blockCommon'), this.getMapLayerId('blockCommon')+'_stroke');
          
         
          

          //this.map.moveLayer(this.getMapLayerId('nodeCommon')+'_bg', this.getMapLayerId('nodeCommon'));
          
          return;
      }
      if (this.map.getLayer(this.getMapLayerId('blockCommon')) && this.map.getLayer(this.getMapLayerId('pipeCommon'))) {
          if (this.map.getLayer('stroke')) {
            this.map.moveLayer(this.getMapLayerId('pipeCommon'), 'stroke');
          }
          this.map.moveLayer(this.getMapLayerId('blockCommon'), this.getMapLayerId('pipeCommon')+'_bg');
          this.map.moveLayer(this.getMapLayerId('blockCommon')+'_stroke', this.getMapLayerId('pipeCommon')+'_bg');
          this.map.moveLayer(this.getMapLayerId('blockCommon'), this.getMapLayerId('pipeCommon'));
          this.map.moveLayer(this.getMapLayerId('blockCommon')+'_stroke', this.getMapLayerId('pipeCommon'));

          this.map.moveLayer(this.getMapLayerId('pipeCommon')+'_bg', this.getMapLayerId('pipeCommon'));
          this.map.moveLayer(this.getMapLayerId('blockCommon'), this.getMapLayerId('blockCommon')+'_stroke');
          return;
      }
      if (this.map.getLayer(this.getMapLayerId('blockCommon')) && this.map.getLayer(this.getMapLayerId('nodeCommon'))) {
          if (this.map.getLayer('stroke')) {
            this.map.moveLayer(this.getMapLayerId('nodeCommon'), 'stroke');
          }
          this.map.moveLayer(this.getMapLayerId('blockCommon'), this.getMapLayerId('nodeCommon')+'_bg');
          this.map.moveLayer(this.getMapLayerId('blockCommon')+'_stroke', this.getMapLayerId('nodeCommon')+'_bg');
          this.map.moveLayer(this.getMapLayerId('blockCommon'), this.getMapLayerId('nodeCommon'));
          this.map.moveLayer(this.getMapLayerId('blockCommon')+'_stroke', this.getMapLayerId('nodeCommon'));

          this.map.moveLayer(this.getMapLayerId('nodeCommon')+'_bg', this.getMapLayerId('nodeCommon'));
          this.map.moveLayer(this.getMapLayerId('blockCommon'), this.getMapLayerId('blockCommon')+'_stroke');
          return;
      }
      if (this.map.getLayer(this.getMapLayerId('pipeCommon')) && this.map.getLayer(this.getMapLayerId('nodeCommon'))) {
        if (this.map.getLayer('stroke')) {
          this.map.moveLayer(this.getMapLayerId('nodeCommon'), 'stroke');
        } 

        this.map.moveLayer(this.getMapLayerId('pipeCommon')+'_bg', this.getMapLayerId('nodeCommon')+'_bg');
        this.map.moveLayer(this.getMapLayerId('pipeCommon'), this.getMapLayerId('nodeCommon')+'_bg')
        this.map.moveLayer(this.getMapLayerId('pipeCommon')+'_bg', this.getMapLayerId('nodeCommon'));
        this.map.moveLayer(this.getMapLayerId('pipeCommon'), this.getMapLayerId('nodeCommon'));

        this.map.moveLayer(this.getMapLayerId('pipeCommon')+'_bg', this.getMapLayerId('pipeCommon'));
        this.map.moveLayer(this.getMapLayerId('nodeCommon')+'_bg', this.getMapLayerId('nodeCommon'));

        return;
     }
     if (this.map.getLayer('stroke')) {
       if (this.map.getLayer(this.getMapLayerId('pipeCommon'))) {
         this.map.moveLayer(this.getMapLayerId('pipeCommon'), 'stroke');
       }
        if (this.map.getLayer(this.getMapLayerId('nodeCommon'))) {
         this.map.moveLayer(this.getMapLayerId('nodeCommon'), 'stroke');
       }
       if (this.map.getLayer(this.getMapLayerId('blockCommon'))) {
         this.map.moveLayer(this.getMapLayerId('blockCommon')+'_stroke', 'stroke');
         this.map.moveLayer(this.getMapLayerId('blockCommon'), 'stroke');
       }
     } 
    },
    showList() {
      this.show_list = !this.show_list;
    },
    donwloadList() {},
    showChart(city_id) {
      var self = this;
      // var container = document.querySelector('.el-collapse');
      // var box = document.getElementById("box-chart");
      // var myChart = this.$echarts.init(box);
      // box.style.width = container.offsetWidth + "px";
      getChart1(city_id).then(res => {
        var chart_data = Array.from(res.data).reverse();
        var chart_name = "节点/道路/交通小区 统计";
        var colors = Array.from(self.colors[0]).reverse()
        self.chartitem = res.data;
        // chart_data.map((item,idx) => {
        //   return item.color = colors[idx]
        // })

        // var option = buildBarYOption(chart_name, chart_data,'name',{
        //   tooltip: {
        //     trigger: 'item',
        //     confine: true,
        //     formatter: function(a) {
        //         var b = "";
        //         console.log(a)
        //         if(a.name == '管网长度') {
        //           b = a.name + ':' + a.data + '千米'
        //         } else {
        //           b = a.name + ':' + a.data +'个'
        //         }
        //         return b
        //     }
        //   },
        // });
        // myChart.setOption(option);


       
      });

      

      // window.onresize = function() {
      //   document.querySelectorAll('.chart-area').forEach((item,idx)=>{
      //     item.style.width = document.querySelector('.el-collapse').offsetWidth+'px';
      //   })
      //   myChart.resize(); 
      // }
    },
    fillStreets(city_id) {
      getStreets(city_id).then(res => {
        let data = res.data;
        data.unshift({ id: 0, name: "街道" });
        this.selects.streets = data;
      });
    },
    fillCommunities(city_id, street_id) {
      getCommunities(city_id, street_id).then(res => {
        let data = res.data;
        data.unshift({ id: 0, name: "社区" });
        this.selects.communities = data;
      });
    },
    fillList1(city_id, page) {
      let params = this.buildParams();
      params.page = page;
      getList1(params).then(res => {
        this.total1 = res.data.total;
        this.data1 = res.data.items;
      });
      getStatistics1(params).then(res => {
        this.statistics = res.data;
      });
      this.downloadUrl = consts.requestUrl + consts.exportTrafficBasic1 + parseJsonToString(params)
      
    },
    fillList2(city_id, page) {
      let params = this.buildParams();
      params.page = page;
      getList2(params).then(res => {
        this.total2 = res.data.total;
        this.data2 = res.data.items;
      });
    },
    fillList3(city_id, page) {
      let params = this.buildParams();
      params.page = page;
      getList3(params).then(res => {
        this.total3 = res.data.total;
        this.data3 = res.data.items;
      });
     
    },
    fill2Map(city_id) {
      let _this = this
      let params = this.buildParams();
      this.removeMapLayer(this.map, 'nodeCommon')
      this.removeMapLayer(this.map, 'pipeCommon')
      this.removeMapLayer(this.map, 'blockCommon')
      if(this.options[0].selected.length == 0) {
          getBlockGeo(params).then(res => {
            let data = res;
            let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
            _this.addBlockLayerToMap(_this.map, 'blockCommon', geoJson); 
            getPipeGeo(params).then(res => {
              let data = res;
              let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
              _this.addLineLayerToMap(_this.map, 'pipeCommon', geoJson);   
              
              getNodeGeo(params).then(res => {
                let data = res;
                let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
                _this.addCircle2Map(_this.map, 'nodeCommon', geoJson);    
              });
            });

          });
          /*  getPipeGeo(params).then(res => {
            let data = res;
            let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
            this.addLineLayerToMap(this.map, 'pipeCommon', geoJson);    
          });
           getNodeGeo(params).then(res => {
            let data = res;
            let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
            this.addCircle2Map(this.map, 'nodeCommon', geoJson);    
          }); */
      } else {
        this.options[0].selected.forEach((item,idx)=>{
          if(item == 1) {
            getNodeGeo(params).then(res => {
                let data = res;
                let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
                this.addCircle2Map(this.map, 'nodeCommon', geoJson);    
              });
            
          } else if(item == 2) {
            getPipeGeo(params).then(res => {
                let data = res;
                let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
                this.addLineLayerToMap(this.map, 'pipeCommon', geoJson);    
              });
          }  else if(item == 3) {
              getBlockGeo(params).then(res => {
                let data = res;
                let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
                this.addBlockLayerToMap(this.map, 'blockCommon', geoJson);    
              });
          } 
        })
      }
      
        
        
        
      
      
    },
    fillBuilding2Map(city_id,type) {
      let params = this.buildParams();
      getBuildingGeojson(params).then(res => {
        let data = res;
        let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        this.addBuildings2Map(this.map, geoJson, type);
      });
    },
    reloadData() {
      this.options[0].data.forEach((item,idx)=>{
        //this['tab'+item.id] = false;
        document.querySelectorAll('.show_list_record .nav-item').forEach((elem,index)=>{
          elem.style.display = 'none';
        })
      })
      if(this.options[0].selected.length == 0 || this.options[0].selected.length == 3) {
        document.querySelectorAll('.show_list_record .nav-item').forEach((elem,index)=>{
          elem.style.display = 'block';
        })
      } else {
        let tmp = 0;
        this.options[0].selected.forEach((item,idx)=>{
          if(tmp == 0) {
            this.activeTab = item==1?"节点":item==2?"管道":"交通小区"
            tmp++;
          }
          document.querySelectorAll('.show_list_record .nav-item').forEach((elem,index)=>{
            if(item == (index+1)) {
              elem.style.display = 'block';
              
            }
          })
        })
      }
      
      this.fillList1(this.city_id, 1);
      this.fillList2(this.city_id, 1);
      this.fillList3(this.city_id, 1);
      this.fill2Map(this.city_id);
    },
    setTipContent() {
      let html = consts.tipsTrafficBasic;
      document.getElementById('left-tip-content').innerHTML = html
    },
    buildParams() {
      let params = { city_id: this.city_id };
      //params.street_id = this.selects.street;
      //params.category_id = this.selects.category;
      //params.community_id = this.selects.community;
      //params.struct_id = this.selects.struct;
      //params.keyword = this.keyword;
      //params.quake_level = this.selects.quakeLevel;
      params.page_size = 10;
      return params;
    },
    changePage1: function(value) {
      this.fillList1(this.city_id, value);
    },
    changePage2: function(value) {
      this.fillList2(this.city_id, value);
    },
    changePage3: function(value) {
      this.fillList3(this.city_id, value);
    },
    getMapLayerId(type) {
      let layer_id = type + "-layer";
      return layer_id;
    },
    getMapSourceName(type) {
      let source_name = type + "-data";
      return source_name;
    },
    removeMapLayer(map, type) {
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      var layer = map.getLayer(layer_id);
      let layer_point_id = layer_id + "point";
      //描边
      var layer_stroke = map.getLayer(layer_id+'_stroke');
      //背景
      var layer_bg = map.getLayer(layer_id+'_bg');
      //热点图的热点层
      if (map.getLayer(layer_point_id)) {
        map.removeLayer(layer_point_id);
      }
      if(layer_stroke){
          map.removeLayer(layer_id+'_stroke');
        }
      if(layer_bg){
          map.removeLayer(layer_id+'_bg');
        }
      if (layer) {
        map.removeLayer(layer_id);
      }
      if(map.getSource(source_name)) {
        map.removeSource(source_name);
      } 
    },
    clearAll(idx) {
      this.options[idx].selected = [];
      this.reloadData();
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.show_list_record .nav {
  width: 100%;
}
.chart-item {
  display: flex;
  margin-bottom: 15px;
}
.chart-item img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.chart-item div {
  display: flex;
  flex-direction: column;
}
.chart-item span:nth-of-type(1) {
  font-size: 12px;
}
.chart-item span:nth-of-type(2) {
  font-size: 14px;
}
.item-1 span:nth-of-type(2) {
  color: #b8e39d;
}

.item-2 span:nth-of-type(2) {
  color: #70ae47;
}
.item-3 span:nth-of-type(2) {
  color: #e3eeda;
}
</style>
