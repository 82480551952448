import request from '@/util/request';


export function addHighLigthLayer(type, feature, self )
{   
  if(self.popup){
    self.popup.remove();
    self.popup = null;
  }
  
  // if(self.map.getLayer('highlight')) {
  //   self.map.removeLayer('highlight');
  //   self.map.removeSource('highlight');
  // } 

  if(type == 'node') {
    self.map.addSource('highlight', {
      type: "geojson",
      data: feature
    });
    self.map.addLayer({
      id: 'highlight',
      type: "circle",
      source: 'highlight',
      paint: {
        // make circles larger as the user zooms from z12 to z22
        'circle-radius': 12,
        // color circles by ethnicity, using a match expression
        // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-match
        'circle-color': "rgba(213,83,40,0.8)",
        'circle-stroke-color': '#000000',
        'circle-stroke-width': 0
      }
    });
  } else if (type == 'line') {
    self.map.addSource('highlight', { 
      type: "geojson",
      data: feature
    });
    self.map.addLayer({
      id: 'highlight',
      type: "line",
      source: 'highlight',
      "layout": {
        "line-join": "round",
        "line-cap": "round"
      },
        "paint": {
        "line-color": "rgba(213,83,40,0.8)",
        "line-width": 12
      }
    });
  } else {
    self.map.addSource('highlight', {
      type: "geojson",
      data: feature
    });
    self.map.addLayer({
      id: 'highlight',
      type: "fill",
      source: 'highlight',
      'paint': {
        'fill-color': "rgba(213,83,40,0.8)",
        'fill-opacity': 0.8
      }
    });
  }
}



export function getCommonNodeGeojson(params) {
  return request({
    url: 'api/powers/geo-node',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}
export function getCommonPipeGeojson(params) {
  return request({
    url: 'api/powers/geo-line',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}
export function getCommonBlockGeojson(params) {
  return request({
    url: 'api/powers/geo-zone',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

//2.1.1

export function getBasicOptions(params) {
  return request({
    url: 'api/powers/list',
    method: 'get',
    params: params
  })
}
export function getBasicNodeList(params) {
  return request({
    url: 'api/powers/node',
    method: 'get',
    params: params
  })
}
export function getBasicPipeList(params) {
  return request({
    url: 'api/powers/line',
    method: 'get',
    params: params
  })
}
export function getBasicBlockList(params) {
  return request({
    url: 'api/powers/zone',
    method: 'get',
    params: params
  })
}

export function getBuildingGeojson(params) {

  params.format = 'geobuf';
  return request({
    url: 'api/buildings-geo-json',
    method: 'get',
    responseType: 'arraybuffer',
    params: params
  })
}




export function getBasicStatistics(params) {
  return request({
    url: 'api/powers/statistics',
    method: 'get',
    params: params,
  })
}

export function getBasicChart(city_id) {
  return request({
    url: 'api/powers/show?city_id='+city_id,
    method: 'get'
  })
}




//2.1.2

export function getNodeTypeOptions(params) {
  return request({
    url: 'api/power-node-types/list',
    method: 'get',
    params: params
  })
}

export function getNodeTypeList(params) {
  return request({
    url: 'api/power-node-types',
    method: 'get',
    params: params
  })
}

export function getNodeTypeNodeGeojson(params) {

  params.format = 'geobuf';
  return request({
    url: 'api/power-node-types/geo',
    method: 'get',
    responseType: 'arraybuffer',
    params: params
  })
}


export function getNodeTypeStatistics(params) {

  return request({
    url: 'api/power-node-types/statistics',
    method: 'get',
    params: params,
  })
}

export function getNodeTypeChart(city_id) {
  return request({
    url: 'api/power-node-types/show?city_id='+city_id,
    method: 'get'
  })
}

//2.1.2-2

export function getNodeVoltagesOptions(params) {
  return request({
    url: 'api/power-node-voltages/list',
    method: 'get',
    params: params
  })
}

export function getNodeVoltagesList(params) {
  return request({
    url: 'api/power-node-voltages',
    method: 'get',
    params: params
  })
}


export function getNodeVoltagesNodeGeojson(params) {
  return request({
    url: 'api/power-node-voltages/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getNodeVoltagesStatistics(params) {

  return request({
    url: 'api/power-node-voltages/statistics',
    method: 'get',
    params: params,
  })
}

export function getNodeVoltagesChart(city_id) {
  return request({
    url: 'api/power-node-voltages/show?city_id='+city_id,
    method: 'get'
  })
}



//2.1.3

export function getPipeVoltagesOptions(params) {
  return request({
    url: 'api/power-line-voltages/list',
    method: 'get',
    params: params
  })
}

export function getPipeVoltagesList(params) {
  return request({
    url: 'api/power-line-voltages',
    method: 'get',
    params: params
  })
}

export function getPipeVoltagesPipeGeojson(params) {
  return request({
    url: 'api/power-line-voltages/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}


export function getPipeVoltagesStatistics(params) {

  return request({
    url: 'api/power-line-voltages/statistics',
    method: 'get',
    params: params,
  })
}

export function getPipeVoltagesChart(city_id) {
  return request({
    url: 'api/power-line-voltages/show?city_id='+city_id,
    method: 'get'
  })
}


//2.1.4

export function getVolumnOptions(params) {
  return request({
    url: 'api/power-zones/list',
    method: 'get',
    params: params
  })
}

export function getVolumnList(params) {
  return request({
    url: 'api/power-zones',
    method: 'get',
    params: params
  })
}

export function getVolumnGeojson(params) {
  return request({
    url: 'api/power-zones/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getVolumnStatistics(params) {

  return request({
    url: 'api/power-zones/statistics',
    method: 'get',
    params: params,
  })
}

export function getVolumnChart(city_id) {
  return request({
    url: 'api/power-zones/show?city_id='+city_id,
    method: 'get'
  })
}



//2.2.1-1

export function getFunctionlossNodeOptions(params) {
  return request({
    url: 'api/power-node-reliabilities/list',
    method: 'get',
    params: params
  })
}

export function getFunctionlossNodeList(params) {
  
  return request({
    url: 'api/power-node-reliabilities',
    method: 'get',
    params: params
  })
}

export function getFunctionlossNodeGeojson(params) {
  return request({
    url: 'api/power-node-reliabilities/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getFunctionlossNodeStatistics(params) {
  return request({
    url: 'api/power-node-reliabilities/statistics',
    method: 'get',
    params: params,
  })
}

export function getFunctionlossNodeChart(city_id) {
  return request({
    url: 'api/power-node-reliabilities/show?city_id='+city_id,
    method: 'get'
  })
}


//2.2.1-2

export function getFunctionlossRatiosOptions(params) {
  return request({
    url: 'api/power-community-ratios/list',
    method: 'get',
    params: params
  })
}

export function getFunctionlossRatiosList(params) {
  
  return request({
    url: 'api/power-community-ratios',
    method: 'get',
    params: params
  })
}

export function getFunctionlossRatiosGeojson(params) {
  return request({
    url: 'api/power-community-ratios/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getFunctionlossRatiosStatistics(params) {
  return request({
    url: 'api/power-community-ratios/statistics',
    method: 'get',
    params: params,
  })
}
export function getFunctionlossRatiosChart(city_id) {
  return request({
    url: 'api/power-community-ratios/show?city_id='+city_id,
    method: 'get'
  })
}


//2.2.3

export function getImportanceOptions(params) {
  return request({
    url: 'api/power-node-importances/list',
    method: 'get',
    params: params
  })
}

export function getImportanceList(params) {
  
  return request({
    url: 'api/power-node-importances',
    method: 'get',
    params: params
  })
}

export function getImportanceGeojson(params) {
  return request({
    url: 'api/power-node-importances/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getImportanceStatistics(params) {
  return request({
    url: 'api/power-node-importances/statistics',
    method: 'get',
    params: params,
  })
}

export function getImportanceChart(city_id) {
  return request({
    url: 'api/power-node-importances/show?city_id='+city_id,
    method: 'get'
  })
}


//2.3.1

export function getReinforceOptions(params) {
  return request({
    url: 'api/power-node-reinforcements/list',
    method: 'get',
    params: params
  })
}

export function getReinforceList(params) {
  
  return request({
    url: 'api/power-node-reinforcements',
    method: 'get',
    params: params
  })
}

export function getReinforceGeojson(params) {
  return request({
    url: 'api/power-node-reinforcements/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getReinforceStatistics(params) {
  return request({
    url: 'api/power-node-reinforcements/statistics',
    method: 'get',
    params: params,
  })
}

export function getReinforceChart(city_id) {
  return request({
    url: 'api/power-node-reinforcements/show?city_id='+city_id,
    method: 'get'
  })
}

//2.3.2-1

// export function getRepairLineOptions(params) {
//   return request({
//     url: 'api/water-line-repairs/list',
//     method: 'get',
//     params: params
//   })
// }

// export function getRepairLineList(params) {
  
//   return request({
//     url: 'api/water-line-repairs',
//     method: 'get',
//     params: params
//   })
// }

// export function getRepairLineGeojson(params) {
//   return request({
//     url: 'api/water-line-repairs/geo',
//     method: 'get',
//     params: params,
//     responseType: 'arraybuffer'
//   })
// }

// export function getRepairLineStatistics(params) {
//   return request({
//     url: 'api/water-line-repairs/statistics',
//     method: 'get',
//     params: params,
//   })
// }

// export function getRepairLineChart(city_id) {
//   return request({
//     url: 'api/water-line-repairs/show?city_id='+city_id,
//     method: 'get'
//   })
// }


// //
export function getRepairNodeOptions(params) {
  return request({
    url: 'api/power-node-repairs/list',
    method: 'get',
    params: params
  })
}

export function getRepairNodeList(params) {
  
  return request({
    url: 'api/power-node-repairs',
    method: 'get',
    params: params
  })
}

export function getRepairNodeGeojson(params) {
  return request({
    url: 'api/power-node-repairs/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getRepairNodeStatistics(params) {
  return request({
    url: 'api/power-node-repairs/statistics',
    method: 'get',
    params: params,
  })
}

export function getRepairNodeChart(city_id) {
  return request({
    url: 'api/power-node-repairs/show?city_id='+city_id,
    method: 'get'
  })
}


//2.3.2-2

export function getFunctionWaterOptions(params) {
  return request({
    url: 'api/power-community-functions/list',
    method: 'get',
    params: params
  })
}

export function getFunctionElectricList(params) {
  
  return request({
    url: 'api/power-community-functions',
    method: 'get',
    params: params
  })
}

export function getFunctionElectricGeojson(params) {
  return request({
    url: 'api/power-community-functions/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getFunctionElectricStatistics(params) {
  return request({
    url: 'api/power-community-functions/statistics',
    method: 'get',
    params: params,
  })
}

export function getFunctionElectricChart(city_id) {
  return request({
    url: 'api/power-community-functions/show?city_id='+city_id,
    method: 'get'
  })
}


//
export function getFunctionNodeOptions(params) {
  return request({
    url: 'api/power-node-functions/list',
    method: 'get',
    params: params
  })
}

export function getFunctionNodeList(params) {
  
  return request({
    url: 'api/power-node-functions',
    method: 'get',
    params: params
  })
}

export function getFunctionNodeGeojson(params) {
  return request({
    url: 'api/power-node-functions/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getFunctionNodeStatistics(params) {
  return request({
    url: 'api/power-node-functions/statistics',
    method: 'get',
    params: params,
  })
}

export function getFunctionNodeChart(city_id) {
  return request({
    url: 'api/power-node-functions/show?city_id='+city_id,
    method: 'get'
  })
}




//risk
export function getRiskOptions(params) {
  return request({
    url: 'api/power-street-risks/list',
    method: 'get',
    params: params
  })
}

export function getRiskList(params) {
  
  return request({
    url: 'api/power-street-risks',
    method: 'get',
    params: params
  })
}

export function getRiskGeojson(params) {
  return request({
    url: 'api/power-street-risks/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getRiskStatistics(params) {
  return request({
    url: 'api/power-street-risks/statistics',
    method: 'get',
    params: params,
  })
}

export function getRiskChart(city_id) {
  return request({
    url: 'api/power-street-risks/show?city_id='+city_id,
    method: 'get'
  })
}