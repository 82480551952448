<template>
  <div>
      <div class="map-menu">
        <div class="mapboxgl-ctrl-icon" @click="setDefault" title="默认视图">  <span class="el-icon-s-home"></span></div>
        <div class="mapboxgl-ctrl-icon pitch" @click="setPitch" title="2D/3D">{{is2d?'2D':'3D'}}</div>
      </div>
      <div class="stroke-switch"><span>行政区划</span> <a :class="[curStroke=='region'?'cur':'']" @click="strokeRegion">城区</a> | <a :class="[curStroke=='street'?'cur':'']" @click="strokeStreet">街道</a> | <a :class="[curStroke=='community'?'cur':'']" @click="strokeCommunity">社区</a>
      <!-- <template v-if="$route.path.indexOf('earthquake/water')>=0" > | <a :class="[curStroke=='service'?'cur':'']"  @click="strokeServiceArea">服务区</a>
      </template>  -->
      </div>
      <!-- <div class="switch-one-side" v-if="$route.path.indexOf('earthquake/water')>=0">
        <p>服务区</p>
        <n-switch v-model="switches.showServiceArea"></n-switch>
      </div> -->
      <div class="disaster-selecter" v-show="showDisaster!='false'">
        <select name="" id="">
          <option value="">M 8.0, D = 15km</option>
        </select>
      </div>
  </div>
</template>
<script>
import { consts } from "../../src/util/consts.js";
import request from '@/util/request';
import { Switch } from "../../src/components";
import mapboxgl from "mapbox-gl";
import geobuf from "geobuf";
import Pbf from "pbf";
export default {
  components: {
    [Switch.name]: Switch,
  },
  name: 'map-menu',
  props: {
    map: {
      type: Object,
      required: true
    },
    mapDefault: {
      type: Object
    },
    showDisaster: {
      type: String,
    }
  },
  data(){
    return {
      curStroke: '',
      is2d: true,
      switches: {
        showServiceArea: true
      }
    }
  },
  watch: {
    mapDefault(val) {
      if(val.pitch == 0) this.is2d = true;
      else this.is2d = false;
      this.map.on('style.load',()=>{
        if(localStorage.getItem('curStroke') == 'region') {
          this.strokeRegion()
        }
        if(localStorage.getItem('curStroke') == 'street') {
          this.strokeStreet()
        }
        if(localStorage.getItem('curStroke') == 'community') {
          this.strokeCommunity()
        }
        if(localStorage.getItem('curStroke') == 'service') {
          this.strokeServiceArea()
        }
        // this.switches.showServiceArea = localStorage.getItem('StrokeServiceArea') == 'true'? true: false
        // if(this.switches.showServiceArea) {
        //   this.strokeServiceArea()
        // }
      })
      
    },
    curStroke(val) {
      localStorage.setItem('curStroke', val)
    },
    "switches.showServiceArea"(val) {
      localStorage.setItem('StrokeServiceArea', val)
      this.strokeServiceArea();
      
    },
  },
  mounted(){
    
  },
  methods: {
    setDefault(){
      //debugger;
      this.map.flyTo({
        // These options control the ending camera position: centered at
        // the target, at zoom level 9, and north up.
        center: this.mapDefault.center,
        zoom: this.mapDefault.zoom,
        bearing: 0,
        pitch: this.mapDefault.pitch,

        // These options control the flight curve, making it move
        // slowly and zoom out almost completely before starting
        // to pan.
        speed: 0.8, // make the flying slow
        curve: 1, // change the speed at which it zooms out

        // This can be any easing function: it takes a number between
        // 0 and 1 and returns another number between 0 and 1.
        easing: function(t) {
          return t;
        }
      });
      // this.map.setCenter(this.mapDefault.center)
      // this.map.setZoom(this.mapDefault.zoom)
      // this.map.setPitch(this.mapDefault.pitch)
      
    },
    setPitch(){
      if(this.is2d) {
        //切换3d
        this.map.flyTo({
          pitch: 60,
          speed: 0.8, // make the flying slow
          curve: 1, // change the speed at which it zooms out
          easing: function(t) {
            return t;
          }
        });
        this.is2d = false;
      } else {
        this.map.flyTo({
          pitch: 0,
          speed: 0.8, // make the flying slow
          curve: 1, // change the speed at which it zooms out
          easing: function(t) {
            return t;
          }
        });
        this.is2d = true;
      }
    },
    strokeRegion(){
      if(this.curStroke == 'region'){
        this.removeMapLayer('stroke');
        this.curStroke = ''
        return;
      }
      this.curStroke = 'region';
      if(!!this.regionGeojson) {
        this.addBlockmapLayer(this.map, 'stroke', this.regionGeojson);
        return;
      }
      request({
          url: 'api/regions/geo',
          method: 'get',
          params: {
            city_id: this.mapDefault.city_id
          },
          responseType: 'arraybuffer'
        }).then(data => {
          this.regionGeojson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addBlockmapLayer(this.map, 'stroke', this.regionGeojson);
        });
    },
    strokeStreet(){
      if(this.curStroke == 'street'){
        this.removeMapLayer('stroke');
        this.curStroke = ''
        return;
      }
      this.curStroke = 'street';
      if(!!this.streetGeojson) {
        this.addBlockmapLayer(this.map, 'stroke', this.streetGeojson);
        return;
      }
      request({
          url: 'api/streets/geo',
          method: 'get',
          params: {
            city_id: this.mapDefault.city_id
          },
          responseType: 'arraybuffer'
        }).then(data => {
          this.streetGeojson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addBlockmapLayer(this.map, 'stroke', this.streetGeojson);
        });
    },
    strokeCommunity(){
      if(this.curStroke == 'community'){
        this.removeMapLayer('stroke');
        this.curStroke = ''
        return;
      }
      this.curStroke = 'community';
      if(!!this.communityGeojson) {
        this.addBlockmapLayer(this.map, 'stroke', this.communityGeojson);
        return;
      }
      request({
          url: 'api/communities/geo',
          method: 'get',
          params: {
            city_id: this.mapDefault.city_id
          },
          responseType: 'arraybuffer'
        }).then(data => {
          this.communityGeojson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addBlockmapLayer(this.map, 'stroke', this.communityGeojson);
        });
    },
    strokeServiceArea(){
      if(this.curStroke == 'service'){
        this.removeMapLayer('stroke');
        this.curStroke = ''
        return;
      }
      this.curStroke = 'service';
      if(!!this.serviceGeojson) {
        this.addBlockmapLayer(this.map, 'stroke', this.serviceGeojson);
        return;
      }
      request({
          url: 'api/waters/geo-zone',
          method: 'get',
          params: {
            city_id: this.mapDefault.city_id
          },
          responseType: 'arraybuffer'
        }).then(data => {
          this.serviceGeojson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addBlockmapLayer(this.map, 'stroke', this.serviceGeojson);
        });
      /* if(!this.switches.showServiceArea){
        //关闭服务区
        this.removeMapLayer('strokeServiceArea');
        return;
      }
      if(!!this.communityGeojson) {
        this.addBlockmapLayer(this.map, 'strokeServiceArea', this.communityGeojson);
        return;
      }
      request({
          url: 'api/communities/geo',
          method: 'get',
          params: {
            city_id: this.mapDefault.city_id
          },
          responseType: 'arraybuffer'
        }).then(data => {
          this.communityGeojson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addBlockmapLayer(this.map, 'strokeServiceArea', this.communityGeojson);
        }); */
    },
    addBlockmapLayer(map, type, geoJson) {
      this.removeMapLayer(type);
      //let source_name_stroke = source_name+'_stroke';
      map.addSource(type, {
        type: "geojson",
        data: geoJson
      });
      //描边
      map.addLayer({
        id: type,
        type: "line",
        source: type,
        layout: {},
        paint: {
          "line-color": "#ffffff",
          "line-width": 1
        }
      });
    },
     removeMapLayer(type) {
      //描边
      var layer_stroke = this.map.getLayer(type);
      if(layer_stroke){
          this.map.removeLayer(type);
        }
      if(this.map.getSource(type)) {
        this.map.removeSource(type);
      } 
    },
    
  }
};
</script>
<style scoped>
.map-menu{
    position: absolute;
    top: 230px;
    right: 10px;
    color: #ccc;
    cursor: pointer;
}
.mapboxgl-ctrl-icon {
  width: 30px;
  height: 30px;
  border: 0;
  border-radius: 5px;
  font-size:18px;
  background: #fff;
  color:#000;
  text-align: center;
  margin-bottom: 10px;
}
.pitch {
  font-size: 14px;
  line-height: 30px;
}
.map-menu label {
  padding:0 5px;
}

.stroke-switch {
  position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%);
    color: #ccc;
    cursor: pointer;
    background: #fff;
    padding: 7px 10px;
    border-radius: 2px;
    color: #394761;
    font-size: 12px;
}
.stroke-switch span {
  font-size: 14px;
  margin-right: 10px;
}

.stroke-switch a {
  padding: 2px 4px;
}
.stroke-switch a.cur {
  background: #1A2948;
  color: #fff;
  border-radius: 5px;
}
</style>
