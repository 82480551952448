import request from '@/util/request';

export function addHighLigthLayer(type, feature, self )
{   
    if(self.popup){
        self.popup.remove();
        self.popup = null;
      }
      

      if(type == 'node') {
        self.map.addSource('highlight', {
          type: "geojson",
          data: feature
        });
        self.map.addLayer({
          id: 'highlight',
          type: "circle",
          source: 'highlight',
          paint: {
            // make circles larger as the user zooms from z12 to z22
            'circle-radius': 12,
            // color circles by ethnicity, using a match expression
            // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-match
            'circle-color': "rgba(213,83,40,0.8)",
            'circle-stroke-color': '#000000',
            'circle-stroke-width': 0
          }
        });
      } else if (type == 'line') {
        self.map.addSource('highlight', { 
          type: "geojson",
          data: feature
        });
        self.map.addLayer({
          id: 'highlight',
          type: "line",
          source: 'highlight',
          "layout": {
            "line-join": "round",
            "line-cap": "round"
          },
            "paint": {
            "line-color": "rgba(213,83,40,0.8)",
            "line-width": 12
          }
        });
      } else {
        self.map.addSource('highlight', {
          type: "geojson",
          data: feature
        });
        self.map.addLayer({
          id: 'highlight',
          type: "fill",
          source: 'highlight',
          'paint': {
            'fill-color': "rgba(213,83,40,0.8)",
            'fill-opacity': 0.8
          }
        });
      }
}




export function getCommonNodeGeojson(params) {
  return request({
    url: 'api/waters/geo-node',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}
export function getCommonPipeGeojson(params) {
  return request({
    url: 'api/waters/geo-line',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}
export function getCommonBlockGeojson(params) {
  return request({
    url: 'api/waters/geo-zone',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

//2.1.1

export function getBasicOptions(params) {
  return request({
    url: 'api/waters/list',
    method: 'get',
    params: params
  })
}
export function getBasicNodeList(params) {
  return request({
    url: 'api/waters/node',
    method: 'get',
    params: params
  })
}
export function getBasicPipeList(params) {
  return request({
    url: 'api/waters/line',
    method: 'get',
    params: params
  })
}
export function getBasicBlockList(params) {
  return request({
    url: 'api/waters/zone',
    method: 'get',
    params: params
  })
}

export function getBuildingGeojson(params) {

  params.format = 'geobuf';
  return request({
    url: 'api/buildings-geo-json',
    method: 'get',
    responseType: 'arraybuffer',
    params: params
  })
}




export function getBasicStatistics(params) {
  return request({
    url: 'api/waters/statistics',
    method: 'get',
    params: params,
  })
}

export function getBasicChart(city_id) {
  return request({
    url: 'api/waters/show?city_id='+city_id,
    method: 'get'
  })
}




//2.1.2

export function getNodeTypeOptions(params) {
  return request({
    url: 'api/water-node-types/list',
    method: 'get',
    params: params
  })
}

export function getNodeTypeList(params) {
  return request({
    url: 'api/water-node-types',
    method: 'get',
    params: params
  })
}

export function getNodeTypeNodeGeojson(params) {

  params.format = 'geobuf';
  return request({
    url: 'api/water-node-types/geo',
    method: 'get',
    responseType: 'arraybuffer',
    params: params
  })
}

export function getNodeTypePipeGeojson(params) {
  return request({
    url: 'api/quake-chasms/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getNodeTypeBlockGeojson(params) {
  return request({
    url: 'api/quake-regions/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}



export function getNodeTypeStatistics(params) {

  return request({
    url: 'api/water-node-types/statistics',
    method: 'get',
    params: params,
  })
}

export function getNodeTypeChart(city_id) {
  return request({
    url: 'api/water-node-types/show?city_id='+city_id,
    method: 'get'
  })
}

//2.1.2-2

export function getNodeElevationOptions(params) {
  return request({
    url: 'api/water-node-elevations/list',
    method: 'get',
    params: params
  })
}

export function getNodeElevationList(params) {
  return request({
    url: 'api/water-node-elevations',
    method: 'get',
    params: params
  })
}


export function getNodeElevationNodeGeojson(params) {
  return request({
    url: 'api/water-node-elevations/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getNodeElevationPipeGeojson(params) {
  return request({
    url: 'api/quake-chasms/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}


export function getNodeElevationStatistics(params) {

  return request({
    url: 'api/water-node-elevations/statistics',
    method: 'get',
    params: params,
  })
}

export function getNodeElevationChart(city_id) {
  return request({
    url: 'api/water-node-elevations/show?city_id='+city_id,
    method: 'get'
  })
}



//2.1.3

export function getPipeDiamOptions(params) {
  return request({
    url: 'api/water-line-diameters/list',
    method: 'get',
    params: params
  })
}

export function getPipeDiamList(params) {
  return request({
    url: 'api/water-line-diameters',
    method: 'get',
    params: params
  })
}

export function getPipeDiamPipeGeojson(params) {
  return request({
    url: 'api/water-line-diameters/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}


export function getPipeDiamStatistics(params) {

  return request({
    url: 'api/water-line-diameters/statistics',
    method: 'get',
    params: params,
  })
}

export function getPipeDiamChart(city_id) {
  return request({
    url: 'api/water-line-diameters/show?city_id='+city_id,
    method: 'get'
  })
}

//2.1.3-2

export function getPipeTypeOptions(params) {
  return request({
    url: 'api/water-line-materials/list',
    method: 'get',
    params: params
  })
}

export function getPipeTypeList(params) {
  return request({
    url: 'api/water-line-materials',
    method: 'get',
    params: params
  })
}

export function getPipeTypeNodeGeojson(params) {
  return request({
    url: 'api/water-line-materials/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getPipeTypePipeGeojson(params) {
  return request({
    url: 'api/water-line-materials/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}


export function getPipeTypeStatistics(params) {

  return request({
    url: 'api/water-line-materials/statistics',
    method: 'get',
    params: params,
  })
}

export function getPipeTypeChart(city_id) {
  return request({
    url: 'api/water-line-materials/show?city_id='+city_id,
    method: 'get'
  })
}


//2.1.4

export function getVolumnOptions(params) {
  return request({
    url: 'api/water-zones/list',
    method: 'get',
    params: params
  })
}

export function getVolumnList(params) {
  return request({
    url: 'api/water-zones',
    method: 'get',
    params: params
  })
}

export function getVolumnGeojson(params) {
  return request({
    url: 'api/water-zones/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getVolumnStatistics(params) {

  return request({
    url: 'api/water-zones/statistics',
    method: 'get',
    params: params,
  })
}

export function getVolumnChart(city_id) {
  return request({
    url: 'api/water-zones/show?city_id='+city_id,
    method: 'get'
  })
}

//2.2.1

export function getPhysicallossNodeOptions(params) {
  return request({
    url: 'api/water-node-damages/list',
    method: 'get',
    params: params
  })
}

export function getPhysicallossNodeList(params) {
  
  return request({
    url: 'api/water-node-damages',
    method: 'get',
    params: params
  })
}

export function getPhysicallossNodeGeojson(params) {
  var tmp = {}
  Object.assign(tmp,params)
  tmp.damage_probabilities = tmp.damage_probabilities_1
  return request({
    url: 'api/water-node-damages/geo',
    method: 'get',
    params: tmp,
    responseType: 'arraybuffer'
  })
}

export function getPhysicallossNodeStatistics(params) {
  return request({
    url: 'api/water-node-damages/statistics',
    method: 'get',
    params: params,
  })
}

export function getPhysicallossNodeChart(city_id) {
  return request({
    url: 'api/water-node-damages/show?city_id='+city_id,
    method: 'get'
  })
}


export function getPhysicallossPipeOptions(params) {
  return request({
    url: 'api/water-line-damages/list',
    method: 'get',
    params: params
  })
}

export function getPhysicallossPipeList(params) {
  return request({
    url: 'api/water-line-damages',
    method: 'get',
    params: params
  })
}

export function getPhysicallossPipeGeojson(params) {
  // var tmp = {}
  // Object.assign(tmp,params)
  // tmp.damage_probabilities = tmp.damage_probabilities_2
  return request({
    url: 'api/water-line-damages/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getPhysicallossPipeStatistics(params) {
  return request({
    url: 'api/water-line-damages/statistics',
    method: 'get',
    params: params,
  })
}

export function getPhysicallossPipeChart(city_id) {
  return request({
    url: 'api/water-line-damages/show?city_id='+city_id,
    method: 'get'
  })
}


//2.2.2-1

export function getFunctionlossNodeOptions(params) {
  return request({
    url: 'api/water-node-reliabilities/list',
    method: 'get',
    params: params
  })
}

export function getFunctionlossNodeList(params) {
  
  return request({
    url: 'api/water-node-reliabilities',
    method: 'get',
    params: params
  })
}

export function getFunctionlossNodeGeojson(params) {
  return request({
    url: 'api/water-node-reliabilities/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getFunctionlossNodeStatistics(params) {
  return request({
    url: 'api/water-node-reliabilities/statistics',
    method: 'get',
    params: params,
  })
}

export function getFunctionlossNodeChart(city_id) {
  return request({
    url: 'api/water-node-reliabilities/show?city_id='+city_id,
    method: 'get'
  })
}


//2.2.2-2

export function getFunctionlossRatiosOptions(params) {
  return request({
    url: 'api/water-community-ratios/list',
    method: 'get',
    params: params
  })
}

export function getFunctionlossRatiosList(params) {
  
  return request({
    url: 'api/water-community-ratios',
    method: 'get',
    params: params
  })
}

export function getFunctionlossRatiosGeojson(params) {
  return request({
    url: 'api/water-community-ratios/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getFunctionlossRatiosStatistics(params) {
  return request({
    url: 'api/water-community-ratios/statistics',
    method: 'get',
    params: params,
  })
}
export function getFunctionlossRatiosChart(city_id) {
  return request({
    url: 'api/water-community-ratios/show?city_id='+city_id,
    method: 'get'
  })
}


//2.2.3

export function getImportanceOptions(params) {
  return request({
    url: 'api/water-line-importances/list',
    method: 'get',
    params: params
  })
}

export function getImportanceList(params) {
  
  return request({
    url: 'api/water-line-importances',
    method: 'get',
    params: params
  })
}

export function getImportanceGeojson(params) {
  return request({
    url: 'api/water-line-importances/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getImportanceStatistics(params) {
  return request({
    url: 'api/water-line-importances/statistics',
    method: 'get',
    params: params,
  })
}

export function getImportanceChart(city_id) {
  return request({
    url: 'api/water-line-importances/show?city_id='+city_id,
    method: 'get'
  })
}


//2.3.1

export function getReinforceOptions(params) {
  return request({
    url: 'api/water-line-reinforcements/list',
    method: 'get',
    params: params
  })
}

export function getReinforceList(params) {
  
  return request({
    url: 'api/water-line-reinforcements',
    method: 'get',
    params: params
  })
}

export function getReinforceGeojson(params) {
  return request({
    url: 'api/water-line-reinforcements/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getReinforceStatistics(params) {
  return request({
    url: 'api/water-line-reinforcements/statistics',
    method: 'get',
    params: params,
  })
}

export function getReinforceChart(city_id) {
  return request({
    url: 'api/water-line-reinforcements/show?city_id='+city_id,
    method: 'get'
  })
}

//2.3.2-1

export function getRepairLineOptions(params) {
  return request({
    url: 'api/water-line-repairs/list',
    method: 'get',
    params: params
  })
}

export function getRepairLineList(params) {
  
  return request({
    url: 'api/water-line-repairs',
    method: 'get',
    params: params
  })
}

export function getRepairLineGeojson(params) {
  return request({
    url: 'api/water-line-repairs/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getRepairLineStatistics(params) {
  return request({
    url: 'api/water-line-repairs/statistics',
    method: 'get',
    params: params,
  })
}

export function getRepairLineChart(city_id) {
  return request({
    url: 'api/water-line-repairs/show?city_id='+city_id,
    method: 'get'
  })
}


//
export function getRepairNodeOptions(params) {
  return request({
    url: 'api/water-node-repairs/list',
    method: 'get',
    params: params
  })
}

export function getRepairNodeList(params) {
  
  return request({
    url: 'api/water-node-repairs',
    method: 'get',
    params: params
  })
}

export function getRepairNodeGeojson(params) {
  return request({
    url: 'api/water-node-repairs/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getRepairNodeStatistics(params) {
  return request({
    url: 'api/water-node-repairs/statistics',
    method: 'get',
    params: params,
  })
}

export function getRepairNodeChart(city_id) {
  return request({
    url: 'api/water-node-repairs/show?city_id='+city_id,
    method: 'get'
  })
}


//2.3.2-2

export function getFunctionWaterOptions(params) {
  return request({
    url: 'api/water-community-functions/list',
    method: 'get',
    params: params
  })
}

export function getFunctionWaterList(params) {
  
  return request({
    url: 'api/water-community-functions',
    method: 'get',
    params: params
  })
}

export function getFunctionWaterGeojson(params) {
  return request({
    url: 'api/water-community-functions/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getFunctionWaterStatistics(params) {
  return request({
    url: 'api/water-community-functions/statistics',
    method: 'get',
    params: params,
  })
}

export function getFunctionWaterChart(city_id) {
  return request({
    url: 'api/water-community-functions/show?city_id='+city_id,
    method: 'get'
  })
}


//
export function getFunctionNodeOptions(params) {
  return request({
    url: 'api/water-node-functions/list',
    method: 'get',
    params: params
  })
}

export function getFunctionNodeList(params) {
  
  return request({
    url: 'api/water-node-functions',
    method: 'get',
    params: params
  })
}

export function getFunctionNodeGeojson(params) {
  return request({
    url: 'api/water-node-functions/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getFunctionNodeStatistics(params) {
  return request({
    url: 'api/water-node-functions/statistics',
    method: 'get',
    params: params,
  })
}

export function getFunctionNodeChart(city_id) {
  return request({
    url: 'api/water-node-functions/show?city_id='+city_id,
    method: 'get'
  })
}






//risk
export function getRiskOptions(params) {
  return request({
    url: 'api/water-street-risks/list',
    method: 'get',
    params: params
  })
}

export function getRiskList(params) {
  
  return request({
    url: 'api/water-street-risks',
    method: 'get',
    params: params
  })
}

export function getRiskGeojson(params) {
  return request({
    url: 'api/water-street-risks/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getRiskStatistics(params) {
  return request({
    url: 'api/water-street-risks/statistics',
    method: 'get',
    params: params,
  })
}

export function getRiskChart(city_id) {
  return request({
    url: 'api/water-street-risks/show?city_id='+city_id,
    method: 'get'
  })
}


