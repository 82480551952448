<template>
  <div class="row station-arrive" style="margin:0 -30px;">
    <div class="col-md-9" style="padding:0">
      <card class="card-map">
        <div id="map" :class="{ 'map-full' : outPage }"></div>
        <menu-map :map="map" :mapDefault="mapdefaultSetting" showDisaster="false" showWeather="true" :marginTop="outPage ? 0 : 30"></menu-map>
        <div class="radio-group">
          <div v-if="outPage">
            <label :for="types[1].id"><input type="radio" :id="types[1].id" :value="types[1].id" v-model="curType">
              {{ types[1].value }}</label>
          </div>
          <div v-else v-for="item in types" @click="changeType(item.id)" :key="item.id">
            <label :for='item.id'><input type="radio" :id="item.id" :value="item.id" v-model="curType">
              {{ item.value }}</label>
          </div>
        </div>
        <div class="map_data_msg" v-if="mapinfo">
          <div class="map_data_legend">{{ mapinfo.title }}</div>
          <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
            <div class="item" v-for="(item, index) in mapinfo.names" :key="index">
              <span :style="'background: ' + mapinfo.colors[index] + ''"></span>
              {{ item }}
            </div>
          </div>
        </div>
      </card>
    </div>
    <div class="col-md-3" id="customStyle">
      <el-collapse v-model="activeNames">
        <el-collapse-item title="信息图表" name="1">
          <div class="col-md-12">
            <div class="chart-area" id="chart-bar-time" style="height:400px"></div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import MenuMap from "src/components/menumapFire3";
import geobuf from "geobuf";
import Pbf from "pbf";
import { Collapse, CollapseItem, Button, Tag } from "element-ui";
import { consts } from "src/util/consts.js";
import {
  getPublicPbf,
  getPublicJson,
} from "src/api/fire.js";
import { getPolygonFromPoint } from "src/util/utils.js"
import { storeMapOptions, getMapOptions } from "src/util/common.js";
import { buildBarMultiOption } from "src/util/chart.js";

export default {
  components: {
    MenuMap,
    [Tag.name]: Tag,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
  },
  data() {
    return {
      mid: 0, //模块id
      types: [
        { id: 3, value: '基于4min行车到场时长' },
        { id: 6, value: '杭州市基于4min行车到场时长' }
      ], //左上角切换radio
      mapinfo: {
        title: '网格最小“行车到场时长”（分钟）',
        colors: ["#ec6347", "#eaa489", "#eed8cf", "#c5b9b5"],
        names: ['0<t≤4', '4<t≤7.5', '7.5<t≤15', 't>15'],
        values: [4, 7.5, 15],
      },
      curType: 0,
      activeNames: ['1'],
      map: {},
      pupupinfo: {},
      mapdefaultSetting: {},
      layerId: 'station-arrive-layer',
      city_id: localStorage.getItem("city_id") || 1,
      outPage: consts.outPages.includes(this.$route.path),
    };
  },
  mounted() {
    let _this = this;
    this.mid = this.$route.params.did || 6;
    this.curType = this.mid;
    this.setTipContent()
    this.initMap(this.city_id);
    this.initChart()
    this.showChart()
    this.map.on('style.load', function () {
      _this.removeLayer()
      _this.showLayer()
    })
  },
  methods: {
    initMap(city_id) {
      this.mapdefaultSetting = {
        center: consts.mapCenter[this.city_id - 1],
        zoom: consts.mapDefaultZoom,
        pitch: 0,
        city_id: this.city_id
      }
      mapboxgl.accessToken = consts.mapboxAccessToken;
      let map_options = getMapOptions();
      let zoom = map_options ? map_options.zoom : 12;
      let center = map_options ? [map_options.lng, map_options.lat] : consts.mapCenter[city_id - 1];
      var map = new mapboxgl.Map({
        container: "map",
        style: consts.mapBoxStyle,
        center: center,
        zoom: zoom,
        pitch: 0,
        bearing: 0,
        attributionControl: false
      });
      this.map = map;
      //定位图标
      map.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl
        })
      );
      map.addControl(new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      }));
      //控制图层
      var nav = new mapboxgl.NavigationControl();
      map.addControl(nav, 'top-right');
      //全屏按钮
      map.addControl(new mapboxgl.FullscreenControl({
        container: document.querySelector('body')
      }));
      //记录地图设置
      map.on('move', function () {
        storeMapOptions({
          lng: map.getCenter().lng,
          lat: map.getCenter().lat,
          zoom: map.getZoom()
        });
      });
    },
    initChart() {
      const box = document.getElementById("chart-bar-time");
      this.chart = this.$echarts.init(box);
      window.addEventListener('resize', () => {
        document.querySelectorAll('.chart-area').forEach((item) => {
          item.style.width = document.querySelector('.el-collapse').offsetWidth + 'px';
        })
        this.chart.resize();
      })
    },
    changeType(val) {
      //切换左上角列表
      if (val == 3) {
        this.$router.push({ path: '/fire/building/stationlayouts/3' })
      } else {
        this.$router.push({ path: '/fire/building/stationarrive/6' })
      }
    },
    showChart() {
      getPublicJson({ fileName: 'drivetime_data.json' }).then(res => {
        this.pupupinfo = res.map
        const option = buildBarMultiOption('杭州市各网格最小行车到场时长分布数量（个）及其对应消防救援站', res.table, this.mapinfo.names, this.mapinfo.colors, null, true);
        this.chart.setOption(option);
        this.chart.resize();
      })
    },
    showLayer() {
      // 区县边界
      this.map.addSource('hangzhou_outline', {
        type: "geojson",
        data: './geojson/hz-outline.geojson',
      });
      this.map.addLayer({
        id: 'hangzhou_outline',
        type: "line",
        source: 'hangzhou_outline',
        paint: {
          "line-color": '#0000ff',
          "line-opacity": 1.0,
          "line-width": 3,
        },
      })
      // 消防边界
      getPublicPbf({ fileName: 'border_output.pbf' }).then(res => {
        const geoJson = geobuf.decode(new Pbf(res));
        const features = geoJson.features.map(it => {
          return {
            ...it,
            properties: {
              ...it.properties,
              colorName: Math.floor(Math.random() * 7),
            }
          }
        })
        this.map.addSource(this.layerId + '-line', {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features,
          },
        });
        this.map.addLayer({
          id: this.layerId + '-line',
          type: "line",
          source: this.layerId + '-line',
          layout: {
            "line-join": "round",
            "line-cap": "round",
            "visibility": "none", // 隐藏图层
          },
          paint: {
            "line-color": [
              "match",
              ["get", "colorName"],
              0, "rgba(0, 200, 120, 0.7)",
              1, "rgba(85, 150, 20, 0.8)",
              2, "rgba(200, 200, 100, 0.9)",
              3, "rgba(230,140,5,0.9)",
              4, "rgba(252, 96, 0, 0.8)",
              5, "rgba(210,80,92,0.9)",
              6, "rgba(175,40,126,0.8)",
              "rgba(241, 90, 241, 0.8)"
            ],
            "line-width": 3,
          }
        });
      })
      // 消防站
      getPublicPbf({ fileName: 'xfz_output.pbf' }).then(res => {
        const geoJson = geobuf.decode(new Pbf(res));
        const features = geoJson.features.map(it => {
          return {
            ...it,
            properties: {
              ...it.properties,
              iconName: `dot-` + Math.floor(Math.random() * 5),
            }
          }
        })
        const colors = ['#c9f982','#82d3f8','#b0faff','#ffff00','#6097e6']
        colors.forEach((item, index) => {
          this.makePointImage(this.map, item, index)
        })
        this.map.addSource(this.layerId + '-point', {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features,
          },
        });
        this.map.addLayer({
          id: this.layerId + '-point',
          type: "symbol",
          source: this.layerId + '-point',
          paint: {
            "text-color": "#ffffff"
          },
          layout: {
            "icon-image": ["get", "iconName"],
            "text-field": ["get", "消防站"],
            "icon-size": [
              "match",
              ["get", "类别"],
              "一级站", 1.1,
              "二级站", 0.8,
              "小型站", 0.6,
              1.0
            ],
            "text-size": [
              "interpolate",
              ["linear"],
              ["zoom"],
              5, //zoom
              0, //font-size
              10,
              12
            ],
            "text-offset": [0, -3],
            "text-anchor": "top",
            "icon-allow-overlap": true,
            "text-allow-overlap": true
          }
        });
      })
      // 网格
      getPublicPbf({ fileName: 'grid_output.pbf' }).then(res => {
        const geoJson = geobuf.decode(new Pbf(res));
        this.map.addSource(this.layerId, {
          type: "geojson",
          data: geoJson,
        });
        this.map.addLayer({
          id: this.layerId,
          type: "fill",
          source: this.layerId,
          layout: {},
          paint: {
            "fill-color": this.getColorExpress('drivetime'),
            "fill-opacity": 0.8,
          }
        }, 'state-label');
        this.map.on('mouseenter', this.layerId, this.mapMouseEnterBlock);
        this.map.on('mousemove', this.layerId, this.mapMouseMoveBlock);
        this.map.on('mouseleave', this.layerId, this.mapMouseLeaveBlock);
      })
      // 柱图
      getPublicPbf({ fileName: 'heat_grid_output.pbf' }).then(res => {
        const geoJson = geobuf.decode(new Pbf(res));
        geoJson.features.forEach((element) => {
          element.geometry = getPolygonFromPoint(
            element.geometry.type == "Point"
              ? element.geometry.coordinates
              : element.geometry.coordinates[0],
            50,
            0.005
          );
        });
        this.map.addSource(this.layerId + '-heatmap', {
          type: "geojson",
          data: geoJson,
        });
        this.map.addLayer({
          id: this.layerId + '-heatmap',
          type: "fill-extrusion",
          source: this.layerId + '-heatmap',
          layout: {},
          paint: {
            "fill-extrusion-color": this.getColorExpress('people_values', true),
            "fill-extrusion-height": [
              "step",
              ["get", "people_values"],
              // 100, 10,
              // 500, 20,
              // 1000, 30,
              100, 4,
              500, 7.5,
              1000, 15,
              2000,
            ],
          },
        });
      })
    },
    getColorExpress(field, reverseColor) {
      const colorExpress = [
        "step",
        ["get", field],
      ]
      if (reverseColor) {
        const colors = [...this.mapinfo.colors]
        colors.reverse()
        this.mapinfo.values.forEach((item, idx) => {
          colorExpress.push(colors[idx], item)
        })
        colorExpress.push(colors[3])
        return colorExpress
      }
      this.mapinfo.values.forEach((item, idx) => {
        colorExpress.push(this.mapinfo.colors[idx], item)
      })
      colorExpress.push(this.mapinfo.colors[3])
      return colorExpress
    },
    mapMouseEnterBlock(e) {
      if (this.popup) {
        this.popup.remove()
      }
    },
    mapMouseMoveBlock(e) {
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: [this.layerId],
      });
      if (!features.length) return
      const { dist_name } = features[0].properties
      const info = {
        name: `${dist_name}消防救援站`,
        info1: `基于频数的响应覆盖率：${this.pupupinfo.one[dist_name].toFixed(2)}%`,
        info2: `基于均匀时长频数的响应覆盖率：${this.pupupinfo.two[dist_name].toFixed(2)}%`,
        info3: `基于频数的交叉响应覆盖率：${this.pupupinfo.three[dist_name].toFixed(2)}%`,
        info4: `基于人口数量和覆盖频数的响应覆盖率：${this.pupupinfo.four[dist_name].toFixed(2)}%`,
      }
      if (this.popup) {
        this.popup.setLngLat(e.lngLat).setMaxWidth("none")
        .setHTML(info.name + '<br>' + info.info1 + '<br>' + info.info2 + '<br>' + info.info3+ '<br>' + info.info4).addTo(this.map);
      } else {
        this.popup = new mapboxgl.Popup({ closeOnClick: false, className: 'map-popup' })
          .setLngLat(e.lngLat)
          .setMaxWidth("none")
          .setHTML(info.name + '<br>' + info.info1 + '<br>' + info.info2 + '<br>' + info.info3+ '<br>' + info.info4)
          .addTo(this.map);
      }
    },
    mapMouseLeaveBlock(e) {
      if (this.popup) {
        this.popup.remove()
      }
    },
    makePointImage(map, item, index) {
      let size = 150;
      let pulsingDot = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),
        // get rendering context for the map canvas when layer is added to the map
        onAdd: function () {
          var canvas = document.createElement("canvas");
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext("2d");
        },
        // called once before every frame where the icon will be used
        render: function () {
          var duration = 1000;
          var t = (performance.now() % duration) / duration;

          var radius = (size / 2) * 0.3;
          var outerRadius = (size / 2) * 0.3 * t + radius;
          var context = this.context;

          // Draw the outer circle.
          context.clearRect(0, 0, this.width, this.height);
          context.beginPath();
          context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2
          );
          switch (item) {
            case '#c9f982':
              context.fillStyle = "rgba(201, 249, 130," + (1 - t) + ")";
              break;
            case '#82d3f8':
              context.fillStyle = "rgba(130, 211, 248," + (1 - t) + ")";
              break;
            case '#b0faff':
              context.fillStyle = "rgba(176, 250, 255," + (1 - t) + ")";
              break;
            case '#ffff00':
              context.fillStyle = "rgba(255, 255, 0," + (1 - t) + ")";
              break;
            case '#6097e6':
              context.fillStyle = "rgba(96, 151, 230," + (1 - t) + ")";
              break;
          }
          context.fill();

          // Draw the inner circle.
          context.beginPath();
          context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
          context.fillStyle = item;
          context.fill();

          // update this image's data with data from the canvas
          this.data = context.getImageData(0, 0, this.width, this.height).data;
          // continuously repaint the map, resulting in the smooth animation of the dot
          map.triggerRepaint();
          // return `true` to let the map know that the image was updated
          return true;
        }
      }
      map.addImage("dot-" + index, pulsingDot, { pixelRatio: 2 });
    },
    removeLayer() {
      if (this.map.getSource(this.layerId)) {
        this.map.removeSource(this.layerId);
      }
      if (this.map.getSource(this.layerId + '-line')) {
        this.map.removeSource(this.layerId + '-line');
      }
      if (this.map.getSource(this.layerId + '-point')) {
        this.map.removeSource(this.layerId + '-point');
      }
      if (this.map.getSource(this.layerId + '-heatmap')) {
        this.map.removeSource(this.layerId + '-heatmap');
      }
      if (this.map.getSource('hangzhou_outline')) {
        this.map.removeSource('hangzhou_outline');
      }
      if (this.map.getLayer(this.layerId)) {
        this.map.removeLayer(this.layerId)
      }
      if (this.map.getLayer(this.layerId + '-line')) {
        this.map.removeLayer(this.layerId  + '-line')
      }
      if (this.map.getLayer(this.layerId + '-point')) {
        this.map.removeLayer(this.layerId + '-point')
      }
      if (this.map.getLayer(this.layerId + '-heatmap')) {
        this.map.removeLayer(this.layerId + '-heatmap')
      }
      if (this.map.getLayer('hangzhou_outline')) {
        this.map.removeLayer('hangzhou_outline')
      }
    },
    setTipContent() {
      const tipEl = document.getElementById('left-tip-content')
      if (tipEl) {
        tipEl.innerHTML = '基于4min行车到场时长：'
      }
    },
  },
  beforeDestroy() {
    this.removeLayer()
  }
};
</script>

<style lang="scss" scoped>
.card-map {
  min-height: 350px;
  height: calc(100vh - 48px);
  margin-bottom: 0;
  position: relative;

  #map {
    width: 100%;
    height: calc(100vh - 100px);
  }

  .radio-group {
    position: absolute;
    left: 10px;
    top: 10px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding: 10px;
  }

  .radio-group div {
    padding: 0 10px;
  }

  .radio-group div input {
    vertical-align: -1px;
    padding-right: 2px;
  }

  .map_data_msg {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    // color: #02f4fe;
    color: #fff;
    padding: 10px;
    width: auto;

    .map_data_legend {
      margin-bottom: 5px;
    }

    .map_data_msg_wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .item {
        // width: 80px;
        line-height: 15px;
        padding: 5px 0;
      }

      .item span {
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 3px;
        vertical-align: middle;
        margin-left: 0;
      }

      .item-circle span {
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 100%;
        vertical-align: middle;
        margin-left: 0;
        position: relative;
        margin-right: 7px;
      }

      .item-circle span:after {
        content: '';
        position: absolute;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        border: 1px solid rgba(255, 255, 255, 0.5);
        left: -5px;
        top: -5px;
      }

      .map_data_msg_inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 10px;
      }
    }

    .map_data_msg_wrapper_vertical {
      flex-direction: column;

      .item {
        width: 130px;
      }
    }
  }
}

@import url("https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css");

.chart-area {
  width: 100%;
}
.station-arrive .map-popup .mapboxgl-popup-content {
  width: 250px;
}
</style>
