<template>
  <div>
    <el-input placeholder="输入典型单位ID" v-model="keyword" @change="searchBuilding" type="number" clearable>
    </el-input>
  </div>
</template>
<script>
import { consts } from "../../src/util/consts.js";
import request from '@/util/request';
import mapboxgl from "mapbox-gl";
import geobuf from "geobuf";
import Pbf from "pbf";
import {
  getBuidingGeo
} from "src/api/building.js";
export default {
  name: 'search-area',
  props: {
    map: {
      type: Object,
      required: true
    },
    mapDefault: {
      type: Object
    },
  },
  data() {
    return {
      isZoomed: false,
      keyword: '',
      popup: null

    }
  },
  watch: {
    map(val) {
      this.bindClick()
    },
    mapDefault(val) {


    },
    keyword(val) {
      if (!val) {
        if (this.popup) {
          this.popup.remove();
        }
      }
    }
  },
  mounted() {

  },
  methods: {
    bindClick() {
      let _this = this;
      this.map.on("click", 'room-extrusion', function (e) {
        if (e.features.length == 0) return;
        var coordinates = e.features[0].geometry.coordinates.slice();
        //var description = e.features[0].properties.description;
        _this.map.flyTo({
          // These options control the ending camera position: centered at
          // the target, at zoom level 9, and north up.
          center: [e.lngLat.lng, e.lngLat.lat],
          zoom: !_this.isZoomed ? 15 : _this.map.getZoom(),

          // These options control the flight curve, making it move
          // slowly and zoom out almost completely before starting
          // to pan.
          speed: 0.8, // make the flying slow
          curve: 1, // change the speed at which it zooms out

          // This can be any easing function: it takes a number between
          // 0 and 1 and returns another number between 0 and 1.
          easing: function (t) {
            return t;
          }
        });
        _this.keyword = e.features[0].id;

        _this.isZoomed = true;
        getBuidingGeo({
          city_id: _this.mapDefault.city_id,
          building_id: e.features[0].id
        }).then(data => {
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          if (typeof geoJson.features == 'undefined') {
            _this.$notify({
              message: '无此建筑id',
              icon: 'fa fa-gift',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'warning'
            })
            return
          };
          // if(_this.popup) {
          //   _this.popup.remove()
          // }
          let props = geoJson.features[0].properties
          let html = '';
          html += '建筑ID：' + props.id + '<br>';
          html += '街道：' + props.street_name + '<br>';
          html += '社区：' + props.community_name + '<br>';
          html += '总面积（平方米）：' + props.total_area + '<br>';
          html += '高度（米）：' + props.build_height + '<br>';
          html += '建筑结构：' + props.struct_name + '<br>';
          html += '单位性质：' + props.nature_name + '<br>';
          html += '耐火等级：' + props.fire_level + '<br>';
          html += '所需消防流量QR（升/秒）：' + props.fire_flow + '<br>';

          _this.addPopup(geoJson, html)




        });
      });
    },
    searchBuilding() {
      //console.log(this.keyword)
      getBuidingGeo({
        city_id: this.mapDefault.city_id,
        building_id: this.keyword
      }).then(data => {
        let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        //console.log(geoJson)
        if (typeof geoJson.features == 'undefined') {
          this.$notify({
            message: '无此建筑ID',
            icon: 'fa fa-gift',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning'
          })
          return
        };

        // if(this.popup) {
        //   this.popup.remove()
        // } 
        this.map.flyTo({
          // These options control the ending camera position: centered at
          // the target, at zoom level 9, and north up.
          center: geoJson.features[0].geometry.coordinates,
          zoom: !this.isZoomed ? 15 : this.map.getZoom(),
          // These options control the flight curve, making it move
          // slowly and zoom out almost completely before starting
          // to pan.
          speed: 0.8, // make the flying slow
          curve: 1, // change the speed at which it zooms out

          // This can be any easing function: it takes a number between
          // 0 and 1 and returns another number between 0 and 1.
          easing: function (t) {
            return t;
          }
        });
        let props = geoJson.features[0].properties
        let html = '';
        html += '建筑ID：' + props.id + '<br>';
        html += '街道：' + props.street_name + '<br>';
        html += '社区：' + props.community_name + '<br>';
        html += '总面积（平方米）：' + props.total_area + '<br>';
        html += '高度（米）：' + props.build_height + '<br>';
        html += '建筑结构：' + props.struct_name + '<br>';
        html += '单位性质：' + props.nature_name + '<br>';
        html += '耐火等级：' + props.fire_level + '<br>';
        html += '所需消防流量QR（升/秒）：' + props.fire_flow + '<br>';

        this.isZoomed = true;

        this.addPopup(geoJson, html)

        // if(this.popup) {
        //   this.popup.setLngLat(geoJson.features[0].geometry.coordinates)
        //   this.popup.setHTML(html)
        //   this.popup.addTo(this.map);
        // } else {
        //   this.popup = new mapboxgl.Popup()
        //   .setLngLat(geoJson.features[0].geometry.coordinates)
        //   .setHTML(html)
        //   .addTo(this.map);

        //   // this.popup.on('close', ()=>{
        //   //   this.keyword = ''
        //   //   //this.popup = null;
        //   // })
        // }


      });
    },
    addPopup(geoJson, html) {
      // if(this.popup) {
      //   this.popup.setLngLat(geoJson.features[0].geometry.coordinates)
      //   this.popup.setHTML(html)
      //   this.popup.addTo(this.map);
      // } else {
      //   this.popup = new mapboxgl.Popup()
      //   .setLngLat(geoJson.features[0].geometry.coordinates)
      //   .setHTML(html)
      //   .addTo(this.map);

      //   // _this.popup.on('close', ()=>{
      //   //   _this.keyword = ''
      //   //   _this.popup = null;
      //   // })
      // }
      if (this.popup) {
        this.popup.remove()
      }

      this.popup = new mapboxgl.Popup({ closeOnClick: false, className: 'map-popup' })
        .setLngLat(geoJson.features[0].geometry.coordinates)
        .setHTML(html)
        .addTo(this.map);

    },

  }
};
</script>
<style scoped>

</style>
