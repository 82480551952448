<template>
   <div class="row" style="margin:0 -30px;">
    <div class="col-md-9" style="padding:0">
      <card class="card-map" >
        <!-- <div class="mask">
          <img src="img/loading.png" class="loading">
        </div> -->
        <div id="map"></div>
        <menu-map :map="map" :mapDefault="mapdefaultSetting" showDisaster="false"></menu-map>
        <div class="map_data_msg" v-show="selects.data_category == 'quake_history'">
          <div class="map_data_legend">历史地震震级</div>
          <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
            <div
              class="item item-circle"
              v-for="(item,index) in quakelist.range"
              :key="index"
            >
              <span :style="'background: '+quakelist.color[index]+';transform: scale('+(1+(index*0.2))*0.6+');'"></span>
              {{item}}
            </div>
          </div>
        </div>
        <div class="map_data_msg" v-show="selects.data_category == 'chasm'">
          <div class="map_data_legend">断层性质</div>
          <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
            <div
              class="item item-bar"
              v-for="(item,index) in chasmlist.range"
              :key="index"
            >
              <span :style="'background: '+chasmlist.color[index]+''"></span>
              {{item}}
            </div>
          </div>
        </div>
        <div class="map_data_msg" v-show="selects.data_category == 'quake_region'">
          <div class="map_data_legend">震源震级上限</div>
          <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
            <div
              class="item item-block"
              v-for="(item,index) in quakeRegionlist.range"
              :key="index"
            >
              <span :style="'background: '+quakeRegionlist.color[index]+''"></span>
              {{item}}
            </div>
          </div>
        </div>
        <div class="map_data_msg" v-show="selects.data_category == 'soil'">
          <div class="map_data_legend">场地土类型</div>
          <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
            <div
              class="item item-bar"
              v-for="(item,index) in soillist.range"
              :key="index"
            >
              <span :style="'background: '+soillist.color[index]+''"></span>
              {{item}}
            </div>
          </div>
        </div>
        <div class= "show_list">
            <a class="up_show" v-on:click="showList"><i class="now-ui-icons" :class="[ show_list ? 'arrows-1_minimal-down':'arrows-1_minimal-up']"></i></a>
            <a class="download ml-auto" :href="downloadUrl"><i class="now-ui-icons arrows-1_cloud-download-93"></i></a>
            <span>数据列表<i>{{total}}</i></span>
        </div>
        <div class="show_list_record" v-show="show_list" :style="{'margin-top':(!data.length?0:-(148+data.length*38))+'px'}"  ref="show_list_record" >
            <el-table :data="data" v-show="selects.data_category=='quake_history'">
              <el-table-column min-width="50" label="记录ID" property="id"></el-table-column>
              <el-table-column min-width="60" label=" 时间" property="date"></el-table-column>
              <!-- <el-table-column min-width="50" label="震中经度" property="lat"></el-table-column>
              <el-table-column min-width="50" label="震中维度" property="lng"></el-table-column> -->
              <el-table-column min-width="20" label="震级" property="level"></el-table-column>
              <el-table-column min-width="50" label="震中烈度" property="intensity"></el-table-column>
              <el-table-column min-width="50" label="震中区域" property="region"></el-table-column>
          </el-table>
            <el-table :data="data" v-show="selects.data_category=='chasm'">
              <el-table-column min-width="50" label="断层ID" property="id"></el-table-column>
              <el-table-column min-width="150" label="断裂名称" property="name"></el-table-column>
              <el-table-column min-width="50" label="走向" property="towards"></el-table-column>
              <el-table-column min-width="50" label="倾向" property="tendency"></el-table-column>
              <el-table-column min-width="50" label="倾角" property="angle"></el-table-column>
              <el-table-column min-width="100" label="性质" property="nature"></el-table-column>
              <el-table-column min-width="50" label="规模" property="scale"></el-table-column>
              <el-table-column min-width="150" label="最近活动时代" property="latest_trend"></el-table-column>
          </el-table>
           <el-table :data="data" v-show="selects.data_category=='quake_region'">
              <el-table-column min-width="150" label="区划ID" property="id"></el-table-column>
              <el-table-column min-width="150" label="名称" property="name"></el-table-column>
              <el-table-column min-width="150" label="震级上限" property="highest_level"></el-table-column>
          </el-table>
          <el-table :data="data" v-show="selects.data_category=='soil'">
              <el-table-column min-width="150" label="岩土的类型" property="type"></el-table-column>
              <el-table-column min-width="150" label="岩土的名称和性质" property="character"></el-table-column>
               <el-table-column min-width="150" label="剪切波速范围Vs(m/s)" property="range"></el-table-column>
                <el-table-column min-width="150" label="工程地质层组或亚层" property="geological_strata"></el-table-column>
          </el-table>
          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <div class="">
            <p class="card-category">显示： {{from + 1}} - {{to}} 总数： {{total}} </p>
          </div>
          <n-pagination class="pagination-no-border"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="total"
                        v-on:input="changePage"
                        >
          </n-pagination>
        </div>
        </div>
      </card>
    </div>
    <div class="col-md-3 hasDecision" id="customStyle">
        <div class="col-md-12 clearfix key-decision" >
          <el-select
            class="select-default"
            size="large"
            placeholder="数据集类型选择"
            v-model="selects.data_category"
            v-on:change="handelMapLayer"
          >
            <el-option
              v-for="option in selects.data_categories"
              class="select-primary"
              :value="option.id"
              :label="option.name"
              :key="option.id"
            ></el-option>
          </el-select>
        </div>
        <el-collapse v-model="activeNames">
          <!-- <el-collapse-item title="一致性 Consistency" name="1">
            <form>
              <div class="input-group no-border">
                <fg-input
                  placeholder="输入关键字..."
                  v-on:keyup.enter="reloadData"
                  v-model="keyword"
                  addon-right-icon="now-ui-icons ui-1_zoom-bold"
                ></fg-input>
                <div class="form-text" style="color:#999;margin-left:20px;">输入 建筑、街道、社区关键字</div>
              </div>
            </form>
          </el-collapse-item> -->
          <el-collapse-item title="信息图表" name="2">
            <div class="col-md-12" v-show="selects.data_category=='quake_history'">
              <div class="chart-area" id="box-chart" style="height:400px"></div>
            </div>
            <div class="col-md-12" v-show="selects.data_category=='chasm'">
              <div class="chart-area" id="box-chart2" style="height:400px"></div>
            </div>
            <div class="col-md-12" v-show="selects.data_category=='quake_region'">
              <div class="chart-area" id="box-chart3" style="height:400px"></div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="数据筛选" name="3">
            <div class="col-md-12" v-show="selects.data_category=='quake_history'">
              <el-select
                class="select-default"
                size="large"
                placeholder="震级"
                v-model="selects.quakeLevel"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="(option,index) in selects.quakeLevels"
                  class="select-primary"
                  :value="index"
                  :label="option"
                  :key="index"
                ></el-option>
              </el-select>
            </div>
             <div class="col-md-12" v-show="selects.data_category=='quake_history'">
              <el-select
                class="select-default"
                size="large"
                placeholder="年代"
                v-model="selects.quakeYear"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="(option,index) in selects.quakeYears"
                  class="select-primary"
                  :value="index"
                  :label="option"
                  :key="index"
                ></el-option>
              </el-select>
            </div>
            <div class="col-md-12" v-show="selects.data_category=='chasm'">
              <el-select
                class="select-default"
                size="large"
                placeholder="断层类型"
                v-model="selects.chasmsType"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="option in selects.chasmsTypes"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id"
                ></el-option>
              </el-select>
            </div>
             <div class="col-md-12" v-show="selects.data_category=='quake_region'">
              <el-select
                class="select-default"
                size="large"
                placeholder="震级上限"
                v-model="selects.quakeHighestLevel"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="option in selects.quakeHighestLevels"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id"
                ></el-option>
              </el-select>
            </div>
          </el-collapse-item>
        </el-collapse>
        
      
    </div>
    <div class="key-infos">
      <div class="key-info" v-show="selects.data_category == 'quake_history'">
        共{{statistics.quake_history.count}}条历史地震记录，最大震级{{statistics.quake_history.max_level}}级，最小震级{{statistics.quake_history.min_level}}级
        <!-- 总人口：{{statistics.people.total_population}},性别比：{{statistics.people.rate}} -->
      </div>
      <div class="key-info" v-show="selects.data_category == 'chasm'">
        共{{statistics.chasm.count}}条断层
        <!-- {{statistics.hideaways.count}}个避难所，总容量{{statistics.hideaways.persons}}人 -->
      </div>
      <div class="key-info" v-show="selects.data_category == 'quake_region'">
        潜在震源区{{statistics.quake_region.count}}个，最大震级上限{{statistics.quake_region.max_level}}级，最小震级上限{{statistics.quake_region.min_level}}级
        <!-- {{statistics.hospital.general_count}}家综合医院，{{statistics.hospital.community_count}}家社区医院，总床位数{{statistics.hospital.beds}}个 -->
      </div>
      <div class="key-info" v-show="selects.data_category == 'soil'">
        场地土类型：{{statistics.soil.soil_type}}
        <!-- {{statistics.warehouse.count}}个应急储备仓库，总面积{{statistics.warehouse.area}}平米 -->
      </div>
    </div>
  </div>
</template>
<script>
import mapboxgl from 'mapbox-gl'
import MenuMap from "src/components/Menumap";
import geobuf from "geobuf";
import Pbf from "pbf";
import MiniMap from "src/mapbox/mapboxgl-minimap.js"
import { TabPane, Tabs, Switch } from 'src/components';
import { Radio } from 'src/components/index';
import {
  Input,
  Button,
  Select,
  Option,
  Collapse, CollapseItem,
  Table, 
  TableColumn
} from 'element-ui';
import {consts} from 'src/util/consts.js';
import {getCategories,getStreets,getCommunities,getList,getGeoJson} from 'src/api/building.js';
import {getChasmsType,getQuakeHistories,getQuakeRegions,getChasms,getSoils,getQuakeHistoriesGeo,getQuakeRegionsGeo,getChasmsGeo,getSoilsGeo,getQuakeStatistics,getQuakeChasmsStatistics,getQuakeRegionsStatistics,getSoilsStatistics} from 'src/api/disaster.js';
import {byStruct,quakeHistoryLevel,quakeHistoryYear,quakeHistory,quakeChasm,quakeRegion} from 'src/api/chart.js';
import {buildBarOption,buildBarStackYValueOption} from 'src/util/chart.js';
import {getQuakeYearRange,getQuakeLevelRange,storeMapOptions,getMapOptions,parseJsonToString } from 'src/util/common.js';
import { Pagination as NPagination } from 'src/components';

import * as THREE from 'three';

export default {
  components: {
    MenuMap,
    TabPane,
    Tabs,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    NPagination,
    [Switch.name]: Switch,
    Radio
  },
  data() {
    return {
      activeNames: ['2','3'],
      activeName: 'first',
      tabs: {
        horizontal: 'Profile',
        vertical: 'Options',
        verticalPills: 'Home',
        pageCategories: 'Messages'
      },
      selects:{
        quakeLevel:0,
        quakeLevels:consts.historyQuakeLevels,
        quakeYear:0,
        quakeYears:consts.historyQuakeYears,
        chasmsType: 0,
        chasmsTypes: [],
        quakeHighestLevel:0,
        quakeHighestLevels:[
          {"id":0,"name":"震级上限"},
          {"id":5.5,"name":5.5},
          {"id":6.0,"name":6.0},
          {"id":6.5,"name":6.5},
          {"id":7.0,"name":7.0}
        ],
        data_category: 'quake_history',
        data_categories: [
          { id: 'quake_history', name: "历史地震记录" },
          { id: 'chasm', name: "断层" },
          { id: 'quake_region', name: "潜在震源" },
          { id: 'soil', name: "场地土" }
        ]
      },
      keyword:'',
      total:0,
      data:[],
      page:1,
      map:{},
      color_category:'struct',
      city_id:localStorage.getItem('city_id') || 1,
      show_list:false,
      show_list_height:0,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 20
      },
      last_updated: consts.last_updated,
      data_type:'quake_history',
      defaultLocation:{
        1: [120.76567181161363,30.32392008416531]
      },
      defaultZoom:{
        1: 6.5
      },
      mapdefaultSetting: {},
      statistics:{
        quake_history: {
          count: '',
          max_level: '',
          min_level: ''
        },
        chasm: {
          count: ''
        },
        quake_region: {
          count: '',
          max_level: '',
          min_level: ''
        },
        soil: {
          soil_type: '',
        }
        
      },
      quakelist: {range:['小于4.0','4.0-5.0','5.0-6.0','6.0-7.0','7.0以上'],color:["#4d71bf", "#6f9fa6", "#f6c243", "#c9866b", "#b22418"]},
      chasmlist: {range:[],color:["#b22418", "#c9866b", "#f6c243", "#6f9fa6", "#4d71bf"]},
      quakeRegionlist: {range:['5.5','6','6.5','7'],color:["#4d71bf", "#6f9fa6", "#f6c243", "#c9866b"]},
      soillist: {
        range:['IV类'],color:["#f96332"]
      },
      downloadUrl: ''
    };
  },

  mounted() {
      let _this = this;
      //this.fillStreets(this.city_id);
      //this.fillCommunities(this.city_id,0);
      this.showChart(this.city_id);
      //this.fillBuildings(this.city_id, 1);
      this.initMap(this.city_id);
      //this.fillBuildingsMap(this.city_id, this.color_category);
      //this.initDataLayersToMap(this.map);
      //this.addLayerToMap(this.map, this.data_type);
      //this.loadData(this.data_type);
      
      getChasmsType().then(res=>
      {
        this.selects.chasmsTypes = res.data;
        res.data.forEach((item,idx)=>{
          this.chasmlist.range.push(item.name)
        })
        this.selects.chasmsTypes.unshift({"id":0,"name":"断层类型"},)
        
        
      })
      
      this.map.on('style.load', function(){
        _this.loadRegionData()
        _this.reloadData()
        
        //_this.fillBlockmapLayer(this.city_id);
      })
  },

  computed:{
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      }
  },
  beforeRouteLeave(to, from, next) {
    //console.log('my beforeRouteLeave');
    next();
  },
  methods: {
      // 初始化
    initMap(city_id) {
        this.mapdefaultSetting = {
          center: consts.mapCenter[this.city_id-1],
          zoom: consts.mapDefaultZoom,
          pitch: 0,
          city_id: this.city_id
        }
        mapboxgl.accessToken = consts.mapboxAccessToken;
        var origin = [-95.97299, 36.15031,0];
        let map_options = getMapOptions();
        //console.log(map_options);
        //let zoom = map_options?map_options.zoom:12;
        let zoom = this.defaultZoom[city_id];
        //let center = map_options?[map_options.lng,map_options.lat]:consts.mapCenter[city_id - 1];
        let center = this.defaultLocation[city_id];
        var map = new mapboxgl.Map({
          container: 'map',
          style: consts.mapBoxStyle,
          //center: [-87.61694, 41.86625],
          center: center,
          zoom: zoom,
          pitch: 0,
          bearing: 0,
          attributionControl:false
        }); 
       this.map = map;
      //定位图标
      map.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl
        })
      );
      this.map.addControl(new mapboxgl.GeolocateControl({
          positionOptions: {
              enableHighAccuracy: true
          },
          trackUserLocation: true
      }));  
      //控制图层
      var nav = new mapboxgl.NavigationControl();
      this.map.addControl(nav, 'top-right');
      //全屏按钮
      this.map.addControl(new mapboxgl.FullscreenControl({
        container: document.querySelector('body')
        })
      );
      //小地图
      //https://github.com/brendanmatkin/mapboxgl-minimap
      map.on("style.load", function () {
        // Possible position values are 'bottom-left', 'bottom-right', 'top-left', 'top-right'
        map.addControl(new MiniMap({
          id: "mapboxgl-minimap",
          width: "200px",
          height: "150px",
          //style: "mapbox://styles/mapbox/streets-v10",
          style: consts.mapBoxStyle,
          fillColor: "#f58220",
          zoomAdjust: null
          }),
          'bottom-right');
      }); 
      //记录地图设置
      map.on('move', function() {
          // storeMapOptions({
          //   lng: map.getCenter().lng,
          //   lat: map.getCenter().lat,
          //   zoom: map.getZoom()
          // });
          //console.log(map.getCenter().lng, map.getCenter().lat, map.getZoom())
      });



    },
    initDataLayersToMap(map) {
      let types = ['quake_history','quake_region','chasm','soil'];
      types.forEach(value => {
            this.addLayerToMap(this.map, value);
      });
    },
    addBuildings2Map(map, geoJsonData) {
        let layer_id = 'room-extrusion';
        let source_name = 'geojson';
        var layer = map.getLayer(layer_id);
        if (layer) {
          map.removeLayer(layer_id);
          map.removeSource(source_name);
        }
        map.addSource(source_name, {
          "type": "geojson",
          "data": geoJsonData
        });
        map.addLayer({
        'id': layer_id,
        'type': 'fill-extrusion',
        'source':source_name,
        //'source': {
          // GeoJSON Data source used in vector tiles, documented at
          // https://gist.github.com/ryanbaumann/a7d970386ce59d11c16278b90dde094d
        //  'type': 'geojson',
        //  'data':geoJsonData,
          //'data': "http://api.urban.test/api/buildings-geo-json?struct_id=2&category_id=1&street_id=1"
          //'data': "https://docs.mapbox.com/mapbox-gl-js/assets/indoor-3d-map.geojson"
        //},
        'paint': {
        // See the Mapbox Style Specification for details on data expressions.
        // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions
        
        // Get the fill-extrusion-color from the source 'color' property.
        'fill-extrusion-color': ['get', 'color'],
        
        // Get fill-extrusion-height from the source 'height' property.
        'fill-extrusion-height': ['get', 'height'],
        
        // Get fill-extrusion-base from the source 'base_height' property.
        'fill-extrusion-base': ['get', 'base_height'],
        
        // Make extrusions slightly opaque for see through indoor walls.
        'fill-extrusion-opacity': 0.5
        }
        });
    },
    showList(){
      this.show_list=!this.show_list;
    },
    donwloadList(){

    },
    showChart(city_id) {
      var container = document.querySelector('.el-collapse');
      var box = document.getElementById('box-chart')
      box.style.width = container.offsetWidth + "px";
      var myChart = this.$echarts.init(box);
      // quakeHistoryLevel().then(res=>{
      //   var chart_data = res.data;
      //   var chart_name = '历史地震记录(震级)';
      //   var option = buildBarOption(chart_name, chart_data,'name');
      //   myChart.setOption(option);
      // });


      
      quakeHistory().then(res=>{
        var chart_data = res.data;
        var chart_name = '历史地震记录';
        chart_data.seriesData.map((item,idx) => {
          return item.color = this.quakelist.color[idx]
        })
        var option = buildBarStackYValueOption(chart_name, chart_data,{
          yAxis: {
            name: '记录数',
            nameLocation: 'end',//坐标轴名称显示位置。
            //nameGap: 30
          },
           grid: {
              left: '6%',
              right: '6%',
              bottom: '40',
              top: '30%',
              containLabel: true
          },
        });
        myChart.setOption(option);
        // myChart.on('legendselectchanged', function (params) {
        //     console.log(params);
        // });
      });
     
   
      
      var box2 = document.getElementById('box-chart2')
      box2.style.width = container.offsetWidth + "px";
      var myChart2 = this.$echarts.init(box2);
      quakeChasm().then(res=>{
        var chart_data = res.data;
        var chart_name = '断层性质';
        chart_data.map((item,idx) => {
          return item.color = this.chasmlist.color[idx]
        })
        var option = buildBarOption(chart_name, chart_data, 'name',{
          yAxis: {
            name: '断层数量',
            nameLocation: 'end',//坐标轴名称显示位置。
          }
        });
        myChart2.setOption(option);
      });

      var box3 = document.getElementById('box-chart3')
      box3.style.width = container.offsetWidth + "px";
      var myChart3 = this.$echarts.init(box3);
      quakeRegion().then(res=>{
        var chart_data = res.data;
        var chart_name = '潜在震源震级上限';
        chart_data.map((item,idx) => {
          return item.color = this.quakeRegionlist.color[idx]
        })
        var option = buildBarOption(chart_name, chart_data, 'name',{
          yAxis: {
            name: '震源数',
            nameLocation: 'end',//坐标轴名称显示位置。
          }
        });
        myChart3.setOption(option);
      });

      
      window.onresize = function() {
        document.querySelectorAll('.chart-area').forEach((item,idx)=>{
          item.style.width = document.querySelector('.el-collapse').offsetWidth+'px';
        })
        myChart.resize(); 
        myChart2.resize(); 
        myChart3.resize(); 
      }

    },
    fillStreets(city_id) {
      getStreets(city_id).then(res=>
      {
        let data = res.data;
        data.unshift({"id":0,"name":"街道"});
        this.selects.streets = data;
      });
    },
    fillCommunities(city_id, street_id) {
      getCommunities(city_id, street_id).then(res=>
      {
        let data = res.data;
        data.unshift({"id":0,"name":"社区"});
        this.selects.communities = data;
      });
    },
    fillBuildings(city_id, page) {
      let params = this.buildParams();
      params.page = page;
      getList(params).then(res=>
      {
        this.total = res.data.total;
        this.data = res.data.items;
      })
    },
    fillBuildingsMap(city_id, color_category) {
      let params = this.buildParams();
      params.color_category = color_category;
      getGeoJson(params).then(res=>
      {
        let geoJson = res.data;
        this.addBuildings2Map(this.map, geoJson);
      })
    },
    fillSoilsData(page) {
      let params = this.buildParams();
      params.page = page;
      getSoils(params).then(res=>
      {
        
        this.total = res.data.total;
        this.data = res.data.items;
      })
      getSoilsStatistics(params).then(res => {
        this.statistics.soil.soil_type = res.data.soil_type;
      });
      this.downloadUrl = consts.requestUrl + consts.exportDisasterSoil + parseJsonToString(params)
    },
    fillChasmsData(page) {
      let params = this.buildParams();
      params.page = page;
      getChasms(params).then(res=>
      {
        this.total = res.data.total;
        this.data = res.data.items;
      })
      getQuakeChasmsStatistics(params).then(res => {
        this.statistics.chasm.count = res.data.count;
      });
      this.downloadUrl = consts.requestUrl + consts.exportDisasterChasm + parseJsonToString(params)
    },
    fillQuakeRegionsData(page) {
      let params = this.buildParams();
      params.page = page;
      getQuakeRegions(params).then(res=>
      {
        this.total = res.data.total;
        this.data = res.data.items;
      })
      getQuakeRegionsStatistics(params).then(res => {
        this.statistics.quake_region.count = res.data.count;
        this.statistics.quake_region.max_level = res.data.max_level;
        this.statistics.quake_region.min_level = res.data.min_level;
      });
      this.downloadUrl = consts.requestUrl + consts.exportDisasterRegion + parseJsonToString(params)
    },
    fillQuakeHistoriesData(page) {
      let params = this.buildParams();
      params.page = page;
      getQuakeHistories(params).then(res=>
      {
        // res.data.items.forEach((item,index)=> {
        //   item.date = item.year+'-'+(!!item.month?item.month:0)+'-'+(!!item.day?item.day:0);
        // })
        this.total = res.data.total;
        this.data = res.data.items;
      
      })
      getQuakeStatistics(params).then(res => {
        this.statistics.quake_history.count = res.data.count;
        this.statistics.quake_history.max_level = res.data.max_level;
        this.statistics.quake_history.min_level = res.data.min_level;
      });
      this.downloadUrl = consts.requestUrl + consts.exportDisasterQuake + parseJsonToString(params)
    },
    reloadData() {
      //this.loadData(this.data_type);
      //this.fillBuildings(this.city_id, 1);
      //this.fillBuildingsMap(this.city_id, this.color_category);  
      this.handelMapLayer()    
    },
    setTipContent() {
      let html = ''
      if(this.selects.data_category == 'quake_history') {
        html = consts.tipsDisasterQuake
      } else if(this.selects.data_category == 'chasm') {
        html = consts.tipsDisasterChasm
      } else if(this.selects.data_category == 'quake_region') {
        html = consts.tipsDisasterRegion
      } else if(this.selects.data_category == 'soil') {
        html = consts.tipsDisasterSoil
      }
      //console.log('3333',document.getElementById('left-tip-content'))
      document.getElementById('left-tip-content').innerHTML = html
    },
    buildParams() {
      let params = {city_id:this.city_id};
      // params.quake_year = this.selects.quakeYear;
      // params.quake_level = this.selects.quakeLevel;
      // params.highest_level = this.selects.quakeHighestLevel;
      params.page_size = 10;
      if(this.selects.data_category == 'quake_history') {
        let range_year = getQuakeYearRange(this.selects.quakeYear, consts.historyQuakeYears);
        //console.log(range_year);
        let year_start = range_year.start;
        let year_end = range_year.end;
        params.year_start = year_start;
        params.year_end = year_end;

        let range_level = getQuakeLevelRange(this.selects.quakeLevel, consts.historyQuakeLevels);
        //console.log(range_level);
        let level_start = range_level.start;
        let level_end = range_level.end;
        params.level_start = level_start;
        params.level_end = level_end;
      } else if(this.selects.data_category == 'chasm') {
        params.type = this.selects.chasmsType;
      }else if(this.selects.data_category == 'quake_region') {
        params.level = this.selects.quakeHighestLevel;
      }else if(this.selects.data_category == 'soil') {
        params.type = this.selects.chasmsType;
      }
      return params;
    },
    changePage:function(value) {
      if (value != this.page) {
          this.loadData(this.selects.data_category, value);
      }
      this.page = value;
    },
    makePointImage(map, type){
      let size = 150;
      switch(type){
        case 1:
          size = 80;
        break;
        case 2:
          size = 100;
        break;
        case 3:
          size = 120;
        break;
        case 4:
          size = 140;
        break;
        case 5:
          size = 160;
        break;
      }
      //point type
      let pulsingDot  = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // get rendering context for the map canvas when layer is added to the map
        onAdd: function() {
          var canvas = document.createElement("canvas");
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext("2d");
        },

        // called once before every frame where the icon will be used
        render: function() {
          var duration = 1000;
          var t = (performance.now() % duration) / duration;

          var radius = (size / 2) * 0.3;
          var outerRadius = (size / 2) * 0.7 * t + radius;
          var context = this.context;

          // draw outer circle
          context.clearRect(0, 0, this.width, this.height);
          context.beginPath();
          context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2
          );
          switch(type){
            case 1:
              context.fillStyle = "rgba(78, 114, 189," + (1 - t) + ")";
            break;
            case 2:
              context.fillStyle = "rgba(110, 159, 165," + (1 - t) + ")";
            break;
            case 3:
              context.fillStyle = "rgba(246, 194, 67," + (1 - t) + ")";
              break;
            case 4:
              context.fillStyle = "rgba(201, 134, 107," + (1 - t) + ")";
              break;
            case 5:
              context.fillStyle = "rgba(177, 36, 24," + (1 - t) + ")";
              break;
          }
          
          context.fill();

          // draw inner circle
          context.beginPath();
          context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
          switch(type){
            case 1:
              context.fillStyle = "rgba(78, 114, 189, 1)";
            break;
            case 2:
              context.fillStyle = "rgba(110, 159, 165, 1)";
            break;
            case 3:
              context.fillStyle = "rgba(246, 194, 67, 1)";
            break;
            case 4:
              context.fillStyle = "rgba(201, 134, 107, 1)";
              break;
            case 5:
              context.fillStyle = "rgba(177, 36, 24, 1)";
              break;
          }
          
          context.strokeStyle = "white";
          context.lineWidth = 2 + 4 * (1 - t);
          context.fill();
          //context.stroke();
          // update this image's data with data from the canvas
          this.data = context.getImageData(0, 0, this.width, this.height).data;

          // continuously repaint the map, resulting in the smooth animation of the dot
          map.triggerRepaint();

          // return `true` to let the map know that the image was updated
          return true;
        }
      }
      map.addImage("dot-"+type, pulsingDot, { pixelRatio: 2 });
    },
    addQuakeHistoryLayerToMap(map,geojson) {
      let type = 'quake_history';
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      if(typeof geojson.features == 'undefined') return;
      //let geojson_url = consts.url_geojson_quake_history;
      if(!map.hasImage("dot-1")) {
        this.makePointImage(map, 1) //<4
        this.makePointImage(map, 2) //4-5
        this.makePointImage(map, 3) //5-6
        this.makePointImage(map, 4) //6-7
        this.makePointImage(map, 5) //>7
        
      }


      geojson.features.forEach((item,idx)=>{
        if(item.properties.level <= 4){
          item.properties.type = 1
        } else if(item.properties.level <= 5){
          item.properties.type = 2
        }else if(item.properties.level <= 6){
          item.properties.type = 3
        }else if(item.properties.level <= 7){
          item.properties.type = 4
        }else {
          item.properties.type = 5
        }
      })

      map.addSource(source_name, {
        type: "geojson",
        data: geojson
       }
      );
      map.addLayer({
        id: layer_id,
        type: "symbol",
        source: source_name,
        paint: {
          "text-color": "#ffffff"
        },
        layout: {
          "icon-image": ["concat", "dot-", ["get","type"]],
          "text-field": ["get", "region"],
          "text-size": 12,
          "text-offset": [0, -3],
          "text-anchor": "top"
        }
      });
      // let icon = "religious-jewish-15";
      // var styleLoadStatus = map.isStyleLoaded();
      // if (styleLoadStatus) {
      //   map.addSource(source_name, {
      //     type: "geojson",
      //     data: geojson
      //   });
      //   map.addLayer({
      //     id: layer_id,
      //     type: "symbol",
      //     source: source_name,
      //     paint: {
      //       "text-color": "#f96332"
      //     },
      //     layout: {
      //       "icon-image": icon,
      //       "text-field": "{EpicentralZone}",
      //       //"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
      //       "text-offset": [0, 0.6],
      //       "text-anchor": "top"
      //     }
      //   });
      //   return;
      // }
      // map.on("load", function() {
      //   map.addSource(source_name, {
      //     type: "geojson",
      //     data: geojson_url
      //   });
      //   map.addLayer({
      //     id: layer_id,
      //     type: "symbol",
      //     source: source_name,
      //     paint: {
      //       "text-color": "#f96332"
      //     },
      //     layout: {
      //       "icon-image": icon,
      //       "text-field": "{EpicentralZone}",
      //       //"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
      //       "text-offset": [0, 0.6],
      //       "text-anchor": "top"
      //     }
      //   });
      // });
    },
    removeMapLayer(map, type) {
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      var layer = map.getLayer(layer_id);
      if (layer) {
        map.removeLayer(layer_id);
        map.removeSource(source_name);
      }
    },
    addQuakeRegionLayerToMap(map,geojson) {
      let type  = 'quake_region';
      this.removeMapLayer(map,type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      if(typeof geojson.features == 'undefined') return;
      map.addSource(source_name, {
        type: "geojson",
        data: geojson
      });
      // map.addLayer({
      //   id: layer_id,
      //   type: "line",
      //   source: source_name,
      //   "layout": {
      //     "line-join": "round",
      //     "line-cap": "round"
      //   },
      //     "paint": {
      //     "line-color": "#f96332",
      //     "line-width": 8
      //   }
      // });
      map.addLayer({
        id: layer_id,
        type: "fill",
        source: source_name,
        'paint': {
          'fill-color': [
              "step",
              ["get", "highest_level"],
              "#4d71bf", 5.6,
              "#6f9fa6",
              6.1,
              "#f6c243",
              6.6,
               "#c9866b",
              7.1,
              "#c9866b"
            ],
          'fill-opacity': 0.8
        }
      });
      //let geojson_url = consts.url_geojson_quake_region;
      // let icon = "garden-15";
      // var styleLoadStatus = map.isStyleLoaded();
      // if (styleLoadStatus) {
      //   map.addSource(source_name, {
      //     type: "geojson",
      //     data: geojson_url
      //   });
      //   // map.addLayer({
      //   //   id: layer_id,
      //   //   type: "line",
      //   //   source: source_name,
      //   //   "layout": {
      //   //     "line-join": "round",
      //   //     "line-cap": "round"
      //   //   },
      //   //     "paint": {
      //   //     "line-color": "#f96332",
      //   //     "line-width": 8
      //   //   }
      //   // });
      //   map.addLayer({
      //     id: layer_id,
      //     type: "fill",
      //     source: source_name,
      //     'paint': {
      //       'fill-color': '#f96332',
      //       'fill-opacity': 0.8
      //     }
      //   });
      //   return;
      // }
      // map.on("load", function() {
      //   map.addSource(source_name, {
      //     type: "geojson",
      //     data: geojson_url
      //   });
      //   // map.addLayer({
      //   //   id: layer_id,
      //   //   type: "line",
      //   //   source: source_name,
      //   //   "layout": {
      //   //     "line-join": "round",
      //   //     "line-cap": "round"
      //   //   },
      //   //     "paint": {
      //   //     "line-color": "#f96332",
      //   //     "line-width": 8
      //   //   }
      //   // });
      //   map.addLayer({
      //     id: layer_id,
      //     type: "fill",
      //     source: source_name,
      //     'paint': {
      //       'fill-color': '#f96332',
      //       'fill-opacity': 0.8
      //     }
      //   });
      // });
    },
    addSoilLayerToMap(map,geojson) {
      let type = 'soil';
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      if(typeof geojson.features == 'undefined') return;
        map.addSource(source_name, {
          type: "geojson",
          data: geojson
        });
        map.addLayer({
          id: layer_id,
          type: "fill",
          source: source_name,
          'paint': {
            'fill-color': '#f96332',
            'fill-opacity': 0.8
          }
        });
      //let geojson_url = consts.url_geojson_soil;
      //let icon = "garden-15";
      //var styleLoadStatus = map.isStyleLoaded();
      // if (styleLoadStatus) {
      //   map.addSource(source_name, {
      //     type: "geojson",
      //     data: geojson
      //   });
      //   map.addLayer({
      //     id: layer_id,
      //     type: "fill",
      //     source: source_name,
      //     'paint': {
      //       'fill-color': '#f96332',
      //       'fill-opacity': 0.8
      //     }
      //   });
      //   return;
      // }
      // map.on("load", function() {
      //   map.addSource(source_name, {
      //     type: "geojson",
      //     data: geojson_url
      //   });
      //   map.addLayer({
      //     id: layer_id,
      //     type: "fill",
      //     source: source_name,
      //     'paint': {
      //       'fill-color': '#f96332',
      //       'fill-opacity': 0.8
      //     }
      //   });
      // });
    },
    addChasmLayerToMap(map,geojson) {
      let type  = 'chasm';
      this.removeMapLayer(map,type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      let linecolor = ''
      if(typeof geojson.features == 'undefined') return;



      map.addSource(source_name, {
          type: "geojson",
          data: geojson
        });
        map.addLayer({
          id: layer_id,
          type: "line",
          source: source_name,
          "layout": {
            "line-join": "round",
            "line-cap": "round"
          },
            "paint": {
            "line-color": [
              "match",
              ["get", "type"],
              1,
              "#b22418",
              2,
              "#c9866b",
              3,
              "#f6c243",
              4,
              "#6f9fa6",
              5,
              "#4d71bf",
              "#4d71bf"
            ],
            "line-width": 4
          }
        });

      //let geojson_url = consts.url_geojson_chasm;
      // var styleLoadStatus = map.isStyleLoaded();
      // if (styleLoadStatus) {
      //   map.addSource(source_name, {
      //     type: "geojson",
      //     data: geojson
      //   });
      //   map.addLayer({
      //     id: layer_id,
      //     type: "line",
      //     source: source_name,
      //     "layout": {
      //       "line-join": "round",
      //       "line-cap": "round"
      //     },
      //       "paint": {
      //       "line-color": "#f96332",
      //       "line-width": 8
      //     }
      //   });
      //   return;
      // }
      // map.on("load", function() {
      //   map.addSource(source_name, {
      //     type: "geojson",
      //     data: geojson_url
      //   });
      //   map.addLayer({
      //     id: layer_id,
      //     type: "line",
      //     source: source_name,
      //     "layout": {
      //       "line-join": "round",
      //       "line-cap": "round"
      //     },
      //       "paint": {
      //       "line-color": "#f96332",
      //       "line-width": 8
      //     }
      //   });
      // });
    },
    getMapLayerId(type) {
        let layer_id = type+'-layer';
        return layer_id;
    },
    getMapSourceName(type) {
      let source_name = type+"-data";
      return source_name;
    },
    fillLayerToMap(map, type) {
      let params = this.buildParams();
      switch(type) {
        case 'quake_history':
          getQuakeHistoriesGeo(params).then(data => {
            let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
            //this.addBlockmapLayer(this.map,'region')
            this.addQuakeHistoryLayerToMap(map,geoJson);
          });
          break;
        case 'quake_region':
          getQuakeRegionsGeo(params).then(data => {
            let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
            //this.addBlockmapLayer(this.map,'region')
            this.addQuakeRegionLayerToMap(map,geoJson)
          });
          break;
        case 'chasm':
          getChasmsGeo(params).then(data => {
            let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
            //this.addBlockmapLayer(this.map,'region')
            this.addChasmLayerToMap(map,geoJson);
          });
          break;  
        case 'soil':
          //this.addBlockmapLayer(this.map,'region')
          // getSoilsGeo(params).then(data => {
          //   let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
            
          //   //this.removeMapLayer(this.map, 'region');
          //   //this.addSoilLayerToMap(map,geoJson);
          // });
          break;                        
      }
      
      
    },
    // addLayerToMap(map, type) {
    //   switch(type) {
    //     case 'quake_history':
    //       this.addQuakeHistoryLayerToMap(map);
    //       break;
    //     case 'quake_region':
    //       this.addQuakeRegionLayerToMap(map);
    //       break;
    //     case 'chasm':
    //       this.addChasmLayerToMap(map);
    //       break;  
    //     case 'soil':
    //       this.addSoilLayerToMap(map);
    //       break;                        
    //   }
    // },
    handelMapLayer() {
      this.setTipContent()
      let type = this.selects.data_category;
      this.loadData(type);
      let types = ['quake_history','quake_region','chasm','soil'];
      //let types = ['soil'];
      types.forEach(value => {
            if(value==type) {
              if(type == 'soil') {
                //  this.map.zoomTo(12.5, {
                //   duration: 2000,
                //   //offset: [100, 50]
                // });
                this.map.setCenter(consts.mapCenter[this.city_id - 1])
                this.map.setZoom(12)
              } else {
                this.map.setCenter(this.defaultLocation[this.city_id])
                this.map.setZoom(this.defaultZoom[this.city_id])
              }
              this.fillLayerToMap(this.map, value);
              //this.addLayerToMap(this.map, value);
            } else {
              this.removeMapLayer(this.map, value);
            }
      });
    },
    loadData(type, page=1) {
      switch(type) {
        case 'quake_history':
          this.fillQuakeHistoriesData(page);
          break;
        case 'quake_region':
          this.fillQuakeRegionsData(page);
          break;
        case 'chasm':
          this.fillChasmsData(page);     
          break;  
        case 'soil':
          this.fillSoilsData(page);
          break;                        
      }
    },
    addBlockmapLayer(map, type, geoJson) {
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      //let source_name_stroke = source_name+'_stroke';
      let geodata;
      if(type == 'region') {
        geodata = this.regionGeojson
      }
      map.addSource(source_name, {
        type: "geojson",
        data: geodata
      });
      let colorExpress = '';
      let fillOpacity = 1;
      if(type == 'region') {
        colorExpress = '#f96332'
        fillOpacity = 0.8
      }
      map.addLayer({
        id: layer_id,
        type: "fill",
        source: source_name,
        layout: {},
        paint: {
          "fill-color": colorExpress,
          "fill-opacity": fillOpacity
        }
      });
     
    },
    loadRegionData (){
      let params = this.buildParams();
      getSoilsGeo(params).then(data => {
        let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        this.regionGeojson = geoJson;
        this.addBlockmapLayer(this.map,'region')
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.card-map {
  min-height: 350px;
  height: calc(100vh - 48px);
  margin-bottom: 0;

  position: relative;
  .map-wrapper{
    width: 100%;
    height: calc(100vh - 100px);
    position: relative;
    & > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  #map {
    width: 100%;
    height: calc(100vh - 100px);
  }
}
.main-panel {
    // height: calc(100vh - 130px);
    min-height: 350px;
}
.right-block{
    height: calc(100vh - 170px);
    min-height: 350px;
    overflow: hidden;
}
.tab-scroller{
    height: calc(100vh - 470px);
    min-height: 350px;
    overflow-y: auto;
}

.show_list{
    width: 100%;
    height: 50px;
    margin-top:8px;
}
.show_list span{
    height: 32px;
    line-height: 36px;
    margin-left: 32px;
    font-size: 14px;
}
.show_list span i{
    height: 16px;
    padding: 2px 12px;
    background: #FFB236;
    margin:0 4px;
    border-radius: 16px;
}
.show_list .up_show{
    display: block;
    float: left;
    font-size:32px;
    cursor: pointer;
}
.show_list .download{
    display: block;
    float: right;
    font-size:32px;
    cursor: pointer;
}
.footer{
    padding-top:0;
}
.extended-forms .el-select {
  width: 100%;
  margin-bottom: 30px;
}

.extended-forms .progress {
  margin-bottom: 30px;
}
@import url('https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css');
.coordinates {
    background: rgba(0,0,0,0.5);
    color: #fff;
    position: absolute;
    bottom: 10px;
    left: 10px;
    padding:5px 10px;
    margin: 0;
    font-size: 11px;
    line-height: 18px;
    border-radius: 3px;
    display: none;
}
ul.nav__items > li:last-of-type a{
  margin-right: 0!important;

}
.card-body .tab-content .tab-scroller{
  min-height: auto;
}

.map_data_msg {
  .item-circle span {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    vertical-align: middle;
    margin-left: 0;
    position: relative;
    margin-right: 7px;
  }
  .item-circle span:after {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    border: 1px solid rgba(255,255,255, 0.5);
    left: -5px;
    top: -5px;
  }
  .item-bar {
    width: 200px;
  }
  .item-bar span {
    display: inline-block;
    width: 40px;
    height: 10px;
    vertical-align: middle;
    margin-left: 0;
    position: relative;
    margin-right: 7px;
  }
  .item-block span {
    display: inline-block;
    width: 40px;
    height: 20px;
    vertical-align: middle;
    margin-left: 0;
    position: relative;
    margin-right: 7px;
  }

}
</style>
