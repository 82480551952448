<template>
  <div class="row" style="margin:0 -30px;">
    <div class="col-md-9" style="padding:0">
      <card class="card-map">
        <div class="switch-two-side" v-show="selects.data_category == 0">
          <p>节点进流量</p>
          <n-switch v-model="switchs.type"></n-switch>
          <p>节点出流量</p>
        </div>
        <div id="map"></div>
        <menu-map :map="map" :mapDefault="mapdefaultSetting" showDisaster="false"></menu-map>
        <div class="map_data_msg" v-if="selects.data_category == 0">
          <div class="map_data_legend">节点流量（辆/小时）</div>
          <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
            <div
              class="item"
              v-for="(item,index) in options[0].data"
              :key="index"
            >
              <span :style="'background: '+colors[0][index]" class="round"></span>
              <!-- <img :src="figures[0][index]" class="figure"/> -->
              {{item.name}}
            </div>
          </div>
        </div>
        <div class="map_data_msg" v-if="selects.data_category == 1">
          <div class="map_data_legend">道路流量（辆/小时）</div>
          <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
            <div
              class="item"
              v-for="(item,index) in options[6].data"
              :key="index"
            >
              <span :style="'background: '+colors[1][index]" class="rect"></span>
              <!-- <img :src="figures[0][index]" class="figure"/> -->
              {{item.name}}
            </div>
          </div>
        </div>
        <div class="map_data_msg" v-if="selects.data_category == 2">
          <div class="map_data_legend">交通小区流量（辆/小时）</div>
          <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
            <div
              class="item"
              v-for="(item,index) in options[7].data"
              :key="index"
            >
              <span :style="'background: '+colors[1][index]" class="line"></span>
              <!-- <img :src="figures[0][index]" class="figure"/> -->
              {{item.name}}
            </div>
          </div>
        </div>
        <div class="show_list">
          <a class="up_show" v-on:click="showList">
            <i
              class="now-ui-icons"
              :class="[ show_list ? 'arrows-1_minimal-down':'arrows-1_minimal-up']"
            ></i>
          </a>
          <a class="download"  :href="downloadUrl">
            <i class="now-ui-icons arrows-1_cloud-download-93"></i>
          </a>
          <span>
            数据列表
            <i>{{total}}</i>
          </span>
        </div>
        <div
          class="show_list_record"
          v-show="show_list"
          :style1="{'top':-(data.length>0?145+data.length*38:200)+'px'}"
          ref="show_list_record"
        >
          <el-table :data="data" empty-text = "暂无数据！" v-if="selects.data_category==0">
            <el-table-column min-width="100" label="节点ID" property="node_id"></el-table-column>
            <el-table-column min-width="100" label="节点名" property="type_name"></el-table-column>
            <el-table-column min-width="100" label="节点进流量" property="in_flow"></el-table-column>
            <el-table-column min-width="100" label="节点出流量" property="out_flow"></el-table-column>
            <el-table-column min-width="250" label="地址" property="crossing_road"></el-table-column>
          </el-table>
          
          <el-table :data="data"  empty-text = "暂无数据！" v-if="selects.data_category==1">
            <el-table-column min-width="150" label="路网线路ID" property="line_id"></el-table-column>
            <el-table-column min-width="150" label="路网线路名" property="name"></el-table-column>
            <el-table-column min-width="150" label="道路名称" property="road_name"></el-table-column>
            <el-table-column min-width="150" label="道路等级" property="road_level"></el-table-column>
            <el-table-column min-width="150" label="道路长度" property="road_len"></el-table-column>
            <el-table-column min-width="150" label="自由流速" property="free_flow_speed"></el-table-column>
            <el-table-column min-width="150" label="通行能力" property="capacity"></el-table-column>
            <el-table-column min-width="150" label="道路流量" property="flow"></el-table-column>
          </el-table>

          <el-table :data="data"  empty-text = "暂无数据！" v-if="selects.data_category==2">
            <el-table-column min-width="150" label="交通小区ID" property="zone_id"></el-table-column>
            <el-table-column min-width="150" label="交通小区名" property="name"></el-table-column>
            <el-table-column min-width="150" label="交通流量" property="flow"></el-table-column>
          </el-table>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class>
              <p class="card-category">显示： {{from + 1}} - {{to}} 总数： {{total}}</p>
            </div>
            <n-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              v-on:input="changePage"
            ></n-pagination>
          </div>
        </div>
      </card>
    </div>
     <div class="col-md-3 hasDecision" id="customStyle">
       <div class="col-md-12 clearfix key-decision" >
          <el-select
            class="select-default"  
            size="large"
            placeholder="数据集类型选择"
            v-model="selects.data_category"
            v-on:change="reloadData"
          >
            <el-option
              v-for="option in selects.data_categories"
              class="select-primary"
              :value="option.id"
              :label="option.name"
              :key="option.id"
            ></el-option>
          </el-select>
      </div>
      <el-collapse v-model="activeNames">
        <el-collapse-item title="信息图表" name="1">
          <div class="col-md-12">
              <div class="chart-area" id="box-chart" style="height: 300px" v-show="selects.data_category == 0"></div>
              <div class="chart-area" id="box-chart2" style="height: 260px" v-show="selects.data_category == 1"></div>
              <div class="chart-area" id="box-chart3" style="height: 260px" v-show="selects.data_category == 2"></div>
            </div>
        </el-collapse-item>
        <el-collapse-item title="数据筛选" name="3">
          <div class="col-md-12">
                <div class="row" v-show="selects.data_category == 0">
                  <div class="col-sm-10">
                    <el-select
                      class="select-default"
                      size="large"
                      :placeholder="options[0].name"
                      v-model="options[0].selected"
                      v-on:change="reloadData"
                      multiple="" 
                    >
                      <el-option
                        v-for="(option,index) in options[0].data"
                        class="select-primary"
                        :value="option.id"
                        :label="option.name"
                        :key="option.id"
                      ></el-option>
                    </el-select>
                    <i class="el-icon-refresh-left" @click="clearAll(0)"></i>
                  </div>
                </div>

                <div class="row" v-show="selects.data_category == 0">
                  <div class="col-sm-10">
                    <el-select
                      class="select-default"
                      size="large"
                      :placeholder="options[1].name"
                      v-model="options[1].selected"
                      v-on:change="reloadData"
                      multiple=""
                    >
                      <el-option
                        v-for="(option,index) in options[1].data"
                        class="select-primary"
                        :value="option.id"
                        :label="option.name"
                        :key="option.id"
                      ></el-option>
                    </el-select>
                    <i class="el-icon-refresh-left" @click="clearAll(1)"></i>
                  </div>
                </div>
                <div class="row" v-show="selects.data_category == 1">
                  <div class="col-sm-10">
                    <el-select
                      class="select-default"
                      size="large"
                      :placeholder="options[2].name"
                      v-model="options[2].selected"
                      v-on:change="reloadData"
                      multiple="" 
                    >
                      <el-option
                        v-for="(option,index) in options[2].data"
                        class="select-primary"
                        :value="option.id"
                        :label="option.name"
                        :key="option.id"
                      ></el-option>
                    </el-select>
                    <i class="el-icon-refresh-left" @click="clearAll(2)"></i>
                  </div>
                </div>

                <div class="row" v-show="selects.data_category == 1">
                  <div class="col-sm-10">
                    <el-select
                      class="select-default"
                      size="large"
                      :placeholder="options[3].name"
                      v-model="options[3].selected"
                      v-on:change="reloadData"
                      multiple="" 
                    >
                      <el-option
                        v-for="(option,index) in options[3].data"
                        class="select-primary"
                        :value="option.id"
                        :label="option.name"
                        :key="option.id"
                      ></el-option>
                    </el-select>
                    <i class="el-icon-refresh-left" @click="clearAll(3)"></i>
                  </div>
                </div>

                <div class="row" v-show="selects.data_category == 1">
                  <div class="col-sm-10">
                    <el-select
                      class="select-default"
                      size="large"
                      :placeholder="options[4].name"
                      v-model="options[4].selected"
                      v-on:change="reloadData"
                      multiple="" 
                    >
                      <el-option
                        v-for="(option,index) in options[4].data"
                        class="select-primary"
                        :value="option.id"
                        :label="option.name"
                        :key="option.id"
                      ></el-option>
                    </el-select>
                    <i class="el-icon-refresh-left" @click="clearAll(4)"></i>
                  </div>
                </div>

                <div class="row" v-show="selects.data_category == 1">
                  <div class="col-sm-10">
                    <el-select
                      class="select-default"
                      size="large"
                      :placeholder="options[5].name"
                      v-model="options[5].selected"
                      v-on:change="reloadData"
                      multiple="" 
                    >
                      <el-option
                        v-for="(option,index) in options[5].data"
                        class="select-primary"
                        :value="option.id"
                        :label="option.name"
                        :key="option.id"
                      ></el-option>
                    </el-select>
                    <i class="el-icon-refresh-left" @click="clearAll(5)"></i>
                  </div>
                </div>

                <div class="row" v-show="selects.data_category == 1">
                  <div class="col-sm-10">
                    <el-select
                      class="select-default"
                      size="large"
                      :placeholder="options[6].name"
                      v-model="options[6].selected"
                      v-on:change="reloadData"
                      multiple="" 
                    >
                      <el-option
                        v-for="(option,index) in options[6].data"
                        class="select-primary"
                        :value="option.id"
                        :label="option.name"
                        :key="option.id"
                      ></el-option>
                    </el-select>
                    <i class="el-icon-refresh-left" @click="clearAll(6)"></i>
                  </div>
                </div>


                <div class="row" v-show="selects.data_category == 2">
                  <div class="col-sm-10">
                    <el-select
                      class="select-default"
                      size="large"
                      :placeholder="options[7].name"
                      v-model="options[7].selected"
                      v-on:change="reloadData"
                      multiple="" 
                    >
                      <el-option
                        v-for="(option,index) in options[7].data"
                        class="select-primary"
                        :value="option.id"
                        :label="option.name"
                        :key="option.id"
                      ></el-option>
                    </el-select>
                    <i class="el-icon-refresh-left" @click="clearAll(7)"></i>
                  </div>
                </div>
                
            </div>
        </el-collapse-item>
      </el-collapse>

      
    </div>
    <div class="key-infos">
      <div class="key-info" v-show="selects.data_category == 0">
         节点平均流量{{statistics.flow}}辆/小时
      </div>
      <div class="key-info" v-show="selects.data_category == 1">
         {{statistics.flow}}
      </div>
       <div class="key-info" v-show="selects.data_category == 2">
         交通小区平均流量 {{statistics.avg_flow}}辆/小时
      </div>
    </div>
  </div>
</template>
<script>
import mapboxgl from "mapbox-gl";
import MenuMap from "src/components/Menumap";
import SearchArea from "src/components/SearchArea";
import MiniMap from "src/mapbox/mapboxgl-minimap.js";
import { TabPane, Tabs, Switch } from "src/components";
import geobuf from "geobuf";
import Pbf from "pbf";
import { Checkbox } from "src/components/index";
import { Input, Button, Select, Option, Collapse, CollapseItem, Table, TableColumn } from "element-ui";
import { consts } from "src/util/consts.js";
import {
  getStreets,
  getCommunities,
} from "src/api/building.js";
import {
  getCommonNodeGeojson as getNodeGeo,
  getCommonPipeGeojson as getPipeGeo,
  getNodeInOptions as getOptions1,
  getNodeOutOptions as getOptions2,
  getNodeList as getList1,
  getNodeGeojson as getGeo1,
  getNodeStatistics as getStatistics1,
  getNodeChart as getChart1,
  getPipeLengthOptions as getOptions3,
  getPipeLevelsOptions as getOptions4,
  getPipeSpeedOptions as getOptions5,
  getPipeCapacityOptions as getOptions6,
  getPipeVolumnOptions as getOptions7,
  getVolumnOptions as getOptions8,
  getVolumnList as getList3,
  getPipeVolumnList as getList2,
  getVolumnGeojson as getGeo3,
  getPipeVolumnGeojson as getGeo2,
  getVolumnStatistics as getStatistics3,
  getPipeVolumnStatistics as getStatistics2,
  getVolumnChart as getChart3,
  getPipeVolumnChart as getChart2,
  addHighLigthLayer
} from "src/api/earthquake/traffic.js";
import { 
  buildBarYOption,
  buildBarStackOption,
  buildlineOption,
  buildPieOption } from "src/util/chart.js";
import {
  storeMapOptions,
  getMapOptions,
  parseJsonToString,
  setZoomLevel
} from "src/util/common.js";
import { Pagination as NPagination } from "src/components";
import * as THREE from "three";

export default {
  components: {
    MenuMap,
    SearchArea,
    TabPane,
    Tabs,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    NPagination,
    [Switch.name]: Switch,
    Checkbox,
  },
  data() {
    return {
      activeNames: ['1','2','3'],
      activeName: "first",
      tabs: {
        horizontal: "Profile",
        vertical: "Options",
        verticalPills: "Home",
        pageCategories: "Messages"
      },
      selects: {
        street: 0,
        streets: [{ id: 0, name: "街道" }],
        community: 0,
        communities: [{ id: 0, name: "社区" }],
        data_category: 0,
        data_categories: [
          { id: 0, name: "节点" },
          { id: 1, name: "道路" },
          { id: 2, name: "交通小区" },
        ],
      },
      keyword: "",
      total: 0,
      data: [],
      page: 1,
      map: {},
      color_category: "build_year",
      city_id: localStorage.getItem("city_id") || 1,
      show_list: false,
      show_list_height: 0,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 20
      },
      switchs: {
        type: false,
      },
      last_updated: consts.last_updated,
      
      statistics: {
      },
      mapdefaultSetting: {},
      downloadUrl: '',

      options: [
        {name: '节点进流量',selected:[],data: []},
        {name: '节点出流量',selected:[],data: []},
        {name: '道路长度',selected:[],data: []},
        {name: '道路等级',selected:[],data: []},
        {name: '自由流速',selected:[],data: []},
        {name: '通行能力',selected:[],data: []},
        {name: '道路流量',selected:[],data: []},
        {name: '交通小区流量',selected:[],data: []},
      ],
      colors: [
        ['#4f80b2','#bad8e7','#fdfcc8','#edb579','#c73c34'],
        ['#fbe6a3','#F5C243','#D8833B','#a6263a','#791b3c',"#4c1c40"],
      ],
      figures: [
        []
      ],
      
     
      //colors: ['#D55328','#F5C243','#447DB3','#62BFF5','#70AD47','#94CFBF','#B22FB9','#9c0003']
    };
  },

  mounted() {
    this.fillStreets(this.city_id);
    //this.fillCommunities(this.city_id, 0);
    this.showChart(this.city_id);
    this.fillFigure()
    
    //this.fillList(this.city_id, 1);
    this.initMap(this.city_id);
    this.setTipContent();
    this.map.on('style.load',()=>{
      this.fillCommonGeo();
      this.reloadData()
      //this.fill2Map(this.city_id, this.color_category);
    })
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    // street() {
    //   return this.selects.street;
    // }
  },
  watch: {
    "selects.street"(val) {
      //街道切换选社区
      this.fillCommunities(this.city_id, val);
    },
    "switchs.type"(val) {
      this.fill2Map(this.city_id);
    },
  },

  methods: {
    // 初始化
    initMap(city_id) {
      let _this = this;
      this.mapdefaultSetting = {
        center: consts.mapCenter[this.city_id-1],
        zoom: consts.mapDefaultZoom,
        pitch: 0,
        city_id: this.city_id
      }
      mapboxgl.accessToken = consts.mapboxAccessToken;
      let map_options = getMapOptions();
      //console.log(map_options);
      let zoom = map_options ? map_options.zoom : 12;
      let center = map_options
        ? [map_options.lng, map_options.lat]
        : consts.mapCenter[city_id - 1];
      var map = new mapboxgl.Map({
        container: "map",
        style: consts.mapBoxStyle,
        //center: [-87.61694, 41.86625],
        center: center,
        zoom: zoom,
        pitch: 0,
        bearing: 0,
        attributionControl: false
      });
      this.map = map;
      //定位图标
      map.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl
        })
      );
      this.map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: true
        })
      );
      //控制图层
      var nav = new mapboxgl.NavigationControl();
      this.map.addControl(nav, "top-right");
      //全屏按钮
      this.map.addControl(
        new mapboxgl.FullscreenControl({
          container: document.querySelector("body")
        })
      );
      //小地图
      //https://github.com/brendanmatkin/mapboxgl-minimap
      map.on("style.load", function() {
        // Possible position values are 'bottom-left', 'bottom-right', 'top-left', 'top-right'
        map.addControl(
          new MiniMap({
            id: "mapboxgl-minimap",
            width: "200px",
            height: "150px",
            //style: "mapbox://styles/mapbox/streets-v10",
            style: consts.mapBoxStyle,
            fillColor: "#f58220",
            zoomAdjust: null
          }),
          "bottom-right"
        );
        // map.loadImage('/img/icons/icon-red.png', function(error, image) {
        //     if (error) throw error;
        //     map.addImage('icon-1', image);
        // });
        _this.makePointImage(map, 1)
        map.loadImage('/img/icons/icon-benzhan.png', function(error, image) {
            if (error) throw error;
            map.addImage('icon-2', image);
        });
        // map.loadImage('/img/icons/icon-yellow.png', function(error, image) {
        //     if (error) throw error;
        //     map.addImage('icon-3', image);
        // });
        _this.makePointImage(map, 3)
        map.loadImage('/img/icons/icon-shuita.png', function(error, image) {
            if (error) throw error;
            map.addImage('icon-4', image);
        });
      });
      //记录地图设置
      map.on("move", function() {
        storeMapOptions({
          lng: map.getCenter().lng,
          lat: map.getCenter().lat,
          zoom: map.getZoom()
        });
      });
    },
    fillFigure(){
      //管道直径
      getOptions1().then(res => {
        let data = res.data;
        this.options[0].data = data;
      });
      getOptions2().then(res => {
        let data = res.data;
        this.options[1].data = data;
      });
      getOptions3().then(res => {
        let data = res.data;
        this.options[2].data = data;
      });
      getOptions4().then(res => {
        let data = res.data;
        this.options[3].data = data;
      });
      getOptions5().then(res => {
        let data = res.data;
        this.options[4].data = data;
      });
      getOptions6().then(res => {
        let data = res.data;
        this.options[5].data = data;
      });
      getOptions7().then(res => {
        let data = res.data;
        this.options[6].data = data;
      });
      getOptions8().then(res => {
        let data = res.data;
        this.options[7].data = data;
      });
    },
    fillCommonGeo(){
        let params = this.buildParams();
        getPipeGeo(params).then(res => {
          let data = res;
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addLineLayerToMap(this.map, 'pipeCommon', geoJson);    
        });
         getNodeGeo(params).then(res => {
          let data = res;
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addCircle2Map(this.map, 'nodeCommon', geoJson);    
        });
        
        
    },
    makePointImage(map, type){
      let size = 150;
      switch(type){
        case 1: //红点
          size = 200;
        break;
        case 3:
          size = 100;
        break;
      }
      //point type
      let pulsingDot  = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // get rendering context for the map canvas when layer is added to the map
        onAdd: function() {
          var canvas = document.createElement("canvas");
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext("2d");
        },

        // called once before every frame where the icon will be used
        render: function() {
          var duration = 1000;
          var t = (performance.now() % duration) / duration;

          var radius = (size / 2) * 0.3;
          var outerRadius = (size / 2) * 0.7 * t + radius;
          var context = this.context;

          // draw outer circle
          if(type == 1) {
            context.clearRect(0, 0, this.width, this.height);
            context.beginPath();
            context.arc(
              this.width / 2,
              this.height / 2,
              outerRadius,
              0,
              Math.PI * 2
            );
            switch(type){
              case 1:
                context.fillStyle = "rgba(213, 83, 40," + (1 - t) + ")";
              break;
              case 3:
                context.fillStyle = "rgba(245, 194, 67, 1)";
                break;
            }
            
            context.fill();
          }

          // draw inner circle
          context.beginPath();
          context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
          switch(type){
            case 1:
              context.fillStyle = "rgba(213, 83, 40, 1)";
            break;
            case 3:
              context.fillStyle = "rgba(246, 194, 67, 1)";
            break;
          }
          
          context.strokeStyle = "white";
          context.lineWidth = 2 + 4 * (1 - t);
          context.fill();
          //context.stroke();
          // update this image's data with data from the canvas
          this.data = context.getImageData(0, 0, this.width, this.height).data;

          // continuously repaint the map, resulting in the smooth animation of the dot
          map.triggerRepaint();

          // return `true` to let the map know that the image was updated
          return true;
        }
      }
      map.addImage("icon-"+type, pulsingDot, { pixelRatio: 2 });
    },
    addPointLayerToMap(map,type, geojson) {
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      if(typeof geojson.features == 'undefined') return;
      //let geojson_url = consts.url_geojson_quake_history;
      if(!map.hasImage("dot-1")) {
        this.makePointImage(map, 1) //<4
        this.makePointImage(map, 2) //4-5
        this.makePointImage(map, 3) //5-6
        this.makePointImage(map, 4) //6-7
        this.makePointImage(map, 5) //>7
        
      }


      geojson.features.forEach((item,idx)=>{
        if(item.properties.level <= 4){
          item.properties.type = 1
        } else if(item.properties.level <= 5){
          item.properties.type = 2
        }else if(item.properties.level <= 6){
          item.properties.type = 3
        }else if(item.properties.level <= 7){
          item.properties.type = 4
        }else {
          item.properties.type = 5
        }
      })

      map.addSource(source_name, {
        type: "geojson",
        data: geojson
       }
      );
      map.addLayer({
        id: layer_id,
        type: "symbol",
        source: source_name,
        paint: {
          "text-color": "#ffffff"
        },
        layout: {
          "icon-image": ["concat", "dot-", ["get","type"]],
          "text-field": ["get", "region"],
          "text-size": 12,
          "text-offset": [0, -3],
          "text-anchor": "top"
        }
      });
    },
    addImageLayerToMap(map,type, geojson) {
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      if(typeof geojson.features == 'undefined') return;
      //let geojson_url = consts.url_geojson_quake_history;
      

      map.addSource(source_name, {
        type: "geojson",
        data: geojson
       }
      );
      map.addLayer({
        id: layer_id,
        type: "symbol",
        source: source_name,
        paint: {
          "text-color": "#ffffff",
          //"icon-opacity": 1,
        },
        layout: {
          "icon-image": ["concat", "icon-", ["get","type_id"]],
          "icon-size": 0.5,
          "text-field": ["get", "region"],
          "text-size": 12,
          "text-offset": [0, -3],
          "text-anchor": "top",
          "icon-allow-overlap": true,
          
        }
      });
    },
    addLineLayerToMap(map, type, geojson) {
      let _this = this;
      this.removeMapLayer(map,type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      let linecolor = ''
      if(typeof geojson.features == 'undefined') return;

      let colorExpress = "";
      if(type == 'pipeCommon'){
           colorExpress = "rgba(204,204,204,0.6)"
       } else {
         colorExpress = [
          "step",
            ["get", "flow"],
            "#fbe6a3", 699.9990,
            "#F5C243", 1400.0001,
            "#D8833B", 2100.0001,
            "#a6263a", 2800.0001,
            "#791b3c", 3500.0001,
            "#4c1c40" // other
        ];
       }
        map.addSource(source_name, {
          type: "geojson",
          data: geojson
        });
        //diameter material_name
        map.addLayer({
          id: layer_id,
          type: "line",
          source: source_name,
          "layout": {
            "line-join": "round",
            "line-cap": "round"
          },
            "paint": {
            "line-color": colorExpress,
            "line-width": consts.defaultLineWidth
          }
        });
        this.setMapLayer()
        setZoomLevel(this.map, layer_id, 'line-width')

        if(type != "pipeCommon") {
          this.map.on("click", layer_id, function(e) {
            if(e.features.length == 0) return;
            addHighLigthLayer('line',e.features[0], _this)
            var coordinates = e.features[0].geometry.coordinates.slice();

            var html = "路段编号："+e.features[0].properties.id+"<br>道路名称："+e.features[0].properties.road_name+"<br>道路等级："+e.features[0].properties.road_level+"<br>道路长度："+e.features[0].properties.road_len+"m<br>自由流速："+e.features[0].properties.free_flow_speed+"km/h<br>通行能力："+e.features[0].properties.capacity+"辆/小时<br>交通流量："+e.features[0].properties.flow+"辆/小时";
            _this.popup = new mapboxgl.Popup({closeOnClick: false,className: 'map-popup'})
            .setLngLat(e.lngLat)
            .setHTML(html)
            .addTo(_this.map);

              _this.popup.on('close', ()=>{
                  _this.popup = null;
                
                if(_this.map.getLayer('highlight')) {
                  _this.map.removeLayer('highlight');
                  _this.map.removeSource('highlight');
                } 
              })

          });
        }
         
    },
    addBlockLayerToMap(map,type, geojson) {
      let _this = this;
      this.removeMapLayer(map,type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      let layer_id_stroke = layer_id+'_stroke';
      if(typeof geojson.features == 'undefined') return;
      map.addSource(source_name, {
        type: "geojson",
        data: geojson
      });
      map.addLayer({
        id: layer_id,
        type: "fill",
        source: source_name,
        'paint': {
          'fill-color': [
              "step",
              ["get", "flow"],
              "#fbe6a3", 5.001, 
              "#F5C243", 10.001,
              "#D8833B", 15.001,
               "#a6263a", 20.001,
              "#791b3c", 25.001,
              "#4c1c40",
            ],
          'fill-opacity': 0.8
        }
      });
      //描边
      map.addLayer({
        id: layer_id_stroke,
        type: "line",
        source: source_name,
        layout: {},
        paint: {
          "line-color": '#333333',
          "line-width": 1
        }
      });
      this.setMapLayer()
      this.map.on("click", layer_id, function(e) {
        if(e.features.length == 0) return;
        addHighLigthLayer('block',e.features[0], _this)
        var coordinates = e.features[0].geometry.coordinates.slice();
        var html = "交通小区ID："+e.features[0].properties.id+"<br>交通小区名："+e.features[0].properties.name+"<br>交通流量："+e.features[0].properties.flow+"辆/小时";
        _this.popup = new mapboxgl.Popup({closeOnClick: false,className: 'map-popup'})
        .setLngLat(e.lngLat)
        .setHTML(html)
        .addTo(_this.map);
        _this.popup.on('close', ()=>{
              _this.popup = null;
            
            if(_this.map.getLayer('highlight')) {
              _this.map.removeLayer('highlight');
              _this.map.removeSource('highlight');
            } 
          })

      });
    },
    addCircle2Map(map,type, geoJsonData) {
      let _this = this;
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      if(typeof geoJsonData.features == 'undefined') return;
      let colorExpress;
     
      if(type == 'nodeCommon'){
           colorExpress = "rgba(204,204,204,1)"
       } else {
          if(this.switchs.type) {
            //out
            colorExpress = [
           "step",
            ["get", "out_flow"],
            "#4f80b2", 1999,
            "#bad8e7", 4000.001,
            "#fdfcc8", 6000.001,
            "#edb579", 8000.001,
            "#c73c34" // other
            ];
          } else {
            colorExpress = [
            "step",
            ["get", "in_flow"],
            "#4f80b2", 1999,
            "#bad8e7", 4000.001,
            "#fdfcc8", 6000.001,
            "#edb579", 8000.001,
            "#c73c34" // other
            ];
          }
         
         
       }

     
      map.addSource(source_name, {
        type: "geojson",
        data: geoJsonData
      });
      map.addLayer({
        id: layer_id,
        type: "circle",
        source: source_name,
        paint: {
          // make circles larger as the user zooms from z12 to z22
          'circle-radius': consts.defaultCircleRadius,
          // color circles by ethnicity, using a match expression
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-match
          'circle-color': colorExpress,
          'circle-stroke-color': '#000000',
          'circle-stroke-width': 1
        }
      });
      this.setMapLayer()
      setZoomLevel(this.map, layer_id, 'circle-radius')

      if(type != "nodeCommon") {
        this.map.on("click", layer_id, function(e) {
          if(e.features.length == 0) return;
          addHighLigthLayer('node',e.features[0], _this)

          var coordinates = e.features[0].geometry.coordinates.slice();
          var html = "节点ID："+e.features[0].properties.id+"<br>地址："+e.features[0].properties.crossing_road+"<br>节点进流量："+(e.features[0].properties.in_flow==0?'-':e.features[0].properties.in_flow)+"辆/小时<br>节点出流量："+(e.features[0].properties.out_flow==0?'-':e.features[0].properties.out_flow)+"辆/小时";
          _this.popup = new mapboxgl.Popup({closeOnClick: false,className: 'map-popup'})
          .setLngLat(e.features[0].geometry.coordinates)
          .setHTML(html)
          .addTo(_this.map);

          _this.popup.on('close', ()=>{
                _this.popup = null;
              if(_this.map.getLayer('highlight')) {
                _this.map.removeLayer('highlight');
                _this.map.removeSource('highlight');
              } 
            })
        });
      }
    },
    setMapLayer(){
      if (this.map.getLayer(this.getMapLayerId('node')) && this.map.getLayer(this.getMapLayerId('pipeCommon'))  && this.map.getLayer(this.getMapLayerId('nodeCommon'))) {
          if (this.map.getLayer('stroke')) {
            this.map.moveLayer(this.getMapLayerId('node'), 'stroke');
          }
          this.map.moveLayer(this.getMapLayerId('nodeCommon'), this.getMapLayerId('node'));
          this.map.moveLayer(this.getMapLayerId('pipeCommon'), this.getMapLayerId('node'));
          this.map.moveLayer(this.getMapLayerId('pipeCommon'), this.getMapLayerId('nodeCommon'));
      }
      if (this.map.getLayer(this.getMapLayerId('block')) && this.map.getLayer(this.getMapLayerId('pipeCommon'))  && this.map.getLayer(this.getMapLayerId('nodeCommon'))) {
        if (this.map.getLayer('stroke')) {
            this.map.moveLayer(this.getMapLayerId('nodeCommon'), 'stroke');
        }
        this.map.moveLayer(this.getMapLayerId('block'), this.getMapLayerId('pipeCommon'));
        this.map.moveLayer(this.getMapLayerId('block')+'_stroke', this.getMapLayerId('pipeCommon'));
        this.map.moveLayer(this.getMapLayerId('block'), this.getMapLayerId('nodeCommon'));
        this.map.moveLayer(this.getMapLayerId('block')+'_stroke', this.getMapLayerId('nodeCommon'));
        this.map.moveLayer(this.getMapLayerId('pipeCommon'), this.getMapLayerId('nodeCommon'));
     }
     if (this.map.getLayer(this.getMapLayerId('pipe')) && this.map.getLayer(this.getMapLayerId('pipeCommon'))  && this.map.getLayer(this.getMapLayerId('nodeCommon'))) {
        if (this.map.getLayer('stroke')) {
            this.map.moveLayer(this.getMapLayerId('nodeCommon'), 'stroke');
        }
        this.map.moveLayer(this.getMapLayerId('pipe'), this.getMapLayerId('nodeCommon'));
        this.map.moveLayer(this.getMapLayerId('pipeCommon'), this.getMapLayerId('nodeCommon'));
        this.map.moveLayer(this.getMapLayerId('pipeCommon'), this.getMapLayerId('pipe'));
     }

    },
    showList() {
      this.show_list = !this.show_list;
    },
    donwloadList() {},
    showChart(city_id) {
      var self = this;
      var container = document.querySelector('.el-collapse');
      
      var box = document.getElementById("box-chart");
      var myChart = this.$echarts.init(box);
      box.style.width = container.offsetWidth + "px";
      getChart1(city_id).then(res => {
        var chart_data = res.data;
        var chart_name = "用户节点流量统计";
        var colors = ["#94f5f6","#c1726d"]

        chart_data.seriesData.map((item,idx) => {
          item.itemStyle= {normal: {areaStyle: {type: 'default'}}}
          item.color = colors[idx]
        })
        
        var option = buildlineOption(chart_name, chart_data, {
          grid: {
          left: '3%',
          right: '4%',
          bottom: '30',
          top: '80',
          containLabel: true
          },
          xAxis: {
          boundaryGap: false,
          show: true,
          name: '节点ID',
          nameLocation: 'center',//坐标轴名称显示位置。
          nameGap: 30
        },
        yAxis: {
          name: '流量(辆/小时)',
          nameLocatiosn: 'end',//坐标轴名称显示位置。
        },
        'type':'waterRecovery'
        },true);
        myChart.setOption(option);
       
      });

     var box2 = document.getElementById("box-chart2");
      var myChart2 = this.$echarts.init(box2);
      box2.style.width = container.offsetWidth + "px";
      getChart2(city_id).then(res => {
        var chart_data = Array.from(res.data).reverse();
        var chart_name = "道路流量";
        var colors = Array.from(self.colors[1]).reverse()

        chart_data.map((item,idx) => {
          return item.color = colors[idx]
        })
        var option = buildBarYOption(chart_name, chart_data,'name',{
            grid: {
              left: '2%',
              right: '4%',
              bottom: '30',
              top: '60',
              containLabel: true
            },
            xAxis: {
              name: '道路长度（km）',
              nameLocation: 'center',//坐标轴名称显示位置。
              nameGap: 30,
              type: 'value',
              axisLine: {
                  lineStyle: {
                      color: "#fff"
                  }
              },
              axisLabel: {
                  textStyle: {
                      fontSize: 12,
                      color: "#fff"
                  }
              }
            },
             yAxis: {
              name: '流量(辆/小时)',
              nameLocatiosn: 'end',//坐标轴名称显示位置。
            },
            
        });
        myChart2.setOption(option);
       
      });


      var box3 = document.getElementById("box-chart3");
      var myChart3 = this.$echarts.init(box3);
      box3.style.width = container.offsetWidth + "px";
      
      getChart3(city_id).then(res => {
        var chart_data = Array.from(res.data).reverse();
        var chart_name = "交通小区流量统计";
        var colors = Array.from(self.colors[1]).reverse()

        chart_data.map((item,idx) => {
          return item.color = colors[idx]
        })
        var option = buildBarYOption(chart_name, chart_data,'name',{
            grid: {
              left: '2%',
              right: '4%',
              bottom: '30',
              top: '60',
              containLabel: true
            },
            xAxis: {
              name: '交通小区个数（个）',
              nameLocation: 'center',//坐标轴名称显示位置。
              nameGap: 30,
              type: 'value',
              axisLine: {
                  lineStyle: {
                      color: "#fff"
                  }
              },
              axisLabel: {
                  textStyle: {
                      fontSize: 12,
                      color: "#fff"
                  }
              }
            },
            yAxis: {
              name: '流量(辆/小时)',
              nameLocatiosn: 'end',//坐标轴名称显示位置。
            },
        });
        myChart3.setOption(option);
       
      });

      myChart.resize(); 
      myChart2.resize();
      myChart3.resize();

      window.onresize = function() {
        document.querySelectorAll('.chart-area').forEach((item,idx)=>{
          item.style.width = document.querySelector('.el-collapse').offsetWidth+'px';
        })
        myChart.resize(); 
        myChart2.resize(); 
        myChart3.resize(); 
      }
    },
    fillStreets(city_id) {
      getStreets(city_id).then(res => {
        let data = res.data;
        data.unshift({ id: 0, name: "街道" });
        this.selects.streets = data;
      });
    },
    fillCommunities(city_id, street_id) {
      getCommunities(city_id, street_id).then(res => {
        let data = res.data;
        data.unshift({ id: 0, name: "社区" });
        this.selects.communities = data;
      });
    },
    fillList(city_id, page) {
        let params = this.buildParams();
        params.page = page;
        if(this.selects.data_category == 0 ) {
         
           getList1(params).then(res => {
              this.total = res.data.total;
              this.data = res.data.items;
          });
          getStatistics1(params).then(res => {
              this.statistics = res.data;
          });
          this.downloadUrl = consts.requestUrl + consts.exportTrafficNodeType + parseJsonToString(params)
          
        } else if(this.selects.data_category == 1 ) {
          //params.flow_ids = (this.options[5].selected.length==0 || this.options[5].selected.length == this.options[5].data.length)?0: (this.options[5].selected.sort((a,b)=>a - b).join("-"));
           getList2(params).then(res => {
              this.total = res.data.total;
              this.data = res.data.items;
          });
          getStatistics2(params).then(res => {
              this.statistics = res.data;
          });
          this.downloadUrl = consts.requestUrl + consts.exportTrafficPipeVolumn + parseJsonToString(params)
          
        } else {
          //params.flow_ids = (this.options[5].selected.length==0 || this.options[5].selected.length == this.options[5].data.length)?0: (this.options[5].selected.sort((a,b)=>a - b).join("-"));
           getList3(params).then(res => {
              this.total = res.data.total;
              this.data = res.data.items;
          });
          getStatistics3(params).then(res => {
              this.statistics = res.data;
          });
          this.downloadUrl = consts.requestUrl + consts.exportTrafficVolumn + parseJsonToString(params)
          
        }
       
        
      
    },
    fill2Map(city_id) {
      let params = this.buildParams();
      //   getPipeGeo1(params).then(res => {
      //     let data = res;
      //     let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
      //     this.addLineLayerToMap(this.map, 'pipe',geoJson);
      //   });
      if(this.selects.data_category == 0 ) {
        //params.flow_ids = (this.options[0].selected.length==0 || this.options[0].selected.length == this.options[0].data.length)?0: (this.options[0].selected.sort((a,b)=>a - b).join("-"));
        this.removeMapLayer(this.map, 'pipe')
        this.removeMapLayer(this.map, 'block')
        getGeo1(params).then(res => {
          let data = res;
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addCircle2Map(this.map, 'node',geoJson);
        });
      } else if(this.selects.data_category == 1 ) {
        //params.flow_ids = (this.options[0].selected.length==0 || this.options[0].selected.length == this.options[0].data.length)?0: (this.options[0].selected.sort((a,b)=>a - b).join("-"));
        this.removeMapLayer(this.map, 'node')
        this.removeMapLayer(this.map, 'block')
        getGeo2(params).then(res => {
          let data = res;
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addLineLayerToMap(this.map, 'pipe', geoJson); 
        });
      } else {
        //params.flow_ids = (this.options[5].selected.length==0 || this.options[5].selected.length == this.options[5].data.length)?0: (this.options[5].selected.sort((a,b)=>a - b).join("-"));
        this.removeMapLayer(this.map, 'node')
        this.removeMapLayer(this.map, 'pipe')
        getGeo3(params).then(res => {
          let data = res;
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addBlockLayerToMap(this.map, 'block', geoJson);    
        });
      }
    },
    reloadData(type) {
      this.setTipContent()
      this.fillList(this.city_id, 1);
      this.fill2Map(this.city_id);
      
    },
    setTipContent() {
      let html = '';
      if(this.selects.data_category == 0) {
        html = consts.tipsTrafficNodeVolumn
      } else if(this.selects.data_category == 1){
        html = consts.tipsTrafficRoadlVolumn
      } else {
        html = consts.tipsTrafficVolumn
      }
      document.getElementById('left-tip-content').innerHTML = html
    },
    buildParams() {
      let params = { city_id: this.city_id };
      params.page_size = 10;

      params.in_flow_ids = (this.options[0].selected.length==0 || this.options[0].selected.length == this.options[0].data.length)?0: (this.options[0].selected.sort((a,b)=>a - b).join("-"));
      params.out_flow_ids = (this.options[1].selected.length==0 || this.options[1].selected.length == this.options[1].data.length)?0: (this.options[1].selected.sort((a,b)=>a - b).join("-"));

      params.len_ids = (this.options[2].selected.length==0 || this.options[2].selected.length == this.options[2].data.length)?0: (this.options[2].selected.sort((a,b)=>a - b).join("-"));
      params.level_ids = (this.options[3].selected.length==0 || this.options[3].selected.length == this.options[3].data.length)?0: (this.options[3].selected.sort((a,b)=>a - b).join("-"));
      params.speed_ids = (this.options[4].selected.length==0 || this.options[4].selected.length == this.options[4].data.length)?0: (this.options[4].selected.sort((a,b)=>a - b).join("-"));
      params.capacity_ids = (this.options[5].selected.length==0 || this.options[5].selected.length == this.options[5].data.length)?0: (this.options[5].selected.sort((a,b)=>a - b).join("-"));
      
      if(this.selects.data_category == 1 ) {
        params.flow_ids = (this.options[6].selected.length==0 || this.options[6].selected.length == this.options[6].data.length)?0: (this.options[6].selected.sort((a,b)=>a - b).join("-"));
      } else if(this.selects.data_category == 2 ) {
        params.flow_ids = (this.options[7].selected.length==0 || this.options[7].selected.length == this.options[7].data.length)?0: (this.options[7].selected.sort((a,b)=>a - b).join("-"));
      }
      
      return params;
    },
    changePage: function(value) {
      if (value != this.page) {
        this.fillList(this.city_id, value);
      }
      this.page = value;
    },
    getMapLayerId(type) {
      let layer_id = type + "-layer";
      return layer_id;
    },
    getMapSourceName(type) {
      let source_name = type + "-data";
      return source_name;
    },
    removeMapLayer(map, type) {
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      var layer = map.getLayer(layer_id);
      let layer_point_id = layer_id + "point";
      //描边
      var layer_stroke = map.getLayer(layer_id+'_stroke');
      //热点图的热点层
      if (map.getLayer(layer_point_id)) {
        map.removeLayer(layer_point_id);
      }
      if(layer_stroke){
          map.removeLayer(layer_id+'_stroke');
        }
      if (layer) {
        map.removeLayer(layer_id);
      }
      if(map.getSource(source_name)) {
        map.removeSource(source_name);
      } 
    },
    clearAll(idx) {
        if(idx == 'street') {
            this.selects.street = 0
        } else if(idx == 'community') {
            this.selects.community = 0;
        } else {
            this.options[idx].selected = [];
        }
      this.reloadData();
    }
  }
};
</script>
<style lang="scss" scoped>
.map_data_msg .map_data_msg_wrapper_vertical .item {
  width: auto;
}
</style>
