<template>
  <div class="row" style="margin:0 -30px;">
    <div class="col-md-9" style="padding:0">
      <card class="card-map">
        <!-- <div class="mask">
                    <img src="img/loading.png" class="loading">
                  </div>-->
        <div id="map"></div>
        <menu-map :map="map" :mapDefault="mapdefaultSetting"></menu-map>
        <div class="map_data_msg" v-show="selects.data_category == 'economic_losses'">
          <div class="map_data_legend">直接经济损失</div>
          <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
            <div class="item" v-for="(item, index) in selects.economicLossesOptions" :key="index" v-if="index > 0">
              <span :style="'background: ' + item.color + ''"></span>
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="map_data_msg" v-show="selects.data_category == 'economic_losses_percent'">
          <div class="map_data_legend">直接经济损失率</div>
          <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
            <div class="item" v-for="(item, index) in selects.economicLossesPercentOptions" :key="index" v-if="index > 0">
              <span :style="'background: ' + item.color + ''"></span>
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="map_data_msg" v-show="selects.data_category == 'death_morning'">
          <div class="map_data_legend">凌晨2点 - 人口伤亡</div>
          <div class="map_data_msg_wrapper">
            <div class="item" v-for="(item, index) in deathMorningOptions" :key="index">
              <span :style="'background: ' + item.color + ''"></span>
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="map_data_msg" v-show="selects.data_category == 'death_afternoon'">
          <div class="map_data_legend">下午2点 - 人口伤亡</div>
          <div class="map_data_msg_wrapper">
            <div class="item" v-for="(item, index) in deathAfternoonOptions" :key="index">
              <span :style="'background: ' + item.color + ''"></span>
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="map_data_msg" v-show="selects.data_category == 'tenants'">
          <div class="map_data_legend">离家住户数</div>
          <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
            <div class="item" v-for="(item, index) in selects.awayFromHomeOptions" :key="index" v-if="index > 0">
              <span :style="'background: ' + item.color + ''"></span>
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="map_data_msg" v-show="selects.data_category == 'tenants_percent'">
          <div class="map_data_legend">离家住户数率</div>
          <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
            <div class="item" v-for="(item, index) in selects.awayFromHomeRateOptions" :key="index" v-if="index > 0">
              <span :style="'background: ' + item.color + ''"></span>
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="map_data_msg" v-show="selects.data_category == 'function_losses'">
          <div class="map_data_legend">功能状态</div>
          <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
            <div class="item" v-for="(item, index) in selects.functionalAvalibleOptions" :key="index" v-if="index > 0">
              <span :style="'background: ' + item.color + ''"></span>
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="show_list">
          <a class="up_show" v-on:click="showList">
            <i class="now-ui-icons" :class="[show_list ? 'arrows-1_minimal-down' : 'arrows-1_minimal-up']"></i>
          </a>
          <a class="download ml-auto" :href="downloadUrl">
            <i class="now-ui-icons arrows-1_cloud-download-93"></i>
          </a>
          <span>
            数据列表
            <i>{{ total }}</i>
          </span>
        </div>
        <div class="show_list_record" v-show="show_list"
          :style="{ 'margin-top': -((selects.data_category == 'death_morning' || selects.data_category == 'death_afternoon' || selects.data_category == 'function_losses') ? (180 + data.length * 38) : (140 + data.length * 38)) + 'px' }"
          ref="show_list_record">
          <el-table :data="data" v-show="selects.data_category == 'economic_losses'">
            <el-table-column label="建筑ID" property="id"></el-table-column>
            <!-- <el-table-column label="经度" property="lat"></el-table-column>
                      <el-table-column label="纬度" property="lng"></el-table-column> -->
            <el-table-column label="街道" property="street_name"></el-table-column>
            <el-table-column label="社区" property="community_name"></el-table-column>
            <el-table-column label="直接经济损失" property="economic_losses"></el-table-column>
          </el-table>

          <el-table :data="data" v-show="selects.data_category == 'economic_losses_percent'">
            <el-table-column label="建筑ID" property="id"></el-table-column>
            <!-- <el-table-column label="经度" property="lat"></el-table-column>
                      <el-table-column label="纬度" property="lng"></el-table-column> -->
            <el-table-column label="街道" property="street_name"></el-table-column>
            <el-table-column label="社区" property="community_name"></el-table-column>
            <el-table-column label="直接经济损失百分比" property="economic_losses_rate"></el-table-column>
          </el-table>
          <el-table :data="data" v-show="selects.data_category == 'death_morning'">
            <el-table-column label="社区ID" property="id"></el-table-column>
            <el-table-column label="社区名" property="community_name"></el-table-column>
            <el-table-column label="街道" property="street_name"></el-table-column>
            <el-table-column label="凌晨2点-人口伤亡">
              <el-table-column property="s1" label="轻微">
              </el-table-column>
              <el-table-column property="s2" label="中等">
              </el-table-column>
              <el-table-column property="s3" label="严重">
              </el-table-column>
              <el-table-column property="s4" label="死亡">
              </el-table-column>
              <el-table-column property="s5" label="总伤亡">
              </el-table-column>

            </el-table-column>
          </el-table>
          <el-table :data="data" v-show="selects.data_category == 'death_afternoon'">
            <el-table-column label="社区ID" property="id"></el-table-column>
            <el-table-column label="社区名" property="community_name"></el-table-column>
            <el-table-column label="街道" property="street_name"></el-table-column>
            <el-table-column label="下午2点-人口伤亡">
              <el-table-column property="s1" label="轻微">
              </el-table-column>
              <el-table-column property="s2" label="中等">
              </el-table-column>
              <el-table-column property="s3" label="严重">
              </el-table-column>
              <el-table-column property="s4" label="死亡">
              </el-table-column>
              <el-table-column property="s5" label="总伤亡">
              </el-table-column>
            </el-table-column>
          </el-table>
          <el-table :data="data" v-show="selects.data_category == 'tenants'">
            <el-table-column label="社区ID" property="id"></el-table-column>
            <el-table-column label="社区名" property="community_name"></el-table-column>
            <el-table-column label="街道" property="street_name"></el-table-column>
            <el-table-column label="住户数" property="household"></el-table-column>
            <el-table-column label="离家住户数" property="leave_home"></el-table-column>
          </el-table>
          <el-table :data="data" v-show="selects.data_category == 'tenants_percent'">
            <el-table-column label="社区ID" property="id"></el-table-column>
            <el-table-column label="社区名" property="community_name"></el-table-column>
            <el-table-column label="街道" property="street_name"></el-table-column>
            <el-table-column label="住户数" property="household"></el-table-column>
            <el-table-column label="离家住户率" property="leave_home_rate"></el-table-column>
          </el-table>
          <el-table :data="data" v-show="selects.data_category == 'function_losses'">
            <el-table-column label="建筑ID" property="id"></el-table-column>
            <!-- <el-table-column label="经度" property="lat"></el-table-column>
                      <el-table-column label="纬度" property="lng"></el-table-column> -->
            <el-table-column label="街道" property="street_name"></el-table-column>
            <el-table-column label="社区" property="community_name"></el-table-column>
            <el-table-column label="功能损失">
              <el-table-column property="function_loss_re" label="RE 不能进入">
              </el-table-column>
              <el-table-column property="function_loss_ru" label="RU 不能使用">
              </el-table-column>
              <el-table-column property="function_loss_ro" label="RO 可以进入">
              </el-table-column>
              <el-table-column property="function_loss_bf" label="BF 基本能住">
              </el-table-column>
              <el-table-column property="function_loss_ff" label="FF 完全能住">
              </el-table-column>
            </el-table-column>
          </el-table>

          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class>
              <p class="card-category">显示： {{ from + 1 }} - {{ to }} 总数： {{ total }}</p>
            </div>
            <n-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage"
              :total="total" v-on:input="changePage"></n-pagination>
          </div>
        </div>
      </card>
    </div>
    <div class="col-md-3 hasDecision" id="customStyle">
      <div class="col-md-12 clearfix key-decision">
        <el-select class="select-default" size="large" placeholder="数据集类型选择" v-model="selects.data_category"
          v-on:change="reloadData">
          <el-option v-for="option in selects.data_categories" class="select-primary" :value="option.id"
            :label="option.name" :key="option.id"></el-option>
        </el-select>
      </div>
      <el-collapse v-model="activeNames">
        <el-collapse-item title="信息图表" name="2">
          <div class="col-md-12" v-show="selects.data_category == 'economic_losses'">
            <div class="chart-area" id="box-chart"></div>
          </div>
          <div class="col-md-12" v-show="selects.data_category == 'economic_losses_percent'">
            <div class="chart-area" id="box-chart2"></div>
          </div>
          <div class="col-md-12" v-show="selects.data_category == 'death_morning'">
            <div class="chart-area" id="box-chart3"></div>
          </div>
          <div class="col-md-12" v-show="selects.data_category == 'death_afternoon'">
            <div class="chart-area" id="box-chart4"></div>
          </div>
          <div class="col-md-12" v-show="selects.data_category == 'tenants'">
            <div class="chart-area" id="box-chart5"></div>
          </div>
          <div class="col-md-12" v-show="selects.data_category == 'tenants_percent'">
            <div class="chart-area" id="box-chart6"></div>
          </div>
          <div class="col-md-12" v-show="selects.data_category == 'function_losses'">
            <div class="chart-area" id="box-chart7"></div>
          </div>
        </el-collapse-item>
        <el-collapse-item title="叠加图层" name="3"
          v-show="['death_morning', 'death_afternoon', 'tenants', 'tenants_percent'].includes(selects.data_category)">
          <div class="col-md-12 clearfix"
            v-if="selects.data_category == 'death_morning' || selects.data_category == 'death_afternoon'">
            <checkbox v-model="community_people" label="社区人口分布" v-on:input="!community_people">社区人口分布</checkbox>
            <div class="overlap_layer_desc">
              <div class="item" v-for="(item, index) in communityPeopleData" :key="index">
                <span :style="'background: ' + item.color + ''"></span>
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="col-md-12 clearfix"
            v-if="selects.data_category == 'tenants' || selects.data_category == 'tenants_percent'">
            <checkbox v-model="household" label="住户数" v-on:input="!household">住户数</checkbox>
            <div class="overlap_layer_desc">
              <div class="item" v-for="(item, index) in householdData" :key="index">
                <img :src="'img/household-' + item.name + '.png'" alt="">
                {{ item.name }}
              </div>
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item title="数据筛选" name="4">
          <div class="col-md-12">
            <el-select class="select-default" size="large" placeholder="街道" v-model="selects.street"
              v-on:change="reloadData('street')">
              <el-option v-for="option in selects.streets" class="select-primary" :value="option.id" :label="option.name"
                :key="option.id"></el-option>
            </el-select>
          </div>

          <div class="col-md-12">
            <el-select class="select-default" size="large" placeholder="社区" v-model="selects.community"
              v-on:change="reloadData">
              <el-option v-for="option in selects.communities" class="select-primary" :value="option.id"
                :label="option.name" :key="option.id"></el-option>
            </el-select>
          </div>

          <div class="col-md-12">
            <el-select class="select-default" size="large" placeholder="结构类型" v-model="selects.struct"
              v-on:change="reloadData">
              <el-option v-for="(option, index) in selects.structs" class="select-primary" :value="index" :label="option"
                :key="index"></el-option>
            </el-select>
          </div>

          <div class="col-md-12">
            <el-select class="select-default" size="large" placeholder="功能类型" v-model="selects.category"
              v-on:change="reloadData">
              <el-option v-for="(option, index) in selects.categories" class="select-primary" :value="index"
                :label="option" :key="index"></el-option>
            </el-select>
          </div>

          <div class="col-md-12">
            <el-select class="select-default" size="large" placeholder="抗震设防烈度" v-model="selects.quakeLevel"
              v-on:change="reloadData">
              <el-option v-for="option in selects.quakeLevels" class="select-primary" :value="option.id"
                :label="option.name" :key="option.id"></el-option>
            </el-select>
          </div>

          <div class="col-md-12">
            <el-select class="select-default" size="large" placeholder="修建年代" v-model="selects.buildYear"
              v-on:change="reloadData">
              <el-option v-for="(option, index) in selects.buildYears" class="select-primary" :value="option"
                :label="option" :key="index"></el-option>
            </el-select>
          </div>

          <div class="col-md-12" v-show="selects.data_category == 'economic_losses'">
            <el-select class="select-default" size="large" placeholder="直接经济损失金额" v-model="selects.economicLoss"
              v-on:change="reloadData">
              <el-option v-for="(option, index) in selects.economicLossesOptions" class="select-primary" :value="index"
                :label="option.name" :key="option.id"></el-option>
            </el-select>
          </div>

          <div class="col-md-12" v-show="selects.data_category == 'economic_losses_percent'">
            <el-select class="select-default" size="large" placeholder="直接经济损失率" v-model="selects.economicLossesPercent"
              v-on:change="reloadData">
              <el-option v-for="(option, index) in selects.economicLossesPercentOptions" class="select-primary"
                :value="index" :label="option.name" :key="option.id"></el-option>
            </el-select>
          </div>

          <div class="col-md-12" v-show="selects.data_category == 'death_morning'">
            <el-select class="select-default" size="large" placeholder="凌晨两点人口伤亡" v-model="selects.deathMorning"
              v-on:change="reloadData">
              <el-option v-for="(option, index) in selects.deathMorningOptions" class="select-primary" :value="index"
                :label="option.name" :key="option.id"></el-option>
            </el-select>
          </div>

          <div class="col-md-12" v-show="selects.data_category == 'death_afternoon'">
            <el-select class="select-default" size="large" placeholder="下午两点人口伤亡" v-model="selects.deathAfternoon"
              v-on:change="reloadData">
              <el-option v-for="(option, index) in selects.deathAfternoonOptions" class="select-primary" :value="index"
                :label="option.name" :key="option.id"></el-option>
            </el-select>
          </div>

          <div class="col-md-12" v-show="selects.data_category == 'tenants'">
            <el-select class="select-default" size="large" placeholder="离家住户数" v-model="selects.awayFromHome"
              v-on:change="reloadData">
              <el-option v-for="(option, index) in selects.awayFromHomeOptions" class="select-primary" :value="index"
                :label="option.name" :key="option.id"></el-option>
            </el-select>
          </div>

          <div class="col-md-12" v-show="selects.data_category == 'tenants_percent'">
            <el-select class="select-default" size="large" placeholder="离家住户率" v-model="selects.awayFromHomeRate"
              v-on:change="reloadData">
              <el-option v-for="(option, index) in selects.awayFromHomeRateOptions" class="select-primary" :value="index"
                :label="option.name" :key="option.id"></el-option>
            </el-select>
          </div>

          <div class="col-md-12" v-show="selects.data_category == 'function_losses'">
            <el-select class="select-default" size="large" placeholder="功能状态" v-model="selects.functionalAvalible"
              v-on:change="reloadData">
              <el-option v-for="(option, index) in selects.functionalAvalibleOptions" class="select-primary"
                :value="index" :label="option.name" :key="option.id"></el-option>
            </el-select>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="key-infos">
      <div class="key-info" v-show="selects.data_category == 'economic_losses'">
        总经济损失{{ statistics.economic_losses.total }}亿元
      </div>
      <div class="key-info" v-show="selects.data_category == 'economic_losses_percent'">
        平均经济损失率{{ statistics.economic_losses_percent.percentage }}%
      </div>
      <div class="key-info" v-show="selects.data_category == 'death_morning'">
        人口伤亡总数{{ statistics.death_morning.total }}人
      </div>
      <div class="key-info" v-show="selects.data_category == 'death_afternoon'">
        人口伤亡总数{{ statistics.death_afternoon.total }}人
      </div>
      <div class="key-info" v-show="selects.data_category == 'tenants'">
        离家住户总数{{ statistics.tenants.total }}户
      </div>
      <div class="key-info" v-show="selects.data_category == 'tenants_percent'">
        平均离家住户率{{ statistics.tenants_percent.percentage }}%
      </div>
      <div class="key-info" v-show="selects.data_category == 'function_losses'">
        RE {{ statistics.function_losses.re }}幢，RU {{ statistics.function_losses.ru }}幢， RO
        {{ statistics.function_losses.ro }}幢，BF {{ statistics.function_losses.bf }}幢，FF {{ statistics.function_losses.ff
        }}幢
      </div>

    </div>
  </div>
</template>
<script>
import mapboxgl from "mapbox-gl";
import MenuMap from "src/components/Menumap";
import MiniMap from "src/mapbox/mapboxgl-minimap.js";
import { TabPane, Tabs, Switch } from "src/components";
import { Checkbox, Radio } from "src/components/index";
import { Input, Button, Select, Option, Collapse, CollapseItem, Table, TableColumn } from "element-ui";
import geobuf from "geobuf";
import Pbf from "pbf";
import { consts } from "src/util/consts.js";
import {
  getCategories,
  getStreets,
  getCommunities,
  getEconomicLossesList,
  getEconomicLossesGeojson,
  getEconomicLossesRateList,
  getEconomicLossesRateGeojson,
  getmorningInjureList,
  getmorningInjureGeojson,
  getAfternoonInjureList,
  getAfternoonInjureGeojson,
  getInjureBlockGeojson,
  getFunctionLossList,
  getFunctionLossGeojson,
  getLeavehomeList,
  getLeavehomeGeojson,
  getLeavehomeRateList,
  getLeavehomeRateGeojson,
  getHouseTotalGeojson,
  getEconomicLossStatistics,
  getEconomicLossRateStatistics,
  getMorningStatistics,
  getAfternoonStatistics,
  getTenantsStatistics,
  getTenantsRateStatistics,
  getFunctionalStatusStatistics
} from "src/api/building.js";
import { byEconomicLoss, byEconomicLossRate, byDeathMorning, byDeathAfternoon, byTenants, byTenantsRate, byFunctionLoss } from "src/api/chart.js";
import { buildBarStackOption, buildBarOption, buildPieOption } from "src/util/chart.js";
import {
  getBuildYearRange,
  storeMapOptions,
  getMapOptions,
  parseJsonToString
} from "src/util/common.js";
import { Pagination as NPagination } from "src/components";
import {
  getPeopleStat,
  getGenderStat,
  getHideaways,
  getHospitals,
  getWarehouses
} from "src/api/cityinfo.js";

import * as THREE from "three";
//import street from "./streetdata"; // 圆柱体：街道中心mock数据含住户数
//import streetOutline from "./GeoJson-block"; // 圆柱体：街道中心mock数据含住户数


export default {
  components: {
    MenuMap,
    TabPane,
    Tabs,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    NPagination,
    [Switch.name]: Switch,
    Radio,
    Checkbox
  },
  data() {
    return {
      activeNames: ['2', '3', '4'],
      activeName: "first",
      tabs: {
        horizontal: "Profile",
        vertical: "Options",
        verticalPills: "Home",
        pageCategories: "Messages"
      },
      selects: {
        street: 0,
        streets: [{ id: 0, name: "街道" }],
        community: 0,
        communities: [{ id: 0, name: "社区" }],
        struct: 0,
        structs: consts.buildingStructs,
        economicLoss: 0, //直接经济损失
        economicLossesOptions: [
          { id: 0, name: "直接经济损失金额" },
          { id: 1, name: "100万以下", color: "#283D94" },
          { id: 2, name: "100-200万", color: "#4F22A6" },
          { id: 3, name: "200-500万", color: "#B22FB9" },
          { id: 4, name: "500-1000万", color: "#C9305A" },
          { id: 5, name: "1000-2000万", color: "#D55328" },
          { id: 6, name: "2000万以上", color: "#F5C243" }
        ],
        economicLossesPercent: 0, //直接经济损失率
        economicLossesPercentOptions: [
          { id: 0, name: "直接经济损失率" },
          { id: 1, name: "小于10%", color: "#283D94" },
          { id: 2, name: "10-20%", color: "#4F22A6" },
          { id: 3, name: "20-30%", color: "#B22FB9" },
          { id: 4, name: "30-40%", color: "#C9305A" },
          { id: 5, name: "40-50%", color: "#D55328" },
          { id: 6, name: "50%以上", color: "#F5C243" }
        ],
        deathMorning: 0, //凌晨两点人口伤亡
        deathMorningOptions: [
          { id: 0, name: "凌晨2点 - 人口伤亡" },
          { id: 1, name: "轻微" },
          { id: 2, name: "中等" },
          { id: 3, name: "严重" },
          { id: 4, name: "死亡" }
        ],
        deathAfternoon: 0, //下午两点人口伤亡
        deathAfternoonOptions: [
          { id: 0, name: "下午2点 - 人口伤亡" },
          { id: 1, name: "轻微" },
          { id: 2, name: "中等" },
          { id: 3, name: "严重" },
          { id: 4, name: "死亡" }
        ],
        awayFromHome: 0, //离家住户数
        awayFromHomeOptions: [
          { id: 0, name: "离家住户数" },
          { id: 1, name: "小于500", color: "#242F7F" },
          { id: 2, name: "500-700", color: "#447DB3" },
          { id: 3, name: "700-800", color: "#62BFF5" },
          { id: 4, name: "800-1200", color: "#94CFBF" },
          { id: 5, name: "1200-1500", color: "#CEE8B9" },
          { id: 6, name: "1500以上", color: "#FDF1D0" }
        ],
        awayFromHomeRate: 0, //离家住户率
        awayFromHomeRateOptions: [
          { id: 0, name: "离家住户率" },
          { id: 1, name: "小于10%", color: "#242F7F" },
          { id: 2, name: "10%-20%", color: "#447DB3" },
          { id: 3, name: "20%-30%", color: "#62BFF5" },
          { id: 4, name: "30%-40%", color: "#94CFBF" },
          { id: 5, name: "40%-50%", color: "#CEE8B9" },
          { id: 6, name: "50%-100%", color: "#FDF1D0" }
        ],
        functionalAvalible: 0, //功能状态
        functionalAvalibleOptions: [
          { id: 0, name: "功能状态" },
          { id: 1, name: "RE 不能进入", color: "#e029f9" },
          { id: 2, name: "RU 不能使用", color: "#ff3708" },
          { id: 3, name: "RO 可以进入", color: "#fef71d" },
          { id: 4, name: "BF 基本能住", color: "#4aff41" },
          { id: 5, name: "FF 完全能住", color: "#02caff" }
        ],
        category: 0,
        categories: consts.buildingCategories,
        quakeLevel: 0,
        quakeLevels: [
          { id: 0, name: "抗震设防烈度" },
          { id: 6, name: 6 },
          { id: 7, name: 7 },
          { id: 8, name: 8 }
        ],
        buildYear: '修建年代',
        buildYears: consts.buildingBuildYears,
        data_category: 'economic_losses',
        data_categories: [
          { id: 'economic_losses', name: "直接经济损失" },
          { id: 'economic_losses_percent', name: "直接经济损失率" },
          { id: 'death_morning', name: "凌晨2点人口伤亡" },
          { id: 'death_afternoon', name: "下午2点人口伤亡" },
          { id: 'tenants', name: "离家住户数" },
          { id: 'tenants_percent', name: "离家住户率" },
          { id: 'function_losses', name: "功能状态" }
        ],
        population: [],
        tenants: [],

      },
      keyword: "",
      total: 0,
      data: [],
      page: 1,
      map: {},
      color_category: "struct",
      city_id: localStorage.getItem("city_id") || 1,
      show_list: false,
      show_list_height: 0,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 20
      },
      switchs: {
        people: true,
        gender: true,
        hideaway: true,
        hospital: true,
        warehouse: true
      },
      last_updated: consts.last_updated,
      //data_type: "people",
      community_people: false, //显示社区人口分布叠加图层
      communityPeopleData: [
        { id: 1, name: ">10000人", color: "#f7fbff" },
        { id: 2, name: "5000-10000人", color: "#5fc0f5" },
        { id: 3, name: "<5000人", color: "#142f67" }
      ],
      household: false, //显示住户数
      householdData: [
        { id: 1, name: "500", color: "#fdf2d0" },
        { id: 2, name: "1000", color: "#fbe6a3" },
        { id: 3, name: "1500", color: "#fbe6a2" },
        { id: 4, name: "2000", color: "#df8244" },
        { id: 5, name: "3000", color: "#b12318" }
      ],
      //显示在地图上的数据
      deathMorningOptions: [
        { id: 1, name: "5", color: "#FDF0D0" },
        { id: 2, name: "10", color: "#F5C243" },
        { id: 3, name: "20", color: "#D8833B" },
        { id: 4, name: "30", color: "#D55745" }
      ],
      //显示在地图上的数据
      deathAfternoonOptions: [
        { id: 1, name: "5", color: "#FDF0D0" },
        { id: 2, name: "10", color: "#F5C243" },
        { id: 3, name: "20", color: "#D8833B" },
        { id: 4, name: "30", color: "#D55745" }
      ],
      maptype: "building",
      colors: {
        'tenants': [
          "#242F7F", '#447DB3', '#62BFF5', '#94CFBF', '#CEE8B9', '#FDF1D0'
        ],
        'tenants_percent': [
          "#242F7F", '#447DB3', '#62BFF5', '#94CFBF', '#CEE8B9', '#FDF1D0'
        ],
        'function_losses': [
          "#e029f9", '#ff3708', '#fef71d', '#4aff41', '#02caff'
        ],
        'death': [
          "#FDF0D0", "#F5C243", "#D8833B", "#D55745"
        ],
        'ecnomic': [
          "#283D94", "#4F22A6", "#B22FB9", "#C9305A", "#D55328", "#F5C243"
        ]
      },
      statistics: {
        economic_losses: {
          total: '',
        },
        economic_losses_percent: {
          percentage: ''
        },
        death_morning: {
          total: '',
        },
        death_afternoon: {
          total: '',
        },
        tenants: {
          total: '',
        },
        tenants_percent: {
          percentage: '',
        },
        function_losses: {
          "re": '',
          "ru": '',
          "ro": '',
          "bf": '',
          "ff": ''
        },

      },
      mapdefaultSetting: {},
      downloadUrl: ''
    };
  },
  watch: {
    community_people(val) {
      //开关叠加涂层
      this.toggleMapLayer('community_people')
    },
    household(val) {
      //开关叠加涂层
      this.toggleMapLayer('household')
    },
    "selects.street"(val) {
      //街道切换选社区
      this.fillCommunities(this.city_id, val);
    }
  },

  mounted() {
    this.fillStreets(this.city_id);
    this.fillCommunities(this.city_id, 0);
    this.showChart(this.city_id);
    //this.fillBuildings(this.city_id, 1);
    this.initMap(this.city_id);
    //this.fillBuildingsMap(this.city_id, 'building');

    //this.addPointLayerToMap(this.map, "hospital");
    //this.addPointLayerToMap(this.map, "hideaway");
    //this.addPointLayerToMap(this.map, "warehouse");
    //this.loadData(this.data_type);
  },

  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    street() {
      return this.selects.street
    }
  },
  methods: {
    initMap(city_id) {
      let self = this;
      this.mapdefaultSetting = {
        center: consts.mapCenter[this.city_id - 1],
        zoom: consts.mapDefaultZoom,
        pitch: 0,
        city_id: this.city_id
      }
      mapboxgl.accessToken = consts.mapboxAccessToken;
      let map_options = getMapOptions();
      //console.log(map_options);
      let zoom = map_options ? map_options.zoom : 12;
      let center = map_options
        ? [map_options.lng, map_options.lat]
        : consts.mapCenter[city_id - 1];
      var map = new mapboxgl.Map({
        container: "map",
        style: consts.mapBoxStyle,
        //center: [-87.61694, 41.86625],
        center: center,
        zoom: zoom,
        pitch: 0,
        bearing: 0,
        attributionControl: false
      });
      this.map = map;
      //定位图标
      map.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl
        })
      );
      this.map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: true
        })
      );
      //控制图层
      var nav = new mapboxgl.NavigationControl();
      this.map.addControl(nav, "top-right");
      //全屏按钮
      this.map.addControl(
        new mapboxgl.FullscreenControl({
          container: document.querySelector("body")
        })
      );
      map.on("load", function () {

      })
      //小地图
      //https://github.com/brendanmatkin/mapboxgl-minimap
      map.on("style.load", function () {
        self.reloadData()
        // Possible position values are 'bottom-left', 'bottom-right', 'top-left', 'top-right'
        map.addControl(
          new MiniMap({
            id: "mapboxgl-minimap",
            width: "200px",
            height: "150px",
            //style: "mapbox://styles/mapbox/streets-v10",
            style: consts.mapBoxStyle,
            fillColor: "#f58220",
            zoomAdjust: null
          }),
          "bottom-right"
        );

      });
      //记录地图设置
      map.on("move", function () {
        storeMapOptions({
          lng: map.getCenter().lng,
          lat: map.getCenter().lat,
          zoom: map.getZoom()
        });
      });
    },
    addCylinder(type, data) {
      const _this = this;

      if (typeof data.features == "undefined") return;
      let newList = [];
      data.features.forEach((item, idx) => {
        newList.push({
          id: item.properties.id,
          lat: item.geometry.coordinates[0],
          log: item.geometry.coordinates[1],
          number: item.properties.household
        })
      })

      data = newList;

      const layer_id = this.getMapLayerId(type);
      mapboxgl.accessToken = consts.mapboxAccessToken;
      let map_options = getMapOptions();
      let zoom = map_options ? map_options.zoom : 12;
      let center = map_options
        ? [map_options.lng, map_options.lat]
        : consts.mapCenter[city_id - 1];
      debugger;

      const distance = 29000; // three坐标&世界对标比,需要按照实际情况调整
      for (let i = 0; i < data.length; i++) {
        data[i].radius = 15;
        data[i].radialSegments = 32;
        data[i].heightSegments = 1;
        data[i].x = (data[i].log - center[0]) * distance;
        data[i].z = -(data[i].lat - center[1]) * distance;
      }
      // 横向x轴，纵向z轴
      // console.log(data) // 街道中心点数据
      const modelOrigin = center;
      const modelAltitude = 0;
      const modelRotate = [Math.PI / 2, 0, 0];
      const modelScale = 0.0000001;

      const modelTransform = {
        translateX: mapboxgl.MercatorCoordinate.fromLngLat(
          modelOrigin,
          modelAltitude
        ).x,
        translateY: mapboxgl.MercatorCoordinate.fromLngLat(
          modelOrigin,
          modelAltitude
        ).y,
        translateZ: mapboxgl.MercatorCoordinate.fromLngLat(
          modelOrigin,
          modelAltitude
        ).z,
        rotateX: modelRotate[0],
        rotateY: modelRotate[1],
        rotateZ: modelRotate[2],
        scale: modelScale
      };

      const cylinderLayer = {
        id: layer_id,
        type: "custom",
        renderingMode: "3d",
        onAdd: function (map, gl) {
          this.camera = new THREE.Camera();
          this.scene = new THREE.Scene();

          const directionalLight = new THREE.DirectionalLight(0xffffff);
          directionalLight.position.set(0, -100, 100).normalize();
          this.scene.add(directionalLight);

          const spotLight = new THREE.SpotLight(0xffffff, 1);
          spotLight.position.set(0, 1000, 0);
          this.scene.add(spotLight);

          // three圆柱体
          // https://threejs.org/docs/index.html#api/en/geometries/CylinderGeometry
          // 圆柱上半径，圆柱下半径，高，柱体侧边三角面，柱体高度三角面
          const cylinderArr = _this.initCylinder(data);
          for (let i = 0; i < cylinderArr.length; i++) {
            this.scene.add(cylinderArr[i]);
          }

          this.map = map;
          // use the Mapbox GL JS map canvas for three.js
          this.renderer = new THREE.WebGLRenderer({
            antialias: true, // 抗锯齿
            canvas: map.getCanvas(),
            context: gl
          });

          this.renderer.autoClear = false;
        },
        render: function (gl, matrix) {
          var rotationX = new THREE.Matrix4().makeRotationAxis(
            new THREE.Vector3(1, 0, 0),
            modelTransform.rotateX
          );
          var rotationY = new THREE.Matrix4().makeRotationAxis(
            new THREE.Vector3(0, 1, 0),
            modelTransform.rotateY
          );
          var rotationZ = new THREE.Matrix4().makeRotationAxis(
            new THREE.Vector3(0, 0, 1),
            modelTransform.rotateZ
          );

          var m = new THREE.Matrix4().fromArray(matrix);
          var l = new THREE.Matrix4()
            .makeTranslation(
              modelTransform.translateX,
              modelTransform.translateY,
              modelTransform.translateZ
            )
            .scale(
              new THREE.Vector3(
                modelTransform.scale,
                -modelTransform.scale,
                modelTransform.scale
              )
            )
            .multiply(rotationX)
            .multiply(rotationY)
            .multiply(rotationZ);

          this.camera.projectionMatrix.elements = matrix;
          this.camera.projectionMatrix = m.multiply(l);
          this.renderer.state.reset();
          this.renderer.render(this.scene, this.camera);
          this.map.triggerRepaint();
        }
      };
      this.map.addLayer(cylinderLayer);
      // https://www.mapbox.cn/mapbox-gl-js/api/#map#addlayer
      // Parameters
      // layer((Object | CustomLayerInterface))需要添加的样式图层，符合 Mapbox 样式规范的 图层定义 。
      // beforeId(string?)用来插入新图层的现有图层 ID。 如果该参数（argument）被省略，该图层将会被添加到图层数组的末尾。
    },
    removeCylinder() {
      // id Cylinder
      if (this.map.getLayer("Cylinder")) {
        this.map.removeLayer("Cylinder", null);
      }
    },
    /**
     * 高度，颜色根据离户数量显示，必要值：radius, radialSegments, heightSegments, x, y(x,y是three坐标系)
     */
    initCylinder(obj) {
      let cylindersMesh = [];
      let height, color;
      for (let i = 0; i < obj.length; i++) {
        for (let j = 0; j < this.householdData.length; j++) {
          if (obj[i].number < this.householdData[j].name) {
            height = 30 * (j + 1);
            color = this.householdData[j].color;

            break;
          }
        }

        const geometry = new THREE.CylinderGeometry(
          obj[i].radius,
          obj[i].radius,
          height,
          obj[i].radialSegments,
          obj[i].heightSegments
        );
        const material = new THREE.MeshStandardMaterial({
          color: color
        });
        const cylinder = new THREE.Mesh(geometry, material);
        cylinder.position.x = obj[i].x;
        cylinder.position.z = obj[i].z;
        cylinder.position.y = 50;
        cylindersMesh.push(cylinder);
      }
      console.log(cylindersMesh)
      debugger;
      return cylindersMesh;
    },

    showList() {
      this.show_list = !this.show_list;
    },
    donwloadList() { },
    showChart(city_id) {
      var self = this;
      var container = document.querySelector('.el-collapse');
      var box = document.getElementById("box-chart");
      box.style.width = container.offsetWidth + "px";
      var myChart = this.$echarts.init(box);
      byEconomicLoss(city_id).then(res => {
        var chart_data = res.data;
        var chart_name = "直接经济损失";
        chart_data.seriesData.map((item, idx) => {
          return item.color = self.colors['ecnomic'][idx]
        })
        var option = buildBarStackOption(chart_name, chart_data, {
          xAxis: {
            name: '建筑数量',
            nameLocation: 'center',
            nameGap: 30,
          }
        });
        myChart.setOption(option);
      });
      // 直接经济损失率
      var box2 = document.getElementById("box-chart2");
      box2.style.width = container.offsetWidth + "px";
      var myChart2 = this.$echarts.init(box2);
      byEconomicLossRate(city_id).then(res => {
        var chart_data = res.data;
        var chart_name = "直接经济损失率(%)";
        chart_data.seriesData.map((item, idx) => {
          return item.color = self.colors['ecnomic'][idx]
        })
        var option = buildBarStackOption(chart_name, chart_data, {
          xAxis: {
            name: '建筑数量',
            nameLocation: 'center',
            nameGap: 30,
          }
        });
        myChart2.setOption(option);
      });

      // 凌晨2点-人口伤亡
      var box3 = document.getElementById("box-chart3");
      box3.style.width = container.offsetWidth + "px";
      var myChart3 = this.$echarts.init(box3);
      byDeathMorning(city_id).then(res => {
        var chart_data = res.data;
        var chart_name = "凌晨2点-人口伤亡";
        chart_data.seriesData.map((item, idx) => {
          return item.color = self.colors['death'][idx]
        })
        var option = buildBarStackOption(chart_name, chart_data, {
          xAxis: {
            name: '伤亡人口',
            nameLocation: 'center',
            nameGap: 30,
          }
        });
        myChart3.setOption(option);
      });


      // 下午2点-人口伤亡
      var box4 = document.getElementById("box-chart4");
      box4.style.width = container.offsetWidth + "px";
      var myChart4 = this.$echarts.init(box4);
      byDeathAfternoon(city_id).then(res => {
        var chart_data = res.data;
        var chart_name = "下午2点-人口伤亡";
        chart_data.seriesData.map((item, idx) => {
          return item.color = self.colors['death'][idx]
        })
        var option = buildBarStackOption(chart_name, chart_data, {
          xAxis: {
            name: '伤亡人口',
            nameLocation: 'center',
            nameGap: 30,
          }
        });
        myChart4.setOption(option);
      });


      // 离家住户数
      var box5 = document.getElementById("box-chart5");
      box5.style.width = container.offsetWidth + "px";
      var myChart5 = this.$echarts.init(box5);
      byTenants(city_id).then(res => {
        var chart_data = res.data;
        var chart_name = "离家住户数";
        chart_data.map((item, idx) => {
          return item.color = self.colors['tenants'][idx]
        })
        var option = buildBarOption(chart_name, chart_data, 'name');
        myChart5.setOption(option);
      });

      // 离家住户率
      var box6 = document.getElementById("box-chart6");
      box6.style.width = container.offsetWidth + "px";
      var myChart6 = this.$echarts.init(box6);
      byTenantsRate(city_id).then(res => {
        var chart_data = res.data;
        var chart_name = "离家住户率";
        chart_data.map((item, idx) => {
          return item.color = self.colors['tenants_percent'][idx]
        })
        var option = buildBarOption(chart_name, chart_data, 'name', { yAxis: { name: '%' } });
        myChart6.setOption(option);
      });

      // 功能损失
      var box7 = document.getElementById("box-chart7");
      box7.style.width = container.offsetWidth + "px";
      var myChart7 = this.$echarts.init(box7);
      byFunctionLoss(city_id).then(res => {
        var chart_data = res.data;
        var chart_name = "功能损失";
        chart_data.seriesData.map((item, idx) => {
          return item.color = self.colors['function_losses'][idx]
        })
        var option = buildBarStackOption(chart_name, chart_data, {
          xAxis: {
            name: '建筑数量',
            nameLocation: 'center',
            nameGap: 30,
          }
        });
        myChart7.setOption(option);
      });

      window.onresize = function () {
        document.querySelectorAll('.chart-area').forEach((item, idx) => {
          item.style.width = document.querySelector('.el-collapse').offsetWidth + 'px';
        })
        myChart.resize();
        myChart2.resize();
        myChart3.resize();
        myChart4.resize();
        myChart5.resize();
        myChart6.resize();
        myChart7.resize();
      }
    },
    fillStreets(city_id) {
      getStreets(city_id).then(res => {
        let data = res.data;
        data.unshift({ id: 0, name: "街道" });
        this.selects.streets = data;
      });
    },
    fillCommunities(city_id, street_id) {
      getCommunities(city_id, street_id).then(res => {
        let data = res.data;
        data.unshift({ id: 0, name: "社区" });
        this.selects.communities = data;
      });
    },
    fillPeopleData(page) {
      let params = this.buildParams();
      params.page = page;
      getPeopleStat(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillGenderData(page) {
      let params = this.buildParams();
      params.page = page;
      getGenderStat(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillHospitalsData(city_id, page) {
      let params = this.buildParams();
      params.page = page;
      getHospitals(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillHideawaysData(page) {
      let params = this.buildParams();
      params.page = page;
      getHideaways(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillWarehousesData(page) {
      let params = this.buildParams();
      params.page = page;
      getWarehouses(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillBuildings(city_id, page) {
      let params = this.buildParams();
      params.page = page;
      params.page_size = 10;
      if (this.selects.data_category == 'economic_losses') {
        getEconomicLossesList(params).then(res => {
          this.total = res.data.total;
          this.data = res.data.items;
        });
        getEconomicLossStatistics(params).then(res => {
          this.statistics.economic_losses.total = res.data.total;
        });
        this.downloadUrl = consts.requestUrl + consts.exportEcnomicLoss + parseJsonToString(params)
      }
      if (this.selects.data_category == 'economic_losses_percent') {
        getEconomicLossesRateList(params).then(res => {
          this.total = res.data.total;
          this.data = res.data.items;
        });
        getEconomicLossRateStatistics(params).then(res => {
          this.statistics.economic_losses_percent.percentage = res.data.percentage;
        });
        this.downloadUrl = consts.requestUrl + consts.exportEcnomicLossRate + parseJsonToString(params)
      }
      if (this.selects.data_category == 'death_morning') {
        getmorningInjureList(params).then(res => {
          //this.pagination.perPage = 100;
          //this.pagination.currentPage = 1;
          this.total = res.data.total;
          this.data = res.data.items;

        });
        getMorningStatistics(params).then(res => {
          this.statistics.death_morning.total = res.data.total;
        });
        this.downloadUrl = consts.requestUrl + consts.exportDeathMorning + parseJsonToString(params)
      }
      if (this.selects.data_category == 'death_afternoon') {
        getAfternoonInjureList(params).then(res => {
          //this.pagination.perPage = 100;
          //this.pagination.currentPage = 1;
          this.total = res.data.total;
          this.data = res.data.items;
        });
        getAfternoonStatistics(params).then(res => {
          this.statistics.death_afternoon.total = res.data.total;
        });
        this.downloadUrl = consts.requestUrl + consts.exportDeathAfternoon + parseJsonToString(params)
      }
      if (this.selects.data_category == 'tenants') {
        getLeavehomeList(params).then(res => {
          this.total = res.data.total;
          this.data = res.data.items;
        });
        getTenantsStatistics(params).then(res => {
          this.statistics.tenants.total = res.data.total;
        });
        this.downloadUrl = consts.requestUrl + consts.exportAwayFromHome + parseJsonToString(params)
      }
      if (this.selects.data_category == 'tenants_percent') {
        getLeavehomeRateList(params).then(res => {
          this.total = res.data.total;
          this.data = res.data.items;
        });
        getTenantsRateStatistics(params).then(res => {
          this.statistics.tenants_percent.percentage = res.data.percentage;
        });
        this.downloadUrl = consts.requestUrl + consts.exportAwayFromHomeRate + parseJsonToString(params)
      }
      if (this.selects.data_category == 'function_losses') {
        getFunctionLossList(params).then(res => {
          this.total = res.data.total;
          this.data = res.data.items;
        });
        getFunctionalStatusStatistics(params).then(res => {
          this.statistics.function_losses.re = res.data.re;
          this.statistics.function_losses.ru = res.data.ru;
          this.statistics.function_losses.ro = res.data.ro;
          this.statistics.function_losses.bf = res.data.bf;
          this.statistics.function_losses.ff = res.data.ff;
        });
        this.downloadUrl = consts.requestUrl + consts.exportFunctionLosses + parseJsonToString(params)

      }

    },
    fillBuildingsMap(city_id, type) {
      let params = this.buildParams();
      if (this.selects.data_category == 'economic_losses') {
        getEconomicLossesGeojson(params).then(data => {
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addBuildings2Map(this.map, type, geoJson);
        });
      }
      if (this.selects.data_category == 'economic_losses_percent') {
        getEconomicLossesRateGeojson(params).then(data => {
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addBuildings2Map(this.map, type, geoJson);
        });
      }
      if (this.selects.data_category == 'function_losses') {
        getFunctionLossGeojson(params).then(data => {
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addBuildings2Map(this.map, type, geoJson);
        });
      }


      // getGeoJson(params).then(res => {
      //   let geoJson = res.data;

      //   if (this.selects.data_category == 'economic_losses') {
      //     //ecnomicloss
      //     //mock数据开始
      //     // geoJson.features.map((item, idx) => {
      //     //   return (item.properties.economicloss = parseInt(
      //     //     Math.random() * 50000000
      //     //   ));
      //     // });
      //   } else if (this.selects.data_category == 'economic_losses_percent') {
      //     //ecnomiclossrate
      //     //mock数据开始
      //     // geoJson.features.map((item, idx) => {
      //     //   return (item.properties.economiclossrate = Math.random() * 0.5);
      //     // });
      //   } else if (this.selects.data_category == 'function_losses') {
      //     //function_losses
      //     //mock数据开始
      //     geoJson.features.map((item, idx) => {
      //       return item.properties.functionloss = Math.ceil(Math.random()*5);
      //     });
      //   }
      //   this.addBuildings2Map(this.map, type, geoJson);
      // });

      //临时
      //this.addBuildings2Map(this.map, type);
    },
    fillHeatMap(city_id, type) {
      let params = this.buildParams();
      if (this.selects.data_category == 'death_morning') {
        getmorningInjureGeojson(params).then(data => {
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addHeatmapLayer(this.map, type, geoJson);
        });
      }
      if (this.selects.data_category == 'death_afternoon') {
        getAfternoonInjureGeojson(params).then(data => {
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addHeatmapLayer(this.map, type, geoJson);
        });
      }
      // getGeoJson(params).then(res => {
      //   let geoJson = res.data;
      //   //mock 热力图数据
      //   //mock数据开始
      //   // geoJson.features.map((item, idx) => {
      //   //   return (item.properties.death = Math.random());
      //   // });
      //   this.addHeatmapLayer(this.map, type, geoJson);
      // });

      //临时
      //this.addHeatmapLayer(this.map, type);
    },
    fillBlockmapLayer(city_id, type, layer_name) {
      if (!layer_name) layer_name = type;
      let params = this.buildParams();
      if (this.selects.data_category == 'death_morning' || this.selects.data_category == 'death_afternoon') {
        getInjureBlockGeojson(params).then(data => {
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addBlockmapLayer(this.map, layer_name, geoJson);
        });
      } else if (this.selects.data_category == 'tenants') {
        getLeavehomeGeojson(params).then(data => {
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addBlockmapLayer(this.map, layer_name, geoJson);
        });
      } else if (this.selects.data_category == 'tenants_percent') {
        getLeavehomeRateGeojson(params).then(data => {
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addBlockmapLayer(this.map, layer_name, geoJson);
        });
      }

      // getGeoJson(params).then(res => {
      //   let geoJson = res.data;
      //   //mock polygon数据 for 伤亡
      //   geoJson = streetOutline;
      //   if(type == 'community_people') {
      //     geoJson.features.map((item, idx) => {
      //       return (item.properties.population = parseInt(
      //         Math.random() * 10000
      //       ));
      //     });
      //   }
      //   //mock polygon数据 for 离家住户
      //   if(type == 'tenants') {
      //     geoJson.features.map((item, idx) => {
      //       return (item.properties.population = parseInt(
      //         Math.random() * 2000
      //       ));
      //     });
      //   }

      //   if(type == 'tenants_percent') {
      //     geoJson.features.map((item, idx) => {
      //       return (item.properties.population = 
      //         Math.random() * 0.5
      //       );
      //     });
      //   }

      //   this.addBlockmapLayer(this.map, layer_name, geoJson);

      // });

      //临时
      /* fetch('/geojson/GeoJson-block.json').then(res => res.json())
      .then(json => {
        let geoJson = json;
        if(type == 'community_people') {
          geoJson.features.map((item, idx) => {
            return (item.properties.population = parseInt(
              Math.random() * 10000
            ));
          });
        }
        //mock polygon数据 for 离家住户
        if(type == 'tenants') {
          geoJson.features.map((item, idx) => {
            return (item.properties.population = parseInt(
              Math.random() * 2000
            ));
          });
        }

        if(type == 'tenants_percent') {
          geoJson.features.map((item, idx) => {
            return (item.properties.population = 
              Math.random() * 0.5
            );
          });
        }
        this.addBlockmapLayer(this.map, layer_name,geoJson);
      }) */

    },
    fillCylinderLayer(city_id, type) {
      let params = this.buildParams();
      getHouseTotalGeojson(params).then(data => {
        let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        this.addCylinder(type, geoJson)
      });

    },
    reloadData(type) {
      if (type == 'street') {
        this.selects.community = 0;
      }
      this.setTipContent();
      this.fillBuildings(this.city_id, 1);


      //关掉叠加涂层
      this.community_people = false;
      this.household = false;

      // if(!['death_morning','death_afternoon'].includes(this.selects.data_category)) {
      //   //关掉叠加涂层
      //   this.community_people = false;
      // }
      // if(!['tenants','tenants_percent'].includes(this.selects.data_category)) {
      //   //关掉叠加涂层
      //   this.household = false;
      // }



      //this.loadData(this.data_type);
      if (['economic_losses', 'economic_losses_percent', 'function_losses'].includes(this.selects.data_category)) {
        //经济损失、经济损失率、功能状态
        //this.removeMapLayer(this.map, "community_people");
        //this.removeMapLayer(this.map, "household");
        //建筑
        this.maptype = 'building';
        this.fillBuildingsMap(this.city_id, "building");
      } else {
        this.removeMapLayer(this.map, "building");
      }
      if (['death_morning', 'death_afternoon'].includes(this.selects.data_category)) {
        //凌晨2点人口伤亡 下午2点人口伤亡
        //热力图
        this.maptype = "heatmap"
        this.fillHeatMap(this.city_id, 'death');
      } else {
        this.removeMapLayer(this.map, "death");
      }

      if (this.selects.data_category === 'tenants' || this.selects.data_category === 'tenants_percent') {
        // 离家住户
        this.fillBlockmapLayer(this.city_id, this.selects.data_category, 'block')
        //this.addCylinder();
      } else {
        this.removeMapLayer(this.map, 'block');
      }


    },
    setTipContent() {
      let html = ''
      if (this.selects.data_category == 'economic_losses') {
        html = consts.tipsEcnomicLoss;
      }
      if (this.selects.data_category == 'economic_losses_percent') {
        html = consts.tipsEcnomicLossRate;
      }
      if (this.selects.data_category == 'death_morning') {
        html = consts.tipsDeathMorning;
      }
      if (this.selects.data_category == 'death_afternoon') {
        html = consts.tipsDeathAfternoon;
      }
      if (this.selects.data_category == 'tenants') {
        html = consts.tipsAwayFromHome;
      }
      if (this.selects.data_category == 'tenants_percent') {
        html = consts.tipsAwayFromHomeRate;
      }
      if (this.selects.data_category == 'function_losses') {
        html = consts.tipsFunctionLosses;
      }

      document.getElementById('left-tip-content').innerHTML = html
    },
    buildParams() {
      let params = { city_id: this.city_id };
      params.street_id = this.selects.street;
      params.community_id = this.selects.community;
      params.struct_id = this.selects.struct;
      params.category_id = this.selects.category;
      params.quake_level = this.selects.quakeLevel;
      params.start_build_year = 0;
      params.end_build_year = 0;
      if (this.selects.buildYear != '修建年代') {
        params.start_build_year = this.selects.buildYear.split('-')[0];
        params.end_build_year = this.selects.buildYear.split('-')[1];
      }
      if (this.selects.data_category == 'economic_losses') {
        params.status = this.selects.economicLoss;
      }
      if (this.selects.data_category == 'economic_losses_percent') {
        params.status = this.selects.economicLossesPercent;
      }
      if (this.selects.data_category == 'death_morning') {
        params.status = this.selects.deathMorning;
      }
      if (this.selects.data_category == 'death_afternoon') {
        params.status = this.selects.deathAfternoon;
      }
      if (this.selects.data_category == 'tenants') {
        params.status = this.selects.awayFromHome;
      }
      if (this.selects.data_category == 'tenants_percent') {
        params.status = this.selects.awayFromHomeRate;
      }
      if (this.selects.data_category == 'function_losses') {
        params.status = this.selects.functionalAvalible;
      }

      params.keyword = this.keyword;

      return params;
    },
    changePage: function (value) {
      this.fillBuildings(this.city_id, value);
    },
    addBuildings2Map(map, type, geoJsonData) {
      if (this.maptype != 'building') {
        return;
      }
      //console.log(geoJsonData)
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      //let layer_id = "room-extrusion";
      //let source_name = "geojson";
      if (typeof geoJsonData.features == "undefined") return;
      var layer = map.getLayer(layer_id);
      let colorExpress = ["get", "color"];
      if (this.selects.data_category == 'economic_losses') {
        colorExpress = [
          "step",
          ["get", "economic_losses"],
          "#283D94", 500000,
          "#4F22A6", 1000000,
          "#B22FB9", 2000000,
          "#C9305A", 5000000,
          "#D55328", 10000000,
          "#F5C243", 50000000,
          "#F5C243" // other
        ];
      } else if (this.selects.data_category == 'economic_losses_percent') {
        colorExpress =
          [
            "step",
            ["get", "economic_losses_rate"],
            "#283D94", 0,
            "#4F22A6", 0.2,
            "#B22FB9", 0.3,
            "#C9305A", 0.4,
            "#D55328", 0.5,
            "#F5C243", 0.6,
            "#F5C243" // other
          ];
      } else if (this.selects.data_category == 'function_losses') {
        colorExpress = [
          "interpolate",
          ["linear"],
          ["get", "status"],
          1,
          "#e029f9",
          2,
          "#ff3708",
          3,
          "#fef71d",
          4,
          "#4aff41",
          5,
          "#02caff",
        ];
      }
      if (layer) {
        map.removeLayer(layer_id);
        map.removeSource(source_name);
      }
      map.addSource(source_name, {
        type: "geojson",
        data: geoJsonData
        //data: "./geojson/GeoJson-2.2.2-3.geojson"
      });
      map.addLayer({
        id: layer_id,
        type: "fill-extrusion",
        source: source_name,
        //'source': {
        // GeoJSON Data source used in vector tiles, documented at
        // https://gist.github.com/ryanbaumann/a7d970386ce59d11c16278b90dde094d
        //  'type': 'geojson',
        //  'data':geoJsonData,
        //'data': "http://api.urban.test/api/buildings-geo-json?struct_id=2&category_id=1&street_id=1"
        //'data': "https://docs.mapbox.com/mapbox-gl-js/assets/indoor-3d-map.geojson"
        //},
        paint: {
          // See the Mapbox Style Specification for details on data expressions.
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions

          // Get the fill-extrusion-color from the source 'color' property.
          "fill-extrusion-color": colorExpress,

          // Get fill-extrusion-height from the source 'height' property.
          "fill-extrusion-height": ["get", "height"],

          // Get fill-extrusion-base from the source 'base_height' property.
          "fill-extrusion-base": ["get", "base_height"],

          // Make extrusions slightly opaque for see through indoor walls.
          "fill-extrusion-opacity": 0.8
        }
      });
    },
    addHeatmapLayer(map, type, geoJsonData) {
      debugger;
      if (this.maptype != 'heatmap') {
        return;
      }
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      let layer_point_id = layer_id + "point";
      let get_param = "casualty"
      if (typeof geoJsonData.features == "undefined") return;
      // if(this.selects.data_category == "death_morning") {
      //   get_param = "casualty"
      // } else if(this.selects.data_category == "death_afternoon") {
      //   get_param = "casualty"
      // }
      // Add a geojson point source.
      // Heatmap layers also work with a vector tile source.
      map.addSource(source_name, {
        type: "geojson",
        //data: "https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson"
        data: geoJsonData
        //data: "./geojson/GeoJson-2.2.2-3.geojson"
      });

      map.addLayer(
        {
          id: layer_id,
          type: "heatmap",
          source: source_name,
          maxzoom: 15,
          paint: {
            // Increase the heatmap weight based on frequency and property magnitude
            "heatmap-weight": [
              "interpolate",
              ["linear"],
              ["get", get_param],
              0, 0,
              30, 1
            ],
            // Increase the heatmap color weight weight by zoom level
            // heatmap-intensity is a multiplier on top of heatmap-weight
            "heatmap-intensity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              0, 1,
              18, 1.5
            ],
            // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
            // Begin color ramp at 0-stop with a 0-transparancy color
            // to create a blur-like effect.
            "heatmap-color": [
              "interpolate",
              ["linear"],
              ["heatmap-density"],
              0,
              "rgba(0, 0, 255, 0)",
              0.15,
              "#FDF0D0",
              0.35,
              "#F5C243",
              0.7,
              "#D8833B",
              1,
              "#D55745"
            ],
            // Adjust the heatmap radius by zoom level
            "heatmap-radius": [
              "interpolate",
              ["linear"],
              ["zoom"],
              0, 1,
              15, 30//热点大图半径
            ],
            // Transition from heatmap to circle layer by zoom level
            "heatmap-opacity": ["interpolate", ["linear"], ["zoom"], 9, 1, 15, 0.2]
          }
        },
        "waterway-label"
      );

      map.addLayer(
        {
          id: layer_point_id,
          type: "circle",
          source: source_name,
          minzoom: 13,
          paint: {
            // Size circle radius by earthquake magnitude and zoom level
            "circle-radius": [
              "interpolate",
              ["linear"],
              ["zoom"],
              13,
              ["interpolate", ["linear"], ["get", get_param], 0, 1, 30, 5],
              15,
              ["interpolate", ["linear"], ["get", get_param], 0, 5, 30, 20]
            ],
            // Color circle by earthquake magnitude
            "circle-color": [
              "interpolate",
              ["linear"],
              ["get", get_param],
              0,
              "rgba(0, 0, 255, 0)",
              5,
              "#FDF0D0",
              10,
              "#F5C243",
              20,
              "#D8833B",
              30,
              "#D55745"
            ],
            //"circle-stroke-color": "white",
            //"circle-stroke-width": 1,
            // Transition from heatmap to circle layer by zoom level
            "circle-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              12, 0,
              16, 1
            ]
          }
        },
        "waterway-label"
      );
    },
    addPointLayerToMap(map, type) {
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      let geojson_url,
        icon = "";
      switch (type) {
        case "hospital":
          geojson_url = consts.url_geojson_hospital;
          icon = "hospital-15";
          break;
        case "hideaway":
          geojson_url = consts.url_geojson_hideaway;
          icon = "school-15";
          break;
        case "warehouse":
          geojson_url = consts.url_geojson_warehouse;
          icon = "parking-15";
          break;
      }
      if (geojson_url == "") {
        return;
      }
      var styleLoadStatus = map.isStyleLoaded();
      if (styleLoadStatus) {
        map.addSource(source_name, {
          type: "geojson",
          data: geojson_url
        });
        map.addLayer({
          id: layer_id,
          type: "symbol",
          source: source_name,
          layout: {
            "icon-image": icon,
            "text-field": "{name}",
            //"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-offset": [0, 0.6],
            "text-anchor": "top"
          }
        });
        return;
      }
      map.on("load", function () {
        map.addSource(source_name, {
          type: "geojson",
          data: geojson_url
        });
        map.addLayer({
          id: layer_id,
          type: "symbol",
          source: source_name,
          layout: {
            "icon-image": icon,
            "text-field": "{name}",
            //"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-offset": [0, 0.6],
            "text-anchor": "top"
          }
        });
      });
    },
    addBlockmapLayer(map, type, geoJsonData) {
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      let layer_id_stroke = layer_id + '_stroke';
      if (typeof geoJsonData.features == "undefined") return;
      map.addSource(source_name, {
        type: "geojson",
        data: geoJsonData
        //data: "./geojson/GeoJson-block.geojson"
      });
      if (type == 'community_people') {
        map.addLayer({
          id: layer_id,
          type: "fill",
          source: source_name,
          layout: {},
          paint: {
            "fill-color": [
              "step",
              ["get", "population"],
              "#f7fbff", 5000,
              "#5fc0f5", 10000,
              "#142f67", 100000,
              "#142f67" // other
            ],
            "fill-opacity": 1
          }
        });
      } else if (this.selects.data_category == "tenants") {
        //离家住户
        map.addLayer({
          id: layer_id,
          type: "fill",
          source: source_name,
          layout: {},
          paint: {
            "fill-color": [
              "step",
              ["get", "leave_home"],
              "#242F7F", 500,
              "#447DB3", 700,
              "#62BFF5", 800,
              "#94CFBF", 1200,
              "#CEE8B9", 1500,
              "#FDF1D0", 2000,
              "#FDF1D0" // other
            ],
            "fill-opacity": 1
          }
        });
      } else if (this.selects.data_category == "tenants_percent") {
        //离家住户
        map.addLayer({
          id: layer_id,
          type: "fill",
          source: source_name,
          layout: {},
          paint: {
            "fill-color": [
              "step",
              ["get", "leave_home_rate"],
              "#242F7F", 0.1,
              "#447DB3", 0.2,
              "#62BFF5", 0.3,
              "#94CFBF", 0.4,
              "#CEE8B9", 0.5,
              "#FDF1D0", 1,
              "#FDF1D0" // other
            ],
            // "fill-color": ["interpolate",["linear"],["get", "leave_home_rate"],
            //   0.1, "#242F7F",
            //   0.2, "#447DB3",
            //   0.3, "#62BFF5",
            //   0.4, "#94CFBF",
            //   0.5, "#CEE8B9",
            //   1, "#FDF1D0",
            // ],
            "fill-opacity": 1
          }
        });
      }
      //描边
      map.addLayer({
        id: layer_id_stroke,
        type: "line",
        source: source_name,
        layout: {},
        paint: {
          "line-color": '#000000',
          "line-width": 1
        }
      });

      //社区人口分布要在热力图底下
      if (type == 'community_people') {
        this.map.moveLayer(this.getMapLayerId(type), this.getMapLayerId('death'));
        this.map.moveLayer(this.getMapLayerId(type) + '_stroke', this.getMapLayerId('death'));
      }
    },
    toggleMapLayer(type) {
      if (this[type]) {
        //勾选
        //换视角
        if (type == 'household') {
          this.map.easeTo({
            pitch: 60
          });
        }
        if (type == 'community_people') {
          this.fillBlockmapLayer(this.city_id, type);
        } else if (type == 'household') {

          this.fillCylinderLayer(this.city_id, type);
        }
      } else {
        this.map.easeTo({
          pitch: 0
        });
        this.removeMapLayer(this.map, type);
      }
    },
    // handelMapLayer(type) {
    //   console.log(type);
    //   this.selects.street = 0;
    //   this.selects.community = 0;
    //   this.loadData(type);
    //   let building_types = [
    //     "economic_losses",
    //     "economic_losses_percent",
    //     "function_losses"
    //   ];
    //   let heatmap_types = ["death_afternoon", "death_morning"];
    //   let block_types = ["tenants", "tenants_percent"];
    //   //building
    //   building_types.forEach(value => {
    //     if (value == type) {
    //       this.addPointLayerToMap(this.map, value);
    //     }
    //   });
    //   //heatmap
    //   heatmap_types.forEach(value => {
    //     if (value == type) {
    //       this.addHeatmapLayer(this.map, value);
    //     }
    //   });
    //   //社区着色
    //   block_types.forEach(value => {
    //     if (value == type) {
    //       this.addBlockmapLayer(this.map, value);
    //     }
    //   });
    // },
    getMapLayerId(type) {
      let layer_id = type + "-layer";
      return layer_id;
    },
    getMapSourceName(type) {
      let source_name = type + "-data";
      return source_name;
    },
    removeMapLayer(map, type) {
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      var layer = map.getLayer(layer_id);
      let layer_point_id = layer_id + "point";
      //描边
      var layer_stroke = map.getLayer(layer_id + '_stroke');
      //热点图的热点层
      if (map.getLayer(layer_point_id)) {
        map.removeLayer(layer_point_id);
      }
      if (layer_stroke) {
        map.removeLayer(layer_id + '_stroke');
      }
      if (layer) {
        map.removeLayer(layer_id);
      }
      if (map.getSource(source_name)) {
        map.removeSource(source_name);
      }
    },
    loadData(type, page = 1) {
      switch (type) {
        case "people":
          this.fillPeopleData(page);
          break;
        case "gender":
          this.fillGenderData(page);
          break;
        case "hospital":
          this.fillHospitalsData(page);
          break;
        case "hideaway":
          this.fillHideawaysData();
          break;
        case "warehouse":
          this.fillWarehousesData();
          break;
      }
    }
  }
};
</script>



<style lang="scss" scoped>
.card-map {
  min-height: 350px;
  height: calc(100vh - 48px);
  margin-bottom: 0;

  position: relative;

  #map {
    width: 100%;
    height: calc(100vh - 100px);
  }

  .map_data_msg {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    // color: #02f4fe;
    color: #fff;
    padding: 10px;
    width: auto;

    .map_data_legend {
      margin-bottom: 5px;
    }

    .map_data_msg_wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .item {
        width: 80px;
        line-height: 15px;
        padding: 5px 0;
      }

      .item span {
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 3px;
        vertical-align: middle;
        margin-left: 0;
      }

      .map_data_msg_inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 10px;
      }
    }

    .map_data_msg_wrapper_vertical {
      flex-direction: column;

      .item {
        width: 130px;
      }
    }
  }
}

.overlap_layer_desc {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .item {
    line-height: 15px;
    padding: 10px 10px 10px 0;
  }

  .item span {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 3px;
    vertical-align: middle;
    margin-left: 0;
  }

  .map_data_msg_inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 10px;
  }

  img {
    width: 20px;
    vertical-align: top;
  }
}

.main-panel {
  // height: calc(100vh - 130px);
  min-height: 350px;
}

.right-block {
  // height: calc(100vh - 377px);
  // min-height: 350px;
  height: calc(100% - 131px);
  overflow: hidden;
}

.tab-scroller {
  // height: calc(100vh - 470px);
  height: 100%;
  min-height: 350px;
  overflow-y: auto;
}

.show_list {
  width: 100%;
  height: 50px;
  padding-top: 6px;
  // margin-top: 8px;
}

.show_list span {
  height: 32px;
  line-height: 36px;
  margin-left: 32px;
  font-size: 14px;
}

.show_list span i {
  height: 16px;
  padding: 2px 12px;
  background: #ffb236;
  margin: 0 4px;
  border-radius: 16px;
}

.show_list .up_show {
  display: block;
  float: left;
  font-size: 32px;
  cursor: pointer;
}

.show_list .download {
  display: block;
  float: right;
  font-size: 32px;
  cursor: pointer;
}

.footer {
  padding-top: 0;
}

.extended-forms .el-select {
  width: 100%;
  margin-bottom: 30px;
}

.extended-forms .progress {
  margin-bottom: 30px;
}

@import url("https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css");

.coordinates {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 5px 10px;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  display: none;
}

ul.nav__items>li:last-of-type a {
  margin-right: 0 !important;
}

.card-body .tab-content .tab-scroller {
  min-height: auto;
}

.chart-area {
  height: 400px;
}

#box-chart,
#box-chart2 {
  height: 450px;
}
</style>
