<template>
  <div class="row" style="margin:0 -30px;">
    <div class="col-md-9" style="padding:0">
      <card class="card-map">
        <div id="map"></div>
        <menu-map :map="map" :mapDefault="mapdefaultSetting" showDisaster="false"></menu-map>
        <div class="map_data_msg">
          <div class="map_data_legend">单位性质</div>
          <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
            <div class="item" v-for="(item, index) in buildingStructs" :key="index">
              <span :style="'background: ' + item.color + ''"></span>
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="show_list">
          <a class="up_show" v-on:click="showList">
            <i class="now-ui-icons" :class="[show_list ? 'arrows-1_minimal-down' : 'arrows-1_minimal-up']"></i>
          </a>
          <a class="download" :href="downloadUrl">
            <i class="now-ui-icons arrows-1_cloud-download-93"></i>
          </a>
          <span>
            建筑数据列表
            <i>{{ total }}</i>
          </span>
        </div>
        <div class="show_list_record  show_fire_list_record" v-show="show_list"
          :style="{ 'margin-top': -(126 + data.length * 38) + 'px' }" ref="show_list_record">
          <el-table :data="data">
            <el-table-column width="80px" label="建筑ID" property="id"></el-table-column>
            <!-- <el-table-column label="经度" property="lng"></el-table-column>
                <el-table-column label="纬度" property="lat"></el-table-column> -->
            <el-table-column label="街道" property="street_name"></el-table-column>
            <el-table-column label="社区" property="community_name"></el-table-column>
            <el-table-column label="总面积（平方米）" property="total_area"></el-table-column>
            <el-table-column label="高度（米）" property="build_height"></el-table-column>
            <el-table-column label="建筑结构" property="struct_name"></el-table-column>
            <el-table-column label="单位性质" property="nature_name"></el-table-column>
            <el-table-column label="耐火等级" property="fire_level"></el-table-column>
            <el-table-column label="所需消防流量QR（升/秒）" property="fire_flow"></el-table-column>
          </el-table>
          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class>
              <p class="card-category">显示： {{ from + 1 }} - {{ to }} 总数： {{ total }}</p>
            </div>
            <n-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage"
              :total="total" v-on:input="changePage"></n-pagination>
          </div>
        </div>
      </card>
    </div>
    <div class="col-md-3 hasDecision" id="customStyle">
      <div class="col-md-3 clearfix key-decision" style="display: flex;justify-content: space-between;">
        <div class="input-group no-border">
          <search-area :map="map" :mapDefault="mapdefaultSetting"></search-area>
        </div>
        <div class="input-group no-border" style="display: inline;padding-right: 30px;width: 270px;">
          <checkbox v-model="showBuilding" label="仅显示典型单位" v-on:input="!showBuilding">仅显示典型单位</checkbox>
        </div>
      </div>
      <el-collapse v-model="activeNames">
        <el-collapse-item title="信息图表" name="1">
          <div class="col-md-12">
            <div class="chart-area" id="box-chart" style="height:350px;"></div>
          </div>
        </el-collapse-item>
        <el-collapse-item title="数据筛选" name="2">
          <div class="col-md-12">
            <form method="get" action="/">
              <div class="row">
                <div class="col-sm-10">
                  <el-select class="select-default" size="large" placeholder="街道" v-model="selects.street"
                    v-on:change="reloadData">
                    <el-option v-for="option in selects.streets" class="select-primary" :value="option.id"
                      :label="option.name" :key="option.id"></el-option>
                  </el-select>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-10">
                  <el-select class="select-default" size="large" placeholder="社区" v-model="selects.community"
                    v-on:change="reloadData">
                    <el-option v-for="option in selects.communities" class="select-primary" :value="option.id"
                      :label="option.name" :key="option.id"></el-option>
                  </el-select>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-10">
                  <el-select class="select-default" size="large" placeholder="建筑结构" v-model="selects.struct"
                    v-on:change="reloadData">
                    <el-option v-for="(option, index) in selects.structs" class="select-primary" :value="index"
                      :label="option" :key="index"></el-option>
                  </el-select>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-10">
                  <el-select class="select-default" size="large" placeholder="单位性质" v-model="selects.category"
                    v-on:change="reloadData">
                    <el-option v-for="(option, index) in selects.categories" class="select-primary" :value="index"
                      :label="option" :key="index"></el-option>
                  </el-select>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-10">
                  <el-select class="select-default" size="large" placeholder="抗震设防烈度" v-model="selects.quakeLevel"
                    v-on:change="reloadData">
                    <el-option v-for="option in selects.quakeLevels" class="select-primary" :value="option.id"
                      :label="option.name" :key="option.id"></el-option>
                  </el-select>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-10">
                  <el-select class="select-default" size="large" placeholder="修建年代" v-model="selects.buildYear"
                    v-on:change="reloadData">
                    <el-option v-for="(option, index) in selects.buildYears" class="select-primary" :value="index"
                      :label="option" :key="index"></el-option>
                  </el-select>
                </div>
              </div>
            </form>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <!-- <div class="key-infos">
          <div class="key-info">
            {{ statistics.struct_name }}结构占 {{ statistics.percentage }}%
          </div>
        </div> -->
  </div>
</template>
<script>
import mapboxgl from "mapbox-gl";
import MenuMap from "src/components/MenumapFire2";
import SearchArea from "src/components/SearchBuilding";
import MiniMap from "src/mapbox/mapboxgl-minimap.js";
import { TabPane, Tabs, Checkbox } from "src/components";
import geobuf from "geobuf";
import Pbf from "pbf";
import { Input, Button, Select, Option, Collapse, CollapseItem, Table, TableColumn } from "element-ui";
import { consts } from "src/util/consts.js";
import {
  getCategories,
  getStreets,
  getCommunities,
  getList,
  getGeoJson,
  getCategoryStatistics
} from "src/api/building.js";
import { byCategoryNature } from "src/api/chart.js";
import { buildPieOption } from "src/util/chart.js";
import {
  getBuildYearRange,
  storeMapOptions,
  getMapOptions,
  parseJsonToString
} from "src/util/common.js";
import { Pagination as NPagination } from "src/components";
import * as THREE from "three";

export default {
  components: {
    MenuMap,
    SearchArea,
    TabPane,
    Tabs,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    NPagination,
    Checkbox
  },
  data() {
    return {
      activeNames: ['1', '2'],
      activeName: "first",
      tabs: {
        horizontal: "Profile",
        vertical: "Options",
        verticalPills: "Home",
        pageCategories: "Messages"
      },
      selects: {
        street: 0,
        streets: [{ id: 0, name: "街道" }],
        community: 0,
        communities: [{ id: 0, name: "社区" }],
        struct: 0,
        structs: consts.buildingStructsFire,
        category: 0,
        categories: consts.buildingCategoriesFire,
        quakeLevel: 0,
        quakeLevels: [
          { id: 0, name: "抗震设防烈度" },
          { id: 6, name: 6 },
          { id: 7, name: 7 },
          { id: 8, name: 8 }
        ],
        buildYear: 0,
        buildYears: consts.buildingBuildYears
      },
      keyword: "",
      total: 0,
      data: [],
      page: 1,
      map: {},
      color_category: "nature",
      city_id: localStorage.getItem("city_id") || 1,
      show_list: false,
      show_list_height: 0,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 20
      },
      last_updated: consts.last_updated,
      buildingStructs: [
        { name: '住宅', color: '#D55328' },
        { name: '公众聚集场所', color: '#F5C243' },
        { name: '医疗机构', color: '#447DB3' },
        { name: '教育机构', color: '#62BFF5' },
        { name: '国家机关单位', color: '#70AD47' },
        { name: '交通设施', color: '#94CFBF' },
        { name: '文物建筑', color: '#B22FB9' },
        { name: '厂房', color: '#891c1f' },
        { name: '仓库', color: '#d7d7d7' },
        { name: '高层办公、公寓', color: '#9ae1f1' },
        { name: '地下公共建筑', color: '#eba389' },
        { name: '其他', color: '#d9c285' },
      ],
      statistics: {
        percentage: 0,
        struct_name: ''
      },
      mapdefaultSetting: {},
      downloadUrl: '',
      showBuilding: true,
      //colors: ['#D55328','#F5C243','#447DB3','#62BFF5','#70AD47','#94CFBF','#B22FB9','#891c1f']
    };
  },

  mounted() {
    this.fillStreets(this.city_id);
    this.fillCommunities(this.city_id, 0);
    this.showChart(this.city_id);
    this.fillBuildings(this.city_id, 1);
    this.initMap(this.city_id);
    this.setTipContent();
    this.map.on('style.load', () => {
      this.fillBuildingsMap(this.city_id, this.color_category);
    })

  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    street() {
      return this.selects.street;
    }
  },
  watch: {
    street(val) {
      this.fillCommunities(this.city_id, this.selects.street);
    },
    showBuilding(val) {
      if (!val) {
        this.$router.push({
          path: '/fire/building/fireproofing/13'
        })
      }

    }
  },
  methods: {
    // 初始化
    initMap(city_id) {
      this.mapdefaultSetting = {
        center: consts.mapCenter[this.city_id - 1],
        zoom: consts.mapDefaultZoom,
        pitch: 60,
        city_id: this.city_id
      }
      mapboxgl.accessToken = consts.mapboxAccessToken;
      let map_options = getMapOptions();
      //console.log(map_options);
      let zoom = map_options ? map_options.zoom : 12;
      let center = map_options
        ? [map_options.lng, map_options.lat]
        : consts.mapCenter[city_id - 1];
      var map = new mapboxgl.Map({
        container: "map",
        style: consts.mapBoxStyle,
        //center: [-87.61694, 41.86625],
        center: center,
        zoom: zoom,
        pitch: 60,
        bearing: 0,
        attributionControl: false
      });
      this.map = map;
      //定位图标
      map.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl
        })
      );
      this.map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: true
        })
      );
      //控制图层
      var nav = new mapboxgl.NavigationControl();
      this.map.addControl(nav, "top-right");
      //全屏按钮
      this.map.addControl(
        new mapboxgl.FullscreenControl({
          container: document.querySelector("body")
        })
      );
      //小地图
      //https://github.com/brendanmatkin/mapboxgl-minimap
      map.on("style.load", function () {
        // Possible position values are 'bottom-left', 'bottom-right', 'top-left', 'top-right'
        map.addControl(
          new MiniMap({
            id: "mapboxgl-minimap",
            width: "200px",
            height: "150px",
            //style: "mapbox://styles/mapbox/streets-v10",
            style: consts.mapBoxStyle,
            fillColor: "#f58220",
            zoomAdjust: null
          }),
          "bottom-right"
        );
      });
      //记录地图设置
      map.on("move", function () {
        storeMapOptions({
          lng: map.getCenter().lng,
          lat: map.getCenter().lat,
          zoom: map.getZoom()
        });
      });
    },

    addBuildings2Map(map, geoJsonData) {
      //['#fd0047','#F5C243','#7700fd','#0030fd','#00b9fd','#06fd00','#fafd00','#fd4d00']
      if (typeof geoJsonData.features == 'undefined') {
        geoJsonData = {}
      } else {
        geoJsonData.features.forEach((item, idx) => {
          if (item.properties.color == '#c23531') {
            item.properties.color = '#D55328'
          }
          if (item.properties.color == '#2f4554') {
            item.properties.color = '#F5C243'
          }
          if (item.properties.color == '#61a0a8') {
            item.properties.color = '#447DB3'
          }
          if (item.properties.color == '#d48265') {
            item.properties.color = '#62BFF5'
          }
          if (item.properties.color == '#91c7ae') {
            item.properties.color = '#70AD47'
          }
          if (item.properties.color == '#749f83') {
            item.properties.color = '#94CFBF'
          }
          if (item.properties.color == '#ca8622') {
            item.properties.color = '#B22FB9'
          }
          if (item.properties.color == '#bda29a') {
            item.properties.color = '#891c1f'
          }
          if (item.properties.color == '#6e7074') {
            item.properties.color = '#d7d7d7'
          }
          if (item.properties.color == '#546570') {
            item.properties.color = '#9ae1f1'
          }
          if (item.properties.color == '#c4ccd3') {
            item.properties.color = '#eba389'
          }
          if (item.properties.color == '#c4ccd3') {
            item.properties.color = '#d9c285'
          }


        })

      }



      let layer_id = "room-extrusion";
      let source_name = "geojson";
      var layer = map.getLayer(layer_id);
      if (layer) {
        map.removeLayer(layer_id);
        map.removeSource(source_name);
      }
      map.addSource(source_name, {
        type: "geojson",
        data: geoJsonData
      });
      map.addLayer({
        id: layer_id,
        type: "fill-extrusion",
        source: source_name,
        //'source': {
        // GeoJSON Data source used in vector tiles, documented at
        // https://gist.github.com/ryanbaumann/a7d970386ce59d11c16278b90dde094d
        //  'type': 'geojson',
        //  'data':geoJsonData,
        //'data': "http://api.urban.test/api/buildings-geo-json?struct_id=2&category_id=1&street_id=1"
        //'data': "https://docs.mapbox.com/mapbox-gl-js/assets/indoor-3d-map.geojson"
        //},
        paint: {
          // See the Mapbox Style Specification for details on data expressions.
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions

          // Get the fill-extrusion-color from the source 'color' property.
          "fill-extrusion-color": ["get", "color"],

          // Get fill-extrusion-height from the source 'height' property.
          "fill-extrusion-height": ["get", "height"],

          // Get fill-extrusion-base from the source 'base_height' property.
          "fill-extrusion-base": ["get", "base_height"],

          // Make extrusions slightly opaque for see through indoor walls.
          "fill-extrusion-opacity": 0.8
        }
      });
    },
    showList() {
      this.show_list = !this.show_list;
    },
    donwloadList() { },
    showChart(city_id) {
      var container = document.getElementById("container");
      var box = document.getElementById("box-chart");
      var myChart = this.$echarts.init(box);
      byCategoryNature(city_id).then(res => {
        var chart_data = res.data;
        var chart_name = "上城区不同单位性质的典型单位数量占比";
        chart_data.map((item, idx) => {
          item.itemStyle = { color: this.buildingStructs[idx].color }
        })
        var option = buildPieOption(chart_name, chart_data);
        option.tooltip = {
          trigger: 'item',
          formatter: "{a} <br/>{b} : {c} ({d}%)",
          position: function (p) { //其中p为当前鼠标的位置
            return [p[0] - 40, p[1] + 10];
          },
          extraCssText: 'width:160px;white-space:inherit;'
        };
        myChart.setOption(option);
      });
      window.onresize = function () {
        document.querySelectorAll('.chart-area').forEach((item, idx) => {
          item.style.width = document.querySelector('.el-collapse').offsetWidth + 'px';
        })
        myChart.resize();
      }
    },
    fillStreets(city_id) {
      getStreets(city_id).then(res => {
        let data = res.data;
        data.unshift({ id: 0, name: "街道" });
        this.selects.streets = data;
      });
    },
    fillCommunities(city_id, street_id) {
      getCommunities(city_id, street_id).then(res => {
        let data = res.data;
        data.unshift({ id: 0, name: "社区" });
        this.selects.communities = data;
      });
    },
    fillBuildings(city_id, page) {
      let params = this.buildParams();
      params.page = page;
      getList(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
      getCategoryStatistics(params).then(res => {
        this.statistics.percentage = res.data.percentage;
        this.statistics.struct_name = res.data.struct_name;
      });
      this.downloadUrl = consts.requestUrl + consts.exportFireCategory + parseJsonToString(params)
    },
    fillBuildingsMap(city_id, color_category) {
      let params = this.buildParams();
      params.color_category = color_category;
      getGeoJson(params).then(res => {
        let data = res;
        let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        //console.info(JSON.stringify(geoJson));
        this.addBuildings2Map(this.map, geoJson);
      });
      //getGeoJson(params).then(res => {
      //let geoJson = res.data;
      //this.addBuildings2Map(this.map, geoJson);
      //});
    },
    reloadData() {
      this.fillBuildings(this.city_id, 1);
      this.fillBuildingsMap(this.city_id, this.color_category);
    },
    setTipContent() {
      let html = "典型单位：按照单位服务或满足的社会功能而划分的单位性质，分为住宅、公众聚集场所、医疗机构、教育机构、国家机关单位、交通设施、文物建筑、厂房、仓库、高层办公公寓、地下公共建筑和其他。";
      document.getElementById('left-tip-content').innerHTML = html
    },
    buildParams() {
      let params = { city_id: this.city_id };
      params.street_id = this.selects.street;
      //params.category_id = this.selects.category;
      params.category_id = 0;
      params.community_id = this.selects.community;
      params.struct_id = this.selects.struct;
      params.keyword = this.keyword;
      params.quake_level = this.selects.quakeLevel;
      params.nature_id = this.selects.category;
      params.nature = this.selects.category;
      if (this.selects.buildYear > 0) {
        let range_year = getBuildYearRange(
          this.selects.buildYear,
          consts.buildingBuildYears
        );
        console.log(range_year);
        let start_year = range_year.start;
        let end_year = range_year.end;
        params.start_build_year = start_year;
        params.end_build_year = end_year;
      } else {
        params.start_build_year = 0;
        params.end_build_year = 0;
      }
      return params;
    },
    changePage: function (value) {
      if (value != this.page) {
        this.fillBuildings(this.city_id, value);
      }
      this.page = value;
    }
  }
};
</script>
<style lang="scss" scoped>
.card-map {
  min-height: 350px;
  height: calc(100vh - 48px);
  margin-bottom: 0;

  position: relative;

  .map-wrapper {
    width: 100%;
    height: calc(100vh - 100px);
    position: relative;

    &>* {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  #map {
    width: 100%;
    height: calc(100vh - 100px);
  }
}

.main-panel {
  // height: calc(100vh - 130px);
  min-height: 350px;
}

.right-block {
  height: calc(100vh - 100px);
  min-height: 350px;
  overflow: hidden;
}

.tab-scroller {
  height: calc(100vh - 470px);
  min-height: 350px;
  overflow-y: auto;
}

.show_list {
  width: 100%;
  height: 34px;
  margin-top: 12px;
  overflow: hidden;
}

.show_list span {
  height: 32px;
  line-height: 36px;
  margin-left: 32px;
  font-size: 14px;
}

.show_list span i {
  height: 16px;
  padding: 2px 12px;
  background: #ffb236;
  margin: 0 4px;
  border-radius: 16px;
}

.show_list .up_show {
  display: block;
  float: left;
  font-size: 32px;
  cursor: pointer;
}

.show_list .download {
  display: block;
  float: right;
  font-size: 32px;
  cursor: pointer;
}

.footer {
  padding-top: 0;
}

.extended-forms .el-select {
  width: 100%;
  margin-bottom: 30px;
}

.extended-forms .progress {
  margin-bottom: 30px;
}

@import url("https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css");

.coordinates {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 5px 10px;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  display: none;
}

ul.nav__items>li:last-of-type a {
  margin-right: 0 !important;
}

.card-body .tab-content .tab-scroller {
  min-height: auto;
}
</style>
