<template>
  <div class="row" style="margin:0 -30px;">
    <div class="col-md-9" style="padding:0">
      <card class="card-map">
        <div id="map"></div>
        <menu-map :map="map" :mapDefault="mapdefaultSetting" showDisaster="false"></menu-map>
        <div class="radio-group">
          <div v-for="(item, idx) in types" @click="changeType(item.id)">
            <label :for='item.id'><input type="radio" :id="item.id" :value="item.id" v-model="curType">
              {{ item.value }}</label>
          </div>
        </div>
        <div class="map_data_msg" v-if="mapinfo">
          <div class="map_data_legend">{{ mapinfo.title }}</div>
          <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
            <div class="item" v-for="(item, index) in mapinfo.names" :key="index">
              <span :style="'background: ' + mapinfo.colors[index] + ''"></span>
              {{ item }}
            </div>
          </div>
        </div>
        <div class="show_list">
          <a class="up_show" v-on:click="showList">
            <i class="now-ui-icons" :class="[show_list ? 'arrows-1_minimal-down' : 'arrows-1_minimal-up']"></i>
          </a>
          <a class="download ml-auto" :href="downloadUrl">
            <i class="now-ui-icons arrows-1_cloud-download-93"></i>
          </a>
          <span>
            数据列表
            <!-- <i>{{total}}</i> -->
          </span>
        </div>
        <div class="show_list_record tabCount2 show_fire_list_record" v-show="show_list" ref="show_list_record">
          <tabs type="primary" v-model="activeTab">
            <tab-pane label="表1">
              <el-table :data="data" max-height="600" :span-method="objectSpanMethod" empty-text="暂无数据！">
                <el-table-column v-for="(item, index) in listheader" min-width="150" :label="item" :key="index">
                  <template slot-scope="scope">
                    <span>{{ scope.row[index] }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </tab-pane>

            <tab-pane label="表2">
              <el-table :data="data2" max-height="600" :span-method="objectSpanMethod2" empty-text="暂无数据！">
                <el-table-column v-for="(item, index) in listheader2" min-width="150" :label="item" :key="index">
                  <template slot-scope="scope">
                    <span>{{ scope.row[index] }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </tab-pane>

          </tabs>
        </div>
      </card>
    </div>
    <div class="col-md-3 hasDecision" id="customStyle">
      <div class="col-md-12 clearfix key-decision">
        <div class="input-group no-border">
          <search-area :map="map" :mapDefault="mapdefaultSetting"></search-area>
        </div>
      </div>
      <el-collapse v-model="activeNames">
        <el-collapse-item title="信息图表" name="2">
          <div class="col-md-12">
            <div class="chart-area" id="box-chart" style="height:300px"></div>
          </div>
          <div class="col-md-12">
            <div class="chart-area" id="box-chart2" style="height:400px"></div>
          </div>
        </el-collapse-item>
        <el-collapse-item title="数据筛选" name="3">
          <div class="col-md-12 options1">
            <el-cascader :options="options1" placeholder="行政区划" clearable @change="handleChange1">
            </el-cascader>
          </div>
          <div class="col-md-12">
            <el-cascader :options="options2" :props="props" placeholder="基本信息" clearable @change="handleChange2">
            </el-cascader>
          </div>
        </el-collapse-item>
      </el-collapse>



    </div>
    <div class="key-infos">
      <!-- <div class="key-info" v-show="selects.data_category == 'people'">
        总人口：{{statistics.people.total_population}}，性别比：{{statistics.people.rate}}，平均年龄：{{statistics.people.age_avg}}
      </div>
      <div class="key-info" v-show="selects.data_category == 'hideaway'">
        {{statistics.hideaways.count}}个避难所，总容量{{statistics.hideaways.persons}}人
      </div>
      <div class="key-info" v-show="selects.data_category == 'hospital'">
        {{statistics.hospital.general_count}}家综合医院，{{statistics.hospital.community_count}}家社区医院，总床位数{{statistics.hospital.beds}}个
      </div>
      <div class="key-info" v-show="selects.data_category == 'warehouse'">
        {{statistics.warehouse.count}}个应急储备仓库，总面积{{statistics.warehouse.area}}平米
      </div> -->
    </div>
  </div>
</template>
<script>
import mapboxgl from "mapbox-gl";
import MenuMap from "src/components/MenumapFire2";
import SearchArea from "src/components/SearchFireHall";
import geobuf from "geobuf";
import Pbf from "pbf";
import MiniMap from "src/mapbox/mapboxgl-minimap.js";
import { TabPane, Tabs, Switch } from "src/components";
import { Radio } from 'src/components/index';
import { Input, Button, Select, Option, Cascader, Collapse, CollapseItem, Table, TableColumn } from "element-ui";
import { consts } from "src/util/consts.js";
import {
  getBasicFilter as getOptions,
  getBasicChart1 as getChart1,
  getBasicChart2 as getChart2,
  getBasicList as getList1,
  //getBasicFullList as getList1,
  getFireHallList as getList2,
  getFireBasicGeo as getGeo1,
  getFireBasicStreetGeo as getStreetGeo,
  getFireBasicHallGeo as getGeoJson, //消防站点
  getFireHallGeo as getStrokeGeoJson, //消防站轮廓
  getBasicExport as getExport,
  getBasicInfo as getInfo,
  getFireShowMap as getMapInfo,
} from "src/api/fire.js";
import { getHideawaylGeo } from 'src/api/cityinfo.js';
import { buildBarOption, buildBarStackYValueOption, buildPieOption, buildBarSideOption } from "src/util/chart.js";
import { storeMapOptions, getMapOptions, parseJsonToString } from "src/util/common.js";
import { Pagination as NPagination } from "src/components";

import * as THREE from "three";

export default {
  components: {
    MenuMap,
    SearchArea,
    TabPane,
    Tabs,
    [Cascader.name]: Cascader,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    NPagination,
    [Switch.name]: Switch,
    Radio
  },
  data() {
    return {
      mid: 0, //模块id
      types: [], //左上角切换radio
      curType: 0,
      activeNames: ['2', '3'],
      activeName: "first",
      selects: {
        town_id: 1,
        street_id: 0,
        ids: {},
        filter_street_id: 0,//地图筛选的street_id
      },
      props: { multiple: true },
      options1: [],
      options2: [],
      keyword: "",
      total: 0,
      data: [],
      data2: [],
      page: 1,
      map: {},
      city_id: localStorage.getItem("city_id") || 1,
      show_list: false,
      show_list_height: 0,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 20
      },
      last_updated: consts.last_updated,
      mapdefaultSetting: {},
      statistics: {
      },
      downloadUrl: '',
      options: [
        { name: '用电量', selected: [], data: [] },
      ],
      mapinfo: null,
      listheader: [],
      listheader2: [],
      tabCount: 1,
      tab2Mid: 1,
      activeTab: '表1',
    };
  },

  mounted() {
    let _this = this;
    //localStorage.setItem('curStroke', 'street');
    //this.fillStreets(this.city_id);
    this.mid = this.$route.params.mid || 1;
    this.curType = this.mid;
    this.setTipContent()
    this.fillBasic() //填充筛选列表
    this.showChart(this.city_id);

    this.initMap(this.city_id);
    this.map.on('style.load', function () {
      getMapInfo(_this.buildParams()).then(res => {
        _this.mapinfo = res.data;
        _this.fillList1() //初始化list，不随筛选改变
        _this.reloadData()
      });

    })
    this.map.on("click", (e) => {
      var features = this.map.queryRenderedFeatures(e.point, {
        layers: ["block-layer", "points"],
      });
      if (!features.length) return;
      let pointscount = 0;
      let blockcount = 0;
      features.forEach((item, idx) => {
        if (item.layer.id == 'points') {
          pointscount++
        } else if (item.layer.id == 'block-layer') {
          blockcount++
        }
      })
      if (pointscount >= 1 && blockcount >= 1) {
        //点和区都点了，触发点
        this.mapClickEventNew(features[0])
      } else if (pointscount >= 1) {
        this.mapClickEventNew(features[0])
      } else if (blockcount >= 1) {
        this.mapClickEvent2New(features[0])
      }

    });
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    // street (){
    //   return this.selects.street
    // }
  },
  watch: {

    "selects.street"(val) {
      //街道切换选社区
      this.fillCommunities(this.city_id, val);
    },
    activeTab(val) {
      if (val == "表1") {
        this.downloadUrl = consts.requestUrl + consts.exportFire2Alarm + parseJsonToString(this.buildParams(this.mid))
      } else {
        this.downloadUrl = consts.requestUrl + consts.exportFire2Hall + parseJsonToString(this.buildParams(this.tab2Mid))
      }
    },
  },
  methods: {
    // 初始化
    initMap(city_id) {
      this.mapdefaultSetting = {
        center: consts.mapCenter[this.city_id - 1],
        zoom: consts.mapDefaultZoom,
        pitch: 0,
        city_id: this.city_id
      }
      mapboxgl.accessToken = consts.mapboxAccessToken;
      var origin = [-95.97299, 36.15031, 0];
      let map_options = getMapOptions();
      //console.log(map_options);
      let zoom = map_options ? map_options.zoom : 12;
      let center = map_options ? [map_options.lng, map_options.lat] : consts.mapCenter[city_id - 1];
      //console.log(center);
      var map = new mapboxgl.Map({
        container: "map",
        style: consts.mapBoxStyle,
        //center: [-96, 37.8],
        center: center,
        zoom: zoom,
        pitch: 0,
        bearing: 0,
        attributionControl: false
      });
      this.map = map;
      //定位图标
      map.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl
        })
      );
      this.map.addControl(new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      }));
      //控制图层
      var nav = new mapboxgl.NavigationControl();
      this.map.addControl(nav, 'top-right');
      //全屏按钮
      this.map.addControl(new mapboxgl.FullscreenControl({
        container: document.querySelector('body')
      })
      );
      //小地图
      //https://github.com/brendanmatkin/mapboxgl-minimap
      map.on("style.load", function () {
        // Possible position values are 'bottom-left', 'bottom-right', 'top-left', 'top-right'
        map.addControl(new MiniMap({
          id: "mapboxgl-minimap",
          width: "200px",
          height: "150px",
          //style: "mapbox://styles/mapbox/streets-v10",
          style: consts.mapBoxStyle,
          fillColor: "#f58220",
          zoomAdjust: null
        }),
          'bottom-right');
      });

      //记录地图设置
      map.on('move', function () {
        storeMapOptions({
          lng: map.getCenter().lng,
          lat: map.getCenter().lat,
          zoom: map.getZoom()
        });
      });

    },
    showList() {
      this.show_list = !this.show_list;
    },
    donwloadList() { },
    showChart(city_id) {
      var self = this;
      var container = document.querySelector('.el-collapse');
      var box = document.getElementById("box-chart");
      var myChart = this.$echarts.init(box);
      box.style.width = container.offsetWidth + "px";
      getChart1({
        "city_id": this.city_id,
        "town_id": 1,
        "module_id": this.mid
      }).then(res => {
        var chart_data = res.data.data;
        var chart_name = res.data.title;
        var colors = res.data.colors

        chart_data.map((item, idx) => {
          item.itemStyle = { color: colors[idx] }
        })
        var option = buildPieOption(chart_name, chart_data);
        option.tooltip = {
          trigger: 'item',
          formatter: "{a} <br/>{b} : {c} ({d}%)",
          position: function (p) { //其中p为当前鼠标的位置
            return [p[0] - 40, p[1] + 10];
          },
          extraCssText: 'width:160px;white-space:inherit;'
        };
        myChart.setOption(option);

      });

      var box2 = document.getElementById("box-chart2");
      var myChart2 = this.$echarts.init(box2);
      box2.style.width = container.offsetWidth + "px";

      getChart2({
        "city_id": this.city_id,
        "town_id": 1,
        "module_id": this.mid
      }).then(res => {
        var chart_data = res.data.data;
        var chart_name = res.data.title;

        // chart_data.forEach(item=>{
        //   item.color = '#55ac68'
        // })
        // chart_data.map((item,idx) => {
        //   //['#ffff35','#c84d21','#ab0030','#780934','#481638'],
        //   if(item.value > 90){
        //       item.color = '#bc171e'
        //   } else if(item.value > 60){
        //       item.color = '#ec6347'
        //   } else if(item.value > 30){
        //       item.color = '#eaa489'
        //   } else {
        //       item.color = '#eed8cf'
        //   }
        //   delete item.level;
        // })
        var option = buildBarOption(chart_name, chart_data, "name");
        option.tooltip.position = function (p) { //其中p为当前鼠标的位置
          return [p[0] - 40, p[1] + 10];
        }
        myChart2.setOption(option);

      });


      myChart.resize();
      myChart2.resize();

      window.onresize = function () {
        document.querySelectorAll('.chart-area').forEach((item, idx) => {
          item.style.width = document.querySelector('.el-collapse').offsetWidth + 'px';
        })
        myChart.resize();
        myChart2.resize();
      }
    },
    handleChange1(value) {
      //右侧下拉change1
      this.selects.town_id = 1
      this.selects.street_id = value[2] || 0
      this.reloadData();

    },
    handleChange2(value) {
      //右侧下拉change2
      let _this = this;
      this.selects.ids = {}
      value.forEach((item, idx) => {
        if (typeof _this.selects.ids[item[2]] == 'undefined') {
          _this.selects.ids[item[2]] = '';
        }
        _this.selects.ids[item[2]] += item[3] + '-'
      })
      this.reloadData();
    },
    fillBasic() {
      let _this = this;
      getOptions({
        "city_id": this.city_id,
        "town_id": 1
      }).then(res => {
        let data = res.data;
        //行政区划
        let towns = data.towns;
        let basic = data.basic;
        _this.options1 = [{
          value: 1,
          label: "杭州市",
          children: [
            {
              value: towns.value,
              label: towns.label,
              children: [],
            }
          ]
        }]
        _this.options1[0]['children'][0]['children'] = towns.children
        //基本数据
        _this.options2 = basic

      });
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      let returnobj = null;
      let returnobj2 = null;
      let _this = this;
      if (columnIndex == 0) {
        this.col1IdxArr.forEach((item, idx) => {
          if (rowIndex == item) {
            returnobj = {
              rowspan: _this.col1Arr[idx],
              colspan: 1
            };

          } else {
            returnobj = {
              rowspan: 0,
              colspan: 0
            };
          }
        })
        return returnobj;

      }
      if (columnIndex == 1) {
        let hasColspan = false;
        this.col2IdxArr.forEach((item, idx) => {
          if (!hasColspan) {
            returnobj2 = {
              rowspan: 0,
              colspan: 0
            };
          }
          if (rowIndex == item) {
            hasColspan = true;
            returnobj2 = {
              rowspan: _this.col2Arr[idx],
              colspan: 1
            };
          }
        })
        return returnobj2;
      }
    },
    objectSpanMethod2({ row, column, rowIndex, columnIndex }) {
      let returnobj = null;
      let returnobj2 = null;
      let _this = this;
      if (columnIndex == 0) {
        this.tab2col1IdxArr.forEach((item, idx) => {
          if (rowIndex == item) {
            returnobj = {
              rowspan: _this.tab2col1Arr[idx],
              colspan: 1
            };

          } else {
            returnobj = {
              rowspan: 0,
              colspan: 0
            };
          }
        })
        return returnobj;

      }
      if (columnIndex == 1) {
        let hasColspan = false;
        this.tab2col2IdxArr.forEach((item, idx) => {
          if (!hasColspan) {
            returnobj2 = {
              rowspan: 0,
              colspan: 0
            };
          }
          if (rowIndex == item) {
            hasColspan = true;
            returnobj2 = {
              rowspan: _this.tab2col2Arr[idx],
              colspan: 1
            };
          }
        })
        return returnobj2;
      }
    },
    fillList1(city_id, page) {
      let _this = this;
      let params = this.buildParams();
      //params.page = page;
      this.col1Arr = []; //第一列数量和
      this.col2Arr = []; //第二列数量和
      this.col1IdxArr = []; //第一列序号
      this.col2IdxArr = []; //第二列序号
      this.col1Last = ''; //第一行最后一项
      this.col2Last = ''; //第二行最后一项

      this.tab2col1Arr = []; //第一列数量和
      this.tab2col2Arr = []; //第二列数量和
      this.tab2col1IdxArr = []; //第一列序号
      this.tab2col2IdxArr = []; //第二列序号
      this.tab2col1Last = ''; //第一行最后一项
      this.tab2col2Last = ''; //第二行最后一项
      getList1(params).then(res => {
        //this.data = res.data;
        let header = res.data.header;
        let body = res.data.body;
        let data = [];
        if (body) {
          body.forEach((item, idx) => {
            let newitem = {}
            item.forEach((subitem, subidx) => {
              newitem[subidx] = subitem
            })
            data.push(newitem)
          })
        }

        this.listheader = header;
        this.data = data;



        this.data.forEach((item, idx) => {
          if (item['0'] == _this.col1Last) {
            _this.col1Arr[_this.col1Arr.length - 1]++;
          } else {
            _this.col1Arr.push(1);
            _this.col1IdxArr.push(idx);
            _this.col1Last = item['0']
          }

          if (item['1'] == _this.col2Last) {
            _this.col2Arr[_this.col2Arr.length - 1]++;
          } else {
            _this.col2Arr.push(1);
            _this.col2IdxArr.push(idx);
            _this.col2Last = item['1']
          }

        })
      });
      getList2(this.buildParams(this.tab2Mid)).then(res => {
        //this.data = res.data;
        let header = res.data.header;
        let body = res.data.body;
        let data = [];
        if (body) {
          body.forEach((item, idx) => {
            let newitem = {}
            item.forEach((subitem, subidx) => {
              newitem[subidx] = subitem
            })
            data.push(newitem)
          })
        }

        this.listheader2 = header;
        this.data2 = data;



        this.data2.forEach((item, idx) => {
          if (item['0'] == _this.tab2col1Last) {
            _this.tab2col1Arr[_this.tab2col1Arr.length - 1]++;
          } else {
            _this.tab2col1Arr.push(1);
            _this.tab2col1IdxArr.push(idx);
            _this.tab2col1Last = item['0']
          }

          if (item['1'] == _this.tab2col2Last) {
            _this.tab2col2Arr[_this.tab2col2Arr.length - 1]++;
          } else {
            _this.tab2col2Arr.push(1);
            _this.tab2col2IdxArr.push(idx);
            _this.tab2col2Last = item['1']
          }

        })
      });

      this.downloadUrl = consts.requestUrl + consts.exportFire2Alarm + parseJsonToString(params)

    },
    fillList1xxx(city_id, page) {
      let _this = this;
      let params = this.buildParams();
      //params.page = page;
      this.col1Arr = []; //第一列数量和
      this.col2Arr = []; //第二列数量和
      this.col1IdxArr = []; //第一列序号
      this.col2IdxArr = []; //第二列序号
      this.col1Last = ''; //第一行最后一项
      this.col2Last = ''; //第二行最后一项
      getList1({
        "city_id": 1,
        "town_id": 1,
        "module_id": 2
      }).then(res => {
        //this.data = res.data;
        let header = res.data.header;
        let body = res.data.body;
        let data = [];
        body.forEach((item, idx) => {
          let newitem = {}
          item.forEach((subitem, subidx) => {
            newitem[subidx] = subitem
          })
          data.push(newitem)
        })
        this.listheader = header;
        this.data = data;
        console.log(data)



        this.data.forEach((item, idx) => {
          if (item['0'] == _this.col1Last) {
            _this.col1Arr[_this.col1Arr.length - 1]++;
          } else {
            _this.col1Arr.push(1);
            _this.col1IdxArr.push(idx);
            _this.col1Last = item['0']
          }

          if (item['1'] == _this.col2Last) {
            _this.col2Arr[_this.col2Arr.length - 1]++;
          } else {
            _this.col2Arr.push(1);
            _this.col2IdxArr.push(idx);
            _this.col2Last = item['1']
          }

        })
      });
      // getStatistics1(params).then(res => {
      //   this.statistics = res.data;
      // });
      this.downloadUrl = consts.requestUrl + consts.exportFirePower + parseJsonToString(params)

    },
    fill2Map(city_id) {
      let params = this.buildParams();
      getGeo1(params).then(res => {
        let data = res;

        let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        this.addBlockmapLayer(this.map, 'block', geoJson);
        this.fillPointLayer()
        this.fillBuildingsMap();
      });
      //this.fillPointLayer()
    },
    fillCommunities(city_id, street_id) {
      if (this.selects.data_category == 'people') {
        getCommunities(city_id, street_id).then(res => {
          let data = res.data;
          data.unshift({ id: 0, name: "社区" });
          this.selects.communities = data;
        });
      } else if (this.selects.data_category == 'hideaway') {
        getCommunitiesHideaway(city_id, street_id).then(res => {
          let data = res.data;
          data.unshift({ id: 0, name: "社区" });
          this.selects.communities = data;
        });
      } else if (this.selects.data_category == 'hospital') {
        getCommunitiesHospital(city_id, street_id).then(res => {
          let data = res.data;
          data.unshift({ id: 0, name: "社区" });
          this.selects.communities = data;
        });
      } else if (this.selects.data_category == 'warehouse') {
        getCommunitiesWarehouse(city_id, street_id).then(res => {
          let data = res.data;
          data.unshift({ id: 0, name: "社区" });
          this.selects.communities = data;
        });
      }

    },
    fillBlockmapLayer(city_id) {
      let params = this.buildParams();
      getPeopleGeo(params).then(data => {
        let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        this.peoplegeoJson = geoJson;
        this.addBlockmapLayer(this.map, 'people', geoJson);
      });
    },
    addBlockmapLayer(map, type, geojson) {
      let _this = this;
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      this.source_name = source_name
      let layer_id = this.getMapLayerId(type);
      if (typeof geojson.features == 'undefined') return;
      //map.off('click', layer_id, this.mapClickEvent2);
      let layer_id_stroke = layer_id + '_stroke';
      map.addSource(source_name, {
        type: "geojson",
        data: geojson,
        generateId: true
      });
      let colorExpress = '';
      let strokeColor = '#000000'
      let fillOpacity = 1;
      colorExpress = [
        "step",
        ["get", "rate"],
      ]
      this.mapinfo.values.forEach((item, idx) => {
        colorExpress.push(_this.mapinfo.colors[idx], item)
      })
      colorExpress.push("rgba(255,255,255,0)")
      map.addLayer({
        id: layer_id,
        type: "fill-extrusion",
        source: source_name,
        layout: {},
        paint: {
          // "fill-color": colorExpress,
          // "fill-opacity": fillOpacity,
          "fill-extrusion-color": colorExpress,

          // Get fill-extrusion-height from the source 'height' property.
          "fill-extrusion-height": [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            400,
            0.5
          ],
          // Get fill-extrusion-base from the source 'base_height' property.
          "fill-extrusion-base": 1,

          // Make extrusions slightly opaque for see through indoor walls.
          "fill-extrusion-opacity": fillOpacity
        }
      });
      // if(type == 'region') {
      //   colorExpress = '#f96332'
      //   fillOpacity = 0.8
      //   strokeColor = '#ffffff'
      // }
      // if(type != 'region') {
      //   map.addLayer({
      //     id: layer_id,
      //     type: "fill",
      //     source: source_name,
      //     layout: {},
      //     paint: {
      //       "fill-color": colorExpress,
      //       "fill-opacity": fillOpacity
      //     }
      //   });
      // }
      //描边
      map.addLayer({
        id: layer_id_stroke,
        type: "line",
        source: source_name,
        layout: {},
        paint: {
          "line-color": strokeColor,
          "line-width": 1
        }
      });

      this.hoveredStateId = null;

      //map.on('click', layer_id, this.mapClickEvent2);


    },
    fillBuildingsMap() {
      let _this = this;
      let params = this.buildParams();
      //params.color_category = 'struct';
      getStrokeGeoJson(params).then(res => {
        //加载轮廓
        let data = res;
        _this.strokegeoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        getGeoJson(params).then(res => {
          let data = res;
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addPointLayerToMap(this.map, geoJson); //消防站的点
        });
      });

    },
    reloadData(type) {
      //this.fillList1()
      this.fill2Map(this.city_id);
    },
    setTipContent() {
      let _this = this;
      let html = ''
      getInfo({
        "module_id": this.mid,
      }).then(res => {
        document.getElementById('left-tip-content').innerHTML = res.data.info;
        this.types = res.data.types
      });
    },
    buildParams(mid) {
      let params = { city_id: this.city_id };
      params.town_id = this.selects.town_id;
      if (this.selects.filter_street_id === 0) {
        //地图没有筛选街道,用select的街道
        params.street_id = this.selects.street_id;
      } else {
        //地图点击了街道
        params.street_id = this.selects.filter_street_id;
      }
      //params.street_id = this.selects.street_id;
      //params.module_id = this.mid;
      if (mid) {
        params.module_id = mid;
      } else {
        params.module_id = this.mid;
      }

      Object.assign(params, {
        "c2": "0",
        "c29": "0",
        "c4": "0",
        "c6": "0",
        "c8": "0",
        "c11": "0",
        "c14": "0",
        "c16": "0",
        "c19": "0",
        "c23": "0",
        "c24": "0",
        "c27": "0",
        "c28": "0",
        "c35": "0",
        "c56": "0",
        "c79": "0",
        "c103": "0",
        "c122": "0",
        "c138": "0",
        "c141": "0",
        "c144": "0",
        "c145": "0",
        "c146": "0",
        "c147": "0",
        "c149": "0",
        "c152": "0",
        "c154": "0",
        "c156": "0",
        "c159": "0",
        "c162": "0",
        "c163": "0",
        "c164": "0",
        "c165": "0",
        "c166": "0",
        "c169": "0",
        "c172": "0",
        "c175": "0",
        "c177": "0",
        "c180": "0",
        "c182": "0",
        "c183": "0",
        "c184": "0",
        "c185": "0",
        "c186": "0",
        "c187": "0",
        "c188": "0",
        "c189": "0",
        "c192": "0",
        "c194": "0",
        "c196": "0",
        "c198": "0",
        "c199": "0",
        "c200": "0",
        "c203": "0",
        "c206": "0",
        "c207": "0",
        "c208": "0",
        "c209": "0",
        "c210": "0",
        "c212": "0",
        "c214": "0",
        "c215": "0",
        "c216": "0",
        "c218": "0",
        "c221": "0",
      }, this.selects.ids)
      //Object.assign(params, this.selects.ids)

      return params;
    },
    changePage: function (value) {
      if (value != this.page) {
        this.loadData(this.selects.data_category, value);
      }
      this.page = value;
    },
    fillPointLayer() {
      let params = this.buildParams();
      getStreetGeo(params).then(data => {
        let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        //this.addBlockmapLayer(this.map,'region')
        this.addStreetLayerToMap(this.map, geoJson);
      });

    },
    makePointImage(map, type) {
      let size = 150;
      switch (type) {
        case 1:
          size = 160;
          break;
        case 2:
          size = 120;
          break;
        case 3:
          size = 80;
          break;
      }
      //point type
      let pulsingDot = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // get rendering context for the map canvas when layer is added to the map
        onAdd: function () {
          var canvas = document.createElement("canvas");
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext("2d");
        },

        // called once before every frame where the icon will be used
        render: function () {
          var duration = 1000;
          var t = (performance.now() % duration) / duration;

          var radius = (size / 2) * 0.3;
          var outerRadius = (size / 2) * 0.7 * t + radius;
          var context = this.context;

          // draw outer circle
          context.clearRect(0, 0, this.width, this.height);
          context.beginPath();
          context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2
          );
          // switch(type){
          //   case 1:
          //     context.fillStyle = "rgba(255, 192, 0," + (1 - t) + ")";
          //   break;
          //   case 2:
          //     context.fillStyle = "rgba(237, 125, 49," + (1 - t) + ")";
          //   break;
          //   case 3:
          //     context.fillStyle = "rgba(255, 0, 0," + (1 - t) + ")";
          //   break;
          // }
          context.fillStyle = "rgba(3, 116, 253," + (1 - t) + ")";

          context.fill();

          // draw inner circle
          context.beginPath();
          context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
          // switch(type){
          //   case 1:
          //     context.fillStyle = "rgba(255, 192, 0, 1)";
          //   break;
          //   case 2:
          //     context.fillStyle = "rgba(237, 125, 49, 1)";
          //   break;
          //   case 3:
          //     context.fillStyle = "rgba(255, 100, 100, 1)";
          //   break;
          // }
          context.fillStyle = "rgba(3, 116, 253, 1)";

          context.strokeStyle = "white";
          context.lineWidth = 2 + 4 * (1 - t);
          context.fill();
          //context.stroke();
          // update this image's data with data from the canvas
          this.data = context.getImageData(0, 0, this.width, this.height).data;

          // continuously repaint the map, resulting in the smooth animation of the dot
          map.triggerRepaint();

          // return `true` to let the map know that the image was updated
          return true;
        }
      }
      map.addImage("dot-" + type, pulsingDot, { pixelRatio: 2 });
    },
    addPointLayerToMap(map, geojson) {
      //let type = this.selects.data_category

      var layer = map.getLayer('points');
      if (layer) {
        map.removeLayer('points');
        map.removeSource('points');
      }

      if (typeof geojson.features == "undefined") return;
      //map.off('click', 'points', this.mapClickEvent);
      let imgIcon = [];
      if (!map.hasImage("dot-1")) {
        this.makePointImage(map, 1) //1000-2000
        this.makePointImage(map, 2) //2000-3000
        this.makePointImage(map, 3) //>3000

      }
      geojson.features.forEach((item, idx) => {
        if (item.properties.type == "区级") {
          item.properties.newtype = 1
        } else if (item.properties.type == "街道级") {
          item.properties.newtype = 2
        } else if (item.properties.type == "社区级") {
          item.properties.newtype = 3
        }
      })
      imgIcon = ["concat", "dot-", ["get", "newtype"]];



      map.addSource("points", {
        type: "geojson",
        data: geojson,
      }
      );
      map.addLayer({
        id: "points",
        type: "symbol",
        source: "points",
        paint: {
          "text-color": "rgba(3, 116, 253, 1)"
        },
        layout: {
          "icon-image": imgIcon,
          "text-field": ["get", "name"],
          "text-size": 12,
          "text-offset": [0, -3],
          "text-anchor": "top",
          "icon-allow-overlap": true,
          "text-allow-overlap": true
        }
      });

      //map.on('click', 'points', this.mapClickEvent);

    },
    mapClickEvent(e, stationId) {
      //点击消防站
      let _this = this;
      if (!!e && e.features.length == 0) {
        return;
      }
      if (!stationId) {
        stationId = e.features[0].properties.id;
      }
      var layer = this.map.getLayer('staionstroke');
      if (layer) {
        this.map.removeLayer('staionstroke');
        this.map.removeSource('staionstroke');
      }
      if (this.stationId == stationId) {
        this.stationId = -1;
        return;
      }
      let newfeatures = this.strokegeoJson.features.filter((item, idx) => {
        return item.properties.id == stationId
      });
      this.stationId = stationId;
      let curStrokeGeoJson = {};
      Object.assign(curStrokeGeoJson, this.strokegeoJson);
      curStrokeGeoJson.features = newfeatures;


      this.map.addSource("staionstroke", {
        type: "geojson",
        data: curStrokeGeoJson,
      });
      this.map.addLayer({
        id: 'staionstroke',
        type: "line",
        source: 'staionstroke',
        layout: {
          "line-join": "round",
          "line-cap": "round"
        },
        paint: {
          "line-color": "#ffffff",
          "line-width": 3,
          "line-dasharray": [2, 4]
        }
      });



    },
    mapClickEvent2(e) {
      let _this = this;
      if (e.features.length > 0) {
        if (_this.hoveredStateId !== null) {
          _this.map.setFeatureState(
            { source: _this.source_name, id: _this.hoveredStateId },
            { hover: false }
          );
        }
        if (e.features[0].id == _this.hoveredStateId) {
          _this.hoveredStateId = null
          _this.selects.filter_street_id = 0
          _this.fillList1()
          return
        };

        _this.hoveredStateId = e.features[0].id;
        _this.map.setFeatureState(
          { source: _this.source_name, id: _this.hoveredStateId },
          { hover: true }
        );
        //筛选列表
        _this.selects.filter_street_id = e.features[0].properties.street_id
        _this.fillList1()

      }
    },
    mapClickEventNew(feature, stationId) {
      //点击消防站
      let _this = this;

      if (!stationId) {
        stationId = feature.properties.id;
      }
      var layer = this.map.getLayer('staionstroke');
      if (layer) {
        this.map.removeLayer('staionstroke');
        this.map.removeSource('staionstroke');
      }
      if (this.stationId == stationId) {
        this.stationId = -1;
        return;
      }
      let newfeatures = this.strokegeoJson.features.filter((item, idx) => {
        return item.properties.id == stationId
      });
      this.stationId = stationId;
      let curStrokeGeoJson = {};
      Object.assign(curStrokeGeoJson, this.strokegeoJson);
      curStrokeGeoJson.features = newfeatures;


      this.map.addSource("staionstroke", {
        type: "geojson",
        data: curStrokeGeoJson,
      });
      this.map.addLayer({
        id: 'staionstroke',
        type: "line",
        source: 'staionstroke',
        layout: {
          "line-join": "round",
          "line-cap": "round"
        },
        paint: {
          "line-color": "#ffffff",
          "line-width": 3,
          "line-dasharray": [2, 4]
        }
      });



    },
    mapClickEvent2New(feature) {
      let _this = this;
      if (_this.hoveredStateId !== null) {
        _this.map.setFeatureState(
          { source: _this.source_name, id: _this.hoveredStateId },
          { hover: false }
        );
      }
      if (feature.id == _this.hoveredStateId) {
        _this.hoveredStateId = null
        _this.selects.filter_street_id = 0
        _this.fillList1()
        return
      };

      _this.hoveredStateId = feature.id;
      _this.map.setFeatureState(
        { source: _this.source_name, id: _this.hoveredStateId },
        { hover: true }
      );
      //筛选列表
      _this.selects.filter_street_id = feature.properties.street_id
      _this.fillList1()

    },
    addStreetLayerToMap(map, geojson) {
      //let type = this.selects.data_category

      var layer = map.getLayer('streetpoints');
      if (layer) {
        map.removeLayer('streetpoints');
        map.removeSource('streetpoints');
      }

      if (typeof geojson.features == "undefined") return;


      map.addSource("streetpoints", {
        type: "geojson",
        data: geojson
      }
      );
      map.addLayer({
        id: "streetpoints",
        type: "symbol",
        source: "streetpoints",
        paint: {
          "text-color": "#ffffff"
        },
        layout: {
          //"icon-image": imgIcon,
          "text-field": ["get", "name"],
          "text-size": 12,
          "text-offset": [0, -3],
          "text-anchor": "top",
          "icon-allow-overlap": true,
          "text-allow-overlap": true,
        }
      });
    },
    getMapLayerId(type) {
      let layer_id = type + '-layer';
      return layer_id;
    },
    getMapSourceName(type) {
      let source_name = type + "-data";
      return source_name;
    },
    removeMapLayer(map, type) {
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      if (type == 'streetpoints') {
        source_name = 'streetpoints';
        layer_id = 'streetpoints'
      }
      var layer = map.getLayer(layer_id);
      let layer_point_id = layer_id + "point";
      //描边
      var layer_stroke = map.getLayer(layer_id + '_stroke');
      if (layer_stroke) {
        map.removeLayer(layer_id + '_stroke');
      }
      if (layer) {
        map.removeLayer(layer_id);
      }
      if (map.getSource(source_name)) {
        map.removeSource(source_name);
      }
    },
    // changeRegin(type){
    //   //切换街道社区
    //   if(type == 'street'){
    //     //街道
    //     this.$router.push({
    //       path: '/fire/building/history/alarm'
    //     })
    //   } else if(type=='community') {
    //     //社区
    //     this.$router.push({
    //       path: '/fire/building/tp?mid='+this.mid
    //     })
    //   }
    // },
    changeType(val) {
      //切换左上角列表
      if (val == 1) {
        if (localStorage.getItem('curStroke') == 'street') {
          //特殊页面
          this.$router.push({ path: '/fire/building/alarm/1' })
        }
        if (localStorage.getItem('curStroke') == 'community') {
          //量产
          this.$router.push({ path: '/fire/building/tp' + val })
        }
      } else {
        this.$router.push({ path: '/fire/building/tp' + val })
      }
    }
  },

};
</script>
<style lang="scss" >
.el-table__row td {
  border-right: 1px solid #eee;
}

.el-table__body-wrapper {
  background: #394761;
}

.options1 .el-cascader .el-input__inner {
  height: 50px;
}

/*.el-table__body-wrapper::-webkit-scrollbar{z-index:11;width:6px}
  .el-table__body-wrapper::-webkit-scrollbar:horizontal{height:6px}
  .el-table__body-wrapper::-webkit-scrollbar-thumb{border-radius:5px;width:6px;background:#b4bccc}
  .el-table__body-wrapper::-webkit-scrollbar-corner,.el-table__body-wrapper::-webkit-scrollbar-track{background:#fff}
  .el-table__body-wrapper::-webkit-scrollbar-track-piece{background:#fff;width:6px}*/
</style>
<style lang="scss" scoped>
.card-map {
  min-height: 350px;
  height: calc(100vh - 48px);
  margin-bottom: 0;

  position: relative;

  .map-wrapper {
    width: 100%;
    height: calc(100vh - 100px);
    position: relative;

    &>* {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  #map {
    width: 100%;
    height: calc(100vh - 100px);
  }

  .radio-group {
    position: absolute;
    left: 10px;
    top: 10px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding: 10px;
  }

  .radio-group div {
    padding: 0 10px;
  }

  .radio-group div input {
    vertical-align: -1px;
    padding-right: 2px;
  }

  .map_data_msg {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    // color: #02f4fe;
    color: #fff;
    padding: 10px;
    width: auto;

    .map_data_legend {
      margin-bottom: 5px;
    }

    .map_data_msg_wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .item {
        // width: 80px;
        line-height: 15px;
        padding: 5px 0;
      }

      .item span {
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 3px;
        vertical-align: middle;
        margin-left: 0;
      }

      .item-circle span {
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 100%;
        vertical-align: middle;
        margin-left: 0;
        position: relative;
        margin-right: 7px;
      }

      .item-circle span:after {
        content: '';
        position: absolute;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        border: 1px solid rgba(255, 255, 255, 0.5);
        left: -5px;
        top: -5px;
      }

      .map_data_msg_inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 10px;
      }
    }

    .map_data_msg_wrapper_vertical {
      flex-direction: column;

      .item {
        width: 130px;
      }
    }
  }
}

.main-panel {
  // height: calc(100vh - 130px);
  min-height: 350px;
}

.right-block {
  height: calc(100vh - 290px);
  min-height: 350px;
  overflow: hidden;
}

.tab-scroller {
  height: calc(100vh - 470px);
  min-height: 350px;
  overflow-y: auto;
}

.show_list {
  width: 100%;
  height: 50px;
  margin-top: 8px;
}

.show_list span {
  height: 32px;
  line-height: 36px;
  margin-left: 32px;
  font-size: 14px;
}

.show_list span i {
  height: 16px;
  padding: 2px 12px;
  background: #ffb236;
  margin: 0 4px;
  border-radius: 16px;
}

.show_list .up_show {
  display: block;
  float: left;
  font-size: 32px;
  cursor: pointer;
}

.show_list .download {
  display: block;
  float: right;
  font-size: 32px;
  cursor: pointer;
}

.footer {
  padding-top: 0;
}

.extended-forms .el-select {
  width: 100%;
  margin-bottom: 30px;
}

.extended-forms .progress {
  margin-bottom: 30px;
}

@import url("https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css");

.coordinates {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 5px 10px;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  display: none;
}

ul.nav__items>li:last-of-type a {
  margin-right: 0 !important;
}

.card-body .tab-content .tab-scroller {
  min-height: auto;
}

.chart-area {
  width: 100%;
}

.right-block .tab-pane {
  height: calc(100vh - 360px);
}

.tabCount2 .el-table {
  position: static;

}

/deep/.show_list_record .nav {
  width: 100%;
}
</style>
