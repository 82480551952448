<template>
  <div class="row" style="margin:0 -30px;">
    <div class="col-md-9" style="padding:0">
      <card class="card-map">
        <!-- <div class="mask">
          <img src="img/loading.png" class="loading">
        </div>-->
        <div id="map"></div>
        <menu-map :map="map" :mapDefault="mapdefaultSetting"></menu-map>
        <div class="map_data_msg">
          <div class="map_data_legend">PGA均值(g)</div>
          <div class="map_data_msg_wrapper">
            <!-- <div class="item" v-for="(item,index) in selects.pgas" :key="index" v-if="index>0">
              <span :style="'background: '+item.color+''"></span>
              {{item.name}}
            </div> -->
            <img src="/img/quake-pga-legend.png" alt="">
            <div class="map_data_msg_inner"><span class="start">高：0.93g</span><span class="end">低：0.73g</span></div>
          </div>
        </div>
        <div class="show_list">
          <a class="up_show" v-on:click="showList">
            <i
              class="now-ui-icons"
              :class="[ show_list ? 'arrows-1_minimal-down':'arrows-1_minimal-up']"
            ></i>
          </a>
          <a class="download ml-auto" :href="downloadUrl">
            <i class="now-ui-icons arrows-1_cloud-download-93"></i>
          </a>
          <span>
            数据列表
            <i>{{total}}</i>
          </span>
        </div>
        <div
          class="show_list_record"
          v-show="show_list"
          :style="{'margin-top':-(140+data.length*38)+'px'}"
          ref="show_list_record"
        >
          <el-table :data="data">
            <el-table-column label="建筑ID" property="id"></el-table-column>
            <el-table-column label="街道" property="street_name"></el-table-column>
            <el-table-column label="社区" property="community_name"></el-table-column>
            <el-table-column label="场地PGA" property="pga"></el-table-column>
          </el-table>

          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class>
              <p class="card-category">显示： {{from + 1}} - {{to}} 总数： {{total}}</p>
            </div>
            <n-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              v-on:input="changePage"
            ></n-pagination>
          </div>
        </div>
      </card>
    </div>
   <div class="col-md-3" id="customStyle">
     <el-collapse v-model="activeNames">
       <!-- <el-collapse-item title="一致性 Consistency" name="1">
          <form>
            <div class="input-group no-border">
              <fg-input
                placeholder="输入关键字..."
                v-on:keyup.enter="reloadData"
                v-model="keyword"
                addon-right-icon="now-ui-icons ui-1_zoom-bold"
              ></fg-input>
              <div class="form-text" style="color:#999;margin-left:20px;">输入 建筑、街道、社区关键字</div>
            </div>
          </form>
        </el-collapse-item> -->
       <el-collapse-item title="信息图表" name="2">
          <div class="col-md-12">
              <div class="chart-area" id="box-chart" style="height:400px"></div>
            </div>
        </el-collapse-item>
        <el-collapse-item title="数据筛选" name="3">
          <div class="col-md-12">
                <el-select
                  class="select-default"
                  size="large"
                  placeholder="街道"
                  v-model="selects.street"
                  v-on:change="reloadData('street')"
                >
                  <el-option
                    v-for="option in selects.streets"
                    class="select-primary"
                    :value="option.id"
                    :label="option.name"
                    :key="option.id"
                  ></el-option>
                </el-select>
              </div>

              <div class="col-md-12">
                <el-select
                  class="select-default"
                  size="large"
                  placeholder="社区"
                  v-model="selects.community"
                  v-on:change="reloadData"
                >
                  <el-option
                    v-for="option in selects.communities"
                    class="select-primary"
                    :value="option.id"
                    :label="option.name"
                    :key="option.id"
                  ></el-option>
                </el-select>
              </div>

              <div class="col-md-12">
                <el-select
                  class="select-default"
                  size="large"
                  placeholder="结构类型"
                  v-model="selects.struct"
                  v-on:change="reloadData"
                >
                  <el-option
                    v-for="(option, index) in selects.structs"
                    class="select-primary"
                    :value="index"
                    :label="option"
                    :key="index"
                  ></el-option>
                </el-select>
              </div>

              <div class="col-md-12">
                <el-select
                  class="select-default"
                  size="large"
                  placeholder="功能类型"
                  v-model="selects.category"
                  v-on:change="reloadData"
                >
                  <el-option
                    v-for="(option, index) in selects.categories"
                    class="select-primary"
                    :value="index"
                    :label="option"
                    :key="index"
                  ></el-option>
                </el-select>
              </div>

              <div class="col-md-12">
                <el-select
                  class="select-default"
                  size="large"
                  placeholder="抗震设防烈度"
                  v-model="selects.quakeLevel"
                  v-on:change="reloadData"
                >
                  <el-option
                    v-for="option in selects.quakeLevels"
                    class="select-primary"
                    :value="option.id"
                    :label="option.name"
                    :key="option.id"
                  ></el-option>
                </el-select>
              </div>

              <div class="col-md-12">
                <el-select
                  class="select-default"
                  size="large"
                  placeholder="修建年代"
                  v-model="selects.buildYear"
                  v-on:change="reloadData"
                >
                  <el-option
                    v-for="(option,index) in selects.buildYears"
                    class="select-primary"
                    :value="index"
                    :label="option"
                    :key="index"
                  ></el-option>
                </el-select>
              </div>

              <div class="col-md-12">
                <el-select
                  class="select-default"
                  size="large"
                  placeholder="PGA均值"
                  v-model="selects.pga"
                  v-on:change="reloadData"
                >
                  <el-option
                    v-for="(option,index) in selects.pgas"
                    class="select-primary"
                    :value="option.id"
                    :label="option.name"
                    :key="index"
                  ></el-option>
                </el-select>
              </div>
        </el-collapse-item>
     </el-collapse>

     
    </div>
    <div class="key-infos">
      <div class="key-info">
        PGA最大{{statistics.max_pga}}，PGA最小{{statistics.min_pga}}，PGA平均{{statistics.avg_pga}}
      </div>
    </div>
  </div>
</template>
<script>
import mapboxgl from "mapbox-gl";
//import MapboxLanguage  from '@mapbox/mapbox-gl-language';
import MiniMap from "src/mapbox/mapboxgl-minimap.js";
import { TabPane, Tabs, Switch } from "src/components";
import { Radio } from "src/components/index";
import MenuMap from "src/components/Menumap";
import { Input, Button, Select, Option, Collapse, CollapseItem, Table, TableColumn } from "element-ui";
import geobuf from "geobuf";
import Pbf from "pbf";
import { consts } from "src/util/consts.js";
import {
  getCategories,
  getStreets,
  getCommunities,
  getQuakeList,
  getQuakeGeojson,
  getPgaStatistics
} from "src/api/building.js";
import { byPGA } from "src/api/chart.js";
import { buildBarOption } from "src/util/chart.js";
import {
  getBuildYearRange,
  storeMapOptions,
  getMapOptions,
  parseJsonToString
} from "src/util/common.js";
import { Pagination as NPagination } from "src/components";
import {
  getPeopleStat,
  getGenderStat,
  getHideaways,
  getHospitals,
  getWarehouses
} from "src/api/cityinfo.js";

import * as THREE from "three";

export default {
  components: {
    MenuMap,
    TabPane,
    Tabs,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    NPagination,
    [Switch.name]: Switch,
    Radio
  },
  data() {
    return {
      activeNames: ['2','3'],
      activeName: "first",
      tabs: {
        horizontal: "Profile",
        vertical: "Options",
        verticalPills: "Home",
        pageCategories: "Messages"
      },
      selects: {
        street: 0,
        streets: [{ id: 0, name: "街道" }],
        community: 0,
        communities: [{ id: 0, name: "社区" }],
        struct: 0,
        structs: consts.buildingStructs,
        category: 0,
        categories: consts.buildingCategories,
        quakeLevel: 0,
        quakeLevels: [
          { id: 0, name: "抗震设防烈度" },
          { id: 6, name: 6 },
          { id: 7, name: 7 },
          { id: 8, name: 8 }
        ],
        buildYear: '修建年代',
        buildYears: consts.buildingBuildYears,
        data_category: 0,
        data_categories: [
          { id: 0, name: "数据集类型" },
          { id: 1, name: "数据1" },
          { id: 2, name: "数据2" }
        ],
        pga: 0,
        pgas: [
          { id: 0, name: "PGA均值" },
          { id: 1, name: " 0.75 以下", color: "#242F7F" },
          { id: 2, name: "0.75-0.8", color: "#59A1AD" },
          { id: 3, name: "0.8-0.85", color: "#FDF1D0" },
          { id: 4, name: "0.85-0.9", color: "#FBE7A3" },
          { id: 5, name: "0.9以上", color: "#F5C243" }
        ]
      },
      keyword: "",
      total: 0,
      data: [],
      page: 1,
      map: {},
      color_category: "struct",
      city_id: localStorage.getItem("city_id") || 1,
      show_list: false,
      show_list_height: 0,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 20
      },
      switchs: {
        people: true,
        gender: true,
        hideaway: true,
        hospital: true,
        warehouse: true
      },
      last_updated: consts.last_updated,
      data_type: "people",
      pgasColors: ["#34146a", "#782e7d", "#b14476", "#e47262", "#f9dca5"],
      mapdefaultSetting: {},
      statistics:{
        max_pga: '',
        min_pga: '',
        avg_pga: ''
      },
      downloadUrl: ''
    };
  },

  mounted() {
    this.fillStreets(this.city_id);
    //this.fillCommunities(this.city_id, 0);
    this.showChart(this.city_id);
    //this.fillBuildings(this.city_id, 1);
    this.initMap(this.city_id);
    this.setTipContent(); 
    //this.fillBuildingsMap(this.city_id, this.color_category);
    //this.addPointLayerToMap(this.map, "hospital");
    //this.addPointLayerToMap(this.map, "hideaway");
    //this.addPointLayerToMap(this.map, "warehouse");
    //this.loadData(this.data_type);
  },

  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    street() {
      return this.selects.street;
    }
  },
  watch: {
    street(val) {
      this.fillCommunities(this.city_id, this.selects.street);
    }
  },
  methods: {
    // 初始化
    initMap(city_id) {
      let self = this;
      this.mapdefaultSetting = {
        center: consts.mapQuakeMapCenter[city_id - 1],
        zoom: 12,
        pitch: 0,
        city_id: this.city_id
      }
      mapboxgl.accessToken = consts.mapboxAccessToken;
      // 英文标注转换为中文   
      // mapboxgl.setRTLTextPlugin(
      //   "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.1.0/mapbox-gl-rtl-text.js"
      // );
      let map_options = getMapOptions();
      //console.log(map_options);
      let zoom = map_options ? map_options.zoom : 12;
      // let center = map_options
      //   ? [map_options.lng, map_options.lat]
      //   : consts.mapCenter[city_id - 1];
      let center = map_options
        ? [map_options.lng, map_options.lat]
        : consts.mapQuakeMapCenter[city_id - 1];
      var map = new mapboxgl.Map({
        container: "map",
        //style: consts.mapBoxStyle,
        style: consts.mapBoxStyle,
        //center: [-87.61694, 41.86625],
        //center: center,
        center: consts.mapQuakeMapCenter[city_id - 1],
        zoom: 12,
        pitch: 0,
        bearing: 0,
        attributionControl: false
      });
      this.map = map;
      //定位图标
      
      map.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl
        })
      );
      this.map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: true
        })
      );
      
  
      this.map.addControl(
          new MiniMap({
            id: "mapboxgl-minimap",
            width: "200px",
            height: "150px",
            //style: "mapbox://styles/mapbox/streets-v10",
            style: consts.mapBoxStyle,
            fillColor: "#f58220",
            zoomAdjust: null
            // center: [map.getCenter().lng, map.getCenter().lat],
            // zoom: 8
          }),
          "bottom-right"
        );
      //控制图层
      var nav = new mapboxgl.NavigationControl();
      this.map.addControl(nav, "top-right");
      //全屏按钮
      this.map.addControl(
        new mapboxgl.FullscreenControl({
          container: document.querySelector("body")
        })
      );
      map.on("load", function() {
        //self.reloadData();
      });
      //小地图
      //https://github.com/brendanmatkin/mapboxgl-minimap
      map.on("style.load", function() {
        // Possible position values are 'bottom-left', 'bottom-right', 'top-left', 'top-right'
        map.addSource("radar", {
          type: "image",
          url: '/img/quake-pga-city-1.png',
          coordinates: [
          // [120.13354033677501, 30.25978124923908],
          // [120.20845521449158, 30.25978124923908],
          // [120.20845521449158, 30.19712608446761],
          // [120.13354033677501, 30.19712608446761],
          [120.126, 30.265],
          [120.212, 30.265],
          [120.212, 30.196],
          [120.126, 30.196],
          ]
        });
        map.addLayer({
          id: "radar-layer",
          "type": "raster",
          "source": "radar",
          "paint": {
            "raster-fade-duration": 0
          }
        });
        self.reloadData();
        // 设置语言
        // var language = new MapboxLanguage({ defaultLanguage: "zh" });
        // self.map.addControl(language);
      });

      //记录地图设置

      map.on("move", function() {
        storeMapOptions({
          lng: map.getCenter().lng,
          lat: map.getCenter().lat,
          zoom: map.getZoom()
        });
        console.log(map.getCenter(), map.getZoom())
      });
      map.on("load", function() {
        // map.addSource('chicago', {
        //     'type': 'raster',
        //     'url': 'mapbox://nikilian.adoud0t0'
        // });
        // map.addLayer({
        //     'id': 'chicago',
        //     'source': 'chicago',
        //     'type': 'raster'
        // });
        
      });
    },
    makePointImage(map){
      var size = 200;

      var pulsingDot = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // get rendering context for the map canvas when layer is added to the map
        onAdd: function() {
          var canvas = document.createElement("canvas");
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext("2d");
        },

        // called once before every frame where the icon will be used
        render: function() {
          var duration = 1000;
          var t = (performance.now() % duration) / duration;

          var radius = (size / 2) * 0.3;
          var outerRadius = (size / 2) * 0.7 * t + radius;
          var context = this.context;

          // draw outer circle
          context.clearRect(0, 0, this.width, this.height);
          context.beginPath();
          context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2
          );
          context.fillStyle = "rgba(255, 200, 200," + (1 - t) + ")";
          context.fill();

          // draw inner circle
          context.beginPath();
          context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
          context.fillStyle = "rgba(255, 100, 100, 1)";
          context.strokeStyle = "white";
          context.lineWidth = 2 + 4 * (1 - t);
          context.fill();
          context.stroke();
          // update this image's data with data from the canvas
          this.data = context.getImageData(0, 0, this.width, this.height).data;

          // continuously repaint the map, resulting in the smooth animation of the dot
          map.triggerRepaint();

          // return `true` to let the map know that the image was updated
          return true;
        }
      };
      map.addImage("pulsing-dot", pulsingDot, { pixelRatio: 2 });
    },
    addQuakeMark(map) {
      var layer = map.getLayer('points');
      if (layer) {
        map.removeLayer('points');
        map.removeSource('points');
      }
      if(!map.hasImage("pulsing-dot")) {
        this.makePointImage(this.map) 
      }

      map.addSource("points", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: consts.quakeLocation
              }
            }
          ]
        }
      });
      map.addLayer({
        id: "points",
        type: "symbol",
        source: "points",
        paint: {
          "text-color": "#ffffff"
        },
        layout: {
          "icon-image": "pulsing-dot",
          "text-field": "Mw=8.0 D=30km",
          "text-offset": [0, -3],
          "text-anchor": "top"
        }
      });
    },
    addBuildings2Map(map, geoJsonData) {
      let layer_id = "room-extrusion";
      let source_name = "geojson";
      var layer = map.getLayer(layer_id);
      if (layer) {
        map.removeLayer(layer_id);
        map.removeSource(source_name);
      }
      if(typeof geoJsonData.features == 'undefined'){
        return;
      }
      // map.addSource(source_name, {
      //   type: "geojson",
      //   data: geoJsonData
      // });

      //local geojson
      map.addSource(source_name, {
        type: "geojson",
        data: geoJsonData
        //data: "./geojson/GeoJson-2.2.1.geojson"
      });
      map.addLayer({
        id: layer_id,
        type: "fill-extrusion",
        source: source_name,
        //'source': {
        // GeoJSON Data source used in vector tiles, documented at
        // https://gist.github.com/ryanbaumann/a7d970386ce59d11c16278b90dde094d
        //  'type': 'geojson',
        //  'data':geoJsonData,
        //'data': "http://api.urban.test/api/buildings-geo-json?struct_id=2&category_id=1&street_id=1"
        //'data': "https://docs.mapbox.com/mapbox-gl-js/assets/indoor-3d-map.geojson"
        //},
        paint: {
          // See the Mapbox Style Specification for details on data expressions.
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions

          // Get the fill-extrusion-color from the source 'color' property.
          //pga数值越小，影响小，颜色暗
          // "fill-extrusion-color": [
          //   "interpolate",
          //   ["linear"],
          //   ["get", "pga"],
          //   0.1,
          //   "#242F7F",
          //   0.2,
          //   "#59A1AD",
          //   0.3,
          //   "#FDF1D0",
          //   0.4,
          //   "#FBE7A3",
          //   1,
          //   "#F5C243"
          // ],
          "fill-extrusion-color": "rgba(255,255,255,0.5)",

          // Get fill-extrusion-height from the source 'height' property.
          //pga数值越小，影响小，楼越底
          // "fill-extrusion-height": [
          //   "interpolate",
          //   ["linear"],
          //   ["get", "pga"],
          //   0.1,
          //   50,
          //   0.2,
          //   100,
          //   0.3,
          //   200,
          //   0.4,
          //   400,
          //   1,
          //   800
          // ],
          "fill-extrusion-height": ["get", "height"],

          // Get fill-extrusion-base from the source 'base_height' property.
          "fill-extrusion-base": ["get", "base_height"],

          // Make extrusions slightly opaque for see through indoor walls.
          "fill-extrusion-opacity": 0.8
        }
      });
    },
    showList() {
      this.show_list = !this.show_list;
    },
    donwloadList() {},
    showChart(city_id) {
      var container = document.getElementById("container");
      var box = document.getElementById("box-chart");
      var myChart = this.$echarts.init(box);
      byPGA(city_id).then(res => {
        var chart_data = res.data;
        var chart_name = "场地PGA";
        chart_data.map((item, idx) => {
          return (item.color = this.pgasColors[idx]);
        });
        var option = buildBarOption(chart_name, chart_data, "name",{
          yAxis: {
            name: '建筑数量',
            nameLocation: 'end'
          }
        });
        myChart.setOption(option);
      });
      window.onresize = function() {
        document.querySelectorAll('.chart-area').forEach((item,idx)=>{
          item.style.width = document.querySelector('.el-collapse').offsetWidth+'px';
        })
        myChart.resize(); 
      }
    },

    fillStreets(city_id) {
      getStreets(city_id).then(res => {
        let data = res.data;
        data.unshift({ id: 0, name: "街道" });
        this.selects.streets = data;
      });
    },
    fillCommunities(city_id, street_id) {
      getCommunities(city_id, street_id).then(res => {
        let data = res.data;
        data.unshift({ id: 0, name: "社区" });
        this.selects.community = 0;
        this.selects.communities = data;
      });
    },
    fillPeopleData(page) {
      let params = this.buildParams();
      params.page = page;
      getPeopleStat(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillGenderData(page) {
      let params = this.buildParams();
      params.page = page;
      getGenderStat(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillHospitalsData(city_id, page) {
      let params = this.buildParams();
      params.page = page;
      getHospitals(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillHideawaysData(page) {
      let params = this.buildParams();
      params.page = page;
      getHideaways(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillWarehousesData(page) {
      let params = this.buildParams();
      params.page = page;
      getWarehouses(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillBuildings(city_id, page) {
      let params = this.buildParams();
      params.page = page;
      params.page_size = 10;
      getQuakeList(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
      getPgaStatistics(params).then(res => {
        this.statistics.max_pga = res.data.max_pga;
        this.statistics.min_pga = res.data.min_pga;
        this.statistics.avg_pga = res.data.avg_pga;
      });
      this.downloadUrl = consts.requestUrl + consts.exportPga + parseJsonToString(params)
    },
    fillBuildingsMap(city_id, color_category) {
      let params = this.buildParams();
      getQuakeGeojson(params).then(data => {
        let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        this.addBuildings2Map(this.map,geoJson);
       
      });
      // getGeoJson(params).then(res => {
      //   let geoJson = res.data;
      //   //mock数据开始
      //   // geoJson.features.map((item,idx)=>{
      //   //   return item.properties.pga = Math.random()*0.7;
      //   // })
      //   //mock数据结束
      //   this.addBuildings2Map(this.map, geoJson);
      // });
      //临时
      
    },
    reloadData(type) {
      if(type == 'street') {
        this.selects.community = 0;
      }
      this.addQuakeMark(this.map);
      this.fillBuildings(this.city_id, 1);
      this.fillBuildingsMap(this.city_id, this.color_category);
      //this.loadData(this.data_type);
    },
    setTipContent() {
      let html = ''
      html = consts.tipsPga
      
      document.getElementById('left-tip-content').innerHTML = html
    },
    buildParams() {
      let params = { city_id: this.city_id };
      params.street_id = this.selects.street;
      params.community_id = this.selects.community;
      params.struct_id = this.selects.struct;
      params.category_id = this.selects.category;
      params.quake_level = this.selects.quakeLevel;
      params.start_build_year = 0;
      params.end_build_year = 0;
      if(this.selects.buildYear != '修建年代'){
        params.start_build_year = this.selects.buildYear.split('-')[0];
        params.end_build_year = this.selects.buildYear.split('-')[1];
      }
      params.pga = this.selects.pga;
      
      params.keyword = this.keyword;
      return params;
    },
    changePage: function(value) {
      this.fillBuildings(this.city_id, value);
    },
    addPointLayerToMap(map, type) {
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      let geojson_url,
        icon = "";
      switch (type) {
        case "hospital":
          geojson_url = consts.url_geojson_hospital;
          icon = "hospital-15";
          break;
        case "hideaway":
          geojson_url = consts.url_geojson_hideaway;
          icon = "school-15";
          break;
        case "warehouse":
          geojson_url = consts.url_geojson_warehouse;
          icon = "parking-15";
          break;
      }
      if (geojson_url == "") {
        return;
      }
      var styleLoadStatus = map.isStyleLoaded();
      if (styleLoadStatus) {
        map.addSource(source_name, {
          type: "geojson",
          data: geojson_url
        });
        map.addLayer({
          id: layer_id,
          type: "symbol",
          source: source_name,
          layout: {
            "icon-image": icon,
            "text-field": "{name}",
            //"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-offset": [0, 0.6],
            "text-anchor": "top"
          }
        });
        return;
      }
      map.on("load", function() {
        map.addSource(source_name, {
          type: "geojson",
          data: geojson_url
        });
        map.addLayer({
          id: layer_id,
          type: "symbol",
          source: source_name,
          layout: {
            "icon-image": icon,
            "text-field": "{name}",
            //"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-offset": [0, 0.6],
            "text-anchor": "top"
          }
        });
      });
    },
    handelMapLayer(type) {
      //console.log(type);
      this.selects.street = 0;
      this.selects.community = 0;
      this.loadData(type);
      let types = ["hospital", "hideaway", "warehouse"];
      types.forEach(value => {
        if (value == type) {
          this.addPointLayerToMap(this.map, value);
        } else {
          this.removeMapLayer(this.map, value);
        }
      });
    },
    getMapLayerId(type) {
      let layer_id = type + "-layer";
      return layer_id;
    },
    getMapSourceName(type) {
      let source_name = type + "-data";
      return source_name;
    },
    removeMapLayer(map, type) {
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      var layer = map.getLayer(layer_id);
      if (layer) {
        map.removeLayer(layer_id);
        map.removeSource(source_name);
      }
    },
    loadData(type, page = 1) {
      switch (type) {
        case "people":
          this.fillPeopleData(page);
          break;
        case "gender":
          this.fillGenderData(page);
          break;
        case "hospital":
          this.fillHospitalsData(page);
          break;
        case "hideaway":
          this.fillHideawaysData();
          break;
        case "warehouse":
          this.fillWarehousesData();
          break;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.main-panel > .content {
  padding: 0 30px;
}
</style>
<style lang="scss" scoped>
.card-map {
  min-height: 350px;
  height: calc(100vh - 48px);
  margin-bottom: 0;

  position: relative;
  #map {
    width: 100%;
    height: calc(100vh - 100px);
  }
  .map_data_msg {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    // color: #02f4fe;
    color: #fff;
    padding: 10px;
    width: 260px;
    .map_data_legend {
      margin-bottom: 5px;
    }
    .map_data_msg_wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .item {
        width: 80px;
        line-height: 15px;
        padding: 5px 0;
      }
      .item span {
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 3px;
        vertical-align: middle;
        margin-left: 0;
      }
      .map_data_msg_inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 10px;
      }
      img {
        width: 40px;

      }
    }
  }
}
.main-panel {
  // height: calc(100vh - 130px);
  min-height: 350px;
}
.right-block {
  // height: calc(100vh - 377px);
  height: 100%;
  min-height: 350px;
  overflow: hidden;
}
.tab-scroller {
  // height: calc(100vh - 470px);
  // min-height: 350px;
  // overflow-y: auto;
}

.show_list {
  width: 100%;
  height: 50px;
  padding-top: 6px;
}
.show_list span {
  height: 32px;
  line-height: 36px;
  margin-left: 32px;
  font-size: 14px;
}
.show_list span i {
  height: 16px;
  padding: 2px 12px;
  background: #ffb236;
  margin: 0 4px;
  border-radius: 16px;
}
.show_list .up_show {
  display: block;
  float: left;
  font-size: 32px;
  cursor: pointer;
}
.show_list .download {
  display: block;
  float: right;
  font-size: 32px;
  cursor: pointer;
}
.footer {
  padding-top: 0;
}
.extended-forms .el-select {
  width: 100%;
  margin-bottom: 30px;
}

.extended-forms .progress {
  margin-bottom: 30px;
}
@import url("https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css");
.coordinates {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 5px 10px;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  display: none;
}
ul.nav__items > li:last-of-type a {
  margin-right: 0 !important;
}
.card-body .tab-content .tab-scroller {
  min-height: auto;
}

</style>
