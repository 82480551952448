import request from '@/util/request';

export function getQuakeHistories(params) {
    return request({
      url: 'api/quakes',
      method: 'get',
      params: params
    })
}

export function getQuakeRegions(params) {
    return request({
      url: 'api/quake-regions',
      method: 'get',
      params: params
    })
}

export function getChasms(params) {
    return request({
      url: 'api/quake-chasms',
      method: 'get',
      params: params
    })
}

export function getChasmsType() {
    return request({
      url: 'api/quake-chasms/list',
      method: 'get'
    })
}


export function getSoils(params) {
  return request({
    url: 'api/soils',
    method: 'get',
    params: params
  })
}


export function getQuakeHistoriesGeo(params) {
    return request({
      url: 'api/quakes/geo',
      method: 'get',
      params: params,
      responseType: 'arraybuffer'
    })
}

export function getQuakeRegionsGeo(params) {
    return request({
      url: 'api/quake-regions/geo',
      method: 'get',
      params: params,
      responseType: 'arraybuffer'
    })
}

export function getChasmsGeo(params) {
    return request({
      url: 'api/quake-chasms/geo',
      method: 'get',
      params: params,
      responseType: 'arraybuffer'
    })
}


export function getSoilsGeo(params) {
    return request({
        url: 'api/regions/geo',
        method: 'get',
        params: params,
        responseType: 'arraybuffer'
    })
}

export function getQuakeStatistics(params) {
    return request({
      url: 'api/quakes/statistics',
      method: 'get',
      params: Object.assign({},params,{
        year_start : 0,
        year_end : 0,
        level_start : 0,
        level_end : 0,
      }),
    })
  }
  
  export function getQuakeChasmsStatistics(params) {
      return request({
      url: 'api/quake-chasms/statistics',
      method: 'get',
      params: Object.assign({},params,{
        type : 0,
      }),
    })
  }
  
  export function getQuakeRegionsStatistics(params) {
    return request({
      url: 'api/quake-regions/statistics',
      method: 'get',
      params: Object.assign({},params,{
        level : 0,
      }),
    })
  }
  
  
  export function getSoilsStatistics(params) {
    return request({
      url: 'api/regions/statistics',
      method: 'get',
      params: params,
    })
  }
  