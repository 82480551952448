export async function getLocalData(fileName) {
  let newData = undefined;
  await fetch(fileName)
    .then((res) => res.arrayBuffer())
    .then((data) => {
      newData = data;
    });
  return newData;
}

//根据中心点坐标返回指定半径的多边形坐标
export function getPolygonFromPoint(
  center,
  numSides,
  radius
) {
  // Convert the center point to radians
  const centerLatRad = (center[1] * Math.PI) / 180;
  const centerLngRad = (center[0] * Math.PI) / 180;

  // Calculate the distance from the center to any vertex
  // const radius = 0.2; // Change this value to set the radius of the polygon
  const vertexDistance = radius / Math.sin(Math.PI / numSides);

  // Create an array to hold the polygon's vertices
  const vertices = [];

  // Calculate the coordinates of each vertex
  for (let i = 0; i < numSides; i++) {
    const angle = (i * 2 * Math.PI) / numSides;
    const vertexLatRad = Math.asin(
      Math.sin(centerLatRad) * Math.cos(vertexDistance / 6371) +
        Math.cos(centerLatRad) *
          Math.sin(vertexDistance / 6371) *
          Math.cos(angle)
    );
    const vertexLngRad =
      centerLngRad +
      Math.atan2(
        Math.sin(angle) *
          Math.sin(vertexDistance / 6371) *
          Math.cos(centerLatRad),
        Math.cos(vertexDistance / 6371) -
          Math.sin(centerLatRad) * Math.sin(vertexLatRad)
      );
    const vertexLng = (vertexLngRad * 180) / Math.PI;
    const vertexLat = (vertexLatRad * 180) / Math.PI;
    vertices.push([vertexLng, vertexLat]);
  }

  // Add the first vertex again to close the polygon
  vertices.push(vertices[0]);

  // Create a GeoJSON object for the polygon
  const polygon = {
    type: "Polygon",
    coordinates: [vertices],
  };

  return polygon;
}
