<template>
    <div class="row" style="margin:0 -30px;">
        <div class="col-md-9" style="padding:0">
            <card class="card-map">
                <div id="map"></div>
                <menu-map :map="map" :mapDefault="mapdefaultSetting" showDisaster="false" showWeather="true" area="3">
                </menu-map>
                <div class="drawer" @click="bindToShowDetail">
                    <div class="el-icon-d-arrow-left" v-if="!drawerOpened"></div>
                    <div class="el-icon-d-arrow-right" v-if="drawerOpened"></div>
                </div>
                <div class="drawer-wrapper" :class="drawerOpened ? 'open' : ''">
                    <div class="drawer-inner">
                        <div class="tabs">
                            <div @click="$router.push({ path: '/fire/building/decisionPeopleOptimizations/1?open=1' })">
                                消防人员
                            </div>
                            <div class="cur">消防器材</div>
                            <div @click="$router.push({ path: '/fire/building/decisionVehicleOptimizations/3?open=1' })">
                                消防车辆
                            </div>
                        </div>
                        <div class="station-list">
                            <div class="station-wrapper"
                                :style="{ 'transform': ('translate(' + curPosArr[idx].x + 'px,' + curPosArr[idx].y + 'px)'), 'z-index': curPosArr[idx].zidx }"
                                v-for="(item, idx) in stations" v-bind:key="idx" @click="cardEnter" :data-idx="idx">
                                <div class="station-title">{{ item.station_name }}</div>
                                <happy-scroll color="rgba(0,0,0,0.2)" size="5" resize hide-horizontal>
                                    <div class="station-content">
                                        <table class="table-station-info">
                                            <tr>
                                                <td width="190">消防站类别</td>
                                                <td>{{ item.station_type }}</td>
                                            </tr>
                                            <tr>
                                                <td>最多出动人数（人）</td>
                                                <td>{{ item.c1 }}</td>
                                            </tr>
                                            <tr>
                                                <td>基本防护装备配备率（%）</td>
                                                <td>{{ item.c2 }}</td>
                                            </tr>
                                            <tr>
                                                <td>抢险救援器材配备率（%）</td>
                                                <td>{{ item.c3 }}</td>
                                            </tr>
                                            <tr>
                                                <td>灭火器材配备率（%）</td>
                                                <td>{{ item.c4 }}</td>
                                            </tr>
                                        </table>
                                        <div class="table-title">基本防护装备配备详情</div>
                                        <happy-scroll color="rgba(0,0,0,0.2)" size="5" resize hide-vertical class="hss">
                                            <el-table :data="item.protect_equipments" style="width: 100%"
                                                class="table-station-sublist" :span-method="objectSpanMethodStation">
                                                <el-table-column prop="c1" label="序号" width="55">
                                                </el-table-column>
                                                <el-table-column prop="c2" label="名称" width="180">
                                                </el-table-column>
                                                <el-table-column prop="c3" label="配备（每人）" width="100">
                                                </el-table-column>
                                                <el-table-column prop="c4" label="备份比（1/X）" width="110">
                                                </el-table-column>
                                                <el-table-column prop="c5" label="现有数">
                                                </el-table-column>
                                                <el-table-column prop="c6" label="标准数">
                                                </el-table-column>
                                                <el-table-column prop="c7" label="缺配数">
                                                </el-table-column>
                                                <el-table-column prop="c8" label="是否符合（1/0)" width="120">
                                                </el-table-column>
                                            </el-table>
                                        </happy-scroll>
                                        <div class="table-total">
                                            <div>基本防护装备符合标准配备的种类数量（种）</div>
                                            <div>{{ item.c5 }}</div>
                                        </div>
                                        <div class="table-total">
                                            <div>基本防护装备应配备的种类总数（种）</div>
                                            <div>{{ item.c6 }}</div>
                                        </div>
                                        <div class="table-title">抢险救援器材配备详情</div>
                                        <happy-scroll color="rgba(0,0,0,0.2)" size="5" resize hide-vertical class="hss">
                                            <el-table :data="item.rescue_equipments" style="width: 100%"
                                                class="table-station-sublist">
                                                <el-table-column prop="c1" label="序号" width="55">
                                                </el-table-column>
                                                <el-table-column prop="c2" label="名称" width="180">
                                                </el-table-column>
                                                <el-table-column prop="c3" label="配备（每人）" width="100">
                                                </el-table-column>
                                                <el-table-column prop="c4" label="备份比（1/X）" width="110">
                                                </el-table-column>
                                                <el-table-column prop="c5" label="现有数">
                                                </el-table-column>
                                                <el-table-column prop="c6" label="标准数">
                                                </el-table-column>
                                                <el-table-column prop="c7" label="缺配数">
                                                </el-table-column>
                                                <el-table-column prop="c8" label="是否符合（1/0)" width="120">
                                                </el-table-column>
                                            </el-table>
                                        </happy-scroll>
                                        <div class="table-total">
                                            <div>抢险救援器材符合标准配备的种类数量（种）</div>
                                            <div>{{ item.c7 }}</div>
                                        </div>
                                        <div class="table-total">
                                            <div>抢险救援器材应配备的种类总数（种）</div>
                                            <div>{{ item.c8 }}</div>
                                        </div>
                                        <div class="table-title">灭火器材配备详情</div>
                                        <happy-scroll color="rgba(0,0,0,0.2)" size="5" resize hide-vertical class="hss">
                                            <el-table :data="item.extinguisher_equipments" style="width: 100%"
                                                class="table-station-sublist">
                                                <el-table-column prop="c1" label="序号" width="55">
                                                </el-table-column>
                                                <el-table-column prop="c2" label="名称" width="180">
                                                </el-table-column>
                                                <el-table-column prop="c3" label="配备（每人）" width="100">
                                                </el-table-column>
                                                <el-table-column prop="c4" label="备份比（1/X）" width="110">
                                                </el-table-column>
                                                <el-table-column prop="c5" label="现有数">
                                                </el-table-column>
                                                <el-table-column prop="c6" label="标准数">
                                                </el-table-column>
                                                <el-table-column prop="c7" label="缺配数">
                                                </el-table-column>
                                                <el-table-column prop="c8" label="是否符合（1/0)" width="120">
                                                </el-table-column>
                                            </el-table>
                                        </happy-scroll>
                                        <div class="table-total">
                                            <div>灭火器材符合标准配备的种类数量（种）</div>
                                            <div>{{ item.c9 }}</div>
                                        </div>
                                        <div class="table-total">
                                            <div>灭火器材配备应配备的种类总数（种）</div>
                                            <div>{{ item.c10 }}</div>
                                        </div>
                                    </div>
                                </happy-scroll>
                            </div>
                        </div>
                        <div class="station-wrapper station-custom">
                            <div class="station-title">消防器材情况概览</div>
                            <happy-scroll color="rgba(0,0,0,0.2)" size="5" resize hide-horizontal>
                                <div class="table-title">基本防护装备配备详情</div>
                                <happy-scroll color="rgba(0,0,0,0.2)" size="5" resize hide-vertical class="hss custom-1">
                                    <el-table :data="stationlist.protect_equipments" style="width: 100%"
                                        class="table-station-sublist" :span-method="objectSpanMethodStation">
                                        <el-table-column prop="c100" label="序号" width="55">
                                        </el-table-column>
                                        <el-table-column prop="c2" label="名称" width="180">
                                            <template slot-scope="scope">
                                                <input type="text" v-model="scope.row.c2" maxlength="10"
                                                    v-if="scope.row.isNew && customEditing" placeholder="名称" />
                                                <template v-else>{{ scope.row.c2 }}</template>

                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="c3" label="配备（每人）" width="100">
                                            <template slot-scope="scope">
                                                <input type="text" v-model="scope.row.c3" maxlength="10"
                                                    v-if="scope.row.isNew && customEditing" placeholder="配备（每人）" />
                                                <template v-else>{{ scope.row.c3 }}</template>

                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="c4" label="备份比（1/X）" width="110">
                                            <template slot-scope="scope">
                                                <input type="text" v-model="scope.row.c4" maxlength="10"
                                                    v-if="scope.row.isNew && customEditing" placeholder="备份比（1/X）" />
                                                <template v-else>{{ scope.row.c4 }}</template>

                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="c5" label="现有数">
                                            <template slot-scope="scope">
                                                <input type="number" v-model="scope.row.c5" maxlength="10"
                                                    v-if="customEditing" placeholder="现有数" />
                                                <template v-else>{{ scope.row.c5 }}</template>

                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="c6" label="标准数">
                                            <template slot-scope="scope">
                                                <input type="text" v-model="scope.row.c6" maxlength="10"
                                                    v-if="scope.row.isNew && customEditing" placeholder="标准数" />
                                                <template v-else>{{ scope.row.c6 }}</template>

                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="c7" label="缺配数">
                                            <template slot-scope="scope">
                                                <!-- <input type="text" v-model="scope.row.c7" maxlength="10"
                                                    v-if="scope.row.isNew && customEditing" placeholder="缺配数" />
                                                <template v-else>{{ scope.row.c7 }}</template> -->
                                                {{ scope.row.c7 }}
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="c8" label="是否符合（1/0)" width="120">
                                            <template slot-scope="scope">
                                                <!-- <input type="text" v-model="scope.row.c8" maxlength="10"
                                                    v-if="scope.row.isNew && customEditing" placeholder="是否符合（1/0)" />
                                                <template v-else>{{ scope.row.c8 }}</template> -->
                                                {{ scope.row.c8 }}
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="c9" label="所属消防站" width="120">
                                            <template slot-scope="scope">
                                                <select name="" id="" v-if="scope.row.isNew && customEditing"
                                                    placeholder="所属消防站" v-model="scope.row.c9">
                                                    <option :value="item.station_name" v-for="(item, idx) in stations"
                                                        :key="idx">{{
                                                            item.station_name
                                                        }}
                                                    </option>
                                                </select>
                                                <template v-else>{{ scope.row.c9
                                                }}</template>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </happy-scroll>
                                <div class="table-total">
                                    <div>基本防护装备符合标准配备的种类数量（种）</div>
                                    <div>{{ stationlist.protect_equipments_kinds.compliance_kinds }}</div>
                                </div>
                                <div class="table-total">
                                    <div>基本防护装备应配备的种类总数（种）</div>
                                    <div>{{ stationlist.protect_equipments_kinds.total_kinds }}</div>
                                </div>
                                <div class="table-title">抢险救援器材配备详情</div>
                                <happy-scroll color="rgba(0,0,0,0.2)" size="5" resize hide-vertical class="hss  custom-2">
                                    <el-table :data="stationlist.rescue_equipments" style="width: 100%"
                                        class="table-station-sublist">
                                        <el-table-column prop="c100" label="序号" width="45">
                                        </el-table-column>
                                        <el-table-column prop="c2" label="名称" width="180">
                                            <template slot-scope="scope">
                                                <input type="text" v-model="scope.row.c2" maxlength="10"
                                                    v-if="scope.row.isNew && customEditing" placeholder="名称" />
                                                <template v-else>{{ scope.row.c2 }}</template>

                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="c3" label="配备（每人）" width="100">
                                            <template slot-scope="scope">
                                                <input type="text" v-model="scope.row.c3" maxlength="10"
                                                    v-if="scope.row.isNew && customEditing" placeholder="配备（每人）" />
                                                <template v-else>{{ scope.row.c3 }}</template>

                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="c4" label="备份比（1/X）" width="110">
                                            <template slot-scope="scope">
                                                <input type="text" v-model="scope.row.c4" maxlength="10"
                                                    v-if="scope.row.isNew && customEditing" placeholder="备份比（1/X）" />
                                                <template v-else>{{ scope.row.c4 }}</template>

                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="c5" label="现有数">
                                            <template slot-scope="scope">
                                                <input type="text" v-model="scope.row.c5" maxlength="10"
                                                    v-if="scope.row.isNew && customEditing" placeholder="现有数" />
                                                <template v-else>{{ scope.row.c5 }}</template>

                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="c6" label="标准数">
                                            <template slot-scope="scope">
                                                <input type="text" v-model="scope.row.c6" maxlength="10"
                                                    v-if="scope.row.isNew && customEditing" placeholder="标准数" />
                                                <template v-else>{{ scope.row.c6 }}</template>

                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="c7" label="缺配数">
                                            <template slot-scope="scope">
                                                <!-- <input type="text" v-model="scope.row.c7" maxlength="10"
                                                    v-if="scope.row.isNew && customEditing" placeholder="缺配数" />
                                                <template v-else>{{ scope.row.c7 }}</template> -->
                                                {{ scope.row.c7 }}
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="c8" label="是否符合（1/0)" width="120">
                                            <template slot-scope="scope">
                                                <!-- <input type="text" v-model="scope.row.c8" maxlength="10"
                                                    v-if="scope.row.isNew && customEditing" placeholder="是否符合（1/0)" />
                                                <template v-else>{{ scope.row.c8 }}</template> -->
                                                {{ scope.row.c8 }}
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="c9" label="所属消防站" width="120">
                                            <template slot-scope="scope">
                                                <select name="" id="" v-if="customEditing" placeholder="所属消防站"
                                                    v-model="scope.row.c9">
                                                    <option :value="item.station_name" v-for="(item, idx) in stations"
                                                        :key="idx">{{
                                                            item.station_name
                                                        }}
                                                    </option>
                                                </select>
                                                <template v-else>{{ scope.row.c9
                                                }}</template>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </happy-scroll>
                                <div class="table-total">
                                    <div>抢险救援器材符合标准配备的种类数量（种）</div>
                                    <div>{{ stationlist.rescue_equipments_kinds.compliance_kinds }}</div>
                                </div>
                                <div class="table-total">
                                    <div>抢险救援器材应配备的种类总数（种）</div>
                                    <div>{{ stationlist.rescue_equipments_kinds.total_kinds }}</div>
                                </div>
                                <div class="table-title">灭火器材配备详情</div>
                                <happy-scroll color="rgba(0,0,0,0.2)" size="5" resize hide-vertical class="hss  custom-3">
                                    <el-table :data="stationlist.extinguisher_equipments" style="width: 100%"
                                        class="table-station-sublist">
                                        <el-table-column prop="c100" label="序号" width="45">
                                        </el-table-column>
                                        <el-table-column prop="c2" label="名称" width="180">
                                            <template slot-scope="scope">
                                                <input type="text" v-model="scope.row.c2" maxlength="10"
                                                    v-if="scope.row.isNew && customEditing" placeholder="名称" />
                                                <template v-else>{{ scope.row.c2 }}</template>

                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="c3" label="配备（每人）" width="100">
                                            <template slot-scope="scope">
                                                <input type="text" v-model="scope.row.c3" maxlength="10"
                                                    v-if="scope.row.isNew && customEditing" placeholder="配备（每人）" />
                                                <template v-else>{{ scope.row.c3 }}</template>

                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="c4" label="备份比（1/X）" width="110">
                                            <template slot-scope="scope">
                                                <input type="text" v-model="scope.row.c4" maxlength="10"
                                                    v-if="scope.row.isNew && customEditing" placeholder="备份比（1/X）" />
                                                <template v-else>{{ scope.row.c4 }}</template>

                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="c5" label="现有数">
                                            <template slot-scope="scope">
                                                <input type="text" v-model="scope.row.c5" maxlength="10"
                                                    v-if="scope.row.isNew && customEditing" placeholder="现有数" />
                                                <template v-else>{{ scope.row.c5 }}</template>

                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="c6" label="标准数">
                                            <template slot-scope="scope">
                                                <input type="text" v-model="scope.row.c6" maxlength="10"
                                                    v-if="scope.row.isNew && customEditing" placeholder="标准数" />
                                                <template v-else>{{ scope.row.c6 }}</template>

                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="c7" label="缺配数">
                                            <template slot-scope="scope">
                                                <!-- <input type="text" v-model="scope.row.c7" maxlength="10"
                                                    v-if="scope.row.isNew && customEditing" placeholder="缺配数" />
                                                <template v-else>{{ scope.row.c7 }}</template> -->
                                                {{ scope.row.c7 }}
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="c8" label="是否符合（1/0)" width="120">
                                            <template slot-scope="scope">
                                                <!-- <input type="text" v-model="scope.row.c8" maxlength="10"
                                                    v-if="scope.row.isNew && customEditing" placeholder="是否符合（1/0)" />
                                                <template v-else>{{ scope.row.c8 }}</template> -->
                                                {{ scope.row.c8 }}
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="c9" label="所属消防站" width="120">
                                            <template slot-scope="scope">
                                                <select name="" id="" v-if="customEditing" placeholder="所属消防站"
                                                    v-model="scope.row.c9">
                                                    <option :value="item.station_name" v-for="(item, idx) in stations"
                                                        :key="idx">{{
                                                            item.station_name
                                                        }}
                                                    </option>
                                                </select>
                                                <template v-else>{{ scope.row.c9
                                                }}</template>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </happy-scroll>
                                <div class="table-total">
                                    <div>灭火器材符合标准配备的种类数量（种）</div>
                                    <div>{{ stationlist.extinguisher_equipments_kinds.compliance_kinds }}</div>
                                </div>
                                <div class="table-total">
                                    <div>灭火器材配备应配备的种类总数（种）</div>
                                    <div>{{ stationlist.extinguisher_equipments_kinds.total_kinds }}</div>
                                </div>
                            </happy-scroll>
                        </div>
                        <div class="btn-add-station">添加消防站 <i class="el-icon-plus"></i></div>
                        <div class="btn-add-car btn-add-car-1" @click="addCustomItem(1)" v-show="customEditing">添加基本防护装备
                            <i class="el-icon-plus"></i>
                        </div>
                        <div class="btn-add-car btn-add-car-2" @click="addCustomItem(2)" v-show="customEditing">添加抢险救援器材
                            <i class="el-icon-plus"></i>
                        </div>
                        <div class="btn-add-car btn-add-car-3" @click="addCustomItem(3)" v-show="customEditing">添加灭火器材
                            <i class="el-icon-plus"></i>
                        </div>
                        <div class="btn-optimize" @click="autoOptimize">一键优化</div>
                        <div class="btn-custom-optimize" @click="customEdit">
                            <template
                                v-if="!customEditing">&nbsp;&nbsp;&nbsp;&nbsp;手动调整&nbsp;&nbsp;&nbsp;&nbsp;</template><template
                                v-if="customEditing">手动调整确定</template>
                        </div>
                        <div class="btn-reset" @click="beforeOptimize">重置</div>
                    </div>
                </div>
                <div class="radio-group">
                    <div v-for="(item, idx) in types" @click="changeType(item.id)" v-bind:key="idx">
                        <label :for='item.id'><input type="radio" :id="item.id" :value="item.id" v-model="curType">
                            {{ item.value }}</label>
                    </div>
                </div>
                <div class="map_data_msg" v-if="mapinfo">
                    <div class="map_data_legend">{{ mapinfo.title }}</div>
                    <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
                        <div class="item" v-for="(item, index) in mapinfo.names" :key="index">
                            <span :style="'background: ' + mapinfo.colors[index] + ''"></span>
                            {{ item }}
                        </div>
                    </div>
                </div>
                <div class="show_list">
                    <a class="up_show" v-on:click="showList">
                        <i class="now-ui-icons" :class="[show_list ? 'arrows-1_minimal-down' : 'arrows-1_minimal-up']"></i>
                    </a>
                    <a class="download ml-auto" :href="downloadUrl">
                        <i class="now-ui-icons arrows-1_cloud-download-93"></i>
                    </a>
                    <span>
                        数据列表
                        <!-- <i>{{total}}</i> -->
                    </span>
                </div>
                <div class="show_list_record tabCount2 show_fire_list_record" v-show="show_list" ref="show_list_record">
                    <tabs type="primary" v-model="activeTab">
                        <tab-pane label="表1">
                            <el-table :data="data" max-height="600" empty-text="暂无数据！">
                                <el-table-column v-for="(item, index) in listheader" min-width="150" :label="item"
                                    :key="index">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row[index] }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </tab-pane>

                        <tab-pane label="表2">
                            <el-table :data="data2" max-height="600" :span-method="objectSpanMethod2" empty-text="暂无数据！">
                                <el-table-column v-for="(item, index) in listheader2" min-width="150" :label="item"
                                    :key="index">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row[index] }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </tab-pane>

                        <tab-pane label="表3">
                            <el-table :data="data3" max-height="600" :span-method="objectSpanMethod3" empty-text="暂无数据！">
                                <el-table-column v-for="(item, index) in listheader3" min-width="150" :label="item"
                                    :key="index">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row[index] }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </tab-pane>

                    </tabs>
                </div>
            </card>
        </div>
        <div class="col-md-3" id="customStyle">
            <el-collapse v-model="activeNames">
                <el-collapse-item title="信息图表" name="2">
                    <div class="col-md-12">
                        <div class="chart-area" id="box-chart2" style="height: 400px"></div>
                    </div>
                    <div class="col-md-12">
                        <div class="chart-area" id="box-chart" style="height: 400px"></div>
                    </div>
                </el-collapse-item>
            </el-collapse>



        </div>
        <div class="key-infos">

        </div>
    </div>
</template>
<script>
import mapboxgl from "mapbox-gl";
import MenuMap from "src/components/menumapFire3";
import SearchArea from "src/components/SearchFireStation";
import geobuf from "geobuf";
import Pbf from "pbf";
import MiniMap from "src/mapbox/mapboxgl-minimap.js";
import { TabPane, Tabs, Switch } from "src/components";
import { Radio } from 'src/components/index';
import { Input, Button, Select, Option, Cascader, Collapse, CollapseItem, Table, TableColumn } from "element-ui";
import { consts } from "src/util/consts.js";
import {
    getDecisionEquipmentOptimizationChart as getChart1,
    getDecisionEquipmentOptimizationChart2 as getChart2,
    getStationGridsList as getList1,
    getDecisionEquipmentOptimizationList as getList2,
    getFireCommunityGeo as getGeo1,
    getFireDivisionsStationGeo as getGeo2, //消防站点
    getFireStationGeoNew as getStrokeGeoJson, //消防站轮廓
    getDecisionEquipmentOptimizationInfo as getInfo,
    getDecisionEquipmentOptimizationMapData as getMapData,
    getDecisionEquipmentOptimizationCommunityData as getCommunityData,
    getDecisionEquipmentOptimizationStations as getStations
} from "src/api/fire.js";
import { getHideawaylGeo } from 'src/api/cityinfo.js';
import { buildBarOption, buildBarStackYValueOption, buildPieOption, buildBarSideOption } from "src/util/chart.js";
import { storeMapOptions, getMapOptions, parseJsonToString } from "src/util/common.js";
import { Pagination as NPagination } from "src/components";

import * as THREE from "three";

export default {
    components: {
        MenuMap,
        SearchArea,
        TabPane,
        Tabs,
        [Cascader.name]: Cascader,
        [Collapse.name]: Collapse,
        [CollapseItem.name]: CollapseItem,
        [Input.name]: Input,
        [Button.name]: Button,
        [Option.name]: Option,
        [Select.name]: Select,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        NPagination,
        [Switch.name]: Switch,
        Radio
    },
    data() {
        return {
            mid: 0, //模块id
            types: [], //左上角切换radio
            curType: 0,
            activeNames: ['2', '3'],
            activeName: "first",
            selects: {
                town_id: 1,
                street_id: 0,
                ids: {},
                filter_street_id: 0,//地图筛选的street_id
            },
            props: { multiple: true },
            options1: [],
            options2: [],
            keyword: "",
            total: 0,
            data: [],
            data2: [],
            data3: [],
            page: 1,
            map: {},
            city_id: localStorage.getItem("city_id") || 1,
            show_list: false,
            show_list_height: 0,
            pagination: {
                perPage: 10,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
                total: 20
            },
            last_updated: consts.last_updated,
            mapdefaultSetting: {},
            statistics: {
            },
            downloadUrl: '',
            options: [
                { name: '用电量', selected: [], data: [] },
            ],
            mapinfo: null,
            listheader: [],
            listheader2: [],
            listheader3: [],
            tabCount: 1,
            tab2Mid: 1,
            activeTab: '表1',
            drawerOpened: false,
            stations: [],
            posArr: [], //每个位置的坐标
            curCardIdx: null, //当前最上层的卡片idx
            curPosArr: [], //每个卡片的最新坐标
            stationlist: {
                protect_equipments: [],
                rescue_equipments: [],
                extinguisher_equipments: [],
                protect_equipments_kinds: {
                    compliance_kinds: 0,
                    total_kinds: 0
                },
                rescue_equipments_kinds: {
                    compliance_kinds: 0,
                    total_kinds: 0
                },
                extinguisher_equipments_kinds: {
                    compliance_kinds: 0,
                    total_kinds: 0
                }
            }, //所有车辆
            type_id: 1,
            customEditing: false,
        };
    },

    mounted() {
        let _this = this;
        //localStorage.setItem('curStroke', 'street');
        //this.fillStreets(this.city_id);
        if (this.$route.query.open == 1) {
            this.drawerOpened = true;
        }
        this.mid = 1;
        this.curType = this.mid;
        this.setTipContent()
        this.showChart(this.city_id);

        this.initMap(this.city_id);
        this.map.on('style.load', function () {
            _this.fillList1() //初始化list，不随筛选改变
            // _this.fillStationList()
            _this.reloadData()
        })



        // this.map.on("click", (e) => {
        //     var features = this.map.queryRenderedFeatures(e.point, {
        //       layers: ["block-layer","points"],
        //     });
        //     if(!features.length) return;
        //     let pointscount = 0;
        //     let blockcount = 0;
        //     features.forEach((item,idx)=>{
        //       if(item.layer.id=='points') {
        //         pointscount++
        //       } else if(item.layer.id=='block-layer'){
        //         blockcount++
        //       }
        //     })
        //     if(pointscount >=1 && blockcount>=1) {
        //       //点和区都点了，触发点
        //       this.mapClickEventNew(features[0])
        //     } else if(pointscount >= 1){
        //       this.mapClickEventNew(features[0])
        //     } else if(blockcount >= 1) {
        //       this.mapClickEvent2New(features[0])
        //     }

        // });
    },
    computed: {
        to() {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        // street (){
        //   return this.selects.street
        // }
    },
    watch: {
        "selects.street"(val) {
            //街道切换选社区
            this.fillCommunities(this.city_id, val);
        },
        activeTab(val) {
            let params = this.buildParams();
            if (val == "表1") {
                params.type_id = 1;
                this.downloadUrl = consts.requestUrl + consts.exportFire2StationGrid + parseJsonToString(params)
            } else if (val == "表2") {
                params.type_id = 2;
                this.downloadUrl = consts.requestUrl + consts.exportFire2DecisionEquipmentOptimization + parseJsonToString(params)
            } else {
                params.type_id = 3;
                this.downloadUrl = consts.requestUrl + consts.exportFire2DecisionEquipmentOptimization + parseJsonToString(params)
            }

        },
        curCardIdx(val) {
            this.posArr.forEach((item, idx) => {
                let newIdx = (val + idx) % this.stations.length
                this.curPosArr[newIdx] = item
            })
            this.$forceUpdate();
            // this.$nextTick(() => {
            //   document.querySelectorAll('.station-list .el-table__row').forEach((item, idx) => {
            //     item.setAttribute('draggable', 'true')
            //     item.addEventListener('dragstart', this.dragstart)
            //   })
            //   document.querySelectorAll('.station-list .station-wrapper').forEach((item, idx) => {
            //     item.addEventListener('drop', this.drop)
            //     item.addEventListener('dragover', this.dragover)
            //   })
            // })

        }
    },
    methods: {
        // 初始化
        initMap(city_id) {
            this.mapdefaultSetting = {
                center: consts.mapCenter[this.city_id - 1],
                zoom: consts.mapDefaultZoom,
                pitch: 0,
                city_id: this.city_id
            }
            mapboxgl.accessToken = consts.mapboxAccessToken;
            var origin = [-95.97299, 36.15031, 0];
            let map_options = getMapOptions();
            //console.log(map_options);
            let zoom = map_options ? map_options.zoom : 12;
            let center = map_options ? [map_options.lng, map_options.lat] : consts.mapCenter[city_id - 1];
            //console.log(center);
            var map = new mapboxgl.Map({
                container: "map",
                style: consts.mapBoxStyle,
                //center: [-96, 37.8],
                center: center,
                zoom: zoom,
                pitch: 0,
                bearing: 0,
                attributionControl: false
            });
            this.map = map;
            //定位图标
            map.addControl(
                new MapboxGeocoder({
                    accessToken: mapboxgl.accessToken,
                    mapboxgl: mapboxgl
                })
            );
            this.map.addControl(new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                trackUserLocation: true
            }));
            //控制图层
            var nav = new mapboxgl.NavigationControl();
            this.map.addControl(nav, 'top-right');
            //全屏按钮
            this.map.addControl(new mapboxgl.FullscreenControl({
                container: document.querySelector('body')
            })
            );
            //小地图
            //https://github.com/brendanmatkin/mapboxgl-minimap
            map.on("style.load", function () {
                // Possible position values are 'bottom-left', 'bottom-right', 'top-left', 'top-right'
                map.addControl(new MiniMap({
                    id: "mapboxgl-minimap",
                    width: "200px",
                    height: "150px",
                    //style: "mapbox://styles/mapbox/streets-v10",
                    style: consts.mapBoxStyle,
                    fillColor: "#f58220",
                    zoomAdjust: null
                }),
                    'bottom-right');
            });

            //记录地图设置
            map.on('move', function () {
                storeMapOptions({
                    lng: map.getCenter().lng,
                    lat: map.getCenter().lat,
                    zoom: map.getZoom()
                });
            });

        },
        showList() {
            this.show_list = !this.show_list;
        },
        donwloadList() { },
        showChart(city_id) {
            var self = this;
            var container = document.querySelector('.el-collapse');
            var box = document.getElementById("box-chart");
            var box2 = document.getElementById("box-chart2");
            var myChart = this.$echarts.init(box);
            var myChart2 = this.$echarts.init(box2);
            box.style.width = container.offsetWidth + "px";

            getChart1({
                "city_id": this.city_id,
                "town_id": 1,
                "module_id": this.mid,
            }).then(res => {
                var chart_data = res.data.data;
                var chart_values = res.data.values
                var chart_stations = res.data.stations
                var chart_name = res.data.name;
                chart_values.forEach((item, idx) => {
                    item.values = item.values.map((subitem, subidx) => {
                        return subitem = (subitem * 100).toFixed(2)

                    })

                })
                var option = {
                    title: {
                        text: chart_name,
                        left: 'left',
                        textStyle: {
                            fontSize: 12,
                            color: "#fff"
                        }
                    },
                    animation: true,
                    legend: {
                        data: [],
                        x: 'center',
                        y: 'bottom',
                        //padding: [30, 0, 0, 0],
                        textStyle: {
                            color: '#ffffff',
                            fontSize: 10,
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                    },
                    grid: {
                        top: '50',
                        left: '3%',
                        right: '30',
                        bottom: '150',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        //nameGap: 3,

                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#ffffff',
                                fontSize: 10,
                            },
                            interval: 0,
                            rotate: 45,
                            // formatter:function(value){
                            //   //if(value ==1000) return value+'天';
                            //   if(value ==1000) return value;
                            //   if(value ==3000) return value;
                            //   if(value ==7000) return value;
                            // }
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#ffffff' //更改坐标轴颜色
                            }
                        },
                        splitLine: { show: false },
                        data: chart_stations
                    },
                    yAxis: {
                        type: 'value',
                        axisLine: {
                            lineStyle: {
                                color: '#ffffff' //更改坐标轴颜色
                            }
                        },
                        splitLine: { show: true },
                        axisLabel: {
                            formatter: '{value}%'
                        }

                    },
                    series: []
                };
                let arrIdx = [0, 3, 1, 4, 2, 5] //返回数组按这个顺序显示
                let legend = ['基本防护装备配备率（优化前）', '基本防护装备配备率（优化后）', '抢险救援器材配备率（优化前）', '抢险救援器材配备率（优化后）', '灭火器材配备率（优化前）      ', '灭火器材配备率（优化后）']
                let colors = ['#6a99d0', '#6a99d0', '#de8344', '#de8344', '#a5a5a5', '#a5a5a5']
                option.legend.data = legend
                // option.tooltip.formatter = function (data) {
                //   let tmpstr = ''
                //   for (let i = 0; i < data[0].axisValue.length; i++) {
                //     tmpstr += '&#' + data[0].axisValue.charCodeAt(i) + ';'
                //   }

                //   let str = tmpstr + "</br>"
                //   debugger;
                //   data.reverse().forEach((item, idx) => {
                //     if (idx == 0) { return; }
                //     str = str + item.marker + item.seriesName + " : " + item.data + "(" + (item.data / count_total * 100).toFixed(2) + "%)" + "</br>"
                //   })
                //   return str
                // }
                arrIdx.map((item, idx) => {
                    option.series.push({
                        name: legend[idx],
                        type: 'line',
                        //stack: 'Total',
                        itemStyle: {
                            normal: {
                                color: colors[idx],
                                lineStyle: {
                                    width: 2,
                                    type: idx % 2 == 0 ? 'soloid' : 'dotted', //'dotted'虚线 'solid'实线
                                }
                            },

                        },
                        data: chart_values[item].values
                    })
                })
                option.tooltip = {
                    trigger: 'axis',
                    position: function (p) { //其中p为当前鼠标的位置
                        return [p[0] - 40, p[1] + 10];
                    },
                    extraCssText: 'width:160px;white-space:inherit;',
                    formatter: function (data) {
                        let tmpstr = ''
                        for (let i = 0; i < data[0].axisValue.length; i++) {
                            tmpstr += '&#' + data[0].axisValue.charCodeAt(i) + ';'
                        }

                        let str = tmpstr + "</br>"
                        data.forEach((item, idx) => {
                            str = str + item.marker + item.seriesName + " : " + item.data + "%" + "</br>"
                        })
                        return str
                    }
                };
                myChart.setOption(option);
            });


            getChart2({
                "city_id": this.city_id,
                "town_id": 1,
                "module_id": this.mid,
            }).then(res => {
                var chart_data = res.data.data;
                var chart_values = res.data.values
                var chart_stations = res.data.street_names
                var chart_name = res.data.name;
                chart_values.forEach((item, idx) => {
                    item.values = item.values.map((subitem, subidx) => {
                        return subitem = (subitem * 100).toFixed(2)

                    })

                })
                var option = {
                    title: {
                        text: chart_name,
                        left: 'left',
                        textStyle: {
                            fontSize: 12,
                            color: "#fff"
                        }
                    },
                    animation: true,
                    legend: {
                        data: [],
                        x: 'center',
                        y: 'bottom',
                        //padding: [30, 0, 0, 0],
                        textStyle: {
                            color: '#ffffff',
                            fontSize: 10,
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                    },
                    grid: {
                        top: '50',
                        left: '3%',
                        right: '30',
                        bottom: '150',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        //nameGap: 3,

                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#ffffff',
                                fontSize: 10,
                            },
                            interval: 0,
                            rotate: 45,
                            // formatter:function(value){
                            //   //if(value ==1000) return value+'天';
                            //   if(value ==1000) return value;
                            //   if(value ==3000) return value;
                            //   if(value ==7000) return value;
                            // }
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#ffffff' //更改坐标轴颜色
                            }
                        },
                        splitLine: { show: false },
                        data: chart_stations
                    },
                    yAxis: {
                        type: 'value',
                        axisLine: {
                            lineStyle: {
                                color: '#ffffff' //更改坐标轴颜色
                            }
                        },
                        splitLine: { show: true },
                        axisLabel: {
                            formatter: '{value}%'
                        }

                    },
                    series: []
                };
                let arrIdx = [0, 3, 1, 4, 2, 5] //返回数组按这个顺序显示
                let legend = ['基本防护装备配备率（优化前）', '基本防护装备配备率（优化后）', '抢险救援器材配备率（优化前）', '抢险救援器材配备率（优化后）', '灭火器材配备率（优化前）      ', '灭火器材配备率（优化后）']
                let colors = ['#6a99d0', '#6a99d0', '#de8344', '#de8344', '#a5a5a5', '#a5a5a5']
                option.legend.data = legend
                // option.tooltip.formatter = function (data) {
                //   let tmpstr = ''
                //   for (let i = 0; i < data[0].axisValue.length; i++) {
                //     tmpstr += '&#' + data[0].axisValue.charCodeAt(i) + ';'
                //   }

                //   let str = tmpstr + "</br>"
                //   debugger;
                //   data.reverse().forEach((item, idx) => {
                //     if (idx == 0) { return; }
                //     str = str + item.marker + item.seriesName + " : " + item.data + "(" + (item.data / count_total * 100).toFixed(2) + "%)" + "</br>"
                //   })
                //   return str
                // }
                arrIdx.map((item, idx) => {
                    option.series.push({
                        name: legend[idx],
                        type: 'line',
                        //stack: 'Total',
                        itemStyle: {
                            normal: {
                                color: colors[idx],
                                lineStyle: {
                                    width: 2,
                                    type: idx % 2 == 0 ? 'soloid' : 'dotted', //'dotted'虚线 'solid'实线
                                }
                            },

                        },
                        data: chart_values[item].values
                    })
                })
                option.tooltip = {
                    trigger: 'axis',
                    position: function (p) { //其中p为当前鼠标的位置
                        return [p[0] - 40, p[1] + 10];
                    },
                    extraCssText: 'width:160px;white-space:inherit;',
                    formatter: function (data) {
                        let tmpstr = ''
                        for (let i = 0; i < data[0].axisValue.length; i++) {
                            tmpstr += '&#' + data[0].axisValue.charCodeAt(i) + ';'
                        }

                        let str = tmpstr + "</br>"
                        data.forEach((item, idx) => {
                            str = str + item.marker + item.seriesName + " : " + item.data + "%" + "</br>"
                        })
                        return str
                    }
                };
                myChart2.setOption(option);
            });





            myChart.resize();
            myChart2.resize();

            window.onresize = function () {
                document.querySelectorAll('.chart-area').forEach((item, idx) => {
                    item.style.width = document.querySelector('.el-collapse').offsetWidth + 'px';
                })
                myChart.resize();
                myChart2.resize();
            }
        },
        handleChange1(value) {
            //右侧下拉change1
            this.selects.town_id = 1
            this.selects.street_id = value[2] || 0
            this.reloadData();

        },
        handleChange2(value) {
            //右侧下拉change2
            let _this = this;
            this.selects.ids = {}
            value.forEach((item, idx) => {
                if (typeof _this.selects.ids[item[2]] == 'undefined') {
                    _this.selects.ids[item[2]] = '';
                }
                _this.selects.ids[item[2]] += item[3] + '-'
            })
            this.reloadData();
        },
        objectSpanMethodStation({ row, column, rowIndex, columnIndex }) {
            let returnobj = null;
            let returnobj2 = null;
            let _this = this;
            if (columnIndex == 0 || columnIndex == 7 || columnIndex == 8) {
                // if (rowIndex == 5 || rowIndex == 6) {
                //     if (columnIndex == 7 || columnIndex == 8) {
                //         debugger;
                //     }
                // }
                if (row.hasOwnProperty("rowspan")) {
                    returnobj = {
                        rowspan: row.rowspan,
                        colspan: 1
                    };

                } else {
                    returnobj = {
                        rowspan: 0,
                        colspan: 0
                    };
                }

                return returnobj;

            }

        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            let returnobj = null;
            let returnobj2 = null;
            let _this = this;
            if (columnIndex == 0) {
                this.col1IdxArr.forEach((item, idx) => {
                    if (rowIndex == item) {
                        returnobj = {
                            rowspan: _this.col1Arr[idx],
                            colspan: 1
                        };

                    } else {
                        returnobj = {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                })
                return returnobj;

            }
            if (columnIndex == 1) {
                let hasColspan = false;
                this.col2IdxArr.forEach((item, idx) => {
                    if (!hasColspan) {
                        returnobj2 = {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                    if (rowIndex == item) {
                        hasColspan = true;
                        returnobj2 = {
                            rowspan: _this.col2Arr[idx],
                            colspan: 1
                        };
                    }
                })
                return returnobj2;
            }
        },
        objectSpanMethod2({ row, column, rowIndex, columnIndex }) {
            let returnobj = null;
            let returnobj2 = null;
            let returnobj3 = null;
            let _this = this;
            if (columnIndex == 0) {
                this.tab2col1IdxArr.forEach((item, idx) => {
                    if (rowIndex == item) {
                        returnobj = {
                            rowspan: _this.tab2col1Arr[idx],
                            colspan: 1
                        };

                    } else {
                        returnobj = {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                })
                return returnobj;

            }
            if (columnIndex == 1) {
                let hasColspan = false;
                this.tab2col2IdxArr.forEach((item, idx) => {
                    if (!hasColspan) {
                        returnobj2 = {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                    if (rowIndex == item) {
                        hasColspan = true;
                        returnobj2 = {
                            rowspan: _this.tab2col2Arr[idx],
                            colspan: 1
                        };
                    }
                })
                return returnobj2;
            }
            if (columnIndex == 2) {
                let hasColspan = false;
                this.tab2col3IdxArr.forEach((item, idx) => {
                    if (!hasColspan) {
                        returnobj3 = {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                    if (rowIndex == item) {
                        hasColspan = true;
                        returnobj3 = {
                            rowspan: _this.tab2col3Arr[idx],
                            colspan: 1
                        };
                    }
                })
                return returnobj3;
            }
        },
        objectSpanMethod3({ row, column, rowIndex, columnIndex }) {
            let returnobj = null;
            let returnobj2 = null;
            let _this = this;
            if (columnIndex == 0) {
                this.tab3col1IdxArr.forEach((item, idx) => {
                    if (rowIndex == item) {
                        returnobj = {
                            rowspan: _this.tab3col1Arr[idx],
                            colspan: 1
                        };

                    } else {
                        returnobj = {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                })
                return returnobj;

            }
            if (columnIndex == 1) {
                let hasColspan = false;
                this.tab3col2IdxArr.forEach((item, idx) => {
                    if (!hasColspan) {
                        returnobj2 = {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                    if (rowIndex == item) {
                        hasColspan = true;
                        returnobj2 = {
                            rowspan: _this.tab3col2Arr[idx],
                            colspan: 1
                        };
                    }
                })
                return returnobj2;
            }
        },
        fillList1(city_id, page) {
            let _this = this;
            let params = this.buildParams();
            //params.page = page;
            this.col1Arr = []; //第一列数量和
            this.col2Arr = []; //第二列数量和
            this.col1IdxArr = []; //第一列序号
            this.col2IdxArr = []; //第二列序号
            this.col1Last = ''; //第一行最后一项
            this.col2Last = ''; //第二行最后一项

            this.tab2col1Arr = []; //第一列数量和
            this.tab2col2Arr = []; //第二列数量和
            this.tab2col3Arr = []; //第三列数量和
            this.tab2col1IdxArr = []; //第一列序号
            this.tab2col2IdxArr = []; //第二列序号
            this.tab2col3IdxArr = []; //第三列序号
            this.tab2col1Last = ''; //第一行最后一项
            this.tab2col2Last = ''; //第二行最后一项
            this.tab2col3Last = ''; //第三行最后一项

            this.tab3col1Arr = []; //第一列数量和
            this.tab3col2Arr = []; //第二列数量和
            this.tab3col1IdxArr = []; //第一列序号
            this.tab3col2IdxArr = []; //第二列序号
            this.tab3col1Last = ''; //第一行最后一项
            this.tab3col2Last = ''; //第二行最后一项
            getList1(this.buildParams(1)).then(res => {
                //this.data = res.data;
                let header = res.data.header;
                let body = res.data.body;
                let data = [];
                if (body) {
                    body.forEach((item, idx) => {
                        let newitem = {}
                        item.forEach((subitem, subidx) => {
                            newitem[subidx] = subitem
                        })
                        data.push(newitem)
                    })
                }

                this.listheader = header;
                this.data = data;

            });

            getList2(this.buildParams(2)).then(res => {
                //this.data = res.data;
                let header = res.data.header;
                let body = res.data.body;
                let data = [];
                if (body) {
                    body.forEach((item, idx) => {
                        let newitem = {}
                        item.forEach((subitem, subidx) => {
                            newitem[subidx] = subitem
                        })
                        data.push(newitem)
                    })
                }

                this.listheader2 = header;
                this.data2 = data;

                this.data2.forEach((item, idx) => {
                    if (item['0'] == _this.tab2col1Last) {
                        _this.tab2col1Arr[_this.tab2col1Arr.length - 1]++;
                    } else {
                        _this.tab2col1Arr.push(1);
                        _this.tab2col1IdxArr.push(idx);
                        _this.tab2col1Last = item['0']
                    }

                    if (item['1'] == _this.tab2col2Last) {
                        _this.tab2col2Arr[_this.tab2col2Arr.length - 1]++;
                    } else {
                        _this.tab2col2Arr.push(1);
                        _this.tab2col2IdxArr.push(idx);
                        _this.tab2col2Last = item['1']
                    }

                    if (item['2'] == _this.tab2col3Last) {
                        _this.tab2col3Arr[_this.tab2col3Arr.length - 1]++;
                    } else {
                        _this.tab2col3Arr.push(1);
                        _this.tab2col3IdxArr.push(idx);
                        _this.tab2col3Last = item['2']
                    }

                })

            });

            getList2(this.buildParams(3)).then(res => {
                //this.data = res.data;
                let header = res.data.header;
                let body = res.data.body;
                let data = [];
                if (body) {
                    body.forEach((item, idx) => {
                        let newitem = {}
                        item.forEach((subitem, subidx) => {
                            newitem[subidx] = subitem
                        })
                        data.push(newitem)
                    })
                }

                this.listheader3 = header;
                this.data3 = data;



                this.data3.forEach((item, idx) => {
                    if (item['0'] == _this.tab3col1Last) {
                        _this.tab3col1Arr[_this.tab3col1Arr.length - 1]++;
                    } else {
                        _this.tab3col1Arr.push(1);
                        _this.tab3col1IdxArr.push(idx);
                        _this.tab3col1Last = item['0']
                    }

                    if (item['1'] == _this.tab3col2Last) {
                        _this.tab3col2Arr[_this.tab3col2Arr.length - 1]++;
                    } else {
                        _this.tab3col2Arr.push(1);
                        _this.tab3col2IdxArr.push(idx);
                        _this.tab3col2Last = item['1']
                    }

                })
            });

            this.downloadUrl = consts.requestUrl + consts.exportFire2StationGrid + parseJsonToString(this.buildParams(1))


        },
        fillStationList(isLoaded) {
            getStations(this.buildParams()).then(res => {
                this.stations = res.data.stations
                this.stations.forEach((item, idx) => {
                    item.list1IdxArr = [];
                    item.list2IdxArr = []
                    item.list3IdxArr = []
                    item.list1Arr = [];
                    item.list2Arr = []
                    item.list3Arr = []
                    item.list1Last = '';
                    item.list2Last = ''
                    item.list3Last = ''
                    item.protect_equipments.forEach((subitem, subidx) => {
                        if (subitem.c1 == item.list1Last) {
                            item.list1Arr[item.list1Arr.length - 1]++;
                        } else {
                            item.list1Arr.push(1);
                            item.list1IdxArr.push(subidx);
                            item.list1Last = subitem.c1
                        }
                    })
                    item.rescue_equipments.forEach((subitem, subidx) => {
                        if (subitem.c1 == item.list2Last) {
                            item.list2Arr[item.list2Arr.length - 1]++;
                        } else {
                            item.list2Arr.push(1);
                            item.list2IdxArr.push(subidx);
                            item.list2Last = subitem.c1
                        }
                    })
                    item.extinguisher_equipments.forEach((subitem, subidx) => {
                        if (subitem.c1 == item.list3Last) {
                            item.list3Arr[item.list3Arr.length - 1]++;
                        } else {
                            item.list3Arr.push(1);
                            item.list3IdxArr.push(subidx);
                            item.list3Last = subitem.c1
                        }
                    })

                })



                this.stations.forEach((item, idx) => {
                    item.list1IdxArr.forEach((subitem, subidx) => {
                        item.protect_equipments.forEach((subitem2, subidx2) => {
                            if (subitem == subidx2) {
                                subitem2.rowspan = item.list1Arr[subidx]
                            }
                        })
                    })
                    item.list2IdxArr.forEach((subitem, subidx) => {
                        item.rescue_equipments.forEach((subitem2, subidx2) => {
                            if (subitem == subidx2) {
                                subitem2.rowspan = item.list2Arr[subidx]
                            }
                        })
                    })
                    item.list3IdxArr.forEach((subitem, subidx) => {
                        item.extinguisher_equipments.forEach((subitem2, subidx2) => {
                            if (subitem == subidx2) {
                                subitem2.rowspan = item.list3Arr[subidx]
                            }
                        })
                    })
                })




                if (!isLoaded) {
                    this.setCards();
                    this.curCardIdx = 0


                    this.stationlist = {
                        protect_equipments: [],
                        rescue_equipments: [],
                        extinguisher_equipments: [],
                        protect_equipments_kinds: {
                            compliance_kinds: 0,
                            total_kinds: 0
                        },
                        rescue_equipments_kinds: {
                            compliance_kinds: 0,
                            total_kinds: 0
                        },
                        extinguisher_equipments_kinds: {
                            compliance_kinds: 0,
                            total_kinds: 0
                        }
                    }
                    // let protect_equipments_kinds = []
                    // let rescue_equipments_kinds = []
                    // let extinguisher_equipments_kinds = []
                    let protect_equipments_kinds = 0
                    let rescue_equipments_kinds = 0
                    let extinguisher_equipments_kinds = 0
                    this.stations.forEach((item) => {
                        item.protect_equipments.forEach((subitem, subidx) => {
                            subitem.c9 = item.station_name;
                        })
                        item.rescue_equipments.forEach((subitem, subidx) => {
                            subitem.c9 = item.station_name;
                        })
                        item.extinguisher_equipments.forEach((subitem, subidx) => {
                            subitem.c9 = item.station_name;
                        })
                        this.stationlist.protect_equipments = this.stationlist.protect_equipments.concat(item.protect_equipments)
                        this.stationlist.rescue_equipments = this.stationlist.rescue_equipments.concat(item.rescue_equipments)
                        this.stationlist.extinguisher_equipments = this.stationlist.extinguisher_equipments.concat(item.extinguisher_equipments)
                        this.stationlist.protect_equipments_kinds.compliance_kinds += item.c5
                        //this.stationlist.c6 += item.c6
                        this.stationlist.rescue_equipments_kinds.compliance_kinds += item.c7
                        //this.stationlist.c8 += item.c8
                        //this.stationlist.c9 += item.c9
                        this.stationlist.extinguisher_equipments_kinds.compliance_kinds += item.c9
                        //this.stationlist.c10 += item.c10

                        // protect_equipments_kinds.push(item.c6)
                        // rescue_equipments_kinds.push(item.c8)
                        // extinguisher_equipments_kinds.push(item.c10)
                        protect_equipments_kinds += item.c6
                        rescue_equipments_kinds += item.c8
                        extinguisher_equipments_kinds += item.c10
                    })
                    console.log(this.stationlist)
                    //console.log(this.stationlist)
                    let count = 0;
                    this.stationlist.protect_equipments.reduce((prev, cur) => {
                        if (prev.rowspan != 2) {
                            cur.c100 = ++count
                        }
                        return cur
                    }, { rowspan: 0 })

                    count = 0
                    this.stationlist.rescue_equipments.reduce((prev, cur) => {
                        if (prev.rowspan != 2) {
                            cur.c100 = ++count
                        }
                        return cur
                    }, { rowspan: 0 })

                    count = 0
                    this.stationlist.extinguisher_equipments.reduce((prev, cur) => {
                        if (prev.rowspan != 2) {
                            cur.c100 = ++count
                        }
                        return cur
                    }, { rowspan: 0 })
                    // this.stationlist.protect_equipments_kinds.total_kinds = Math.max(...protect_equipments_kinds)
                    // this.stationlist.rescue_equipments_kinds.total_kinds = Math.max(...rescue_equipments_kinds)
                    // this.stationlist.extinguisher_equipments_kinds.total_kinds = Math.max(...extinguisher_equipments_kinds)
                    this.stationlist.protect_equipments_kinds.total_kinds = protect_equipments_kinds
                    this.stationlist.rescue_equipments_kinds.total_kinds = rescue_equipments_kinds
                    this.stationlist.extinguisher_equipments_kinds.total_kinds = extinguisher_equipments_kinds
                }
            });
        },
        setCards() {
            //卡片分布在椭圆形上，以90度为第一张卡的位置，以此类推
            let cardCount = this.stations.length;
            let degree = parseInt(360 / cardCount)
            this.posArr = [] //每个位置的坐标
            let allpos = this.getCPoint({
                a: 140,
                b: 40
            }) //椭圆上所有点的坐标，间隔1度
            this.stations.forEach((item, idx) => {
                let deg = (90 + degree * idx) % 360 //不超过360度
                this.posArr.push({
                    x: allpos[deg].value[0],
                    y: allpos[deg].value[1],
                    zidx: parseInt(allpos[deg].value[1] + 100)
                })
            })
        },
        fill2Map(city_id) {
            let params = this.buildParams();
            getCommunityData(params).then(res => {
                getGeo1(params).then(res2 => {
                    let data = res2;
                    let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
                    let communitiesgeo = { "type": "FeatureCollection", "features": [] };
                    geoJson.features.forEach((item, idx) => {
                        communitiesgeo.features.push({
                            type: "Feature",
                            geometry: {
                                type: 'Point', coordinates: [item.properties.lng, item.properties.lat]
                            },
                            properties: item.properties
                        })
                    })
                    this.map_data = res.data.map_data;

                    this.addBlockmapLayer(this.map, 'block', geoJson, res.data.map_data);
                    this.fillPointLayer();
                    this.addTextLayerToMap(this.map, communitiesgeo); //消防站的点
                });
            });
        },
        addStrokemapLayer(map, type, geojson) {
            let _this = this;
            this.removeMapLayer(map, type);
            let source_name = this.getMapSourceName(type);
            this.source_name = source_name
            let layer_id = this.getMapLayerId(type);
            if (typeof geojson.features == 'undefined') return;



            map.addSource(source_name, {
                type: "geojson",
                data: geojson,
            });
            //描边
            map.addLayer({
                id: layer_id,
                type: "line",
                source: source_name,
                layout: {
                    "line-join": "round",
                    "line-cap": "round"
                },
                paint: {
                    "line-color": "#ffffff",
                    "line-width": 3,
                    "line-dasharray": [2, 4]
                }
            });


        },
        addBlockmapLayer(map, type, geojson, mapdata) {
            let _this = this;
            this.removeMapLayer(map, type);
            let source_name = this.getMapSourceName(type);
            this.source_name = source_name
            let layer_id = this.getMapLayerId(type);
            if (typeof geojson.features == 'undefined') return;
            //map.off('click', layer_id, this.mapClickEvent2);

            geojson.features.forEach((item, idx) => {
                let station = mapdata[item.properties.id]
                item.properties.val = item.properties.name + '<br>';
                station.forEach((subitem, subidx) => {
                    item.properties.val += subitem.name + ": " + subitem.value + "<br>";
                })
            })

            let layer_id_stroke = layer_id + '_stroke';
            map.addSource(source_name, {
                type: "geojson",
                data: geojson,
                generateId: true
            });
            let colorExpress = '';
            let strokeColor = '#ffffff'
            let fillOpacity = 1;


            map.addLayer({
                id: layer_id,
                type: "fill",
                source: source_name,
                layout: {},
                paint: {
                    "fill-opacity": 0,
                }
            });

            //描边
            map.addLayer({
                id: layer_id_stroke,
                type: "line",
                source: source_name,
                layout: {},
                paint: {
                    "line-color": strokeColor,
                    "line-width": 1
                }
            });

            this.hoveredStateId = null;
            map.on('mousemove', layer_id, this.mapMouseMoveBlock);
            map.on('mouseleave', layer_id, this.mapMouseLeaveBlock);



        },
        fillBuildingsMap() {
            let _this = this;
            let params = this.buildParams();
            //params.color_category = 'struct';
            getStrokeGeoJson(params).then(res => {
                //加载轮廓
                let data = res;
                _this.strokegeoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
                _this.addStrokemapLayer(_this.map, 'stationstroke', _this.strokegeoJson);

            });

        },
        reloadData(isLoaded) {
            //this.fillList1()
            this.fillStationList(isLoaded)
            this.fill2Map(this.city_id);
        },
        setTipContent() {
            let _this = this;
            let html = ''
            getInfo({
                "module_id": this.mid,
            }).then(res => {
                document.getElementById('left-tip-content').innerHTML = res.data.info;
                this.types = res.data.types
            });
        },
        buildParams(type_id) {
            let params = { city_id: this.city_id };
            params.town_id = this.selects.town_id;
            if (this.selects.filter_street_id === 0) {
                //地图没有筛选街道,用select的街道
                params.street_id = this.selects.street_id;
            } else {
                //地图点击了街道
                params.street_id = this.selects.filter_street_id;
            }
            //params.street_id = this.selects.street_id;
            //params.module_id = this.mid;
            if (type_id) {
                params.type_id = type_id;
            } else {
                params.type_id = this.type_id;
            }
            params.module_id = this.mid;

            Object.assign(params, {

            }, this.selects.ids)
            //Object.assign(params, this.selects.ids)

            return params;
        },
        changePage: function (value) {
            if (value != this.page) {
                this.loadData(this.selects.data_category, value);
            }
            this.page = value;
        },
        fillPointLayer() {
            let params = this.buildParams();
            getMapData(params).then(res => {
                getGeo2(params).then(res2 => {
                    let data = res2;
                    let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
                    this.addPointLayerToMap(this.map, geoJson, res.data.map_data); //消防站的点

                });
            });

            // getStreetGeo(params).then(data => {
            //   let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
            //   //this.addBlockmapLayer(this.map,'region')
            //   this.addStreetLayerToMap(this.map, geoJson);
            // });

        },
        makePointImage(map, type) {
            let size = 180;
            switch (type) {
                case 1:
                    size = 190;
                    break;
                case 2:
                    size = 150;
                    break;
                case 3:
                    size = 110;
                    break;
            }
            //point type
            let pulsingDot = {
                width: size,
                height: size,
                data: new Uint8Array(size * size * 4),

                // get rendering context for the map canvas when layer is added to the map
                onAdd: function () {
                    var canvas = document.createElement("canvas");
                    canvas.width = this.width;
                    canvas.height = this.height;
                    this.context = canvas.getContext("2d");
                },

                // called once before every frame where the icon will be used
                render: function () {
                    var duration = 1000;
                    var t = (performance.now() % duration) / duration;

                    var radius = (size / 2) * 0.3;
                    var outerRadius = (size / 2) * 0.7 * t + radius;
                    var context = this.context;

                    // draw outer circle
                    context.clearRect(0, 0, this.width, this.height);
                    context.beginPath();
                    context.arc(
                        this.width / 2,
                        this.height / 2,
                        outerRadius,
                        0,
                        Math.PI * 2
                    );
                    // switch(type){
                    //   case 1:
                    //     context.fillStyle = "rgba(255, 192, 0," + (1 - t) + ")";
                    //   break;
                    //   case 2:
                    //     context.fillStyle = "rgba(237, 125, 49," + (1 - t) + ")";
                    //   break;
                    //   case 3:
                    //     context.fillStyle = "rgba(255, 0, 0," + (1 - t) + ")";
                    //   break;
                    // }
                    context.fillStyle = "rgba(3, 116, 253," + (1 - t) + ")";

                    context.fill();

                    // draw inner circle
                    context.beginPath();
                    context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
                    // switch(type){
                    //   case 1:
                    //     context.fillStyle = "rgba(255, 192, 0, 1)";
                    //   break;
                    //   case 2:
                    //     context.fillStyle = "rgba(237, 125, 49, 1)";
                    //   break;
                    //   case 3:
                    //     context.fillStyle = "rgba(255, 100, 100, 1)";
                    //   break;
                    // }
                    //context.fillStyle = "rgba(3, 116, 253, 1)";
                    context.fillStyle = "rgba(3, 116, 253, 1)";

                    context.strokeStyle = "white";
                    context.lineWidth = 2 + 4 * (1 - t);
                    context.fill();
                    //context.stroke();
                    // update this image's data with data from the canvas
                    this.data = context.getImageData(0, 0, this.width, this.height).data;

                    // continuously repaint the map, resulting in the smooth animation of the dot
                    map.triggerRepaint();

                    // return `true` to let the map know that the image was updated
                    return true;
                }
            }
            map.addImage("dot-" + type, pulsingDot, { pixelRatio: 2 });
        },
        addPointLayerToMap(map, geojson, mapdata) {
            //let type = this.selects.data_category
            var layer = map.getLayer('points');
            if (layer) {
                map.removeLayer('points');
                map.removeSource('points');
            }

            if (typeof geojson.features == "undefined") return;
            //map.off('click', 'points', this.mapClickEvent);
            let imgIcon = [];
            if (!map.hasImage("dot-1")) {
                this.makePointImage(map, 1) //1000-2000
                this.makePointImage(map, 2) //2000-3000
                this.makePointImage(map, 3) //>3000

            }
            geojson.features.forEach((item, idx) => {
                if (item.properties.type == "一级站") {
                    item.properties.newtype = 1
                } else if (item.properties.type == "二级站") {
                    item.properties.newtype = 2
                } else if (item.properties.type == "小型站") {
                    item.properties.newtype = 3
                }
                let station = mapdata.filter((subitem, subidx) => {
                    return subitem.id == item.properties.id
                })

                item.properties.val = station[0].name + '<br>';
                station[0].value.forEach((subitem, subidx) => {
                    item.properties.val += subitem.name + ": " + subitem.value + "<br>";
                })

            })
            imgIcon = ["concat", "dot-", ["get", "newtype"]];



            map.addSource("points", {
                type: "geojson",
                data: geojson,
            }
            );
            map.addLayer({
                id: "points",
                type: "symbol",
                source: "points",
                paint: {
                    "text-color": "#fff"
                },
                layout: {
                    "icon-image": imgIcon,
                    "text-field": ["get", "name"],
                    "icon-size": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        5, //zoom
                        0, //icon-size
                        10,
                        1
                    ],
                    "text-size": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        5, //zoom
                        0, //font-size
                        10,
                        12
                    ],
                    "text-offset": [0, -3],
                    "text-anchor": "top",
                    "icon-allow-overlap": true,
                    "text-allow-overlap": true
                }
            });
            //map.on('click', 'points', this.mapClickEvent);
            map.on('mousemove', 'points', this.mapMouseEnter);
            map.on('mouseleave', 'points', this.mapMouseLeave);
        },
        addTextLayerToMap(map, geojson) {
            //let type = this.selects.data_category
            var layer = map.getLayer('communitypoints');
            if (layer) {
                map.removeLayer('communitypoints');
                map.removeSource('communitypoints');
            }

            if (typeof geojson.features == "undefined") return;

            map.addSource("communitypoints", {
                type: "geojson",
                data: geojson,
            }
            );
            map.addLayer({
                id: "communitypoints",
                type: "symbol",
                source: "communitypoints",
                paint: {
                    "text-color": "#fff"
                },
                layout: {
                    "icon-image": '',
                    "text-field": ["get", "name"],
                    "text-size": 12,
                    "text-offset": [0, 0],
                    "text-anchor": "top",
                    "icon-allow-overlap": true,
                    "text-allow-overlap": false
                }
            });

        },
        mapMouseEnter(e) {
            if (this.popup) {
                this.popup.remove()
            }
            this.popup = new mapboxgl.Popup({ closeOnClick: false, className: 'map-popup' })
                .setLngLat(e.features[0].geometry.coordinates)
                .setHTML(e.features[0].properties.val)
                .addTo(this.map);
        },
        mapMouseLeave(e) {
            if (this.popup) {
                this.popup.remove()
            }
        },

        mapMouseMoveBlock(e) {
            if (!this.map_data) return;
            let val = e.features[0].properties.val;
            if (this.popup) {
                //this.popup.remove()
                this.popup.setLngLat(e.lngLat).setHTML(val).addTo(this.map);
            } else {
                this.popup = new mapboxgl.Popup({ closeOnClick: false, className: 'map-popup' })
                    .setLngLat(e.lngLat)
                    .setHTML(val)
                    .addTo(this.map);
            }


        },
        mapMouseLeaveBlock(e) {
            if (this.popup) {
                this.popup.remove()
            }
        },
        mapClickEvent(e, stationId) {
            //点击消防站
            let _this = this;
            if (!!e && e.features.length == 0) {
                return;
            }
            if (!stationId) {
                stationId = e.features[0].properties.id;
            }
            var layer = this.map.getLayer('staionstroke');
            if (layer) {
                this.map.removeLayer('staionstroke');
                this.map.removeSource('staionstroke');
            }
            if (this.stationId == stationId) {
                this.stationId = -1;
                return;
            }
            let newfeatures = this.strokegeoJson.features.filter((item, idx) => {
                return item.properties.id == stationId
            });
            this.stationId = stationId;
            let curStrokeGeoJson = {};
            Object.assign(curStrokeGeoJson, this.strokegeoJson);
            curStrokeGeoJson.features = newfeatures;


            this.map.addSource("staionstroke", {
                type: "geojson",
                data: curStrokeGeoJson,
            });
            this.map.addLayer({
                id: 'staionstroke',
                type: "line",
                source: 'staionstroke',
                layout: {
                    "line-join": "round",
                    "line-cap": "round"
                },
                paint: {
                    "line-color": "#ffffff",
                    "line-width": 3,
                    "line-dasharray": [2, 4]
                }
            });



        },
        mapClickEvent2(e) {
            let _this = this;
            if (e.features.length > 0) {
                if (_this.hoveredStateId !== null) {
                    _this.map.setFeatureState(
                        { source: _this.source_name, id: _this.hoveredStateId },
                        { hover: false }
                    );
                }
                if (e.features[0].id == _this.hoveredStateId) {
                    _this.hoveredStateId = null
                    _this.selects.filter_street_id = 0
                    _this.fillList1()
                    return
                };

                _this.hoveredStateId = e.features[0].id;
                _this.map.setFeatureState(
                    { source: _this.source_name, id: _this.hoveredStateId },
                    { hover: true }
                );
                //筛选列表
                _this.selects.filter_street_id = e.features[0].properties.street_id
                _this.fillList1()

            }
        },
        mapClickEventNew(feature, stationId) {
            //点击消防站
            let _this = this;

            if (!stationId) {
                stationId = feature.properties.id;
            }
            var layer = this.map.getLayer('staionstroke');
            if (layer) {
                this.map.removeLayer('staionstroke');
                this.map.removeSource('staionstroke');
            }
            if (this.stationId == stationId) {
                this.stationId = -1;
                return;
            }
            let newfeatures = this.strokegeoJson.features.filter((item, idx) => {
                return item.properties.id == stationId
            });
            this.stationId = stationId;
            let curStrokeGeoJson = {};
            Object.assign(curStrokeGeoJson, this.strokegeoJson);
            curStrokeGeoJson.features = newfeatures;


            this.map.addSource("staionstroke", {
                type: "geojson",
                data: curStrokeGeoJson,
            });
            this.map.addLayer({
                id: 'staionstroke',
                type: "line",
                source: 'staionstroke',
                layout: {
                    "line-join": "round",
                    "line-cap": "round"
                },
                paint: {
                    "line-color": "#ffffff",
                    "line-width": 3,
                    "line-dasharray": [2, 4]
                }
            });



        },
        mapClickEvent2New(feature) {
            let _this = this;
            if (_this.hoveredStateId !== null) {
                _this.map.setFeatureState(
                    { source: _this.source_name, id: _this.hoveredStateId },
                    { hover: false }
                );
            }
            if (feature.id == _this.hoveredStateId) {
                _this.hoveredStateId = null
                _this.selects.filter_street_id = 0
                _this.fillList1()
                return
            };

            _this.hoveredStateId = feature.id;
            _this.map.setFeatureState(
                { source: _this.source_name, id: _this.hoveredStateId },
                { hover: true }
            );
            //筛选列表
            _this.selects.filter_street_id = feature.properties.street_id
            _this.fillList1()

        },
        addStreetLayerToMap(map, geojson) {
            //let type = this.selects.data_category

            var layer = map.getLayer('streetpoints');
            if (layer) {
                map.removeLayer('streetpoints');
                map.removeSource('streetpoints');
            }

            if (typeof geojson.features == "undefined") return;


            map.addSource("streetpoints", {
                type: "geojson",
                data: geojson
            }
            );
            map.addLayer({
                id: "streetpoints",
                type: "symbol",
                source: "streetpoints",
                paint: {
                    "text-color": "#ffffff"
                },
                layout: {
                    //"icon-image": imgIcon,
                    "text-field": ["get", "name"],
                    "text-size": 12,
                    "text-offset": [0, -3],
                    "text-anchor": "top",
                    "icon-allow-overlap": true,
                    "text-allow-overlap": true
                }
            });
        },
        getMapLayerId(type) {
            let layer_id = type + '-layer';
            return layer_id;
        },
        getMapSourceName(type) {
            let source_name = type + "-data";
            return source_name;
        },
        removeMapLayer(map, type) {
            let source_name = this.getMapSourceName(type);
            let layer_id = this.getMapLayerId(type);
            if (type == 'streetpoints') {
                source_name = 'streetpoints';
                layer_id = 'streetpoints'
            }
            var layer = map.getLayer(layer_id);
            let layer_point_id = layer_id + "point";
            //描边
            var layer_stroke = map.getLayer(layer_id + '_stroke');
            if (layer_stroke) {
                map.removeLayer(layer_id + '_stroke');
            }
            if (layer) {
                map.removeLayer(layer_id);
            }
            if (map.getSource(source_name)) {
                map.removeSource(source_name);
            }
        },
        // changeRegin(type){
        //   //切换街道社区
        //   if(type == 'street'){
        //     //街道
        //     this.$router.push({
        //       path: '/fire/building/history/alarm'
        //     })
        //   } else if(type=='community') {
        //     //社区
        //     this.$router.push({
        //       path: '/fire/building/tp?mid='+this.mid
        //     })
        //   }
        // },
        changeType(val) {
            //切换左上角列表
            if (val == 1) {
                this.$router.push({ path: '/fire/building/stationgrid/2' })
            } else {
                this.$router.push({ path: '/fire/building/stationduration/2' })
            }
        },
        bindToShowDetail() {
            this.drawerOpened = !this.drawerOpened;

        },
        getCPoint({ a, b, p = 1, cx = 0, cy = 0, }) {
            //获得椭圆上的点
            // a 长半径， b 短半径， p 节点的间隔 ， cx, cy 圆心,
            const data = []
            for (let index = 0; index < 360; index = index + p) {
                let x = a * Math.cos(Math.PI * 2 * index / 360).toFixed(2)
                let y = b * Math.sin(Math.PI * 2 * index / 360).toFixed(2)
                data.push(...[
                    {
                        value: [x + cx, y + cy],
                    }
                ])
            }
            return data
        },
        cardEnter(e) {
            let idx = e.currentTarget.dataset.idx - 0;
            this.curCardIdx = idx;
        },
        dragstart(event) {
            event.dataTransfer.setData("dragContent", event.target);
        },
        drop(event) {
            event.preventDefault();
            let data = event.dataTransfer.getData('dragContent');
            event.target.appendChild(data)
        },
        dragover(event) {
            event.preventDefault();
        },
        autoOptimize() {
            this.type_id = 2;
            this.reloadData(1)
        },
        beforeOptimize() {
            this.type_id = 1;
            this.reloadData(1)
            //重置表格内容
            //this.stations = this.originStations
            //this.setCards();
            this.fillStationList()
        },
        addCustomItem(typeId) {
            //let dom = document.createElement('input')
            if (typeId == 1) {
                //基本防护装备
                let idx = this.stationlist.protect_equipments[this.stationlist.protect_equipments.length - 1].c100 + 1;
                this.stationlist.protect_equipments.push({
                    c2: '',
                    c3: '',
                    c4: '',
                    c5: '',
                    c6: '',
                    c7: '',
                    c8: '',
                    c9: '',
                    c100: idx,
                    rowspan: 1,
                    isNew: true,
                })
                document.querySelector('.station-custom .happy-scroll-container').scrollTop =
                    document.querySelector('.custom-1').offsetHeight - document.querySelector('.station-custom').offsetHeight + 150
            } else if (typeId == 2) {
                //抢险救援器材配备详情
                let idx = this.stationlist.rescue_equipments[this.stationlist.rescue_equipments.length - 1].c100 + 1;
                this.stationlist.rescue_equipments.push({
                    c2: '',
                    c3: '',
                    c4: '',
                    c5: '',
                    c6: '',
                    c7: '',
                    c8: '',
                    c9: '',
                    c100: idx,
                    rowspan: 1,
                    isNew: true,
                })
                document.querySelector('.station-custom .happy-scroll-container').scrollTop =
                    document.querySelector('.custom-1').offsetHeight + document.querySelector('.custom-2').offsetHeight - document.querySelector('.station-custom').offsetHeight + 270
            } else if (typeId == 3) {
                //抢险救援器材配备详情
                let idx = this.stationlist.extinguisher_equipments[this.stationlist.extinguisher_equipments.length - 1].c100 + 1;
                this.stationlist.extinguisher_equipments.push({
                    c2: '',
                    c3: '',
                    c4: '',
                    c5: '',
                    c6: '',
                    c7: '',
                    c8: '',
                    c9: '',
                    c100: idx,
                    rowspan: 1,
                    isNew: true,
                })
                document.querySelector('.station-custom .happy-scroll-container').scrollTop =
                    document.querySelector('.custom-1').offsetHeight + document.querySelector('.custom-2').offsetHeight + document.querySelector('.custom-3').offsetHeight - document.querySelector('.station-custom').offsetHeight + 390
            }

        },
        customEdit() {
            debugger;
            this.customEditing = !this.customEditing;
            document.querySelector('.custom-1 .happy-scroll-container').scrollLeft = 300
            //document.querySelector('.custom-1 .happy-scroll-content').offsetWidth

            document.querySelector('.custom-2 .happy-scroll-container').scrollLeft = 300
            //document.querySelector('.custom-2 .happy-scroll-content').offsetWidth
            document.querySelector('.custom-3 .happy-scroll-container').scrollLeft = 300
            //document.querySelector('.custom-3 .happy-scroll-content').offsetWidth

            if (!this.customEditing) {
                //非编辑状态
                let newStations = [...this.stations];
                newStations.forEach((item, idx) => {
                    item.protect_equipments = this.stationlist.protect_equipments.filter((subitem, subidx) => {
                        return subitem.c9 == item.station_name
                    })
                    item.rescue_equipments = this.stationlist.rescue_equipments.filter((subitem, subidx) => {
                        return subitem.c9 == item.station_name
                    })
                    item.extinguisher_equipments = this.stationlist.extinguisher_equipments.filter((subitem, subidx) => {
                        return subitem.c9 == item.station_name
                    })
                    //合计
                    let c2 = 0;
                    let c3 = 0;
                    let c4 = 0;
                    let newId = 0; //单元格合并的colspan的c1一致，不能直接根据subidx排序
                    item.c5 = 0;
                    item.c6 = 0;
                    item.protect_equipments.forEach((subitem, subidx) => {
                        subitem.c7 = subitem.c5 - subitem.c6 //现有-标准 = 缺配
                        subitem.c8 = parseInt(subitem.c7) >= 0 ? 1 : 0 //根据缺配判断是否符合
                        //序号修改
                        if (subitem.hasOwnProperty("rowspan")) {
                            subitem.c1 = ++newId
                            item.c6 = item.c6 + 1
                        } else {
                            subitem.c1 = newId
                        }
                        if (subitem.hasOwnProperty("rowspan") && subitem.c8 == 1) {
                            item.c5 = item.c5 + 1
                        }
                    })

                    newId = 0;
                    item.c7 = 0;
                    item.c8 = 0;
                    item.rescue_equipments.forEach((subitem, subidx) => {
                        subitem.c7 = subitem.c5 - subitem.c6 //现有-标准 = 缺配
                        subitem.c8 = parseInt(subitem.c7) >= 0 ? 1 : 0 //根据缺配判断是否符合
                        //序号修改
                        if (subitem.hasOwnProperty("rowspan")) {
                            subitem.c1 = ++newId
                            item.c8 = item.c8 + 1
                        } else {
                            subitem.c1 = newId
                        }
                        if (subitem.hasOwnProperty("rowspan") && subitem.c8 == 1) {
                            item.c7 = item.c7 + 1
                        }
                    })

                    newId = 0;
                    item.c9 = 0;
                    item.c10 = 0;
                    item.extinguisher_equipments.forEach((subitem, subidx) => {
                        subitem.c7 = subitem.c5 - subitem.c6 //现有-标准 = 缺配
                        subitem.c8 = parseInt(subitem.c7) >= 0 ? 1 : 0 //根据缺配判断是否符合
                        //序号修改
                        if (subitem.hasOwnProperty("rowspan")) {
                            subitem.c1 = ++newId
                            item.c10 = item.c10 + 1
                        } else {
                            subitem.c1 = newId
                        }
                        if (subitem.hasOwnProperty("rowspan") && subitem.c8 == 1) {
                            item.c9 = item.c9 + 1
                        }
                    })



                    //c1 人数不变
                    //c2 基本防护装备配备率
                    item.c2 = (item.c5 / item.c6 * 100).toFixed(2) + '%'
                    //c3 抢险救援器材配备率
                    item.c3 = (item.c7 / item.c8 * 100).toFixed(2) + '%'
                    //c4 灭火器材配备率
                    item.c4 = (item.c9 / item.c10 * 100).toFixed(2) + '%'

                })
                this.stationlist.c5 = 0
                this.stationlist.c7 = 0
                this.stationlist.c9 = 0

                newStations.forEach((item) => {

                    this.stationlist.c5 += item.c5
                    this.stationlist.c7 += item.c7
                    this.stationlist.c9 += item.c9

                })
                let tmp = []
                this.stationlist.protect_equipments.forEach((item, idx) => {
                    if (item.hasOwnProperty("rowspan")) {
                        tmp.push(item.c2)
                    }
                })
                this.stationlist.c6 = Array.from(new Set(tmp)).length
                tmp = []
                this.stationlist.rescue_equipments.forEach((item, idx) => {
                    if (item.hasOwnProperty("rowspan")) {
                        tmp.push(item.c2)
                    }
                })
                this.stationlist.c8 = Array.from(new Set(tmp)).length
                tmp = []
                this.stationlist.extinguisher_equipments.forEach((item, idx) => {
                    if (item.hasOwnProperty("rowspan")) {
                        tmp.push(item.c2)
                    }
                })
                this.stationlist.c10 = Array.from(new Set(tmp)).length

                this.stations = newStations;
                this.setCards()

            }
        },
    },

};
</script>
<style lang="scss" >
.el-table__row td {
    border-right: 1px solid #eee;
}

.el-table__body-wrapper {
    background: #394761;
}

.options1 .el-cascader .el-input__inner {
    height: 50px;
}



/*.el-table__body-wrapper::-webkit-scrollbar{z-index:11;width:6px}
     .el-table__body-wrapper::-webkit-scrollbar:horizontal{height:6px}
     .el-table__body-wrapper::-webkit-scrollbar-thumb{border-radius:5px;width:6px;background:#b4bccc}
     .el-table__body-wrapper::-webkit-scrollbar-corner,.el-table__body-wrapper::-webkit-scrollbar-track{background:#fff}
     .el-table__body-wrapper::-webkit-scrollbar-track-piece{background:#fff;width:6px}*/
</style>
<style lang="scss" scoped>
.card-map {

    overflow: hidden;

    .radio-group {
        position: absolute;
        left: 10px;
        top: 10px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 5px;
        padding: 10px;
    }

    .radio-group div {
        padding: 0 10px;
    }

    .radio-group div input {
        vertical-align: -1px;
        padding-right: 2px;
    }
}



.drawer {
    width: 30px;
    position: absolute;
    right: 10px;
    background: rgba(40, 61, 79, 0.7);
    top: 310px;
    color: #c9c4c4;
    height: 80px;
    font-size: 20px;
    line-height: 80px;
    text-align: center;
    z-index: 11;
}

.drawer-wrapper {
    width: 100%;
    height: -webkit-calc(100% - 130px);
    position: absolute;
    right: 0;
    bottom: 70px;
    z-index: 10;
    transform: translate(100%);
    transition: transform 0.4s ease-in;


    &.open {
        transform: translate(0);
    }

    .drawer-inner {
        background: rgba(0, 0, 0, 0.7);
        height: 100%;
        margin: 0 60px 0 10px;
        padding: 20px;
    }

    .tabs {
        div {
            background: #457e87;
            padding: 5px 10px;
            border-radius: 5px;
            display: inline;
            margin-right: 10px;
            color: #fff;
            border: 1px solid #ccc;

            &.cur {
                border: 2px solid #eee;
            }
        }

    }

    .station-list {
        position: absolute;
        top: 100px;
        left: 11vw;
        width: 500px;
        height: 100%;
    }

    .station-wrapper {
        width: 24vw;
        height: 300px;
        height: -webkit-calc(100% - 200px);
        position: absolute;
        border: 1px solid #666;
        transition: all linear 0.4s;
        box-shadow: 0 0 10px #333;
        background: rgba(37, 86, 125, 0.9);
        padding-top: 20px;
    }

    .station-title {
        width: 100%;
        height: 20px;
        line-height: 20px;
        background: #457e87;
        font-size: 12px;
        color: #fff;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
    }

    .station-content {
        // height: 300px;
        width: -webkit-calc(24vw - 2px);
        // overflow: auto;
        // background: rgba(37, 86, 125, 0)
    }

    .table-station-info {
        // background: rgba(37, 86, 125, 0.9);
        width: 100%;

        td {
            font-size: 12px;
            color: #fff;
            padding: 10px;
        }

        td,
        th {
            border-right: 1px solid rgba(255, 255, 255, 0.1) !important;
        }

        /**
      改变表格内行线颜色
      */
        td,
        th.is-leaf {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
        }
    }


}

.station-custom {
    left: 43.5vw;
    top: 70px;

    .table-title {
        width: -webkit-calc(24vw - 2px);
    }

    .table-station-sublist {
        width: -webkit-calc(24vw - 2px);
    }
}

.btn-add-station {
    position: absolute;
    left: 10px;
    bottom: 10px;
    color: #ccc;
    padding: 4px 10px;
    background: rgba(40, 61, 79, 0.7);
    border-radius: 2px;

    i {
        color: #333;
        background: #fff;
        border-radius: 2px;

    }
}

.btn-add-car {
    position: absolute;
    right: 70px;
    bottom: 50px;
    color: #ccc;
    padding: 4px 10px;
    background: rgba(40, 61, 79, 0.7);
    border-radius: 2px;

    i {
        color: #333;
        background: #fff;
        border-radius: 2px;

    }

    &.btn-add-car-1 {
        right: 360px;
    }

    &.btn-add-car-2 {
        right: 200px;
    }
}

.btn-optimize,
.btn-custom-optimize,
.btn-reset {
    background: #f49a28;
    border-radius: 5px;
    color: #fff;
    position: absolute;
    bottom: 14px;
    right: 240px;
    padding: 2px 10px;
    font-size: 12px;
}

.btn-custom-optimize {
    right: 130px;
}

.btn-reset {
    right: 70px;
}

.table-title {
    width: 100%;
    line-height: 20px;
    // background: rgba(37, 86, 125, 0.9);
    text-align: center;
    color: #fff;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    font-size: 12px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.table-total {
    width: 100%;
    line-height: 20px;
    // background: rgba(37, 86, 125, 0.9);
    text-align: center;
    color: #fff;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    font-size: 12px;
    display: flex;


    div:nth-of-type(1) {
        width: 70%;
        border-right: 1px solid rgba(255, 255, 255, 0.1);
        padding: 5px 0;
    }

    div:nth-of-type(2) {
        padding: 5px 10px;
    }
}

/deep/.table-station-sublist {
    width: 100%;
    position: static;
    // background: rgba(37, 86, 125, 0.9) !important;

    th.el-table__cell {
        font-size: 12px !important;
        line-height: 20px;
        background: rgba(43, 93, 127, 0);
        padding: 0;


    }

    .el-table__header th {
        background: rgba(48, 61, 79, 0.5)
    }

    .el-table__row:hover {
        background: rgba(69, 126, 135, 0.5)
    }

    .cell {
        line-height: 100%;
        color: #fff;
        font-size: 12px;
        font-family: sans-serif;
        font-weight: 400;
        padding: 0 10px;
        height: 40px;
        line-height: 40px;

        input,
        select {
            width: 100%;
            height: 20px;
            background: rgba(48, 61, 79, .5);
            color: #fff;
            border: 0;
            padding: 0 10px;
            box-shadow: inset 2px 2px 2px #333;
            box-sizing: border-box;
        }
    }

    td.el-table__cell {
        font-size: 12px !important;
        line-height: 20px;
        background: rgba(43, 93, 127, 0);
        padding: 0;
    }

    tr {
        background: rgba(43, 93, 127, 0);
    }

    &.el-table,
    .el-table--group {
        border: 1px solid rgba(255, 255, 255, 0.1) !important;
    }

    /**
    改变表格内竖线颜色
    */
    &.el-table td,
    &.el-table th,
    .el-table__body-wrapper &.el-table.is-scrolling-left~.el-table__fixed {
        border-right: 1px solid rgba(255, 255, 255, 0.1) !important;
    }

    /**
    改变表格内行线颜色
    */
    &.el-table td,
    &.el-table th.is-leaf {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
    }

    &.el-table thead tr th {
        border-color: #8c939d;
    }

    .el-table__body-wrapper {
        background: transparent;
    }

    &.el-table::before {
        background-color: rgba(255, 255, 255, 0.1) !important;
    }
}

/deep/.happy-scroll-content {
    border-right: 20px solid transparent;
    border-bottom: 20px solid transparent;
}

/deep/.hss .happy-scroll-container {
    height: auto !important;
}

::-webkit-input-placeholder {
    color: #fff !important;
}
</style>