<template>
  <div class="row" style="margin:0 -30px;">
    <div class="col-md-9" style="padding:0">
      <card class="card-map">
        <div id="map"></div>
        <menu-map :map="map" :mapDefault="mapdefaultSetting" showDisaster="false"></menu-map>
        <div class="map_data_msg">
          <div class="map_data_legend">修建年代</div>
          <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
            <div
              class="item"
              v-for="(item,index) in buildingStructs"
              :key="index"
            >
              <span :style="'background: '+item.color+''"></span>
              {{item.name}}
            </div>
          </div>
        </div>
        <div class="show_list">
          <a class="up_show" v-on:click="showList">
            <i
              class="now-ui-icons"
              :class="[ show_list ? 'arrows-1_minimal-down':'arrows-1_minimal-up']"
            ></i>
          </a>
          <a class="download"  :href="downloadUrl">
            <i class="now-ui-icons arrows-1_cloud-download-93"></i>
          </a>
          <span>
            建筑数据列表
            <i>{{total}}</i>
          </span>
        </div>
        <div
          class="show_list_record"
          v-show="show_list"
          :style="{'margin-top':-(126+data.length*38)+'px'}"
          ref="show_list_record"
        >
          <el-table :data="data">
            <el-table-column min-width="150" label="建筑ID" property="building_id"></el-table-column>
            <!-- <el-table-column min-width="150" label="经度" property="lng"></el-table-column>
            <el-table-column min-width="150" label="纬度" property="lat"></el-table-column> -->
            <el-table-column min-width="150" label="街道" property="street_name"></el-table-column>
            <el-table-column min-width="150" label="社区" property="community_name"></el-table-column>
            <el-table-column min-width="150" label="修建年代" property="build_year"></el-table-column>
          </el-table>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class>
              <p class="card-category">显示： {{from + 1}} - {{to}} 总数： {{total}}</p>
            </div>
            <n-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              v-on:input="changePage"
            ></n-pagination>
          </div>
        </div>
      </card>
    </div>
     <div class="col-md-3 hasDecision" id="customStyle">
       <div class="col-md-12 clearfix key-decision" >
        <div class="input-group no-border">
          <search-area :map="map" :mapDefault="mapdefaultSetting"></search-area>
        </div>
      </div>
      <el-collapse v-model="activeNames">
        <el-collapse-item title="信息图表" name="1">
          <div class="col-md-12">
              <div class="chart-area" id="box-chart" style="height: 350px"></div>
            </div>
        </el-collapse-item>
        <el-collapse-item title="数据筛选" name="2">
          <div class="col-md-12">
              <form method="get" action="/">
                <div class="row">
                  <div class="col-sm-10">
                    <el-select
                      class="select-default"
                      size="large"
                      placeholder="街道"
                      v-model="selects.street"
                      v-on:change="reloadData"
                    >
                      <el-option
                        v-for="option in selects.streets"
                        class="select-primary"
                        :value="option.id"
                        :label="option.name"
                        :key="option.id"
                      ></el-option>
                    </el-select>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-10">
                    <el-select
                      class="select-default"
                      size="large"
                      placeholder="社区"
                      v-model="selects.community"
                      v-on:change="reloadData"
                    >
                      <el-option
                        v-for="option in selects.communities"
                        class="select-primary"
                        :value="option.id"
                        :label="option.name"
                        :key="option.id"
                      ></el-option>
                    </el-select>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-10">
                    <el-select
                      class="select-default"
                      size="large"
                      placeholder="结构类型"
                      v-model="selects.struct"
                      v-on:change="reloadData"
                    >
                      <el-option
                        v-for="(option, index) in selects.structs"
                        class="select-primary"
                        :value="index"
                        :label="option"
                        :key="index"
                      ></el-option>
                    </el-select>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-10">
                    <el-select
                      class="select-default"
                      size="large"
                      placeholder="功能类型"
                      v-model="selects.category"
                      v-on:change="reloadData"
                    >
                      <el-option
                        v-for="(option, index) in selects.categories"
                        class="select-primary"
                        :value="index"
                        :label="option"
                        :key="index"
                      ></el-option>
                    </el-select>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-10">
                    <el-select
                      class="select-default"
                      size="large"
                      placeholder="抗震设防烈度"
                      v-model="selects.quakeLevel"
                      v-on:change="reloadData"
                    >
                      <el-option
                        v-for="option in selects.quakeLevels"
                        class="select-primary"
                        :value="option.id"
                        :label="option.name"
                        :key="option.id"
                      ></el-option>
                    </el-select>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-10">
                    <el-select
                      class="select-default"
                      size="large"
                      placeholder="修建年代"
                      v-model="selects.buildYear"
                      v-on:change="reloadData"
                    >
                      <el-option
                        v-for="(option,index) in selects.buildYears"
                        class="select-primary"
                        :value="index"
                        :label="option"
                        :key="index"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
              </form>
            </div>
        </el-collapse-item>
      </el-collapse>

      
    </div>
    <div class="key-infos">
      <div class="key-info">
        平均修建年代{{statistics.year}}
      </div>
    </div>
  </div>
</template>
<script>
import mapboxgl from "mapbox-gl";
import MenuMap from "src/components/Menumap";
import SearchArea from "src/components/SearchArea";
import MiniMap from "src/mapbox/mapboxgl-minimap.js";
import { TabPane, Tabs } from "src/components";
import geobuf from "geobuf";
import Pbf from "pbf";
import { Input, Button, Select, Option, Collapse, CollapseItem, Table, TableColumn } from "element-ui";
import { consts } from "src/util/consts.js";
import {
  getCategories,
  getStreets,
  getCommunities,
  getList,
  getGeoJson,
  getBuildingYearStatistics
} from "src/api/building.js";
import { byBuildYear } from "src/api/chart.js";
import { buildPieOption } from "src/util/chart.js";
import {
  getBuildYearRange,
  storeMapOptions,
  getMapOptions,
  parseJsonToString
} from "src/util/common.js";
import { Pagination as NPagination } from "src/components";
import * as THREE from "three";

export default {
  components: {
    MenuMap,
    SearchArea,
    TabPane,
    Tabs,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    NPagination
  },
  data() {
    return {
      activeNames: ['1','2'],
      activeName: "first",
      tabs: {
        horizontal: "Profile",
        vertical: "Options",
        verticalPills: "Home",
        pageCategories: "Messages"
      },
      selects: {
        street: 0,
        streets: [{ id: 0, name: "街道" }],
        community: 0,
        communities: [{ id: 0, name: "社区" }],
        struct: 0,
        structs: consts.buildingStructs,
        category: 0,
        categories: consts.buildingCategories,
        quakeLevel: 0,
        quakeLevels: [
          { id: 0, name: "抗震设防烈度" },
          { id: 6, name: 6 },
          { id: 7, name: 7 },
          { id: 8, name: 8 }
        ],
        buildYear: 0,
        buildYears: consts.buildingBuildYears
      },
      keyword: "",
      total: 0,
      data: [],
      page: 1,
      map: {},
      color_category: "build_year",
      city_id: localStorage.getItem("city_id") || 1,
      show_list: false,
      show_list_height: 0,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 20
      },
      last_updated: consts.last_updated,
      buildingStructs : [
        {name:'1960-1969',color: '#D55328'},
        {name:'1970-1979',color: '#F5C243'},
        {name:'1980-1989',color: '#447DB3'},
        {name:'1990-1999',color: '#62BFF5'},
        {name:'2000-2010',color: '#70AD47'},
      ],
      statistics: {
        year: 0
      },
      mapdefaultSetting: {},
      downloadUrl: '',
      //colors: ['#D55328','#F5C243','#447DB3','#62BFF5','#70AD47','#94CFBF','#B22FB9','#9c0003']
    };
  },

  mounted() {
    this.fillStreets(this.city_id);
    this.fillCommunities(this.city_id, 0);
    this.showChart(this.city_id);
    this.fillBuildings(this.city_id, 1);
    this.initMap(this.city_id);
    this.setTipContent();
    this.map.on('style.load',()=>{
      this.fillBuildingsMap(this.city_id, this.color_category);
    })
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    street() {
      return this.selects.street;
    }
  },
  watch: {
    street(val) {
      this.fillCommunities(this.city_id, this.selects.street);
    }
  },

  methods: {
    // 初始化
    initMap(city_id) {
      this.mapdefaultSetting = {
        center: consts.mapCenter[this.city_id-1],
        zoom: consts.mapDefaultZoom,
        pitch: 60,
        city_id: this.city_id
      }
      mapboxgl.accessToken = consts.mapboxAccessToken;
      let map_options = getMapOptions();
      //console.log(map_options);
      let zoom = map_options ? map_options.zoom : 12;
      let center = map_options
        ? [map_options.lng, map_options.lat]
        : consts.mapCenter[city_id - 1];
      var map = new mapboxgl.Map({
        container: "map",
        style: consts.mapBoxStyle,
        //center: [-87.61694, 41.86625],
        center: center,
        zoom: zoom,
        pitch: 60,
        bearing: 0,
        attributionControl: false
      });
      this.map = map;
      //定位图标
      map.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl
        })
      );
      this.map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: true
        })
      );
      //控制图层
      var nav = new mapboxgl.NavigationControl();
      this.map.addControl(nav, "top-right");
      //全屏按钮
      this.map.addControl(
        new mapboxgl.FullscreenControl({
          container: document.querySelector("body")
        })
      );
      //小地图
      //https://github.com/brendanmatkin/mapboxgl-minimap
      map.on("style.load", function() {
        // Possible position values are 'bottom-left', 'bottom-right', 'top-left', 'top-right'
        map.addControl(
          new MiniMap({
            id: "mapboxgl-minimap",
            width: "200px",
            height: "150px",
            //style: "mapbox://styles/mapbox/streets-v10",
            style: consts.mapBoxStyle,
            fillColor: "#f58220",
            zoomAdjust: null
          }),
          "bottom-right"
        );
      });
      //记录地图设置
      map.on("move", function() {
        storeMapOptions({
          lng: map.getCenter().lng,
          lat: map.getCenter().lat,
          zoom: map.getZoom()
        });
      });
    },

    addBuildings2Map(map, geoJsonData) {
      geoJsonData.features.forEach((item, idx)=> {
        if(item.properties.color == '#c23531') {
          item.properties.color = '#D55328'
        }
        if(item.properties.color == '#2f4554') {
          item.properties.color = '#F5C243'
        }
        if(item.properties.color == '#61a0a8') {
          item.properties.color = '#447DB3'
        }
        if(item.properties.color == '#d48265') {
          item.properties.color = '#62BFF5'
        }
         if(item.properties.color == '#91c7ae') {
          item.properties.color = '#70AD47'
        }
       
        

      })
      let layer_id = "room-extrusion";
      let source_name = "geojson";
      var layer = map.getLayer(layer_id);
      if (layer) {
        map.removeLayer(layer_id);
        map.removeSource(source_name);
      }
      map.addSource(source_name, {
        type: "geojson",
        data: geoJsonData
      });
      map.addLayer({
        id: layer_id,
        type: "fill-extrusion",
        source: source_name,
        //'source': {
        // GeoJSON Data source used in vector tiles, documented at
        // https://gist.github.com/ryanbaumann/a7d970386ce59d11c16278b90dde094d
        //  'type': 'geojson',
        //  'data':geoJsonData,
        //'data': "http://api.urban.test/api/buildings-geo-json?struct_id=2&category_id=1&street_id=1"
        //'data': "https://docs.mapbox.com/mapbox-gl-js/assets/indoor-3d-map.geojson"
        //},
        paint: {
          // See the Mapbox Style Specification for details on data expressions.
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions

          // Get the fill-extrusion-color from the source 'color' property.
          "fill-extrusion-color": ["get", "color"],

          // Get fill-extrusion-height from the source 'height' property.
          "fill-extrusion-height": ["get", "height"],

          // Get fill-extrusion-base from the source 'base_height' property.
          "fill-extrusion-base": ["get", "base_height"],

          // Make extrusions slightly opaque for see through indoor walls.
          "fill-extrusion-opacity": 0.8
        }
      });
    },
    showList() {
      this.show_list = !this.show_list;
    },
    donwloadList() {},
    showChart(city_id) {
      var container = document.getElementById("container");
      var box = document.getElementById("box-chart");
      var myChart = this.$echarts.init(box);
      byBuildYear(city_id).then(res => {
        var chart_data = res.data;
        var chart_name = "修建年代";
        chart_data.map((item,idx) => {
          item.itemStyle = {color:this.buildingStructs[idx].color}
        })
        var option = buildPieOption(chart_name, chart_data);
        myChart.setOption(option);
      });
      window.onresize = function() {
        document.querySelectorAll('.chart-area').forEach((item,idx)=>{
          item.style.width = document.querySelector('.el-collapse').offsetWidth+'px';
        })
        myChart.resize(); 
      }
    },
    fillStreets(city_id) {
      getStreets(city_id).then(res => {
        let data = res.data;
        data.unshift({ id: 0, name: "街道" });
        this.selects.streets = data;
      });
    },
    fillCommunities(city_id, street_id) {
      getCommunities(city_id, street_id).then(res => {
        let data = res.data;
        data.unshift({ id: 0, name: "社区" });
        this.selects.communities = data;
      });
    },
    fillBuildings(city_id, page) {
      let params = this.buildParams();
      params.page = page;
      getList(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
      getBuildingYearStatistics(params).then(res => {
        this.statistics.year = res.data.year;
      });
      this.downloadUrl = consts.requestUrl + consts.exportBuildYear + parseJsonToString(params)
    },
    fillBuildingsMap(city_id, color_category) {
      let params = this.buildParams();
      params.color_category = color_category;
      getGeoJson(params).then(res => {
        let data = res;
        let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        this.addBuildings2Map(this.map, geoJson);
      });
    },
    reloadData() {
      this.fillBuildings(this.city_id, 1);
      this.fillBuildingsMap(this.city_id, this.color_category);
    },
    setTipContent() {
      let html = consts.tipsBuildYear;
      document.getElementById('left-tip-content').innerHTML = html
    },
    buildParams() {
      let params = { city_id: this.city_id };
      params.street_id = this.selects.street;
      params.category_id = this.selects.category;
      params.community_id = this.selects.community;
      params.struct_id = this.selects.struct;
      params.keyword = this.keyword;
      params.quake_level = this.selects.quakeLevel;
      if (this.selects.buildYear > 0) {
        let range_year = getBuildYearRange(
          this.selects.buildYear,
          consts.buildingBuildYears
        );
        console.log(range_year);
        let start_year = range_year.start;
        let end_year = range_year.end;
        params.start_build_year = start_year;
        params.end_build_year = end_year;
      }else {
        params.start_build_year = 0;
        params.end_build_year = 0;  
      }
      return params;
    },
    changePage: function(value) {
      if (value != this.page) {
        this.fillBuildings(this.city_id, value);
      }
      this.page = value;
    }
  }
};
</script>
<style lang="scss" scoped>
.card-map {
  min-height: 350px;
  height: calc(100vh - 48px);
  margin-bottom: 0;

  position: relative;
  .map-wrapper{
    width: 100%;
    height: calc(100vh - 100px);
    position: relative;
    & > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  #map {
    width: 100%;
    height: calc(100vh - 100px);
  }
}
.main-panel {
  // height: calc(100vh - 130px);
  min-height: 350px;
}
.right-block {
  height: calc(100vh - 100px);
  min-height: 350px;
  overflow: hidden;
}
.tab-scroller {
  height: calc(100vh - 470px);
  min-height: 350px;
  overflow-y: auto;
}

.show_list {
  width: 100%;
  height: 30px;
  margin-top: 8px;
}
.show_list span {
  height: 32px;
  line-height: 36px;
  margin-left: 32px;
  font-size: 14px;
}
.show_list span i {
  height: 16px;
  padding: 2px 12px;
  background: #ffb236;
  margin: 0 4px;
  border-radius: 16px;
}
.show_list .up_show {
  display: block;
  float: left;
  font-size: 32px;
  cursor: pointer;
}
.show_list .download {
  display: block;
  float: right;
  font-size: 32px;
  cursor: pointer;
}
.footer {
  padding-top: 0;
}
.extended-forms .el-select {
  width: 100%;
  margin-bottom: 30px;
}

.extended-forms .progress {
  margin-bottom: 30px;
}
@import url("https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css");
.coordinates {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 5px 10px;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  display: none;
}
ul.nav__items > li:last-of-type a {
  margin-right: 0 !important;
}
.card-body .tab-content .tab-scroller {
  min-height: auto;
}
</style>
