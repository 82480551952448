<template>
  <div class="row" style="margin:0 -30px;">
    <div class="col-md-9" style="padding:0">
      <card class="card-map">
        <!-- <div class="mask">
          <img src="img/loading.png" class="loading">
        </div>-->
        <div id="map"></div>
        <menu-map :map="map" :mapDefault="mapdefaultSetting"></menu-map>
        <div class="recovery_days" v-show="selects.data_category == 'recovery_show'">
          
          <p class="recovery_lable">恢复时间</p>
          <div class="timeline-controller" @click="playTimeline">
            <i class="el-icon-video-play" v-if="!playing"></i>
            <i class="el-icon-video-pause" v-if="playing" ></i>
          </div>
          
          <div class="bar">
            <slider v-model="recovery_days" :options="{step: 1}" :range="{'min': [0], 'max': [149]}"></slider> 
          </div>
          <p class="start_day">0周</p>
          <p class="end_day">149周</p>


        </div>
        <div class="map_data_msg" v-show="selects.data_category == 'recovery_show'">
          <div class="map_data_legend">FF 完全能用 概率</div>
          <div class="map_data_msg_wrapper">
            <div
              class="item"
              v-for="(item,index) in options[0].data"
              :key="index"
            >
              <span :style="'background: '+colors['recovery_show'][index]+''"></span>
              {{item.name}}
            </div>
          </div>
        </div> 
        <div class="map_data_msg" v-show="selects.data_category == 'recovery_time'">
          <div class="map_data_legend">功能恢复时间（周）</div>
          <div class="map_data_msg_wrapper">
            <div
              class="item"
              v-for="(item,index) in selects.recovery_timeOptions"
              :key="index"
              v-if="index>0"
            >
              <span :style="'background: '+item.color+''"></span>
              {{item.name}}
            </div>
          </div>
        </div> 
        <div class="show_list">
          <a class="up_show" v-on:click="showList">
            <i
              class="now-ui-icons"
              :class="[ show_list ? 'arrows-1_minimal-down':'arrows-1_minimal-up']"
            ></i>
          </a>
          <a class="download ml-auto" :href="downloadUrl">
            <i class="now-ui-icons arrows-1_cloud-download-93"></i>
          </a>
          <span>
            数据列表
            <i>{{total}}</i>
          </span>
        </div>
        <div
          class="show_list_record"
          v-show="show_list"
          :style="{'margin-top':-((selects.data_category == 'recovery_show')?(172+data.length*38):(125+data.length*38))+'px'}"
          ref="show_list_record"
        >
          <el-table :data="data" v-show="selects.data_category == 'recovery_show'">
            <el-table-column label="建筑ID" property="id"></el-table-column>
            <el-table-column label="街道" property="street_name"></el-table-column>
            <el-table-column label="社区" property="community_name"></el-table-column>
            <el-table-column label="FF完全能住概率">
              <el-table-column
                property="week1"
                label="第1周">
              </el-table-column>
              <el-table-column
                property="week5"
                label="第5周">
              </el-table-column>
              <el-table-column
                property="week10"
                label="第10周">
              </el-table-column>
              <el-table-column
                property="week20"
                label="第20周">
              </el-table-column>
              <el-table-column
                property="week50"
                label="第50周">
              </el-table-column>
              <el-table-column
                property="week100"
                label="第100周">
              </el-table-column>
              <el-table-column
                property="week149"
                label="第149周">
              </el-table-column>
            </el-table-column>
          </el-table>
          <el-table :data="data" v-show="selects.data_category == 'recovery_time'">
            <el-table-column label="建筑ID" property="building_id"></el-table-column>
            <!-- <el-table-column label="经度" property="lng"></el-table-column>
            <el-table-column label="纬度" property="lat"></el-table-column> -->
            <el-table-column label="街道" property="street_name"></el-table-column>
            <el-table-column label="社区" property="community_name"></el-table-column>
            <el-table-column label="平均功能恢复时间" property="functional_recovery_time"></el-table-column>
          </el-table>

          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class>
              <p class="card-category">显示： {{from + 1}} - {{to}} 总数： {{total}}</p>
            </div>
            <n-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              v-on:input="changePage"
            ></n-pagination>
          </div>
        </div>
      </card>
    </div>
    <div class="col-md-3 hasDecision" id="customStyle">
      <div class="col-md-12 clearfix key-decision" >
          <el-select
            class="select-default"
            size="large"
            placeholder="数据集类型选择"
            v-model="selects.data_category"
            v-on:change="reloadData('decision')"
          >
            <el-option
              v-for="option in selects.data_categories"
              class="select-primary"
              :value="option.id"
              :label="option.name"
              :key="option.id"
            ></el-option>
          </el-select>
      </div>
      <el-collapse v-model="activeNames">
        <!-- <el-collapse-item title="一致性 Consistency" name="1">
          <form>
            <div class="input-group no-border">
              <fg-input
                placeholder="输入关键字..."
                v-on:keyup.enter="reloadData"
                v-model="keyword"
                addon-right-icon="now-ui-icons ui-1_zoom-bold"
              ></fg-input>
              <div class="form-text" style="color:#999;margin-left:20px;">输入 建筑、街道、社区关键字</div>
            </div>
          </form>
        </el-collapse-item> -->
        <el-collapse-item title="信息图表" name="2">
          <div class="col-md-12" v-show="selects.data_category == 'recovery_show'">
            <div class="chart-area" id="box-chart" style="height:400px"></div>
          </div>
          <div class="col-md-12" v-show="selects.data_category == 'recovery_show'">
            <div class="chart-area" id="box-chart2" style="height:400px;margin-top: 30px;"></div>
          </div>
          <div class="col-md-12" v-show="selects.data_category == 'recovery_time'">
            <div class="chart-area" id="box-chart3" style="height:400px"></div>
          </div>
        </el-collapse-item>
        <el-collapse-item title="数据筛选" name="3">
          <div class="col-md-12">
                <el-select
                  class="select-default"
                  size="large"
                  placeholder="街道"
                  v-model="selects.street"
                  v-on:change="reloadData('street')"
                >
                  <el-option
                    v-for="option in selects.streets"
                    class="select-primary"
                    :value="option.id"
                    :label="option.name"
                    :key="option.id"
                  ></el-option>
                </el-select>
              </div>

              <div class="col-md-12">
                <el-select
                  class="select-default"
                  size="large"
                  placeholder="社区"
                  v-model="selects.community"
                  v-on:change="reloadData"
                >
                  <el-option
                    v-for="option in selects.communities"
                    class="select-primary"
                    :value="option.id"
                    :label="option.name"
                    :key="option.id"
                  ></el-option>
                </el-select>
              </div>

              <div class="col-md-12">
                <el-select
                  class="select-default"
                  size="large"
                  placeholder="结构类型"
                  v-model="selects.struct"
                  v-on:change="reloadData"
                >
                  <el-option
                    v-for="(option, index) in selects.structs"
                    class="select-primary"
                    :value="index"
                    :label="option"
                    :key="index"
                  ></el-option>
                </el-select>
              </div>

              <div class="col-md-12">
                <el-select
                  class="select-default"
                  size="large"
                  placeholder="功能类型"
                  v-model="selects.category"
                  v-on:change="reloadData"
                >
                  <el-option
                    v-for="(option, index) in selects.categories"
                    class="select-primary"
                    :value="index"
                    :label="option"
                    :key="index"
                  ></el-option>
                </el-select>
              </div>

              <div class="col-md-12">
                <el-select
                  class="select-default"
                  size="large"
                  placeholder="抗震设防烈度"
                  v-model="selects.quakeLevel"
                  v-on:change="reloadData"
                >
                  <el-option
                    v-for="option in selects.quakeLevels"
                    class="select-primary"
                    :value="option.id"
                    :label="option.name"
                    :key="option.id"
                  ></el-option>
                </el-select>
              </div>

              <div class="col-md-12">
                <el-select
                  class="select-default"
                  size="large"
                  placeholder="修建年代"
                  v-model="selects.buildYear"
                  v-on:change="reloadData"
                >
                  <el-option
                    v-for="(option,index) in selects.buildYears"
                    class="select-primary"
                    :value="option"
                    :label="option"
                    :key="index"
                  ></el-option>
                </el-select>
              </div>
              <div class="col-md-12" v-show="selects.data_category == 'recovery_show'">
                <el-select
                  class="select-default"
                  size="large"
                  placeholder="FF完全能用概率"
                  v-model="selects.ff_probability"
                  v-on:change="reloadData"
                >
                  <el-option
                    v-for="(option,index) in selects.ff_probabilityOptions"
                    class="select-primary"
                    :value="option.id"
                    :label="option.name"
                    :key="index"
                  ></el-option>
                </el-select>
              </div>
              <div class="col-md-12" v-show="selects.data_category == 'recovery_time'">
                <el-select
                  class="select-default"
                  size="large"
                  placeholder="功能恢复时间"
                  v-model="selects.recovery_time"
                  v-on:change="reloadData"
                >
                  <el-option
                    v-for="(option,index) in selects.recovery_timeOptions"
                    class="select-primary"
                    :value="option.id"
                    :label="option.name"
                    :key="index"
                  ></el-option>
                </el-select>
              </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="key-infos">
      <div class="key-info" v-show="selects.data_category == 'recovery_show'">
        功能状态分布 RE：{{statistics.recovery_show.re}}，RU：{{statistics.recovery_show.ru}}，RO：{{statistics.recovery_show.ro}}，BF：{{statistics.recovery_show.bf}}，FF：{{statistics.recovery_show.ff}}
      </div>
      <div class="key-info" v-show="selects.data_category == 'recovery_time'">
        平均功能恢复时间{{statistics.recovery_time.rebuild_cost}}周
      </div>
    </div>
  </div>
</template>
<script>
import mapboxgl from "mapbox-gl";
import MenuMap from "src/components/Menumap";
import MiniMap from "src/mapbox/mapboxgl-minimap.js";
import geobuf from "geobuf";
import Pbf from "pbf";
import { Slider,TabPane, Tabs, Switch } from "src/components";
import { Radio } from "src/components/index";
import { Input, Button, Select, Option,Collapse, CollapseItem, Table, TableColumn } from "element-ui";
import { consts } from "src/util/consts.js";
import {
  getCategories,
  getStreets,
  getCommunities,
  getFunctionRecoveryOutlineGeojson as getBuilingGeo,
  getFunctionRecoveryOptions as getOptions1,
  getFunctionRecoveryList as getList1,
  getFunctionRecoveryGeojson as getGeo1,
  getRecoveryTimeList,
  getRecoveryTimeGeojson,
  getRecoveryShowStatistics as getStatistics1,
  getRecoveryTimeStatistics,
  getFunctionRecoveryChart as getChart1,
  getFunctionRecoveryStreetChart as getChart2,
} from "src/api/building.js";
import { byBuildingFunctionRecovery, byStreetFunctionRecovery,functionRecoveryDays } from "src/api/chart.js";
import { buildlineOption,buildBarStackOption, buildBarOption } from "src/util/chart.js";
import {
  getBuildYearRange,
  storeMapOptions,
  getMapOptions,
  parseJsonToString
} from "src/util/common.js";
import { Pagination as NPagination } from "src/components";
import {
  getPeopleStat,
  getGenderStat,
  getHideaways,
  getHospitals,
  getWarehouses
} from "src/api/cityinfo.js";

import * as THREE from "three";

export default {
  components: {
    MenuMap,
    Slider,
    TabPane,
    Tabs,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    NPagination,
    [Switch.name]: Switch,
    Radio
  },
  data() {
    return {
      activeNames: ['2','3'],
      activeName: "first",
      tabs: {
        horizontal: "Profile",
        vertical: "Options",
        verticalPills: "Home",
        pageCategories: "Messages"
      },
      selects: {
        street: 0,
        streets: [{ id: 0, name: "街道" }],
        community: 0,
        communities: [{ id: 0, name: "社区" }],
        struct: 0,
        structs: consts.buildingStructs,
        category: 0,
        categories: consts.buildingCategories,
        quakeLevel: 0,
        quakeLevels: [
          { id: 0, name: "抗震设防烈度" },
          { id: 6, name: 6 },
          { id: 7, name: 7 },
          { id: 8, name: 8 }
        ],
        buildYear: '修建年代',
        buildYears: consts.buildingBuildYears,
        data_category: 'recovery_show',
        data_categories: [
          { id: 'recovery_show', name: "功能恢复推演" },
          { id: 'recovery_time', name: "功能恢复时间" },
        ],
        ff_probability: 0,
        ff_probabilityOptions: [
          { id: 0, name: "FF完全能用概率" },
          { id: 1, name: "0-0.2", color: "#d55846" },
          { id: 2, name: "0.2-0.4", color: "#d7843c" },
          { id: 3, name: "0.4-0.6", color: "#e9c645" },
          { id: 4, name: "0.6-0.8", color: "#905eb1" },
          { id: 5, name: "0.8-0.9", color: "#4f97d6" },
          { id: 6, name: "0.9-1.0", color: "#60c97a" },
        ],
        recovery_time: 0,
        recovery_timeOptions: [
          { id: 0, name: "功能恢复时间" },
          { id: 1, name: "0-20周", color: "#60c97a" },
          { id: 2, name: "20-40周", color: "#4f97d6" },
          { id: 3, name: "40-60周", color: "#905eb1" },
          { id: 4, name: "60-80周", color: "#e9c645" },
          // { id: 5, name: "80-100周", color: "#d7843c" },
          // { id: 6, name: "100-149周", color: "#d55846" },
        ],
      },
      keyword: "",
      total: 0,
      data: [],
      page: 1,
      map: {},
      color_category: "recovery",
      city_id: localStorage.getItem("city_id") || 1,
      show_list: false,
      show_list_height: 0,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 20
      },
      switchs: {
        people: true,
        gender: true,
        hideaway: true,
        hospital: true,
        warehouse: true
      },
      last_updated: consts.last_updated,
      data_type: "people",
      recovery_days: 0, //恢复时间
      colors: {
        "recovery_show": ["#d55846","#d7843c","#e9c645","#905eb1","#4f97d6","#60c97a"],
        "recovery_time": ["#d7843c","#e9c645","#905eb1","#4f97d6","#60c97a"]
      },
      playing: false,
      itv: null,
      statistics: {
        recovery_show: {
          "re": '',
          "ru": '',
          "ro": '',
          "bf": '',
          "ff": ''
        },
        recovery_time: {rebuild_cost: ''}
      },
      mapdefaultSetting: {},
      downloadUrl: '',
      options: [
        {name: 'FF完全能用概率',selected:[],data: []},
        {name: '功能回复时间',selected:[],data: []},
      ],
      chart2Data: false,
    };
  },
  mounted() {
    this.fillStreets(this.city_id);
    this.fillCommunities(this.city_id, 0);
    this.showChart(this.city_id);
    //this.fillBuildings(this.city_id, 1);
    this.initMap(this.city_id);
    this.fillFigure()
    // this.fillBuildingsMap(this.city_id, this.color_category);
    //this.addPointLayerToMap(this.map, "hospital");
    //this.addPointLayerToMap(this.map, "hideaway");
    //this.addPointLayerToMap(this.map, "warehouse");
    //this.loadData(this.data_type);
  },

  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    street() {
      return this.selects.street;
    }
  },
  watch: {
    recovery_days(val){
      this.updateDays(val)
    },
    street(val) {
      this.fillCommunities(this.city_id, this.selects.street);
    }
  },

  methods: {
    // 初始化
    initMap(city_id) {
      const _this = this;
      this.mapdefaultSetting = {
        center: consts.mapCenter[this.city_id-1],
        zoom: consts.mapDefaultZoom,
        pitch: 0,
        city_id: this.city_id
      }
      mapboxgl.accessToken = consts.mapboxAccessToken;
      let map_options = getMapOptions();
      //console.log(map_options);
      let zoom = map_options ? map_options.zoom : 12;
      let center = map_options
        ? [map_options.lng, map_options.lat]
        : consts.mapCenter[city_id - 1];
      var map = new mapboxgl.Map({
        container: "map",
        style: consts.mapBoxStyle,
        // center: [-87.61694, 41.86625],
        center: center,
        zoom: zoom,
        pitch: 0,
        bearing: 0,
        attributionControl: false
      });
      this.map = map;

      //定位图标
      map.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl
        })
      );
      this.map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: true
        })
      );
      //控制图层
      var nav = new mapboxgl.NavigationControl();
      this.map.addControl(nav, "top-right");
      //全屏按钮
      this.map.addControl(
        new mapboxgl.FullscreenControl({
          container: document.querySelector("body")
        })
      );
      map.on("load", function() {
        
      });
      //小地图
      //https://github.com/brendanmatkin/mapboxgl-minimap
      map.on("style.load", function() {
        _this.reloadData();
        // Possible position values are 'bottom-left', 'bottom-right', 'top-left', 'top-right'
        _this.fillBuildingOutlineGeo()
        map.addControl(
          new MiniMap({
            id: "mapboxgl-minimap",
            width: "200px",
            height: "150px",
            //style: "mapbox://styles/mapbox/streets-v10",
            style: consts.mapBoxStyle,
            fillColor: "#f58220",
            zoomAdjust: null
          }),
          "bottom-right"
        );
      });
      //记录地图设置
      map.on("move", function() {
        storeMapOptions({
          lng: map.getCenter().lng,
          lat: map.getCenter().lat,
          zoom: map.getZoom()
        });
      });
    },
    fillFigure(){
      getOptions1().then(res => {
        let data = res.data;
        this.options[0].data = data;
      });
    },
    fillBuildingOutlineGeo(){
      let _this = this;
      let params = this.buildParams();
      getBuilingGeo(params).then(res => {
        let data = res;
        let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        _this.buildingCommonGeo = geoJson
        
        //console.log(this.buildingCommonGeo)
      });
    },
    addBuildings2Map(map, geoJsonData) {
      this.removeMapLayer(map, 'room-extrusion');
      let layer_id = this.getMapLayerId('room-extrusion');
      let source_name = this.getMapSourceName('room-extrusion');
      let colorExpress = ["get", "color"];
      if(typeof geoJsonData.features == 'undefined') return;
      if(this.selects.data_category == 'recovery_show') {
        
        colorExpress = ["step",["get", "w"+parseInt(this.recovery_days)],
            "rgba(255,255,255,0)", -0.0001,
            "#d55846", 0.2,
            "#d7843c", 0.4,
            "#e9c645", 0.6,
            "#905eb1", 0.8,
            "#4f97d6", 0.9,
            "#60c97a", 1,
            "#60c97a" // other
          ]
          // colorExpress = [
          //   "step",
          //   ["get", "rate"],
          //   "#d55846", 0.2,
          //   "#d7843c", 0.4,
          //   "#e9c645", 0.6,
          //   "#905eb1", 0.8,
          //   "#4f97d6", 0.9,
          //   "#60c97a", 1,
          //   "#60c97a" // other
          // ]
      } else {
        // colorExpress = ["interpolate",["linear"],["get", "functional_recovery_time"],
        //     20, "#60c97a",
        //     40, "#4f97d6",
        //     60, "#905eb1",
        //     80, "#e9c645",
        //     100, "#d7843c",
        //     149, "#d55846",
        //   ]

          colorExpress = [
            "step",
            ["get", "functional_recovery_time"],
            "#60c97a", 20,
            "#4f97d6", 40,
            "#905eb1", 60,
            "#e9c645", 80,
            "#d7843c", 100,
            "#d55846", 149,
            "#d55846" // other
          ]
      }
      map.addSource(source_name, {
        type: "geojson",
        data: geoJsonData
        //data: "./geojson/GeoJson-2.2.2-3.geojson"
      });
      map.addLayer({
        id: layer_id,
        type: "fill-extrusion",
        source: source_name,
        //'source': {
        // GeoJSON Data source used in vector tiles, documented at
        // https://gist.github.com/ryanbaumann/a7d970386ce59d11c16278b90dde094d
        //  'type': 'geojson',
        //  'data':geoJsonData,
        //'data': "http://api.urban.test/api/buildings-geo-json?struct_id=2&category_id=1&street_id=1"
        //'data': "https://docs.mapbox.com/mapbox-gl-js/assets/indoor-3d-map.geojson"
        //},
        paint: {
          // See the Mapbox Style Specification for details on data expressions.
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions

          // Get the fill-extrusion-color from the source 'color' property.
          "fill-extrusion-color": colorExpress,

          // Get fill-extrusion-height from the source 'height' property.
          "fill-extrusion-height": ["get", "height"],

          // Get fill-extrusion-base from the source 'base_height' property.
          "fill-extrusion-base": ["get", "base_height"],

          // Make extrusions slightly opaque for see through indoor walls.
          "fill-extrusion-opacity": 0.8
        }
      });
    },
    showList() {
      this.show_list = !this.show_list;
    },
    donwloadList() {},
    showChart(city_id) {
      let _this = this;
      var container = document.querySelector('.el-collapse');

      var box = document.getElementById("box-chart");
      box.style.width = container.offsetWidth + "px";
      this.myChart = this.$echarts.init(box);
      getChart1(city_id).then(res=>{
        var chart_data = res.data;
        var chart_name = "建筑群落功能状态恢复曲线";
        chart_data.seriesData.map((item,idx) => {
          item.itemStyle= {normal: {areaStyle: {type: 'default'}}}
          item.color = _this.colors['recovery_time'][idx]
        })
        var option = buildlineOption(chart_name, chart_data, {
           xAxis: {
            show: true,
            name: '恢复时间（周）',
            nameLocation: 'center',//坐标轴名称显示位置。
            nameGap: 30,
            axisLabel: {
                interval:19,//代表显示所有x轴标签显示
            }
          },
          yAxis: {
            name: '概率',
            nameLocation: 'end'
          }
        }, true);
        this.myChart.setOption(option);
        this.myChart.setOption({
            series: [{
                id: 'timeline',
                type: 'line',
                data: [[parseInt(this.recovery_days), 0], [parseInt(this.recovery_days), 1]]
            }]
        });
      });

      // 社区建筑功能恢复
      var box2 = document.getElementById("box-chart2");
      box2.style.width = container.offsetWidth + "px";
      this.myChart2 = this.$echarts.init(box2);
      getChart2(this.city_id,this.recovery_days).then(res=>{
          //this.chart_data = res.data;
          this.chart2Data = res.data;
          this.showChart2()
          // var chart_name = "街道建筑功能恢复统计";
          // chart_data.seriesData.map((item,idx) => {
          //   return item.color = _this.colors['recovery_time'][idx]
          // })
          // var option = buildBarStackOption(chart_name, chart_data, {
          //  xAxis: {
          //   name: '建筑数量',
          //   nameLocation: 'center',//坐标轴名称显示位置。
          //   nameGap: 30
          // }});
          // this.myChart2.setOption(option);
        });
      // 功能恢复时间
      var box3 = document.getElementById("box-chart3");
      box3.style.width = container.offsetWidth + "px";
      var myChart3 = this.$echarts.init(box3);
      functionRecoveryDays(city_id).then(res => {
        var chart_data = res.data;
        var chart_name = "建筑功能恢复时间";
        chart_data.seriesData.map((item,idx) => {
          return item.color = this.colors['recovery_time'][idx]
        })
        var option = buildBarStackOption(chart_name, chart_data, {
           xAxis: {
            name: '建筑数量',
            nameLocation: 'center',//坐标轴名称显示位置。
            nameGap: 30
          }});
        myChart3.setOption(option);
      });

      window.onresize = function() {
        document.querySelectorAll('.chart-area').forEach((item,idx)=>{
          item.style.width = document.querySelector('.el-collapse').offsetWidth+'px';
        })
        _this.myChart.resize(); 
        _this.myChart2.resize(); 
        myChart3.resize(); 
      }
    },
    showChart2(){
      if(!this.chart2Data)return;
      let chart_data = JSON.parse(JSON.stringify(this.chart2Data));
      var chart_name = "街道建筑功能恢复统计";
      this.buildingRecoveryCount = 0;
      chart_data.seriesData.map((item,idx) => {
        item.color = this.colors['recovery_time'][idx]
        item.data = item.data[parseInt(this.recovery_days)]
        
      })
      chart_data.seriesData[1].data.forEach((item,idx)=>{
        this.buildingRecoveryCount += item;
      })
      var option = buildBarStackOption(chart_name, chart_data);
      this.myChart2.setOption(option);

    },
    fillStreets(city_id) {
      getStreets(city_id).then(res => {
        let data = res.data;
        data.unshift({ id: 0, name: "街道" });
        this.selects.streets = data;
      });
    },
    fillCommunities(city_id, street_id) {
      getCommunities(city_id, street_id).then(res => {
        let data = res.data;
        data.unshift({ id: 0, name: "社区" });
        this.selects.communities = data;
      });
    },
    fillPeopleData(page) {
      let params = this.buildParams();
      params.page = page;
      getPeopleStat(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillGenderData(page) {
      let params = this.buildParams();
      params.page = page;
      getGenderStat(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillHospitalsData(city_id, page) {
      let params = this.buildParams();
      params.page = page;
      getHospitals(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillHideawaysData(page) {
      let params = this.buildParams();
      params.page = page;
      getHideaways(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillWarehousesData(page) {
      let params = this.buildParams();
      params.page = page;
      getWarehouses(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillBuildings(city_id, page) {
      let params = this.buildParams();
      params.page = page;
      params.page_size = 10;
      
      if(this.selects.data_category == 'recovery_show') {
        getList1(params).then(res => {
          this.total = res.data.total;
          this.data = res.data.items;
        });
        params.in_week = parseInt(this.recovery_days);
        getStatistics1(params).then(res => {
          this.statistics.recovery_show = res.data;
        });
        this.downloadUrl = consts.requestUrl + consts.exportRecoveryShowNew + parseJsonToString(params)
      } else if (this.selects.data_category == 'recovery_time'){
        getRecoveryTimeList(params).then(res => {
          this.total = res.data.total;
          this.data = res.data.items;
        });
        getRecoveryTimeStatistics(params).then(res => {
          this.statistics.recovery_time.rebuild_cost = res.data.rebuild_cost;
        });
        this.downloadUrl = consts.requestUrl + consts.exportRecoveryTime + parseJsonToString(params)
      }
      
    },
    fillBuildingsMap(city_id, color_category) {
      let _this = this;
      let params = this.buildParams();
      if(this.selects.data_category == 'recovery_show') {
        params.in_week = parseInt(this.recovery_days);
        getGeo1(params).then(data => {
          //let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          let geoJson = data;
          geoJson.features.forEach((item,idx)=>{
            _this.buildingCommonGeo.features.forEach((subitem,subidx)=>{
                if(subitem.properties.id == item.properties.id) {
                  item.geometry = subitem.geometry
                }
            })
          })
          this.addBuildings2Map(this.map, geoJson);
        });
      } else if (this.selects.data_category == 'recovery_time'){
        getRecoveryTimeGeojson(params).then(data => {
          //let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
         
          this.addBuildings2Map(this.map, geoJson);
        });
      }
    },
    updateChart(){
      let _this = this;
      if(this.selects.data_category == 'recovery_show') {
       
        this.showChart2()
        // byStreetFunctionRecovery(this.city_id,this.recovery_days).then(res=>{
        //   var chart_data = res.data;
        //   var chart_name = "街道建筑功能恢复统计";
        //   chart_data.seriesData.map((item,idx) => {
        //     return item.color = _this.colors['recovery_time'][idx]
        //   })
        //   var option = buildBarStackOption(chart_name, chart_data);
        //   this.myChart2.setOption(option);
        // });
        
        
      //   statistics: {
      //   recovery_show: {
      //     "re": '',
      //     "ru": '',
      //     "ro": '',
      //     "bf": '',
      //     "ff": ''
      //   },
      //   recovery_time: {rebuild_cost: ''}
      // },
       if(this.myChart.getOption() != undefined) {
          let options = this.myChart.getOption()
          _this.statistics.recovery_show.re = options.series[0].data[parseInt(this.recovery_days)]
          _this.statistics.recovery_show.ru = options.series[1].data[parseInt(this.recovery_days)]
          _this.statistics.recovery_show.ro = options.series[2].data[parseInt(this.recovery_days)]
          _this.statistics.recovery_show.bf = options.series[3].data[parseInt(this.recovery_days)]
          _this.statistics.recovery_show.ff = options.series[4].data[parseInt(this.recovery_days)]
          
          this.myChart.setOption({
              series: [{
                  id: 'timeline',
                  type: 'line',
                  data: [[parseInt(this.recovery_days), 0], [parseInt(this.recovery_days), 1]]
              }]
          });

          this.myChart.dispatchAction({
              type:'hideTip'
          })
          setTimeout(()=>{
            this.myChart.dispatchAction({
              type: 'showTip',
              seriesIndex: 0,
              dataIndex: parseInt(this.recovery_days)
            })
          },10)
        }
        

      }
    },
    updateDays () {
      //更新地图paint
      this.updateBuildingmapLayer('room-extrusion')
      //this.fillBuildingsMap(this.city_id, this.color_category);
      this.updateChart()
    },  
    reloadData(type) {
      if(type == 'street') {
        this.selects.community = 0;
      }
      this.setTipContent();
      if(type == 'decision' && this.selects.data_category == 'recovery_time') {
        this.playing = false;
        clearInterval(this.itv);
      }
      this.fillBuildings(this.city_id, 1);
      this.fillBuildingsMap(this.city_id, this.color_category);
      //this.loadData(this.data_type);
    },
    setTipContent() {
      let html = ''
      if(this.selects.data_category == 'recovery_show'){
        html = consts.tipsRecoveryShow;
      }
      if(this.selects.data_category == 'recovery_time'){
        html = consts.tipsRecoveryTime;
      }

      document.getElementById('left-tip-content').innerHTML = html
    },
    buildParams() {
      let params = { city_id: this.city_id };
      params.street_id = this.selects.street;
      params.community_id = this.selects.community;
      params.struct_id = this.selects.struct;
      params.category_id = this.selects.category;
      params.quake_level = this.selects.quakeLevel;
      params.start_build_year = 0;
      params.end_build_year = 0;
      if(this.selects.buildYear != '修建年代'){
        params.start_build_year = this.selects.buildYear.split('-')[0];
        params.end_build_year = this.selects.buildYear.split('-')[1];
      }
      if(this.selects.data_category == 'recovery_show') {
        params.status = this.selects.ff_probability;
      } else if (this.selects.data_category == 'recovery_time'){
        params.status = this.selects.recovery_time;
      }
      
      params.keyword = this.keyword;
      return params;
    },
    changePage: function(value) {
      this.fillBuildings(this.city_id, value);
      //this.loadData(this.data_type, value);
    },
    addPointLayerToMap(map, type) {
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      let geojson_url,
        icon = "";
      switch (type) {
        case "hospital":
          geojson_url = consts.url_geojson_hospital;
          icon = "hospital-15";
          break;
        case "hideaway":
          geojson_url = consts.url_geojson_hideaway;
          icon = "school-15";
          break;
        case "warehouse":
          geojson_url = consts.url_geojson_warehouse;
          icon = "parking-15";
          break;
      }
      if (geojson_url == "") {
        return;
      }
      var styleLoadStatus = map.isStyleLoaded();
      if (styleLoadStatus) {
        map.addSource(source_name, {
          type: "geojson",
          data: geojson_url
        });
        map.addLayer({
          id: layer_id,
          type: "symbol",
          source: source_name,
          layout: {
            "icon-image": icon,
            "text-field": "{name}",
            //"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-offset": [0, 0.6],
            "text-anchor": "top"
          }
        });
        return;
      }
      map.on("load", function() {
        map.addSource(source_name, {
          type: "geojson",
          data: geojson_url
        });
        map.addLayer({
          id: layer_id,
          type: "symbol",
          source: source_name,
          layout: {
            "icon-image": icon,
            "text-field": "{name}",
            //"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-offset": [0, 0.6],
            "text-anchor": "top"
          }
        });
      });
    },
    updateBuildingmapLayer(layer_name){
      let layer_id = this.getMapLayerId(layer_name);
      if(!this.map.getLayer(layer_id)) return;
      let colorExpress = [];
      // colorExpress = ["interpolate",["linear"],["get", "week"+parseInt(this.recovery_days)],
      //     0.2, "#60c97a",
      //     0.4, "#4f97d6",
      //     0.6, "#905eb1",
      //     0.8, "#e9c645",
      //     0.9, "#d7843c",
      //     1, "#d55846"
      //   ]
      colorExpress = ["step",["get", "w"+parseInt(this.recovery_days)],
            "rgba(255,255,255,0)", 0,
            "#d55846", 0.2,
            "#d7843c", 0.4,
            "#e9c645", 0.6,
            "#905eb1", 0.8,
            "#4f97d6", 0.9,
            "#60c97a", 1,
            "#60c97a" // other
          ]
        
      this.map.setPaintProperty(layer_id, 'fill-extrusion-color', colorExpress);
    },
    handelMapLayer(type) {
      //console.log(type);
      this.selects.street = 0;
      this.selects.community = 0;
      this.loadData(type);
      let types = ["hospital", "hideaway", "warehouse"];
      types.forEach(value => {
        if (value == type) {
          this.addPointLayerToMap(this.map, value);
        } else {
          this.removeMapLayer(this.map, value);
        }
      });
    },
    getMapLayerId(type) {
      let layer_id = type + "-layer";
      return layer_id;
    },
    getMapSourceName(type) {
      let source_name = type + "-data";
      return source_name;
    },
    removeMapLayer(map, type) {
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      var layer = map.getLayer(layer_id);
      if (layer) {
        map.removeLayer(layer_id);
        map.removeSource(source_name);
      }
    },
    loadData(type, page = 1) {
      switch (type) {
        case "people":
          this.fillPeopleData(page);
          break;
        case "gender":
          this.fillGenderData(page);
          break;
        case "hospital":
          this.fillHospitalsData(page);
          break;
        case "hideaway":
          this.fillHideawaysData();
          break;
        case "warehouse":
          this.fillWarehousesData();
          break;
      }
    },
    playTimeline() {
      clearInterval(this.itv)
      this.playing = !this.playing;
      if(this.playing) {
        this.itv = setInterval(()=>{
          this.recovery_days++
          if(this.recovery_days >=  149) this.recovery_days = 0;
        },1000)
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.card-map {
  min-height: 350px;
  height: calc(100vh - 48px);
  margin-bottom: 0;
  position: relative;
  #map {
    width: 100%;
    height: calc(100vh - 100px);
  }
  .map_data_msg {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    // color: #02f4fe;
    color: #fff;
    padding: 10px;
    width: 320px;
    .map_data_legend {
      margin-bottom: 5px;
    }
    .map_data_msg_wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .item {
        width: 100px;
        line-height: 15px;
        padding: 5px 0;
      }
      .item span {
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 3px;
        vertical-align: middle;
        margin-left: 0;
      }
      .map_data_msg_inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 10px;
      }
    }
    &.map_data_chart {
      width: 75%;
      bottom: 80px;
      background: rgba(255, 255, 255, 0);
      .map_data_chart_inner {
        width: 100%;
        display: flex;
      }
      .map-chart-area { 
        width: 10vw;
        height: 200px;  
      }
      #map-chart {
        width: 15vw;
        height: 200px;
      }
    }
  }
}
.main-panel {
  // height: calc(100vh - 130px);
  min-height: 350px;
}
.right-block {
  height: calc(100vh - 180px);
  min-height: 350px;
  overflow: hidden;
}
.tab-scroller {
  height: calc(100vh - 470px);
  min-height: 350px;
  overflow-y: auto;
}

.show_list {
  width: 100%;
  height: 30px;
  margin-top: 8px;
}
.show_list span {
  height: 32px;
  line-height: 36px;
  margin-left: 32px;
  font-size: 14px;
}
.show_list span i {
  height: 16px;
  padding: 2px 12px;
  background: #ffb236;
  margin: 0 4px;
  border-radius: 16px;
}
.show_list .up_show {
  display: block;
  float: left;
  font-size: 32px;
  cursor: pointer;
}
.show_list .download {
  display: block;
  float: right;
  font-size: 32px;
  cursor: pointer;
}
.footer {
  padding-top: 0;
}
.extended-forms .el-select {
  width: 100%;
  margin-bottom: 30px;
}

.extended-forms .progress {
  margin-bottom: 30px;
}
@import url("https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css");
.coordinates {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 5px 10px;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  display: none;
}
ul.nav__items > li:last-of-type a {
  margin-right: 0 !important;
}
.card-body .tab-content .tab-scroller {
  min-height: auto;
}
.recovery_days {
    position: absolute;
    bottom: 70px;
    left: 370px;
    //transform: translate(-45%);
    color: #fff;
    width: calc(100% - 600px);
    // height: 100px;
    padding: 20px 20px 50px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .recovery_lable {
      // float: left;
      margin: 0;
      // margin: -10px 0 0 0;
    }
    .bar {
      // float: left;
      width: 87%;
    }
    .start_day {
      position: absolute;
      left: 90px;
      top: 40px;
    }
    .end_day {
      // float: left;
      position: absolute;
      right: 20px;
      top: 40px;
      // position: absolute;
      // right: -50px;
      // top: -11px;
    }
    .timeline-controller {
      // position: relative;
      // top: -10px;
      // display: inline-block;
      // float: left;
     position: absolute;
     left: 55%;
     bottom: 15px;
     transform: translate(-50%);
    }
    .timeline-controller i {
      width: 100%;
      font-size: 24px;
      margin-left: 10px;
    }
}
.right-block .card-body {
  height: calc(100% - 150px)!important;
}
.chart-area {
  height: 400px;
}
.right-block .tab-pane {
    height: calc(100vh - 260px);
}
</style>
