import request from '@/util/request';


export function addHighLigthLayer(type, feature, self )
{   
  if(self.popup){
    self.popup.remove();
    self.popup = null;
  }
  
  // if(self.map.getLayer('highlight')) {
  //   self.map.removeLayer('highlight');
  //   self.map.removeSource('highlight');
  // } 

  if(type == 'node') {
    self.map.addSource('highlight', {
      type: "geojson",
      data: feature
    });
    self.map.addLayer({
      id: 'highlight',
      type: "circle",
      source: 'highlight',
      paint: {
        // make circles larger as the user zooms from z12 to z22
        'circle-radius': 12,
        // color circles by ethnicity, using a match expression
        // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-match
        'circle-color': "rgba(213,83,40,0.8)",
        'circle-stroke-color': '#000000',
        'circle-stroke-width': 0
      }
    });
  } else if (type == 'line') {
    self.map.addSource('highlight', { 
      type: "geojson",
      data: feature
    });
    self.map.addLayer({
      id: 'highlight',
      type: "line",
      source: 'highlight',
      "layout": {
        "line-join": "round",
        "line-cap": "round"
      },
        "paint": {
        "line-color": "rgba(213,83,40,0.8)",
        "line-width": 12
      }
    });
  } else {
    self.map.addSource('highlight', {
      type: "geojson",
      data: feature
    });
    self.map.addLayer({
      id: 'highlight',
      type: "fill",
      source: 'highlight',
      'paint': {
        'fill-color': "rgba(213,83,40,0.8)",
        'fill-opacity': 0.8
      }
    });
  }
}



export function getCommonNodeGeojson(params) {
  return request({
    url: 'api/traffics/geo-node',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}
export function getCommonPipeGeojson(params) {
  return request({
    url: 'api/traffics/geo-line',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}
export function getCommonBlockGeojson(params) {
  return request({
    url: 'api/traffics/geo-zone',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

//2.1.1

export function getBasicOptions(params) {
  return request({
    url: 'api/traffics/list',
    method: 'get',
    params: params
  })
}
export function getBasicNodeList(params) {
  return request({
    url: 'api/traffics/node',
    method: 'get',
    params: params
  })
}
export function getBasicPipeList(params) {
  return request({
    url: 'api/traffics/line',
    method: 'get',
    params: params
  })
}
export function getBasicBlockList(params) {
  return request({
    url: 'api/traffics/zone',
    method: 'get',
    params: params
  })
}

export function getBuildingGeojson(params) {

  params.format = 'geobuf';
  return request({
    url: 'api/buildings-geo-json',
    method: 'get',
    responseType: 'arraybuffer',
    params: params
  })
}




export function getBasicStatistics(params) {
  return request({
    url: 'api/traffics/statistics',
    method: 'get',
    params: params,
  })
}

export function getBasicChart(city_id) {
  return request({
    url: 'api/traffics/show?city_id='+city_id,
    method: 'get'
  })
}




//2.1.2

export function getNodeInOptions(params) {
  return request({
    url: 'api/traffic-node-flows/list',
    method: 'get',
    params: params
  })
}

export function getNodeList(params) {
  return request({
    url: 'api/traffic-node-flows',
    method: 'get',
    params: params
  })
}

export function getNodeGeojson(params) {

  params.format = 'geobuf';
  return request({
    url: 'api/traffic-node-flows/geo',
    method: 'get',
    responseType: 'arraybuffer',
    params: params
  })
}


export function getNodeStatistics(params) {

  return request({
    url: 'api/traffic-node-flows/statistics',
    method: 'get',
    params: params,
  })
}

export function getNodeChart(city_id) {
  return request({
    url: 'api/traffic-node-flows/show?city_id='+city_id,
    method: 'get'
  })
}

//2.1.2-2

export function getNodeOutOptions(params) {
  return request({
    url: 'api/traffic-node-flows/list-out',
    method: 'get',
    params: params
  })
}


//2.1.3

export function getPipeLengthOptions(params) {
  return request({
    url: 'api/traffic-line-levels/list',
    method: 'get',
    params: params
  })
}
export function getPipeLevelsOptions(params) {
    return request({
      url: 'api/traffic-line-levels/list-level',
      method: 'get',
      params: params
    })
  }

  export function getPipeSpeedOptions(params) {
    return request({
      url: 'api/traffic-line-levels/list-speed',
      method: 'get',
      params: params
    })
  }

  export function getPipeCapacityOptions(params) {
    return request({
      url: 'api/traffic-line-levels/list-capacity',
      method: 'get',
      params: params
    })
  }
  


export function getPipeLevelsList(params) {
  return request({
    url: 'api/traffic-line-levels',
    method: 'get',
    params: params
  })
}

export function getPipeLevelsPipeGeojson(params) {
  return request({
    url: 'api/traffic-line-levels/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}


export function getPipeLevelsStatistics(params) {

  return request({
    url: 'api/traffic-line-levels/statistics',
    method: 'get',
    params: params,
  })
}

export function getPipeLevelsChart(city_id) {
  return request({
    url: 'api/traffic-line-levels/show?city_id='+city_id,
    method: 'get'
  })
}


//2.1.4

export function getVolumnOptions(params) {
  return request({
    url: 'api/traffic-zone-flows/list',
    method: 'get',
    params: params
  })
}

export function getVolumnList(params) {
  return request({
    url: 'api/traffic-zone-flows',
    method: 'get',
    params: params
  })
}

export function getVolumnGeojson(params) {
  return request({
    url: 'api/traffic-zone-flows/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getVolumnStatistics(params) {

  return request({
    url: 'api/traffic-zone-flows/statistics',
    method: 'get',
    params: params,
  })
}

export function getVolumnChart(city_id) {
  return request({
    url: 'api/traffic-zone-flows/show?city_id='+city_id,
    method: 'get'
  })
}


export function getPipeVolumnOptions(params) {
    return request({
      url: 'api/traffic-line-flows/list',
      method: 'get',
      params: params
    })
  }
  
  export function getPipeVolumnList(params) {
    return request({
      url: 'api/traffic-line-flows',
      method: 'get',
      params: params
    })
  }
  
  export function getPipeVolumnGeojson(params) {
    return request({
      url: 'api/traffic-line-flows/geo',
      method: 'get',
      params: params,
      responseType: 'arraybuffer'
    })
  }
  
  export function getPipeVolumnStatistics(params) {
  
    return request({
      url: 'api/traffic-line-flows/statistics',
      method: 'get',
      params: params,
    })
  }
  
  export function getPipeVolumnChart(city_id) {
    return request({
      url: 'api/traffic-line-flows/show?city_id='+city_id,
      method: 'get'
    })
  }
  



//2.2.1

export function getPhysicallossPipeOptions(params) {
  return request({
    url: 'api/traffic-line-damages/list',
    method: 'get',
    params: params
  })
}

export function getPhysicallossPipeList(params) {
  return request({
    url: 'api/traffic-line-damages',
    method: 'get',
    params: params
  })
}

export function getPhysicallossPipeGeojson(params) {
  // var tmp = {}
  // Object.assign(tmp,params)
  // tmp.damage_probabilities = tmp.damage_probabilities_2
  return request({
    url: 'api/traffic-line-damages/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getPhysicallossPipeStatistics(params) {
  return request({
    url: 'api/traffic-line-damages/statistics',
    method: 'get',
    params: params,
  })
}

export function getPhysicallossPipeChart(city_id) {
  return request({
    url: 'api/traffic-line-damages/show?city_id='+city_id,
    method: 'get'
  })
}


//2.2.2

export function getJamOptions(params) {
  return request({
    url: 'api/traffic-line-jams/list',
    method: 'get',
    params: params
  })
}

export function getJamList(params) {
  
  return request({
    url: 'api/traffic-line-jams',
    method: 'get',
    params: params
  })
}

export function getJamGeojson(params) {
  return request({
    url: 'api/traffic-line-jams/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getJamStatistics(params) {
  return request({
    url: 'api/traffic-line-jams/statistics',
    method: 'get',
    params: params,
  })
}

export function getJamChart(city_id) {
  return request({
    url: 'api/traffic-line-jams/show?city_id='+city_id,
    method: 'get'
  })
}


//2.2.3

export function getZoneEfficiencyOptions(params) {
  return request({
    url: 'api/traffic-zone-travel-efficiencies/list',
    method: 'get',
    params: params
  })
}

export function getZoneEfficiencyList(params) {
  
  return request({
    url: 'api/traffic-zone-travel-efficiencies',
    method: 'get',
    params: params
  })
}

export function getZoneEfficiencyGeojson(params) {
  return request({
    url: 'api/traffic-zone-travel-efficiencies/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getZoneEfficiencyStatistics(params) {
  return request({
    url: 'api/traffic-zone-travel-efficiencies/statistics',
    method: 'get',
    params: params,
  })
}

export function getZoneEfficiencyChart(city_id) {
  return request({
    url: 'api/traffic-zone-travel-efficiencies/show?city_id='+city_id,
    method: 'get'
  })
}


//2.2.3-2

export function getCommunityEfficiencyOptions(params) {
  return request({
    url: 'api/traffic-community-travel-efficiencies/list',
    method: 'get',
    params: params
  })
}

export function getCommunityEfficiencyList(params) {
  
  return request({
    url: 'api/traffic-community-travel-efficiencies',
    method: 'get',
    params: params
  })
}

export function getCommunityEfficiencyGeojson(params) {
  return request({
    url: 'api/traffic-community-travel-efficiencies/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getCommunityEfficiencyStatistics(params) {
  return request({
    url: 'api/traffic-community-travel-efficiencies/statistics',
    method: 'get',
    params: params,
  })
}

export function getCommunityEfficiencyChart(city_id) {
  return request({
    url: 'api/traffic-community-travel-efficiencies/show?city_id='+city_id,
    method: 'get'
  })
}




//2.2.4

export function getImportanceOptions(params) {
  return request({
    url: 'api/traffic-line-importances/list',
    method: 'get',
    params: params
  })
}

export function getImportanceList(params) {
  
  return request({
    url: 'api/traffic-line-importances',
    method: 'get',
    params: params
  })
}

export function getImportanceGeojson(params) {
  return request({
    url: 'api/traffic-line-importances/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getImportanceStatistics(params) {
  return request({
    url: 'api/traffic-line-importances/statistics',
    method: 'get',
    params: params,
  })
}

export function getImportanceChart(city_id) {
  return request({
    url: 'api/traffic-line-importances/show?city_id='+city_id,
    method: 'get'
  })
}

//2.2.5

export function getRiskOptions(params) {
  return request({
    url: 'api/traffic-street-risks/list',
    method: 'get',
    params: params
  })
}

export function getRiskList(params) {
  
  return request({
    url: 'api/traffic-street-risks',
    method: 'get',
    params: params
  })
}

export function getRiskGeojson(params) {
  return request({
    url: 'api/traffic-street-risks/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getRiskStatistics(params) {
  return request({
    url: 'api/traffic-street-risks/statistics',
    method: 'get',
    params: params,
  })
}

export function getRiskChart(city_id) {
  return request({
    url: 'api/traffic-street-risks/show?city_id='+city_id,
    method: 'get'
  })
}


//2.3.1

export function getReinforceOptions(params) {
  return request({
    url: 'api/traffic-line-reinforcements/list',
    method: 'get',
    params: params
  })
}

export function getReinforceList(params) {
  
  return request({
    url: 'api/traffic-line-reinforcements',
    method: 'get',
    params: params
  })
}

export function getReinforceGeojson(params) {
  return request({
    url: 'api/traffic-line-reinforcements/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getReinforceStatistics(params) {
  return request({
    url: 'api/traffic-line-reinforcements/statistics',
    method: 'get',
    params: params,
  })
}

export function getReinforceChart(city_id) {
  return request({
    url: 'api/traffic-line-reinforcements/show?city_id='+city_id,
    method: 'get'
  })
}



//2.3.2-1
export function getRepairPipeOptions(params) {
  return request({
    url: 'api/traffic-line-repairs/list',
    method: 'get',
    params: params
  })
}

export function getRepairPipeList(params) {
  
  return request({
    url: 'api/traffic-line-repairs',
    method: 'get',
    params: params
  })
}

export function getRepairPipeGeojson(params) {
  return request({
    url: 'api/traffic-line-repairs/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getRepairPipeStatistics(params) {
  return request({
    url: 'api/traffic-line-repairs/statistics',
    method: 'get',
    params: params,
  })
}

export function getRepairPipeChart(city_id) {
  return request({
    url: 'api/traffic-line-repairs/show?city_id='+city_id,
    method: 'get'
  })
}


//2.3.2-2

export function getFunctionElectricOptions(params) {
  return request({
    url: 'api/traffic-community-travels/list',
    method: 'get',
    params: params
  })
}

export function getFunctionElectricList(params) {
  
  return request({
    url: 'api/traffic-community-travels',
    method: 'get',
    params: params
  })
}

export function getFunctionElectricGeojson(params) {
  return request({
    url: 'api/traffic-community-travels/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getFunctionElectricStatistics(params) {
  return request({
    url: 'api/traffic-community-travels/statistics',
    method: 'get',
    params: params,
  })
}

export function getFunctionElectricChart(city_id) {
  return request({
    url: 'api/traffic-community-travels/show?city_id='+city_id,
    method: 'get'
  })
}


//
export function getFunctionPipeOptions(params) {
  return request({
    url: 'api/traffic-line-travels/list',
    method: 'get',
    params: params
  })
}

export function getFunctionPipeList(params) {
  
  return request({
    url: 'api/traffic-line-travels',
    method: 'get',
    params: params
  })
}

export function getFunctionPipeGeojson(params) {
  return request({
    url: 'api/traffic-line-travels/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getFunctionPipeStatistics(params) {
  return request({
    url: 'api/traffic-line-travels/statistics',
    method: 'get',
    params: params,
  })
}

export function getFunctionPipeChart(city_id) {
  return request({
    url: 'api/traffic-line-travels/show?city_id='+city_id,
    method: 'get'
  })
}

