import request from '@/util/request';

export function getCategories() {
    return request({
      url: 'api/building-categories',
      method: 'get'
    })
}

export function getStreets(city_id) {
  return request({
    url: 'api/streets?city_id='+city_id,
    method: 'get'
  })
}
export function getStreetsHideaway(city_id) {
  return request({
    url: 'api/hideaways/street?city_id='+city_id,
    method: 'get'
  })
}
export function getStreetsHospital(city_id) {
  return request({
    url: 'api/serious-injuries/street?city_id='+city_id+'&category=0',
    method: 'get'
  })
}
export function getStreetsWarehouse(city_id) {
  return request({
    url: 'api/warehouses/street?city_id='+city_id,
    method: 'get'
  })
}

export function getCommunities(city_id, street_id) {
  return request({
    url: 'api/communities?city_id='+city_id+'&street_id='+street_id,
    method: 'get'
  })
}
export function getCommunitiesHideaway(city_id, street_id) {
  return request({
    url: 'api/hideaways/community?city_id='+city_id+'&street_id='+street_id,
    method: 'get'
  })
}
export function getCommunitiesHospital(city_id, street_id) {
  return request({
    url: 'api/serious-injuries/community?city_id='+city_id+'&street_id='+street_id+'&category=0',
    method: 'get'
  })
}
export function getCommunitiesWarehouse(city_id, street_id) {
  return request({
    url: 'api/warehouses/community?city_id='+city_id+'&street_id='+street_id,
    method: 'get'
  })
}

export function getList(params) {
  return request({
    url: 'api/buildings',
    method: 'get',
    params: params
  })
}

export function getGeoJson(params) {
  params.format = 'geobuf';
  return request({
    url: 'api/buildings-geo-json',
    method: 'get',
    responseType: 'arraybuffer',
    params: params
  })
}
//加固比例-百分比列表
export function getReinforceList(params) {
  // return request({
  //   url: 'api/buildings-geo-json',
  //   method: 'get',
  //   params: params
  // })
}

//避难所列表
export function getShelters(city_id) {
  return request({
    url: 'api/shelters/list?city_id='+city_id,
    method: 'get'
  })
  // return new Promise(function(resolve, reject){
  //   let data = {"code":0,"data":[
  //     {"id":1, "避难所ID": "避难所1 (S1)", "方案1": 0, "方案2": 0, "方案3": 0, "方案4": 0, "方案5": 0, "方案6": 0, "方案7": 0, "方案8": 0, "方案9": 0, "方案10": 0, "方案11": 0, "方案12": 0, "方案13": 0, "方案14": 0, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":2, "避难所ID": "避难所2 (S2)", "方案1": 0, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 0, "方案16": 0, "方案17": 0, "方案18": 0, "方案19": 0, "方案20": 0, "方案21": 0, "方案22": 0, "方案23": 0, "方案24": 0, "方案25": 0, "方案26": 0, "方案27": 0, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":3, "避难所ID": "避难所3 (S3)", "方案1": 1, "方案2": 0, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 0, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 0, "方案29": 0, "方案30": 0, "方案31": 0, "方案32": 0, "方案33": 0, "方案34": 0, "方案35": 0, "方案36": 0, "方案37": 0, "方案38": 0, "方案39": 0, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":4, "避难所ID": "避难所4 (S4)", "方案1": 1, "方案2": 1, "方案3": 0, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 0, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 0, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 0, "方案41": 0, "方案42": 0, "方案43": 0, "方案44": 0, "方案45": 0, "方案46": 0, "方案47": 0, "方案48": 0, "方案49": 0, "方案50": 0, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":5, "避难所ID": "避难所5 (S5)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 0, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 0, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 0, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 0, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 0, "方案52": 0, "方案53": 0, "方案54": 0, "方案55": 0, "方案56": 0, "方案57": 0, "方案58": 0, "方案59": 0, "方案60": 0, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":6, "避难所ID": "避难所6 (S6)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 0, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 0, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 0, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 0, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 0, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 0, "方案62": 0, "方案63": 0, "方案64": 0, "方案65": 0, "方案66": 0, "方案67": 0, "方案68": 0, "方案69": 0, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":7, "避难所ID": "避难所7 (S7)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 0, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 0, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 0, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 0, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 0, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 0, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 0, "方案71": 0, "方案72": 0, "方案73": 0, "方案74": 0, "方案75": 0, "方案76": 0, "方案77": 0, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":8, "避难所ID": "避难所8 (S8)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 0, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 0, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 0, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 0, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 0, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 0, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 0, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 0, "方案79": 0, "方案80": 0, "方案81": 0, "方案82": 0, "方案83": 0, "方案84": 0, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":9, "避难所ID": "避难所9 (S9)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 0, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 0, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 0, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 0, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 0, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 0, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 0, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 0, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 0, "方案86": 0, "方案87": 0, "方案88": 0, "方案89": 0, "方案90": 0, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":10, "避难所ID": "避难所10 S(10)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 0, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 0, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 0, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 0, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 0, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 0, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 0, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 0, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 0, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 0, "方案92": 0, "方案93": 0, "方案94": 0, "方案95": 0, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":11, "避难所ID": "避难所11 S(11)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 0, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 0, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 0, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 0, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 0, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 0, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 0, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 0, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 0, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 0, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 0, "方案97": 0, "方案98": 0, "方案99": 0, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":12, "避难所ID": "避难所12 S(12)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 0, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 0, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 0, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 0, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 0, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 0, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 0, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 0, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 0, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 0, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 0, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 0, "方案101": 0, "方案102": 0, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":13, "避难所ID": "避难所13 S(13)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 0, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 0, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 0, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 0, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 0, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 0, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 0, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 0, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 0, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 0, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 0, "方案98": 1, "方案99": 1, "方案100": 0, "方案101": 1, "方案102": 1, "方案103": 0, "方案104": 0, "方案105": 1, "方案106": 1},
  //     {"id":14, "避难所ID": "避难所14 S(14)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 0, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 0, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 0, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 0, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 0, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 0, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 0, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 0, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 0, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 0, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 0, "方案99": 1, "方案100": 1, "方案101": 0, "方案102": 1, "方案103": 0, "方案104": 1, "方案105": 0, "方案106": 1}
  //     ]};
  //   resolve(data);
  // })
}
//获取避难所方案
export function getSheltersPlan(city_id,params) {
  //params:00111111111111 方案1
  return request({
    url: 'api/shelters/decision?city_id='+city_id+'&decision='+params,
    method: 'get'
  })
  // return new Promise(function(resolve, reject){
  //   let data = {"code":0,"data":1};
  //   resolve(data);
  // })
}

//获取医疗点
export function getRescueList(city_id,type) {
  var category = 1;
  if(type == 'slight') category = 0;
  //0：轻伤；1：重伤
  
  return request({
    url: 'api/serious-injuries/list?city_id='+city_id+'&category='+category,
    method: 'get'
  })
  //type 'serious'重伤or'slight'轻伤
  // return new Promise(function(resolve, reject){
  //   let data = {"code":0,"data":[
  //     {"id":1, "医疗点ID": "医疗点1 (S1)", "方案1": 0, "方案2": 0, "方案3": 0, "方案4": 0, "方案5": 0, "方案6": 0, "方案7": 0, "方案8": 0, "方案9": 0, "方案10": 0, "方案11": 0, "方案12": 0, "方案13": 0, "方案14": 0, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":2, "医疗点ID": "医疗点2 (S2)", "方案1": 0, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 0, "方案16": 0, "方案17": 0, "方案18": 0, "方案19": 0, "方案20": 0, "方案21": 0, "方案22": 0, "方案23": 0, "方案24": 0, "方案25": 0, "方案26": 0, "方案27": 0, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":3, "医疗点ID": "医疗点3 (S3)", "方案1": 1, "方案2": 0, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 0, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 0, "方案29": 0, "方案30": 0, "方案31": 0, "方案32": 0, "方案33": 0, "方案34": 0, "方案35": 0, "方案36": 0, "方案37": 0, "方案38": 0, "方案39": 0, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":4, "医疗点ID": "医疗点4 (S4)", "方案1": 1, "方案2": 1, "方案3": 0, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 0, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 0, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 0, "方案41": 0, "方案42": 0, "方案43": 0, "方案44": 0, "方案45": 0, "方案46": 0, "方案47": 0, "方案48": 0, "方案49": 0, "方案50": 0, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":5, "医疗点ID": "医疗点5 (S5)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 0, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 0, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 0, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 0, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 0, "方案52": 0, "方案53": 0, "方案54": 0, "方案55": 0, "方案56": 0, "方案57": 0, "方案58": 0, "方案59": 0, "方案60": 0, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":6, "医疗点ID": "医疗点6 (S6)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 0, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 0, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 0, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 0, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 0, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 0, "方案62": 0, "方案63": 0, "方案64": 0, "方案65": 0, "方案66": 0, "方案67": 0, "方案68": 0, "方案69": 0, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":7, "医疗点ID": "医疗点7 (S7)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 0, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 0, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 0, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 0, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 0, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 0, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 0, "方案71": 0, "方案72": 0, "方案73": 0, "方案74": 0, "方案75": 0, "方案76": 0, "方案77": 0, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":8, "医疗点ID": "医疗点8 (S8)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 0, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 0, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 0, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 0, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 0, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 0, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 0, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 0, "方案79": 0, "方案80": 0, "方案81": 0, "方案82": 0, "方案83": 0, "方案84": 0, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":9, "医疗点ID": "医疗点9 (S9)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 0, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 0, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 0, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 0, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 0, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 0, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 0, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 0, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 0, "方案86": 0, "方案87": 0, "方案88": 0, "方案89": 0, "方案90": 0, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":10, "医疗点ID": "医疗点10 S(10)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 0, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 0, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 0, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 0, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 0, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 0, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 0, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 0, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 0, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 0, "方案92": 0, "方案93": 0, "方案94": 0, "方案95": 0, "方案96": 1, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":11, "医疗点ID": "医疗点11 S(11)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 0, "方案11": 1, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 0, "方案24": 1, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 0, "方案36": 1, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 0, "方案47": 1, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 0, "方案57": 1, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 0, "方案66": 1, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 0, "方案74": 1, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 0, "方案81": 1, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 0, "方案87": 1, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 0, "方案92": 1, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 0, "方案97": 0, "方案98": 0, "方案99": 0, "方案100": 1, "方案101": 1, "方案102": 1, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":12, "医疗点ID": "医疗点12 S(12)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 0, "方案12": 1, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 0, "方案25": 1, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 0, "方案37": 1, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 0, "方案48": 1, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 0, "方案58": 1, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 0, "方案67": 1, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 0, "方案75": 1, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 0, "方案82": 1, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 0, "方案88": 1, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 0, "方案93": 1, "方案94": 1, "方案95": 1, "方案96": 0, "方案97": 1, "方案98": 1, "方案99": 1, "方案100": 0, "方案101": 0, "方案102": 0, "方案103": 1, "方案104": 1, "方案105": 1, "方案106": 1},
  //     {"id":13, "医疗点ID": "医疗点13 S(13)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 0, "方案13": 1, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 0, "方案26": 1, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 0, "方案38": 1, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 0, "方案49": 1, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 0, "方案59": 1, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 0, "方案68": 1, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 0, "方案76": 1, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 0, "方案83": 1, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 0, "方案89": 1, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 0, "方案94": 1, "方案95": 1, "方案96": 1, "方案97": 0, "方案98": 1, "方案99": 1, "方案100": 0, "方案101": 1, "方案102": 1, "方案103": 0, "方案104": 0, "方案105": 1, "方案106": 1},
  //     {"id":14, "医疗点ID": "医疗点14 S(14)", "方案1": 1, "方案2": 1, "方案3": 1, "方案4": 1, "方案5": 1, "方案6": 1, "方案7": 1, "方案8": 1, "方案9": 1, "方案10": 1, "方案11": 1, "方案12": 1, "方案13": 0, "方案14": 1, "方案15": 1, "方案16": 1, "方案17": 1, "方案18": 1, "方案19": 1, "方案20": 1, "方案21": 1, "方案22": 1, "方案23": 1, "方案24": 1, "方案25": 1, "方案26": 0, "方案27": 1, "方案28": 1, "方案29": 1, "方案30": 1, "方案31": 1, "方案32": 1, "方案33": 1, "方案34": 1, "方案35": 1, "方案36": 1, "方案37": 1, "方案38": 0, "方案39": 1, "方案40": 1, "方案41": 1, "方案42": 1, "方案43": 1, "方案44": 1, "方案45": 1, "方案46": 1, "方案47": 1, "方案48": 1, "方案49": 0, "方案50": 1, "方案51": 1, "方案52": 1, "方案53": 1, "方案54": 1, "方案55": 1, "方案56": 1, "方案57": 1, "方案58": 1, "方案59": 0, "方案60": 1, "方案61": 1, "方案62": 1, "方案63": 1, "方案64": 1, "方案65": 1, "方案66": 1, "方案67": 1, "方案68": 0, "方案69": 1, "方案70": 1, "方案71": 1, "方案72": 1, "方案73": 1, "方案74": 1, "方案75": 1, "方案76": 0, "方案77": 1, "方案78": 1, "方案79": 1, "方案80": 1, "方案81": 1, "方案82": 1, "方案83": 0, "方案84": 1, "方案85": 1, "方案86": 1, "方案87": 1, "方案88": 1, "方案89": 0, "方案90": 1, "方案91": 1, "方案92": 1, "方案93": 1, "方案94": 0, "方案95": 1, "方案96": 1, "方案97": 1, "方案98": 0, "方案99": 1, "方案100": 1, "方案101": 0, "方案102": 1, "方案103": 0, "方案104": 1, "方案105": 0, "方案106": 1}
  //     ]};
  //   resolve(data);
  // })
}

//获取医疗点方案
export function getRescuePlan(city_id,params) {
  //params:00111111111111 方案1
  return new Promise(function(resolve, reject){
    let data = {"code":0,"data":1};
    resolve(data);
  })
}

//获取2.3.1灾前建筑加固列表
export function getReinforcePrioritiesList(params) {
  return request({
    url: 'api/reinforcement-priorities',
    method: 'get',
    params: params
  })
}


//获取2.3.1灾前建筑加固Geojson
export function getReinforcePrioritiesGeojson(params) {
  return request({
    url: 'api/reinforcement-priorities/geo',
    method: 'get',
    params: params, 
    responseType: 'arraybuffer'
  })
}




//获取2.3.1灾后建筑加固Geojson
export function getReinforceaAfterGeojson(params) {
  return request({
    url: 'api/reinforcement-effects/geo',
    method: 'get',
    params: params, 
    responseType: 'arraybuffer'
  })
}

//2.3.2 避难所街道
export function getShelterStreets(city_id) {
  return request({
    url: 'api/shelters/street?city_id='+city_id,
    method: 'get'
  })
}

//2.3.2 避难所社区
export function getShelterCommunities(city_id, street_id) {
  return request({
    url: 'api/shelters/community?city_id='+city_id+'&street_id='+street_id,
    method: 'get'
  })
}

//2.3.2 避难所列表
export function getShelterList(params) {
  return request({
    url: 'api/shelters',
    method: 'get',
    params: params
  })
}

//2.3.2 避难所geojson
export function getShelterGeojson(params) {
  return request({
    url: 'api/shelters/geo-shelter',
    method: 'get',
    params: params, 
    responseType: 'arraybuffer'
  })
}

//2.3.2 避难所飞线geojson
export function getShelterLineGeojson(params) {
  return request({
    url: 'api/shelters/geo',
    method: 'get',
    params: params, 
    responseType: 'arraybuffer'
  })
}

//2.3.2 避难所block geojson
export function getShelterBlockGeojson(params) {
  return request({
    url: 'api/shelters/geo-village',
    method: 'get',
    params: params, 
    //responseType: 'arraybuffer'
  })
}

//2.3.3 医疗点街道
export function getRescueStreets(city_id,category) {
  return request({
    url: 'api/serious-injuries/street?city_id='+city_id+'&category='+category,
    method: 'get'
  })
}

//2.3.3 医疗点社区
export function getRescueCommunities(city_id, street_id, category) {
  return request({
    url: 'api/serious-injuries/community?city_id='+city_id+'&street_id='+street_id+'&category='+category,
    method: 'get'
  })
}

//2.3.3 重伤医疗点列表
export function getSeriousList(params) {
  return request({
    url: 'api/serious-injuries',
    method: 'get',
    params: params
  })
}

//2.3.3 重伤医疗点geojson
export function getSeriousGeojson(params) {
  return request({
    url: 'api/serious-injuries/geo-hospital',
    method: 'get',
    params: params, 
    responseType: 'arraybuffer'
  })
}

//2.3.3 重伤医疗点飞线geojson
export function getSeriousLineGeojson(params) {
  return request({
    url: 'api/serious-injuries/geo',
    method: 'get',
    params: params, 
    responseType: 'arraybuffer'
  })
}

//2.3.3 重伤医疗点block geojson
export function getSeriousBlockGeojson(params) {
  return request({
    url: 'api/serious-injuries/geo-village',
    method: 'get',
    params: params, 
    responseType: 'arraybuffer'
  })
}

//2.3.3 轻伤医疗点列表
export function getSlightList(params) {
  return request({
    url: 'api/minor-injuries',
    method: 'get',
    params: params
  })
}

//2.3.3 轻伤医疗点geojson
export function getSlightGeojson(params) {
  return request({
    url: 'api/minor-injuries/geo-hospital',
    method: 'get',
    params: params, 
    responseType: 'arraybuffer'
  })
}

//2.3.3 轻伤医疗点飞线geojson
export function getSlightLineGeojson(params) {
  return request({
    url: 'api/minor-injuries/geo',
    method: 'get',
    params: params, 
    responseType: 'arraybuffer'
  })
}

//2.3.3 轻伤医疗点block geojson
export function getSlightBlockGeojson(params) {
  return request({
    url: 'api/minor-injuries/geo-village',
    method: 'get',
    params: params, 
    responseType: 'arraybuffer'
  })
}


//2.3.4 恢复街道
export function getRestorationStreets(city_id) {
  return request({
    url: 'api/community-restorations/street?city_id='+city_id,
    method: 'get'
  })
}

//2.3.4 恢复社区
export function getRestorationCommunities(city_id, street_id) {
  return request({
    url: 'api/community-restorations/community?city_id='+city_id+'&street_id='+street_id,
    method: 'get'
  })
}

//2.3.4 小区列表
export function getRestorationCommunityList(params) {
  return request({
    url: 'api/community-restorations',
    method: 'get',
    params: params,
  })
}
//2.3.4 小区geojson
export function getRestorationCommunityGeojson(params) {
  return request({
    url: 'api/community-restorations/geo',
    method: 'get',
    params: params, 
    //responseType: 'arraybuffer'
  })
}

//2.3.4 建筑列表
export function getRestorationBuildingList(params) {
  return request({
    url: 'api/building-restorations',
    method: 'get',
    params: params,
  })
}
//2.3.4 建筑geojson
export function getRestorationBuildingGeojson(params) {
  return request({
    url: 'api/building-restorations/geo',
    method: 'get',
    params: params, 
    responseType: 'arraybuffer'
  })
}

//2.3.4 工人数列表
export function getRestorationMaintenanceList(params) {
  return request({
    url: 'api/maintenance-resources',
    method: 'get',
    params: params,
  })
}
//2.3.4 工人geojson
export function getRestorationMaintenanceGeojson(params) {
  return request({
    url: 'api/maintenance-resources/geo',
    method: 'get',
    params: params, 
    responseType: 'arraybuffer'
  })
}

//2.2.1 列表
export function getQuakeList(params) {
  return request({
    url: 'api/earthquake-analyses',
    method: 'get',
    params: params,
  })
}
//2.3.4 geojson
export function getQuakeGeojson(params) {
  return request({
    url: 'api/earthquake-analyses/geo',
    method: 'get',
    params: params, 
    responseType: 'arraybuffer'
  })
}

//2.2.2 结构损伤
export function getPhysicalList(params) {
  return request({
    url: 'api/structural-damages',
    method: 'get',
    params: params,
  })
}
//2.2.2 结构损伤 geojson
export function getPhysicalGeojson(params) {
  return request({
    url: 'api/structural-damages/geo',
    method: 'get',
    params: params, 
    responseType: 'arraybuffer'
  })
}

//2.2.2 非结构损伤
export function getUnPhysicalList(params) {
  return request({
    url: 'api/non-structural-damages',
    method: 'get',
    params: params,
  })
}
//2.2.2 非结构损伤 geojson
export function getUnPhysicalGeojson(params) {
  return request({
    url: 'api/non-structural-damages/geo',
    method: 'get',
    params: params, 
    responseType: 'arraybuffer'
  })
}

//2.2.4 综合风险指数
export function getrisksList(params) {
  return request({
    url: 'api/comprehensive-risks',
    method: 'get',
    params: params,
  })
}
//2.2.4 综合风险指数 geojson
export function getrisksGeojson(params) {
  return request({
    url: 'api/comprehensive-risks/geo',
    method: 'get',
    params: params, 
    responseType: 'arraybuffer'
  })
}

//2.2.3 直接经济损失
export function getEconomicLossesList(params) {
  return request({
    url: 'api/economic-losses',
    method: 'get',
    params: params,
  })
}
//2.2.3 直接经济损失 geojson
export function getEconomicLossesGeojson(params) {
  return request({
    url: 'api/economic-losses/geo',
    method: 'get',
    params: params, 
    responseType: 'arraybuffer'
  })
}

//2.2.3 直接经济损失率
export function getEconomicLossesRateList(params) {
  return request({
    url: 'api/economic-loss-rates',
    method: 'get',
    params: params,
  })
}
//2.2.3 直接经济损失率 geojson
export function getEconomicLossesRateGeojson(params) {
  return request({
    url: 'api/economic-loss-rates/geo',
    method: 'get',
    params: params, 
    responseType: 'arraybuffer'
  })
}

//2.2.3 凌晨2点死伤
export function getmorningInjureList(params) {
  return request({
    url: 'api/morning-casualties',
    method: 'get',
    params: params,
  })
}
//2.2.3 凌晨2点死伤 geojson
export function getmorningInjureGeojson(params) {
  return request({
    url: 'api/morning-casualties/geo',
    method: 'get',
    params: params, 
    responseType: 'arraybuffer'
  })
}

//2.2.3 下午2点死伤
export function getAfternoonInjureList(params) {
  return request({
    url: 'api/afternoon-casualties',
    method: 'get',
    params: params,
  })
}
//2.2.3 下午2点死伤 geojson
export function getAfternoonInjureGeojson(params) {
  return request({
    url: 'api/afternoon-casualties/geo',
    method: 'get',
    params: params, 
    responseType: 'arraybuffer'
  })
}

//2.2.3 社区叠加图层 geojson
export function getInjureBlockGeojson(params) {
  return request({
    url: 'api/afternoon-casualties/geo-population',
    method: 'get',
    params: params, 
    responseType: 'arraybuffer'
  })
}

//2.2.3 功能损失
export function getFunctionLossList(params) {
  return request({
    url: 'api/functional-status',
    method: 'get',
    params: params,
  })
}
//2.2.3 功能损失 geojson
export function getFunctionLossGeojson(params) {
  return request({
    url: 'api/functional-status/geo',
    method: 'get',
    params: params, 
    responseType: 'arraybuffer'
  })
}

//2.2.3 离家住户
export function getLeavehomeList(params) {
  return request({
    url: 'api/leave-homes',
    method: 'get',
    params: params,
  })
}
//2.2.3 离家住户 geojson
export function getLeavehomeGeojson(params) {
  return request({
    url: 'api/leave-homes/geo',
    method: 'get',
    params: params, 
    responseType: 'arraybuffer'
  })
}

//2.2.3 离家住户率
export function getLeavehomeRateList(params) {
  return request({
    url: 'api/leave-home-rates',
    method: 'get',
    params: params,
  })
}
//2.2.3 离家住户率 geojson
export function getLeavehomeRateGeojson(params) {
  return request({
    url: 'api/leave-home-rates/geo',
    method: 'get',
    params: params, 
    responseType: 'arraybuffer'
  })
}

//2.2.3 住户数叠加 geojson
export function getHouseTotalGeojson(params) {
  return request({
    url: 'api/leave-homes/geo-household',
    method: 'get',
    params: params, 
    responseType: 'arraybuffer'
  })
}

//2.2.5 ff能用概率
export function getFFList(params) {
  return request({
    url: 'api/functional-recovery-deductions',
    method: 'get',
    params: params,
  })
}
//2.2.5 ff能用概率 geojson
export function getFFGeojson(params) {
  return request({
    url: 'api/functional-recovery-deductions/geo',
    method: 'get',
    params: params, 
    responseType: 'arraybuffer'
  })
}

//2.2.5 功能恢复时间
export function getRecoveryTimeList(params) {
  return request({
    url: 'api/functional-recovery-times',
    method: 'get',
    params: params,
  })
}
//2.2.5 功能恢复时间 geojson
export function getRecoveryTimeGeojson(params) {
  return request({
    url: 'api/functional-recovery-times/geo',
    method: 'get',
    params: params, 
    responseType: 'arraybuffer'
  })
}

//Statistics 2.1
export function getStructStatistics(params) {
  return request({
    url: 'api/statistics/building-struct',
    method: 'get',
    params: params,
  })
}
export function getCategoryStatistics(params) {
  return request({
    url: 'api/statistics/building-category',
    method: 'get',
    params: params,
  })
}
export function getQuakeLevelStatistics(params) {
  return request({
    url: 'api/statistics/building-quake-level',
    method: 'get',
    params: params,
  })
}
export function getBuildingYearStatistics(params) {
  return request({
    url: 'api/statistics/building-year',
    method: 'get',
    params: params,
  })
}
export function getRebuildCostStatistics(params) {
  return request({
    url: 'api/statistics/building-rebuild-cost',
    method: 'get',
    params: params,
  })
}
//2.2.1
export function getPgaStatistics(params) {
  return request({
    url: 'api/statistics/building-pga',
    method: 'get',
    params: params,
  })
}
//2.2.2
export function getStructuralDamageStatistics(params) {
  return request({
    url: 'api/structural-damages/statistics',
    method: 'get',
    params: params,
  })
}

export function getNonStructuralDamageStatistics(params) {
  return request({
    url: 'api/non-structural-damages/statistics',
    method: 'get',
    params: params,
  })
}

//2.2.3
export function getEconomicLossStatistics(params) {
  return request({
    url: 'api/economic-losses/statistics',
    method: 'get',
    params: params,
  })
}
export function getEconomicLossRateStatistics(params) {
  return request({
    url: 'api/economic-loss-rates/statistics',
    method: 'get',
    params: params,
  })
}
export function getMorningStatistics(params) {
  return request({
    url: 'api/morning-casualties/statistics',
    method: 'get',
    params: params,
  })
}
export function getAfternoonStatistics(params) {
  return request({
    url: 'api/afternoon-casualties/statistics',
    method: 'get',
    params: params,
  })
}
export function getTenantsStatistics(params) {
  return request({
    url: 'api/leave-homes/statistics',
    method: 'get',
    params: params,
  })
}
export function getTenantsRateStatistics(params) {
  return request({
    url: 'api/leave-home-rates/statistics',
    method: 'get',
    params: params,
  })
}
export function getFunctionalStatusStatistics(params) {
  return request({
    url: 'api/functional-status/statistics',
    method: 'get',
    params: params,
  })
}
//2.2.4
export function getRiskStatistics(params) {
  return request({
    url: 'api/comprehensive-risks/statistics',
    method: 'get',
    params: params,
  })
}

//2.2.5
export function getRecoveryShowStatistics(params) {
  return request({
    url: 'api/functional-recovery-deductions/statistics',
    method: 'get',
    params: params,
  })
}
export function getRecoveryTimeStatistics(params) {
  return request({
    url: 'api/functional-recovery-times/statistics',
    method: 'get',
    params: params,
  })
}

//2.3.1
export function getBeforeReinforceStatistics(params) {
  return request({
    url: 'api/reinforcement-priorities/statistics',
    method: 'get',
    params: params,
  })
}

//2.3.2
export function getShelterStatistics(params) {
  return request({
    url: 'api/shelters/statistics',
    method: 'get',
    params: params,
  })
}
//2.3.3
export function getSeriousStatistics(params) {
  return request({
    url: 'api/serious-injuries/statistics',
    method: 'get',
    params: params,
  })
}


export function getSlightStatistics(params) {
  return request({
    url: 'api/minor-injuries/statistics',
    method: 'get',
    params: params,
  })
}
//2.3.4
export function getRecoveryCommunitiesStatistics(params) {
  return request({
    url: 'api/community-restorations/statistics',
    method: 'get',
    params: params,
  })
}


export function getRecoveryBuildingstStatistics(params) {
  return request({
    url: 'api/building-restorations/statistics',
    method: 'get',
    params: params,
  })
}

export function getRecoveryResourcetStatistics(params) {
  return request({
    url: 'api/building-restorations/statistics',
    method: 'get',
    params: params,
  })
}

//搜索建筑id
export function getBuidingGeo(params) {
  params.format = 'geobuf';
  return request({
    url: 'api/buildings/geo',
    method: 'get',
    responseType: 'arraybuffer',
    params: params
  })
}



//risk
export function getRiskOptions(params) {
  return request({
    url: 'api/building-street-risks/list',
    method: 'get',
    params: params
  })
}

export function getRiskList(params) {
  
  return request({
    url: 'api/building-street-risks',
    method: 'get',
    params: params
  })
}

export function getRiskGeojson(params) {
  return request({
    url: 'api/building-street-risks/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getRiskStatistics2(params) {
  return request({
    url: 'api/building-street-risks/statistics',
    method: 'get',
    params: params,
  })
}

export function getRiskChart(city_id) {
  return request({
    url: 'api/building-street-risks/show?city_id='+city_id,
    method: 'get'
  })
}


//recovery update


export function getRecoveryStreet(city_id) {
  return request({
    url: 'api/building-village-repairs/street?city_id='+city_id,
    method: 'get'
  })
}


export function getRecoveryCommunities(city_id, street_id) {
  return request({
    url: 'api/building-village-repairs/community?city_id='+city_id+'&street_id='+street_id,
    method: 'get'
  })
}

export function getRecoveryCommunityOptions(params) {
  return request({
    url: 'api/building-village-repairs/list',
    method: 'get',
    params: params
  })
}

export function getRecoveryCommunityList(params) {
  
  return request({
    url: 'api/building-village-repairs',
    method: 'get',
    params: params
  })
}

export function getRecoveryCommunityGeojson(params) {
  return request({
    url: 'api/building-village-repairs/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getRecoveryCommunitiesBlockGeo(params) {
  return request({
    url: 'api/building-village-repairs/geo-village',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}


export function getRecoveryCommunityStatistics(params) {
  return request({
    url: 'api/building-village-repairs/statistics',
    method: 'get',
    params: params,
  })
}

export function getRecoveryCommunityChart(city_id, worker_number) {
  return request({
    url: 'api/building-village-repairs/show?city_id='+city_id+'&type='+(parseInt(worker_number)==20000?1:2),
    method: 'get'
  })
}



//recovery village resources
export function getRecoveryResourceOptions(params) {
  return request({
    url: 'api/building-village-resources/list',
    method: 'get',
    params: params
  })
}

export function getRecoveryResourceList(params) {
  
  return request({
    url: 'api/building-village-resources',
    method: 'get',
    params: params
  })
}

export function getRecoveryResourceGeojson(params) {
  return request({
    url: 'api/building-village-resources/geo',
    //url: 'api/building-village-resources/geo-village',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getRecoveryResourceStatistics(params) {
  return request({
    url: 'api/building-village-resources/statistics',
    method: 'get',
    params: params,
  })
}

export function getRecoveryResourceChart(city_id, worker_number,day) {
  return request({
    url: 'api/building-village-resources/show?city_id='+city_id+'&type='+(parseInt(worker_number)==20000?1:2)+'&in_day='+parseInt(day),
    method: 'get'
  })
}



//recovery building

export function getRecoveryBuildingOptions(params) {
  return request({
    url: 'api/building-building-repairs/list',
    method: 'get',
    params: params
  })
}

export function getRecoveryBuildingList(params) {
  
  return request({
    url: 'api/building-building-repairs',
    method: 'get',
    params: params
  })
}

export function getRecoveryBuildingGeojson(params) {
  return request({
    url: 'api/building-building-repairs/geo',
    method: 'get',
    params: params,
    //responseType: 'arraybuffer'
  })
}

export function getRecoveryBuildingOutlineGeojson(params) {
  return request({
    url: 'api/building-building-repairs/geo-default',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getRecoveryBuildingStatistics(params) {
  return request({
    url: 'api/building-building-repairs/statistics',
    method: 'get',
    params: params,
  })
}

export function getRecoveryBuildingChart(city_id, worker_number,day) {
  return request({
    url: 'api/building-building-repairs/show?city_id='+city_id+'&type='+(parseInt(worker_number)==20000?1:2)+'&in_day='+parseInt(day),
    method: 'get'
  })
}

export function getRecoveryBuildingTotalChart(city_id, worker_number,day) {
  return request({
    url: 'api/building-building-repairs/show-street?city_id='+city_id+'&type='+(parseInt(worker_number)==20000?1:2)+'&in_day='+parseInt(day),
    method: 'get'
  })
}


//function recovery

export function getFunctionRecoveryOptions(params) {
  return request({
    url: 'api/building-function-recoveries/list',
    method: 'get',
    params: params
  })
}

export function getFunctionRecoveryList(params) {
  
  return request({
    url: 'api/building-function-recoveries',
    method: 'get',
    params: params
  })
}

export function getFunctionRecoveryGeojson(params) {
  return request({
    url: 'api/building-function-recoveries/geo',
    method: 'get',
    params: params,
    //responseType: 'arraybuffer'
  })
}

export function getFunctionRecoveryOutlineGeojson(params) {
  return request({
    url: 'api/building-function-recoveries/geo-default',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

// export function getFunctionRecoveryStatistics(params) {
//   return request({
//     url: 'api/building-function-recoveries/statistics',
//     method: 'get',
//     params: params,
//   })
// }

export function getFunctionRecoveryChart(city_id, worker_number,day) {
  return request({
    url: 'api/building-function-recoveries/show?city_id='+city_id+'&type='+(parseInt(worker_number)==20000?1:2)+'&in_day='+parseInt(day),
    method: 'get'
  })
}

export function getFunctionRecoveryStreetChart(city_id, worker_number,day) {
  return request({
    url: 'api/building-function-recoveries/show-street?city_id='+city_id+'&type='+(parseInt(worker_number)==20000?1:2)+'&in_day='+parseInt(day),
    method: 'get'
  })
}
