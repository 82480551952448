<template>
  <div class="row all-procccess-city" style="margin:0 -30px;">
    <div class="col-md-9" style="padding:0">
      <card class="card-map">
        <div id="map" :class="{ 'map-full' : outPage }"></div>
        <menu-map :map="map" :mapDefault="mapdefaultSetting" showDisaster="false" showWeather="true" :marginTop="outPage ? 0 : 30"></menu-map>
        <div class="radio-group">
          <div v-if="outPage">
            <label :for="types[1].id"><input type="radio" :id="types[1].id" :value="types[1].id" v-model="curType">
              {{ types[1].value }}</label>
          </div>
          <div v-else v-for="item in types" @click="changeType(item.id)" :key="item.id">
            <label :for='item.id'><input type="radio" :id="item.id" :value="item.id" v-model="curType">
              {{ item.value }}</label>
          </div>
        </div>
        <div class="map_data_msg" v-if="mapinfo">
          <div class="map_data_legend">{{ mapinfo.title }}</div>
          <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
            <div class="item" v-for="(item, index) in mapinfo.names" :key="index">
              <span :style="'background: ' + mapinfo.colors[index] + ''"></span>
              {{ item }}
            </div>
          </div>
        </div>
        <!-- <div class="show_list">
          <a class="up_show" v-on:click="showList">
            <i class="now-ui-icons" :class="[show_list ? 'arrows-1_minimal-down' : 'arrows-1_minimal-up']"></i>
          </a>
          <a class="download ml-auto" :href="downloadUrl">
            <i class="now-ui-icons arrows-1_cloud-download-93"></i>
          </a>
          <span>
            数据列表
          </span>
        </div>
        <div class="show_list_record show_fire_list_record" v-show="show_list" ref="show_list_record">
          <el-table :data="data" max-height="600" :span-method="objectSpanMethod">
            <el-table-column v-for="(item, index) in listheader" min-width="150" :label="item" :key="index">
              <template slot-scope="scope">
                <span>{{ scope.row[index] }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div> -->
      </card>
    </div>
    <div class="col-md-3" id="customStyle">
      <el-collapse v-model="activeNames">
        <el-collapse-item title="信息图表" name="1">
          <div class="col-md-12">
            <div class="chart-area" id="chart-pie-index" style="height:300px"></div>
          </div>
          <div class="col-md-12">
            <div class="chart-area" id="chart-bar-index" style="height:600px"></div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import MenuMap from "src/components/menumapFire3";
import geobuf from "geobuf";
import Pbf from "pbf";
import { Collapse, CollapseItem, Button, Tag, Table, TableColumn } from "element-ui";
import { consts } from "src/util/consts.js";
import {
  getPublicPbf,
  getPublicJson,
} from "src/api/fire.js";
import { storeMapOptions, getMapOptions } from "src/util/common.js";
import { buildPieOption, buildBarMultiOption, buildLineMultiOption } from "src/util/chart.js";

export default {
  components: {
    MenuMap,
    [Tag.name]: Tag,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      mid: 0, //模块id
      types: [
        { id: 5, value: '全阶段消防安全指数差值' },
        { id: 6, value: '杭州市全阶段消防安全指数差值' }
      ], //左上角切换radio
      mapinfo: {
        title: '全阶段消防安全指数差值',
        colors: ["#eed8cf", "#eaa489", "#ec6347", "#bc171e"],
        names: [],
        values: [],
      },
      chart: {
        colors: ["#4e81bc", "#c0514d", "#9bbb59", "#8064a2"],
      },
      listheader: [],
      curType: 0,
      activeNames: ['1'],
      map: {},
      pupupinfo: [],
      mapdefaultSetting: {},
      layerId: 'all-process-city-layer',
      city_id: localStorage.getItem("city_id") || 1,
      outPage: consts.outPages.includes(this.$route.path),
    };
  },
  mounted() {
    let _this = this;
    this.mid = this.$route.params.did || 6;
    this.curType = this.mid;
    this.setTipContent()
    this.initMap(this.city_id);
    this.initChart()
    this.initMapChartData()
    this.showChart()
    this.map.on('style.load', function () {
      _this.removeLayer()
      _this.showLayer()
    })
  },
  methods: {
    initMap(city_id) {
      this.mapdefaultSetting = {
        center: consts.mapCenter[this.city_id - 1],
        zoom: consts.mapDefaultZoom,
        pitch: 0,
        city_id: this.city_id
      }
      mapboxgl.accessToken = consts.mapboxAccessToken;
      let map_options = getMapOptions();
      let zoom = map_options ? map_options.zoom : 12;
      let center = map_options ? [map_options.lng, map_options.lat] : consts.mapCenter[city_id - 1];
      var map = new mapboxgl.Map({
        container: "map",
        style: consts.mapBoxStyle,
        center: center,
        zoom: zoom,
        pitch: 0,
        bearing: 0,
        attributionControl: false
      });
      this.map = map;
      //定位图标
      map.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl
        })
      );
      map.addControl(new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      }));
      //控制图层
      var nav = new mapboxgl.NavigationControl();
      map.addControl(nav, 'top-right');
      //全屏按钮
      map.addControl(new mapboxgl.FullscreenControl({
        container: document.querySelector('body')
      }));
      //记录地图设置
      map.on('move', function () {
        storeMapOptions({
          lng: map.getCenter().lng,
          lat: map.getCenter().lat,
          zoom: map.getZoom()
        });
      });
    },
    initChart() {
      const box1 = document.getElementById("chart-pie-index");
      const box2 = document.getElementById("chart-bar-index");
      this.chart1 = this.$echarts.init(box1);
      this.chart2 = this.$echarts.init(box2);
      window.addEventListener('resize', () => {
        document.querySelectorAll('.chart-area').forEach((item) => {
          item.style.width = document.querySelector('.el-collapse').offsetWidth + 'px';
        })
        this.chart.resize();
      })
    },
    initMapChartData() {
      // 地图折线图
      getPublicJson({ fileName: 'line_chart.json' }).then(res => {
        this.pupupinfo = Object.keys(res).map(it => {
          return {
            name: it,
            type: 'line',
            smooth: true,
            data: res[it].map(n => ({ name: Math.abs(res[it][0][0]), value: n[0] < 0 ? [n[0] * 10, n[1]] : n })),
            lineStyle: {
              width: 2,
              type: 'dotted',  //'dotted'虚线 'solid'实线
            }
          }
        })
      })
    },
    changeType(val) {
      //切换左上角列表
      if (val == 5) {
        this.$router.push({ path: '/fire/building/allprocess/5' })
      } else {
        this.$router.push({ path: '/fire/building/allprocesscity/6' })
      }
    },
    showChart() {
      getPublicJson({ fileName: 'pie_pic.json' }).then(res => {
        const keys = Object.keys(res).map(it => {
          const index = it.indexOf('-', 1)
          return [Number(it.substring(0, index)), Number(it.substring(index + 1))]
        })
        keys.sort((a, b) => b[0] - a[0])

        this.mapinfo.names = keys.map(it => it.join('-'))
        const values = Array.from(new Set(keys.flat(2)))
        values.sort((a, b) => a - b)
        this.mapinfo.values = values.slice(1, values.length - 1)

        const data = keys.map((it, ind) => {
          const k = it.join('-')
          return {
            name: k,
            value: res[k].toFixed(2),
            itemStyle: { color: this.mapinfo.colors[ind] }
          }
        })
        const option = buildPieOption('杭州市不同全阶段消防安全指数差值的区县数量占比', data);
        option.title.left  = 'center'
        option.tooltip = {
          trigger: 'item',
          formatter: "{a} <br/>{b} : {c} ({d}%)",
          position: function (p) { //其中p为当前鼠标的位置
            return [p[0] - 40, p[1] + 10];
          },
          extraCssText: 'width:160px;white-space:inherit;'
        };
        this.chart1.setOption(option);
        this.chart1.resize();
      })
      getPublicJson({ fileName: 'bar_chart.json' }).then(res => {
        const data = {}
        const names = Object.keys(Object.values(res)[0])
        Object.keys(res).forEach(it => {
          data[it] = []
          names.forEach(n => {
            data[it].push(res[it][n])
          })
        })
        const option = buildBarMultiOption('杭州市各区县的全阶段消防安全指数差值', data, names, this.chart.colors, 10);
        option.xAxis.axisLabel.rotate = 90
        option.grid = {
          left: "6%",
          right: "6%",
          bottom: "40",
          top: "15%",
          containLabel: true,
        }
        this.chart2.setOption(option);
        this.chart2.resize();
      })
    },
    showLayer() {
      // 区县边界
      this.map.addSource('hangzhou_outline', {
        type: "geojson",
        data: './geojson/hz-outline.geojson',
      });
      this.map.addLayer({
        id: 'hangzhou_outline',
        type: "line",
        source: 'hangzhou_outline',
        paint: {
          "line-color": '#0000ff',
          "line-opacity": 1.0,
          "line-width": 3,
        },
        filter: ["!=", ["get", "dist_id"], null] // 去掉西湖风景区
      })
      // 区域
      getPublicPbf({ fileName: 'fire_work.pbf' }).then(res => {
        const geoJson = geobuf.decode(new Pbf(res));
        this.map.addSource(this.layerId, {
          type: "geojson",
          data: geoJson,
        });
        this.map.addLayer({
          id: this.layerId,
          type: "fill-extrusion",
          source: this.layerId,
          layout: {},
          paint: {
            "fill-extrusion-color": this.getColorExpress('全阶段消防安全指数差'),
            "fill-extrusion-opacity": 0.8,
            "fill-extrusion-base": 1,
            "fill-extrusion-height": [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              10000,
              0.5
            ],
          }
        }, 'state-label');
        this.hoveredStateId = null;
        this.map.on('click', this.layerId, this.mapClickEvent);
        this.map.on('mouseenter', this.layerId, this.mapMouseEnterBlock);
      })
    },
    getColorExpress(field) {
      const colorExpress = [
        "step",
        ["get", field],
      ]
      const colors = [...this.mapinfo.colors]
      colors.reverse()
      this.mapinfo.values.forEach((item, idx) => {
        colorExpress.push(colors[idx], item)
      })
      colorExpress.push(colors[3])
      return colorExpress
    },
    mapClickEvent(e) {
      let _this = this;
      if (e.features.length > 0) {
        if (_this.hoveredStateId !== null) {
          _this.map.setFeatureState(
            { source: _this.layerId, id: _this.hoveredStateId },
            { hover: false }
          );
        }
        if (e.features[0].id == _this.hoveredStateId) {
          _this.hoveredStateId = null
          return
        };
        _this.hoveredStateId = e.features[0].id;
        _this.map.setFeatureState(
          { source: _this.layerId, id: _this.hoveredStateId },
          { hover: true }
        );
      }
    },
    mapMouseEnterBlock(e) {
      if (!e.features.length) return
      if (this.lastpopid == e.features[0].id) return;
      if (this.popup) {
        this.popup.remove()
      }
      this.lastpopid = e.features[0].id
      this.popup = new mapboxgl.Popup({ closeOnClick: false, className: 'map-popup' })
        .setLngLat(e.lngLat)
        .setMaxWidth("none")
        .setHTML('<div class="map-chart-area" id="map-chart"></div>')
        .addTo(this.map);
      const box = document.getElementById("map-chart");
      const chart = this.$echarts.init(box);
      const option = buildLineMultiOption(this.pupupinfo);
      chart.setOption(option);
    },
    removeLayer() {
      if (this.map.getSource(this.layerId)) {
        this.map.removeSource(this.layerId);
      }
      if (this.map.getSource('hangzhou_outline')) {
        this.map.removeSource('hangzhou_outline');
      }
      if (this.map.getLayer(this.layerId)) {
        this.map.removeLayer(this.layerId)
      }
      if (this.map.getLayer('hangzhou_outline')) {
        this.map.removeLayer('hangzhou_outline')
      }
    },
    setTipContent() {
      const tipEl = document.getElementById('left-tip-content')
      if (tipEl) {
        tipEl.innerHTML = '全阶段消防安全指数差值：预防阶段、减轻阶段、救援阶段、恢复阶段的综合消防安全指数差值，反映全阶段的消防安全情况。'
      }
    },
  },
  beforeDestroy() {
    this.removeLayer()
  }
};
</script>

<style lang="scss" scoped>
.card-map {
  min-height: 350px;
  height: calc(100vh - 48px);
  margin-bottom: 0;
  position: relative;

  #map {
    width: 100%;
    height: calc(100vh - 100px);
  }

  .radio-group {
    position: absolute;
    left: 10px;
    top: 10px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding: 10px;
  }

  .radio-group div {
    padding: 0 10px;
  }

  .radio-group div input {
    vertical-align: -1px;
    padding-right: 2px;
  }

  .map_data_msg {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    // color: #02f4fe;
    color: #fff;
    padding: 10px;
    width: auto;

    .map_data_legend {
      margin-bottom: 5px;
    }

    .map_data_msg_wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .item {
        // width: 80px;
        line-height: 15px;
        padding: 5px 0;
      }

      .item span {
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 3px;
        vertical-align: middle;
        margin-left: 0;
      }

      .item-circle span {
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 100%;
        vertical-align: middle;
        margin-left: 0;
        position: relative;
        margin-right: 7px;
      }

      .item-circle span:after {
        content: '';
        position: absolute;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        border: 1px solid rgba(255, 255, 255, 0.5);
        left: -5px;
        top: -5px;
      }

      .map_data_msg_inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 10px;
      }
    }

    .map_data_msg_wrapper_vertical {
      flex-direction: column;

      .item {
        width: 130px;
      }
    }
  }
}

@import url("https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css");

.chart-area {
  width: 100%;
}
.all-procccess-city .map-popup .mapboxgl-popup-content {
  width: 250px;
}
</style>
<style lang="scss">
#map-chart {
  width: 430px;
  height: 430px;
}
</style>
