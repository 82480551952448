<template>
   <div class="row" style="margin:0 -30px;">
    <div class="col-md-9" style="padding:0">
      <card class="card-map">
        <!-- <div class="mask">
          <img src="img/loading.png" class="loading">
        </div>-->
        <div id="map"></div>
        <menu-map :map="map" :mapDefault="mapdefaultSetting" showDisaster="false"></menu-map>
        <div class="radio-group" v-show="selects.data_category == 'people'">
          <div v-for="(item,idx) in selects.radioList">
            <input type="radio" :id="item.value" :value="item.value" v-model="selects.peopleProperty" ><label :for='item.value'>{{item.label}}</label>
          </div>
        </div>
        <div class="map_data_msg" v-show="selects.data_category == 'people' && selects.peopleProperty == 'amount'">
          <div class="map_data_legend">人口数量</div>
          <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
            <div
              class="item"
              v-for="(item,index) in peopleList.amount.range"
              :key="index"
            >
              <span :style="'background: '+peopleList.amount.color[index]+''"></span>
              {{item}}
            </div>
          </div>
        </div>
        <div class="map_data_msg" v-show="selects.data_category == 'people' && selects.peopleProperty == 'density'">
          <div class="map_data_legend">人口密度（人/平方公里）</div>
          <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
             <div
              class="item"
              v-for="(item,index) in peopleList.density.range"
              :key="index"
            >
              <span :style="'background: '+peopleList.density.color[index]+''"></span>
              {{item}}
            </div>
          </div>
        </div>
        <div class="map_data_msg" v-show="selects.data_category == 'people' && selects.peopleProperty == 'household'">
          <div class="map_data_legend">住户数</div>
          <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
             <div
              class="item"
              v-for="(item,index) in peopleList.household.range"
              :key="index"
            >
              <span :style="'background: '+peopleList.household.color[index]+''"></span>
              {{item}}
            </div>
          </div>
        </div>
        <div class="map_data_msg" v-show="selects.data_category == 'people' && selects.peopleProperty == 'genderrate'">
          <div class="map_data_legend">性别比</div>
          <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
             <div
              class="item"
              v-for="(item,index) in peopleList.genderrate.range"
              :key="index"
            >
              <span :style="'background: '+peopleList.genderrate.color[index]+''"></span>
              {{item}}
            </div>
          </div>
        </div>
        <div class="map_data_msg" v-show="selects.data_category == 'hideaway'">
          <div class="map_data_legend">避难所容纳人数</div>
          <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
            <div
              class="item item-circle"
              v-for="(item,index) in capacityOptions"
              :key="index"
            >
              <span :style="'background: '+item.color+';transform: scale('+(3-(index*0.5))*0.4+');'"></span>
              {{item.name}}
            </div>
          </div>
        </div>
        <div class="map_data_msg" v-show="selects.data_category == 'hospital'">
          <div class="map_data_legend">医疗救护点类型</div>
          <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
            <div
              class="item item-circle"
              v-for="(item,index) in rescueOptions"
              :key="index"
            >
              <span :style="'background: '+item.color+';transform: scale('+(3-(index*0.5))*0.4+');'"  ></span>
              {{item.name}}
            </div>
          </div>
        </div>
        <div class="map_data_msg" v-show="selects.data_category == 'warehouse'">
          <div class="map_data_legend">应急储备仓库面积</div>
          <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
            <div
              class="item item-circle"
              v-for="(item,index) in warehouseOptions"
              :key="index"
            >
              <span :style="'background: '+item.color+';transform: scale('+(3-(index*0.5))*0.4+');'"  ></span>
              {{item.name}}
            </div>
          </div>
        </div>
        <div class="show_list">
          <a class="up_show" v-on:click="showList">
            <i
              class="now-ui-icons"
              :class="[ show_list ? 'arrows-1_minimal-down':'arrows-1_minimal-up']"
            ></i>
          </a>
          <a class="download ml-auto" :href="downloadUrl">
            <i class="now-ui-icons arrows-1_cloud-download-93"></i>
          </a>
          <span>
            数据列表
            <i>{{total}}</i>
          </span>
        </div>
        <div
          class="show_list_record"
          v-show="show_list"
          :style="{'margin-top':-(148+data.length*38)+'px'}"
          ref="show_list_record"
        >
          <el-table :data="data" v-show="selects.data_category=='people'">
            <el-table-column min-width="100" label="社区ID" property="id"></el-table-column>
            <el-table-column min-width="150" label="社区名" property="name"></el-table-column>
            <el-table-column min-width="150" label="总人口" property="total_population"></el-table-column>
            <el-table-column min-width="150" label="性别比" property="rate"></el-table-column>
            <el-table-column min-width="150" label="男性人口" property="man_population"></el-table-column>
            <el-table-column min-width="150" label="女性人口" property="woman_population"></el-table-column>
            <el-table-column min-width="100" label="0-9岁" property="age1"></el-table-column>
            <el-table-column min-width="100" label="10-19岁" property="age2"></el-table-column>
            <el-table-column min-width="100" label="20-29岁" property="age3"></el-table-column>
            <el-table-column min-width="100" label="30-39岁" property="age4"></el-table-column>
            <el-table-column min-width="100" label="40-49岁" property="age5"></el-table-column>
            <el-table-column min-width="100" label="50-59岁" property="age6"></el-table-column>
            <el-table-column min-width="100" label="60-69岁" property="age7"></el-table-column>
            <el-table-column min-width="100" label="70-79岁" property="age8"></el-table-column>
            <el-table-column min-width="100" label="80岁及以上" property="age9"></el-table-column>
          </el-table>
          <el-table :data="data" v-show="selects.data_category  =='hideaway'">
            <el-table-column min-width="100" label="避难所ID" property="id"></el-table-column>
            <el-table-column min-width="150" label="名称" property="name"></el-table-column>
            <!-- <el-table-column min-width="150" label="经度" property="lat"></el-table-column>
            <el-table-column min-width="150" label="纬度" property="lng"></el-table-column> -->
            <el-table-column min-width="150" label="容纳人数" property="persons"></el-table-column>
            <el-table-column min-width="150" label="面积" property="area"></el-table-column>
          </el-table>
          <el-table :data="data" v-show="selects.data_category  =='hospital'">
            <el-table-column min-width="100" label="医疗救助点ID" property="id"></el-table-column>
            <el-table-column min-width="150" label="名称" property="name"></el-table-column>
            <!-- <el-table-column min-width="150" label="经度" property="lat"></el-table-column>
            <el-table-column min-width="150" label="纬度" property="lng"></el-table-column> -->
            <el-table-column min-width="150" label="容纳人数" property="persons"></el-table-column>
            <el-table-column min-width="150" label="床位数" property="beds"></el-table-column>
          </el-table>
          <el-table :data="data" v-show="selects.data_category  =='warehouse'">
            <el-table-column min-width="100" label="应急储备仓库ID" property="id"></el-table-column>
            <el-table-column min-width="150" label="名称" property="name"></el-table-column>
            <!-- <el-table-column min-width="150" label="经度" property="lat"></el-table-column>
            <el-table-column min-width="150" label="纬度" property="lng"></el-table-column> -->
            <el-table-column min-width="150" label="面积" property="area"></el-table-column>
          </el-table>

          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class>
              <p class="card-category">显示： {{from + 1}} - {{to}} 总数： {{total}}</p>
            </div>
            <n-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              v-on:input="changePage"
            ></n-pagination>
          </div>
        </div>
      </card>
    </div>
    <div class="col-md-3 hasDecision" id="customStyle">
      <div class="col-md-12 clearfix key-decision" >
          <el-select
            class="select-default"  
            size="large"
            placeholder="数据集类型选择"
            v-model="selects.data_category"
            v-on:change="handelMapLayer"
          >
            <el-option
              v-for="option in selects.data_categories"
              class="select-primary"
              :value="option.id"
              :label="option.name"
              :key="option.id"
            ></el-option>
          </el-select>
      </div>
      <el-collapse v-model="activeNames">
        <!-- <el-collapse-item title="一致性 Consistency" name="1">
          <form>
            <div class="input-group no-border">
              <fg-input
                placeholder="输入关键字..."
                v-on:keyup.enter="reloadData"
                v-model="keyword"
                addon-right-icon="now-ui-icons ui-1_zoom-bold"
              ></fg-input>
              <div class="form-text" style="color:#999;margin-left:20px;">输入 建筑、街道、社区关键字</div>
            </div>
          </form>
        </el-collapse-item> -->
        <el-collapse-item title="信息图表" name="2">
          <div class="col-md-12" v-show="selects.data_category == 'people'">
            <div class="chart-area" id="box-chart0" style="height:300px"></div>
          </div>
          <div class="col-md-12" v-show="selects.data_category == 'people'">
            <div class="chart-area" id="box-chart" style="height:300px"></div>
          </div>
          <div class="col-md-12" v-show="selects.data_category == 'hideaway'">
            <div class="chart-area" id="box-chart2" style="height:300px"></div>
          </div>
          <div class="col-md-12" v-show="selects.data_category == 'hospital'">
            <div class="chart-area" id="box-chart3" style="height:300px"></div>
          </div>
          <div class="col-md-12" v-show="selects.data_category == 'warehouse'">
            <div class="chart-area" id="box-chart4" style="height:300px"></div>
          </div>
        </el-collapse-item>
        <el-collapse-item title="数据筛选" name="3">
          <div class="col-md-12">
              <el-select
                class="select-default"
                size="large"
                placeholder="街道"
                v-model="selects.street"
               v-on:change="reloadData('street')"
              >
                <el-option
                  v-for="option in selects.streets"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id"
                ></el-option>
              </el-select>
            </div>
            <div class="col-md-12">
              <el-select
                class="select-default"
                size="large"
                placeholder="社区"
                v-model="selects.community"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="option in selects.communities"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id"
                ></el-option>
              </el-select>
            </div>
        </el-collapse-item>
      </el-collapse>
     

      
    </div>
    <div class="key-infos">
      <div class="key-info" v-show="selects.data_category == 'people'">
        总人口：{{statistics.people.total_population}}，性别比：{{statistics.people.rate}}，平均年龄：{{statistics.people.age_avg}}
      </div>
      <div class="key-info" v-show="selects.data_category == 'hideaway'">
        {{statistics.hideaways.count}}个避难所，总容量{{statistics.hideaways.persons}}人
      </div>
      <div class="key-info" v-show="selects.data_category == 'hospital'">
        {{statistics.hospital.general_count}}家综合医院，{{statistics.hospital.community_count}}家社区医院，总床位数{{statistics.hospital.beds}}个
      </div>
      <div class="key-info" v-show="selects.data_category == 'warehouse'">
        {{statistics.warehouse.count}}个应急储备仓库，总面积{{statistics.warehouse.area}}平米
      </div>
    </div>
  </div>
</template>
<script>
import mapboxgl from "mapbox-gl";
import MenuMap from "src/components/Menumap";
import geobuf from "geobuf";
import Pbf from "pbf";
import MiniMap from "src/mapbox/mapboxgl-minimap.js";
import { TabPane, Tabs, Switch } from "src/components";
import { Radio } from 'src/components/index';
import { Input, Button, Select, Option, Collapse, CollapseItem, Table, TableColumn } from "element-ui";
import { consts } from "src/util/consts.js";
import {
  getCategories,
  getStreets,
  getStreetsHideaway,
  getStreetsHospital,
  getStreetsWarehouse,
  getCommunities,
  getCommunitiesHideaway,
  getCommunitiesHospital,
  getCommunitiesWarehouse,
  getList,
  // getGeoJson,
  // getPeopleGeojson
} from "src/api/building.js";
import { byStruct, hospitalCategory, hideawayPerson, warehouseArea, peopleByAgeRange, peopleByStreet } from "src/api/chart.js";
import { buildBarOption, buildPieOption, buildBarSideOption } from "src/util/chart.js";
import { getBuildYearRange,storeMapOptions,getMapOptions,parseJsonToString } from "src/util/common.js";
import { Pagination as NPagination } from "src/components";
import {getPeopleStat,getGenderStat,getHideaways,getHospitals,getWarehouses,getPeopleGeo,getHospitalGeo,getHideawaylGeo,getWarehouseGeo,getRegionGeo,getPeopleStatistics,getHospitalsStatistics,getHideawaysStatistics,getWarehousesStatistics,
} from 'src/api/cityinfo.js';

import * as THREE from "three";

export default {
  components: {
    MenuMap,
    TabPane,
    Tabs,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    NPagination,
    [Switch.name]: Switch,
    Radio
  },
  data() {
    return {
      activeNames: ['2','3'],
      activeName: "first",
      tabs: {
        horizontal: "Profile",
        vertical: "Options",
        verticalPills: "Home",
        pageCategories: "Messages"
      },
      selects: {
        street: 0,
        streets: [{ id: 0, name: "街道" }],
        community: 0,
        communities: [{ id: 0, name: "社区" }],
        category: 0,
        categories: consts.buildingCategories,
        quakeLevel: 0,
        quakeLevels: [
          { id: 0, name: "抗震设防烈度" },
          { id: 6, name: 6 },
          { id: 7, name: 7 },
          { id: 8, name: 8 }
        ],
        buildYear: 0,
        buildYears: consts.buildingBuildYears,
        data_category: 'people',
        data_categories: [
          { id: 'people', name: "人口信息" },
          { id: 'hideaway', name: "避难场所" },
          { id: 'hospital', name: "医疗救护点" },
          { id: 'warehouse', name: "应急储备仓库" }
        ],
        peopleProperty: 'amount',
        radioList: [
          {'value':'amount','label':'人口数量'},
          {'value':'density','label':'人口密度'},
          {'value':'household','label':'住户数'},
          {'value':'genderrate','label':'性别比'},
        ],
      },
      keyword: "",
      total: 0,
      data: [],
      page: 1,
      map: {},
      color_category: "struct",
      city_id: localStorage.getItem("city_id") || 1,
      show_list: false,
      show_list_height: 0,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 20
      },
      switchs: {
        people: true,
        gender: true,
        hideaway: true,
        hospital: true,
        warehouse: true
      },
      last_updated: consts.last_updated,
      data_type:'people',
      peopleList: {
        'amount':{range:['0-2000','2000-4000','4000-6000','6000-8000','8000-10000','>10000'],color:["#006bcf", "#008dcf", "#83bd49", "#aad481", "#ffd04e", "#ffba4d"]},
        'density':{range:['0-10000','10000-20000','20000-30000','30000-40000','40000-50000','>50000'],color:["#006bcf", "#008dcf", "#83bd49", "#aad481", "#ffd04e", "#ffba4d"]},
        'household':{range:['0-1500','1500-2000','2000-2500','2500-3000','3000-3500','>3500'],color:["#006bcf", "#008dcf", "#83bd49", "#aad481", "#ffd04e",'#ffba4d']},
        'genderrate':{range:['0-0.8','0.8-0.9','0.9-1','1-1.1','1.1-1.2','>1.2'],color:["#006bcf", "#008dcf", "#83bd49", "#aad481", "#ffd04e", "#ffba4d"]},
        'agerange':{range:['0-1','1-2','2-3','3-4','4-5','5-6'],color:["#006bcf", "#008dcf", "#83bd49", "#aad481", "#ffd04e", "#ffba4d"]}
      },
      capacityOptions: [
        { id: 1, name: "3000-3900", color: "#ff0000" },
        { id: 2, name: "2000-2900", color: "#ed7d31" },
        { id: 3, name: "1000-1900", color: "#ffc000" }
      ],
      rescueOptions: [
        { id: 1, name: "综合医院", color: "#ff0000" },
        { id: 2, name: "社区医院", color: "#ffc000" },
      ],
      warehouseOptions: [
        { id: 1, name: "10000平米以上", color: "#ff0000" },
        { id: 2, name: "5000-10000平米", color: "#ed7d31" },
        { id: 3, name: "5000平米以下", color: "#ffc000" },
      ],
      mapdefaultSetting: {},
      statistics:{
        people: {
          rate: '',
          total_population: '',
          age_avg: ''
        },
        hideaways: {
          count: '',
          persons: ''
        },
        hospital: {
          general_count: '',
          community_count: '',
          beds: ''
        },
        warehouse: {
          count: '',
          area: ''
        }
        
      },
      downloadUrl: ''
    };
  },

  mounted() {
    let _this = this;
    this.fillStreets(this.city_id);
    //this.fillCommunities(this.city_id, 0);
    this.showChart(this.city_id);
    
    //this.fillBuildings(this.city_id, 1);
    this.initMap(this.city_id);
    // this.fillBuildingsMap(this.city_id, this.color_category);
    //this.addPointLayerToMap(this.map, "hospital");
    //this.addPointLayerToMap(this.map, "hideaway");
    //this.addPointLayerToMap(this.map, "warehouse");
    // this.loadData(this.data_type);
    //this.loadGeoData();
    this.map.on('style.load', function(){
      _this.reloadData()
      _this.loadRegionData()
      //_this.fillBlockmapLayer(this.city_id);
    })
    
    
  
  },

  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    // street (){
    //   return this.selects.street
    // }
  },
  watch:{
    // street(val){
    //   this.fillCommunities(this.city_id,this.selects.street)
    // },
    "selects.street"(val) {
      //街道切换选社区
      this.fillCommunities(this.city_id, val);
    },
    "selects.peopleProperty"(val){
      this.addBlockmapLayer(this.map,'people')
    },
  },
  methods: {
    // 初始化
    initMap(city_id) {
      this.mapdefaultSetting = {
        center: consts.mapCenter[this.city_id-1],
        zoom: consts.mapDefaultZoom,
        pitch: 0,
        city_id: this.city_id
      }
      mapboxgl.accessToken = consts.mapboxAccessToken;
      var origin = [-95.97299, 36.15031, 0];
      let map_options = getMapOptions();
      //console.log(map_options);
      let zoom = map_options?map_options.zoom:12;
      let center = map_options?[map_options.lng,map_options.lat]:consts.mapCenter[city_id - 1];
      //console.log(center);
      var map = new mapboxgl.Map({
        container: "map",
        style: consts.mapBoxStyle,
        //center: [-96, 37.8],
        center: center,
        zoom: zoom,
        pitch: 0,
        bearing: 0,
        attributionControl:false
      });
      this.map = map;
      //定位图标
      map.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl
        })
      );
      this.map.addControl(new mapboxgl.GeolocateControl({
          positionOptions: {
              enableHighAccuracy: true
          },
          trackUserLocation: true
      }));  
      //控制图层
      var nav = new mapboxgl.NavigationControl();
      this.map.addControl(nav, 'top-right');
      //全屏按钮
      this.map.addControl(new mapboxgl.FullscreenControl({
        container: document.querySelector('body')
        })
      );
      //小地图
      //https://github.com/brendanmatkin/mapboxgl-minimap
      map.on("style.load", function () {
        // Possible position values are 'bottom-left', 'bottom-right', 'top-left', 'top-right'
        map.addControl(new MiniMap({
          id: "mapboxgl-minimap",
          width: "200px",
          height: "150px",
          //style: "mapbox://styles/mapbox/streets-v10",
          style: consts.mapBoxStyle,
          fillColor: "#f58220",
          zoomAdjust: null
          }),
          'bottom-right');
      });
      
      //记录地图设置
      map.on('move', function() {
          storeMapOptions({
            lng: map.getCenter().lng,
            lat: map.getCenter().lat,
            zoom: map.getZoom()
          });
      });
       
    },
    showList() {
      this.show_list = !this.show_list;
    },
    donwloadList() {},
    showChart(city_id) {
      let _this = this;
      var container = document.querySelector('.el-collapse');
      //
      var box0 = document.getElementById("box-chart0");
      box0.style.width = container.offsetWidth + "px";
      var myChart0 = this.$echarts.init(box0);
      peopleByStreet(city_id).then(res => {
        var chart_data = res.data;
        var chart_name = "街道人口情况";
        // chart_data.forEach(item=>{
        //   if(item.value <= 2000) {
        //     item.color = _this.peopleList['amount']['color'][0]
        //   } else if(item.value <=4000) {
        //     item.color = _this.peopleList['amount']['color'][1]
        //   }else if(item.value <=6000) {
        //     item.color = _this.peopleList['amount']['color'][2]
        //   }else if(item.value <=8000) {
        //     item.color = _this.peopleList['amount']['color'][3]
        //   }else if(item.value <=10000) {
        //     item.color = _this.peopleList['amount']['color'][4]
        //   } else {
        //     item.color = _this.peopleList['amount']['color'][5]
        //   }
        // })
        chart_data.forEach(item=>{
          item.color = '#55ac68'
        })
        var option0 = buildBarOption(chart_name, chart_data, "name",{
          yAxis: {
            name: '人口数',
            nameLocation: 'end'
          }
        });
        myChart0.setOption(option0);
      });
     

      // 男女各年龄段人数
      var box = document.getElementById("box-chart");
      box.style.width = container.offsetWidth + "px";
      var myChart = this.$echarts.init(box);
      peopleByAgeRange(city_id).then(res => {
        var chart_data = res.data;
        var chart_name = "男女各年龄段人数";
        var option = buildBarSideOption(chart_name, chart_data, "age");
        myChart.setOption(option);
      });
    
      // 避难所容纳人数
      var box2 = document.getElementById("box-chart2");
      box2.style.width = container.offsetWidth + "px";
      var myChart2 = this.$echarts.init(box2);
      hideawayPerson(city_id).then(res => {
        var chart_data = res.data;
        chart_data.forEach((item,idx)=>{
          item.itemStyle = {color: this.capacityOptions[this.capacityOptions.length-idx-1].color}
        })
        var chart_name = "避难所容纳人数";
        var option = buildPieOption(chart_name, chart_data);
        myChart2.setOption(option);
      });
      
      // 医疗救护点类型
      var box3 = document.getElementById("box-chart3");
      box3.style.width = container.offsetWidth + "px";
      var myChart3 = this.$echarts.init(box3);
      hospitalCategory(city_id).then(res => {
        var chart_data = res.data;
        chart_data.forEach((item,idx)=>{
          item.itemStyle = {color: this.rescueOptions[idx].color}
        })
        var chart_name = "医疗救护点类型";
        var option = buildPieOption(chart_name, chart_data);
        myChart3.setOption(option);
      });
      
      // 应急储备仓库面积
      var box4 = document.getElementById("box-chart4");
      box4.style.width = container.offsetWidth + "px";
      var myChart4 = this.$echarts.init(box4);
      warehouseArea(city_id).then(res => {
        var chart_data = res.data;
        chart_data.forEach((item,idx)=>{
          item.itemStyle = {color: this.warehouseOptions[this.warehouseOptions.length-idx-1].color}
        })
        var chart_name = "应急储备仓库面积";
        var option = buildPieOption(chart_name, chart_data);
        myChart4.setOption(option);
      });
      
      window.onresize = function() {
        document.querySelectorAll('.chart-area').forEach((item,idx)=>{
          item.style.width = document.querySelector('.el-collapse').offsetWidth+'px';
        })
        myChart0.resize();
        myChart.resize(); 
        myChart2.resize(); 
        myChart3.resize(); 
        myChart4.resize(); 
      }
    },
    fillStreets(city_id) {
      if(this.selects.data_category == 'people') {
        getStreets(city_id).then(res => {
          let data = res.data;
          data.unshift({ id: 0, name: "街道" });
          this.selects.streets = data;
        });
      } else if(this.selects.data_category == 'hideaway') {
        getStreetsHideaway(city_id).then(res => {
          let data = res.data;
          data.unshift({ id: 0, name: "街道" });
          this.selects.streets = data;
        });
      } else if(this.selects.data_category == 'hospital') {
        getStreetsHospital(city_id).then(res => {
          let data = res.data;
          data.unshift({ id: 0, name: "街道" });
          this.selects.streets = data;
        });
      } else if(this.selects.data_category == 'warehouse') {
        getStreetsWarehouse(city_id).then(res => {
          let data = res.data;
          data.unshift({ id: 0, name: "街道" });
          this.selects.streets = data;
        });
      }
    },
    fillCommunities(city_id, street_id) {
      if(this.selects.data_category == 'people') {
        getCommunities(city_id, street_id).then(res => {
          let data = res.data;
          data.unshift({ id: 0, name: "社区" });
          this.selects.communities = data;
        });
      } else if(this.selects.data_category == 'hideaway') {
        getCommunitiesHideaway(city_id, street_id).then(res => {
          let data = res.data;
          data.unshift({ id: 0, name: "社区" });
          this.selects.communities = data;
        });
      } else if(this.selects.data_category == 'hospital') {
        getCommunitiesHospital(city_id, street_id).then(res => {
          let data = res.data;
          data.unshift({ id: 0, name: "社区" });
          this.selects.communities = data;
        });
      } else if(this.selects.data_category == 'warehouse') {
        getCommunitiesWarehouse(city_id, street_id).then(res => {
          let data = res.data;
          data.unshift({ id: 0, name: "社区" });
          this.selects.communities = data;
        });
      }
      
    },
    fillPeopleData(page) {
      let params = this.buildParams();
      params.page = page;
      getPeopleStat(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
      getPeopleStatistics(params).then(res => {
        this.statistics.people.total_population = res.data.total_population;
        this.statistics.people.rate = res.data.rate;
        this.statistics.people.age_avg = res.data.age_avg;
      });
      
      this.downloadUrl = consts.requestUrl + consts.exportCityInfoPeople+parseJsonToString(params)
      
    },
    fillGenderData(page) {
      let params = this.buildParams();
      params.page = page;
      getGenderStat(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillHospitalsData(page) {
      let params = this.buildParams();
      params.page = page;
      getHospitals(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
      getHospitalsStatistics(params).then(res => {
        this.statistics.hospital.general_count = res.data.general_count;
        this.statistics.hospital.community_count = res.data.community_count;
        this.statistics.hospital.beds = res.data.beds;
      });
      this.downloadUrl = consts.requestUrl + consts.exportCityInfoHospital+parseJsonToString(params)
    },
    fillHideawaysData(page) {
      let params = this.buildParams();
      params.page = page;
      getHideaways(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
      getHideawaysStatistics(params).then(res => {
        this.statistics.hideaways.count = res.data.count;
        this.statistics.hideaways.persons = res.data.persons;
      });
      this.downloadUrl = consts.requestUrl + consts.exportCityInfoHideaway+parseJsonToString(params)
    },
    fillWarehousesData(page) {
      let params = this.buildParams();
      params.page = page;
      getWarehouses(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
      getWarehousesStatistics(params).then(res => {
        this.statistics.warehouse.count = res.data.count;
        this.statistics.warehouse.area = res.data.area;
      });
      this.downloadUrl = consts.requestUrl + consts.exportCityInfoWarehouse+parseJsonToString(params)
    },
    fillBlockmapLayer(city_id){
      let params = this.buildParams();
      getPeopleGeo(params).then(data => {
        let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        this.peoplegeoJson = geoJson;
        this.addBlockmapLayer(this.map, 'people', geoJson);
      });
    },
    addBlockmapLayer(map, type, geoJson) {
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      //let source_name_stroke = source_name+'_stroke';
      let layer_id_stroke = layer_id+'_stroke';
      let geodata;
      if(type == 'people') {
        geodata = !!geoJson?geoJson:this.peoplegeoJson
      }
      if(type == 'region') {
        geodata = this.regionGeojson
      }
      map.addSource(source_name, {
        type: "geojson",
        data: geodata
      });
      let colorExpress = '';
      let strokeColor = '#000000'
      let fillOpacity = 1;
      if(this.selects.peopleProperty == 'amount') {
        colorExpress = [
            "step",
            ["get", "total_population"],
            "#006bcf", 2000,
            "#008dcf", 4000,
            "#83bd49", 6000,
            "#aad481", 8000,
            "#ffd04e", 10000,
            "#ffba4d", 12000,
            "#ffba4d" // other
          ]
      } else if (this.selects.peopleProperty == 'density') {
        colorExpress = [
            "step",
            ["get", "density"],
            "#006bcf", 10000,
            "#008dcf", 20000,
            "#83bd49", 30000,
            "#aad481", 40000,
            "#ffd04e", 50000,
            "#ffba4d", 60000,
            "#ffba4d" // other
          ]
      } else if (this.selects.peopleProperty == 'household') {
        colorExpress = [
            "step",
            ["get", "household"],
            "#006bcf", 1500,
            "#008dcf", 2000,
            "#83bd49", 2500,
            "#aad481", 3000,
            "#ffd04e", 3500,
            "#ffba4d", 4000,
            "#ffba4d" // other
          ]
      } else if (this.selects.peopleProperty == 'genderrate') {
        colorExpress = [
            "step",
            ["get", "rate"],
            "#006bcf", 0.8,
            "#008dcf", 0.9,
            "#83bd49", 1,
            "#aad481", 1.1,
            "#ffd04e", 1.2,
            "#ffba4d", 1.3,
            "#ffba4d" // other
          ]
      } else if (this.selects.peopleProperty == 'agerange') {
        colorExpress = [
            "step",
            ["get", "total_population"],
            "#006bcf", 2000,
            "#008dcf", 4000,
            "#83bd49", 6000,
            "#aad481", 8000,
            "#ffd04e", 10000,
            "#ffba4d", 12000,
            "#ffba4d" // other
          ]
      }
      if(type == 'region') {
        colorExpress = '#f96332'
        fillOpacity = 0.8
        strokeColor = '#ffffff'
      }
      if(type != 'region') {
        map.addLayer({
          id: layer_id,
          type: "fill",
          source: source_name,
          layout: {},
          paint: {
            "fill-color": colorExpress,
            "fill-opacity": fillOpacity
          }
        });
      }
      //描边
      map.addLayer({
        id: layer_id_stroke,
        type: "line",
        source: source_name,
        layout: {},
        paint: {
          "line-color": strokeColor,
          "line-width": 1
        }
      });
    },
    reloadData(type) {
      if(type == 'street') {
        this.selects.community = 0;
      }
      
      //left-tip-content
      //this.fillBuildings(this.city_id, 1);
      //this.fillBuildingsMap(this.city_id, this.color_category);
      //this.loadData(this.selects.data_category);
      this.handelMapLayer()
    },
    setTipContent() {
      let html = ''
      if(this.selects.data_category == 'people') {
        //html = '社区人口性别比定义：每个社区中的男性人口相对女性人口的比值'
        html = consts.tipsCityInfoPeople;
      } else if(this.selects.data_category == 'hideaway') {
        html = consts.tipsCityInfoHideaway;
      } else if(this.selects.data_category == 'hospital') {
        html = consts.tipsCityInfoHospital;
      } else if(this.selects.data_category == 'warehouse') {
        html = consts.tipsCityInfoWarehouse;
      }
      //console.log('3333',document.getElementById('left-tip-content'))
      document.getElementById('left-tip-content').innerHTML = html
    },
    buildParams() {
      let params = { city_id: this.city_id };
      params.street_id = this.selects.street;
      params.community_id = this.selects.community;
      params.keyword = this.keyword;
      params.page_size = 10;  
      return params;
    },
    changePage: function(value) {
      if (value != this.page) {
          this.loadData(this.selects.data_category, value);
      }
      this.page = value;
    },
    fillPointLayer(){
      let params = this.buildParams();
      if(this.selects.data_category == 'hideaway') {
        getHideawaylGeo(params).then(data => {
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          //this.addBlockmapLayer(this.map,'region')
          this.addPointLayerToMap(this.map, geoJson);
        });
      } else if(this.selects.data_category == 'hospital') {
        getHospitalGeo(params).then(data => {
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          //this.addBlockmapLayer(this.map,'region')
          this.addPointLayerToMap(this.map, geoJson);
        });
      } else if(this.selects.data_category == 'warehouse') {
        getWarehouseGeo(params).then(data => {
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          //this.addBlockmapLayer(this.map,'region')
          this.addPointLayerToMap(this.map, geoJson);
        });
      }
      
    },
    makePointImageWarehouse(map, type){
      let size = 150;
      switch(type){
        case 1:
          size = 80;
        break;
        case 2:
          size = 120;
        break;
        case 3:
          size = 160;
        break;
      }
      //point type
      let pulsingDot  = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // get rendering context for the map canvas when layer is added to the map
        onAdd: function() {
          var canvas = document.createElement("canvas");
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext("2d");
        },

        // called once before every frame where the icon will be used
        render: function() {
          var duration = 1000;
          var t = (performance.now() % duration) / duration;

          var radius = (size / 2) * 0.3;
          var outerRadius = (size / 2) * 0.7 * t + radius;
          var context = this.context;

          // draw outer circle
          context.clearRect(0, 0, this.width, this.height);
          context.beginPath();
          context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2
          );
          switch(type){
            case 1:
              context.fillStyle = "rgba(255, 192, 0," + (1 - t) + ")";
            break;
            case 2:
              context.fillStyle = "rgba(237, 125, 49," + (1 - t) + ")";
            break;
            case 3:
              context.fillStyle = "rgba(255, 0, 0," + (1 - t) + ")";
            break;
          }
          
          context.fill();

          // draw inner circle
          context.beginPath();
          context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
          switch(type){
            case 1:
              context.fillStyle = "rgba(255, 192, 0, 1)";
            break;
            case 2:
              context.fillStyle = "rgba(237, 125, 49, 1)";
            break;
            case 3:
              context.fillStyle = "rgba(255, 100, 100, 1)";
            break;
          }
          
          context.strokeStyle = "white";
          context.lineWidth = 2 + 4 * (1 - t);
          context.fill();
          //context.stroke();
          // update this image's data with data from the canvas
          this.data = context.getImageData(0, 0, this.width, this.height).data;

          // continuously repaint the map, resulting in the smooth animation of the dot
          map.triggerRepaint();

          // return `true` to let the map know that the image was updated
          return true;
        }
      }
      map.addImage("warehouse-dot-"+type, pulsingDot, { pixelRatio: 2 });
    },
    makePointImageHospital(map, type, capacity){
      let size = 150;
      switch(capacity){
         case 1:
            size = 80;
          break;
          case 2:
            size = 120;
          break;
          case 3:
            size = 160;
          break;
      }
      //point type
      let pulsingDot  = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // get rendering context for the map canvas when layer is added to the map
        onAdd: function() {
          var canvas = document.createElement("canvas");
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext("2d");
        },

        // called once before every frame where the icon will be used
        render: function() {
          var duration = 1000;
          var t = (performance.now() % duration) / duration;

          var radius = (size / 2) * 0.3;
          var outerRadius = (size / 2) * 0.7 * t + radius;
          var context = this.context;

          // draw outer circle
          context.clearRect(0, 0, this.width, this.height);
          context.beginPath();
          context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2
          );
          switch(type){
            case 1: //综合医院
              context.fillStyle = "rgba(255, 0, 0," + (1 - t) + ")";
            break;
            case 2: //社区医院
              context.fillStyle = "rgba(237, 125, 49," + (1 - t) + ")";
            break;
          }
          
          context.fill();

          // draw inner circle
          context.beginPath();
          context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
          switch(type){
            case 1:
              context.fillStyle = "rgba(255, 100, 100, 1)";
            break;
            case 2:
              context.fillStyle = "rgba(237, 125, 49, 1)";
            break;
          }
          
          context.strokeStyle = "white";
          context.lineWidth = 2 + 4 * (1 - t);
          context.fill();
          //context.stroke();
          // update this image's data with data from the canvas
          this.data = context.getImageData(0, 0, this.width, this.height).data;

          // continuously repaint the map, resulting in the smooth animation of the dot
          map.triggerRepaint();

          // return `true` to let the map know that the image was updated
          return true;
        }
      }
      map.addImage("hospital-dot-"+type+"-"+capacity, pulsingDot, { pixelRatio: 2 });
    },
    makePointImageHideaway(map, type){
      let size = 150;
      switch(type){
        case 1:
          size = 80;
        break;
        case 2:
          size = 120;
        break;
        case 3:
          size = 160;
        break;
      }
      //point type
      let pulsingDot  = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // get rendering context for the map canvas when layer is added to the map
        onAdd: function() {
          var canvas = document.createElement("canvas");
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext("2d");
        },

        // called once before every frame where the icon will be used
        render: function() {
          var duration = 1000;
          var t = (performance.now() % duration) / duration;

          var radius = (size / 2) * 0.3;
          var outerRadius = (size / 2) * 0.7 * t + radius;
          var context = this.context;

          // draw outer circle
          context.clearRect(0, 0, this.width, this.height);
          context.beginPath();
          context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2
          );
          switch(type){
            case 1:
              context.fillStyle = "rgba(255, 192, 0," + (1 - t) + ")";
            break;
            case 2:
              context.fillStyle = "rgba(237, 125, 49," + (1 - t) + ")";
            break;
            case 3:
              context.fillStyle = "rgba(255, 0, 0," + (1 - t) + ")";
            break;
          }
          
          context.fill();

          // draw inner circle
          context.beginPath();
          context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
          switch(type){
            case 1:
              context.fillStyle = "rgba(255, 192, 0, 1)";
            break;
            case 2:
              context.fillStyle = "rgba(237, 125, 49, 1)";
            break;
            case 3:
              context.fillStyle = "rgba(255, 100, 100, 1)";
            break;
          }
          
          context.strokeStyle = "white";
          context.lineWidth = 2 + 4 * (1 - t);
          context.fill();
          //context.stroke();
          // update this image's data with data from the canvas
          this.data = context.getImageData(0, 0, this.width, this.height).data;

          // continuously repaint the map, resulting in the smooth animation of the dot
          map.triggerRepaint();

          // return `true` to let the map know that the image was updated
          return true;
        }
      }
      map.addImage("hideaway-dot-"+type, pulsingDot, { pixelRatio: 2 });
    },
    addPointLayerToMap(map, geojson) {
      //let type = this.selects.data_category

      var layer = map.getLayer('points');
      if (layer) {
        map.removeLayer('points');
        map.removeSource('points');
      }
      
      if(typeof geojson.features == "undefined") return;
      let imgIcon = [];
      if(this.selects.data_category == 'hideaway'){
        //避难所
        if(!map.hasImage("hideaway-dot-1")) {
          this.makePointImageHideaway(map, 1) //1000-2000
          this.makePointImageHideaway(map, 2) //2000-3000
          this.makePointImageHideaway(map, 3) //>3000
          
        }
        geojson.features.forEach((item,idx)=>{
          if(item.properties.persons <= 2000){
            item.properties.type = 1
          } else if(item.properties.persons <= 3000){
            item.properties.type = 2
          } else {
            item.properties.type = 3
          }
        })
        imgIcon = ["concat", "hideaway-dot-", ["get","type"]];
      } else if (this.selects.data_category == 'hospital'){
        //医疗点
        if(!map.hasImage("hospital-dot-1-1")) {
          this.makePointImageHospital(map, 1, 1) //综合医院 //1000-2000
          this.makePointImageHospital(map, 1, 2) //综合医院 //2000-3000
          this.makePointImageHospital(map, 1, 3) //综合医院 //>3000
          this.makePointImageHospital(map, 2, 1) //社区医院 //1000-2000
          this.makePointImageHospital(map, 2, 2) //社区医院 //2000-3000
          this.makePointImageHospital(map, 2, 3) //社区医院 //>3000
        }
        geojson.features.forEach((item,idx)=>{
          if(item.properties.persons <= 1000){
            item.properties.capacity = 1
          } else if(item.properties.persons <= 2000){
            item.properties.capacity = 2
          } else {
            item.properties.capacity = 3
          }
        })
        imgIcon = ["concat", "hospital-dot-", ["get","category"], "-", ["get","capacity"]];
      } else if (this.selects.data_category == 'warehouse'){
        //
        if(!map.hasImage("warehouse-dot-1")) {
          this.makePointImageWarehouse(map, 1) //<5000
          this.makePointImageWarehouse(map, 2) //5000-10000
          this.makePointImageWarehouse(map, 3) //>10000
        }
        geojson.features.forEach((item,idx)=>{
          if(item.properties.area <= 5000){
            item.properties.capacity = 1
          } else if(item.properties.area <= 10000){
            item.properties.capacity = 2
          } else {
            item.properties.capacity = 3
          }
        })
        imgIcon = ["concat", "warehouse-dot-", ["get","capacity"]];
      }
      
       

      map.addSource("points", {
        type: "geojson",
        data: geojson
       }
      );
      map.addLayer({
        id: "points",
        type: "symbol",
        source: "points",
        paint: {
          "text-color": "#ffffff"
        },
        layout: {
          "icon-image": imgIcon,
          "text-field": ["get", "name"],
          "text-size": 12,
          "text-offset": [0, -3],
          "text-anchor": "top"
        }
      });

      // this.removeMapLayer(map, type);
      // let source_name = this.getMapSourceName(type);
      // let layer_id = this.getMapLayerId(type);
      // let geojson_url,icon = "";
      // debugger;
      // switch (type) {
      //   case "hospital":
      //     //geojson_url = consts.url_geojson_hospital;
      //     geojson_url = this.hospitalGeojson;
      //     icon = "hospital-15";
      //     break;
      //   case "hideaway":
      //     geojson_url = consts.url_geojson_hideaway;
      //     icon = "campsite-15";
      //     break;
      //   case "warehouse":
      //     geojson_url = consts.url_geojson_warehouse;
      //     icon = "town-hall-15";
      //     break;
      // }
      // if (geojson_url == "") {
      //   return;
      // }
      // var styleLoadStatus = map.isStyleLoaded();
      // if (styleLoadStatus) {
      //   map.addSource(source_name, {
      //     type: "geojson",
      //     data: geojson_url
      //   });
      //   map.addLayer({
      //     id: layer_id,
      //     type: "symbol",
      //     source: source_name,
      //     paint: {
      //       "text-color": "#f96332"
      //     },
      //     layout: {
      //       // "icon-ignore-placement": true,
      //       // "icon-allow-overlap": true,
      //       // "text-allow-overlap": true,
      //       "icon-image": icon,
      //       "text-field": "{name}",
      //       //"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
      //       "text-offset": [0, 0.6],
      //       "text-anchor": "top"
      //     }
      //   });
      //   return;
      // }
      // map.on("load", function() {
      //   map.addSource(source_name, {
      //     type: "geojson",
      //     data: geojson_url
      //   });
      //   map.addLayer({
      //     id: layer_id,
      //     type: "symbol",
      //     source: source_name,
      //     paint: {
      //       "text-color": "#f96332"
      //     },
      //     layout: {
      //       "icon-image": icon,
      //       "text-field": "{name}",
      //       //"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
      //       "text-offset": [0, 0.6],
      //       "text-anchor": "top"
      //     }
      //   });
      // });
    },
    handelMapLayer() {
      let type = this.selects.data_category
      this.fillStreets(this.city_id);
      this.setTipContent()
      //console.log(type);
      // this.selects.street = 0;
      // this.selects.community = 0;
      this.loadData(type);
      
      let types = ['people','hospital','hideaway','warehouse'];
      types.forEach(value => {
            if(value==type) {
              if(type == 'people') {
                this.fillBlockmapLayer(this.city_id)
              } else {
                this.fillPointLayer()
                //this.addPointLayerToMap(this.map, value);
              }
            } else {
              if(type == 'people') {
                this.removeMapLayer(this.map, 'points')
              } else {
                this.removeMapLayer(this.map, value);
              }
              
            }
      });

    },
    getMapLayerId(type) {
        let layer_id = type+'-layer';
        return layer_id;
    },
    getMapSourceName(type) {
      let source_name = type+"-data";
      return source_name;
    },
    removeMapLayer(map, type) {
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      if(type == 'points') {
        source_name = 'points';
        layer_id = 'points'
      }
      var layer = map.getLayer(layer_id);
      let layer_point_id = layer_id + "point";
      //描边
      var layer_stroke = map.getLayer(layer_id+'_stroke');
      if(layer_stroke){
          map.removeLayer(layer_id+'_stroke');
        }
      if (layer) {
        map.removeLayer(layer_id);
      }
      if(map.getSource(source_name)) {
        map.removeSource(source_name);
      } 
    },
    loadData(type, page=1) {
      switch(type) {
        case 'people':
          this.fillPeopleData(page);
          break;
        case 'gender':
          this.fillGenderData(page);
          break;
        case 'hospital':
          this.fillHospitalsData(page);     
          break;  
        case 'hideaway':
          this.fillHideawaysData(page);
          break; 
        case 'warehouse':
          this.fillWarehousesData(page);
          break;                                  
      }
    },
    loadRegionData (){
      let params = this.buildParams();
      getRegionGeo(params).then(data => {
        let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        this.regionGeojson = geoJson;
      });
    }
  },
    
};
</script>
<style lang="scss" scoped>
.card-map {
  min-height: 350px;
  height: calc(100vh - 48px);
  margin-bottom: 0;

  position: relative;
  .map-wrapper{
    width: 100%;
    height: calc(100vh - 100px);
    position: relative;
    & > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  #map {
    width: 100%;
    height: calc(100vh - 100px);
  }
  .radio-group {
    position: absolute;
    left: 30px;
    top: 10px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding: 10px;
  }
  .radio-group div {
    display: inline-block;
    padding: 0 10px;
  }
   .radio-group div input {
    vertical-align: -1px;
    padding-right: 2px;
   }
  .map_data_msg {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    // color: #02f4fe;
    color: #fff;
    padding: 10px;
    width: auto;
    .map_data_legend {
      margin-bottom: 5px;
    }
    .map_data_msg_wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .item {
        // width: 80px;
        line-height: 15px;
        padding: 5px 0;
      }
      .item span {
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 3px;
        vertical-align: middle;
        margin-left: 0;
      }
      .item-circle span {
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 100%;
        vertical-align: middle;
        margin-left: 0;
        position: relative;
        margin-right: 7px;
      }
      .item-circle span:after {
        content: '';
        position: absolute;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        border: 1px solid rgba(255,255,255, 0.5);
        left: -5px;
        top: -5px;
      }
      .map_data_msg_inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 10px;
      }
    }
    .map_data_msg_wrapper_vertical {
      flex-direction: column;
      .item {
        width: 130px;
      }
    }
  }
}
.main-panel {
  // height: calc(100vh - 130px);
  min-height: 350px;
}
.right-block {
  height: calc(100vh - 290px);
  min-height: 350px;
  overflow: hidden;
}
.tab-scroller {
  height: calc(100vh - 470px);
  min-height: 350px;
  overflow-y: auto;
}

.show_list {
  width: 100%;
  height: 50px;
  margin-top: 8px;
}
.show_list span {
  height: 32px;
  line-height: 36px;
  margin-left: 32px;
  font-size: 14px;
}
.show_list span i {
  height: 16px;
  padding: 2px 12px;
  background: #ffb236;
  margin: 0 4px;
  border-radius: 16px;
}
.show_list .up_show {
  display: block;
  float: left;
  font-size: 32px;
  cursor: pointer;
}
.show_list .download {
  display: block;
  float: right;
  font-size: 32px;
  cursor: pointer;
}
.footer {
  padding-top: 0;
}
.extended-forms .el-select {
  width: 100%;
  margin-bottom: 30px;
}

.extended-forms .progress {
  margin-bottom: 30px;
}
@import url("https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css");
.coordinates {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 5px 10px;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  display: none;
}
ul.nav__items > li:last-of-type a {
  margin-right: 0 !important;
}
.card-body .tab-content .tab-scroller {
  min-height: auto;
}

.chart-area {
  width: 100%;
}
.right-block .tab-pane {
    height: calc(100vh - 360px);
}
</style>
