<template>
    <div class="row" style="margin:0 -30px;">
        <div class="col-md-9" style="padding:0">
            <card class="card-map">
                <div id="map"></div>
                <menu-map :map="map" :mapDefault="mapdefaultSetting" showDisaster="false"></menu-map>
                <div class="radio-group">
                    <div v-for="(item, idx) in types" @click="changeType(item.id)">
                        <label :for='item.id'><input type="radio" :id="item.id" :value="item.id" v-model="curType">
                            {{ item.value }}</label>
                    </div>
                </div>
                <div class="map_data_msg" v-if="mapinfo">
                    <div class="map_data_legend">{{ mapinfo.title }}</div>
                    <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
                        <div class="item" v-for="(item, index) in mapinfo.names" :key="index">
                            <span :style="'background: ' + mapinfo.colors[index] + ''"></span>
                            {{ item }}
                        </div>
                    </div>
                </div>
                <!-- <div class="map_data_msg">
                    <div class="map_data_legend">用电量（MW）</div>
                    <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
                        <div class="item" v-for="(item, index) in options[0].data" :key="index">
                            <span :style="'background: ' + colors[0][index]" class="rect"></span>
                            {{ item.name }}
                        </div>
                    </div>
                </div> -->
                <div class="show_list">
                    <a class="up_show" v-on:click="showList">
                        <i class="now-ui-icons" :class="[show_list ? 'arrows-1_minimal-down' : 'arrows-1_minimal-up']"></i>
                    </a>
                    <!-- <a class="download" :href="downloadUrl">
                        <i class="now-ui-icons arrows-1_cloud-download-93"></i>
                    </a> -->
                    <span>
                        数据列表
                        <!-- <i>{{total}}</i> -->
                    </span>
                </div>
                <div class="show_list_record show_fire_list_record" v-show="show_list" ref="show_list_record">
                    <tabs type="primary" v-model="activeTab">
                        <tab-pane label="行政区划">
                            <el-table :data="data" max-height="600" :span-method="objectSpanMethod">
                                <el-table-column v-for="(item, index) in listheader" min-width="150" :label="item"
                                    :key="index">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row[index] }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </tab-pane>


                        <tab-pane label="线路">
                            <el-table :data="data2" empty-text="暂无数据！">
                                <el-table-column min-width="150" label="线路ID" property="line_id"></el-table-column>
                                <el-table-column min-width="150" label="起始节点ID" property="begin_node_id"></el-table-column>
                                <el-table-column min-width="150" label="终止节点ID" property="end_node_id"></el-table-column>
                                <el-table-column min-width="150" label="线路电压" property="voltage"></el-table-column>
                            </el-table>
                            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                                <div class>
                                    <p class="card-category">显示： {{ from2 + 1 }} - {{ to2 }} 总数： {{ total2 }}</p>
                                </div>
                                <n-pagination class="pagination-no-border" v-model="pagination2.currentPage"
                                    :per-page="pagination2.perPage" :total="total2" v-on:input="changePage2"></n-pagination>
                            </div>
                        </tab-pane>

                        <tab-pane label="节点">
                            <el-table :data="data1" empty-text="暂无数据！">
                                <el-table-column min-width="150" label="节点ID" property="node_id"></el-table-column>
                                <el-table-column min-width="150" label="节点类型" property="type_name"></el-table-column>
                                <el-table-column min-width="150" label="节点电压" property="voltage"></el-table-column>
                            </el-table>
                            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                                <div class>
                                    <p class="card-category">显示： {{ from1 + 1 }} - {{ to1 }} 总数： {{ total1 }}</p>
                                </div>
                                <n-pagination class="pagination-no-border" v-model="pagination1.currentPage"
                                    :per-page="pagination1.perPage" :total="total1" v-on:input="changePage1"></n-pagination>
                            </div>
                        </tab-pane>



                    </tabs>

                </div>



            </card>
        </div>
        <div class="col-md-3" id="customStyle">
            <el-collapse v-model="activeNames">
                <el-collapse-item title="信息图表" name="2">
                    <div class="col-md-12">
                        <div class="chart-area" id="box-chart" style="height:300px"></div>
                    </div>
                    <div class="col-md-12">
                        <div class="chart-area" id="box-chart2" style="height:300px"></div>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="数据筛选" name="3">
                    <div class="col-md-12 options1">
                        <el-cascader :options="options1" placeholder="行政区划" clearable @change="handleChange1">
                        </el-cascader>
                    </div>
                    <div class="col-md-12">
                        <el-cascader :options="options2" :props="props" placeholder="基本信息" clearable
                            @change="handleChange2">
                        </el-cascader>
                    </div>
                </el-collapse-item>
            </el-collapse>



        </div>
        <div class="key-infos">
            <!-- <div class="key-info" v-show="selects.data_category == 'people'">
          总人口：{{statistics.people.total_population}}，性别比：{{statistics.people.rate}}，平均年龄：{{statistics.people.age_avg}}
        </div>
        <div class="key-info" v-show="selects.data_category == 'hideaway'">
          {{statistics.hideaways.count}}个避难所，总容量{{statistics.hideaways.persons}}人
        </div>
        <div class="key-info" v-show="selects.data_category == 'hospital'">
          {{statistics.hospital.general_count}}家综合医院，{{statistics.hospital.community_count}}家社区医院，总床位数{{statistics.hospital.beds}}个
        </div>
        <div class="key-info" v-show="selects.data_category == 'warehouse'">
          {{statistics.warehouse.count}}个应急储备仓库，总面积{{statistics.warehouse.area}}平米
        </div> -->
        </div>
    </div>
</template>
<script>
import mapboxgl from "mapbox-gl";
import MenuMap from "src/components/MenumapFire2";
import geobuf from "geobuf";
import Pbf from "pbf";
import MiniMap from "src/mapbox/mapboxgl-minimap.js";
import { TabPane, Tabs, Switch } from "src/components";
import { Radio } from 'src/components/index';
import { Input, Button, Select, Option, Cascader, Collapse, CollapseItem, Table, TableColumn } from "element-ui";
import { consts } from "src/util/consts.js";
import {
    getCommonNodeGeojson as getNodeGeo,
    getCommonPipeGeojson as getPipeGeo,
    getVolumnOptions as getOptions1,
    //getVolumnGeojson as getGeo1,
    getBasicNodeList as getList1,
    getBasicPipeList as getList2,
    getVolumnStatistics as getStatistics1,
    //getVolumnChart as getChart1,
    addHighLigthLayer,
} from "src/api/earthquake/electric.js";
import {
    getBasicCommunityFilter as getOptions,
    getBasicChart2 as getChart2,
    getBasicList as getList3,
    //getBasicFullList as getList1,
    getFireBasicCommunityGeo as getGeo1,
    getFireBasicStreetGeo as getStreetGeo,
    getBasicExport as getExport,
    getBasicInfo as getInfo,
    getFireShowCommunityMap as getMapInfo,
    getBasicCommunityChart1 as getChart1,
} from "src/api/fire.js";
import { getHideawaylGeo } from 'src/api/cityinfo.js';
import { buildBarYOption } from "src/util/chart.js";
import { storeMapOptions, getMapOptions, parseJsonToString, setZoomLevel } from "src/util/common.js";
import { Pagination as NPagination } from "src/components";

import * as THREE from "three";

export default {
    components: {
        MenuMap,
        TabPane,
        Tabs,
        [Cascader.name]: Cascader,
        [Collapse.name]: Collapse,
        [CollapseItem.name]: CollapseItem,
        [Input.name]: Input,
        [Button.name]: Button,
        [Option.name]: Option,
        [Select.name]: Select,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        NPagination,
        [Switch.name]: Switch,
        Radio
    },
    data() {
        return {
            mid: 0, //模块id
            types: [], //左上角切换radio
            curType: 0,
            activeNames: ['2', '3'],
            activeName: "first",
            selects: {
                town_id: 1,
                street_id: 0,
                community_id: 0,
                ids: {},
                filter_street_id: 0,//地图筛选的street_id
            },
            props: { multiple: true },
            options1: [],
            options2: [],
            keyword: "",
            total: 0,
            data: [],
            data1: [],
            data2: [],
            data3: [],
            page: 1,
            map: {},
            city_id: localStorage.getItem("city_id") || 1,
            show_list: false,
            show_list_height: 0,
            pagination: {
                perPage: 10,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
                total: 20
            },
            last_updated: consts.last_updated,
            mapdefaultSetting: {},
            statistics: {
            },
            downloadUrl: '',
            options: [
                //{name: '用电量',selected:[],data: []},
            ],
            mapinfo: null,
            listheader: [],
            colors: [
                ['#fbe6a3', '#F5C243', '#D8833B', '#a6263a', '#791b3c', "#4c1c40"],
            ],
            options: [
                { name: '社区用电量', selected: [], data: [] },
            ],
            lineEventAdded: false,
            circleEventAdded: false,
            blockEventAdded: false,
            activeTab: '行政区划',
            pagination1: {
                perPage: 10,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
                total: 20
            },
            pagination2: {
                perPage: 10,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
                total: 20
            },
            pagination3: {
                perPage: 10,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
                total: 20
            },
            total1: 0,
            total2: 0,
            total3: 0,
            figures: [
                []
            ],
        };
    },

    mounted() {
        let _this = this;
        //localStorage.setItem('curStroke', 'street');
        //this.fillStreets(this.city_id);
        this.mid = this.$route.params.mid || 1;
        this.curType = this.mid;
        this.setTipContent()
        this.fillBasic() //填充筛选列表
        this.showChart(this.city_id);
        this.fillFigure()

        this.initMap(this.city_id);
        this.map.on('style.load', function () {
            getMapInfo(_this.buildParams()).then(res => {
                _this.mapinfo = res.data;

                //_this.fillList1() //初始化list，不随筛选改变
                _this.reloadData()
            });

        })


    },
    computed: {
        to1() {
            let highBound = this.from1 + this.pagination1.perPage;
            if (this.total1 < highBound) {
                highBound = this.total1;
            }
            return highBound;
        },
        from1() {
            return this.pagination1.perPage * (this.pagination1.currentPage - 1);
        },
        to2() {
            let highBound = this.from2 + this.pagination2.perPage;
            if (this.total2 < highBound) {
                highBound = this.total2;
            }
            return highBound;
        },
        from2() {
            return this.pagination2.perPage * (this.pagination2.currentPage - 1);
        },
        to3() {
            let highBound = this.from3 + this.pagination3.perPage;
            if (this.total3 < highBound) {
                highBound = this.total3;
            }
            return highBound;
        },
        from3() {
            return this.pagination3.perPage * (this.pagination3.currentPage - 1);
        },
        to() {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        // street (){
        //   return this.selects.street
        // }
    },
    watch: {

        "selects.street"(val) {
            //街道切换选社区
            this.fillCommunities(this.city_id, val);
        },
        activeTab(val) {
            let params = this.buildParams();
            if (val == '节点') {
                this.downloadUrl = consts.requestUrl + consts.exportElectricBasic1 + parseJsonToString(params)
            } else if (val == '线路') {
                this.downloadUrl = consts.requestUrl + consts.exportElectricBasic2 + parseJsonToString(params)
            } else if (val == '社区') {
                this.downloadUrl = consts.requestUrl + consts.exportFire2Alarm + parseJsonToString(params)
            }
        },
        // "mid"(val){
        //   if(val == 1) {
        //     if(localStorage.getItem('curStroke') == 'street') {
        //       //特殊页面
        //       this.$router.push({path: '/fire/building/history/alarm'})
        //     }
        //     if(localStorage.getItem('curStroke') == 'community'){
        //       //量产
        //       this.$router.push({path: '/fire/building/tp?mid='+val})
        //     }
        //   } else {
        //         this.$router.push({path: '/fire/building/tp?mid='+val})
        //   }


        // },
    },
    methods: {
        // 初始化
        initMap(city_id) {
            this.mapdefaultSetting = {
                center: consts.mapCenter[this.city_id - 1],
                zoom: consts.mapDefaultZoom,
                pitch: 0,
                city_id: this.city_id
            }
            mapboxgl.accessToken = consts.mapboxAccessToken;

            var origin = [-95.97299, 36.15031, 0];
            let map_options = getMapOptions();
            //console.log(map_options);
            let zoom = map_options ? map_options.zoom : 12;
            let center = map_options ? [map_options.lng, map_options.lat] : consts.mapCenter[city_id - 1];
            //console.log(center);
            var map = new mapboxgl.Map({
                container: "map",
                style: consts.mapBoxStyle,
                //center: [-96, 37.8],
                center: center,
                zoom: zoom,
                pitch: 0,
                bearing: 0,
                attributionControl: false
            });
            this.map = map;
            //定位图标
            map.addControl(
                new MapboxGeocoder({
                    accessToken: mapboxgl.accessToken,
                    mapboxgl: mapboxgl
                })
            );
            this.map.addControl(new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                trackUserLocation: true
            }));
            //控制图层
            var nav = new mapboxgl.NavigationControl();
            this.map.addControl(nav, 'top-right');
            //全屏按钮
            this.map.addControl(new mapboxgl.FullscreenControl({
                container: document.querySelector('body')
            })
            );
            //小地图
            //https://github.com/brendanmatkin/mapboxgl-minimap
            map.on("style.load", function () {
                // Possible position values are 'bottom-left', 'bottom-right', 'top-left', 'top-right'
                map.addControl(new MiniMap({
                    id: "mapboxgl-minimap",
                    width: "200px",
                    height: "150px",
                    //style: "mapbox://styles/mapbox/streets-v10",
                    style: consts.mapBoxStyle,
                    fillColor: "#f58220",
                    zoomAdjust: null
                }),
                    'bottom-right');
            });

            //记录地图设置
            map.on('move', function () {
                storeMapOptions({
                    lng: map.getCenter().lng,
                    lat: map.getCenter().lat,
                    zoom: map.getZoom()
                });
            });

        },
        showList() {
            this.show_list = !this.show_list;
        },
        donwloadList() { },
        showChart(city_id) {
            var self = this;
            var container = document.querySelector('.el-collapse');
            var box = document.getElementById("box-chart");
            var myChart = this.$echarts.init(box);
            box.style.width = container.offsetWidth + "px";
            getChart1({
                "city_id": this.city_id,
                "town_id": 1,
                "street_id": this.selects.filter_street_id,
                "module_id": this.mid
            }).then(res => {
                var chart_data = Array.from(res.data.data).reverse();
                var chart_name = res.data.title;
                var colors = Array.from(res.data.colors).reverse()


                // var chart_data = res.data.data;
                // var chart_name = res.data.title;
                // var colors = res.data.colors;

                chart_data.map((item, idx) => {
                    return item.color = colors[idx]
                })
                var option = buildBarYOption(chart_name, chart_data, 'name', {
                    grid: {
                        left: '2%',
                        right: '4%',
                        bottom: '30',
                        top: '60',
                        containLabel: true
                    },
                    xAxis: {
                        name: '社区数量（个）',
                        nameLocation: 'center',//坐标轴名称显示位置。
                        nameGap: 30,
                        type: 'value',
                        axisLine: {
                            lineStyle: {
                                color: "#fff"
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                fontSize: 12,
                                color: "#fff"
                            }
                        }
                    },
                    yAxis: {
                        name: '用电量（MW）',
                        nameLocatiosn: 'end',//坐标轴名称显示位置。
                    },
                });
                myChart.setOption(option);

            });

            window.onresize = function () {
                document.querySelectorAll('.chart-area').forEach((item, idx) => {
                    item.style.width = document.querySelector('.el-collapse').offsetWidth + 'px';
                })
                myChart.resize();
            }
        },
        handleChange1(value) {
            //右侧下拉change1
            this.selects.town_id = 1
            this.selects.street_id = value[2] || 0
            this.selects.community_id = value[3] || 0
            this.reloadData();

        },
        handleChange2(value) {
            //右侧下拉change2
            let _this = this;
            this.selects.filter_street_id = 0; //地图筛选的街道先清空
            this.selects.ids = {}
            value.forEach((item, idx) => {
                if (typeof _this.selects.ids[item[2]] == 'undefined') {
                    _this.selects.ids[item[2]] = '';
                }
                _this.selects.ids[item[2]] += item[3] + '-'
            })
            this.reloadData();
        },
        fillFigure() {
            //数据类型
            getOptions1().then(res => {
                let data = res.data;
                this.options[0].data = data;
                this.options[0].data.forEach((item, idx) => {
                    item.icon = this.figures[0][idx]
                    item.color = this.colors[0][idx]
                })
            });
        },
        fillBasic() {
            let _this = this;
            getOptions({
                "city_id": this.city_id,
                "town_id": 1
            }).then(res => {
                let data = res.data;
                //行政区划
                let towns = data.towns;
                let basic = data.basic;
                _this.options1 = [{
                    value: 1,
                    label: "杭州市",
                    children: [
                        {
                            value: towns.value,
                            label: towns.label,
                            children: [],
                        }
                    ]
                }]
                _this.options1[0]['children'][0]['children'] = towns.children
                //基本数据
                _this.options2 = basic

            });
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            let returnobj = null;
            let returnobj2 = null;
            let _this = this;
            if (columnIndex == 0) {
                this.col1IdxArr.forEach((item, idx) => {
                    if (rowIndex == item) {
                        returnobj = {
                            rowspan: _this.col1Arr[idx],
                            colspan: 1
                        };

                    } else {
                        returnobj = {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                })
                return returnobj;

            }
            if (columnIndex == 1) {
                let hasColspan = false;
                this.col2IdxArr.forEach((item, idx) => {
                    if (!hasColspan) {
                        returnobj2 = {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                    if (rowIndex == item) {
                        hasColspan = true;
                        returnobj2 = {
                            rowspan: _this.col2Arr[idx],
                            colspan: 1
                        };
                    }
                })
                return returnobj2;
            }
        },
        fillList3(city_id, page) {
            //社区
            let _this = this;
            let params = this.buildParams();
            //params.page = page;
            this.col1Arr = []; //第一列数量和
            this.col2Arr = []; //第二列数量和
            this.col1IdxArr = []; //第一列序号
            this.col2IdxArr = []; //第二列序号
            this.col1Last = ''; //第一行最后一项
            this.col2Last = ''; //第二行最后一项
            getList3(params).then(res => {
                //this.data = res.data;
                let header = res.data.header;
                let body = res.data.body;
                let data = [];
                if (body) {
                    body.forEach((item, idx) => {
                        let newitem = {}
                        item.forEach((subitem, subidx) => {
                            newitem[subidx] = subitem
                        })
                        data.push(newitem)
                    })
                }

                this.listheader = header;
                this.data = data;



                this.data.forEach((item, idx) => {
                    if (item['0'] == _this.col1Last) {
                        _this.col1Arr[_this.col1Arr.length - 1]++;
                    } else {
                        _this.col1Arr.push(1);
                        _this.col1IdxArr.push(idx);
                        _this.col1Last = item['0']
                    }

                    if (item['1'] == _this.col2Last) {
                        _this.col2Arr[_this.col2Arr.length - 1]++;
                    } else {
                        _this.col2Arr.push(1);
                        _this.col2IdxArr.push(idx);
                        _this.col2Last = item['1']
                    }

                })
            });
            // getExport(params).then(res => {
            //   const filename = res.headers["content-disposition"];
            //   const blob = new Blob([res.data]);
            //   var downloadElement = document.querySelector(".download");
            //   var href = window.URL.createObjectURL(blob);
            //   downloadElement.href = href;
            //   downloadElement.download = decodeURIComponent(filename.split("filename=")[1]);
            //   document.body.appendChild(downloadElement);
            //   downloadElement.click();
            //   document.body.removeChild(downloadElement);
            //   window.URL.revokeObjectURL(href); 

            // });

            // getStatistics1(params).then(res => {
            //   this.statistics = res.data;
            // });
            this.downloadUrl = consts.requestUrl + consts.exportFire2Alarm + parseJsonToString(params)

        },
        fillList1(city_id, page) {
            //节点
            let params = this.buildParams();
            params.page = page;
            getList1(params).then(res => {

                this.total1 = res.data.total;
                this.data1 = res.data.items;
            });
            getStatistics1(params).then(res => {
                this.statistics = res.data;
            });
            this.downloadUrl = consts.requestUrl + consts.exportElectricBasic1 + parseJsonToString(params)

        },
        fillList2(city_id, page) {
            //线路
            let params = this.buildParams();
            params.page = page;
            getList2(params).then(res => {
                this.total2 = res.data.total;
                this.data2 = res.data.items;
            });
        },
        fillList1xxx(city_id, page) {
            let _this = this;
            let params = this.buildParams();
            //params.page = page;
            this.col1Arr = []; //第一列数量和
            this.col2Arr = []; //第二列数量和
            this.col1IdxArr = []; //第一列序号
            this.col2IdxArr = []; //第二列序号
            this.col1Last = ''; //第一行最后一项
            this.col2Last = ''; //第二行最后一项
            getList1({
                "city_id": 1,
                "town_id": 1,
                "module_id": 2
            }).then(res => {
                //this.data = res.data;
                let header = res.data.header;
                let body = res.data.body;
                let data = [];
                body.forEach((item, idx) => {
                    let newitem = {}
                    item.forEach((subitem, subidx) => {
                        newitem[subidx] = subitem
                    })
                    data.push(newitem)
                })
                this.listheader = header;
                this.data = data;
                console.log(data)



                this.data.forEach((item, idx) => {
                    if (item['0'] == _this.col1Last) {
                        _this.col1Arr[_this.col1Arr.length - 1]++;
                    } else {
                        _this.col1Arr.push(1);
                        _this.col1IdxArr.push(idx);
                        _this.col1Last = item['0']
                    }

                    if (item['1'] == _this.col2Last) {
                        _this.col2Arr[_this.col2Arr.length - 1]++;
                    } else {
                        _this.col2Arr.push(1);
                        _this.col2IdxArr.push(idx);
                        _this.col2Last = item['1']
                    }

                })
            });
            // getStatistics1(params).then(res => {
            //   this.statistics = res.data;
            // });
            this.downloadUrl = consts.requestUrl + consts.exportFirePower + parseJsonToString(params)

        },
        fill2Map(city_id) {
            let _this = this
            let params = this.buildParams();
            this.removeMapLayer(this.map, 'nodeCommon')
            this.removeMapLayer(this.map, 'pipeCommon')
            this.removeMapLayer(this.map, 'blockCommon')
            //this.fillCommonGeo();
            getGeo1(params).then(res => {
                let data = res;
                let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
                this.addBlockLayerToMap(this.map, 'block', geoJson);
                getPipeGeo(params).then(res => {
                    let data = res;
                    let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
                    this.addLineLayerToMap(this.map, 'pipeCommon', geoJson);
                });
                getNodeGeo(params).then(res => {
                    let data = res;
                    let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
                    this.addCircle2Map(this.map, 'nodeCommon', geoJson);
                });
            });


        },
        fillCommunities(city_id, street_id) {
            if (this.selects.data_category == 'people') {
                getCommunities(city_id, street_id).then(res => {
                    let data = res.data;
                    data.unshift({ id: 0, name: "社区" });
                    this.selects.communities = data;
                });
            } else if (this.selects.data_category == 'hideaway') {
                getCommunitiesHideaway(city_id, street_id).then(res => {
                    let data = res.data;
                    data.unshift({ id: 0, name: "社区" });
                    this.selects.communities = data;
                });
            } else if (this.selects.data_category == 'hospital') {
                getCommunitiesHospital(city_id, street_id).then(res => {
                    let data = res.data;
                    data.unshift({ id: 0, name: "社区" });
                    this.selects.communities = data;
                });
            } else if (this.selects.data_category == 'warehouse') {
                getCommunitiesWarehouse(city_id, street_id).then(res => {
                    let data = res.data;
                    data.unshift({ id: 0, name: "社区" });
                    this.selects.communities = data;
                });
            }

        },
        fillCommonGeo() {
            let params = this.buildParams();
            getPipeGeo(params).then(res => {
                let data = res;
                let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
                this.addLineLayerToMap(this.map, 'pipeCommon', geoJson);
            });
            getNodeGeo(params).then(res => {
                let data = res;
                let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
                this.addCircle2Map(this.map, 'nodeCommon', geoJson);
            });


        },
        fillBlockmapLayer(city_id) {
            let params = this.buildParams();
            getPeopleGeo(params).then(data => {
                let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
                this.peoplegeoJson = geoJson;
                this.addBlockmapLayer(this.map, 'people', geoJson);
            });
        },
        addLineLayerToMap(map, type, geojson) {
            let _this = this;
            this.removeMapLayer(map, type);
            let source_name = this.getMapSourceName(type);
            let layer_id = this.getMapLayerId(type);
            let linecolor = ''
            if (typeof geojson.features == 'undefined') return;

            let colorExpress = "rgba(204,204,204,0.6)";
            map.addSource(source_name, {
                type: "geojson",
                data: geojson
            });
            //diameter material_name
            map.addLayer({
                id: layer_id,
                type: "line",
                source: source_name,
                "layout": {
                    "line-join": "round",
                    "line-cap": "round"
                },
                "paint": {
                    "line-color": colorExpress,
                    "line-width": consts.defaultLineWidth
                }
            });
            this.setMapLayer()
            setZoomLevel(this.map, layer_id, 'line-width')

            if (!_this.lineEventAdded) {
                _this.lineEventAdded = true;
                this.map.on("click", layer_id, function (e) {
                    if (e.features.length == 0) return;
                    addHighLigthLayer('line', e.features[0], _this)
                    var coordinates = e.features[0].geometry.coordinates.slice();

                    var html = "线路编号：" + e.features[0].properties.id + "<br>线路类型：" + e.features[0].properties.voltage + "Kv<br>起始节点：" + e.features[0].properties.begin_node_id + "<br>终止节点：" + e.features[0].properties.end_node_id;
                    _this.popup = new mapboxgl.Popup({ closeOnClick: false, className: 'map-popup' })
                        .setLngLat(e.lngLat)
                        .setHTML(html)
                        .addTo(_this.map);

                    _this.popup.on('close', () => {
                        _this.popup = null;

                        if (_this.map.getLayer('highlight')) {
                            _this.map.removeLayer('highlight');
                            _this.map.removeSource('highlight');
                        }
                    })

                });
            }

        },
        addCircle2Map(map, type, geoJsonData) {
            let _this = this;
            this.removeMapLayer(map, type);
            let source_name = this.getMapSourceName(type);
            let layer_id = this.getMapLayerId(type);
            if (typeof geoJsonData.features == 'undefined') return;


            map.addSource(source_name, {
                type: "geojson",
                data: geoJsonData
            });
            map.addLayer({
                id: layer_id,
                type: "circle",
                source: source_name,
                paint: {
                    // make circles larger as the user zooms from z12 to z22
                    'circle-radius': consts.defaultCircleRadius,
                    // color circles by ethnicity, using a match expression
                    // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-match
                    'circle-color': "rgba(204,204,204,1)",
                    'circle-stroke-color': '#333333',
                    'circle-stroke-width': 1
                }
            });
            this.setMapLayer()
            setZoomLevel(this.map, layer_id, 'circle-radius')
            if (!_this.circleEventAdded) {
                _this.circleEventAdded = true;
                this.map.on("click", layer_id, function (e) {
                    if (e.features.length == 0) return;
                    addHighLigthLayer('node', e.features[0], _this)
                    var coordinates = e.features[0].geometry.coordinates.slice();
                    var html = "节点ID：" + e.features[0].properties.id + "<br>节点类型：" + e.features[0].properties.type_name;
                    _this.popup = new mapboxgl.Popup({ closeOnClick: false, className: 'map-popup' })
                        .setLngLat(e.features[0].geometry.coordinates)
                        .setHTML(html)
                        .addTo(_this.map);

                    _this.popup.on('close', () => {
                        _this.popup = null;

                        if (_this.map.getLayer('highlight')) {
                            _this.map.removeLayer('highlight');
                            _this.map.removeSource('highlight');
                        }
                    })


                });
            }
        },
        setMapLayer() {
            if (this.map.getLayer(this.getMapLayerId('block')) && this.map.getLayer(this.getMapLayerId('pipeCommon')) && this.map.getLayer(this.getMapLayerId('nodeCommon'))) {
                if (this.map.getLayer('stroke')) {
                    this.map.moveLayer(this.getMapLayerId('nodeCommon'), 'stroke');
                }
                this.map.moveLayer(this.getMapLayerId('block'), this.getMapLayerId('pipeCommon'));
                this.map.moveLayer(this.getMapLayerId('block') + '_stroke', this.getMapLayerId('pipeCommon'));
                this.map.moveLayer(this.getMapLayerId('block'), this.getMapLayerId('nodeCommon'));
                this.map.moveLayer(this.getMapLayerId('block') + '_stroke', this.getMapLayerId('nodeCommon'));
                this.map.moveLayer(this.getMapLayerId('pipeCommon'), this.getMapLayerId('nodeCommon'));
            }
        },
        addBlockLayerToMap(map, type, geojson) {
            let _this = this;
            this.removeMapLayer(map, type);
            let source_name = this.getMapSourceName(type);
            let layer_id = this.getMapLayerId(type);
            let layer_id_stroke = layer_id + '_stroke';
            if (typeof geojson.features == 'undefined') return;
            map.addSource(source_name, {
                type: "geojson",
                data: geojson
            });
            map.addLayer({
                id: layer_id,
                type: "fill",
                source: source_name,
                'paint': {
                    'fill-color': [
                        "step",
                        ["get", "rate"],
                        "#fbe6a3", 5.001,
                        "#F5C243", 10.001,
                        "#D8833B", 15.001,
                        "#a6263a", 20.001,
                        "#791b3c", 25.001,
                        "#4c1c40",
                    ],
                    'fill-opacity': 0.8
                }
            });
            //描边
            map.addLayer({
                id: layer_id_stroke,
                type: "line",
                source: source_name,
                layout: {},
                paint: {
                    "line-color": '#333333',
                    "line-width": 1
                }
            });
            this.setMapLayer()
            if (!_this.blockEventAdded) {
                _this.blockEventAdded = true;
                this.map.on("click", layer_id, function (e) {
                    if (e.features.length == 0) return;
                    addHighLigthLayer('block', e.features[0], _this)
                    var coordinates = e.features[0].geometry.coordinates.slice();
                    var html = e.features[0].properties.community_name + "<br>所属街道：" + e.features[0].properties.street_name + "<br>用电量：" + e.features[0].properties.rate + "MW<br>人口：" + e.features[0].properties.population;
                    _this.popup = new mapboxgl.Popup({ closeOnClick: false, className: 'map-popup' })
                        .setLngLat(e.lngLat)
                        .setHTML(html)
                        .addTo(_this.map);
                    _this.popup.on('close', () => {
                        _this.popup = null;

                        if (_this.map.getLayer('highlight')) {
                            _this.map.removeLayer('highlight');
                            _this.map.removeSource('highlight');
                        }
                    })

                });
            }
        },
        mapClickEvent(e) {
            let _this = this;
            if (e.features.length > 0) {
                if (_this.hoveredStateId !== null) {
                    _this.map.setFeatureState(
                        { source: _this.source_name, id: _this.hoveredStateId },
                        { hover: false }
                    );
                }
                if (e.features[0].id == _this.hoveredStateId) {
                    _this.hoveredStateId = null
                    _this.selects.filter_street_id = 0
                    _this.fillList1()
                    return
                };

                _this.hoveredStateId = e.features[0].id;
                _this.map.setFeatureState(
                    { source: _this.source_name, id: _this.hoveredStateId },
                    { hover: true }
                );
                //筛选列表
                _this.selects.filter_street_id = e.features[0].properties.street_id
                _this.fillList1()

            }
        },
        reloadData(type) {
            //this.fillList1()
            this.fillList1(this.city_id, 1);
            this.fillList2(this.city_id, 1);
            this.fillList3(this.city_id, 1);
            //this.fillList3(this.city_id, 1);
            this.fill2Map(this.city_id);
        },
        setTipContent() {
            let _this = this;
            let html = ''

            getInfo({
                "module_id": this.mid,
            }).then(res => {
                document.getElementById('left-tip-content').innerHTML = res.data.info;
                this.types = res.data.types
            });

        },
        changePage1: function (value) {
            this.fillList1(this.city_id, value);
        },
        changePage2: function (value) {
            this.fillList2(this.city_id, value);
        },
        changePage3: function (value) {
            this.fillList3(this.city_id, value);
        },
        buildParams() {
            let params = { city_id: this.city_id };
            params.town_id = this.selects.town_id;
            if (this.selects.filter_street_id === 0) {
                //地图没有筛选街道,用select的街道
                params.street_id = this.selects.street_id;
            } else {
                //地图点击了街道
                params.street_id = this.selects.filter_street_id;
            }
            params.community_id = this.selects.community_id;
            //params.street_id = this.selects.street_id;
            params.module_id = this.mid;
            params.page_size = 10;
            params.demand_ids = (this.options[0].selected.length == 0 || this.options[0].selected.length == this.options[0].data.length) ? 0 : (this.options[0].selected.sort((a, b) => a - b).join("-"));

            Object.assign(params, {
                "c2": "0",
                "c29": "0",
                "c4": "0",
                "c6": "0",
                "c8": "0",
                "c11": "0",
                "c14": "0",
                "c16": "0",
                "c19": "0",
                "c23": "0",
                "c24": "0",
                "c27": "0",
                "c28": "0",
                "c35": "0",
                "c56": "0",
                "c79": "0",
                "c103": "0",
                "c122": "0",
                "c138": "0",
                "c141": "0",
                "c144": "0",
                "c145": "0",
                "c146": "0",
                "c147": "0",
                "c149": "0",
                "c152": "0",
                "c154": "0",
                "c156": "0",
                "c159": "0",
                "c162": "0",
                "c163": "0",
                "c164": "0",
                "c165": "0",
                "c166": "0",
                "c169": "0",
                "c172": "0",
                "c175": "0",
                "c177": "0",
                "c180": "0",
                "c182": "0",
                "c183": "0",
                "c184": "0",
                "c185": "0",
                "c186": "0",
                "c187": "0",
                "c188": "0",
                "c189": "0",
                "c192": "0",
                "c194": "0",
                "c196": "0",
                "c198": "0",
                "c199": "0",
                "c200": "0",
                "c203": "0",
                "c206": "0",
                "c207": "0",
                "c208": "0",
                "c209": "0",
                "c210": "0",
                "c212": "0",
                "c214": "0",
                "c215": "0",
                "c216": "0",
                "c218": "0",
                "c221": "0",
            }, this.selects.ids)
            //Object.assign(params, this.selects.ids)

            return params;
        },
        changePage: function (value) {
            if (value != this.page) {
                this.loadData(this.selects.data_category, value);
            }
            this.page = value;
        },
        fillPointLayer() {
            let params = this.buildParams();
            getStreetGeo(params).then(data => {
                let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
                //this.addBlockmapLayer(this.map,'region')
                this.addPointLayerToMap(this.map, geoJson);
            });

        },
        makePointImageWarehouse(map, type) {
            let size = 150;
            switch (type) {
                case 1:
                    size = 80;
                    break;
                case 2:
                    size = 120;
                    break;
                case 3:
                    size = 160;
                    break;
            }
            //point type
            let pulsingDot = {
                width: size,
                height: size,
                data: new Uint8Array(size * size * 4),

                // get rendering context for the map canvas when layer is added to the map
                onAdd: function () {
                    var canvas = document.createElement("canvas");
                    canvas.width = this.width;
                    canvas.height = this.height;
                    this.context = canvas.getContext("2d");
                },

                // called once before every frame where the icon will be used
                render: function () {
                    var duration = 1000;
                    var t = (performance.now() % duration) / duration;

                    var radius = (size / 2) * 0.3;
                    var outerRadius = (size / 2) * 0.7 * t + radius;
                    var context = this.context;

                    // draw outer circle
                    context.clearRect(0, 0, this.width, this.height);
                    context.beginPath();
                    context.arc(
                        this.width / 2,
                        this.height / 2,
                        outerRadius,
                        0,
                        Math.PI * 2
                    );
                    switch (type) {
                        case 1:
                            context.fillStyle = "rgba(255, 192, 0," + (1 - t) + ")";
                            break;
                        case 2:
                            context.fillStyle = "rgba(237, 125, 49," + (1 - t) + ")";
                            break;
                        case 3:
                            context.fillStyle = "rgba(255, 0, 0," + (1 - t) + ")";
                            break;
                    }

                    context.fill();

                    // draw inner circle
                    context.beginPath();
                    context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
                    switch (type) {
                        case 1:
                            context.fillStyle = "rgba(255, 192, 0, 1)";
                            break;
                        case 2:
                            context.fillStyle = "rgba(237, 125, 49, 1)";
                            break;
                        case 3:
                            context.fillStyle = "rgba(255, 100, 100, 1)";
                            break;
                    }

                    context.strokeStyle = "white";
                    context.lineWidth = 2 + 4 * (1 - t);
                    context.fill();
                    //context.stroke();
                    // update this image's data with data from the canvas
                    this.data = context.getImageData(0, 0, this.width, this.height).data;

                    // continuously repaint the map, resulting in the smooth animation of the dot
                    map.triggerRepaint();

                    // return `true` to let the map know that the image was updated
                    return true;
                }
            }
            map.addImage("warehouse-dot-" + type, pulsingDot, { pixelRatio: 2 });
        },
        makePointImageHospital(map, type, capacity) {
            let size = 150;
            switch (capacity) {
                case 1:
                    size = 80;
                    break;
                case 2:
                    size = 120;
                    break;
                case 3:
                    size = 160;
                    break;
            }
            //point type
            let pulsingDot = {
                width: size,
                height: size,
                data: new Uint8Array(size * size * 4),

                // get rendering context for the map canvas when layer is added to the map
                onAdd: function () {
                    var canvas = document.createElement("canvas");
                    canvas.width = this.width;
                    canvas.height = this.height;
                    this.context = canvas.getContext("2d");
                },

                // called once before every frame where the icon will be used
                render: function () {
                    var duration = 1000;
                    var t = (performance.now() % duration) / duration;

                    var radius = (size / 2) * 0.3;
                    var outerRadius = (size / 2) * 0.7 * t + radius;
                    var context = this.context;

                    // draw outer circle
                    context.clearRect(0, 0, this.width, this.height);
                    context.beginPath();
                    context.arc(
                        this.width / 2,
                        this.height / 2,
                        outerRadius,
                        0,
                        Math.PI * 2
                    );
                    switch (type) {
                        case 1: //综合医院
                            context.fillStyle = "rgba(255, 0, 0," + (1 - t) + ")";
                            break;
                        case 2: //社区医院
                            context.fillStyle = "rgba(237, 125, 49," + (1 - t) + ")";
                            break;
                    }

                    context.fill();

                    // draw inner circle
                    context.beginPath();
                    context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
                    switch (type) {
                        case 1:
                            context.fillStyle = "rgba(255, 100, 100, 1)";
                            break;
                        case 2:
                            context.fillStyle = "rgba(237, 125, 49, 1)";
                            break;
                    }

                    context.strokeStyle = "white";
                    context.lineWidth = 2 + 4 * (1 - t);
                    context.fill();
                    //context.stroke();
                    // update this image's data with data from the canvas
                    this.data = context.getImageData(0, 0, this.width, this.height).data;

                    // continuously repaint the map, resulting in the smooth animation of the dot
                    map.triggerRepaint();

                    // return `true` to let the map know that the image was updated
                    return true;
                }
            }
            map.addImage("hospital-dot-" + type + "-" + capacity, pulsingDot, { pixelRatio: 2 });
        },
        makePointImageHideaway(map, type) {
            let size = 150;
            switch (type) {
                case 1:
                    size = 80;
                    break;
                case 2:
                    size = 120;
                    break;
                case 3:
                    size = 160;
                    break;
            }
            //point type
            let pulsingDot = {
                width: size,
                height: size,
                data: new Uint8Array(size * size * 4),

                // get rendering context for the map canvas when layer is added to the map
                onAdd: function () {
                    var canvas = document.createElement("canvas");
                    canvas.width = this.width;
                    canvas.height = this.height;
                    this.context = canvas.getContext("2d");
                },

                // called once before every frame where the icon will be used
                render: function () {
                    var duration = 1000;
                    var t = (performance.now() % duration) / duration;

                    var radius = (size / 2) * 0.3;
                    var outerRadius = (size / 2) * 0.7 * t + radius;
                    var context = this.context;

                    // draw outer circle
                    context.clearRect(0, 0, this.width, this.height);
                    context.beginPath();
                    context.arc(
                        this.width / 2,
                        this.height / 2,
                        outerRadius,
                        0,
                        Math.PI * 2
                    );
                    switch (type) {
                        case 1:
                            context.fillStyle = "rgba(255, 192, 0," + (1 - t) + ")";
                            break;
                        case 2:
                            context.fillStyle = "rgba(237, 125, 49," + (1 - t) + ")";
                            break;
                        case 3:
                            context.fillStyle = "rgba(255, 0, 0," + (1 - t) + ")";
                            break;
                    }

                    context.fill();

                    // draw inner circle
                    context.beginPath();
                    context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
                    switch (type) {
                        case 1:
                            context.fillStyle = "rgba(255, 192, 0, 1)";
                            break;
                        case 2:
                            context.fillStyle = "rgba(237, 125, 49, 1)";
                            break;
                        case 3:
                            context.fillStyle = "rgba(255, 100, 100, 1)";
                            break;
                    }

                    context.strokeStyle = "white";
                    context.lineWidth = 2 + 4 * (1 - t);
                    context.fill();
                    //context.stroke();
                    // update this image's data with data from the canvas
                    this.data = context.getImageData(0, 0, this.width, this.height).data;

                    // continuously repaint the map, resulting in the smooth animation of the dot
                    map.triggerRepaint();

                    // return `true` to let the map know that the image was updated
                    return true;
                }
            }
            map.addImage("hideaway-dot-" + type, pulsingDot, { pixelRatio: 2 });
        },
        addPointLayerToMap2(map, geojson) {
            //let type = this.selects.data_category

            var layer = map.getLayer('points');
            if (layer) {
                map.removeLayer('points');
                map.removeSource('points');
            }

            if (typeof geojson.features == "undefined") return;
            let imgIcon = [];
            if (!map.hasImage("hideaway-dot-1")) {
                this.makePointImageHideaway(map, 1) //1000-2000
                this.makePointImageHideaway(map, 2) //2000-3000
                this.makePointImageHideaway(map, 3) //>3000

            }
            geojson.features.forEach((item, idx) => {
                if (item.properties.persons <= 2000) {
                    item.properties.type = 1
                } else if (item.properties.persons <= 3000) {
                    item.properties.type = 2
                } else {
                    item.properties.type = 3
                }
            })
            imgIcon = ["concat", "hideaway-dot-", ["get", "type"]];



            map.addSource("points", {
                type: "geojson",
                data: geojson
            }
            );
            map.addLayer({
                id: "points",
                type: "symbol",
                source: "points",
                paint: {
                    "text-color": "#ffffff"
                },
                layout: {
                    "icon-image": imgIcon,
                    "text-field": ["get", "name"],
                    "text-size": 12,
                    "text-offset": [0, -3],
                    "text-anchor": "top"
                }
            });
        },
        addPointLayerToMap(map, geojson) {
            //let type = this.selects.data_category

            var layer = map.getLayer('points');
            if (layer) {
                map.removeLayer('points');
                map.removeSource('points');
            }

            if (typeof geojson.features == "undefined") return;
            let imgIcon = [];
            if (!map.hasImage("hideaway-dot-1")) {
                this.makePointImageHideaway(map, 1) //1000-2000
                this.makePointImageHideaway(map, 2) //2000-3000
                this.makePointImageHideaway(map, 3) //>3000

            }
            geojson.features.forEach((item, idx) => {
                if (item.properties.persons <= 2000) {
                    item.properties.type = 1
                } else if (item.properties.persons <= 3000) {
                    item.properties.type = 2
                } else {
                    item.properties.type = 3
                }
            })
            imgIcon = ["concat", "hideaway-dot-", ["get", "type"]];



            map.addSource("points", {
                type: "geojson",
                data: geojson
            }
            );
            map.addLayer({
                id: "points",
                type: "symbol",
                source: "points",
                paint: {
                    "text-color": "#ffffff"
                },
                layout: {
                    //"icon-image": imgIcon,
                    "text-field": ["get", "name"],
                    "text-size": 12,
                    "text-offset": [0, 0],
                    "text-anchor": "top"
                }
            });
        },
        getMapLayerId(type) {
            let layer_id = type + '-layer';
            return layer_id;
        },
        getMapSourceName(type) {
            let source_name = type + "-data";
            return source_name;
        },
        removeMapLayer(map, type) {
            let source_name = this.getMapSourceName(type);
            let layer_id = this.getMapLayerId(type);
            if (type == 'points') {
                source_name = 'points';
                layer_id = 'points'
            }
            var layer = map.getLayer(layer_id);
            let layer_point_id = layer_id + "point";
            //描边
            var layer_stroke = map.getLayer(layer_id + '_stroke');
            if (layer_stroke) {
                map.removeLayer(layer_id + '_stroke');
            }
            if (layer) {
                map.removeLayer(layer_id);
            }
            if (map.getSource(source_name)) {
                map.removeSource(source_name);
            }
        },
        // changeRegin(type){
        //   //切换街道社区
        //   if(type == 'street'){
        //     //街道
        //     this.$router.push({
        //       path: '/fire/building/history/alarm'
        //     })
        //   } else if(type=='community') {
        //     //社区
        //     this.$router.push({
        //       path: '/fire/building/tp?mid='+this.mid
        //     })
        //   }
        // },
        changeType(val) {
            //切换左上角列表
            if (val == 1) {
                if (localStorage.getItem('curStroke') == 'street') {
                    //特殊页面
                    this.$router.push({ path: '/fire/building/alarm/1' })
                }
                if (localStorage.getItem('curStroke') == 'community') {
                    //量产
                    this.$router.push({ path: '/fire/building/tp' + val })
                }
            } else {
                this.$router.push({ path: '/fire/building/tp' + val })
            }
        }
    },

};
</script>
<style lang="scss" >
.el-table__row td {
    border-right: 1px solid #eee;
}

.el-table__body-wrapper {
    background: #394761;
}

.options1 .el-cascader .el-input__inner {
    height: 50px;
}

/*.el-table__body-wrapper::-webkit-scrollbar{z-index:11;width:6px}
    .el-table__body-wrapper::-webkit-scrollbar:horizontal{height:6px}
    .el-table__body-wrapper::-webkit-scrollbar-thumb{border-radius:5px;width:6px;background:#b4bccc}
    .el-table__body-wrapper::-webkit-scrollbar-corner,.el-table__body-wrapper::-webkit-scrollbar-track{background:#fff}
    .el-table__body-wrapper::-webkit-scrollbar-track-piece{background:#fff;width:6px}*/
</style>
<style lang="scss" scoped>
.card-map {
    min-height: 350px;
    height: calc(100vh - 48px);
    margin-bottom: 0;

    position: relative;

    .map-wrapper {
        width: 100%;
        height: calc(100vh - 100px);
        position: relative;

        &>* {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    #map {
        width: 100%;
        height: calc(100vh - 100px);
    }

    .radio-group {
        position: absolute;
        left: 10px;
        top: 10px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 5px;
        padding: 10px;
    }

    .radio-group div {
        padding: 0 10px;
    }

    .radio-group div input {
        vertical-align: -1px;
        padding-right: 2px;
    }


}

.main-panel {
    // height: calc(100vh - 130px);
    min-height: 350px;
}

.right-block {
    height: calc(100vh - 290px);
    min-height: 350px;
    overflow: hidden;
}

.tab-scroller {
    height: calc(100vh - 470px);
    min-height: 350px;
    overflow-y: auto;
}

.show_list {
    width: 100%;
    height: 50px;
    margin-top: 8px;
}

.show_list span {
    height: 32px;
    line-height: 36px;
    margin-left: 32px;
    font-size: 14px;
}

.show_list span i {
    height: 16px;
    padding: 2px 12px;
    background: #ffb236;
    margin: 0 4px;
    border-radius: 16px;
}

.show_list .up_show {
    display: block;
    float: left;
    font-size: 32px;
    cursor: pointer;
}

.show_list .download {
    display: block;
    float: right;
    font-size: 32px;
    cursor: pointer;
}

.footer {
    padding-top: 0;
}

.extended-forms .el-select {
    width: 100%;
    margin-bottom: 30px;
}

.extended-forms .progress {
    margin-bottom: 30px;
}

@import url("https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css");

.coordinates {
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    position: absolute;
    bottom: 10px;
    left: 10px;
    padding: 5px 10px;
    margin: 0;
    font-size: 11px;
    line-height: 18px;
    border-radius: 3px;
    display: none;
}

ul.nav__items>li:last-of-type a {
    margin-right: 0 !important;
}

.card-body .tab-content .tab-scroller {
    min-height: auto;
}

.chart-area {
    width: 100%;
}

.right-block .tab-pane {
    height: calc(100vh - 360px);
}
</style>
  