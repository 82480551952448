export function getBuildYearRange(index, buildYears)
{
    var range = buildYears[index];
    var ranges = range.split('-');
    return {start:ranges[0], end:ranges[1]};
}

export function getQuakeYearRange(index, quakeYears)
{
    if (index == 0) return {start: 0, end: 0};
    if (index == 1) {
        return {start:0, end:1000}
    }
    var range = quakeYears[index];
    var ranges = range.split('-');
    return {start:ranges[0], end:ranges[1]};
}

export function getQuakeLevelRange(index, quakeLevels)
{
    if (index == 0) return {start: 0, end: 0}
    if (index == 1) {
        return {start:0, end:4}
    }
    if (index == 5) {
        return {start:7.1, end:100}
    }
    var range = quakeLevels[index];
    var ranges = range.split('-');
    return {start:(ranges[0]-0+0.1), end:ranges[1]};
}

export function storeMapOptions(options)
{
    localStorage.setItem("mapOptions",JSON.stringify(options));
}

export function getMapOptions()
{
    let value = localStorage.getItem("mapOptions");
    if (value) {
        return JSON.parse(value);
    }
    return null;
}

export function parseJsonToString(obj)
{
    let str = '?'
    for(let key in obj) {
        str += key+'='+obj[key]+'&'
    }
    return str;
}

export function setZoomLevel(map, layer_id, property)
{
    if(property == 'line-width') {
        map.setPaintProperty(layer_id, 'line-width', [
          "interpolate",
          ["exponential", 0.5],
          ["zoom"],
          10, //缩放比例
          1, //值
          12,
          3, //值
          22,
          5
        ]);
      } else if(property == 'circle-radius'){
        map.setPaintProperty(layer_id, 'circle-radius', [
          "interpolate",
          ["exponential", 0.5],
          ["zoom"],
          10, //缩放比例
          2, //值
          12,
          6, //值
          22,
          8
        ]);
      }
}

