<template>
  <div>
    <div class="map-menu">
      <div class="mapboxgl-ctrl-icon" @click="setDefault" title="默认视图"> <span class="el-icon-s-home"></span></div>
      <div class="mapboxgl-ctrl-icon pitch" @click="setPitch" title="2D/3D">{{ is2d ? '2D' : '3D' }}</div>
    </div>
    <div class="stroke-switch"><span>行政区划</span> <a :class="[curStroke == 'region' ? 'cur' : '']"
        @click="strokeRegion">城区</a>
      | <a :class="[curStroke == 'street' ? 'cur' : '']" @click="strokeStreet">街道</a> | <a
        :class="[curStroke == 'community' ? 'cur' : '']" @click="strokeCommunity">社区</a>
    </div>
    <div class="disaster-selecter" v-show="showDisaster != 'false'">
      <select name="" id="">
        <option value="">M 8.0, D = 15km</option>
      </select>
    </div>
    <div class="weather-selecter" :class="showFull ? 'full' : ''" v-if="showWeather != 'false' && !!weather"
      :style="{ marginTop: marginTop + 'px' }">
      <div class="weather-selecter-header" @click="showFull = !showFull"></div>
      <table>
        <tr>
          <td>气温（摄氏度）</td>
          <td>{{ weather.c1 }}</td>
        </tr>
        <tr>
          <td>相对湿度</td>
          <td>{{ weather.c2 }}</td>
        </tr>
        <tr>
          <td>风向</td>
          <td>{{ weather.c3 }}</td>
        </tr>
        <tr>
          <td>常年风向</td>
          <td>{{ weather.c4 }}</td>
        </tr>
        <tr>
          <td>风力（级）</td>
          <td>{{ weather.c5 }}</td>
        </tr>
        <tr>
          <td>连续无降水日数（天）</td>
          <td>{{ weather.c6 }}</td>
        </tr>
        <tr>
          <td>日降水量（毫米）</td>
          <td>{{ weather.c7 }}</td>
        </tr>

        <tr>
          <td>气温对应的火险气象指数分量</td>
          <td>{{ weather.c9 }}</td>
        </tr>
        <tr>
          <td>相对湿度对应的火险气象指数分量</td>
          <td>{{ weather.c10 }}</td>
        </tr>
        <tr>
          <td>风力对应的火险气象指数分量</td>
          <td>{{ weather.c11 }}</td>
        </tr>
        <tr>
          <td>连续无降水日数对应的火险气象指数分量</td>
          <td>{{ weather.c12 }}</td>
        </tr>
        <tr>
          <td>日降水量对应的火险气象指数分量</td>
          <td>{{ weather.c13 }}</td>
        </tr>
        <tr>
          <td>城市火险气象指数</td>
          <td>{{ weather.c8 }}</td>
        </tr>
        <tr>
          <td>气象</td>
          <td>{{ weather.c14 }}</td>
        </tr>
      </table>
    </div>

  </div>
</template>
<script>
import { consts } from "../../src/util/consts.js";
import request from '@/util/request';
import { Switch } from "../../src/components";
import mapboxgl from "mapbox-gl";
import geobuf from "geobuf";
import Pbf from "pbf";
import {
  getWeather2 as getWeather,
} from "src/api/fire.js";
export default {
  components: {
    [Switch.name]: Switch,
  },
  name: 'map-menu',
  props: {
    map: {
      type: Object,
      required: true
    },
    mapDefault: {
      type: Object
    },
    showDisaster: {
      type: String,
    },
    showWeather: {
      type: String,
      default: 'false',
    },
    marginTop: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      curStroke: '',
      is2d: true,
      switches: {
        showServiceArea: true
      },
      weather: null,
      showFull: false, //显示全部气象
    }
  },
  watch: {
    mapDefault(val) {
      if (val.pitch == 0) this.is2d = true;
      else this.is2d = false;
      this.map.on('style.load', () => {
        if (localStorage.getItem('curStroke') == 'region') {
          //this.strokeRegion()
        }
        if (localStorage.getItem('curStroke') == 'street') {
          this.curStroke = 'street';
          //this.strokeStreet()
        }
        if (localStorage.getItem('curStroke') == 'community') {
          this.curStroke = 'community';
          //this.strokeCommunity()
        }

      })

    },
    // curStroke(val) {
    //   localStorage.setItem('curStroke', val)
    // },
    "switches.showServiceArea"(val) {
      localStorage.setItem('StrokeServiceArea', val)
      this.strokeServiceArea();

    },
  },
  mounted() {
    this.checkRegin();
    this.getWeatherData()
  },
  methods: {
    getWeatherData() {
      let _this = this;
      getWeather({
        "city_id": localStorage.getItem("city_id") || 1,
        "town_id": 1
      }).then(res => {
        let data = res.data;
        _this.weather = data;
        // Ldate: "202203291535"
        // actual: "1615"
        // actualObservTimes: "202203291615"
        // forecast1: "杭州市气象台2022年3月29日15时30分发布的市区天气预报：今天傍晚到夜里多云；明天白天阴到多云，有短时阵雨；明天夜里阴有中阵雨或雷雨；后天早晨到上午小雨转阴，下午到夜里阴到多云。今天傍晚到明天白天偏南风3-4级，明天傍晚到夜里转偏北风3-4级阵风5级。明天白天最高气温24度，明天早晨最低气温14度，明天平均相对湿度75%。"
        // humi: "45"
        // press: "1011"
        // rain: "0"
        // temp: "22.7"
        // tgtemp: "22.8"
        // visi: "18953"
        // windd: "东南偏东风"
        // windspeed: "1.2米/秒"
        // windv: "1级"        
      });
    },
    setDefault() {
      //debugger;
      this.map.flyTo({
        // These options control the ending camera position: centered at
        // the target, at zoom level 9, and north up.
        center: this.mapDefault.center,
        zoom: this.mapDefault.zoom,
        bearing: 0,
        pitch: this.mapDefault.pitch,

        // These options control the flight curve, making it move
        // slowly and zoom out almost completely before starting
        // to pan.
        speed: 0.8, // make the flying slow
        curve: 1, // change the speed at which it zooms out

        // This can be any easing function: it takes a number between
        // 0 and 1 and returns another number between 0 and 1.
        easing: function (t) {
          return t;
        }
      });
      // this.map.setCenter(this.mapDefault.center)
      // this.map.setZoom(this.mapDefault.zoom)
      // this.map.setPitch(this.mapDefault.pitch)

    },
    setPitch() {
      if (this.is2d) {
        //切换3d
        this.map.flyTo({
          pitch: 60,
          speed: 0.8, // make the flying slow
          curve: 1, // change the speed at which it zooms out
          easing: function (t) {
            return t;
          }
        });
        this.is2d = false;
      } else {
        this.map.flyTo({
          pitch: 0,
          speed: 0.8, // make the flying slow
          curve: 1, // change the speed at which it zooms out
          easing: function (t) {
            return t;
          }
        });
        this.is2d = true;
      }
    },
    strokeRegion() {

      return;
      //this.curStroke = 'region';
      //this.$parent.$parent.changeRegin('community')
      // if(this.curStroke == 'region'){
      //   this.removeMapLayer('stroke');
      //   this.curStroke = ''
      //   return;
      // }
      // this.curStroke = 'region';
      // if(!!this.regionGeojson) {
      //   this.addBlockmapLayer(this.map, 'stroke', this.regionGeojson);
      //   return;
      // }
      // request({
      //     url: 'api/regions/geo',
      //     method: 'get',
      //     params: {
      //       city_id: this.mapDefault.city_id
      //     },
      //     responseType: 'arraybuffer'
      //   }).then(data => {
      //     this.regionGeojson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
      //     this.addBlockmapLayer(this.map, 'stroke', this.regionGeojson);
      //   });
    },
    strokeStreet() {
      this.curStroke = 'street';
      this.changeRegin('street')
      // if(this.curStroke == 'street'){
      //   this.removeMapLayer('stroke');
      //   this.curStroke = ''
      //   return;
      // }
      // this.curStroke = 'street';
      // if(!!this.streetGeojson) {
      //   this.addBlockmapLayer(this.map, 'stroke', this.streetGeojson);
      //   return;
      // }
      // request({
      //     url: 'api/streets/geo',
      //     method: 'get',
      //     params: {
      //       city_id: this.mapDefault.city_id
      //     },
      //     responseType: 'arraybuffer'
      //   }).then(data => {
      //     this.streetGeojson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
      //     this.addBlockmapLayer(this.map, 'stroke', this.streetGeojson);
      //   });
    },
    strokeCommunity() {
      this.curStroke = 'community';
      this.changeRegin('community')
      // if(this.curStroke == 'community'){
      //   this.removeMapLayer('stroke');
      //   this.curStroke = ''
      //   return;
      // }
      // this.curStroke = 'community';
      // if(!!this.communityGeojson) {
      //   this.addBlockmapLayer(this.map, 'stroke', this.communityGeojson);
      //   return;
      // }
      // request({
      //     url: 'api/communities/geo',
      //     method: 'get',
      //     params: {
      //       city_id: this.mapDefault.city_id
      //     },
      //     responseType: 'arraybuffer'
      //   }).then(data => {
      //     this.communityGeojson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
      //     this.addBlockmapLayer(this.map, 'stroke', this.communityGeojson);
      //   });
    },
    strokeServiceArea() {
      if (this.curStroke == 'service') {
        this.removeMapLayer('stroke');
        this.curStroke = ''
        return;
      }
      this.curStroke = 'service';
      if (!!this.serviceGeojson) {
        this.addBlockmapLayer(this.map, 'stroke', this.serviceGeojson);
        return;
      }
      request({
        url: 'api/waters/geo-zone',
        method: 'get',
        params: {
          city_id: this.mapDefault.city_id
        },
        responseType: 'arraybuffer'
      }).then(data => {
        this.serviceGeojson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        this.addBlockmapLayer(this.map, 'stroke', this.serviceGeojson);
      });
      /* if(!this.switches.showServiceArea){
        //关闭服务区
        this.removeMapLayer('strokeServiceArea');
        return;
      }
      if(!!this.communityGeojson) {
        this.addBlockmapLayer(this.map, 'strokeServiceArea', this.communityGeojson);
        return;
      }
      request({
          url: 'api/communities/geo',
          method: 'get',
          params: {
            city_id: this.mapDefault.city_id
          },
          responseType: 'arraybuffer'
        }).then(data => {
          this.communityGeojson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addBlockmapLayer(this.map, 'strokeServiceArea', this.communityGeojson);
        }); */
    },
    addBlockmapLayer(map, type, geoJson) {
      this.removeMapLayer(type);
      //let source_name_stroke = source_name+'_stroke';
      map.addSource(type, {
        type: "geojson",
        data: geoJson
      });
      //描边
      map.addLayer({
        id: type,
        type: "line",
        source: type,
        layout: {},
        paint: {
          "line-color": "#ffffff",
          "line-width": 1
        }
      });
    },
    removeMapLayer(type) {
      //描边
      var layer_stroke = this.map.getLayer(type);
      if (layer_stroke) {
        this.map.removeLayer(type);
      }
      if (this.map.getSource(type)) {
        this.map.removeSource(type);
      }
    },
    checkRegin() {
      //进入页面检测当前是街道还是社区
      let type = localStorage.getItem('curStroke')
      if (type == 'community') {
        if (this.$route.params.dmid) {
          //评估
          this.$router.replace({
            path: '/fire/building/dtp' + this.$route.params.dmid
          })
          return;
        }
        if (this.$route.params.emid) {
          //评估
          if (this.$route.params.emid == 1 && this.$route.path != '/fire/building/etp1') {
            this.$router.replace({
              path: '/fire/building/etp1'
            })
          }
          if (this.$route.params.emid == 5 && this.$route.path != '/fire/building/etp5') {
            this.$router.replace({
              path: '/fire/building/etp5'
            })
          }
          return;
        }
        //社区
        if (this.$route.params.mid == 1 && this.$route.path != '/fire/building/tp1') {
          this.$router.replace({
            path: '/fire/building/tp1'
          })
        } else if (this.$route.params.mid == 20 && this.$route.path != '/fire/building/firefighter/community/20') {
          this.$router.replace({
            path: '/fire/building/firefighter/community/20'
          })
        } else if (this.$route.params.mid == 25 && this.$route.path != '/fire/building/training/community/25') {
          this.$router.replace({
            path: '/fire/building/training/community/25'
          })
        }

      }
    },
    changeRegin(type) {
      localStorage.setItem('curStroke', type)
      //切换街道社区
      if (type == 'street') {
        //街道
        if (this.$route.params.dmid) {
          //评估
          this.$router.replace({
            path: '/fire/building/dtp' + this.$route.params.dmid
          })
          return;
        }
        if (this.$route.params.emid) {
          //评估
          if (this.$route.params.emid == 1) {
            this.$router.push({
              path: '/fire/building/prevent/1'
            })
          } else if (this.$route.params.emid == 5) {
            this.$router.push({
              path: '/fire/building/allprocess/5'
            })
          } else {
            this.$router.push({
              path: '/fire/building/etp' + this.$route.params.emid
            })
          }
          return;
        }
        if (this.$parent.$parent.mid == 1) {
          this.$router.push({
            path: '/fire/building/alarm/1'
          })
        } else if (this.$parent.$parent.mid == 10) {
          this.$router.push({
            path: '/fire/building/electricity/10'
          })
        } else if (this.$parent.$parent.mid == 13) {
          this.$router.push({
            path: '/fire/building/fireproofing/13'
          })
        } else if (this.$parent.$parent.mid == 20) {
          this.$router.push({
            path: '/fire/building/firefighter/20'
          })
        } else if (this.$parent.$parent.mid == 25) {
          this.$router.push({
            path: '/fire/building/training/25'
          })
        } else {
          this.$router.push({
            path: '/fire/building/tp' + this.$parent.$parent.mid
          })
        }

      } else if (type == 'community') {
        //社区
        if (this.$route.params.dmid) {
          //评估
          this.$router.replace({
            path: '/fire/building/dtp' + this.$route.params.dmid
          })
          return;
        }
        if (this.$route.params.emid) {
          //评估
          this.$router.push({
            path: '/fire/building/etp' + this.$route.params.emid
          })
          return;
        }
        if (this.$parent.$parent.mid == 20) {
          this.$router.push({
            path: '/fire/building/firefighter/community/20'
          })
        } else if (this.$parent.$parent.mid == 10) {
          this.$router.push({
            path: '/fire/building/electricity/10'
          })
        } else if (this.$parent.$parent.mid == 25) {
          this.$router.push({
            path: '/fire/building/training/community/25'
          })
        } else {
          this.$router.push({
            path: '/fire/building/tp' + this.$parent.$parent.mid
          })
        }

      }
    },

  }
};
</script>
<style scoped>
.map-menu {
  position: absolute;
  top: 230px;
  right: 10px;
  color: #ccc;
  cursor: pointer;
}

.mapboxgl-ctrl-icon {
  width: 30px;
  height: 30px;
  border: 0;
  border-radius: 5px;
  font-size: 18px;
  background: #fff;
  color: #000;
  text-align: center;
  margin-bottom: 10px;
}

.pitch {
  font-size: 14px;
  line-height: 30px;
}

.map-menu label {
  padding: 0 5px;
}

.stroke-switch {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%);
  color: #ccc;
  cursor: pointer;
  background: #fff;
  padding: 7px 10px;
  border-radius: 2px;
  color: #394761;
  font-size: 12px;
}

.stroke-switch span {
  font-size: 14px;
  margin-right: 10px;
}

.stroke-switch a {
  padding: 2px 4px;
}

.stroke-switch a.cur {
  background: #1A2948;
  color: #fff;
  border-radius: 5px;
}
</style>
