<template>
  <div class="row" style="margin:0 -30px;">
    <div class="col-md-9" style="padding:0">
      <card class="card-map">
        <!-- <div class="mask">
          <img src="img/loading.png" class="loading">
        </div>-->
        <div class="map-wrapper">
          <div id="map"></div>  
        </div>
        <menu-map :map="map" :mapDefault="mapdefaultSetting"></menu-map>
        <div class="map_data_msg" v-show="selects.data_category == 'before_reinforce'">
          <div class="map_data_legend">加固优先级</div>
          <div class="map_data_msg_wrapper">
            <div
              class="item"
              v-for="(item,index) in selects.priorityOptions"
              :key="index"
              v-if="index>0"
            >
              <span style="background: #b9d530;" :style="'width: '+4*(5-index)+'px;height:'+4*(5-index)+'px;border-radius: 50%'"></span>
              {{item.name}}
            </div>
          </div>
        </div>
        <div class="map_data_msg map_data_chart" v-show="selects.data_category == 'after_reinforce'">
          <!-- <div class="map_data_legend">图例</div>
          <div class="map_data_msg_wrapper">
            <div
              class="item"
              v-for="(item,index) in selects.buidingTypeOptions"
              :key="index"
              v-if="index>0"
            >
              <span :style="'background: '+item.color+''"></span>
              {{item.name}}
            </div>
          </div>-->
          <div class="map_data_chart_inner">
            <div class="map-chart-area" id="map-chart"></div>
            <div class="map-chart-area" id="map-chart2"></div>
            <div class="map-chart-area" id="map-chart3"></div>
            <div class="map-chart-area" id="map-chart4"></div>
            <div class="map-chart-area" id="map-chart5"></div>
          </div>   
        </div>
        <template  v-if="selects.data_category == 'before_reinforce'">
            <div class="show_list">
              <a class="up_show" v-on:click="showList">
                <i
                  class="now-ui-icons"
                  :class="[ show_list ? 'arrows-1_minimal-down':'arrows-1_minimal-up']"
                ></i>
              </a>
              <a class="download ml-auto" :href="downloadUrl">
                <i class="now-ui-icons arrows-1_cloud-download-93"></i>
              </a>
              <span>
                数据列表
                <i>{{total}}</i>
              </span>
            </div>
            <div
              class="show_list_record"
              v-show="show_list"
              :style="{'margin-top':-(140+data.length*38)+'px'}"
              ref="show_list_record"
            >
              <el-table :data="data">
                <el-table-column label="建筑ID" property="building_id"></el-table-column>
                <!-- <el-table-column label="经度" property="lng"></el-table-column>
                <el-table-column label="纬度" property="lat"></el-table-column> -->
                <el-table-column label="街道" property="street_name"></el-table-column>
                <el-table-column label="社区" property="community_name"></el-table-column>
                <el-table-column label="功能类型" property="category_name"></el-table-column>
                <el-table-column label="加固优先级指数" property="reinforcement_index"></el-table-column>
                <el-table-column label="加固优先级" property="reinforcement_priority"></el-table-column>
              </el-table>
              <div
                slot="footer"
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                
              >
                <div class>
                  <p class="card-category">显示： {{from + 1}} - {{to}} 总数： {{total}}</p>
                </div>
                <n-pagination
                  class="pagination-no-border"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                  v-on:input="changePage"
                ></n-pagination>
              </div>
            </div>
        </template>
        <template  v-if="selects.data_category == 'after_reinforce'">
            <div class="show_list">
              <a class="up_show" v-on:click="showList">
                <i
                  class="now-ui-icons"
                  :class="[ show_list ? 'arrows-1_minimal-down':'arrows-1_minimal-up']"
                ></i>
              </a>
              <a class="download ml-auto" :href="downloadUrl2">
                <i class="now-ui-icons arrows-1_cloud-download-93"></i>
              </a>
              <span>
                数据列表
              </span>
            </div>

            <div
              class="show_list_record"
              v-show="show_list"
              :style="{'margin-top':-(90+dataAfter.length*38)+'px'}"
              ref="show_list_record"
            >
              <el-table :data="dataAfter">
                <el-table-column label="指标类别" property="name"></el-table-column>
                <el-table-column label="加固前" property="before"></el-table-column>
                <el-table-column label="加固后" property="after"></el-table-column>
                <el-table-column label="加固后/加固前" property="rate"></el-table-column>
              </el-table>
             
            </div>
        </template>
      </card>
    </div>
    <div class="col-md-3 hasDecision" id="customStyle" :style="{'padding-top':selects.data_category == 'after_reinforce'?'230px!important':'70px'}">
      <div class="col-md-12 clearfix key-decision" :style="{'height':selects.data_category == 'after_reinforce'?'230px':'70px'}">
        <el-select
          class="select-default"
          size="large"
          placeholder="数据集类型选择"
          v-model="selects.data_category"
          v-on:change="reloadData"
        >
          <el-option
            v-for="option in selects.data_categories"
            class="select-primary"
            :value="option.id"
            :label="option.name"
            :key="option.id"
          ></el-option>
        </el-select>
        <!-- <el-collapse v-model="activeNameskey" v-show="selects.data_category == 'after_reinforce'">
          <el-collapse-item title="决策信息" name="1">
            <div class="col-md-12 clearfix decision-wrapper">
              <div class="decision-block">
                <span class="demonstration">加固比例</span>
                <p class="msg-percent">{{parseInt(reinforce_percent)}}%</p>
              </div>
              <div class="silide-wrapper">
                <slider v-model="reinforce_percent" :options="{step: 1}" :range="{'min': [1], 'max': [100]}"></slider>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse> -->
        <el-collapse v-model="activeNameskey">
          <el-collapse-item title="决策信息" name="1" v-show="selects.data_category == 'after_reinforce'">
            <div class="col-md-12 clearfix decision-wrapper">
              <div class="decision-block">
                <span class="demonstration">加固比例</span>
                <p class="msg-percent">{{parseInt(reinforce_percent)}}%</p>
              </div>
              <div class="silide-wrapper">
                <slider v-model="reinforce_percent" :options="{step: 1}" :range="{'min': [1], 'max': [100]}"></slider>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <el-collapse v-model="activeNames"  class="el-collapse-main">
        <!-- <el-collapse-item title="一致性 Consistency" name="1">
          <form>
            <div class="input-group no-border">
              <fg-input
                placeholder="输入关键字..."
                v-on:keyup.enter="reloadData"
                v-model="keyword"
                addon-right-icon="now-ui-icons ui-1_zoom-bold"
              ></fg-input>
              <div class="form-text" style="color:#999;margin-left:20px;">输入 建筑、街道、社区关键字</div>
            </div>
          </form>
        </el-collapse-item> -->
        
        <el-collapse-item title="信息图表" name="4">
          <div class="col-md-12" v-show="selects.data_category == 'before_reinforce'">
              <div class="chart-area" id="box-chart" style="height:400px"></div>
            </div>
            <div class="col-md-12" v-show="selects.data_category == 'after_reinforce'">
              <div class="chart-area" id="box-chart2" style="height:300px"></div>
            </div>
            <div class="col-md-12" v-show="selects.data_category == 'after_reinforce'">
              <div class="chart-area" id="box-chart3" style="height:300px"></div>
            </div>
        </el-collapse-item>
        <el-collapse-item title="叠加图层" name="6"  v-show="['before_reinforce'].includes(selects.data_category)">
          <div class="col-md-12 clearfix">
              <checkbox
                v-model="risk"
                label="综合风险指数"
                v-on:input="!risk"
              >综合风险指数</checkbox>
              <div class="overlap_layer_desc">
                <div class="item" v-for="(item,index) in riskData" :key="index">
                  <span :style="'background: '+item.color+';'"></span>
                  {{item.name}}
                </div>
              </div>
            </div>
        </el-collapse-item>
        <el-collapse-item title="数据筛选" name="5">
          <div class="col-md-12">
              <el-select
                class="select-default"
                size="large"
                placeholder="街道"
                v-model="selects.street"
                v-on:change="reloadData('street')"
              >
                <el-option
                  v-for="option in selects.streets"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id"
                ></el-option>
              </el-select>
            </div>

            <div class="col-md-12">
              <el-select
                class="select-default"
                size="large"
                placeholder="社区"
                v-model="selects.community"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="option in selects.communities"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id"
                ></el-option>
              </el-select>
            </div>

            <div class="col-md-12">
              <el-select
                class="select-default"
                size="large"
                placeholder="结构类型"
                v-model="selects.struct"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="(option, index) in selects.structs"
                  class="select-primary"
                  :value="index"
                  :label="option"
                  :key="index"
                ></el-option>
              </el-select>
            </div>

            <div class="col-md-12">
              <el-select
                class="select-default"
                size="large"
                placeholder="功能类型"
                v-model="selects.category"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="(option, index) in selects.categories"
                  class="select-primary"
                  :value="index"
                  :label="option"
                  :key="index"
                ></el-option>
              </el-select>
            </div>

            <div class="col-md-12">
              <el-select
                class="select-default"
                size="large"
                placeholder="抗震设防烈度"
                v-model="selects.quakeLevel"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="option in selects.quakeLevels"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id"
                ></el-option>
              </el-select>
            </div>

            <div class="col-md-12">
              <el-select
                class="select-default"
                size="large"
                placeholder="修建年代"
                v-model="selects.buildYear"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="(option,index) in selects.buildYears"
                  class="select-primary"
                  :value="option"
                  :label="option"
                  :key="index"
                ></el-option>
              </el-select>
            </div>

            <div class="col-md-12" v-show="selects.data_category == 'before_reinforce'">
              <el-select
                class="select-default"
                size="large"
                placeholder="加固优先级"
                v-model="selects.priority"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="(option,index) in selects.priorityOptions"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="index"
                ></el-option>
              </el-select>
            </div>
        </el-collapse-item>
        
      </el-collapse>
      
      <!-- <card v-show="false">
        <form>
          <div class="input-group no-border">
            <fg-input
              placeholder="输入关键字..."
              v-on:keyup.enter="reloadData"
              v-model="keyword"
              addon-right-icon="now-ui-icons ui-1_zoom-bold"
            ></fg-input>
            <div class="form-text" style="color:#999;margin-left:20px;">输入 建筑、街道、社区关键字</div>
          </div>
        </form>
      </card> -->
      <!-- <card>
        <form>
          <div class="col-md-12 clearfix">
            <label class="switch-label">数据集类型选择</label>
            <br />
            <el-select
              class="select-default"
              size="large"
              placeholder="数据集类型选择"
              v-model="selects.data_category"
              v-on:change="reloadData"
            >
              <el-option
                v-for="option in selects.data_categories"
                class="select-primary"
                :value="option.id"
                :label="option.name"
                :key="option.id"
              ></el-option>
            </el-select>
          </div>
        </form>
      </card> -->
      <!-- <card  v-show="selects.data_category == 'after_reinforce'">
        <form>
          <div class="col-md-12 clearfix decision-wrapper">
            <div class="decision-block">
              <span class="demonstration">加固比例</span>
              <p class="msg-percent">{{parseInt(reinforce_percent)}}%</p>
            </div>
            <div class="silide-wrapper">
              <slider v-model="reinforce_percent" :options="{step: 1}" :range="{'min': [1], 'max': [100]}"></slider>
            </div>
          </div>
        </form>
      </card> -->

      <!-- <card class="right-block" id="container">
        <tabs type="primary" v-model="tabs.activeName">
          <tab-pane label="信息图表">
            <div class="col-md-12" v-show="selects.data_category == 'before_reinforce'">
              <div class="chart-area" id="box-chart" style="height:400px"></div>
            </div>
            <div class="col-md-12" v-show="selects.data_category == 'after_reinforce'">
              <div class="chart-area" id="box-chart2" style="height:300px"></div>
            </div>
            <div class="col-md-12" v-show="selects.data_category == 'after_reinforce'">
              <div class="chart-area" id="box-chart3" style="height:300px"></div>
            </div>
          </tab-pane>

          <tab-pane label="数据筛选" class="tab-scroller data-filter" ref="sidebarScrollArea">
            <div class="col-md-12">
              <el-select
                class="select-default"
                size="large"
                placeholder="街道"
                v-model="selects.street"
                v-on:change="reloadData('street')"
              >
                <el-option
                  v-for="option in selects.streets"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id"
                ></el-option>
              </el-select>
            </div>

            <div class="col-md-12">
              <el-select
                class="select-default"
                size="large"
                placeholder="社区"
                v-model="selects.community"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="option in selects.communities"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id"
                ></el-option>
              </el-select>
            </div>

            <div class="col-md-12">
              <el-select
                class="select-default"
                size="large"
                placeholder="结构类型"
                v-model="selects.struct"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="(option, index) in selects.structs"
                  class="select-primary"
                  :value="index"
                  :label="option"
                  :key="index"
                ></el-option>
              </el-select>
            </div>

            <div class="col-md-12">
              <el-select
                class="select-default"
                size="large"
                placeholder="功能类型"
                v-model="selects.category"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="(option, index) in selects.categories"
                  class="select-primary"
                  :value="index"
                  :label="option"
                  :key="index"
                ></el-option>
              </el-select>
            </div>

            <div class="col-md-12">
              <el-select
                class="select-default"
                size="large"
                placeholder="抗震设防烈度"
                v-model="selects.quakeLevel"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="option in selects.quakeLevels"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id"
                ></el-option>
              </el-select>
            </div>

            <div class="col-md-12">
              <el-select
                class="select-default"
                size="large"
                placeholder="修建年代"
                v-model="selects.buildYear"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="(option,index) in selects.buildYears"
                  class="select-primary"
                  :value="option"
                  :label="option"
                  :key="index"
                ></el-option>
              </el-select>
            </div>

            <div class="col-md-12" v-show="selects.data_category == 'before_reinforce'">
              <el-select
                class="select-default"
                size="large"
                placeholder="加固优先级"
                v-model="selects.priority"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="(option,index) in selects.priorityOptions"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="index"
                ></el-option>
              </el-select>
            </div>

          </tab-pane>
          <tab-pane label="叠加图层"  v-if="['before_reinforce'].includes(selects.data_category)">
            <div class="col-md-12 clearfix">
              <checkbox
                v-model="risk"
                label="综合风险指数"
                v-on:input="!risk"
              >综合风险指数（立柱高度）</checkbox>
              <div class="overlap_layer_desc">
                <div class="item" v-for="(item,index) in riskData" :key="index">
                  <span :style="'background: '+(risk?item.color:'#ccc')+';height:'+item.height+'px'"></span>
                  {{item.name}}
                </div>
              </div>
            </div>
          </tab-pane>
        </tabs>
      </card> -->
    </div>
    <div class="key-infos">
      <div class="key-info" v-show="selects.data_category == 'before_reinforce'">
        高加固优先级{{statistics.before_reinforce.count}}幢
      </div>
     
    </div>
  </div>
</template>
<script>
import mapboxgl from "mapbox-gl";
import MenuMap from "src/components/Menumap";
import MiniMap from "src/mapbox/mapboxgl-minimap.js";
import { Slider, TabPane, Tabs,  Switch } from "src/components";
import geobuf from "geobuf";
import Pbf from "pbf";
import { Radio } from "src/components/index";
import { Checkbox } from "src/components/index";
import { Input, Button, Select, Option, Collapse, CollapseItem, Table, TableColumn } from "element-ui";
import { consts } from "src/util/consts.js";
import {
  getCategories,
  getStreets,
  getCommunities,
  getReinforcePrioritiesList,
  getReinforcePrioritiesGeojson,
  getReinforceaAfterGeojson,
  getReinforceList,
  getBeforeReinforceStatistics
} from "src/api/building.js";
import { byBeforeReinForce, byAfterReinForce,byAfterReinForceBuilding,byAfterReinForcePercent } from "src/api/chart.js";
import {
  buildBarWhiteOption,
  buildBarOption,
  buildBarStackOption,
  buildlineOption,
  buildPieWhiteOption
} from "src/util/chart.js";
import {
  getBuildYearRange,
  storeMapOptions,
  getMapOptions,
  parseJsonToString
} from "src/util/common.js";
import { Pagination as NPagination } from "src/components";
import {
  getPeopleStat,
  getGenderStat,
  getHideaways,
  getHospitals,
  getWarehouses
} from "src/api/cityinfo.js";

import * as THREE from "three";
//import geoJsonBasic from "./GeoJson-basic"; // 基本建筑数据from2.2-2.3
//import geoJson231 from "./GeoJson-2.3.1"; // 基本建筑数据from2.3.1

export default {
  components: {
    MenuMap,
    Slider,
    TabPane,
    Tabs,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    NPagination,
    [Switch.name]: Switch,
    Checkbox,
    Radio
  },
  data() {
    return {
      activeNameskey: ['1'],
      activeNames: ['2','3','4','5','6'],
      activeName: "first",
      tabs: {
        activeName: "信息图表",
        vertical: "Options",
        verticalPills: "Home",
        pageCategories: "Messages"
      },
      selects: {
        street: 0,
        streets: [{ id: 0, name: "街道" }],
        community: 0,
        communities: [{ id: 0, name: "社区" }],
        struct: 0,
        structs: consts.buildingStructs,
        priority: 0,
        priorityOptions: [
          { id: 0, name: "加固优先级" },
          { id: 1, name: "高", color: "#e9c645" },
          { id: 2, name: "中", color: "#d7843c" },
          { id: 3, name: "低", color: "#d55846" }
        ],
        buidingType: 0,
        buidingTypeOptions: [
          { id: 0, name: "加固优先级" },
          { id: 1, name: "医院", color: "#e9c645" },
          { id: 2, name: "学校", color: "#d7843c" },
          { id: 3, name: "住宅", color: "#d55846" }
        ],
        category: 0,
        categories: consts.buildingCategories,
        quakeLevel: 0,
        quakeLevels: [
          { id: 0, name: "抗震设防烈度" },
          { id: 6, name: 6 },
          { id: 7, name: 7 },
          { id: 8, name: 8 }
        ],
        buildYear: '修建年代',
        buildYears: consts.buildingBuildYears,
        data_category: 'before_reinforce',
        data_categories: [
          { id: 'before_reinforce', name: "加固优先级" },
          { id: 'after_reinforce', name: "加固后效果" }
        ]
      },
      keyword: "",
      total: 0,
      data: [],
      dataAfter: [],  
      page: 1,
      map: {},
      color_category: "reinforce",
      city_id: localStorage.getItem("city_id") || 1,
      show_list: false,
      show_list_height: 0,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 20
      },
      switchs: {
        people: true,
        gender: true,
        hideaway: true,
        hospital: true,
        warehouse: true
      },
      last_updated: consts.last_updated,
      riskData: [//立柱叠加
        { id: 1, name: "0-0.2", color: "#5E209D", height: '3' },
        { id: 2, name: "0.2-0.4", color: "#273B8E", height: '6'},
        { id: 3, name: "0.4-0.6", color: "#55AC68",height: '9' },
        { id: 4, name: "0.6-0.8", color: "#E9C645",height: '12' },
        { id: 5, name: "0.8-0.9", color: "#D8833B",height: '15' },
        { id: 6, name: "0.9-1", color: "#D55745",height: '18' },
      ],
      colors: {
        'before_reinforce': ["#e9c645", "#d7843c", "#d55846"],
        'after_reinforce': ["#e9c645", "#d7843c", "#d55846"],
        'building_type': ['#D55328','#F5C243','#447DB3','#62BFF5','#70AD47','#94CFBF','#B22FB9','#891c1f'],
        'reinforce_type': ['#D55328', '#F5C243','#447DB3', '#70AD47'] ,
        'risk': ["#5E209D", "#273B8E", "#55AC68", "#E9C645", "#D8833B", "#D55745"],
      },
      risk: false,
      reinforce_percent: 1,
      statistics: {
        before_reinforce: {
          count: ''
        }
      },
      mapdefaultSetting: {},
      downloadUrl: '',
      downloadUrl2: ''
    };
  },
  watch: {
    risk(val) {
      //开关叠加涂层
      this.toggleMapLayer('risk')
    },
    reinforce_percent(val) {
      //加固比例
      this.updateChart()
      this.fillBuildingsMap(this.city_id, this.color_category);
    },
    "selects.street"(val) {
      //街道切换选社区
      this.fillCommunities(this.city_id, val);
    }
  },
  mounted() {
    this.fetchReinforceData(this.city_id);
    this.fillStreets(this.city_id);
    
    this.showChart(this.city_id);
    //this.fillBuildings(this.city_id, 1);
    this.initMap(this.city_id);
    
    //this.fillBuildingsMap(this.city_id, this.color_category);
    //this.addPointLayerToMap(this.map, "hospital");
    //this.addPointLayerToMap(this.map, "hideaway");
    //this.addPointLayerToMap(this.map, "warehouse");
    //this.loadData(this.data_type);
  },

  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    }
  },
  methods: {
    // 初始化
    initMap(city_id) {
      let self = this;
      this.mapdefaultSetting = {
        center: consts.mapCenter[this.city_id-1],
        zoom: consts.mapDefaultZoom,
        pitch: 0,
        city_id: this.city_id
      }
      mapboxgl.accessToken = consts.mapboxAccessToken;
      let map_options = getMapOptions();
      //console.log(map_options);
      let zoom = map_options ? map_options.zoom : 12;
      let center = map_options
        ? [map_options.lng, map_options.lat]
        : consts.mapCenter[city_id - 1];
      var map = new mapboxgl.Map({
        container: "map",
        style: consts.mapBoxStyle,
        //center: [-87.61694, 41.86625],
        center: center,
        zoom: zoom,
        pitch: 0,
        bearing: 0,
        attributionControl: false
      });
      this.map = map;
      //定位图标
      map.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl
        })
      );
      this.map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: true
        })
      );
      map.on("load", function(){
        
      })
      //控制图层
      var nav = new mapboxgl.NavigationControl();
      this.map.addControl(nav, "top-right");
      //全屏按钮
      this.map.addControl(
        new mapboxgl.FullscreenControl({
          container: document.querySelector("body")
        })
      );
      //小地图
      //https://github.com/brendanmatkin/mapboxgl-minimap
      map.on("style.load", function() {
        self.reloadData()
        // Possible position values are 'bottom-left', 'bottom-right', 'top-left', 'top-right'
        map.addControl(
          new MiniMap({
            id: "mapboxgl-minimap",
            width: "200px",
            height: "150px",
            //style: "mapbox://styles/mapbox/streets-v10",
            style: consts.mapBoxStyle,
            fillColor: "#f58220",
            zoomAdjust: null
          }),
          "bottom-right"
        );
      });
      //记录地图设置
      map.on("move", function() {
        storeMapOptions({
          lng: map.getCenter().lng,
          lat: map.getCenter().lat,
          zoom: map.getZoom()
        });
      });
    },
    addCircle2Map(map, geoJsonData) {
      let type = 'circle';
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);

      let colorExpress = '#b9d530';
      if(this.risk) {
        
        colorExpress = [
          "step",
            ["get", "risk_index"],
            "#5E209D", 0.2,
            "#273B8E", 0.4,
            "#55AC68", 0.6,
            "#E9C645", 0.8,
            "#D8833B", 0.9,
            "#D55745", 1,
            "#D55745" // other
        ];
      }
      map.addSource(source_name, {
        type: "geojson",
        data: geoJsonData
      });
      map.addLayer({
        id: layer_id,
        type: "circle",
        source: source_name,
        paint: {
          // make circles larger as the user zooms from z12 to z22
          'circle-radius': [
              "interpolate",
              ["linear"],
              ["zoom"],
              7,
              [
                "match",
                ["get", "reinforcement_priority"],
                '高',
                3 ,
                '中',
                2,
                '低',
                1,
                1
              ],
              12,
              [
                "match",
                ["get", "reinforcement_priority"],
                '高',
                6,
                '中',
                4,
                '低',
                2,
                2
              ]
            ]
            
            ,
          // color circles by ethnicity, using a match expression
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-match
          'circle-color': colorExpress,
          'circle-stroke-color': '#000000',
          'circle-stroke-width': 1
        }
      });

    },
    addBuildings2Map(map, geoJsonData) {
      let type = 'building'
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      
      map.addSource(source_name, {
        type: "geojson",
        data: geoJsonData
      });
      let colorExpress = ["get", "color"];
      let heightExpress = ["get", "height"];
      if(this.selects.data_category == "before_reinforce") {
        colorExpress = [
          "match",
          ["get", "reinforcement_priority"],
          '高',
          "#e9c645",
          '中',
          "#d7843c",
          '低',
          "#d55846",
          "#e9c645"
        ];
      } else if(this.selects.data_category == "after_reinforce") {
      //   switch(type) {
      //   case '功能类型1': 
      //     name = "居住建筑";
      //   break;
      //   case '功能类型2': 
      //     name = "商用建筑";
      //   break;
      //   case '功能类型3': 
      //     name = "行政单位";
      //   break;
      //   case '功能类型4': 
      //     name = "交通出行";
      //   break;
      //   case '功能类型5': 
      //     name = "教育机构";
      //   break;
      //   case '功能类型6':
      //     name = "医疗机构";
      //   break;
      //   case '功能类型7':
      //     name = "旅游景点";
      //   break;
      //   case '功能类型8':
      //     name = "工业建筑";
      //   break;  
      // }
        colorExpress = [
          "match",
          ["get", "category_id"],
          1,
          "#D55328",
          2,
          "#F5C243",
          3,
          "#447DB3",
          4,
          "#62BFF5",
          5,
          "#70AD47",
          6,
          "#94CFBF",
          7,
          "#B22FB9",
          8,
          "#891c1f",
          "rgba(0,0,0,0)"
        ];
      }
      // if(this.risk) {
      //   //叠加图层开，根据风险指数改变建筑高度
      //   this.map.easeTo({
      //      pitch: 60
      //   });
      //   heightExpress = [
      //     "interpolate",
      //     ["linear"],
      //     ["get", "risk_index"],
      //     0.2, 100,
      //     0.4, 200,
      //     0.6, 400,
      //     0.8, 600,
      //     0.9, 800,
      //     1, 1000
      //   ];
      // } else {
      //   this.map.easeTo({
      //      pitch: 0
      //   });
      // }
      map.addLayer({
        id: layer_id,
        type: "fill-extrusion",
        source: source_name,
        //'source': {
        // GeoJSON Data source used in vector tiles, documented at
        // https://gist.github.com/ryanbaumann/a7d970386ce59d11c16278b90dde094d
        //  'type': 'geojson',
        //  'data':geoJsonData,
        //'data': "http://api.urban.test/api/buildings-geo-json?struct_id=2&category_id=1&street_id=1"
        //'data': "https://docs.mapbox.com/mapbox-gl-js/assets/indoor-3d-map.geojson"
        //},
        paint: {
          // See the Mapbox Style Specification for details on data expressions.
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions

          // Get the fill-extrusion-color from the source 'color' property.
          "fill-extrusion-color": colorExpress,

          // Get fill-extrusion-height from the source 'height' property.
          "fill-extrusion-height": heightExpress,

          // Get fill-extrusion-base from the source 'base_height' property.
          "fill-extrusion-base": ["get", "base_height"],

          // Make extrusions slightly opaque for see through indoor walls.
          "fill-extrusion-opacity": 0.8
        }
      });
 
    },
    showList() {
      this.show_list = !this.show_list;
    },
    donwloadList() {},
    showChart(city_id) {
      var _this = this;
      var container = document.querySelector('.el-collapse-main');
      var box = document.getElementById("box-chart");
      box.style.width = container.offsetWidth + "px";
      var myChart = this.$echarts.init(box);
      byBeforeReinForce(city_id).then(res=>{
        var chart_data = res.data;
        var chart_name = "加固优先级";
        chart_data.seriesData.map((item,idx) => {
          return item.color = _this.colors['before_reinforce'][idx]
        })
        var option = buildBarStackOption(chart_name, chart_data,{xAxis: {name: '建筑数量',nameLocation: 'center','nameGap':30}});
        myChart.setOption(option);
      });


      var box2 = document.getElementById("box-chart2");
      box2.style.width = container.offsetWidth + "px";
      this.myChart2 = this.$echarts.init(box2);
      byAfterReinForce(this.city_id).then(res=>{
        var chart_data = res.data;
        var chart_name = "不同加固比例下各指标相对原有值百分比";
        

        chart_data.categoryData.forEach((item,idx) => {
            chart_data.categoryData[idx] = item.replace('加固','').replace('%建筑','')
        })
        chart_data.seriesData.map((item,idx) => {
          item.color = _this.colors['reinforce_type'][idx]
        })
        var option = buildlineOption(chart_name, chart_data, {
           xAxis: {
            show: true,
            name: '加固比例（%）',
            nameLocation: 'center',//坐标轴名称显示位置。
            nameGap: 30,
            
          },
          yAxis: {
            name: '各指标相对原有值百分比（%）',
            nameLocation: 'end',//坐标轴名称显示位置。
             nameTextStyle: {
              padding: [0,0,0,120]
            }
          },
          'type':'reinforce'
          },true);
        this.myChart2.setOption(option);
      });

      var boxchart2 = document.getElementById("box-chart3");
      boxchart2.style.width = container.offsetWidth + "px";
      this.myChart3 = this.$echarts.init(boxchart2);
      byAfterReinForceBuilding(this.city_id).then(res=>{
        var chart_data = res.data;
        var chart_name = "不同加固比例下各功能类型建筑数量";
        chart_data.categoryData.forEach((item,idx) => {
            chart_data.categoryData[idx] = item.replace('加固','').replace('%建筑','')
        })
        chart_data.seriesData.map((item,idx) => {
          item.itemStyle= {normal: {areaStyle: {type: 'default'}}}
          item.color = _this.colors['building_type'][idx]
        })
        
        var option = buildlineOption(chart_name, chart_data, {
           xAxis: {
            show: true,
            name: '加固比例（%）',
            nameLocation: 'center',//坐标轴名称显示位置。
            nameGap: 30
          },
          yAxis: {
            name: '建筑数量 ',
            nameLocation: 'end',//坐标轴名称显示位置。
           
          },
          'type':'reinforce'
          },true);
        this.myChart3.setOption(option);
      });


      //根据百分比动态改变
      //建筑类型柱状图
      var box3 = document.getElementById("map-chart");
      this.mapchart1 = this.$echarts.init(box3);  
      //灾后恢复时间饼图     
      var box4 = document.getElementById("map-chart2");
      this.mapchart2 = this.$echarts.init(box4);    
      //经济损失饼图
      var box5 = document.getElementById("map-chart3");
      this.mapchart3 = this.$echarts.init(box5);
      //人口伤亡
      var box6 = document.getElementById("map-chart4");
      this.mapchart4 = this.$echarts.init(box6);
      //离家住户
      var box7 = document.getElementById("map-chart5");
      this.mapchart5 = this.$echarts.init(box7);

       document.querySelector('.key-decision').style.width = document.querySelector('#customStyle').offsetWidth +'px'
      window.onresize = function() {
        document.querySelector('.key-decision').style.width = document.querySelector('#customStyle').offsetWidth +'px';

        document.querySelectorAll('.chart-area').forEach((item,idx)=>{
          item.style.width = document.querySelector('.el-collapse-main').offsetWidth+'px';
        })
        myChart.resize(); 
        _this.myChart2.resize(); 
        _this.myChart3.resize(); 
      }
    },

    fillStreets(city_id) {
      getStreets(city_id).then(res => {
        let data = res.data;
        data.unshift({ id: 0, name: "街道" });
        this.selects.streets = data;
      });
    },
    fillCommunities(city_id, street_id) {
      getCommunities(city_id, street_id).then(res => {
        let data = res.data;
        data.unshift({ id: 0, name: "社区" });
        this.selects.community = 0;
        this.selects.communities = data;
      });
    },
    fillPeopleData(page) {
      let params = this.buildParams();
      params.page = page;
      getPeopleStat(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillGenderData(page) {
      let params = this.buildParams();
      params.page = page;
      getGenderStat(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillHospitalsData(city_id, page) {
      let params = this.buildParams();
      params.page = page;
      getHospitals(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillHideawaysData(page) {
      let params = this.buildParams();
      params.page = page;
      getHideaways(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillWarehousesData(page) {
      let params = this.buildParams();
      params.page = page;
      getWarehouses(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillBuildings(city_id, page) {
      let params = this.buildParams();
      params.page = page;
      params.page_size = 10;
      getReinforcePrioritiesList(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
      getBeforeReinforceStatistics(params).then(res => {
        this.statistics.before_reinforce.count = res.data.count;
      });
      this.downloadUrl = consts.requestUrl + consts.exportBeforeReinforce + parseJsonToString(params)
    },
    fillBuildingsMap(city_id, color_category) {
      let params = this.buildParams();
      //params.color_category = color_category;
    
      if(this.selects.data_category == "before_reinforce") {
        getReinforcePrioritiesGeojson(params).then(data => {
          //let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          //mock综合风险指数，这个数值GeoJson-2.3.1里没有
          // geoJson.features.map((item, idx) => {
          //   return (item.properties["risk_index"] = Math.random());
          // });
          this.addCircle2Map(this.map, geoJson);
        });
        // fetch('/geojson/GeoJson-2.3.1.json').then(res => res.json())
        // .then(json => {
        //   let geoJson = json;
        //   //mock综合风险指数，这个数值GeoJson-2.3.1里没有
        //   geoJson.features.map((item, idx) => {
        //     return (item.properties["综合风险指数"] = Math.random());
        //   });
        //   this.addBuildings2Map(this.map, geoJson);
        // })
        // fetch('/geojson/pbf-2.3.1.pbf').then(res => res.arrayBuffer())
        // .then(buffer => {
        //   let geoJson = geobuf.decode(new Pbf(buffer)); // 对GeoBuf解码
        //   //mock综合风险指数，这个数值GeoJson-2.3.1里没有
        //   geoJson.features.map((item, idx) => {
        //     return (item.properties["综合风险指数"] = Math.random());
        //   });
        //   this.addBuildings2Map(this.map, geoJson);
        // })

        
      }else {
        this.removeMapLayer(this.map, "circle");
      } 
      if(this.selects.data_category == "after_reinforce") {
        getReinforceaAfterGeojson(params).then(data => {
          //let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addBuildings2Map(this.map, geoJson);
        });
        // fetch('/geojson/GeoJson-2.3.1.json').then(res => res.json())
        // .then(json => {
        //   let geoJson = json;
        //   this.addBuildings2Map(this.map, geoJson);
        // })
      } else {
        this.removeMapLayer(this.map, "building");
      }
      
      
      
      
    },
    fetchReinforceData(){
      let _this = this;
      byAfterReinForcePercent(this.city_id).then(res=>{
        _this.reinforce_building_percent_list = res.data.category;
        _this.reinforce_percent_list = res.data.reinforced;
        _this.reinforce_total_list = res.data.un_reinforced; 
        _this.updateChart();
      });
      
      // let p1 = new Promise((resolve, reject) => {
      //   fetch('/geojson/GeoJson-2.3.1 percent.json').then(res => res.json())
      //   .then(json => {
      //     _this.reinforce_percent_list = json.data; 
      //     resolve('success')
      //   })
      // })
      
      
      
      // let p2 = new Promise((resolve, reject) => {
      //   fetch('/geojson/GeoJson-2.3.1 building.json').then(res => res.json())
      //   .then(json => {
      //     _this.reinforce_building_percent_list = json.data; 
      //     resolve('success')
      //   })
      // })
      
      
      
      // Promise.all([p1, p2]).then((result) => {
      //   _this.updateChart();
        
      // }).catch((error) => {
      //   console.log(error)
      // })
    },
    updateChart(){
      // this.reinforce_percent_list.forEach(item => {
      //   if(item['百分数'] == this.reinforce_percent) {
      //     this.mapchart2.setOption(buildPieWhiteOption('灾后恢复时间', [{name: "剩余恢复时间", value: parseInt(item["恢复时间"]*100)},{name: "已用恢复时间", value: (100-parseInt(item["恢复时间"]*100))}]))
      //     this.mapchart3.setOption(buildPieWhiteOption('经济损失', [{name: "经济损失", value: parseInt(item["经济损失"]*100)},{name: "未损失", value: (100-parseInt(item["经济损失"]*100))}]))
      //     this.mapchart4.setOption(buildPieWhiteOption('人员伤亡', [{name: "人员伤亡", value: parseInt(item["人员伤亡"]*100)},{name: "人员未伤亡", value: (100-parseInt(item["人员伤亡"]*100))}]))
      //     this.mapchart5.setOption(buildPieWhiteOption('离家住户', [{name: "离家住户", value: parseInt(item["离家住户"]*100)},{name: "未离家住户", value: (100-parseInt(item["离家住户"]*100))}]))
      //   }
      // });
      let cur_recorytime = 0;
      let total_recoverytime = 0;
      let cur_ecnoloss = 0;
      let total_ecnoloss = 0;
      let cur_death = 0;
      let total_death = 0;
      let cur_leave = 0;
      let total_leave = 0;
     this.reinforce_percent_list[parseInt(this.reinforce_percent)-1].forEach((item,idx)=>{
       switch(idx){
         case 0:
            cur_recorytime = parseInt(item);
            total_recoverytime = parseInt(this.reinforce_total_list[idx]);
            this.mapchart2.setOption(buildPieWhiteOption('灾后恢复时间(天)', [{name: "剩余恢复时间",itemStyle:{color:this.colors['reinforce_type'][0]}, value: parseInt(item)},{name: "已用恢复时间",itemStyle:{color:'#bbbbbb'}, value: parseInt(this.reinforce_total_list[idx]-item)}]))
         break;
         case 1:
            cur_ecnoloss = parseInt(item);
            total_ecnoloss = parseInt(this.reinforce_total_list[idx]);
            this.mapchart3.setOption(buildPieWhiteOption('经济损失(元)', [{name: "经济损失",itemStyle:{color:this.colors['reinforce_type'][1]}, value: parseInt(item)},{name: "未损失",itemStyle:{color:'#bbbbbb'}, value: parseInt(this.reinforce_total_list[idx]-item)}]))
         break;
         case 2:
           cur_death = parseInt(item);
          total_death = parseInt(this.reinforce_total_list[idx]);
           this.mapchart4.setOption(buildPieWhiteOption('人员伤亡(人)', [{name: "人员伤亡",itemStyle:{color:this.colors['reinforce_type'][2]}, value: parseInt(item)},{name: "人员未伤亡",itemStyle:{color:'#bbbbbb'}, value: parseInt(this.reinforce_total_list[idx]-item)}]))
         break;
         case 3:
           cur_leave = parseInt(item);
           total_leave = parseInt(this.reinforce_total_list[idx]);
           this.mapchart5.setOption(buildPieWhiteOption('离家住户(户)', [{name: "离家住户",itemStyle:{color:this.colors['reinforce_type'][3]}, value: parseInt(item)},{name: "未离家住户",itemStyle:{color:'#bbbbbb'}, value: parseInt(this.reinforce_total_list[idx]-item)}]))
         break;

       }
     })



     //更新列表
     this.dataAfter = [{
        'name': '经济损失','before': total_ecnoloss, 'after': cur_ecnoloss, 'rate': (cur_ecnoloss/total_ecnoloss*100).toFixed(2) + '%'
      },{
        'name': '离家住户','before': total_leave, 'after': cur_leave, 'rate': (cur_leave/total_leave*100).toFixed(2) + '%'
      },
      {
        'name': '恢复时间','before': total_recoverytime, 'after': cur_recorytime, 'rate': (cur_recorytime/total_recoverytime*100).toFixed(2) + '%'
      },
      {
        'name': '人员伤亡','before': total_death, 'after': cur_death, 'rate': (cur_death/total_death*100).toFixed(2) + '%'
      }];

      //下载
      this.downloadUrl2 = consts.requestUrl + consts.exportAfterReinforce + '?city_id='+this.city_id+'&rate='+this.reinforce_percent

     console.log('this.reinforce_building_percent_list',this.reinforce_building_percent_list)

     let data2 = [];
     
     this.reinforce_building_percent_list.forEach((item,idx) => {
        let obj = {
          'name': item.name,
          'value': item.data[parseInt(this.reinforce_percent)-1],
          'color': this.colors.building_type[idx]
        }
        data2.push(obj)

      });
      
      this.mapchart1.setOption(buildBarWhiteOption('建筑类型', data2,'name'))
      


    this.myChart2.dispatchAction({
        type:'hideTip'
     })
     this.myChart3.dispatchAction({
        type:'hideTip'
     })
     setTimeout(()=>{
      this.myChart2.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: parseInt(this.reinforce_percent)-1
      })
      this.myChart3.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: parseInt(this.reinforce_percent)-1
      })
     },50)
    if(this.myChart2.getOption() != undefined) {
      this.myChart2.setOption({
            series: [{
                id: 'timeline',
                type: 'line',
                data: [[parseInt(this.reinforce_percent)-1, 0], [parseInt(this.reinforce_percent)-1, 100]]
            }]
        });
    }

      if(this.myChart3.getOption() != undefined) {
        this.myChart3.setOption({
            series: [{
                id: 'timeline',
                type: 'line',
                data: [[parseInt(this.reinforce_percent)-1, 0], [parseInt(this.reinforce_percent)-1, 3000]]
            }]
        });
      }
     
     
    },
    getBuidingNameByType(type){
      let name = ''
      switch(type) {
        case '功能类型1': 
          name = "居住建筑";
        break;
        case '功能类型2': 
          name = "商用建筑";
        break;
        case '功能类型3': 
          name = "行政单位";
        break;
        case '功能类型4': 
          name = "交通出行";
        break;
        case '功能类型5': 
          name = "教育机构";
        break;
        case '功能类型6':
          name = "医疗机构";
        break;
        case '功能类型7':
          name = "旅游景点";
        break;
        case '功能类型8':
          name = "工业建筑";
        break;  
      }
      return name;
    },
    reloadData(type) {
      if(type == 'street') {
        this.selects.community = 0;
      }
      this.setTipContent();
      if(this.selects.data_category == "after_reinforce" && this.tabs.activeName == '叠加图层') {
        //在不显示叠加图层的数据集上，切换到第一个tab
        this.tabs.activeName = '信息图表'
      }
      this.fillBuildings(this.city_id, 1);
      this.fillBuildingsMap(this.city_id, this.color_category);
      //this.loadData(this.data_type);
      //关掉叠加涂层
      this.risk = false;
    },
    setTipContent() {
      let html = ''
      if(this.selects.data_category == 'before_reinforce') {
        html = consts.tipsBeforeReinforce
      } else {
        html = consts.tipsAfterReinforce
      }
      document.getElementById('left-tip-content').innerHTML = html
    },
    buildParams() {
      let params = { city_id: this.city_id };
      params.street_id = this.selects.street;
      params.community_id = this.selects.community;
      params.struct_id = this.selects.struct;
      params.category_id = this.selects.category;
      params.quake_level = this.selects.quakeLevel;
      params.start_build_year = 0;
      params.end_build_year = 0;
      if(this.selects.buildYear != '修建年代'){
        params.start_build_year = this.selects.buildYear.split('-')[0];
        params.end_build_year = this.selects.buildYear.split('-')[1];
      }
      
      params.keyword = this.keyword;
      params.reinforcement_priority = '';
      if(this.selects.priority){
        let tmp = ''
        switch(this.selects.priority){
          case 1:
            tmp = '高'
            break;
          case 2:
            tmp = '中'
            break;
          case 3:
            tmp = '低'
            break;
        }
        params.reinforcement_priority = tmp
      }
      if(this.selects.data_category == "after_reinforce") {
        params.rate = parseInt(this.reinforce_percent)
      }
      return params;
    },
    changePage: function(value) {
      this.fillBuildings(this.city_id, value);
    },
    addHeatmapLayer(map, type) {
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      let layer_point_id = layer_id + "point";
      // Add a geojson point source.
      // Heatmap layers also work with a vector tile source.
      map.addSource(source_name, {
        type: "geojson",
        data: "https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson"
      });

      map.addLayer(
        {
          id: layer_id,
          type: "heatmap",
          source: source_name,
          maxzoom: 9,
          paint: {
            // Increase the heatmap weight based on frequency and property magnitude
            "heatmap-weight": [
              "interpolate",
              ["linear"],
              ["get", "mag"],
              0,
              0,
              6,
              1
            ],
            // Increase the heatmap color weight weight by zoom level
            // heatmap-intensity is a multiplier on top of heatmap-weight
            "heatmap-intensity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              0,
              1,
              9,
              3
            ],
            // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
            // Begin color ramp at 0-stop with a 0-transparancy color
            // to create a blur-like effect.
            "heatmap-color": [
              "interpolate",
              ["linear"],
              ["heatmap-density"],
              0,
              "rgba(33,102,172,0)",
              0.2,
              "rgb(103,169,207)",
              0.4,
              "rgb(209,229,240)",
              0.6,
              "rgb(253,219,199)",
              0.8,
              "rgb(239,138,98)",
              1,
              "rgb(178,24,43)"
            ],
            // Adjust the heatmap radius by zoom level
            "heatmap-radius": [
              "interpolate",
              ["linear"],
              ["zoom"],
              0,
              2,
              9,
              20
            ],
            // Transition from heatmap to circle layer by zoom level
            "heatmap-opacity": ["interpolate", ["linear"], ["zoom"], 7, 1, 9, 0]
          }
        },
        "waterway-label"
      );

      map.addLayer(
        {
          id: layer_point_id,
          type: "circle",
          source: source_name,
          minzoom: 7,
          paint: {
            // Size circle radius by earthquake magnitude and zoom level
            "circle-radius": [
              "interpolate",
              ["linear"],
              ["zoom"],
              7,
              ["interpolate", ["linear"], ["get", "mag"], 1, 1, 6, 4],
              16,
              ["interpolate", ["linear"], ["get", "mag"], 1, 5, 6, 50]
            ],
            // Color circle by earthquake magnitude
            "circle-color": [
              "interpolate",
              ["linear"],
              ["get", "mag"],
              1,
              "rgba(33,102,172,0)",
              2,
              "rgb(103,169,207)",
              3,
              "rgb(209,229,240)",
              4,
              "rgb(253,219,199)",
              5,
              "rgb(239,138,98)",
              6,
              "rgb(178,24,43)"
            ],
            "circle-stroke-color": "white",
            "circle-stroke-width": 1,
            // Transition from heatmap to circle layer by zoom level
            "circle-opacity": ["interpolate", ["linear"], ["zoom"], 7, 0, 8, 1]
          }
        },
        "waterway-label"
      );
    },
    addPointLayerToMap(map, type) {
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      let geojson_url,
        icon = "";
      switch (type) {
        case "hospital":
          geojson_url = consts.url_geojson_hospital;
          icon = "hospital-15";
          break;
        case "hideaway":
          geojson_url = consts.url_geojson_hideaway;
          icon = "school-15";
          break;
        case "warehouse":
          geojson_url = consts.url_geojson_warehouse;
          icon = "parking-15";
          break;
      }
      if (geojson_url == "") {
        return;
      }
      var styleLoadStatus = map.isStyleLoaded();
      if (styleLoadStatus) {
        map.addSource(source_name, {
          type: "geojson",
          data: geojson_url
        });
        map.addLayer({
          id: layer_id,
          type: "symbol",
          source: source_name,
          layout: {
            "icon-image": icon,
            "text-field": "{name}",
            //"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-offset": [0, 0.6],
            "text-anchor": "top"
          }
        });
        return;
      }
      map.on("load", function() {
        map.addSource(source_name, {
          type: "geojson",
          data: geojson_url
        });
        map.addLayer({
          id: layer_id,
          type: "symbol",
          source: source_name,
          layout: {
            "icon-image": icon,
            "text-field": "{name}",
            //"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-offset": [0, 0.6],
            "text-anchor": "top"
          }
        });
      });
    },
    toggleMapLayer(type) {
      this.fillBuildingsMap(this.city_id, this.color_category);
    },
    handelMapLayer(type) {
      //console.log(type);
      this.selects.street = 0;
      this.selects.community = 0;
      //this.loadData(type);
      let types = ["hospital", "hideaway", "warehouse"];
      types.forEach(value => {
        if (value == type) {
          this.addPointLayerToMap(this.map, value);
        } else {
          this.removeMapLayer(this.map, value);
        }
      });
    },
    getMapLayerId(type) {
      let layer_id = type + "-layer";
      return layer_id;
    },
    getMapSourceName(type) {
      let source_name = type + "-data";
      return source_name;
    },
    removeMapLayer(map, type) {
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      var layer = map.getLayer(layer_id);
      if (layer) {
        map.removeLayer(layer_id);
        map.removeSource(source_name);
      }
    },
    removeHeatmapLayer(map, type) {
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      let layer_point_id = layer_id + "point";
      var layer = map.getLayer(layer_id);
      if (layer) {
        map.removeLayer(layer_id);
        map.removeLayer(layer_point_id);
        map.removeSource(source_name);
      }
    },
    loadData(type, page = 1) {
      switch (type) {
        case "people":
          this.fillPeopleData(page);
          break;
        case "gender":
          this.fillGenderData(page);
          break;
        case "hospital":
          this.fillHospitalsData(page);
          break;
        case "hideaway":
          this.fillHideawaysData();
          break;
        case "warehouse":
          this.fillWarehousesData();
          break;
      }
    }
  }
};
</script>



<style lang="scss" scoped>
.card-map {
  min-height: 350px;
  height: calc(100vh - 48px);
  margin-bottom: 0;

  position: relative;
  .map-wrapper{
    width: 100%;
    height: calc(100vh - 100px);
    position: relative;
  }
  #map {
    width: 100%;
    height: calc(100vh - 100px);
  }
  .map_data_msg {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    // color: #02f4fe;
    color: #fff;
    padding: 10px;
    width: auto;
    .map_data_legend {
      margin-bottom: 5px;
    }
    .map_data_msg_wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .item {
        width: 80px;
        line-height: 15px;
        padding: 5px 0;
      }
      .item span {
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 3px;
        vertical-align: middle;
        margin-left: 0;
      }
      .map_data_msg_inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 10px;
      }
    }
    &.map_data_chart {
      // width: 75%;
      bottom: 80px;
      background: rgba(255, 255, 255, 0);
      .map_data_chart_inner {
        width: 100%;
        display: flex;
        background: rgba(0, 0, 0, 0.5);
        padding: 10px;
      }
      .map-chart-area { 
        //width: 10vw;
        width: 100px;
        height: 150px;  
        margin-right: 20px;
      }
      #map-chart {
        width: 200px;
        height: 150px;
      }
    }
  }
}

.overlap_layer_desc {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .item {
    line-height: 15px;
    padding: 10px 10px 10px 0;
  }
  .item span {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 3px;
    vertical-align: bottom;
    margin-left: 0;
  }
  .map_data_msg_inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 10px;
  }
}

.main-panel {
  // height: calc(100vh - 130px);
  min-height: 350px;
}


.right-block {
  height: calc(100% - 131px);
  // min-height: 350px;
  overflow: hidden;
  .tab-pane   {
    height: calc(100vh - 370px);
    overflow: auto; 
    overflow-scrolling:touch; 
    -webkit-overflow-scrolling:touch;
  }
}
.silide-wrapper {
  width: 82%;
  margin: 0 auto;
}
.decision-wrapper {
  padding: 0 0 20px;  
}
.decision-block {
  overflow: hidden;
  padding-bottom: 30px;
}
.demonstration {
  padding: 10px 0;
  display: block;
  float: left;
}
.tab-scroller {
  height: 100%;
  min-height: 350px;
  overflow-y: auto;
}

.msg-percent {
  padding: 10px 0 0 0;  
  float: right;
}


.show_list {
  width: 100%;
  height: 50px;
  padding-top: 6px;

  // margin-top: 8px;
}
.show_list span {
  height: 32px;
  line-height: 36px;
  margin-left: 32px;
  font-size: 14px;
}
.show_list span i {
  height: 16px;
  padding: 2px 12px;
  background: #ffb236;
  margin: 0 4px;
  border-radius: 16px;
}
.show_list .up_show {
  display: block;
  float: left;
  font-size: 32px;
  cursor: pointer;
}
.show_list .download {
  display: block;
  float: right;
  font-size: 32px;
  cursor: pointer;
}
.footer {
  padding-top: 0;
}
.extended-forms .el-select {
  width: 100%;
  margin-bottom: 30px;
}

.extended-forms .progress {
  margin-bottom: 30px;
}
@import url("https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css");
.coordinates {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 5px 10px;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  display: none;
}
ul.nav__items > li:last-of-type a {
  margin-right: 0 !important;
}
.card-body .tab-content .tab-scroller {
  min-height: auto;
}
// .card-map .card-body {
//   padding: 0;
// }
.chart-area {
  height: 400px;
}
#box-chart3 {
  margin-top: 30px;
}
.key-decision {
  .el-collapse {
    padding: 0;
    margin: 10px 0;
  }
  /deep/ .el-collapse-item__content {
    padding: 10px 0!important;
  }
}
</style>
