import request from '@/util/request';

export function getPeopleStat(params) {
    return request({
      url: 'api/community-populations',
      method: 'get',
      params: params
    })
}

export function getGenderStat(params) {
    return request({
      url: 'api/stat/gender',
      method: 'get',
      params: params
    })
}

export function getHideaways(params) {
    return request({
      url: 'api/hideaways',
      method: 'get',
      params: params
    })
}

export function getHospitals(params) {
    return request({
      url: 'api/hospitals',
      method: 'get',
      params: params
    })
}

export function getWarehouses(params) {
    return request({
      url: 'api/warehouses',
      method: 'get',
      params: params
    })
}

export function getPeopleGeo(params) {
  return request({
    url: 'api/community-populations/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getHospitalGeo(params) {
  return request({
    url: 'api/hospitals/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getHideawaylGeo(params) {
  return request({
    url: 'api/hideaways/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getWarehouseGeo(params) {
  return request({
    url: 'api/warehouses/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getRegionGeo(params) {
  return request({
    url: 'api/regions/geo',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function getPeopleStatistics(params) {
  return request({
    url: 'api/community-populations/statistics',
    method: 'get',
    params: Object.assign({}, params,{street_id: 0, community_id: 0}),
  })
}

export function getHideawaysStatistics(params) {
  return request({
    url: 'api/hideaways/statistics',
    method: 'get',
    params: Object.assign({}, params,{street_id: 0, community_id: 0}),
  })
}

export function getWarehousesStatistics(params) {
  return request({
    url: 'api/warehouses/statistics',
    method: 'get',
    params: Object.assign({},params,{street_id: 0, community_id: 0}),
  })
}


export function getHospitalsStatistics(params) {
  return request({
    url: 'api/hospitals/statistics',
    method: 'get',
    params: Object.assign({},params,{street_id: 0, community_id: 0}),
  })
}

