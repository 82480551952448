<template>
  <div class="row" style="margin:0 -30px;">
    <div class="col-md-9" style="padding:0">
      <card class="card-map">
        <div id="map"></div>
        <menu-map :map="map" :mapDefault="mapdefaultSetting" showDisaster="false" showWeather="true" area="2" :marginTop="30">
        </menu-map>
        <div class="radio-group">
          <div v-for="(item) in types" :key="item.id" @click="changeType(item.id)">
            <label :for='item.id'>
              <input type="radio" :id="item.id" :value="item.id" v-model="curType">
              {{ item.value }}
            </label>
          </div>
        </div>
      </card>
    </div>
    <div class="col-md-3" id="customStyle">
      <el-collapse v-model="activeNames">
        <el-collapse-item title="信息图表" name="1">
          <div class="col-md-12 condition-box">
            <div>
              <div class="sub-title">行政区域</div>
              <div class="condition-area">
                <el-checkbox :indeterminate="dist.isIndet" v-model="dist.checkAll" @change="(e) => changeAll(e, 'dist')">全选</el-checkbox>
                <el-checkbox-group v-model="dist.value" @change="(e) => changeCheck(e, 'dist')">
                  <el-checkbox v-for="it in dist.list" :label="it" :key="it"></el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
            <div>
              <div class="sub-title">起火场所</div>
              <div class="condition-area">
                <el-checkbox :indeterminate="location.isIndet" v-model="location.checkAll" @change="(e) => changeAll(e, 'location')">全选</el-checkbox>
                <el-checkbox-group v-model="location.value" @change="(e) => changeCheck(e, 'location')">
                  <el-checkbox v-for="it in location.list" :label="it" :key="it"></el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
            <div>
              <div class="sub-title">起火原因</div>
              <div class="condition-area">
                <el-checkbox :indeterminate="reason.isIndet" v-model="reason.checkAll" @change="(e) => changeAll(e, 'reason')">全选</el-checkbox>
                <el-checkbox-group v-model="reason.value" @change="(e) => changeCheck(e, 'reason')">
                  <el-checkbox v-for="it in reason.list" :label="it" :key="it"></el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </div>
          <div class="col-md-12 chart-pie-box">
            <div class="chart-area" id="chart-pie-location"></div>
            <div class="chart-area" id="chart-pie-reason"></div>
          </div>
          <div class="col-md-12 chart-river-box">
            <div class="chart-area" id="chart-river-location"></div>
          </div>
          <div class="col-md-12 chart-river-box">
            <div class="chart-area" id="chart-river-reason"></div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="col-md-9 bottom-time-content">
      <div class="control-box">
        <div>
          时间段：
          <el-tag
            v-for="(it, ind) in timeRange"
            :key="ind"
            class="time-tag"
            size="small"
            closable
            @close="delTimeRange(ind)"
          >
            {{ it.join('-') }}
          </el-tag>
        </div>
        <div>
          <el-button class="chart-btn" size="small" @click="onTimeSelect">选择时间</el-button>
          <el-button class="chart-btn" size="small" @click="resetTimeRange">重置时间</el-button>
        </div>
      </div>
      <div v-show="showSlider" class="col-md-12 slider-box"></div>
      <div class="chart-time" id="chart-line-time"></div>
    </div>
  </div>
</template>
<script>
import mapboxgl from "mapbox-gl";
import MenuMap from "src/components/menumapFire3";
import { consts } from "src/util/consts.js";
import { Collapse, CollapseItem, CheckboxGroup, Checkbox, Button, Tag } from "element-ui";
import { buildPieGradientOption, buildRiverOption, buildLineAreaOption } from "src/util/chart.js";
import {
  getDecisionForecastChartLine as getChartLine,
  getDecisionForecastChartPie as getChartPie,
  getDecisionForecastChartRiver as getChartRiver,
} from "src/api/fire.js";
import { storeMapOptions, getMapOptions } from "src/util/common.js";

const dict = consts.fireForecastDict

export default {
  components: {
    MenuMap,
    [Tag.name]: Tag,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
  },
  data() {
    return {
      mid: 0, //模块id
      types: [
        { id: 1, value: '基于历史和当前火灾风险动态拟合' },
        { id: 3, value: '基于历史火灾和时空数据预测' }
      ], //左上角切换radio
      curType: 0,
      activeNames: ['1'],
      activeName: "first",
      showSlider: true,
      timeRange: [dict.time],
      timeList: [],
      timeChartAreas: [],
      dist:{
        value: [...dict.dist],
        list: [...dict.dist],
        isIndet: false,
        checkAll: true,
      },
      location:{
        value: [...dict.scene],
        list: [...dict.scene],
        isIndet: false,
        checkAll: true,
      },
      reason:{
        value: [...dict.caus],
        list: [...dict.caus],
        isIndet: false,
        checkAll: true,
      },
      map: {},
      mapdefaultSetting: {},
      layerId: 'decision-forecast-layer',
      city_id: localStorage.getItem("city_id") || 1,
    };
  },

  mounted() {
    let _this = this;
    this.mid = this.$route.params.did || 1;
    this.curType = this.mid;
    this.setTipContent()
    this.initMap(this.city_id);
    this.initChart()
    this.showChart()
    this.map.on('style.load', function () {
      _this.showLayer()
    })
    // 监听键盘
    window.onkeydown = function (e) {
      if (e.keyCode === 17) {// Control键
        _this.onTimeSelect()
      }
    }
  },
  methods: {
    initMap(city_id) {
      this.mapdefaultSetting = {
        center: consts.mapCenter[this.city_id - 1],
        zoom: consts.mapDefaultZoom,
        pitch: 0,
        city_id: this.city_id
      }
      mapboxgl.accessToken = consts.mapboxAccessToken;
      let map_options = getMapOptions();
      let zoom = map_options ? map_options.zoom : 12;
      let center = map_options ? [map_options.lng, map_options.lat] : consts.mapCenter[city_id - 1];
      var map = new mapboxgl.Map({
        container: "map",
        style: consts.mapBoxStyle,
        center: center,
        zoom: zoom,
        pitch: 0,
        bearing: 0,
        attributionControl: false
      });
      this.map = map;
      //定位图标
      map.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl
        })
      );
      this.map.addControl(new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      }));
      //控制图层
      var nav = new mapboxgl.NavigationControl();
      this.map.addControl(nav, 'top-right');
      //全屏按钮
      this.map.addControl(new mapboxgl.FullscreenControl({
        container: document.querySelector('body')
      }));

      //记录地图设置
      map.on('move', function () {
        storeMapOptions({
          lng: map.getCenter().lng,
          lat: map.getCenter().lat,
          zoom: map.getZoom()
        });
      });
    },
    initChart() {
      const box1 = document.getElementById("chart-pie-location");
      const box2 = document.getElementById("chart-pie-reason");
      const box3 = document.getElementById("chart-river-location");
      const box4 = document.getElementById("chart-river-reason");
      const box5 = document.getElementById("chart-line-time");
      this.chart1 = this.$echarts.init(box1);
      this.chart2 = this.$echarts.init(box2);
      this.chart3 = this.$echarts.init(box3);
      this.chart4 = this.$echarts.init(box4);
      this.chart5 = this.$echarts.init(box5);

      this.chart3.on('legendselectchanged', (obj) => {
        this.filterChart(obj, 'location')
      })
      this.chart4.on('legendselectchanged', (obj) => {
        this.filterChart(obj, 'reason')
      })
      this.chart5.on('brushEnd', (params) => {
        this.timeChartAreas = params.areas
        this.showSlider = false
        this.timeRange = params.areas.map(it => it.coordRange).map(it => it.map(i => this.timeList[i]))
      })
      window.addEventListener('resize', () => {
        document.querySelectorAll('.chart-area').forEach((item) => {
          item.style.width = document.querySelector('.el-collapse').offsetWidth + 'px';
        })
        document.querySelectorAll('.chart-time').forEach((item) => {
          item.style.width = document.querySelector('.slider-box').offsetWidth + 38 + 'px';
        })
        this.chart1.resize();
        this.chart2.resize();
        this.chart3.resize();
        this.chart4.resize();
        this.chart5.resize();
      })
    },
    changeType(val) {
      //切换左上角列表
      if (val == 1) {
        this.$router.push({ path: '/fire/building/decisionrisk/1' })
      } else {
        this.$router.push({ path: '/fire/building/decisionforecast/3' })
      }
    },
    changeCheck(val, key) {
      let checkedCount = val.length;
      this[key].checkAll = checkedCount === this[key].list.length;
      this[key].isIndet = checkedCount > 0 && checkedCount < this[key].list.length;
    },
    changeAll(val, key) {
      this[key].value = val ? this[key].list : [];
      this[key].isIndet = false;
    },
    delTimeRange(ind) {
      const old = this.timeRange
      const areas = this.timeChartAreas
      old.splice(ind, 1)
      areas.splice(ind, 1)
      this.timeRange = old
      this.chart5.dispatchAction({
        type: 'brush',
        areas,
      })
    },
    resetTimeRange() {
      this.timeRange = [dict.time]
      this.showSlider = true
      this.chart5.dispatchAction({
        type: 'brush',
        areas: [],
      })
    },
    onTimeSelect() {
      this.chart5.dispatchAction({
        type: 'takeGlobalCursor',
        key: 'brush',
        brushOption: {
          brushType: 'lineX',
          brushMode: 'multiple',
        }
      })
    },
    offTimeSelect() {
      this.chart5.dispatchAction({
        type: 'takeGlobalCursor',
        key: 'brush',
        brushOption: {
          brushType: false,
          brushMode: 'multiple',
        }
      })
    },
    showChart(isUpdate) {
      const params = {
        time: this.timeRange.flat(1),
        dist: this.dist.value.length === dict.dist.length ? [] : this.dist.value,
        caus: this.reason.value.length === dict.caus.length ? [] : this.reason.value,
        scene: this.location.value.length === dict.scene.length ? [] : this.location.value,
      }
      getChartPie(params).then(res => {
        const { sence, casuse } = res.data
        const data1 = Object.keys(sence).map(it => ({ name: it, value: sence[it] }))
        const data2 = Object.keys(casuse).map(it => ({ name: it, value: casuse[it] }))
        const option1 = buildPieGradientOption('各起火场所数量和占比', data1)
        const option2 = buildPieGradientOption('各起火原因数量和占比', data2)
        this.chart1.setOption(option1);
        this.chart2.setOption(option2);
        this.chart1.resize();
        this.chart2.resize();
      })
      getChartRiver(params).then(res => {
        const { sence, casuse } = res.data
        const labels3 = Object.keys(sence)
        const labels4 = Object.keys(casuse)
        const data3 = labels3.map(it1 => {
          return sence[it1].map((it2, ind) => [`2023/12/15 ${ind}`, it2, it1])
        }).flat(1)
        const data4 = labels4.map(it1 => {
          return casuse[it1].map((it2, ind) => [`2023/12/15 ${ind}`, it2, it1])
        }).flat(1)
        const option3 = buildRiverOption('各起火场所时序河流图', data3, labels3)
        const option4 = buildRiverOption('各起火原因时序河流图', data4, labels4)
        this.chart3.setOption(option3);
        this.chart4.setOption(option4);
        this.chart3.resize();
        this.chart4.resize();
      })
      if (isUpdate) return
      getChartLine(params).then(res => {
        const option5 = buildLineAreaOption(this.$echarts, res.data)
        this.chart5.setOption(option5);
        this.chart5.resize();
        this.timeList = Object.keys(res.data)
      })
    },
    showLayer() {
      if (!this.map.getSource('hangzhou_outline')) {
        this.map.addSource('hangzhou_outline', {
          type: "geojson",
          data: './geojson/hz-outline.geojson',
        });
      }
      if (!this.map.getSource(this.layerId)) {
        this.map.addSource(this.layerId, {
          type: "geojson",
          data: './geojson/fire.geojson',
        });
      }
      const layerHZ = {
        id: 'hangzhou_outline',
        type: "line",
        source: 'hangzhou_outline',
        paint: {
          "line-color": '#00ff00',
          "line-opacity": 1.0,
        },
      }
      const layerPoint = {
        id: this.layerId,
        type: "circle",
        source: this.layerId,
        paint: {
          "circle-radius": 3.5,
          "circle-color": '#ffffff',
          "circle-stroke-color": "white",
          "circle-stroke-width": 0.7,
          "circle-opacity": 0.9,
        },
      }
      this.map.addLayer(layerHZ)
      this.map.addLayer(layerPoint)
    },
    filterChart(obj, type) {
      const { name, selected } = obj
      if (type === 'location') { // 场所
        this.chart1.dispatchAction({type: selected[name] ? 'legendSelect' : 'legendUnSelect', name})
        this.chart3.dispatchAction({type: selected[name] ? 'legendSelect' : 'legendUnSelect', name})
      } else { // 原因
        this.chart2.dispatchAction({type: selected[name] ? 'legendSelect' : 'legendUnSelect', name})
        this.chart4.dispatchAction({type: selected[name] ? 'legendSelect' : 'legendUnSelect', name})
      }
    },
    filterMap() {
      if (this.map.getLayer(this.layerId)) {
        const filter = this.timeRange.map(it => {
          return ["all", [">=", "fire_month", Number(it[0])], ["<=", "fire_month", Number(it[1])]]
        })
        this.map.setFilter(this.layerId, [
          "all",
          ["in", "dist", ...Array.from(this.dist.value)],
          ["in", "fire_cause", ...Array.from(this.reason.value)],
          ["in", "fire_scene", ...Array.from(this.location.value)],
          [ "any", ...filter ]
        ]);
      }
    },
    removeLayer() {
      if (this.map.getSource(this.layerId)) {
        this.map.removeSource(this.layerId);
      }
      if (this.map.getLayer(this.layerId)) {
        this.map.removeLayer(this.layerId)
      }
    },
    setTipContent() {
      document.getElementById('left-tip-content').innerHTML = '基于历史火灾和时空数据预测：'
    },
  },
  watch: {
    'dist.value'() {
      this.filterMap()
      this.showChart()
    },
    'location.value'() {
      this.filterMap()
      this.showChart()
    },
    'reason.value'() {
      this.filterMap()
      this.showChart()
    },
    'timeRange'(newVal) {
      this.filterMap()
      this.showChart(true)
      if (newVal.length === 3) {
        this.offTimeSelect()
      }
    },
  },
  beforeDestroy() {
    this.removeLayer()
  }
};
</script>

<style lang="scss" scoped>
.card-map {
  min-height: 350px;
  height: calc(100vh - 48px);
  margin-bottom: 0;
  position: relative;

  .map-wrapper {
    width: 100%;
    height: calc(100vh - 100px);
    position: relative;

    &>* {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  #map {
    width: 100%;
    height: calc(100vh - 200px);
  }

  .radio-group {
    position: absolute;
    left: 10px;
    top: 10px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding: 10px;
  }

  .radio-group div {
    padding: 0 10px;
  }

  .radio-group div input {
    vertical-align: -1px;
    padding-right: 2px;
  }
}

.sub-title {
  text-align: center;
  font-weight: 700;
}

.chart-area {
  width: 100%;
  height: 200px;
}

.condition-box {
  display: flex;
  width: 100%;
  margin-bottom: 16px;

  > div {
    flex: 1;

    &:hover ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: rgba(0,0,0,0.3);
    }
  }

  .sub-title {
    text-align: left;
  }

  .condition-area {
    max-height: 200px;
    overflow-y: auto;
  }

  :deep(.el-checkbox) {
    display: block;
    margin: 0 8px 0 0;
  }

  :deep(.el-checkbox__label) {
    color: #fff;
    font-size: 12px;
    padding-left: 4px;
  }
}

.chart-pie-box {
  display: flex;
  width: 100%;

  > div {
    flex: 1;
    margin-bottom: 0;
  }
}

.bottom-time-content {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 182px;

  .control-box {
    margin: 8px 16px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;

    .time-tag {
      color: #fff;
      background: transparent;
      border-color: rgba(151,184,216,0.5);
      margin-bottom: 0;
    }

    .chart-btn {
      color: #fff;
      font-weight: 400;
      height: 24px;
      background: transparent;
      border-color: rgba(151,184,216,0.5);
      padding: 0 8px;

      &:hover {
        color: #fff;
      }
    }

    .time-range {
      height: 24px;
      line-height: 24px;
      width: 66px !important;
      padding: 2px 8px;
      background: transparent;
      border-color: rgba(151,184,216,0.5);

      :deep(.el-input__icon),
      :deep(.el-range-input) {
        display: none;
      }
      :deep(.el-range-separator) {
        color: #fff;
        width: auto;
        line-height: 18px;
        padding: 0;
      }
    }
  }

  .slider-box {
    position: absolute;
    left: 34px;
    right: 34px;
    width: calc(100% - 68px);
    height: 60px;
    margin-top: 15px;
    background-color: rgba(#fff, 0.2);
  }

  .chart-time {
    width: 100%;
    height: 100px;
  }
}

@import url("https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css");
.chart-area {
  width: 100%;
}
</style>
