export function buildPieOption(name, data, _option) {
  var legends = data.map((x) => x.name);

  var option = {
    title: {
      text: name,
      left: "left",
      textStyle: {
        fontSize: 12,
        color: "#fff",
      },
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      orient: "horizontal",
      left: 0,
      top: 30,
      data: legends,
      textStyle: {
        fontSize: 10,
        color: "#fff",
      },
    },
    series: [
      {
        name: name,
        type: "pie",
        radius: "60%",
        center: ["50%", "60%"],
        data: data,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        // label: {
        //     normal: {
        //         formatter: '{c}',
        //         position: 'inside'
        //     }
        // },
        label: {
          normal: {
            //formatter: "{d}%",
            formatter: function (data) {
              return data.percent.toFixed(2) + "%";
            },
            position: "inside",
            textStyle: {
              color: "#fff",
            },
          },
        },
      },
    ],
  };
  if (_option) {
    Object.assign(option, _option);
  }
  return option;
}
export function buildPieGradientOption(name, data, colors) {
  const color = colors || ["#37A2DA", "#32C5E9", "#67E0E3", "#9FE6B8", "#FFDB5C", "#ff9f7f", "#fb7293", "#E062AE", "#E690D1", "#e7bcf3", "#9d96f5", "#8378EA", "#96BFFF"]

  // 右侧 标注 圆环 文字
  const legendData = data.map((item, index) => {
    return {
    name: item.name,
    itemStyle: {
      color: 'transparent',
      borderWidth: 2,
      borderColor: color[index % color.length]
    }
    }
  })
  const option = {
    title: {
      text: name,
      x: "center",
      textStyle: {
        fontSize: 14,
        color: '#fff'
      },
    },
    tooltip: {
      show: true,
      confine: true,
      formatter: '{b}: {c}个 \t{d}%',
      textStyle: {
        fontSize: 12,
      },
      padding: 3,
    },
    legend: {
      show: true,
      type: 'scroll',
      orient: 'horizontal',
      align: 'auto',
      top: -100, // 隐藏legend
      icon: 'circle',
      itemGap: 5,
      itemHeight: 8,
      itemWidth: 8,
      formatter: params => {
        const aim = data.find(item => item.name === params)
        return `{name|${params}}`
      },
      pageTextStyle: {
        color:'#fff'
      },
      textStyle: {
        lineHeight: 20,
        color: '#fff',
        rich: {
        name: {
          fontSize: 12,
          fontFamily: 'PingFang-SC-Regular'
        },
        number: {
          fontSize: 14,
          fontFamily: 'DS-Digital-Bold',
          padding: [0, 5, 0, 8],
        },
        unit: {
          fontSize: 14,
        }
      }
    },
    data: legendData
  },
  series: [{
      type: 'pie',
      radius: ['50%', '70%'],
      center: ['50%', '55%'],
      clockwise: true,
      avoidLabelOverlap: true,
      label: {
        show: false,
      },
      itemStyle: {
        normal: {
        // 设置渐变
        color: function (params) {
            return {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 1,
              y2: 1,
              colorStops: [{
                offset: 0,
                // color: 'rgba(58, 87, 105, 1)' // 0% 处的颜色
                color: color[params.dataIndex % color.length] // 0% 处的颜色
              },
              {
                  offset: 1,
                  color: color[params.dataIndex % color.length],
                  // 100% 处的颜色
              }
              ],
              globalCoord: false // 缺省为 false
            }
        }
        }
      },
      data,
    },
    // 中间阴影圈
    {
      name: "阴影圈",
      type: "pie",
      radius: ["0", "40%"],
      center: ["50%", "55%"],
      emphasis: {
        scale: false,
      },
      tooltip: {
        show: false,
      },
      itemStyle: {
        color: "rgba(204,225,255, 0.05)",
      },
      zlevel: 4,
      labelLine: {
        show: false,
      },
      data: [100],
    },]
  };
  return option
}
export function buildRiverOption(name, data, labels) {
  const color = ["#37A2DA", "#32C5E9", "#67E0E3", "#9FE6B8", "#FFDB5C", "#ff9f7f", "#fb7293", "#E062AE", "#E690D1", "#e7bcf3", "#9d96f5", "#8378EA", "#96BFFF"]
  const option = {
    title: {
      text: name,
      x: "center",
      textStyle: {
        fontSize: 14,
        color: '#fff'
      },
    },
    color,
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'line',
        lineStyle: {
          color: 'rgba(255,255,0,0.8)',
          width: 1,
          type: 'solid'
        }
      },
      formatter: function(params) {
        const list = params.map(it => `<div style="display: flex;justify-content: space-between;">
          ${it.value[2]}
          <span>${it.value[1]}</span>
        </div>`
        ).join('')
        return `${params[0].dataIndex}h:<br />${list}`
    },
    },
    legend: {
      type: 'scroll',
      pageIconColor: '#fff',
      pageTextStyle: {
        color: '#fff'
      },
      top: 20,
      itemWidth: 10,
      itemHeight: 10,
      textStyle: {
        color: '#fff',
        fontSize: 12,
      },
      data: labels,
    },
    singleAxis: {
      top: 50,
      bottom: 20,
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      axisTick: {},
      axisLabel: {
        formatter: '{H}h',
      },
      type: 'time',
      axisPointer: {
        animation: true,
        label: {
          show: false
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
          opacity: 0.5,
        }
      }
    },
    series: [{
      type: 'themeRiver',
      label: {
        normal: {
          show: false,
          position: 'left'
        }
      },
      itemStyle: {
        emphasis: {
          shadowBlur: 20,
          shadowColor: 'rgba(0, 0, 0, 0.8)'
        }
      },
      data,
    }]
  };
  return option
}
export function buildPieWhiteOption(name, data) {
  var legends = data.map((x) => x.name);

  var option = {
    title: {
      text: name,
      x: "center",
      y: "bottom",
      textStyle: {
        fontSize: 12,
        color: "#fff",
      },
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      right: 30,
      data: legends,
      textStyle: {
        fontSize: 10,
        color: "#fff",
      },
      show: false,
    },
    series: [
      {
        name: name,
        type: "pie",
        radius: "80%",
        center: ["50%", "60%"],
        data: data,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(255, 255, 255, 0.5)",
          },
        },
        label: {
          normal: {
            formatter: "{c}",
            position: "inside",
          },
        },
      },
    ],
  };
  return option;
}
export function buildBarOption(name, data, _legend, _option) {
  var series = [];
  let legends = [];
  for (var i in data[0]) {
    if (i == _legend || i == "color") {
      continue;
    }
    if (i != "value") {
      //只有一个series的话，legends就不显示了，key是value
      legends.push(i);
    }
    series.push({
      type: "bar",
      name: i,
      data: data.map((item) => item[i]),
      itemStyle: {
        normal: {
          color: function (params) {
            // build a color map as your need.
            if ("color" in data[0]) {
              return data[params.dataIndex]["color"];
            }
          },
        },
      },
    });
  }

  var option = {
    grid: {
      left: "2%",
      right: "2%",
      bottom: "2%",
      containLabel: true,
      textStyle: {
        fontSize: 12,
        color: "#fff",
      },
    },
    title: {
      text: name,
      left: "left",
      textStyle: {
        fontSize: 12,
        color: "#fff",
      },
    },
    legend: {
      data: legends,
      left: "left",
      top: "30",
      textStyle: {
        fontSize: 12,
        color: "#fff",
      },
    },
    tooltip: {
      trigger: "item",
      formatter: "{b}:{c} ",
    },
    xAxis: {
      type: "category",
      data: data.map((item) => item[_legend]),
      nameLocation: "end", //坐标轴名称显示位置。
      axisLine: {
        lineStyle: {
          color: "#fff",
        },
      },
      axisLabel: {
        //坐标轴刻度标签的相关设置。
        interval: 0,
        rotate: "45",
        textStyle: {
          fontSize: 10,
          color: "#fff",
        },
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#fff",
        },
      },
      axisLabel: {
        textStyle: {
          fontSize: 12,
          color: "#fff",
        },
      },
    },
    series: series,
  };
  if (legends.length > 0) {
    option.grid.top = "25%";
  }
  // if(_option) {
  //     Object.assign(option, _option)
  // }
  if (_option && _option.yAxis) {
    Object.assign(option.yAxis, _option.yAxis);
  }
  return option;
}

export function buildBarYOption(name, data, _legend, _option) {
  //与buildBarOption坐标系对调一下
  var series = [];
  let legends = [];
  for (var i in data[0]) {
    if (i == _legend || i == "color") {
      continue;
    }
    if (i != "value") {
      //只有一个series的话，legends就不显示了，key是value
      legends.push(i);
    }
    series.push({
      type: "bar",
      name: i,
      data: data.map((item) => item[i]),
      itemStyle: {
        normal: {
          color: function (params) {
            // build a color map as your need.
            if ("color" in data[0]) {
              return data[params.dataIndex]["color"];
            }
          },
        },
      },
    });
  }

  var option = {
    grid: {
      left: "2%",
      right: "4%",
      bottom: "2%",
      top: "30",
      containLabel: true,
    },
    title: {
      text: name,
      left: "left",
      textStyle: {
        fontSize: 12,
        color: "#fff",
      },
    },
    legend: {
      data: legends,
      left: "left",
      top: "30",
      textStyle: {
        fontSize: 12,
        color: "#fff",
      },
    },
    tooltip: {
      trigger: "item",
      formatter: "{b}:{c} ",
    },
    yAxis: {
      type: "category",
      data: data.map((item) => item[_legend]),
      nameLocation: "end", //坐标轴名称显示位置。
      axisLine: {
        lineStyle: {
          color: "#fff",
        },
      },
      axisLabel: {
        //坐标轴刻度标签的相关设置。
        textStyle: {
          color: "#fff",
        },
      },
      // axisLabel: {//坐标轴刻度标签的相关设置。
      //     interval: 0,
      //     rotate: "45",
      //     textStyle: {
      //         fontSize: 10
      //     }
      // }
    },
    xAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#fff",
        },
      },
      axisLabel: {
        textStyle: {
          fontSize: 12,
          color: "#fff",
        },
      },
    },
    series: series,
  };
  if (legends.length > 0) {
    option.grid.top = "25%";
  }
  // if(_option) {
  //     Object.assign(option, _option)
  // }
  if (_option && _option.xAxis) {
    Object.assign(option.xAxis, _option.xAxis);
  }
  if (_option && _option.grid) {
    Object.assign(option.grid, _option.grid);
  }
  if (_option && _option.yAxis) {
    Object.assign(option.yAxis, _option.yAxis);
  }
  if (_option && _option.tooltip) {
    Object.assign(option.tooltip, _option.tooltip);
  }
  if (_option && _option.dataZoom) {
    option.dataZoom = _option.dataZoom;
  }
  return option;
}

export function buildBarSideOption(name, data, _legend, _option) {
  //bar在两边数值在中间
  var series = [];
  let legends = [];
  let catagory = [];
  data.forEach((item, idx) => {
    catagory.push(item.age);
  });
  var j = 0;
  for (var i in data[0]) {
    if (i == _legend || i == "color") {
      continue;
    }
    if (i != "value") {
      //只有一个series的话，legends就不显示了，key是value
      legends.push(i);
    }

    series.push({
      id: i,
      name: i,
      type: "bar",
      barWidth: 15,
      xAxisIndex: j == 0 ? 0 : 2,
      yAxisIndex: j == 0 ? 0 : 2,
      label: {
        normal: {
          show: false,
        },
        emphasis: {
          show: false,
          position: j == 0 ? "left" : "right",
          offset: [0, 0],
          textStyle: {
            color: "#fff",
            fontSize: 14,
          },
        },
      },
      itemStyle: {
        normal: {
          color: j == 0 ? "#62bff5" : "#f5c243",
        },
      },
      data: data.map((item) => item[i]),
    });
    j++;
  }

  var option = {
    title: {
      text: name,
      left: "left",
      textStyle: {
        fontSize: 12,
        color: "#fff",
      },
    },
    grid: [
      {
        left: "4%",
        top: 65,
        bottom: 10,
        containLabel: true,
        width: "32%",
      },
      {
        left: "54%",
        top: 85,
        bottom: 10,
        width: "0",
      },
      {
        right: "4%",
        top: 65,
        bottom: 10,
        containLabel: true,
        width: "32%",
      },
    ],
    legend: {
      data: legends,
      top: 30,
      right: "15",
      textStyle: {
        fontSize: 12,
        color: "#fff",
      },
    },
    tooltip: {
      show: true,
      trigger: "axis",
      formatter: "{b}<br/>{a}: {c}",
      axisPointer: {
        type: "shadow",
      },
    },
    xAxis: [
      {
        type: "value",
        inverse: true,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        position: "top",
        axisLabel: {
          show: true,
          textStyle: {
            color: "#ffffff",
            fontSize: 12,
          },
          formatter: function (a, b) {
            return a / 10000 + "\u4E07";
          },
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: "#1F2022",
            width: 1,
            type: "solid",
          },
        },
        minInterval: 20000,
      },
      {
        gridIndex: 1,
        show: false,
      },
      {
        gridIndex: 2,
        type: "value",
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        position: "top",
        axisLabel: {
          show: true,
          textStyle: {
            color: "#ffffff",
            fontSize: 12,
          },
          formatter: function (a, b) {
            return a / 10000 + "\u4E07";
          },
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: "#1F2022",
            width: 1,
            type: "solid",
          },
        },
        minInterval: 20000,
      },
    ],
    yAxis: [
      {
        type: "category",
        position: "right",
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
          margin: 8,
          textStyle: {
            color: "#02f4fe",
            fontSize: 12,
          },
        },
        data: catagory,
      },
      {
        gridIndex: 1,
        type: "category",
        position: "left",
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: true,
          textStyle: {
            color: "#ffffff",
            fontSize: 12,
          },
        },
        data: catagory.map(function (a) {
          return {
            value: a,
            textStyle: {
              align: "center",
            },
          };
        }),
      },
      {
        gridIndex: 2,
        type: "category",
        position: "left",
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
          textStyle: {
            color: "#9D9EA0",
            fontSize: 12,
          },
        },
        data: catagory,
      },
    ],
    series: series,
    backgroundColor: "",
  };
  if (legends.length > 0) {
    option.grid.top = "25%";
  }
  if (_option) {
    Object.assign(option, _option);
  }
  return option;
}

export function buildBarWhiteOption(name, data, _legend, _option) {
  var series = [];
  let legends = [];
  for (var i in data[0]) {
    if (i == _legend || i == "color") {
      continue;
    }
    if (i != "value") {
      //只有一个series的话，legends就不显示了，key是value
      legends.push(i);
    }
    series.push({
      type: "bar",
      name: i,
      data: data.map((item) => item[i]),
      itemStyle: {
        normal: {
          color: function (params) {
            // build a color map as your need.
            if ("color" in data[0]) {
              return data[params.dataIndex]["color"];
            }
          },
          label: { show: true, position: "inside" },
        },
      },
    });
  }

  var option = {
    grid: {
      top: "30",
      left: "2%",
      right: "2%",
      bottom: "2%",
      containLabel: true,
    },
    title: {
      text: name,
      left: "left",
      textStyle: {
        fontSize: 12,
        color: "#fff",
      },
    },
    legend: {
      data: legends,
      left: "left",
      top: "0",
    },
    tooltip: {
      trigger: "item",
      formatter: "{b}:{c} ",
    },
    xAxis: {
      type: "category",
      data: data.map((item) => item[_legend]),
      nameLocation: "end", //坐标轴名称显示位置。
      axisLabel: {
        //坐标轴刻度标签的相关设置。
        interval: 0,
        rotate: "45",
        textStyle: {
          fontSize: 10,
          color: "#fff",
        },
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        //坐标轴刻度标签的相关设置。
        textStyle: {
          fontSize: 10,
          color: "#fff",
        },
      },
    },
    series: series,
  };
  if (legends.length > 0) {
    option.grid.top = "25%";
  }
  if (_option) {
    Object.assign(option, _option);
  }
  return option;
}

export function buildBarStackOption(
  name,
  data,
  options,
  isStack,
  withTimeline
) {
  if (typeof isStack == "undefined") isStack = true;
  var series = [];
  let legends = [];
  if (isStack) {
    data.seriesData.forEach((item, idx) => {
      legends.push(item.name);
      series.push({
        name: item.name,
        type: "bar",
        stack: "总量",
        barWidth: item.barWidth || 20,
        // label: {
        //     show: true,
        //     position: 'insideRight'
        // },
        color: item.color,
        data: item.data,
      });
    });
  } else {
    data.seriesData.forEach((item, idx) => {
      legends.push(item.name);
      series.push({
        name: item.name,
        type: "bar",
        // label: {
        //     show: true,
        //     position: 'insideRight'
        // },
        color: item.color,
        data: item.data,
      });
    });
  }
  if (withTimeline) {
    series.push({
      name: "timeline",
      id: "timeline",
      type: "line",
      data: [],
      itemStyle: {
        color: "#f96332",
      },
      lineStyle: {
        width: 1,
      },
    });
  }

  var option = {
    title: {
      text: name,
      left: "left",
      textStyle: {
        fontSize: 12,
        color: "#fff",
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // 坐标轴指示器，坐标轴触发有效
        type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
      },
    },
    legend: {
      data: legends,
      left: "left",
      top: "30",
      textStyle: {
        fontSize: 12,
        color: "#fff",
      },
    },
    grid: {
      left: "6%",
      right: "6%",
      bottom: "40",
      top: "20%",
      containLabel: true,
    },
    xAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#fff",
        },
      },
      axisLabel: {
        //坐标轴刻度标签的相关设置。
        textStyle: {
          color: "#fff",
        },
      },
    },
    yAxis: {
      type: "category",
      data: data.categoryData,
      axisLine: {
        lineStyle: {
          color: "#fff",
        },
      },
      axisLabel: {
        //坐标轴刻度标签的相关设置。
        textStyle: {
          color: "#fff",
        },
      },
    },
    series: series,
  };
  if (options && options.xAxis) {
    Object.assign(option.xAxis, options.xAxis);
  }
  if (options && options.grid) {
    Object.assign(option.grid, options.grid);
  }
  if (options && options.yAxis) {
    Object.assign(option.yAxis, options.yAxis);
  }
  if (options && options.tooltip) {
    Object.assign(option.tooltip, options.tooltip);
  }
  if (options && options.dataZoom) {
    option.dataZoom = options.dataZoom;
  }

  return option;
}

export function buildBarStackYValueOption(name, data, options) {
  var series = [];
  let legends = [];
  data.seriesData.forEach((item, idx) => {
    legends.push(item.name);
    series.push({
      name: item.name,
      type: "bar",
      stack: "总量",
      label: {
        show: true,
        textStyle: { color: "#ffffff" },
      },
      barWidth: item.barWidth || 20,
      // label: {
      //     show: true,
      //     position: 'insideRight'
      // },
      color: item.color,
      data: item.data,
    });
  });

  var option = {
    title: {
      text: name,
      left: "left",
      textStyle: {
        fontSize: 12,
        color: "#fff",
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // 坐标轴指示器，坐标轴触发有效
        type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
      },
    },
    legend: {
      data: legends,
      left: "left",
      top: "30",
      textStyle: {
        fontSize: 12,
        color: "#fff",
      },
    },
    grid: {
      left: "6%",
      right: "6%",
      bottom: "40",
      top: "20%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      show: true,
      data: data.categoryData,
      axisLine: {
        lineStyle: {
          color: "#fff",
        },
      },
      axisLabel: {
        //坐标轴刻度标签的相关设置。
        rotate: "90",
        textStyle: {
          color: "#fff",
        },
      },
    },
    yAxis: {
      type: "value",
      //name: '坐标标题',
      show: true,
      axisLine: {
        lineStyle: {
          color: "#fff",
        },
      },
      axisLabel: {
        //坐标轴刻度标签的相关设置。
        textStyle: {
          color: "#fff",
        },
      },
    },
    series: series,
  };
  if (options && options.xAxis) {
    Object.assign(option.xAxis, options.xAxis);
  }
  if (options && options.grid) {
    Object.assign(option.grid, options.grid);
  }
  if (options && options.yAxis) {
    Object.assign(option.yAxis, options.yAxis);
  }
  return option;
}

export function buildlineOption(name, data, options, withTimeline) {
  var series = [];
  let legends = [];
  data.seriesData.forEach((item, idx) => {
    legends.push(item.name);
    series.push({
      name: item.name,
      type: "line",
      data: item.data,
      smooth: true,
      color: item.color,
      symbol: "none",
      markLine: !!item.markLine ? item.markLine : {},
      itemStyle: !!item.itemStyle ? item.itemStyle : {},
    });
  });
  if (withTimeline) {
    series.push({
      name: "timeline",
      id: "timeline",
      type: "line",
      data: [],
      itemStyle: {
        // color: {
        //     type: "linear",
        //     x: 0,
        //     y: 0,
        //     x2: 0,
        //     y2: 1,
        //     colorStops: [{
        //         offset: 0,
        //         color: "#1c344d"
        //     }, {
        //         offset: 1,
        //         color: "#298b91"
        //     }],
        //     global: false
        // }
        color: "#f96332",
      },
      lineStyle: {
        width: 1.5,
      },
    });
  }
  var option = {
    tooltip: {
      trigger: "axis",
      confine: true,
      // formatter: function (params, ticket, callback) {
      //     console.log(params,ticket, callback);

      // },
      formatter: function c(a) {
        var b = "";
        a.forEach(function (a, c) {
          if (a.seriesId != "timeline") {
            if (!b) {
              if (options.type == "reinforce") {
                //加固优先级
                b = "加固" + a.axisValue + "%建筑<br>";
              } else if (options.type == "recovery") {
                b = a.axisValue + "天<br>";
              } else {
                b = a.axisValue + "<br>";
              }
            }
            b += a.marker + a.seriesName + ":" + a.data + "<br>";
            // if (!b) {
            //     b = "<div style=\"border-bottom: 1px solid rgba(255,255,255,.3); font-size: 16px;padding-bottom: 7px;margin-bottom: 7px; color:yellow;\">" + (a.value[0] == 0 || a.value[0] == "0" ? "\u53D7\u707E\u5468" : "\u6062\u590D\u7B2C" + a.value[0] + "\u5468") + "</div>"
            // }
            // b += a.marker + a.seriesName + "\uFF1A" + (a.value[a.seriesIndex + 1] * 100).toFixed(2) + "%" + "<br>"
          }
        });
        return b;
      },
    },
    legend: {
      data: legends,
      left: "left",
      top: "30",
      textStyle: {
        fontSize: 12,
        color: "#fff",
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "10%",
      top: "110",
      containLabel: true,
    },
    title: {
      text: name,
      left: "left",
      textStyle: {
        fontSize: 12,
        color: "#fff",
      },
    },
    xAxis: {
      type: "category",
      data: data.categoryData,
      show: false,
      axisLine: {
        lineStyle: {
          color: "#fff",
        },
      },
      axisLabel: {
        //坐标轴刻度标签的相关设置。
        textStyle: {
          color: "#fff",
        },
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#fff",
        },
      },
      axisLabel: {
        textStyle: {
          fontSize: 12,
          color: "#fff",
        },
      },
    },
    series: series,
  };
  if (options && options.xAxis) {
    Object.assign(option.xAxis, options.xAxis);
  }
  if (options && options.yAxis) {
    Object.assign(option.yAxis, options.yAxis);
  }
  if (options && options.grid) {
    Object.assign(option.grid, options.grid);
  }
  return option;
}

export function buildLineAreaOption(echarts, data) {
  const color = ["#27d391", "#eddb76", "#fe3838", "#27d391"]
  const names = Object.keys(data)
  const values = Object.values(data)
  const option = {
    color,
    tooltip: {
      trigger: 'axis',
    },
    xAxis: {
      axisLabel: {
        show: true,
      },
      axisTick: {
        show: false
      },
      axisLine: {
        lineStyle: {
          color: 'rgba(151,184,216,0.5)',
          type: "dashed"
        }
      },
      type: 'category',
      boundaryGap: false,
      data: names,
    },
    yAxis: {
      axisLabel: {
        show:false
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: false,
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: 'rgba(151,184,216,0.5)',
          type: "dashed"
        }
      },
      splitNumber: 1,
    },
    grid: {
      top: 15,
      left: 20,
      right: 20,
      height: '60%',
    },
    brush: {
      toolbox: ['lineX'],
      brushMode: 'multiple',
      xAxisIndex: 0,
      brushStyle: {
        borderWidth: 0,
        color: 'rgba(255,255,255,0.2)'
      }
    },
    toolbox: {
      top: '-100px'
    },
    series: [{
        type: 'line',
        data: values,
        xAxisIndex: 0,
        yAxisIndex: 0,
        smooth: false,
        showSymbol: false,
        lineStyle: {
            color: '#92c690'
        },
        itemStyle: {
            normal: {
                color: '#92c690',
                borderColor: '#92c690'
            }
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: '#98f195'
          }, {
              offset: 1,
              color: '#98f19503'
          }])
        },
    }]
};
  return option
}

export function buildBarMultiOption(name, data, xNames, colors, barWidth, isRatio) {
  const names = Object.keys(data)
  const count_total = Object.values(data).reduce((pre, cur) => {
    const count = cur.reduce((p, c) => p + c, 0)
    return pre + count
  }, 0)
  const series = names.map((name, sid) => {
    return {
      name,
      type: 'bar',
      stack: "总量",
      barWidth: '60%',
      label: {
        show: false,
      },
      barWidth: barWidth || 20,
      color: colors[sid],
      data: data[name]
    };
  });
  const option = {
    title: {
      text: name,
      x: "center",
      textStyle: {
        fontSize: 12,
        color: '#fff'
      },
    },
    tooltip: {
      trigger: "axis",
      confine: true,
      axisPointer: {
        type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
      },
      formatter: function (data) {
        let tmpstr = ''
        for (let i = 0; i < data[0].axisValue.length; i++) {
          tmpstr += '&#' + data[0].axisValue.charCodeAt(i) + ';'
        }
        let str = tmpstr + "</br>"
        data.reverse().forEach((item, idx) => {
          if (idx == 0) { return; }
          if (isRatio) {
            str = str + item.marker + item.seriesName + " : " + item.data + "(" + (item.data / count_total * 100).toFixed(2) + "%)" + "</br>"
          } else {
            str = str + item.marker + item.seriesName + " : " + item.data + "</br>"
          }
        })
        return str
      }
    },
    legend: {
      data: names,
      left: "left",
      top: "30",
      textStyle: {
        fontSize: 12,
        color: "#fff",
      },
    },
    grid: {
      left: "6%",
      right: "6%",
      bottom: "40",
      top: "25%",
      containLabel: true,
    },
    yAxis: {
      type: "value",
      //name: '坐标标题',
      show: true,
      axisLine: {
        lineStyle: {
          color: "#fff",
        },
      },
      axisLabel: {
        //坐标轴刻度标签的相关设置。
        textStyle: {
          color: "#fff",
        },
      },
    },
    xAxis: {
      type: "category",
      show: true,
      data: xNames,
      axisLine: {
        lineStyle: {
          color: "#fff",
        },
      },
      axisLabel: {
        textStyle: {
          color: "#fff",
        },
      },
    },
    series
  };
  let tmpdata = []
  let maxvalues = [];
  Object.values(data).forEach((item, idx) => {
    item.forEach((it, ind) => {
      tmpdata[ind] += it
    })
  })
  tmpdata = tmpdata.map(it => it.toFixed(2))
  if (isRatio) {
    maxvalues = [...tmpdata]
  } else {
    maxvalues = data['恢复阶段']
  }
  option.series.push({
    legend: {
      show: false
    },
    barWidth: barWidth || 20,
    color: "#fff",
    data: maxvalues,
    label: {
      show: true,
      position: 'top',
      textStyle: {
        color: '#ffffff',
        fontSize: 10,
      },
      formatter: function (params) {
        if (isRatio) {
          const count = (params.value / count_total * 100).toFixed(2)
          return params.value + "(" + count + "%)"
        }
        return params.value
      }
    },
    name: "总计",
    stack: "all",
    type: "bar",
    z: -1,
    barGap: '-100%',
  })
  return option
}

export function buildLineMultiOption(data) {
  const legend = data.map(it => it.name)
  const nums = []
  data.forEach(item => {
    item.data.forEach(it => {
      nums.push(it.value[1])
    })
  })
  const option = {
    animation: false,
    legend: {
      data: legend,
      textStyle: {
        color: '#ffffff'
      }
    },
    tooltip: {
      trigger: 'axis',
      formatter: function (val) {
        let value0 = val[0].data['value'][0]
        let x = val[0].data['name'] + '+' + value0
        if (value0 <= 0) {
          x = val[0].data['name']
        }
        return val[0]['seriesName'] + ' (' + x + ',' + val[0].data['value'][1] + ')'
      }
    },
    grid: {
      top: '20%',
      left: '20',
      bottom: '30',
      right: '20',
      containLabel: true,
      textStyle: {
        fontSize: 12,
        color: "#fff"
      }
    },
    dataZoom: [{
      type: "inside"
    }],
    xAxis: [{
      name: '分钟',
      nameLocation: 'end',
      nameTextStyle: {
        padding: [0, 0, -40, -50], // 加上padding可以调整其位置
        verticalAlign: "bottom",
      },
      nameGap: 30,
      position: 'bottom',
      interval: 10,
      axisLabel: {
        show: true,
        textStyle: {
          color: '#ffffff'
        },
        interval: 0,
        formatter: function (value) {
          if (value < 0) return value / 10
          return value
        },
      },
      axisLine: {
        lineStyle: {
          color: '#ffffff' //更改坐标轴颜色
        }
      },
      splitLine: { show: false }
    },{
      name: '天',
      nameLocation: 'start',
      nameTextStyle: {
        padding: [0, -40, -40, 0], // 加上padding可以调整其位置
        verticalAlign: "bottom",
        color: '#ffffff'
      },
      nameGap: 30,
      position: 'bottom',
      axisLabel: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      splitLine: { show: false },
    }
  ],
    yAxis: {
      name: '消防安全指数',
      nameLocation: 'center',//坐标轴名称显示位置。
      nameGap: 30,
      min: Math.min(...nums) - 1,
      max: Math.max(...nums) + 1,
      axisLabel: {
        show: true,
        textStyle: {
          color: '#ffffff'
        }
      },
      axisLine: {
        lineStyle: {
          color: '#ffffff' //更改坐标轴颜色
        }
      },
      splitLine: { show: false }
    },
    series: data,
  };
  return option
}
