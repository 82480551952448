<template>
  <div class="row" style="margin:0 -30px;">
    <div class="col-md-9" style="padding:0">
      <card class="card-map">
        <!-- <div class="mask">
          <img src="img/loading.png" class="loading">
        </div>-->
        <div class="map-wrapper">
          <div id="map"></div>
          <canvas id="deck-canvas"></canvas>
        </div>
         <menu-map :map="map" :mapDefault="mapdefaultSetting"></menu-map>
        <div class="map_data_msg">
          <div class="map_data_legend">避难所容量</div>
          <div class="map_data_msg_wrapper">
            <div
              class="item"
              v-for="(item,index) in capacityOptions"
              :key="index"
              v-if="index>0"
            >
              <span :style="'background: '+item.color+';transform: scale('+(3-(index*0.5))*0.4+');'"></span>
              {{item.name}}
            </div>
          </div>
        </div>
        <div class="show_list">
          <a class="up_show" v-on:click="showList">
            <i
              class="now-ui-icons"
              :class="[ show_list ? 'arrows-1_minimal-down':'arrows-1_minimal-up']"
            ></i>
          </a>
          <a class="download ml-auto" :href="downloadUrl">
            <i class="now-ui-icons arrows-1_cloud-download-93"></i>
          </a>
          <span>
            数据列表
            <i>{{total}}</i>
          </span>
        </div>
        <div
          class="show_list_record"
          v-show="show_list"
          :style="{'margin-top': (data.length?-(140+data.length*38): 0)+'px'}"
          ref="show_list_record"
        >
          <el-table :data="data">
            <el-table-column label="小区ID" property="id"></el-table-column>
            <el-table-column label="街道" property="street_name"></el-table-column>
            <el-table-column label="社区" property="community_name"></el-table-column>
            <el-table-column label="分配避难所ID" property="shelter_id"></el-table-column>
            <el-table-column label="避难所名称" property="name"></el-table-column>
          </el-table>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class>
              <p class="card-category">显示： {{from + 1}} - {{to}} 总数： {{total}}</p>
            </div>
            <n-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              v-on:input="changePage"
            ></n-pagination>
          </div>
        </div>
      </card>
    </div>
     <div class="col-md-3 hasDecision" id="customStyle">
        <div class="col-md-12 clearfix key-decision" :style="{'height':selects.data_category == 'after_reinforce'?'200px':'70px'}">
          <el-collapse v-model="activeNameskey">
            <el-collapse-item title="决策信息" name="1">
              <div class="col-md-12 clearfix decision-wrapper">
                <div class="decision-block" @click = "modals.classic = true">
                  <span class="demonstration">避难所选择</span>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
          <!-- <div class="col-md-12 clearfix decision-wrapper">
            <label class="switch-label">决策信息</label>
            <div class="decision-block" @click = "modals.classic = true">
              <span class="demonstration">避难所选择</span>
            </div>
          </div> -->
        </div>
        <el-collapse v-model="activeNames" class="el-collapse-main">
          <!-- <el-collapse-item title="一致性 Consistency" name="1">
            <form>
              <div class="input-group no-border">
                <fg-input
                  placeholder="输入关键字..."
                  v-on:keyup.enter="reloadData"
                  v-model="keyword"
                  addon-right-icon="now-ui-icons ui-1_zoom-bold"
                ></fg-input>
                <div class="form-text" style="color:#999;margin-left:20px;">输入 建筑、街道、社区关键字</div>
              </div>
            </form>
          </el-collapse-item> -->
          
          <el-collapse-item title="信息图表" name="2">
            <div class="col-md-12">
              <p class="chart-total-msg"><strong>避难所总容量：{{totalUsage+totalNotUsage}}</strong><br>使用：{{totalUsage}} （{{totalUsageRate}}）<br>空置：{{totalNotUsage}} （{{totalNotUsageRate}}）</p>
              <div class="chart-area" id="box-chart" style="height:550px"></div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="叠加图层" name="3">
            <div class="col-md-12 clearfix">
              <checkbox 
                v-model="awayFromHome"
                label="离家住户"
                v-on:input="!awayFromHome"
              >离家住户</checkbox>
              <div class="overlap_layer_desc">
                <div class="item" v-for="(item,index) in awayFromHomeOptions" :key="index">
                  <span :style="'background: '+item.color+''"></span>
                  {{item.name}}
                </div>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="数据筛选" name="4">
            <div class="col-md-12">
              <el-select
                class="select-default"
                size="large"
                placeholder="街道"
                v-model="selects.street"
                v-on:change="reloadData('street')"
              >
                <el-option
                  v-for="option in selects.streets"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id"
                ></el-option>
              </el-select>
            </div>

            <div class="col-md-12">
              <el-select
                class="select-default"
                size="large"
                placeholder="社区"
                v-model="selects.community"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="option in selects.communities"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id"
                ></el-option>
              </el-select>
            </div>

            <div class="col-md-12">
              筛选避难所 <checkbox v-model="isCheckAllShelterForFilter" @input="handleCheckAllFilterChange"  >全选</checkbox>
              <checkbox v-for="(option,idx) in selects.shelters" v-model="selects.shelter[idx]" :key="option.name" :label="option.name" @input="reloadData">{{option.name}}</checkbox>
              <!-- <el-select
                class="select-default"
                size="large"
                multiple="" 
                placeholder="避难所"
                v-model="selects.shelter"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="option in selects.shelters"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id"
                ></el-option>
              </el-select> -->
            </div>
          </el-collapse-item>
        </el-collapse>
        
     
    </div>
    <modal :show.sync="modals.classic" headerclasses="justify-content-center">
      <h4 slot="header" class="title title-up">请选择至少12所避难所</h4>
        <div class="col-md-12">
          <p>已选{{getCheckedTotal()}}所避难所</p>
          <div>
            <checkbox v-model="isCheckAllShelter" @input="handleCheckAllChange"  >全选</checkbox>
          </div>
          <div class="checkbox-wrapper">
            <checkbox v-for="(option,idx) in shelters" v-model="shelter[idx]" :key="option.name" :label="option.name">{{option.name}}</checkbox>
          </div>
        </div>


        
        <!-- <el-select
          class="select-default"
          size="large"
          multiple="" 
          placeholder="避难所"
          v-model="shelter"
        >
          <el-option
            v-for="option in shelters"
            class="select-primary"
            :value="option.id"
            :label="option.name"
            :key="option.id"
          ></el-option>
        </el-select> -->
      <p class="shelter-error-msg" v-show="showErrMsg">请选满12所避难所</p>
      <template slot="footer">
        
        <n-button type="default" @click.native="modals.classic = false">关闭</n-button>
        <n-button type="primary" @click.native="confirm">确认选择</n-button>
      </template>
    </modal>
    <div class="key-infos">
      <div class="key-info">
        需避难人口总数{{statistics.persons}}
      </div>
     
    </div>
  </div>  
</template>
<script>
import { Deck } from "@deck.gl/core";
import { GeoJsonLayer, ArcLayer } from "@deck.gl/layers";
import mapboxgl from "mapbox-gl";
import MenuMap from "src/components/Menumap";
import MiniMap from "src/mapbox/mapboxgl-minimap.js";
import { Modal,TabPane, Tabs, Switch } from "src/components";
import geobuf from "geobuf";
import Pbf from "pbf";
import { Radio } from "src/components/index";
import { Checkbox } from "src/components/index";
import { Input, Button, Select, Option,  Collapse, CollapseItem, Table, TableColumn } from "element-ui";
import { consts } from "src/util/consts.js";
import {
  getCategories,
  getShelterStreets,
  getShelters,
  getSheltersPlan,
  getShelterCommunities,
  getShelterList,
  getGeoJson,
  getShelterGeojson,
  getShelterLineGeojson,
  getShelterBlockGeojson,
  getShelterStatistics
} from "src/api/building.js";
import { byShelterUsage } from "src/api/chart.js";
import {
  buildBarStackOption,
  buildlineOption,
  buildPieOption
} from "src/util/chart.js";
import {
  getBuildYearRange,
  storeMapOptions,
  getMapOptions,
  parseJsonToString
} from "src/util/common.js";
import { Pagination as NPagination } from "src/components";
import {
  getPeopleStat,
  getGenderStat,
  getHideaways,
  getHospitals,
  getWarehouses
} from "src/api/cityinfo.js";

import * as THREE from "three";
//import geoJsonBasic from "./GeoJson-basic"; // 基本建筑数据from2.2-2.3
//import geoJson231 from "./GeoJson-2.3.1"; // 基本建筑数据from2.3.1

export default {
  components: {
    MenuMap,
    TabPane,
    Tabs,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    NPagination,
    [Switch.name]: Switch,
    Checkbox,
    // [Checkbox.name]: Checkbox,
    // [CheckboxGroup.name]: CheckboxGroup,
    Radio,
    Modal
  },
  data() {
    return {
      activeNameskey: ['1'],
      activeNames: ['2','3','4'],
      activeName: "first",
      tabs: {
        horizontal: "Profile",
        vertical: "Options",
        verticalPills: "Home",
        pageCategories: "Messages"
      },
      selects: {
        street: 0,
        streets: [{ id: 0, name: "街道" }],
        community: 0,
        communities: [{ id: 0, name: "社区" }],
        struct: 0,
        structs: consts.buildingStructs,
        shelter: [],
        shelters: [{ id: 0, name: "避难所" }],
        category: 0,
        categories: consts.buildingCategories,
        quakeLevel: 0,
        quakeLevels: [
          { id: 0, name: "抗震设防烈度" },
          { id: 6, name: 6 },
          { id: 7, name: 7 },
          { id: 8, name: 8 }
        ],
        buildYear: 0,
        buildYears: consts.buildingBuildYears,
      },
      keyword: "",
      total: 0,
      data: [],
      page: 1,
      map: {},
      color_category: "struct",
      city_id: localStorage.getItem("city_id") || 1,
      show_list: false,
      show_list_height: 0,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 20
      },
      switchs: {
        people: true,
        gender: true,
        hideaway: true,
        hospital: true,
        warehouse: true
      },
      last_updated: consts.last_updated,
      colors: {
        'shelter': ["#F5C243", "#62BFF5", "#ffc000"],
      },
      capacity: 0,
      capacityOptions: [
        { id: 0, name: "避难所容量" },
        { id: 1, name: ">3000", color: "#ff0000" },
        { id: 2, name: "2000-3000", color: "#ed7d31" },
        { id: 3, name: "1000-2000", color: "#ffc000" }
      ],
      awayFromHome: false, //是否叠加离家住户数
      awayFromHomeOptions: [
        { id: 1, name: "小于500", color: "#FDF1D0" },
        { id: 2, name: "500-700", color: "#CEE8B9" },
        { id: 3, name: "700-800", color: "#94CFBF" },
        { id: 4, name: "800-1200", color: "#62BFF5" },
        { id: 5, name: "1200-1500", color: "#447DB3" },
        { id: 6, name: "1500以上", color: "#242F7F" },
      ],
      modals: {
        classic: false,
        isIndeterminate: false,//是否决定
        checkAll:false, //全选
      },
      showErrMsg: false,
      shelter: [],//弹窗
      shelters: [{ id: 0, name: "避难所" }],
      isCheckAllShelter: true,
      isCheckAllShelterForFilter: true,
      totalUsage:0, //图表额外信息
      totalUsageRate:0,
      totalNotUsage:0,
      totalNotUsageRate:0,
      statistics: {
        persons: ''
      },
      mapdefaultSetting: {},
      downloadUrl: ''
    };
  },
  watch: {
    awayFromHome(val) {
      //开关叠加涂层
      this.toggleMapLayer('awayFromHome')
    },
    "shelter"(val){
      // let tmpvar = Array.from(val);
      // tmpvar.sort((a,b)=>a - b)
      let _this = this;
      _this.selects.shelters = [];
      val.forEach((element,idx) => {
        //_this.selects.shelters.push(..._this.shelters.filter(item=>item.id==element))
        //_this.selects.shelters.push(..._this.shelters.filter(item=>element===true))
        if(element === true) {
          _this.selects.shelters.push(_this.shelters[idx])
        }
        
      });
      _this.selects.shelter = val.filter(item=>item===true);
      console.log(this.selects.shelters,this.selects.shelter)
    },
    "selects.street"(val) {
      //街道切换选社区
      this.fillCommunities(this.city_id, val);
    }
  },
  mounted() {
    let _this = this;
    this.fillStreets(this.city_id);
    //this.fillCommunities(this.city_id, 0);
    
    
    //this.fillBuildings(this.city_id, 1);
    this.setTipContent();
    this.initMap(this.city_id);
    //this.fillBuildingsMap(this.city_id, this.color_category);
    //this.addPointLayerToMap(this.map, "hospital");
    //this.addPointLayerToMap(this.map, "hideaway");
    //this.addPointLayerToMap(this.map, "warehouse");
    //this.loadData(this.data_type);
    this.map.on("load", function() {
      _this.modals.classic = true;
      _this.fillShelters(_this.city_id);
      _this.showChart(_this.city_id);
      // if(!sessionStorage.getItem("checkedshelters")) {
      //   _this.modals.classic = true;
      // } else {
      //   _this.modals.classic = false;
      //   //this.getPlanId()
      // }
    });
    
  },

  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    // isCheckAllShelter() {
    //   return this.getCheckedTotal() == this.shelters.length
    // }
  },
  methods: {
    // 初始化
    initMap(city_id) {
      let self = this;
      this.mapdefaultSetting = {
        center: consts.mapCenter[this.city_id-1],
        zoom: consts.mapDefaultZoom,
        pitch: 0,
        city_id: this.city_id
      }
      mapboxgl.accessToken = consts.mapboxAccessToken;
      let map_options = getMapOptions();
      //console.log(map_options);
      let zoom = map_options ? map_options.zoom : 12;
      let center = map_options
        ? [map_options.lng, map_options.lat]
        : consts.mapCenter[city_id - 1];
      var map = new mapboxgl.Map({
        container: "map",
        style: consts.mapBoxStyle,
        //center: [-87.61694, 41.86625],
        center: center,
        zoom: zoom,
        pitch: 0,
        bearing: 0,
        attributionControl: false
      });
      this.map = map;
      //定位图标
      map.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl
        })
      );
      this.map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: true
        })
      );
      map.on("load", function(){
        //self.reloadData()
      })
      //控制图层
      var nav = new mapboxgl.NavigationControl();
      this.map.addControl(nav, "top-right");
      //全屏按钮
      this.map.addControl(
        new mapboxgl.FullscreenControl({
          container: document.querySelector("body")
        })
      );
      
      //小地图
      //https://github.com/brendanmatkin/mapboxgl-minimap
      map.on("style.load", function() {
        // Possible position values are 'bottom-left', 'bottom-right', 'top-left', 'top-right'
        map.addControl(
          new MiniMap({
            id: "mapboxgl-minimap",
            width: "200px",
            height: "150px",
            //style: "mapbox://styles/mapbox/streets-v10",
            style: consts.mapBoxStyle,
            fillColor: "#f58220",
            zoomAdjust: null
          }),
          "bottom-right"
        );
      });
      //记录地图设置
      map.on("move", function() {
        storeMapOptions({
          lng: map.getCenter().lng,
          lat: map.getCenter().lat,
          zoom: map.getZoom()
        });
        self.deck.setProps({
          viewState: {
            latitude: map.getCenter().lat,
            longitude: map.getCenter().lng,
            zoom: map.getZoom(),
            bearing: map.getBearing(),
            pitch: map.getPitch()   
          }
        })
      });
    },
    addBuildings2Map(map, geoJsonData) {
      let layer_id = "room-extrusion";
      let source_name = "geojson";
      var layer = map.getLayer(layer_id);
      if (layer) {
        map.removeLayer(layer_id);
        map.removeSource(source_name);
      }
      
      
      map.addSource(source_name, {
        type: "geojson",
        data: geoJsonData
      });
      let colorExpress = ["get", "color"];
      if(this.selects.data_category == "before_reinforce") {
        colorExpress = [
          "match",
          ["get", "加固优先级"],
          '高',
          "#ff0000",
          '中',
          "#ed7d31",
          '低',
          "#ffc000",
          "#ff0000"
        ];
      } else if(this.selects.data_category == "after_reinforce") {
        
      }
      map.addLayer({
        id: layer_id,
        type: "fill-extrusion",
        source: source_name,
        //'source': {
        // GeoJSON Data source used in vector tiles, documented at
        // https://gist.github.com/ryanbaumann/a7d970386ce59d11c16278b90dde094d
        //  'type': 'geojson',
        //  'data':geoJsonData,
        //'data': "http://api.urban.test/api/buildings-geo-json?struct_id=2&category_id=1&street_id=1"
        //'data': "https://docs.mapbox.com/mapbox-gl-js/assets/indoor-3d-map.geojson"
        //},
        paint: {
          // See the Mapbox Style Specification for details on data expressions.
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions

          // Get the fill-extrusion-color from the source 'color' property.
          "fill-extrusion-color": colorExpress,

          // Get fill-extrusion-height from the source 'height' property.
          "fill-extrusion-height": ["get", "height"],

          // Get fill-extrusion-base from the source 'base_height' property.
          "fill-extrusion-base": ["get", "base_height"],

          // Make extrusions slightly opaque for see through indoor walls.
          "fill-extrusion-opacity": 0.8
        }
      });
    },
    showList() {
      this.show_list = !this.show_list;
    },
    donwloadList() {},
    showChart(city_id) {
      let _this = this;
      var container = document.querySelector('.el-collapse-main');
      var box = document.getElementById("box-chart");
      box.style.width = container.offsetWidth + "px";
      this.myChart = this.$echarts.init(box);
      document.querySelector('.key-decision').style.width = document.querySelector('#customStyle').offsetWidth +'px'
      window.onresize = function() {
        document.querySelector('.key-decision').style.width = document.querySelector('#customStyle').offsetWidth +'px'
        document.querySelectorAll('.chart-area').forEach((item,idx)=>{
          item.style.width = document.querySelector('.el-collapse-main').offsetWidth+'px';
        })
        _this.myChart.resize(); 
      }
      
    },
    updateChart() {
      let _this = this;
      //byShelterUsage(this.city_id,this.planId).then(res=>{
      byShelterUsage(this.city_id,this.selectedString).then(res=>{
        var chart_data = res.data;
        var chart_name = "避难所使用情况";
        chart_data.seriesData.map((item,idx) => {
          return item.color = _this.colors['shelter'][idx]
        })
        var option = buildBarStackOption(chart_name, chart_data,{xAxis: {
            show: true,
            name: '使用/空置容量',
            nameLocation: 'center',//坐标轴名称显示位置。
            nameGap: 30,
            
          }});
        this.myChart.setOption(option);
        this.totalUsage = chart_data.totalData[0];
        this.totalNotUsage = chart_data.totalData[1];
        this.totalUsageRate = (this.totalUsage/(this.totalUsage+this.totalNotUsage)*100).toFixed(2) + '%';
        this.totalNotUsageRate = (this.totalNotUsage/(this.totalUsage+this.totalNotUsage)*100).toFixed(2) + '%';
      });
    },
    fillShelters(city_id){
      getShelters(city_id).then(res => {
        let data = res.data;
        this.shelters = data;
        if(sessionStorage.getItem("checkedshelters")) {
          this.shelter = JSON.parse(sessionStorage.getItem("checkedshelters"))
          this.$nextTick(()=>{
            this.getPlanId()
          })
          
        } else {
          // this.shelters.forEach((item, idx) => {
          //   this.shelter.push(item.id)
          // });
          
          this.shelter = new Array(this.shelters.length).fill(true);
          console.log(this.shelter)
        }
      });
    },
    fillStreets(city_id) {
      getShelterStreets(city_id).then(res => {
        let data = res.data;
        data.unshift({ id: 0, name: "街道" });
        this.selects.streets = data;
      });
    },
    fillCommunities(city_id, street_id) {
      getShelterCommunities(city_id, street_id).then(res => {
        let data = res.data;
        data.unshift({ id: 0, name: "社区" });
        this.selects.community = 0;
        this.selects.communities = data;
      });
    },
    fillPeopleData(page) {
      let params = this.buildParams();
      params.page = page;
      getPeopleStat(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillGenderData(page) {
      let params = this.buildParams();
      params.page = page;
      getGenderStat(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillHospitalsData(city_id, page) {
      let params = this.buildParams();
      params.page = page;
      getHospitals(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillHideawaysData(page) {
      let params = this.buildParams();
      params.page = page;
      getHideaways(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillWarehousesData(page) {
      let params = this.buildParams();
      params.page = page;
      getWarehouses(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillBuildings(city_id, page) {
      let params = this.buildParams();
      params.page = page;
      params.page_size = 10;
      getShelterList(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
      getShelterStatistics(params).then(res => {
        this.statistics.persons = res.data.persons;
      });
      this.downloadUrl = consts.requestUrl + consts.exportShelter + parseJsonToString(params)
    },
    fillBuildingsMap(city_id, color_category) {
      let params = this.buildParams();
      params.color_category = color_category;
      // getGeoJson(params).then(res => {
      //   let geoJson = res.data;
      //   this.addBuildings2Map(this.map, geoJson);
      // });

      //临时
      if(this.selects.data_category == "before_reinforce") {
        fetch('/geojson/GeoJson-2.3.1.json').then(res => res.json())
        .then(json => {
          let geoJson = json;
          this.addBuildings2Map(this.map, geoJson);
        })
        
      }
    },
    fillBlockmapLayer(city_id, type, layer_name){
      if(!layer_name) layer_name = type;
      let params = this.buildParams();
      //获取叠加小区坐标
      getShelterBlockGeojson(params).then(data => {
        //let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        let geoJson = data.data;
        this.addBlockmapLayer(this.map, layer_name,geoJson);
      });
      
      // getGeoJson(params).then(res => {
      //   let geoJson = res.data;
      //   //mock polygon数据 for 离家住户
      //     geoJson.features.map((item, idx) => {
      //       return (item.properties.population = parseInt(
      //         Math.random() * 2000
      //       ));
      //     });

      //   this.addBlockmapLayer(this.map, layer_name, geoJson);
        
      // });

      //临时
      // fetch('/geojson/GeoJson-block.json').then(res => res.json())
      // .then(json => {
      //   let geoJson = json;
      //   //mock polygon数据 for 离家住户
      //   geoJson.features.map((item, idx) => {
      //     return (item.properties.population = parseInt(
      //       Math.random() * 2000
      //     ));
      //   });
      //   this.addBlockmapLayer(this.map, layer_name,geoJson);
      // })
      
    },
    fillArcLayer(){
      let params = this.buildParams();
      //获取避难所飞线坐标
      getShelterLineGeojson(params).then(data => {
        let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        this.addArcLayer(this.map, geoJson)
      });
      
    },
    fillPointLayer(){
      let params = this.buildParams();
      //获取避难所坐标
      getShelterGeojson(params).then(data => {
        let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        this.addAnimatePointLayerToMap(this.map, geoJson)
      });
      
      
    },
    reloadData(type) {
      if(type == 'street') {
        this.selects.community = 0;
      }
      
      this.fillBuildings(this.city_id, 1);
      this.fillBuildingsMap(this.city_id, this.color_category);
      this.fillArcLayer();
      this.fillPointLayer();
      if(this.awayFromHome){
        this.fillBlockmapLayer(this.city_id,'awayFromHome','block');
      }       
      //this.loadData(this.data_type);
    },
    setTipContent() {
      let html = ''
      html = consts.tipsShelter;
      document.getElementById('left-tip-content').innerHTML = html
    },
    buildParams() {
      let params = { city_id: this.city_id };
      params.street_id = this.selects.street;
      params.community_id = this.selects.community;
      params.keyword = this.keyword;
      params.decision = this.selectedString;
      let idsArr = []
      this.selects.shelters.forEach((item,idx)=>{
        if(this.selects.shelter[idx]) {
          idsArr.push(item.id)
        }
      })

      if(idsArr.length == 0) {
        params.shelter_ids = '100' //全部选，传个不存在的
      } else {
        params.shelter_ids = idsArr.join(',');
      }

      //params.shelter_ids = idsArr.join(',');
     
      return params;
    },
    changePage: function(value) {
      this.fillBuildings(this.city_id, value);
    },
    addArcLayer: function(map,geojson){
      let _this = this;
      if(typeof geojson.features == 'undefined') {
        geojson.features = [];
      }
      geojson = geojson.features;
      geojson.forEach((item,idx) => {
        if(item.properties.persons <= 2000){
          item.properties.color = [255, 192, 0]
        } else if(item.properties.persons <= 3000){
          item.properties.color = [237, 125, 49]
        } else {
          item.properties.color = [255, 0, 0]
        }
        //item.properties.color = [78,173,234]
      })
      //飞线
      //const geoJson = "https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_airports.geojson";  
      // let geoJson = [
      //         {"type":"Feature","properties":{"lng":120.1964333,"lat": 30.23462552,"color":[194,214,236]},"geometry":{"type":"Point","coordinates":[120.16682548,30.24599102]}},
      //         {"type":"Feature","properties":{"lng":120.1964333,"lat": 30.23462552,"color":[194,214,236]},"geometry":{"type":"Point","coordinates":[120.16817525,30.24700676]}},
      //         {"type":"Feature","properties":{"lng": 120.1964333,"lat": 30.23462552,"color":[194,214,236]},"geometry":{"type":"Point","coordinates":[120.16877076,30.24572819]}}
      //       ]   
      if(!this.deck) {
        this.deck = new Deck({
        canvas: "deck-canvas",
        width: "100%",
        height: "100%",
        initialViewState: {
          latitude: this.map.getCenter().lat,
          longitude: this.map.getCenter().lng,
          zoom: this.map.getZoom(),
          bearing: 0,
          pitch: 0
        },
        controller: true,
        onViewStateChange: ({ viewState }) => {
          console.log(viewState)
          this.map.jumpTo({
            center: [viewState.longitude, viewState.latitude],
            zoom: viewState.zoom,
            bearing: viewState.bearing,
            pitch: viewState.pitch
          });
        },
        layers: [
          new GeoJsonLayer({
            id: "airports",
            data: geojson,
            // Styles
            filled: true,
            pointRadiusMinPixels: 2,
            pointRadiusScale: 2,
            getRadius: f => 11 - f.properties.scalerank,
            getFillColor: [194, 214, 236, 180],
            // Interactive props
            pickable: true,
            autoHighlight: true,
            onClick: info =>{
              // eslint-disable-next-line
              // info.object &&
              // alert(
              //   `${info.object.properties.name} (${info.object.properties.abbrev})`
              // )  
            }
          }),
          new ArcLayer({
            id: "arcs",
            data: geojson,
            //dataTransform: d => d.features.filter(f => f.properties.natlscale < 4),
            // Styles
            getSourcePosition: f => [f.properties.lng,f.properties.lat], // London
            getTargetPosition: f => f.geometry.coordinates,
            getSourceColor: f => f.properties.color,
            getTargetColor: f => f.properties.color,
            getWidth: 1
          })
        ]
      });
    } else {
        this.deck.setProps({
          layers: [
            new GeoJsonLayer({
              id: _this.getMapLayerId("airports"),
              data: geojson,
              // Styles
              filled: true,
              pointRadiusMinPixels: 2,
              pointRadiusScale: 2,
              getRadius: f => 11 - f.properties.scalerank,
              getFillColor: [194, 214, 236, 180],
              // Interactive props
              pickable: true,
              autoHighlight: true,
              onClick: info =>{
                // eslint-disable-next-line
                // info.object &&
                // alert(
                //   `${info.object.properties.name} (${info.object.properties.abbrev})`
                // )  
              }
            }),
            new ArcLayer({
              id: _this.getMapLayerId("arcs"),
              data: geojson,
              //dataTransform: d => d.features.filter(f => f.properties.natlscale < 4),
              // Styles
              getSourcePosition: f => [f.properties.lng,f.properties.lat], // London
              getTargetPosition: f => f.geometry.coordinates,
              getSourceColor: f => f.properties.color,
              getTargetColor: f => f.properties.color,
              getWidth: 1
            })
          ]
        })
      } 
    },
    addHeatmapLayer(map, type) {
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      let layer_point_id = layer_id + "point";
      // Add a geojson point source.
      // Heatmap layers also work with a vector tile source.
      map.addSource(source_name, {
        type: "geojson",
        data: "https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson"
      });

      map.addLayer(
        {
          id: layer_id,
          type: "heatmap",
          source: source_name,
          maxzoom: 9,
          paint: {
            // Increase the heatmap weight based on frequency and property magnitude
            "heatmap-weight": [
              "interpolate",
              ["linear"],
              ["get", "mag"],
              0,
              0,
              6,
              1
            ],
            // Increase the heatmap color weight weight by zoom level
            // heatmap-intensity is a multiplier on top of heatmap-weight
            "heatmap-intensity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              0,
              1,
              9,
              3
            ],
            // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
            // Begin color ramp at 0-stop with a 0-transparancy color
            // to create a blur-like effect.
            "heatmap-color": [
              "interpolate",
              ["linear"],
              ["heatmap-density"],
              0,
              "rgba(33,102,172,0)",
              0.2,
              "rgb(103,169,207)",
              0.4,
              "rgb(209,229,240)",
              0.6,
              "rgb(253,219,199)",
              0.8,
              "rgb(239,138,98)",
              1,
              "rgb(178,24,43)"
            ],
            // Adjust the heatmap radius by zoom level
            "heatmap-radius": [
              "interpolate",
              ["linear"],
              ["zoom"],
              0,
              2,
              9,
              20
            ],
            // Transition from heatmap to circle layer by zoom level
            "heatmap-opacity": ["interpolate", ["linear"], ["zoom"], 7, 1, 9, 0]
          }
        },
        "waterway-label"
      );

      map.addLayer(
        {
          id: layer_point_id,
          type: "circle",
          source: source_name,
          minzoom: 7,
          paint: {
            // Size circle radius by earthquake magnitude and zoom level
            "circle-radius": [
              "interpolate",
              ["linear"],
              ["zoom"],
              7,
              ["interpolate", ["linear"], ["get", "mag"], 1, 1, 6, 4],
              16,
              ["interpolate", ["linear"], ["get", "mag"], 1, 5, 6, 50]
            ],
            // Color circle by earthquake magnitude
            "circle-color": [
              "interpolate",
              ["linear"],
              ["get", "mag"],
              1,
              "rgba(33,102,172,0)",
              2,
              "rgb(103,169,207)",
              3,
              "rgb(209,229,240)",
              4,
              "rgb(253,219,199)",
              5,
              "rgb(239,138,98)",
              6,
              "rgb(178,24,43)"
            ],
            "circle-stroke-color": "white",
            "circle-stroke-width": 1,
            // Transition from heatmap to circle layer by zoom level
            "circle-opacity": ["interpolate", ["linear"], ["zoom"], 7, 0, 8, 1]
          }
        },
        "waterway-label"
      );
    },
    makePointImage(map, type){
      let size = 150;
      switch(type){
        case 1:
          size = 80;
        break;
        case 2:
          size = 120;
        break;
        case 3:
          size = 160;
        break;
      }
      //point type
      let pulsingDot  = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // get rendering context for the map canvas when layer is added to the map
        onAdd: function() {
          var canvas = document.createElement("canvas");
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext("2d");
        },

        // called once before every frame where the icon will be used
        render: function() {
          var duration = 1000;
          var t = (performance.now() % duration) / duration;

          var radius = (size / 2) * 0.3;
          var outerRadius = (size / 2) * 0.7 * t + radius;
          var context = this.context;

          // draw outer circle
          context.clearRect(0, 0, this.width, this.height);
          context.beginPath();
          context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2
          );
          switch(type){
            case 1:
              context.fillStyle = "rgba(255, 192, 0," + (1 - t) + ")";
            break;
            case 2:
              context.fillStyle = "rgba(237, 125, 49," + (1 - t) + ")";
            break;
            case 3:
              context.fillStyle = "rgba(255, 0, 0," + (1 - t) + ")";
            break;
          }
          
          context.fill();

          // draw inner circle
          context.beginPath();
          context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
          switch(type){
            case 1:
              context.fillStyle = "rgba(255, 192, 0, 1)";
            break;
            case 2:
              context.fillStyle = "rgba(237, 125, 49, 1)";
            break;
            case 3:
              context.fillStyle = "rgba(255, 100, 100, 1)";
            break;
          }
          
          context.strokeStyle = "white";
          context.lineWidth = 2 + 4 * (1 - t);
          context.fill();
          //context.stroke();
          // update this image's data with data from the canvas
          this.data = context.getImageData(0, 0, this.width, this.height).data;

          // continuously repaint the map, resulting in the smooth animation of the dot
          map.triggerRepaint();

          // return `true` to let the map know that the image was updated
          return true;
        }
      }
      map.addImage("pulsing-dot-"+type, pulsingDot, { pixelRatio: 2 });
    },
    addAnimatePointLayerToMap(map, geojson){
      if(!map.hasImage("pulsing-dot-1")) {
        this.makePointImage(map,1) //1000-2000
        this.makePointImage(map,2) //2000-3000
        this.makePointImage(map,3) //>3000
      }
      if(map.getLayer('points')){
        map.removeLayer('points')
        map.removeSource('points')
      }
      if(typeof geojson.features == "undefined") return;
      geojson.features.forEach((item,idx)=>{
        if(item.properties.persons <= 2000){
          item.properties.type = 1
        } else if(item.properties.persons <= 3000){
          item.properties.type = 2
        } else {
          item.properties.type = 3
        }
      })
      map.addSource("points", {
        type: "geojson",
        data: geojson
        // data: {
        //   type: "FeatureCollection",
        //   features: [
        //     {
        //       type: "Feature",
        //       geometry: {
        //         type: "Point",
        //         coordinates: consts.quakeLocation
        //       }
        //     }
        //   ]
        // }
       }
      );
      map.addLayer({
        id: "points",
        type: "symbol",
        source: "points",
        paint: {
          "text-color": "#ffffff"
        },
        layout: {
          "icon-image": ["concat", "pulsing-dot-", ["get","type"]],
          "text-field": ["get", "name"],
          "text-size": 12,
          "text-offset": [0, -3],
          "text-anchor": "top"
        }
      });
    },
    addPointLayerToMap(map, type) {
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      let geojson_url,
        icon = "";
      switch (type) {
        case "hospital":
          geojson_url = consts.url_geojson_hospital;
          icon = "hospital-15";
          break;
        case "hideaway":
          geojson_url = consts.url_geojson_hideaway;
          icon = "school-15";
          break;
        case "warehouse":
          geojson_url = consts.url_geojson_warehouse;
          icon = "parking-15";
          break;
      }
      if (geojson_url == "") {
        return;
      }
      var styleLoadStatus = map.isStyleLoaded();
      if (styleLoadStatus) {
        map.addSource(source_name, {
          type: "geojson",
          data: geojson_url
        });
        map.addLayer({
          id: layer_id,
          type: "symbol",
          source: source_name,
          layout: {
            "icon-image": icon,
            "text-field": "{name}",
            //"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-offset": [0, 0.6],
            "text-anchor": "top"
          }
        });
        return;
      }
      map.on("style.load", function() {
        map.addSource(source_name, {
          type: "geojson",
          data: geojson_url
        });
        map.addLayer({
          id: layer_id,
          type: "symbol",
          source: source_name,
          layout: {
            "icon-image": icon,
            "text-field": "{name}",
            //"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-offset": [0, 0.6],
            "text-anchor": "top"
          }
        });
      });
    },
    addBlockmapLayer(map, type, geoJsonData) {
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      //let source_name_stroke = source_name+'_stroke';
      let layer_id_stroke = layer_id+'_stroke';
      map.addSource(source_name, {
        type: "geojson",
        data: geoJsonData
        //data: "./geojson/GeoJson-block.geojson"
      });
      map.addLayer({
        id: layer_id,
        type: "fill",
        source: source_name,
        layout: {},
        paint: {
          "fill-color": [
            "step",
            ["get", "number"],
            "#FDF1D0", 500,
            "#CEE8B9", 700,
            "#94CFBF", 800,
            "#62BFF5", 1200,
            "#447DB3", 1500,
            "#242F7F" // other
          ]
        }
      });
      //描边
      map.addLayer({
        id: layer_id_stroke,
        type: "line",
        source: source_name,
        layout: {},
        paint: {
          "line-color": '#000000',
          "line-width": 1
        }
      });
      //离家人口分布要在
      var layer = map.getLayer('points');
      if (layer) {
        this.map.moveLayer(this.getMapLayerId(type), 'points');
      }
    },
    toggleMapLayer(type) {
      if (this[type]) {
        //勾选
        this.fillBlockmapLayer(this.city_id,type,'block');
      } else {  
        this.removeMapLayer(this.map, 'block');
      }
    },
    handelMapLayer(type) {
      //console.log(type);
      this.selects.street = 0;
      this.selects.community = 0;
      this.loadData(type);
      let types = ["hospital", "hideaway", "warehouse"];
      types.forEach(value => {
        if (value == type) {
          this.addPointLayerToMap(this.map, value);
        } else {
          this.removeMapLayer(this.map, value);
        }
      });
    },
    getMapLayerId(type) {
      let layer_id = type + "-layer";
      return layer_id;
    },
    getMapSourceName(type) {
      let source_name = type + "-data";
      return source_name;
    },
    getPlanId(){
      let _this = this;
      
      //建个新数组，把选中的塞进去
      this.selectedString = new Array(this.shelters.length).fill(0)
      this.shelter.forEach((item,idx)=>{
        //this.selectedString[item-1] = 1;
        this.selectedString[idx] = item?1:0;
      })
      this.selectedString = this.selectedString.join("");
      this.updateChart();
      this.fillBuildings(this.city_id, 1);
      
      if(this.map.loaded()) {
        _this.fillArcLayer()
        _this.fillPointLayer()
      } else {
        this.map.on("style.load", function(){
          //设置方案小区对应避难所
          _this.fillArcLayer()
          _this.fillPointLayer()
       })
      }

      //设置方案小区对应避难所
      // _this.fillArcLayer()
      // _this.fillPointLayer()
      
      
      
      // getSheltersPlan(this.city_id, newAarray.join("")).then(res => {
      //   debugger;
      //   this.planId = res.data;
      //   //获得方案
      //   //设置图表的避难所用量
      //   this.updateChart();
        
      //   this.map.on("load", function(){
      //     //设置方案小区对应避难所
      //     _this.fillPointLayer()
          
      //   })
      //   this.fillArcLayer()
      // });
    },
    removeMapLayer(map, type) {
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      var layer = map.getLayer(layer_id);
      //描边
      var layer_stroke = map.getLayer(layer_id+'_stroke');
      
      if (layer) {
        map.removeLayer(layer_id);
        if(layer_stroke){
          map.removeLayer(layer_id+'_stroke');
        }
        map.removeSource(source_name);
      }
      
      
    },
    removeHeatmapLayer(map, type) {
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      let layer_point_id = layer_id + "point";
      var layer = map.getLayer(layer_id);
      if (layer) {
        map.removeLayer(layer_id);
        map.removeLayer(layer_point_id);
        map.removeSource(source_name);
      }
    },
    loadData(type, page = 1) {
      switch (type) {
        case "people":
          this.fillPeopleData(page);
          break;
        case "gender":
          this.fillGenderData(page);
          break;
        case "hospital":
          this.fillHospitalsData(page);
          break;
        case "hideaway":
          this.fillHideawaysData();
          break;
        case "warehouse":
          this.fillWarehousesData();
          break;
      }
    },
    getCheckedTotal(){
      var tmp = 0;
      this.shelter.forEach(item=>{
        if(item === true){
          tmp++
        }
      })
      if(tmp == this.shelters.length) this.isCheckAllShelter = true;
      else this.isCheckAllShelter = false;
      return tmp;
    },
    
    confirm () {
      if(this.getCheckedTotal()<12) {
        this.showErrMsg = true;
        return;
      }
      this.modals.classic = false;
      this.showErrMsg = false;
      sessionStorage.setItem("checkedshelters",JSON.stringify(this.shelter));
      this.getPlanId();
      
    },
    handleCheckAllChange (val) {
      this.shelter.forEach((item,idx)=>{
        this.shelter[idx] = val;
      })
    },
    handleCheckAllFilterChange (val) {
      this.selects.shelter.forEach((item,idx)=>{
        this.selects.shelter[idx] = val;
      })
      this.$nextTick(()=>{
        this.reloadData();
      })
      
    }
  }
};
</script>



<style lang="scss" scoped>
.card-map {
  min-height: 350px;
  height: calc(100vh - 48px);
  margin-bottom: 0;

  position: relative;
  .map-wrapper{
    width: 100%;
    height: calc(100vh - 100px);
    position: relative;
    & > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  #map {
    width: 100%;
    height: calc(100vh - 100px);
  }
  .map_data_msg {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    // color: #02f4fe;
    color: #fff;
    padding: 10px;
    width: auto;
    .map_data_legend {
      margin-bottom: 5px;
    }
    .map_data_msg_wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .item {
        line-height: 15px;
        padding: 5px 20px 5px 0;
        width: auto;
      }
      .item span {
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 100%;
        vertical-align: middle;
        margin-left: 0;
        position: relative;
        margin-right: 7px;
      }
      .item span:after {
        content: '';
        position: absolute;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        border: 1px solid rgba(255,255,255, 0.5);
        left: -5px;
        top: -5px;
      }
      .map_data_msg_inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 10px;
      }
    }
  }
}

.overlap_layer_desc {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .item {
    line-height: 15px;
    padding: 10px 10px 10px 0;
  }
  .item span {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 3px;
    vertical-align: top;
    margin-left: 0;
  }
  .map_data_msg_inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 10px;
  }
}

.main-panel {
  // height: calc(100vh - 130px);
  min-height: 350px;
}
.right-block {
  height: calc(100% - 72px);
  // min-height: 350px;
  overflow: hidden;
}
.tab-scroller {
  height: calc(100vh - 470px);
  min-height: 350px;
  overflow-y: auto;
}

.show_list {
  width: 100%;
  height: 50px;
  padding-top: 6px;

  // margin-top: 8px;
}
.show_list span {
  height: 32px;
  line-height: 36px;
  margin-left: 32px;
  font-size: 14px;
}
.show_list span i {
  height: 16px;
  padding: 2px 12px;
  background: #ffb236;
  margin: 0 4px;
  border-radius: 16px;
}
.show_list .up_show {
  display: block;
  float: left;
  font-size: 32px;
  cursor: pointer;
}
.show_list .download {
  display: block;
  float: right;
  font-size: 32px;
  cursor: pointer;
}
.footer {
  padding-top: 0;
}
.extended-forms .el-select {
  width: 100%;
  margin-bottom: 30px;
}

.extended-forms .progress {
  margin-bottom: 30px;
}
@import url("https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css");
.coordinates {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 5px 10px;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  display: none;
}
ul.nav__items > li:last-of-type a {
  margin-right: 0 !important;
}
.card-body .tab-content .tab-scroller {
  min-height: auto;
}
.card-map .card-body {
  padding: 0;
}
.shelter-error-msg {
  color: #f00;
}
.chart-area {
  height: 400px;
}
.checkbox-wrapper {
  max-height: 500px;
  overflow: auto;
  background:#efefef;
}
#customStyle {
  padding-top: 150px!important;
}
.key-decision {
  height: 150px!important;
  .el-collapse {
    padding: 0;
    margin: 0;
  }
  .decision-block {
    width: 194px;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.35em;
    border: none;
    margin: 10px 1px;
    border-radius: 0.1875rem;
    padding: 11px 22px;
    cursor: pointer;
    border-width: 1px;
    border-radius: 30px;
    padding-right: 23px;
    padding-left: 23px;
    background: #BEC3C7;
    color: #394761;
  }
}
</style>
<style lang="scss">
  .el-select .el-select__tags .el-tag:first-child {
    margin-left: 6px;
  }
  .el-select .el-tag {
    border-color: transparent;
  }
</style>  
