<template>
  <div class="row" style="margin:0 -30px;">
    <div class="col-md-9" style="padding:0">
      <card class="card-map">
        <!-- <div class="mask">
          <img src="img/loading.png" class="loading">
        </div>-->
        <div class="switch-two-side">
          <p>下午两点</p>
          <n-switch v-model="switchs.rescueTime"></n-switch>
          <p>凌晨两点</p>
        </div>
        
        <div class="map-wrapper">
          <div id="map"></div>
          <canvas id="deck-canvas"></canvas>
        </div>
         <menu-map :map="map" :mapDefault="mapdefaultSetting"></menu-map>
        <div class="map_data_msg" v-if="selects.data_category == 'serious'">
          <div class="map_data_legend">医疗点</div>
          <div class="map_data_msg_wrapper">
            <div
              class="item"
              v-for="(item,index) in rescueOptions"
              :key="index"
              v-if="index==0"
            >
              <span :style="'background: '+item.color+';transform: scale('+(3-(index*0.5))*0.4+');'"></span>
              {{item.name}}
            </div>
          </div>
        </div>
        <div class="map_data_msg" v-if="selects.data_category == 'slight'">
          <div class="map_data_legend">医疗点</div>
          <div class="map_data_msg_wrapper">
            <div
              class="item"
              v-for="(item,index) in rescueOptions"
              :key="index"
            >
              <span :style="'background: '+item.color+';transform: scale('+(3-(index*0.5))*0.4+');'"  ></span>
              {{item.name}}
            </div>
          </div>
        </div>
        <div class="show_list">
          <a class="up_show" v-on:click="showList">
            <i
              class="now-ui-icons"
              :class="[ show_list ? 'arrows-1_minimal-down':'arrows-1_minimal-up']"
            ></i>
          </a>
          <a class="download ml-auto" :href="downloadUrl">
            <i class="now-ui-icons arrows-1_cloud-download-93"></i>
          </a>
          <span>
            数据列表
            <i>{{total}}</i>
          </span>
        </div>
        <div
          class="show_list_record"
          v-show="show_list"
          :style="{'margin-top':-(140+data.length*38)+'px'}"
          ref="show_list_record"
        >
          <el-table :data="data" v-show="selects.data_category == 'serious'">
            <el-table-column label="小区ID" property="id"></el-table-column>
            <el-table-column label="街道" property="street_name"></el-table-column>
            <el-table-column label="社区" property="community_name"></el-table-column>
            <el-table-column label="医院ID" property="hospital_id"></el-table-column>
            <el-table-column label="医院名称" property="name"></el-table-column>
          </el-table>

          <el-table :data="data" v-show="selects.data_category == 'slight'">
            <el-table-column label="小区ID" property="id"></el-table-column>
            <el-table-column label="街道" property="street_name"></el-table-column>
            <el-table-column label="社区" property="community_name"></el-table-column>
            <el-table-column label="医院ID" property="hospital_id"></el-table-column>
            <el-table-column label="医院名称" property="name"></el-table-column>
          </el-table>

          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class>
              <p class="card-category">显示： {{from + 1}} - {{to}} 总数： {{total}}</p>
            </div>
            <n-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              v-on:input="changePage"
            ></n-pagination>
          </div>
        </div>
      </card>
    </div>
    <div class="col-md-3 hasDecision" id="customStyle" >
      <div class="col-md-12 clearfix key-decision" >
          <el-select
          class="select-default"
          size="large"
          placeholder="数据集类型选择"
          v-model="selects.data_category"
          v-on:change="reloadData('key')"
        >
          <el-option
            v-for="option in selects.data_categories"
            class="select-primary"
            :value="option.id"
            :label="option.name"
            :key="option.id"
          ></el-option>
        </el-select>
        <el-collapse v-model="activeNameskey">
          <el-collapse-item title="决策信息" name="1">
            <div class="col-md-12 clearfix decision-wrapper">
              <div class="decision-block" @click = "modals.serious = true" v-show="selects.data_category == 'serious'">
                <span class="demonstration">重伤医疗点选择</span>
              </div>
              <div class="decision-block" @click = "modals.slight = true" v-show="selects.data_category == 'slight'">
                <span class="demonstration">轻伤医疗点选择</span>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
        </div>
        <el-collapse v-model="activeNames" class="el-collapse-main">
          <!-- <el-collapse-item title="一致性 Consistency" name="1">
            <form>
              <div class="input-group no-border">
                <fg-input
                  placeholder="输入关键字..."
                  v-on:keyup.enter="reloadData"
                  v-model="keyword"
                  addon-right-icon="now-ui-icons ui-1_zoom-bold"
                ></fg-input>
                <div class="form-text" style="color:#999;margin-left:20px;">输入 建筑、街道、社区关键字</div>
              </div>
            </form>
          </el-collapse-item> -->
          
          <el-collapse-item title="信息图表" name="2">
            <div class="col-md-12">
              <div class="chart-area" id="box-chart" style="height:550px"></div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="叠加图层" name="3">
            <div class="col-md-12 clearfix" v-show="selects.data_category=='serious'">
              <checkbox 
                v-model="seriousInjuredLayer"
                label="重伤人口"
                v-on:input="!seriousInjuredLayer"
              >重伤人口</checkbox>
              <div class="overlap_layer_desc">
                <div class="item" v-for="(item,index) in seriousInjuredLayerOptions" :key="index">
                  <span :style="'background: '+item.color+''"></span>
                  {{item.name}}
                </div>
              </div>
            </div>
            <div class="col-md-12 clearfix" v-show="selects.data_category=='slight'">
              <checkbox 
                v-model="slightInjuredLayer"
                label="轻伤人口"
                v-on:input="!slightInjuredLayer"
              >轻伤人口</checkbox>
              <div class="overlap_layer_desc">
                <div class="item" v-for="(item,index) in slightInjuredLayerOptions" :key="index">
                  <span :style="'background: '+item.color+''"></span>
                  {{item.name}}
                </div>
              </div>
            </div>
          </el-collapse-item>
           <el-collapse-item title="数据筛选" name="4">
            <div class="col-md-12">
              <el-select
                class="select-default"
                size="large"
                placeholder="街道"
                v-model="selects.street"
                v-on:change="reloadData('street')"
              >
                <el-option
                  v-for="option in selects.streets"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id"
                ></el-option>
              </el-select>
            </div>

            <div class="col-md-12">
              <el-select
                class="select-default"
                size="large"
                placeholder="社区"
                v-model="selects.community"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="option in selects.communities"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id"
                ></el-option>
              </el-select>
            </div>

            <div class="col-md-12" v-show="selects.data_category == 'serious'">
             筛选重伤医疗点 <checkbox v-model="isCheckAllseriousForFilter" @input="handleCheckAllFilterChange('serious')"  >全选</checkbox>
              <checkbox v-for="(option,idx) in selects.seriousOptions" v-model="selects.serious[idx]" :key="option.name" :label="option.name" @input="reloadData">{{option.name}}</checkbox>
              <!-- <el-select
                class="select-default"
                size="large"
                multiple="" 
                placeholder="医疗点"
                v-model="selects.serious"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="option in selects.seriousOptions"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id"
                ></el-option>
              </el-select> -->
            </div>


            <div class="col-md-12" v-show="selects.data_category == 'slight'">
              筛选轻伤医疗点 <checkbox v-model="isCheckAllslightForFilter" @input="handleCheckAllFilterChange('slight')"  >全选</checkbox>
              <checkbox v-for="(option,idx) in selects.slightOptions" v-model="selects.slight[idx]" :key="option.name" :label="option.name" @input="reloadData">{{option.name}}</checkbox>
              <!-- <el-select
                class="select-default"
                size="large"
                multiple="" 
                placeholder="医疗点"
                v-model="selects.slight"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="option in selects.slightOptions"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id"
                ></el-option>
              </el-select> -->
            </div>
          </el-collapse-item>
        </el-collapse>
    </div>
    <modal :show.sync="modals.serious" headerclasses="justify-content-center">
      <h4 slot="header" class="title title-up">请选择至少4所重伤医院</h4>
      <!-- <p>
        <el-select
          class="select-default"
          size="large"
          multiple="" 
          placeholder="医疗点"
          v-model="serious"
        >
          <el-option
            v-for="option in seriousOptions"
            class="select-primary"
            :value="option.id"
            :label="option.name"
            :key="option.id"
          ></el-option>
        </el-select>
      </p> -->
       <div class="col-md-12">
          <p>已选{{getCheckedTotal('serious')}}所重伤医院</p>
          <div>
            <checkbox v-model="isCheckAll_serious" @input="handleCheckAllChange('serious')"  >全选</checkbox>
          </div>
          <div class="checkbox-wrapper">
            <checkbox v-for="(option,idx) in seriousOptions" v-model="serious[idx]" :key="option.name" :label="option.name">{{option.name}}</checkbox>
          </div>
        </div>
      <p class="shelter-error-msg" v-show="showErrMsg">请选满4所重伤医院</p>
      <template slot="footer">
        
        <n-button type="default" @click.native="modals.serious = false">关闭</n-button>
        <n-button type="primary" @click.native="confirm">确认选择</n-button>
      </template>
    </modal>
    <modal :show.sync="modals.slight" headerclasses="justify-content-center">
      <h4 slot="header" class="title title-up">请选择至少12所轻伤医院</h4>
      <!-- <p>
        <el-select
          class="select-default"
          size="large"
          multiple="" 
          placeholder="医疗点"
          v-model="slight"
        >
          <el-option
            v-for="option in slightOptions"
            class="select-primary"
            :value="option.id"
            :label="option.name"
            :key="option.id"
          ></el-option>
        </el-select>
      </p> -->
      <div class="col-md-12">
          <p>已选{{getCheckedTotal('slight')}}所轻伤医院</p>
          <div>
            <checkbox v-model="isCheckAll_slight" @input="handleCheckAllChange('slight')"  >全选</checkbox>
          </div>
          <div class="checkbox-wrapper">
            <checkbox v-for="(option,idx) in slightOptions" v-model="slight[idx]" :key="option.name" :label="option.name">{{option.name}}</checkbox>
          </div>
        </div>
      <p class="shelter-error-msg" v-show="showErrMsg">请选满12所轻伤医院</p>
      <template slot="footer">
        
        <n-button type="default" @click.native="modals.slight = false">关闭</n-button>
        <n-button type="primary" @click.native="confirm">确认选择</n-button>
      </template>
    </modal>
    <div class="key-infos">
      <div class="key-info" v-show="selects.data_category == 'serious'">
        重伤人口总数{{statistics.serious.count}}人  
      </div>
      <div class="key-info" v-show="selects.data_category == 'slight'">
        轻伤人口总数{{statistics.slight.count}}人
      </div>
     
    </div>
  </div>  
</template>
<script>
import { Deck } from "@deck.gl/core";
import { GeoJsonLayer, ArcLayer } from "@deck.gl/layers";
import mapboxgl from "mapbox-gl";
import MenuMap from "src/components/Menumap";
import MiniMap from "src/mapbox/mapboxgl-minimap.js";
import { Modal,TabPane, Tabs, Switch } from "src/components";
import geobuf from "geobuf";
import Pbf from "pbf";
import { Radio } from "src/components/index";
import { Checkbox } from "src/components/index";
import { Input, Button, Select, Option, Collapse, CollapseItem,Table, TableColumn } from "element-ui";
import { consts } from "src/util/consts.js";
import {
  getCategories,
  getRescueStreets,
  getRescueList,
  getRescuePlan,
  getRescueCommunities,
  getSeriousList,
  getSlightList,
  getSeriousGeojson,
  getSeriousLineGeojson,
  getSeriousBlockGeojson,
  getSlightGeojson,
  getSlightLineGeojson,
  getSlightBlockGeojson,
  getSeriousStatistics,
  getSlightStatistics
} from "src/api/building.js";
import { byHospitalUsage } from "src/api/chart.js";
import {
  buildBarStackOption,
  buildlineOption,
  buildPieOption
} from "src/util/chart.js";
import {
  getBuildYearRange,
  storeMapOptions,
  getMapOptions,
  parseJsonToString
} from "src/util/common.js";
import { Pagination as NPagination } from "src/components";
import {
  getPeopleStat,
  getGenderStat,
  getHideaways,
  getHospitals,
  getWarehouses
} from "src/api/cityinfo.js";

import * as THREE from "three";
//import geoJsonBasic from "./GeoJson-basic"; // 基本建筑数据from2.2-2.3
//import geoJson231 from "./GeoJson-2.3.1"; // 基本建筑数据from2.3.1

export default {
  components: {
    MenuMap,
    TabPane,
    Tabs,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    NPagination,
    [Switch.name]: Switch,
    Checkbox,
    Radio,
    Modal
  },
  data() {
    return {
      activeNameskey: ['1'],
      activeNames: ['2','3','4'],
      activeName: "first",
      tabs: {
        horizontal: "Profile",
        vertical: "Options",
        verticalPills: "Home",
        pageCategories: "Messages"
      },
      selects: {
        street: 0,
        streets: [{ id: 0, name: "街道" }],
        community: 0,
        communities: [{ id: 0, name: "社区" }],
        struct: 0,
        structs: consts.buildingStructs,
        slight: [],
        slightOptions: [{ id: 0, name: "医疗点选择" }],
        serious: [],
        seriousOptions: [{ id: 0, name: "医疗点选择" }],
        category: 0,
        categories: consts.buildingCategories,
        quakeLevel: 0,
        quakeLevels: [
          { id: 0, name: "抗震设防烈度" },
          { id: 6, name: 6 },
          { id: 7, name: 7 },
          { id: 8, name: 8 }
        ],
        buildYear: 0,
        buildYears: consts.buildingBuildYears,
        data_category: 'serious',
        data_categories: [
          { id: 'serious', name: "重伤员医疗救援" },
          { id: 'slight', name: "轻伤员医疗救援" }
        ]
      },
      keyword: "",
      total: 0,
      data: [],
      page: 1,
      map: {},
      color_category: "struct",
      city_id: localStorage.getItem("city_id") || 1,
      show_list: false,
      show_list_height: 0,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 20
      },
      switchs: {
        people: true,
        gender: true,
        hideaway: true,
        hospital: true,
        warehouse: true,
        rescueTime: true
      },
      last_updated: consts.last_updated,
      colors: {
        'rescue': ["#F5C243", "#62BFF5", "#ffc000"],
      },
      rescueOptions: [
        { id: 1, name: "综合医院", color: "#ff0000" },
        { id: 2, name: "社区医院", color: "#ffc000" },
      ],
      seriousInjuredLayer: false, //是否叠加重伤人口
      seriousInjuredLayerOptions: [
        { id: 1, name: "5人", color: "#4f71be" },
        { id: 2, name: "10人", color: "#4eadea" },
        { id: 3, name: "15人", color: "#ffff54" },
        { id: 4, name: "20人", color: "#de8344" },
        { id: 5, name: "30人", color: "#b02418" },
      ],
      slightInjuredLayer: false, //是否叠加轻伤人口
      slightInjuredLayerOptions: [
        { id: 1, name: "5人", color: "#4f71be" },
        { id: 2, name: "10人", color: "#4eadea" },
        { id: 3, name: "15人", color: "#ffff54" },
        { id: 4, name: "20人", color: "#de8344" },
        { id: 5, name: "30人", color: "#b02418" },
      ],
      modals: {
        serious: false,
        slight: false
      },
      showErrMsg: false,
      slight: [],
      slightOptions: [{ id: 0, name: "医疗点选择" }],
      serious: [],
      seriousOptions: [{ id: 0, name: "医疗点选择" }],
      isCheckAll_serious: true,
      isCheckAll_slight: true,
      isCheckAllseriousForFilter: true,
      isCheckAllslightForFilter: true,
      statistics: {
        serious: {
          count: ''
        },
        slight: {
          count: ''
        }
      },
      mapdefaultSetting: {},
      downloadUrl: ''
    };
  },
  watch: {
    seriousInjuredLayer(val) {
      //开关叠加涂层
      this.toggleMapLayer('seriousInjuredLayer')
    },
    slightInjuredLayer(val) {
      //开关叠加涂层
      this.toggleMapLayer('slightInjuredLayer')
    },
    "selects.data_category"(val){
      //this.fillRescues(this.city_id)
      // if(val == 'serious') {
      //   this.seriousInjuredLayer = this.slightInjuredLayer;
      //   this.slightInjuredLayer = false;
      // } else {
      //   this.slightInjuredLayer = this.seriousInjuredLayer;
      //   this.seriousInjuredLayer = false;
      // }
    },
    "switchs.rescueTime"(val) {
      //救援时间切换，影响叠加的热力图
      if(this.seriousInjuredLayer) {
        this.toggleMapLayer('seriousInjuredLayer')
      } else if(this.slightInjuredLayer){
        this.toggleMapLayer('slightInjuredLayer')
      }
    },
    "slight"(val){
      // let tmpvar = Array.from(val);
      // tmpvar.sort((a,b)=>a - b)
      let _this = this;
      _this.selects.slightOptions = [];
      // tmpvar.forEach(element => {
      //   _this.selects.slightOptions.push(..._this.slightOptions.filter(item=>item.id==element))
      // });
      // _this.selects.slight = tmpvar;
      val.forEach((element,idx) => {
        if(element === true) {
          _this.selects.slightOptions.push(_this.slightOptions[idx])
        }
        
      });
      _this.selects.slight = val.filter(item=>item===true);
    },
    "serious"(val){
      // let tmpvar = Array.from(val);
      // tmpvar.sort((a,b)=>a - b)
      let _this = this;
      _this.selects.seriousOptions = [];
      // tmpvar.forEach(element => {
      //   _this.selects.seriousOptions.push(..._this.seriousOptions.filter(item=>item.id==element))
      // });
      // _this.selects.serious = tmpvar;
      val.forEach((element,idx) => {
        if(element === true) {
          _this.selects.seriousOptions.push(_this.seriousOptions[idx])
        }
        
      });
      _this.selects.serious = val.filter(item=>item===true);
    },
    "selects.street"(val) {
      //街道切换选社区
      this.fillCommunities(this.city_id, val);
    },
    "selects.serious"(val) {
      var tmp = 0;
      val.forEach(item=>{
        if(item === true){
          tmp++
        }
      })
      if(tmp == this.selects.seriousOptions.length) this['isCheckAllseriousForFilter'] = true;
      else this['isCheckAllseriousForFilter'] = false;
    },
    "selects.slight"(val) {
      var tmp = 0;
      val.forEach(item=>{
        if(item === true){
          tmp++
        }
      })
      if(tmp == this.selects.slightOptions.length) this['isCheckAllslightForFilter'] = true;
      else this['isCheckAllslightForFilter'] = false;
    },
  },
  mounted() {
    this.fillStreets(this.city_id);
    //this.fillCommunities(this.city_id, 0);
    this.fillRescues(this.city_id);
    this.showChart(this.city_id);
    //this.fillBuildings(this.city_id, 1);
    this.initMap(this.city_id);
    //this.fillBuildingsMap(this.city_id, this.color_category);
    //this.addPointLayerToMap(this.map, "hospital");
    //this.addPointLayerToMap(this.map, "hideaway");
    //this.addPointLayerToMap(this.map, "warehouse");
    //this.loadData(this.data_type);
    //
    
    
    
  },

  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    }
  },
  methods: {
    // 初始化
    initMap(city_id) {
      let self = this;
      this.mapdefaultSetting = {
        center: consts.mapCenter[this.city_id-1],
        zoom: consts.mapDefaultZoom,
        pitch: 0,
        city_id: this.city_id
      }
      mapboxgl.accessToken = consts.mapboxAccessToken;
      let map_options = getMapOptions();
      //console.log(map_options);
      let zoom = map_options ? map_options.zoom : 12;
      let center = map_options
        ? [map_options.lng, map_options.lat]
        : consts.mapCenter[city_id - 1];
      var map = new mapboxgl.Map({
        container: "map",
        style: consts.mapBoxStyle,
        //center: [-87.61694, 41.86625],
        center: center,
        zoom: zoom,
        pitch: 0,
        bearing: 0,
        attributionControl: false
      });
      this.map = map;
      //定位图标
      map.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl
        })
      );
      this.map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: true
        })
      );
      map.on("load", function(){
        self.reloadData()
        self.modals[self.selects.data_category] = true;
      })
      //控制图层
      var nav = new mapboxgl.NavigationControl();
      this.map.addControl(nav, "top-right");
      //全屏按钮
      this.map.addControl(
        new mapboxgl.FullscreenControl({
          container: document.querySelector("body")
        })
      );
      
      //小地图
      //https://github.com/brendanmatkin/mapboxgl-minimap
      map.on("style.load", function() {
        // Possible position values are 'bottom-left', 'bottom-right', 'top-left', 'top-right'
        map.addControl(
          new MiniMap({
            id: "mapboxgl-minimap",
            width: "200px",
            height: "150px",
            //style: "mapbox://styles/mapbox/streets-v10",
            style: consts.mapBoxStyle,
            fillColor: "#f58220",
            zoomAdjust: null
          }),
          "bottom-right"
        );
      });
      //记录地图设置
      map.on("move", function() {
        storeMapOptions({
          lng: map.getCenter().lng,
          lat: map.getCenter().lat,
          zoom: map.getZoom()
        });
        if(typeof self.deck != "undefined") {
          self.deck.setProps({
            viewState: {
              latitude: map.getCenter().lat,
              longitude: map.getCenter().lng,
              zoom: map.getZoom(),
              bearing: map.getBearing(),
              pitch: map.getPitch()   
            }
          })
        }
      });
    },
    addBuildings2Map(map, geoJsonData) {
      let layer_id = "room-extrusion";
      let source_name = "geojson";
      var layer = map.getLayer(layer_id);
      if (layer) {
        map.removeLayer(layer_id);
        map.removeSource(source_name);
      }
      
      
      map.addSource(source_name, {
        type: "geojson",
        data: geoJsonData
      });
      let colorExpress = ["get", "color"];
      if(this.selects.data_category == "before_reinforce") {
        colorExpress = [
          "match",
          ["get", "加固优先级"],
          '高',
          "#ff0000",
          '中',
          "#ed7d31",
          '低',
          "#ffc000",
          "#ff0000"
        ];
      } else if(this.selects.data_category == "after_reinforce") {
        
      }
      map.addLayer({
        id: layer_id,
        type: "fill-extrusion",
        source: source_name,
        //'source': {
        // GeoJSON Data source used in vector tiles, documented at
        // https://gist.github.com/ryanbaumann/a7d970386ce59d11c16278b90dde094d
        //  'type': 'geojson',
        //  'data':geoJsonData,
        //'data': "http://api.urban.test/api/buildings-geo-json?struct_id=2&category_id=1&street_id=1"
        //'data': "https://docs.mapbox.com/mapbox-gl-js/assets/indoor-3d-map.geojson"
        //},
        paint: {
          // See the Mapbox Style Specification for details on data expressions.
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions

          // Get the fill-extrusion-color from the source 'color' property.
          "fill-extrusion-color": colorExpress,

          // Get fill-extrusion-height from the source 'height' property.
          "fill-extrusion-height": ["get", "height"],

          // Get fill-extrusion-base from the source 'base_height' property.
          "fill-extrusion-base": ["get", "base_height"],

          // Make extrusions slightly opaque for see through indoor walls.
          "fill-extrusion-opacity": 0.8
        }
      });
    },
    showList() {
      this.show_list = !this.show_list;
    },
    donwloadList() {},
    showChart(city_id) {
      let _this = this;
      var container = document.querySelector('.el-collapse-main');
      var box = document.getElementById("box-chart");
      box.style.width = container.offsetWidth + "px";
      this.myChart = this.$echarts.init(box);
      
      document.querySelector('.key-decision').style.width = document.querySelector('#customStyle').offsetWidth +'px'
      window.onresize = function() {
        document.querySelector('.key-decision').style.width = document.querySelector('#customStyle').offsetWidth +'px'
        document.querySelectorAll('.chart-area').forEach((item,idx)=>{
          item.style.width = document.querySelector('.el-collapse-main').offsetWidth+'px';
        })
        _this.myChart.resize(); 
      }
    },
    updateChart() {
      let _this = this;
      //byHospitalUsage(this.city_id,this.planId).then(res=>{
      byHospitalUsage(this.city_id,this.selectedString, this.selects.data_category).then(res=>{
        var chart_data = res.data;
        var chart_name = "医疗点容量所使用率";
        chart_data.seriesData.map((item,idx) => {
          return item.color = _this.colors['rescue'][idx]
        })
        var option = buildBarStackOption(chart_name, chart_data, {
          xAxis: {
            name: '使用/空置容量',
            nameLocation: 'center',//坐标轴名称显示位置。
            nameGap: 30,
            
          }
        });
        this.myChart.setOption(option);
      });
    },
    fillRescues(city_id){
      //if(!this[this.selects.data_category].length){
        // getRescueList(city_id,this.selects.data_category).then(res => {
        //   let data = res.data;
        //   this[this.selects.data_category+'Options'] = data;
        //   if(sessionStorage.getItem("rescue_"+this.selects.data_category)) {
        //     this[this.selects.data_category] = JSON.parse(sessionStorage.getItem("rescue_"+this.selects.data_category))
        //   } else {
        //     this[this.selects.data_category+'Options'].forEach((item, idx) => {
        //       this[this.selects.data_category].push(item.id)
        //     });
        //   }
        //   this.getRescueId()
        // });
      //}
      getRescueList(city_id,'serious').then(res => {
        let data = res.data;
        this['seriousOptions'] = data;
        if(sessionStorage.getItem("checked_rescue_serious")) {
          this['serious'] = JSON.parse(sessionStorage.getItem("checked_rescue_serious"))
        } else {
          // this['seriousOptions'].forEach((item, idx) => {
          //   this['serious'].push(item.id)
          // });
          this.serious = new Array(this['seriousOptions'].length).fill(true);
          console.log(this.serious)
        }
      });
      getRescueList(city_id,"slight").then(res => {
        let data = res.data;
        this["slightOptions"] = data;
        if(sessionStorage.getItem("checked_rescue_slight")) {
          this["slight"] = JSON.parse(sessionStorage.getItem("checked_rescue_slight"))
        } else {
          // this["slightOptions"].forEach((item, idx) => {
          //   this["slight"].push(item.id)
          // });
          this.slight = new Array(this['slightOptions'].length).fill(true);
          console.log(this.slight)
        }
      });
    },
    fillStreets(city_id) {
      getRescueStreets(city_id,this.selects.data_category=='serious'?1:0).then(res => {
        let data = res.data;
        data.unshift({ id: 0, name: "街道" });
        this.selects.streets = data;
      });
    },
    fillCommunities(city_id, street_id) {
      getRescueCommunities(city_id, street_id,this.selects.data_category=='serious'?1:0).then(res => {
       let data = res.data;
        data.unshift({ id: 0, name: "社区" });
        this.selects.community = 0;
        this.selects.communities = data;
      });
    },
    fillPeopleData(page) {
      let params = this.buildParams();
      params.page = page;
      getPeopleStat(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillGenderData(page) {
      let params = this.buildParams();
      params.page = page;
      getGenderStat(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillHospitalsData(city_id, page) {
      let params = this.buildParams();
      params.page = page;
      getHospitals(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillHideawaysData(page) {
      let params = this.buildParams();
      params.page = page;
      getHideaways(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillWarehousesData(page) {
      let params = this.buildParams();
      params.page = page;
      getWarehouses(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillBuildings(city_id, page) {
      let params = this.buildParams();
      params.page = page;
      params.page_size = 10;
      if(this.selects.data_category == 'serious') {
        getSeriousList(params).then(res => {
          this.total = res.data.total;
          this.data = res.data.items;
        });
        getSeriousStatistics(params).then(res => {
          this.statistics.serious.count = res.data.count;
        });
        this.downloadUrl = consts.requestUrl + consts.exportRescueSerious + parseJsonToString(params)
      } else {
        getSlightList(params).then(res => {
          this.total = res.data.total;
          this.data = res.data.items;
        });
        getSlightStatistics(params).then(res => {
          this.statistics.slight.count = res.data.count;
        });
        this.downloadUrl = consts.requestUrl + consts.exportRescueSlight + parseJsonToString(params)
      }
      
    },
    fillBuildingsMap(city_id, color_category) {
      let params = this.buildParams();
      params.color_category = color_category;
      // getGeoJson(params).then(res => {
      //   let geoJson = res.data;
      //   this.addBuildings2Map(this.map, geoJson);
      // });

      //临时
      if(this.selects.data_category == "before_reinforce") {
        fetch('/geojson/GeoJson-2.3.1.json').then(res => res.json())
        .then(json => {
          let geoJson = json;
          this.addBuildings2Map(this.map, geoJson);
        })
        
      }
    },
    fillBlockmapLayer(city_id, type, layer_name){
      if(!layer_name) layer_name = type;
      let params = this.buildParams();
      params.color_category = type;
      
      // getGeoJson(params).then(res => {
      //   let geoJson = res.data;
      //   //mock polygon数据 for 离家住户
      //     geoJson.features.map((item, idx) => {
      //       return (item.properties.population = parseInt(
      //         Math.random() * 2000
      //       ));
      //     });

      //   this.addBlockmapLayer(this.map, layer_name, geoJson);
        
      // });

      //临时
      fetch('/geojson/GeoJson-block.json').then(res => res.json())
      .then(json => {
        let geoJson = json;
        //mock polygon数据 for 离家住户
        geoJson.features.map((item, idx) => {
          return (item.properties.population = parseInt(
            Math.random() * 2000
          ));
        });
        this.addBlockmapLayer(this.map, layer_name,geoJson);
      })
      
    },
    fillArcLayer(){
     
      let params = this.buildParams();
      //医疗点飞线坐标
      if(this.selects.data_category == 'serious') {
        getSeriousLineGeojson(params).then(data => {
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          console.log('重伤飞线',geoJson)
          this.addArcLayer(this.map, geoJson)
        });
      } else {
        getSlightLineGeojson(params).then(data => {
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          console.log(geoJson)
          this.addArcLayer(this.map, geoJson)
        });
      }

    },
    fillPointLayer(){
      //获取医疗点坐标
      let params = this.buildParams();
      if(this.selects.data_category == 'serious') {
        getSeriousGeojson(params).then(data => {
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          console.log('重伤点',geoJson)
          this.addAnimatePointLayerToMap(this.map, geoJson)
        });
      } else {
        getSlightGeojson(params).then(data => {
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addAnimatePointLayerToMap(this.map, geoJson)
        });
      }
      
      
    },
    fillHeatMap(city_id, type) {
      let params = this.buildParams();
      if(this.switchs.rescueTime) {
        //am两点
        params.time_point = 1;
      } else {
        //pm两点
        params.time_point = 2;
      }
      if(this.selects.data_category == 'serious') {
        getSeriousBlockGeojson(params).then(data => {
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addHeatmapLayer(this.map, type,geoJson);
        });
      } else {
        getSlightBlockGeojson(params).then(data => {
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addHeatmapLayer(this.map, type,geoJson);
        });
      }
      // getGeoJson(params).then(res => {
      //   let geoJson = res.data;
      //   //mock 热力图数据
      //   //mock数据开始
      //   // geoJson.features.map((item, idx) => {
      //   //   return (item.properties.death = Math.random());
      //   // });
      //   this.addHeatmapLayer(this.map, type, geoJson);
      // });

      //临时
      //this.addHeatmapLayer(this.map, type);
    },
    reloadData(type) {
      if(type == 'street') {
        this.selects.community = 0;
      }
      this.setTipContent();
      if(!sessionStorage.getItem("checked_rescue_"+this.selects.data_category)) {
          this.modals[this.selects.data_category] = true;
        } else {
          this.modals[this.selects.data_category] = false;
          this.getRescueId()
          
        }
      
      //this.loadData(this.data_type);
    },
    filterdMapData(){
      
    },
    setTipContent() {
      let html = ''
      if(this.selects.data_category == 'serious') {
        html = consts.tipsRescueSerious
      } else {
        html = consts.tipsRescueSlight
      }
      document.getElementById('left-tip-content').innerHTML = html
    },
    buildParams() {
      let params = { city_id: this.city_id };
      params.street_id = this.selects.street;
      params.community_id = this.selects.community;
      params.keyword = this.keyword;
      params.decision = this.selectedString;
      //params.hospital_ids = this.selects[this.selects.data_category].join(',');
      let idsArr = []
      this.selects[this.selects.data_category+'Options'].forEach((item,idx)=>{
        if(this.selects[this.selects.data_category][idx]) {
          idsArr.push(item.id)
        }
      })
      if(idsArr.length == 0) {
        params.hospital_ids = '100' //全部选，传个不存在的
      } else {
        params.hospital_ids = idsArr.join(',');
      }
      
      
      return params;
    },
    changePage: function(value) {
      this.fillBuildings(this.city_id, value);
    },
    addArcLayer: function(map,geojson){
      let _this = this;
      if(typeof geojson.features == 'undefined') {
        geojson.features = [];
      }
      geojson = geojson.features;
      geojson.forEach((item,idx) => {
        if(item.properties.category == 1){
          item.properties.color = [255, 0, 0]
        } else if(item.properties.category == 2){
          item.properties.color = [237, 125, 49]
        }
        //item.properties.color = [78,173,234]
      })
      //飞线
      //const geoJson = "https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_airports.geojson";  
      // let geoJson = [
      //         {"type":"Feature","properties":{"lng":120.1964333,"lat": 30.23462552,"color":[194,214,236]},"geometry":{"type":"Point","coordinates":[120.16682548,30.24599102]}},
      //         {"type":"Feature","properties":{"lng":120.1964333,"lat": 30.23462552,"color":[194,214,236]},"geometry":{"type":"Point","coordinates":[120.16817525,30.24700676]}},
      //         {"type":"Feature","properties":{"lng": 120.1964333,"lat": 30.23462552,"color":[194,214,236]},"geometry":{"type":"Point","coordinates":[120.16877076,30.24572819]}}
      //       ]   
      if(!this.deck) {
        this.deck = new Deck({
        canvas: "deck-canvas",
        width: "100%",
        height: "100%",
        initialViewState: {
          latitude: this.map.getCenter().lat,
          longitude: this.map.getCenter().lng,
          zoom: this.map.getZoom(),
          bearing: 0,
          pitch: 0
        },
        controller: true,
        onViewStateChange: ({ viewState }) => {
          this.map.jumpTo({
            center: [viewState.longitude, viewState.latitude],
            zoom: viewState.zoom,
            bearing: viewState.bearing,
            pitch: viewState.pitch
          });
        },
        layers: [
          new GeoJsonLayer({
            id: "airports",
            data: geojson,
            // Styles
            filled: true,
            pointRadiusMinPixels: 2,
            pointRadiusScale: 2,
            getRadius: f => 11 - f.properties.scalerank,
            getFillColor: [194, 214, 236, 180],
            // Interactive props
            pickable: true,
            autoHighlight: true,
            onClick: info =>{
              // eslint-disable-next-line
              // info.object &&
              // alert(
              //   `${info.object.properties.name} (${info.object.properties.abbrev})`
              // )  
            }
          }),
          new ArcLayer({
            id: "arcs",
            data: geojson,
            //dataTransform: d => d.features.filter(f => f.properties.natlscale < 4),
            // Styles
            getSourcePosition: f => [f.properties.lng,f.properties.lat], // London
            getTargetPosition: f => f.geometry.coordinates,
            getSourceColor: f => f.properties.color,
            getTargetColor: f => f.properties.color,
            getWidth: 1
          })
        ]
      });
    } else {
        this.deck.setProps({
          layers: [
            new GeoJsonLayer({
              id: _this.getMapLayerId("airports"),
              data: geojson,
              // Styles
              filled: true,
              pointRadiusMinPixels: 2,
              pointRadiusScale: 2,
              getRadius: f => 11 - f.properties.scalerank,
              getFillColor: [194, 214, 236, 180],
              // Interactive props
              pickable: true,
              autoHighlight: true,
              onClick: info =>{
                // eslint-disable-next-line
                // info.object &&
                // alert(
                //   `${info.object.properties.name} (${info.object.properties.abbrev})`
                // )  
              }
            }),
            new ArcLayer({
              id: _this.getMapLayerId("arcs"),
              data: geojson,
              //dataTransform: d => d.features.filter(f => f.properties.natlscale < 4),
              // Styles
              getSourcePosition: f => [f.properties.lng,f.properties.lat], // London
              getTargetPosition: f => f.geometry.coordinates,
              getSourceColor: f => f.properties.color,
              getTargetColor: f => f.properties.color,
              getWidth: 1
            })
          ]
        })
      } 
    },
    addHeatmapLayer(map, type, geoJsonData) {
      // if(this.maptype != 'heatmap') {
      //   return;
      // }
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      let layer_point_id = layer_id + "point";
      let get_param = "number"
      // if(type == "serious") {
      //   if(this.switchs.rescueTime) {
      //     //凌晨
      //     get_param = "凌晨2点_人口伤亡_严重"
      //   } else {
      //     //下午
      //     get_param = "下午2点_人口伤亡_严重"
      //   } 
        
      // } else if(type == "slight") {
      //   if(this.switchs.rescueTime) {
      //     //凌晨
      //     get_param = "凌晨2点_人口伤亡_轻微"
      //   } else {
      //     //下午
      //     get_param = "下午2点_人口伤亡_轻微"
      //   } 
      // }
      // Add a geojson point source.
      // Heatmap layers also work with a vector tile source.

      if(typeof geoJsonData.features == "undefined") return;
      map.addSource(source_name, {
        type: "geojson",
        //data: "https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson"
        data: geoJsonData
        //data: "./geojson/GeoJson-2.2.2-3.geojson"
      });

      map.addLayer(
        {
          id: layer_id,
          type: "heatmap",
          source: source_name,
          maxzoom: 15,
          paint: {
            // Increase the heatmap weight based on frequency and property magnitude
            "heatmap-weight": [
              "interpolate",
              ["linear"],
              ["get", get_param],
              0, 0,
              30, 1
            ],
            // Increase the heatmap color weight weight by zoom level
            // heatmap-intensity is a multiplier on top of heatmap-weight
            "heatmap-intensity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              0,
              1,
              18,
              1.5
            ],
            // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
            // Begin color ramp at 0-stop with a 0-transparancy color
            // to create a blur-like effect.
            "heatmap-color": [
              "interpolate",
              ["linear"],
              ["heatmap-density"],
              0,
              "rgba(0, 0, 255, 0)",
              0.2,
              "#4f71be",
              0.4,
              "#4eadea",
              0.6,
              "#ffff54",
              0.8,
              "#de8344",
              1,
              "#b02418"
            ],
            // Adjust the heatmap radius by zoom level
            "heatmap-radius": [
              "interpolate",
              ["linear"],
              ["zoom"],
              0, 1,
              15, 26//热点大图半径
            ],
            // Transition from heatmap to circle layer by zoom level
            "heatmap-opacity": ["interpolate", ["linear"], ["zoom"], 9, 1, 15, 0.2]
          }
        },
        "waterway-label"
      );

      map.addLayer(
        {
          id: layer_point_id,
          type: "circle",
          source: source_name,
          minzoom: 13,
          paint: {
            // Size circle radius by earthquake magnitude and zoom level
            "circle-radius": [
              "interpolate",
              ["linear"],
              ["zoom"],
              13,
              ["interpolate", ["linear"], ["get", get_param], 0, 1, 30, 5],
              15,
              ["interpolate", ["linear"], ["get", get_param], 0, 5, 30, 20]
            ],
            // Color circle by earthquake magnitude
            "circle-color": [
              "interpolate",
              ["linear"],
              ["get", get_param],
              0,
              "rgba(0, 0, 255, 0)",
              5,
              "#4f71be",
              10,
              "#4eadea",
              15,
              "#ffff54",
              20,
              "#de8344",
              30,
              "#b02418"
            ],
            //"circle-stroke-color": "white",
            //"circle-stroke-width": 1,
            // Transition from heatmap to circle layer by zoom level
            "circle-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              12,
              0,
              16,
              1
            ]
          }
        },
        "waterway-label"
      );
    },
    makePointImage(map, type, capacity){
      let size = 150;
      switch(capacity){
         case 1:
            size = 80;
          break;
          case 2:
            size = 120;
          break;
          case 3:
            size = 160;
          break;
      }
      //point type
      let pulsingDot  = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // get rendering context for the map canvas when layer is added to the map
        onAdd: function() {
          var canvas = document.createElement("canvas");
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext("2d");
        },

        // called once before every frame where the icon will be used
        render: function() {
          var duration = 1000;
          var t = (performance.now() % duration) / duration;

          var radius = (size / 2) * 0.3;
          var outerRadius = (size / 2) * 0.7 * t + radius;
          var context = this.context;

          // draw outer circle
          context.clearRect(0, 0, this.width, this.height);
          context.beginPath();
          context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2
          );
          switch(type){
            case 1: //综合医院
              context.fillStyle = "rgba(255, 0, 0," + (1 - t) + ")";
            break;
            case 2: //社区医院
              context.fillStyle = "rgba(237, 125, 49," + (1 - t) + ")";
            break;
          }
          
          context.fill();

          // draw inner circle
          context.beginPath();
          context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
          switch(type){
            case 1:
              context.fillStyle = "rgba(255, 100, 100, 1)";
            break;
            case 2:
              context.fillStyle = "rgba(237, 125, 49, 1)";
            break;
          }
          
          context.strokeStyle = "white";
          context.lineWidth = 2 + 4 * (1 - t);
          context.fill();
          //context.stroke();
          // update this image's data with data from the canvas
          this.data = context.getImageData(0, 0, this.width, this.height).data;

          // continuously repaint the map, resulting in the smooth animation of the dot
          map.triggerRepaint();

          // return `true` to let the map know that the image was updated
          return true;
        }
      }
      map.addImage("pulsing-dot-"+type+"-"+capacity, pulsingDot, { pixelRatio: 2 });
    },
    addAnimatePointLayerToMap(map,geojson){
      var layer = map.getLayer('points');
      if (layer) {
        map.removeLayer('points');
        map.removeSource('points');
      }
      if(!map.hasImage("pulsing-dot-1-1")) {
        this.makePointImage(map, 1, 1) //综合医院 //1000-2000
        this.makePointImage(map, 1, 2) //综合医院 //2000-3000
        this.makePointImage(map, 1, 3) //综合医院 //>3000
        this.makePointImage(map, 2, 1) //社区医院 //1000-2000
        this.makePointImage(map, 2, 2) //社区医院 //2000-3000
        this.makePointImage(map, 2, 3) //社区医院 //>3000
      }
      if(typeof geojson.features == "undefined") return;
       geojson.features.forEach((item,idx)=>{
        if(item.properties.persons <= 2000){
          item.properties.capacity = 1
        } else if(item.properties.persons <= 3000){
          item.properties.capacity = 2
        } else {
          item.properties.capacity = 3
        }
      })
      
      map.addSource("points", {
        type: "geojson",
        data: geojson
       }
      );
      map.addLayer({
        id: "points",
        type: "symbol",
        source: "points",
        paint: {
          "text-color": "#ffffff"
        },
        layout: {
          "icon-image": ["concat", "pulsing-dot-", ["get","category"], "-", ["get","capacity"]],
          "text-field": ["get", "name"],
          "text-size": 12,
          "text-offset": [0, -3],
          "text-anchor": "top"
        }
      });
    },
    addPointLayerToMap(map, type) {
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      let geojson_url,
        icon = "";
      switch (type) {
        case "hospital":
          geojson_url = consts.url_geojson_hospital;
          icon = "hospital-15";
          break;
        case "hideaway":
          geojson_url = consts.url_geojson_hideaway;
          icon = "school-15";
          break;
        case "warehouse":
          geojson_url = consts.url_geojson_warehouse;
          icon = "parking-15";
          break;
      }
      if (geojson_url == "") {
        return;
      }
      var styleLoadStatus = map.isStyleLoaded();
      if (styleLoadStatus) {
        map.addSource(source_name, {
          type: "geojson",
          data: geojson_url
        });
        map.addLayer({
          id: layer_id,
          type: "symbol",
          source: source_name,
          layout: {
            "icon-image": icon,
            "text-field": "{name}",
            //"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-offset": [0, 0.6],
            "text-anchor": "top"
          }
        });
        return;
      }
      map.on("style.load", function() {
        map.addSource(source_name, {
          type: "geojson",
          data: geojson_url
        });
        map.addLayer({
          id: layer_id,
          type: "symbol",
          source: source_name,
          layout: {
            "icon-image": icon,
            "text-field": "{name}",
            //"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-offset": [0, 0.6],
            "text-anchor": "top"
          }
        });
      });
    },
    addBlockmapLayer(map, type, geoJsonData) {
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      map.addSource(source_name, {
        type: "geojson",
        data: geoJsonData
        //data: "./geojson/GeoJson-block.geojson"
      });
      map.addLayer({
        id: layer_id,
        type: "fill",
        source: source_name,
        layout: {},
        paint: {
          "fill-color": ["interpolate",["linear"],["get", "population"],
            500, "#c2d6ec",
            1000, "#4f71be",
            5000, "#253493",
          ],
          "fill-opacity": 1
        }
      });
      //离家人口分布要在
      var layer = map.getLayer('points');
      if (layer) {
        this.map.moveLayer(this.getMapLayerId(type), 'points');
      }
    },
    toggleMapLayer(type) {
      if (this[type]) {
        //勾选  
        if(type == 'seriousInjuredLayer') {
          //重伤
          this.fillHeatMap(this.city_id,'serious');
        } else {
          this.fillHeatMap(this.city_id,'slight');
        }
      } else {  
        if(type == 'seriousInjuredLayer') {
          //重伤
          this.removeMapLayer(this.map,'serious');
        } else {
          this.removeMapLayer(this.map,'slight');
        }
      }
    },
    handelMapLayer(type) {
      //console.log(type);
      this.selects.street = 0;
      this.selects.community = 0;
      this.loadData(type);
      let types = ["hospital", "hideaway", "warehouse"];
      types.forEach(value => {
        if (value == type) {
          this.addPointLayerToMap(this.map, value);
        } else {
          this.removeMapLayer(this.map, value);
        }
      });
    },
    getMapLayerId(type) {
      let layer_id = type + "-layer";
      return layer_id;
    },
    getMapSourceName(type) {
      let source_name = type + "-data";
      return source_name;
    },
    getRescueId(){
      let _this = this;
      //建个新数组，把选中的塞进去
      // this.selectedString = new Array(this[this.selects.data_category+'Options'].length).fill(0)
      // this[this.selects.data_category].forEach((item,idx)=>{
      //   this.selectedString[item-1] = 1;
      // })
      // this.selectedString = this.selectedString.join("");
      this.selectedString = new Array(this[this.selects.data_category+'Options'].length).fill(0)
      this[this.selects.data_category].forEach((item,idx)=>{
        //this.selectedString[item-1] = 1;
        this.selectedString[idx] = item?1:0;
      })
      this.selectedString = this.selectedString.join("");
      
      this.updateChart();
      this.map.on("load", function(){
        //设置方案小区对应
      })
      this.fillBuildings(this.city_id, 1);
      this.fillBuildingsMap(this.city_id, this.color_category);
      this.fillPointLayer()
      this.fillArcLayer()
      if(this.selects.data_category == 'serious') {
        this.slightInjuredLayer = false;
        // if(val == 'serious') {
        //   this.seriousInjuredLayer = this.slightInjuredLayer;
        //   this.slightInjuredLayer = false;
        // } else {
        //   this.slightInjuredLayer = this.seriousInjuredLayer;
        //   this.seriousInjuredLayer = false;
        // }
      } else {
        this.seriousInjuredLayer = false;
      }
      if(this.seriousInjuredLayer){
        this.fillHeatMap(this.city_id,'serious');
      } else if(this.slightInjuredLayer){
        this.fillHeatMap(this.city_id,'slight');
      }
      // getRescuePlan(this[this.selects.data_category].join("")).then(res => {
      //   this.planId = res.data;
      //   //获得方案
      //   //设置图表的避难所用量
      //   this.updateChart();
      //   this.map.on("load", function(){
      //     //设置方案小区对应
          
          
      //   })
      //   this.fillPointLayer()
      //   this.fillArcLayer()
      // });
    },
    removeMapLayer(map, type) {
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      var layer = map.getLayer(layer_id);
      let layer_point_id = layer_id + "point";
      //热点图的热点层
      if (map.getLayer(layer_point_id)) {
        map.removeLayer(layer_point_id);
      }
      if (layer) {
        map.removeLayer(layer_id);
      }
      if(map.getSource(source_name)) {
        map.removeSource(source_name);
      } 
    },
    removeHeatmapLayer(map, type) {
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      let layer_point_id = layer_id + "point";
      var layer = map.getLayer(layer_id);
      if (layer) {
        map.removeLayer(layer_id);
        map.removeLayer(layer_point_id);
        map.removeSource(source_name);
      }
    },
    loadData(type, page = 1) {
      switch (type) {
        case "people":
          this.fillPeopleData(page);
          break;
        case "gender":
          this.fillGenderData(page);
          break;
        case "hospital":
          this.fillHospitalsData(page);
          break;
        case "hideaway":
          this.fillHideawaysData();
          break;
        case "warehouse":
          this.fillWarehousesData();
          break;
      }
    },
    confirm () {
      if(this.selects.data_category == 'serious') {
        if(this.getCheckedTotal('serious')<4) {
          this.showErrMsg = true;
          return;
        }

      } else {
        if(this.getCheckedTotal('slight')<12) {
          this.showErrMsg = true;
          return;
        }
      }
      this.modals[this.selects.data_category] = false;
      this.showErrMsg = false;
      sessionStorage.setItem("checked_rescue_"+this.selects.data_category,JSON.stringify(this[this.selects.data_category]));
      this.getRescueId();
      
    },
    getCheckedTotal (type) {
      var tmp = 0;
      this[type].forEach(item=>{
        if(item === true){
          tmp++
        }
      })
      if(tmp == this[type].length) this['isCheckAll_'+type] = true;
      else this['isCheckAll_'+type] = false;
      return tmp;
    },
    handleCheckAllChange (type) {
      this[type].forEach((item,idx)=>{
        this[type][idx] = this['isCheckAll_'+type];
      })
    },
    handleCheckAllFilterChange (type) {
      this.selects[type].forEach((item,idx)=>{
        this.selects[type][idx] = this['isCheckAll'+type+'ForFilter'];
      })
      this.$nextTick(()=>{
        this.reloadData();
      })
    }
  }
};
</script>



<style lang="scss" scoped>
.card-map {
  min-height: 350px;
  height: calc(100vh - 48px);
  margin-bottom: 0;

  position: relative;
  .map-wrapper{
    width: 100%;
    height: calc(100vh - 100px);
    position: relative;
    & > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  #map {
    width: 100%;
    height: calc(100vh - 100px);
  }
  .map_data_msg {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    // color: #02f4fe;
    color: #fff;
    padding: 10px;
    width: auto;
    .map_data_legend {
      margin-bottom: 5px;
    }
    .map_data_msg_wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .item {
        width: auto;
        line-height: 15px;
        padding: 5px 20px 5px 0;
      }
      .item span {
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 100%;
        vertical-align: middle;
        margin-left: 0;
        position: relative;
        margin-right: 7px;
      }
      .item span:after {
        content: '';
        position: absolute;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        border: 1px solid rgba(255,255,255, 0.5);
        left: -5px;
        top: -5px;
      }
      .map_data_msg_inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 10px;
      }
    }
  }
}

.overlap_layer_desc {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .item {
    line-height: 15px;
    padding: 10px 10px 10px 0;
  }
  .item span {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 3px;
    vertical-align: top;
    margin-left: 0;
  }
  .map_data_msg_inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 10px;
  }
}

.main-panel {
  // height: calc(100vh - 130px);
  min-height: 350px;
}
.right-block {
  height: calc(100% - 131px);
  // min-height: 350px;
  overflow: hidden;
}
.tab-scroller {
  height: calc(100vh - 470px);
  min-height: 350px;
  overflow-y: auto;
}

.show_list {
  width: 100%;
  height: 50px;
  padding-top: 6px;

  // margin-top: 8px;
}
.show_list span {
  height: 32px;
  line-height: 36px;
  margin-left: 32px;
  font-size: 14px;
}
.show_list span i {
  height: 16px;
  padding: 2px 12px;
  background: #ffb236;
  margin: 0 4px;
  border-radius: 16px;
}
.show_list .up_show {
  display: block;
  float: left;
  font-size: 32px;
  cursor: pointer;
}
.show_list .download {
  display: block;
  float: right;
  font-size: 32px;
  cursor: pointer;
}
.footer {
  padding-top: 0;
}
.extended-forms .el-select {
  width: 100%;
  margin-bottom: 30px;
}

.extended-forms .progress {
  margin-bottom: 30px;
}

.switch-two-side {
  left: 160px;
}

@import url("https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css");
.coordinates {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;  
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 5px 10px;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  display: none;
}
ul.nav__items > li:last-of-type a {
  margin-right: 0 !important;
}
.card-body .tab-content .tab-scroller {
  min-height: auto;
}
.card-map .card-body {
  padding: 0;
}
.shelter-error-msg {
  color: #f00;
}
.chart-area {
  height: 400px;
}
#customStyle {
  padding-top: 190px!important;
}
.key-decision {
  height: 190px!important;
  .el-collapse {
    padding: 0;
    margin: 10px 0;
  }
  .decision-block {
    width: 194px;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.35em;
    border: none;
    margin: 10px 1px;
    border-radius: 0.1875rem;
    padding: 11px 22px;
    cursor: pointer;
    border-width: 1px;
    border-radius: 30px;
    padding-right: 23px;
    padding-left: 23px;
    background: #BEC3C7;
    color: #394761;
  }
  /deep/ .el-collapse-item__content {
    padding: 10px 0!important;
  }
}
</style>
<style lang="scss">
  .el-select .el-select__tags .el-tag:first-child {
    margin-left: 6px;
  }
  
  .el-select .el-tag {
    border-color: transparent;
  }
</style>  
