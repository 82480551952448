<template>
  <div class="row" style="margin:0 -30px;">
    <div class="col-md-9" style="padding:0">
      <card class="card-map">
        <!-- <div class="mask">
          <img src="img/loading.png" class="loading">
        </div>-->
        <div id="map"></div>
        <menu-map :map="map" :mapDefault="mapdefaultSetting"></menu-map>
        <div class="map_data_msg" v-show="selects.data_category == 1">
          <div class="map_data_legend">结构损伤状态</div>
          <div class="map_data_msg_wrapper">
            <div
              class="item"
              v-for="(item,index) in selects.physicallossOptions"
              :key="index"
              v-if="index>0"
            >
              <span :style="'background: '+item.color+''"></span>
              {{item.name}}
            </div>
          </div>
        </div>
        <div class="map_data_msg" v-show="selects.data_category == 2">
          <div class="map_data_legend">非结构损伤状态</div>
          <div class="map_data_msg_wrapper">
            <div
              class="item"
              v-for="(item,index) in selects.unphysicallossOptions"
              :key="index"
              v-if="index>0"
            >
              <span :style="'background: '+item.color+''"></span>
              {{item.name}}
            </div>
          </div>
        </div>
        <div class="show_list">
          <a class="up_show" v-on:click="showList">
            <i
              class="now-ui-icons"
              :class="[ show_list ? 'arrows-1_minimal-down':'arrows-1_minimal-up']"
            ></i>
          </a>
          <a class="download ml-auto" :href="downloadUrl">
            <i class="now-ui-icons arrows-1_cloud-download-93"></i>
          </a>
          <span>
            数据列表
            <i>{{total}}</i>
          </span>
        </div>
        <div
          class="show_list_record"
          v-show="show_list"
          :style="{'margin-top':-(data.length>0?180+data.length*38:0)+'px'}"
          ref="show_list_record"
        >
          <el-table :data="data" v-show="selects.data_category == 1">
            <el-table-column label="建筑ID" property="id"></el-table-column>
            <!-- <el-table-column label="经度" property="lng"></el-table-column>
            <el-table-column label="纬度" property="lat"></el-table-column> -->
            <el-table-column label="街道" property="street_name"></el-table-column>
            <el-table-column label="社区" property="community_name"></el-table-column>
            <el-table-column label="结构构件损伤">
              <el-table-column
                property="struck_breaking_s1"
                label="没有">
              </el-table-column>
              <el-table-column
                property="struck_breaking_s2"
                label="轻微">
              </el-table-column>
              <el-table-column
                property="struck_breaking_s3"
                label="中等">
              </el-table-column>
              <el-table-column
                property="struck_breaking_s4"
                label="严重">
              </el-table-column>
              <el-table-column
                property="struck_breaking_s5"
                label="完全">
              </el-table-column>
            </el-table-column>
            
          </el-table>
          <el-table :data="data" v-show="selects.data_category == 2">
            <el-table-column label="建筑ID" property="id"></el-table-column>
            <!-- <el-table-column label="经度" property="lng"></el-table-column>
            <el-table-column label="纬度" property="lat"></el-table-column> -->
            <el-table-column label="街道" property="street_name"></el-table-column>
            <el-table-column label="社区" property="community_name"></el-table-column>
            <el-table-column label="非结构构件损伤">
              <el-table-column
                property="no_struck_breaking_s1"
                label="没有">
              </el-table-column>
              <el-table-column
                property="no_struck_breaking_s2"
                label="轻微">
              </el-table-column>
              <el-table-column
                property="no_struck_breaking_s3"
                label="中等">
              </el-table-column>
              <el-table-column
                property="no_struck_breaking_s4"
                label="严重">
              </el-table-column>
              <el-table-column
                property="no_struck_breaking_s5"
                label="完全">
              </el-table-column>
            </el-table-column>
          </el-table>

          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class>
              <p class="card-category">显示： {{from + 1}} - {{to}} 总数： {{total}}</p>
            </div>
            <n-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              v-on:input="changePage"
            ></n-pagination>
          </div>
        </div>
      </card>
    </div>
     <div class="col-md-3 hasDecision" id="customStyle">
      <div class="col-md-12 clearfix key-decision" >
         <el-select
          class="select-default"
          size="large"
          placeholder="数据集类型选择"
          v-model="selects.data_category"
          v-on:change="reloadData"
        >
          <el-option
            v-for="option in selects.data_categories"
            class="select-primary"
            :value="option.id"
            :label="option.name"
            :key="option.id"
          ></el-option>
        </el-select>
      </div>
      <el-collapse v-model="activeNames">
        <!-- <el-collapse-item title="一致性 Consistency" name="1">
          <form>
            <div class="input-group no-border">
              <fg-input
                placeholder="输入关键字..."
                v-on:keyup.enter="reloadData"
                v-model="keyword"
                addon-right-icon="now-ui-icons ui-1_zoom-bold"
              ></fg-input>
              <div class="form-text" style="color:#999;margin-left:20px;">输入 建筑、街道、社区关键字</div>
            </div>
          </form>
        </el-collapse-item> -->
        <el-collapse-item title="信息图表" name="2">
          <div class="col-md-12" v-show="selects.data_category == 1">
            <div class="chart-area" id="box-chart" style="height:400px"></div>
          </div>
          <div class="col-md-12" v-show="selects.data_category == 2">
            <div class="chart-area" id="box-chart2" style="height:400px"></div>
          </div>
        </el-collapse-item>
        <el-collapse-item title="数据筛选" name="3">
          <div class="col-md-12">
              <el-select
                class="select-default"
                size="large"
                placeholder="街道"
                v-model="selects.street"
                v-on:change="reloadData('street')"
              >
                <el-option
                  v-for="option in selects.streets"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id"
                ></el-option>
              </el-select>
            </div>

            <div class="col-md-12">
              <el-select
                class="select-default"
                size="large"
                placeholder="社区"
                v-model="selects.community"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="option in selects.communities"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id"
                ></el-option>
              </el-select>
            </div>

            <div class="col-md-12">
              <el-select
                class="select-default"
                size="large"
                placeholder="结构类型"
                v-model="selects.struct"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="(option, index) in selects.structs"
                  class="select-primary"
                  :value="index"
                  :label="option"
                  :key="index"
                ></el-option>
              </el-select>
            </div>

            <div class="col-md-12">
              <el-select
                class="select-default"
                size="large"
                placeholder="功能类型"
                v-model="selects.category"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="(option, index) in selects.categories"
                  class="select-primary"
                  :value="index"
                  :label="option"
                  :key="index"
                ></el-option>
              </el-select>
            </div>

            <div class="col-md-12">
              <el-select
                class="select-default"
                size="large"
                placeholder="抗震设防烈度"
                v-model="selects.quakeLevel"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="option in selects.quakeLevels"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id"
                ></el-option>
              </el-select>
            </div>

            <div class="col-md-12">
              <el-select
                class="select-default"
                size="large"
                placeholder="修建年代"
                v-model="selects.buildYear"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="(option,index) in selects.buildYears"
                  class="select-primary"
                  :value="option"
                  :label="option"
                  :key="index"
                ></el-option>
              </el-select>
            </div>

            <div class="col-md-12" v-show="selects.data_category == 1">
              <el-select
                class="select-default"
                size="large"
                placeholder="结构构件损伤"
                v-model="selects.physicalloss"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="(option,index) in selects.physicallossOptions"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="index"
                ></el-option>
              </el-select>
            </div>

            <div class="col-md-12" v-show="selects.data_category == 2">
              <el-select
                class="select-default"
                size="large"
                placeholder="非结构构件损伤"
                v-model="selects.unphysicalloss"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="(option,index) in selects.unphysicallossOptions"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="index"
                ></el-option>
              </el-select>
            </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="key-infos">
      <div class="key-info" v-show="selects.data_category == 1">
        中等损伤及以上{{statistics.structureloss.total}}幢
      </div>
      <div class="key-info" v-show="selects.data_category == 2">
        中等损伤及以上{{statistics.unstructureloss.total}}幢
      </div>
    </div>
  </div>
</template>
<script>
import mapboxgl from "mapbox-gl";
import MenuMap from "src/components/Menumap";
import MiniMap from "src/mapbox/mapboxgl-minimap.js";
import { TabPane, Tabs, Switch } from "src/components";
import { Radio } from "src/components/index";
import { Checkbox } from "src/components/index";
import { Input, Button, Select, Option, Collapse, CollapseItem, Table, TableColumn } from "element-ui";
import geobuf from "geobuf";
import Pbf from "pbf";
import { consts } from "src/util/consts.js";
import {
  getCategories,
  getStreets,
  getCommunities,
  getPhysicalList,
  getPhysicalGeojson,
  getUnPhysicalList,
  getUnPhysicalGeojson,
  getStructuralDamageStatistics,
  getNonStructuralDamageStatistics
} from "src/api/building.js";
import { byStructLoss, byUnStructLoss } from "src/api/chart.js";
import { buildBarStackOption, buildBarOption } from "src/util/chart.js";
import {
  getBuildYearRange,
  storeMapOptions,
  getMapOptions,
  parseJsonToString
} from "src/util/common.js";
import { Pagination as NPagination } from "src/components";
import {
  getPeopleStat,
  getGenderStat,
  getHideaways,
  getHospitals,
  getWarehouses
} from "src/api/cityinfo.js";

import * as THREE from "three";

export default {
  components: {
    MenuMap,
    TabPane,
    Tabs,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    NPagination,
    [Switch.name]: Switch,
    Checkbox,
    Radio
  },
  data() {
    return {
      activeNames: ['2','3'],
      activeName: "first",
      tabs: {
        horizontal: "Profile",
        vertical: "Options",
        verticalPills: "Home",
        pageCategories: "Messages"
      },
      selects: {
        street: 0,
        streets: [{ id: 0, name: "街道" }],
        community: 0,
        communities: [{ id: 0, name: "社区" }],
        struct: 0,
        structs: consts.buildingStructs,
        physicalloss: 0,
        physicallossOptions: [
          { id: 0, name: "结构构件损伤" },
          { id: 1, name: "没有", color: "#62BFF5" },
          { id: 2, name: "轻微", color: "#70AD47" },
          { id: 3, name: "中等", color: "#FDF1D0" },
          { id: 4, name: "严重", color: "#D8833B" },
          { id: 5, name: "完全", color: "#D55745" }
        ],
        unphysicalloss: 0,
        unphysicallossOptions: [
          { id: 0, name: "非结构构件损伤" },
          { id: 1, name: "没有", color: "#62BFF5" },
          { id: 2, name: "轻微", color: "#70AD47" },
          { id: 3, name: "中等", color: "#FDF1D0" },
          { id: 4, name: "严重", color: "#D8833B" },
          { id: 5, name: "完全", color: "#D55745" }
        ],
        category: 0,
        categories: consts.buildingCategories,
        quakeLevel: 0,
        quakeLevels: [
          { id: 0, name: "抗震设防烈度" },
          { id: 6, name: 6 },
          { id: 7, name: 7 },
          { id: 8, name: 8 }
        ],
        buildYear: '修建年代',
        buildYears: consts.buildingBuildYears,
        data_category: 1,
        data_categories: [
          { id: 1, name: "结构构件损伤" },
          { id: 2, name: "非结构构件损伤" }
        ]
      },
      keyword: "",
      total: 0,
      data: [],
      page: 1,
      map: {},
      color_category: "struct",
      city_id: localStorage.getItem("city_id") || 1,
      show_list: false,
      show_list_height: 0,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 20
      },
      switchs: {
        people: true,
        gender: true,
        hideaway: true,
        hospital: true,
        warehouse: true
      },
      last_updated: consts.last_updated,
      pga: false,
      colors: ["#62BFF5", "#70AD47", "#FDF1D0", "#D8833B", "#D55745"],
      statistics: {
        structureloss: {total: ''},
        unstructureloss: {total: ''}
      },
      mapdefaultSetting: {},
      downloadUrl: ''
    };
  },

  mounted() {
    this.fillStreets(this.city_id);
    this.fillCommunities(this.city_id, 0);
    this.showChart(this.city_id);
    //this.fillBuildings(this.city_id, 1);
    this.initMap(this.city_id);
    //this.fillBuildingsMap(this.city_id, this.color_category);
    //this.addPointLayerToMap(this.map, "hospital");
    //this.addPointLayerToMap(this.map, "hideaway");
    //this.addPointLayerToMap(this.map, "warehouse");
    //this.reloadData(this.data_type);
  },

  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    // street() {
    //   return this.selects.street;
    // }
  },
  watch: {
    "selects.street"(val) {
      //街道切换选社区
      this.fillCommunities(this.city_id, val);
    }
  },
  methods: {
    // 初始化
    initMap(city_id) {
      let self = this;
      this.mapdefaultSetting = {
        center: consts.mapCenter[this.city_id-1],
        zoom: consts.mapDefaultZoom,
        pitch: 0,
        city_id: this.city_id
      }
      mapboxgl.accessToken = consts.mapboxAccessToken;
      let map_options = getMapOptions();
      //console.log(map_options);
      let zoom = map_options ? map_options.zoom : 12;
      let center = map_options
        ? [map_options.lng, map_options.lat]
        : consts.mapCenter[city_id - 1];
      var map = new mapboxgl.Map({
        container: "map",
        style: consts.mapBoxStyle,
        //center: [-87.61694, 41.86625],
        center: center,
        zoom: zoom,
        pitch: 0,
        bearing: 0,
        attributionControl: false
      });
      this.map = map;
      //定位图标
      map.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl
        })
      );
      this.map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: true
        })
      );
      //控制图层
      var nav = new mapboxgl.NavigationControl();
      this.map.addControl(nav, "top-right");
      //全屏按钮
      this.map.addControl(
        new mapboxgl.FullscreenControl({
          container: document.querySelector("body")
        })
      );
      map.on("load", function() {
        
      });
      //小地图
      //https://github.com/brendanmatkin/mapboxgl-minimap
      map.on("style.load", function() {
        self.reloadData();
        // Possible position values are 'bottom-left', 'bottom-right', 'top-left', 'top-right'
        map.addControl(
          new MiniMap({
            id: "mapboxgl-minimap",
            width: "200px",
            height: "150px",
            //style: "mapbox://styles/mapbox/streets-v10",
            style: consts.mapBoxStyle,
            fillColor: "#f58220",
            zoomAdjust: null
          }),
          "bottom-right"
        );
      });
      //记录地图设置
      map.on("move", function() {
        storeMapOptions({
          lng: map.getCenter().lng,
          lat: map.getCenter().lat,
          zoom: map.getZoom()
        });
      });
    },
    addBuildings2Map(map, geoJsonData) {
      let layer_id = "room-extrusion";
      let source_name = "geojson";
      var layer = map.getLayer(layer_id);
      var get_param = "status";
      if (layer) {
        map.removeLayer(layer_id);
        map.removeSource(source_name);
      }
      if(typeof geoJsonData.features == 'undefined') return;
      map.addSource(source_name, {
        type: "geojson",
        data: geoJsonData
        //data: "./geojson/GeoJson-2.2.2-3.geojson"
      });
      map.addLayer({
        id: layer_id,
        type: "fill-extrusion",
        source: source_name,
        //'source': {
        // GeoJSON Data source used in vector tiles, documented at
        // https://gist.github.com/ryanbaumann/a7d970386ce59d11c16278b90dde094d
        //  'type': 'geojson',
        //  'data':geoJsonData,
        //'data': "http://api.urban.test/api/buildings-geo-json?struct_id=2&category_id=1&street_id=1"
        //'data': "https://docs.mapbox.com/mapbox-gl-js/assets/indoor-3d-map.geojson"
        //},
        paint: {
          // See the Mapbox Style Specification for details on data expressions.
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions

          // Get the fill-extrusion-color from the source 'color' property.
          //structloss数值越小，影响小，楼越底
          "fill-extrusion-color": [
            "match",
            ["get", get_param],
            1,"#62BFF5",
            2,"#70AD47",
            3,"#FDF1D0",
            4,"#D8833B",
            5,"#D55745",
            "#D55745" // other
          ],
          // Get fill-extrusion-height from the source 'height' property.
          "fill-extrusion-height": ["get", "height"],

          // Get fill-extrusion-base from the source 'base_height' property.
          "fill-extrusion-base": ["get", "base_height"],

          // Make extrusions slightly opaque for see through indoor walls.
          "fill-extrusion-opacity": 1
        }
      });
    },
    showList() {
      this.show_list = !this.show_list;
    },
    donwloadList() {},
    showChart(city_id) {
      var container = document.querySelector('.el-collapse');
      var box = document.getElementById("box-chart");
      box.style.width = container.offsetWidth + "px";
      var myChart = this.$echarts.init(box);
      byStructLoss(city_id).then(res => {
        var chart_data = res.data;
        var chart_name = "结构构件损伤";
        chart_data.seriesData.map((item, idx) => {
          return (item.color = this.colors[idx]);
        });
        var option = buildBarStackOption(chart_name, chart_data,{xAxis: {name: '建筑数量',nameLocation: 'center','nameGap':30}});
        myChart.setOption(option);
      });

      var box2 = document.getElementById("box-chart2");
      box2.style.width = container.offsetWidth + "px";
      var myChart2 = this.$echarts.init(box2);

      byUnStructLoss(city_id).then(res => {
        var chart_data = res.data;
        var chart_name = "非结构构件损伤";
        chart_data.seriesData.map((item, idx) => {
          return (item.color = this.colors[idx]);
        });
        var option = buildBarStackOption(chart_name, chart_data,{xAxis: {name: '建筑数量',nameLocation: 'center','nameGap':30}});
        myChart2.setOption(option);
      });
      window.onresize = function() {
        document.querySelectorAll('.chart-area').forEach((item,idx)=>{
          item.style.width = document.querySelector('.el-collapse').offsetWidth+'px';
        })
        myChart.resize(); 
        myChart2.resize(); 
      }
    },

    fillStreets(city_id) {
      getStreets(city_id).then(res => {
        let data = res.data;
        data.unshift({ id: 0, name: "街道" });
        this.selects.streets = data;
      });
    },
    fillCommunities(city_id, street_id) {
      getCommunities(city_id, street_id).then(res => {
        let data = res.data;
        data.unshift({ id: 0, name: "社区" });
        this.selects.communities = data;
      });
    },
    fillPeopleData(page) {
      let params = this.buildParams();
      params.page = page;
      getPeopleStat(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillGenderData(page) {
      let params = this.buildParams();
      params.page = page;
      getGenderStat(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillHospitalsData(city_id, page) {
      let params = this.buildParams();
      params.page = page;
      getHospitals(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillHideawaysData(page) {
      let params = this.buildParams();
      params.page = page;
      getHideaways(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillWarehousesData(page) {
      let params = this.buildParams();
      params.page = page;
      getWarehouses(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillBuildings(city_id, page) {
      let params = this.buildParams();
      params.page = page;
      params.page_size = 10;
      if(this.selects.data_category == 1) {
        //结构
        getPhysicalList(params).then(res => {
          this.total = res.data.total;
          this.data = res.data.items;
        });
        getStructuralDamageStatistics(params).then(res => {
          this.statistics.structureloss.total = res.data.total;
        });
        this.downloadUrl = consts.requestUrl + consts.exportPhyicalLoss + parseJsonToString(params)
      } else {
        //非结构
        getUnPhysicalList(params).then(res => {
          this.total = res.data.total;
          this.data = res.data.items;
        });
        getNonStructuralDamageStatistics(params).then(res => {
          this.statistics.unstructureloss.total = res.data.total;
        });
        this.downloadUrl = consts.requestUrl + consts.exportUnPhyicalLoss + parseJsonToString(params)
      }
      
      
    },
    fillBuildingsMap(city_id, color_category) {
      let params = this.buildParams();
      if(this.selects.data_category == 1) {
        //结构
        getPhysicalGeojson(params).then(data => {
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addBuildings2Map(this.map,geoJson);
        });
      } else {
        //非结构
        getUnPhysicalGeojson(params).then(data => {
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addBuildings2Map(this.map,geoJson);
        });
      }
      // getGeoJson(params).then(res => {
      //   let geoJson = res.data;
      //   //structloss 1：没有 5：完全
      //   //mock数据开始
      //   geoJson.features.map((item,idx)=>{
      //     return item.properties.structloss = Math.ceil(Math.random()*4);
      //   })
      //   //mock数据结束
      //   this.addBuildings2Map(this.map, geoJson);
      // });

      //临时
      //this.addBuildings2Map(this.map);
    },
    reloadData(type) {
      if(type == 'street') {
        this.selects.community = 0;
      }
      this.setTipContent();
      this.fillBuildings(this.city_id, 1);
      this.fillBuildingsMap(this.city_id, this.color_category);
      //this.loadData(this.data_type);
    },
    setTipContent() {
      let html = ''
      if(this.selects.data_category == 1) {
        html = consts.tipsPhyicalLoss
      } else {
        html = consts.tipsUnPhyicalLoss
      }
      //console.log('3333',document.getElementById('left-tip-content'))
      document.getElementById('left-tip-content').innerHTML = html
    },
    buildParams() {
      let params = { city_id: this.city_id };
      params.street_id = this.selects.street;
      params.community_id = this.selects.community;
      params.struct_id = this.selects.struct;
      params.category_id = this.selects.category;
      params.quake_level = this.selects.quakeLevel;
      params.start_build_year = 0;
      params.end_build_year = 0;
        if(this.selects.buildYear != '修建年代'){
        params.start_build_year = this.selects.buildYear.split('-')[0];
        params.end_build_year = this.selects.buildYear.split('-')[1];
      }
      
      params.keyword = this.keyword;
      if(this.selects.data_category == 1) {
        params.status = this.selects.physicalloss;
      } else if (this.selects.data_category == 2){
        params.status = this.selects.unphysicalloss;
      }
      
      
      return params;
    },
    changePage: function(value) {
      this.fillBuildings(this.city_id, value);
    },
    addHeatmapLayer(map, type) {
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      let layer_point_id = layer_id + "point";
      // Add a geojson point source.
      // Heatmap layers also work with a vector tile source.
      map.addSource(source_name, {
        type: "geojson",
        data: "https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson"
      });

      map.addLayer(
        {
          id: layer_id,
          type: "heatmap",
          source: source_name,
          maxzoom: 9,
          paint: {
            // Increase the heatmap weight based on frequency and property magnitude
            "heatmap-weight": [
              "interpolate",
              ["linear"],
              ["get", "mag"],
              0,
              0,
              6,
              1
            ],
            // Increase the heatmap color weight weight by zoom level
            // heatmap-intensity is a multiplier on top of heatmap-weight
            "heatmap-intensity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              0,
              1,
              9,
              3
            ],
            // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
            // Begin color ramp at 0-stop with a 0-transparancy color
            // to create a blur-like effect.
            "heatmap-color": [
              "interpolate",
              ["linear"],
              ["heatmap-density"],
              0,
              "rgba(33,102,172,0)",
              0.2,
              "rgb(103,169,207)",
              0.4,
              "rgb(209,229,240)",
              0.6,
              "rgb(253,219,199)",
              0.8,
              "rgb(239,138,98)",
              1,
              "rgb(178,24,43)"
            ],
            // Adjust the heatmap radius by zoom level
            "heatmap-radius": [
              "interpolate",
              ["linear"],
              ["zoom"],
              0,
              2,
              9,
              20
            ],
            // Transition from heatmap to circle layer by zoom level
            "heatmap-opacity": ["interpolate", ["linear"], ["zoom"], 7, 1, 9, 0]
          }
        },
        "waterway-label"
      );

      map.addLayer(
        {
          id: layer_point_id,
          type: "circle",
          source: source_name,
          minzoom: 7,
          paint: {
            // Size circle radius by earthquake magnitude and zoom level
            "circle-radius": [
              "interpolate",
              ["linear"],
              ["zoom"],
              7,
              ["interpolate", ["linear"], ["get", "mag"], 1, 1, 6, 4],
              16,
              ["interpolate", ["linear"], ["get", "mag"], 1, 5, 6, 50]
            ],
            // Color circle by earthquake magnitude
            "circle-color": [
              "interpolate",
              ["linear"],
              ["get", "mag"],
              1,
              "rgba(33,102,172,0)",
              2,
              "rgb(103,169,207)",
              3,
              "rgb(209,229,240)",
              4,
              "rgb(253,219,199)",
              5,
              "rgb(239,138,98)",
              6,
              "rgb(178,24,43)"
            ],
            "circle-stroke-color": "white",
            "circle-stroke-width": 1,
            // Transition from heatmap to circle layer by zoom level
            "circle-opacity": ["interpolate", ["linear"], ["zoom"], 7, 0, 8, 1]
          }
        },
        "waterway-label"
      );
    },
    addPointLayerToMap(map, type) {
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      let geojson_url,
        icon = "";
      switch (type) {
        case "hospital":
          geojson_url = consts.url_geojson_hospital;
          icon = "hospital-15";
          break;
        case "hideaway":
          geojson_url = consts.url_geojson_hideaway;
          icon = "school-15";
          break;
        case "warehouse":
          geojson_url = consts.url_geojson_warehouse;
          icon = "parking-15";
          break;
      }
      if (geojson_url == "") {
        return;
      }
      var styleLoadStatus = map.isStyleLoaded();
      if (styleLoadStatus) {
        map.addSource(source_name, {
          type: "geojson",
          data: geojson_url
        });
        map.addLayer({
          id: layer_id,
          type: "symbol",
          source: source_name,
          layout: {
            "icon-image": icon,
            "text-field": "{name}",
            //"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-offset": [0, 0.6],
            "text-anchor": "top"
          }
        });
        return;
      }
      map.on("load", function() {
        map.addSource(source_name, {
          type: "geojson",
          data: geojson_url
        });
        map.addLayer({
          id: layer_id,
          type: "symbol",
          source: source_name,
          layout: {
            "icon-image": icon,
            "text-field": "{name}",
            //"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-offset": [0, 0.6],
            "text-anchor": "top"
          }
        });
      });
    },
    toggleMapLayer(type) {
      //console.log(type);
      this.selects.street = 0;
      this.selects.community = 0;
      this.loadData(type);
      if (this[type]) {
        //勾选
        this.addHeatmapLayer(this.map, type);
      } else {
        this.removeHeatmapLayer(this.map, type);
      }
    },
    handelMapLayer(type) {
      //console.log(type);
      this.selects.street = 0;
      this.selects.community = 0;
      this.loadData(type);
      let types = ["hospital", "hideaway", "warehouse"];
      types.forEach(value => {
        if (value == type) {
          this.addPointLayerToMap(this.map, value);
        } else {
          this.removeMapLayer(this.map, value);
        }
      });
    },
    getMapLayerId(type) {
      let layer_id = type + "-layer";
      return layer_id;
    },
    getMapSourceName(type) {
      let source_name = type + "-data";
      return source_name;
    },
    removeMapLayer(map, type) {
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      var layer = map.getLayer(layer_id);
      if (layer) {
        map.removeLayer(layer_id);
        map.removeSource(source_name);
      }
    },
    removeHeatmapLayer(map, type) {
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      let layer_point_id = layer_id + "point";
      var layer = map.getLayer(layer_id);
      if (layer) {
        map.removeLayer(layer_id);
        map.removeLayer(layer_point_id);
        map.removeSource(source_name);
      }
    },
    loadData(type, page = 1) {
      switch (type) {
        case "people":
          this.fillPeopleData(page);
          break;
        case "gender":
          this.fillGenderData(page);
          break;
        case "hospital":
          this.fillHospitalsData(page);
          break;
        case "hideaway":
          this.fillHideawaysData();
          break;
        case "warehouse":
          this.fillWarehousesData();
          break;
      }
    }
  }
};
</script>



<style lang="scss" scoped>
.card-map {
  min-height: 350px;
  height: calc(100vh - 48px);
  margin-bottom: 0;

  position: relative;
  #map {
    width: 100%;
    height: calc(100vh - 100px);
  }
  .map_data_msg {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    // color: #02f4fe;
    color: #fff;
    padding: 10px;
    width: auto;
    .map_data_legend {
      margin-bottom: 5px;
    }
    .map_data_msg_wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .item {
        width: 80px;
        line-height: 15px;
        padding: 5px 0;
      }
      .item span {
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 3px;
        vertical-align: middle;
        margin-left: 0;
      }
      .map_data_msg_inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 10px;
      }
    }
  }
}

.main-panel {
  // height: calc(100vh - 130px);
  min-height: 350px;
}
.right-block {
  height: calc(100% - 131px);
  // min-height: 350px;
  overflow: hidden;
}
.tab-scroller {
  // height: calc(100vh - 470px);
  // min-height: 350px;
  // overflow-y: auto;
}

.show_list {
  width: 100%;
  height: 50px;
  padding-top: 6px;

  // margin-top: 8px;
}
.show_list span {
  height: 32px;
  line-height: 36px;
  margin-left: 32px;
  font-size: 14px;
}
.show_list span i {
  height: 16px;
  padding: 2px 12px;
  background: #ffb236;
  margin: 0 4px;
  border-radius: 16px;
}
.show_list .up_show {
  display: block;
  float: left;
  font-size: 32px;
  cursor: pointer;
}
.show_list .download {
  display: block;
  float: right;
  font-size: 32px;
  cursor: pointer;
}
.footer {
  padding-top: 0;
}
.extended-forms .el-select {
  width: 100%;
  margin-bottom: 30px;
}

.extended-forms .progress {
  margin-bottom: 30px;
}
@import url("https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css");
.coordinates {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 5px 10px;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  display: none;
}
ul.nav__items > li:last-of-type a {
  margin-right: 0 !important;
}
.card-body .tab-content .tab-scroller {
  min-height: auto;
}
.card-map .card-body {
  padding: 0;
}



</style>
<style>
  /* .show_list_record .el-table th>.cell {
    text-align: center;
  } */
  .el-table thead.is-group th {
  background: transparent;
}
  .el-table--border, .el-table--group {
  border: 0;
}
.el-table--border td {
  border-right: 0;
}
.el-table--border::after, .el-table--group::after {
  display: none;
}
</style>