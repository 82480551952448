<template>
  <div class="row" style="margin:0 -30px;">
    <div class="col-md-9" style="padding:0">
      <card class="card-map">
        <div id="map"></div>
        <menu-map :map="map" :mapDefault="mapdefaultSetting"></menu-map>
        <div class="recovery_days">
          <p class="recovery_lable">修复时间</p>
          <div class="timeline-controller" @click="playTimeline">
            <i class="el-icon-video-play" v-if="!playing"></i>
            <i class="el-icon-video-pause" v-if="playing" ></i>
          </div>
          <div class="bar">
            <slider v-model="recovery_days" :options="{step: 1}" :range="{'min': [0], 'max': [25]}"></slider> 
          </div>
           <p class="start_day">0天</p>
          <p class="end_day">25天</p>

        </div>
        <div class="map_data_msg">
          <!-- <div class="map_data_legend">物理损伤概率</div> -->
          <div class="map_data_msg_wrapper" v-show="selects.data_category == 0">
            <div class="column">
                <div class="map_data_subtitle">节点修复状态</div>
                <div
                class="item"
                v-for="(item,index) in options[5].data"
                :key="index"
                >
                <span :style="'border:2px solid #fff;background: '+colors[0][index]" class="round"  :class="item.indexOf('正修')>-1?'bold-circle':''"></span>
                <!-- <img :src="figures[0][index]" class="figure"/> -->
                </div>
            </div>
            <div class="column">
                <div class="placeholder">数值</div>
                <div
                class="item"
                v-for="(item,index) in options[5].data"
                :key="index"
                >
                 {{item}}
                </div>
                
            </div>
          </div>

          <div class="map_data_msg_wrapper" v-show="selects.data_category == 1">
            
            <div class="column column-2">
                <div class="map_data_subtitle">社区供电比率</div>
                <div
                class="item"
                v-for="(item,index) in options[2].data"
                :key="index"
                >
                <span :style="'background: '+colors[3][index]"></span>
                <!-- <img :src="figures[0][index]" class="figure"/> -->
                {{item.name}}
                </div>
                
            </div>
            <div class="column column-3">
                <div class="map_data_subtitle">节点功能状态</div>
                <div
                class="item"
                v-for="(item,index) in options[4].data"
                :key="index"
                >
                <span :style="'background: '+colors[4][index]" class="round"></span>
                <!-- <img :src="figures[0][index]" class="figure"/> -->
                {{item.name}}
                </div>
                
            </div>
          </div>
        </div>
        <div class="show_list">
          <a class="up_show" v-on:click="showList">
            <i
              class="now-ui-icons"
              :class="[ show_list ? 'arrows-1_minimal-down':'arrows-1_minimal-up']"
            ></i>
          </a>
          <a class="download"  :href="downloadUrl">
            <i class="now-ui-icons arrows-1_cloud-download-93"></i>
          </a>
          <span>
            数据列表
            <!-- <i>{{total}}</i> -->
          </span>
        </div>
        <div class="show_list_record" v-show="show_list" :style1="{'top':'-556px','height': '506px'}"
          ref="show_list_record">
          <tabs type="primary" v-model="activeTab" v-show="selects.data_category == 0">
            <tab-pane label="节点">
              <el-table :data="data1"  empty-text = "暂无数据！">
                <el-table-column min-width="150" label="节点ID" property="node_id"></el-table-column>
                <el-table-column min-width="150" label="节点名" property="name"></el-table-column>
                <el-table-column min-width="150" label="灾后损伤状态" property="status"></el-table-column>
                <el-table-column min-width="150" label="当前修复状态" property="repair_status"></el-table-column>
                <el-table-column min-width="150" label="施工开始时间" property="work_start_day"></el-table-column>
                <el-table-column min-width="150" label="施工结束时间" property="work_end_day"></el-table-column>
                <el-table-column min-width="150" label="维修工期" property="work_days"></el-table-column>
              </el-table>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class>
                  <p class="card-category">显示： {{from1 + 1}} - {{to1}} 总数： {{total1}}</p>
                </div>
                <n-pagination
                  class="pagination-no-border"
                  v-model="pagination1.currentPage"
                  :per-page="pagination1.perPage"
                  :total="total1"
                  v-on:input="changePage1"
                ></n-pagination>
              </div>
            </tab-pane>

          </tabs>
           <tabs type="primary" v-model="activeTab" v-show="selects.data_category == 1">
            <tab-pane label="社区">
              <el-table :data="data1"  empty-text = "暂无数据！">
                <el-table-column min-width="150" label="社区ID" property="id"></el-table-column>
                <el-table-column min-width="150" label="社区" property="name"></el-table-column>
                <el-table-column min-width="150" label="街道" property="street_name"></el-table-column>
                <el-table-column min-width="150" label="供电比率（当前时刻）" property="demand_rate"></el-table-column>
              </el-table>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class>
                  <p class="card-category">显示： {{from1 + 1}} - {{to1}} 总数： {{total1}}</p>
                </div>
                <n-pagination
                  class="pagination-no-border"
                  v-model="pagination1.currentPage"
                  :per-page="pagination1.perPage"
                  :total="total1"
                  v-on:input="changePage1"
                ></n-pagination>
              </div>
            </tab-pane>

            <tab-pane label="节点">
              <el-table :data="data2"  empty-text = "暂无数据！">
                <el-table-column min-width="150" label="节点ID" property="node_id"></el-table-column>
                <el-table-column min-width="150" label="节点名" property="name"></el-table-column>
                <el-table-column min-width="150" label="灾后功能状态" property="function_status"></el-table-column>
              </el-table>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class>
                  <p class="card-category">显示： {{from2 + 1}} - {{to2}} 总数： {{total2}}</p>
                </div>
                <n-pagination
                  class="pagination-no-border"
                  v-model="pagination2.currentPage"
                  :per-page="pagination2.perPage"
                  :total="total2"
                  v-on:input="changePage2"
                ></n-pagination>
              </div>
            </tab-pane>

          </tabs>
        </div>
      </card>
    </div>
     <div class="col-md-3 hasDecision" id="customStyle">
      <div class="col-md-12 clearfix key-decision" >
          <el-select
            class="select-default"  
            size="large"
            placeholder="数据集类型选择"
            v-model="selects.data_category"
            v-on:change="reloadData"
          >
            <el-option
              v-for="option in selects.data_categories"
              class="select-primary"
              :value="option.id"
              :label="option.name"
              :key="option.id"
            ></el-option>
          </el-select>

          <el-collapse v-model="activeNameskey">
          <el-collapse-item title="决策信息" name="1">
            <div class="col-md-12 clearfix decision-wrapper">
              <div class="decision-block">
                <span class="demonstration">施工队数量</span>
              </div>
              <slider class="silide-wrapper" v-model="worker_number" :options="{step: 1}" :range="{'min': [1], 'max': [5]}" disabled=""></slider>
              <p class="slide-start">1</p>
              <p class="slide-end">5</p>
            </div>
          </el-collapse-item>
        </el-collapse>
          
      </div>
      <el-collapse v-model="activeNames" class="el-collapse-main">
        <el-collapse-item title="信息图表" name="1">
          <div class="col-md-12">
              <div class="chart-area" id="box-chart" style="height: 400px" v-show="selects.data_category == 0"></div>
              <div class="chart-area" id="box-chart2" style="height: 400px" v-show="selects.data_category == 1"></div>
              <div class="chart-area" id="box-chart3" style="height: 300px" v-show="selects.data_category == 1"></div>
            </div>
        </el-collapse-item>
        <el-collapse-item title="数据筛选" name="3">
          <div class="col-md-12">
              <form method="get" action="/">
                <!-- <div class="row">
                  <div class="col-sm-10">
                    <el-select
                      class="select-default"
                      size="large"
                      placeholder="街道"
                      v-model="selects.street"
                      v-on:change="reloadData"
                    >
                      <el-option
                        v-for="option in selects.streets"
                        class="select-primary"
                        :value="option.id"
                        :label="option.name"
                        :key="option.id"
                      ></el-option>
                    </el-select>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-10">
                    <el-select
                      class="select-default"
                      size="large"
                      placeholder="社区"
                      v-model="selects.community"
                      v-on:change="reloadData"
                    >
                      <el-option
                        v-for="option in selects.communities"
                        class="select-primary"
                        :value="option.id"
                        :label="option.name"
                        :key="option.id"
                      ></el-option>
                    </el-select>
                  </div>
                </div> -->
                <p class="subtitle" v-show="selects.data_category == 0">节点</p>

                <div class="row" v-show="selects.data_category == 0">
                  <div class="col-sm-10">
                    <el-select
                      class="select-default"
                      size="large"
                      :placeholder="options[0].name"
                      v-model="options[0].selected"
                      v-on:change="reloadData"
                      multiple="" 
                    >
                      <el-option
                        v-for="(option,index) in options[0].data"
                        class="select-primary"
                        :value="option.id"
                        :label="option.name"
                        :key="option.id"
                      ></el-option>
                    </el-select>
                    <i class="el-icon-refresh-left" @click="clearAll(0)"></i>
                  </div>
                </div>

                <div class="row" v-show="selects.data_category == 0">
                  <div class="col-sm-10">
                    <el-select
                      class="select-default"
                      size="large"
                      :placeholder="options[1].name"
                      v-model="options[1].selected"
                      v-on:change="reloadData"
                      multiple=""
                    >
                      <el-option
                        v-for="(option,index) in options[1].data"
                        class="select-primary"
                        :value="option.id"
                        :label="option.name"
                        :key="option.id"
                      ></el-option>
                    </el-select>
                    <i class="el-icon-refresh-left" @click="clearAll(1)"></i>
                  </div>
                </div>

                <p class="subtitle" v-show="selects.data_category == 1">区域</p>

                <div class="row" v-show="selects.data_category == 1">
                  <div class="col-sm-10">
                    <el-select
                      class="select-default"
                      size="large"
                      placeholder="街道"
                      v-model="selects.street"
                      v-on:change="reloadData('street')"
                    >
                      <el-option
                        v-for="option in selects.streets"
                        class="select-primary"
                        :value="option.id"
                        :label="option.name"
                        :key="option.id"
                      ></el-option>
                    </el-select>
                    <i class="el-icon-refresh-left" @click="clearAll('street')"></i>
                    
                  </div>
                </div>

                <div class="row" v-show="selects.data_category == 1">
                  <div class="col-sm-10">
                    <el-select
                      class="select-default"
                      size="large"
                      placeholder="社区"
                      v-model="selects.community"
                      v-on:change="reloadData"
                    >
                      <el-option
                        v-for="option in selects.communities"
                        class="select-primary"
                        :value="option.id"
                        :label="option.name"
                        :key="option.id"
                      ></el-option>
                    </el-select>
                    <i class="el-icon-refresh-left" @click="clearAll('community')"></i>
                  </div>
                </div>

                <div class="row" v-show="selects.data_category == 1">
                  <div class="col-sm-10">
                    <el-select
                      class="select-default"
                      size="large"
                      :placeholder="options[2].name"
                      v-model="options[2].selected"
                      v-on:change="reloadData"
                      multiple=""
                    >
                      <el-option
                        v-for="(option,index) in options[2].data"
                        class="select-primary"
                        :value="option.id"
                        :label="option.name"
                        :key="option.id"
                      ></el-option>
                    </el-select>
                    <i class="el-icon-refresh-left" @click="clearAll(2)"></i>
                  </div>
                </div>

                <p class="subtitle" v-show="selects.data_category == 1">节点</p>

                <div class="row" v-show="selects.data_category == 1">
                  <div class="col-sm-10">
                    <el-select
                      class="select-default"
                      size="large"
                      :placeholder="options[3].name"
                      v-model="options[3].selected"
                      v-on:change="reloadData"
                      multiple=""
                    >
                      <el-option
                        v-for="(option,index) in options[3].data"
                        class="select-primary"
                        :value="option.id"
                        :label="option.name"
                        :key="option.id"
                      ></el-option>
                    </el-select>
                    <i class="el-icon-refresh-left" @click="clearAll(3)"></i>
                  </div>
                </div>

                <div class="row" v-show="selects.data_category == 1">
                  <div class="col-sm-10">
                    <el-select
                      class="select-default"
                      size="large"
                      :placeholder="options[4].name"
                      v-model="options[4].selected"
                      v-on:change="reloadData"
                      multiple=""
                    >
                      <el-option
                        v-for="(option,index) in options[4].data"
                        class="select-primary"
                        :value="option.id"
                        :label="option.name"
                        :key="option.id"
                      ></el-option>
                    </el-select>
                    <i class="el-icon-refresh-left" @click="clearAll(4)"></i>
                  </div>
                </div>

               


              </form>
            </div>
        </el-collapse-item>
      </el-collapse>

      
    </div>
    <div class="key-infos">
      <div class="key-info" v-show="selects.data_category == 0">
        工期共25天，当前{{statistics.count1}}个节点正在施工
      </div>
      <div class="key-info" v-show="selects.data_category == 1">
        工期共25天，当前供电比率{{statistics.count3}}%、影响人口比例{{statistics.count4}}%
      </div>
      
    </div>
  </div>
</template>
<script>
import mapboxgl from "mapbox-gl";
import MenuMap from "src/components/Menumap";
import SearchArea from "src/components/SearchArea";
import MiniMap from "src/mapbox/mapboxgl-minimap.js";
import { Slider, TabPane, Tabs, Switch } from "src/components";
import geobuf from "geobuf";
import Pbf from "pbf";
import { Checkbox } from "src/components/index";
import { Input, Button, Select, Option, Collapse, CollapseItem, Table, TableColumn } from "element-ui";
import { consts } from "src/util/consts.js";
import {
  getStreets,
  getCommunities,
} from "src/api/building.js";
import {
  getCommonNodeGeojson as getNodeGeo,
  getCommonPipeGeojson as getPipeGeo,
  getNodeTypeOptions as getOptions1,
  getRepairNodeOptions as getOptions2,
  getFunctionWaterOptions as getOptions3,
  getFunctionNodeOptions as getOptions4,
  getRepairNodeList as getList1,
  getFunctionElectricList as getList2,
  getFunctionNodeList as getList3,
//   getNodeElevationList as getList5,
//   getNodeElevationList as getList6,

  getRepairNodeGeojson as getGeo1,
  getFunctionElectricGeojson as getGeo2,
  getFunctionNodeGeojson as getGeo3,
  
  getRepairNodeChart as getChart1,
  getFunctionElectricChart as getChart2,
  getFunctionNodeChart as getChart3,
  
  
  getRepairNodeStatistics as getStatistics1,
  getFunctionElectricStatistics as getStatistics2,
  getFunctionNodeStatistics as getStatistics3,
  
} from "src/api/earthquake/electric.js";
import { 
  buildBarYOption,
  buildBarStackOption,
  buildlineOption,
  buildPieOption } from "src/util/chart.js";
import {
  storeMapOptions,
  getMapOptions,
  parseJsonToString,
  setZoomLevel
} from "src/util/common.js";
import { Pagination as NPagination } from "src/components";
import * as THREE from "three";

export default {
  components: {
    MenuMap,
    Slider,
    SearchArea,
    TabPane,
    Tabs,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    NPagination,
    [Switch.name]: Switch,
    Checkbox,
  },
  data() {
    return {
      activeNameskey: ['1'],
      activeNames: ['1','2','3'],
      activeName: "first",
      tabs: {
        horizontal: "Profile",
        vertical: "Options",
        verticalPills: "Home",
        pageCategories: "Messages"
      },
      selects: {
        street: 0,
        streets: [{ id: 0, name: "街道" }],
        community: 0,
        communities: [{ id: 0, name: "社区" }],
        data_category: 0,
        data_categories: [
          { id: 0, name: "节点修复" },
          { id: 1, name: "功能恢复" },
        ],
      },
      keyword: "",
      total1: 0,
      total2: 0,
      data1: [],
      data2: [],
      page: 1,
      map: {},
      color_category: "build_year",
      city_id: localStorage.getItem("city_id") || 1,
      show_list: false,
      show_list_height: 0,
      pagination1: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 20
      },
      pagination2: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 20
      },
      last_updated: consts.last_updated,
      
      statistics: {
        year: 0
      },
      mapdefaultSetting: {},
      downloadUrl: '',

      options: [
        {name: '节点类型',selected:[],data: []},
        {name: '修复状态',selected:[],data: []},
        {name: '供电比率',selected:[],data: []},
        {name: '节点类型',selected:[],data: []},
        {name: '功能状态',selected:[],data: []},
        {name: '修复状态',data: ['未修','正修-施工队1','正修-施工队2','完好']},
      ],
      colors: [
        ['#717471','#be0001','#fec20b','#0800ee'],
        ['#717471','#be0001','#fec20b','#0800ee'],
        ["rgba(0,0,0,0)", '#be0001', '#fec20b'],
        ['#c00d00','#ff1600','#ed7d32','#ffc000','#ffff00'],
        ['#73fdd6','#ff0012'],
        ['#07affc','#ffa02a']
      ],
      figures: [
        [],
        []
      ],
      activeTab: '节点',
      worker_number: 2, //工人队数量
      recovery_days: 0, //修复时间
      playing: false,
      itv: null,

      
     
      //colors: ['#D55328','#F5C243','#447DB3','#62BFF5','#70AD47','#94CFBF','#B22FB9','#9c0003']
    };
  },

  mounted() {
    this.fillStreets(this.city_id);
    // this.fillCommunities(this.city_id, 0);
    this.showChart(this.city_id);
    this.fillFigure()
    
    //this.fillList(this.city_id, 1);
    this.initMap(this.city_id);
    this.setTipContent();
    this.map.on('style.load',()=>{
      this.fillCommonGeo();
      this.reloadData()
      //this.fill2Map(this.city_id, this.color_category);
    })
  },
  computed: {
    to1() {
      let highBound = this.from1 + this.pagination1.perPage;
      if (this.total1 < highBound) {
        highBound = this.total1;
      }
      return highBound;
    },
    from1() {
      return this.pagination1.perPage * (this.pagination1.currentPage - 1);
    },
    to2() {
      let highBound = this.from2 + this.pagination2.perPage;
      if (this.total2 < highBound) {
        highBound = this.total2;
      }
      return highBound;
    },
    from2() {
      return this.pagination2.perPage * (this.pagination2.currentPage - 1);
    },
    street() {
      return this.selects.street;
    }
  },
  watch: {
    worker_number(val){
      //this.worker_number = parseInt(val)
      this.reloadPlan(val)
    },
    recovery_days(val){
      this.updateDays(val)
    },
    "selects.street"(val) {
      //街道切换选社区
      this.fillCommunities(this.city_id, val);
    },
    "selects.data_category"(val) {
        this.fillList1(this.city_id, 1);
        this.fillList2(this.city_id, 1);
    },
    activeTab(val){
      let params = Object.assign({},this.buildParams());
      if(val == '节点') {
        if(this.selects.data_category == 0) {
            params.type_ids = (this.options[0].selected.length==0 || this.options[0].selected.length == this.options[0].data.length)?0: (this.options[0].selected.sort((a,b)=>a - b).join("-"));
            params.repair_status = (this.options[1].selected.length==0 || this.options[1].selected.length == this.options[1].data.length)?0: (this.options[1].selected.sort((a,b)=>a - b).join("-"));
            this.downloadUrl = consts.requestUrl + consts.exportExportRepairsNode + parseJsonToString(params)
        } else {
            params.type_ids = (this.options[3].selected.length==0 || this.options[3].selected.length == this.options[3].data.length)?0: (this.options[3].selected.sort((a,b)=>a - b).join("-"));
            params.function_status = (this.options[4].selected.length==0 || this.options[4].selected.length == this.options[4].data.length)?0: (this.options[4].selected.sort((a,b)=>a - b).join("-"));
            this.downloadUrl = consts.requestUrl + consts.exportExportFunctionNode + parseJsonToString(params)
        }
        
      } else if (val == '社区') {
        //params.demand_rates = (this.options[6].selected.length==0 || this.options[6].selected.length == this.options[6].data.length)?0: (this.options[6].selected.sort((a,b)=>a - b).join("-"));
        //this.downloadUrl = consts.requestUrl + consts.exportExportFunctionArea + parseJsonToString(params)
      }
    },
  },

  methods: {
    // 初始化
    initMap(city_id) {
      let _this = this;
      this.mapdefaultSetting = {
        center: consts.mapCenter[this.city_id-1],
        zoom: consts.mapDefaultZoom,
        pitch: 0,
        city_id: this.city_id
      }
      mapboxgl.accessToken = consts.mapboxAccessToken;
      let map_options = getMapOptions();
      //console.log(map_options);
      let zoom = map_options ? map_options.zoom : 12;
      let center = map_options
        ? [map_options.lng, map_options.lat]
        : consts.mapCenter[city_id - 1];
      var map = new mapboxgl.Map({
        container: "map",
        style: consts.mapBoxStyle,
        //center: [-87.61694, 41.86625],
        center: center,
        zoom: zoom,
        pitch: 0,
        bearing: 0,
        attributionControl: false
      });
      this.map = map;
      //定位图标
      map.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl
        })
      );
      this.map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: true
        })
      );
      //控制图层
      var nav = new mapboxgl.NavigationControl();
      this.map.addControl(nav, "top-right");
      //全屏按钮
      this.map.addControl(
        new mapboxgl.FullscreenControl({
          container: document.querySelector("body")
        })
      );
      //小地图
      //https://github.com/brendanmatkin/mapboxgl-minimap
      map.on("style.load", function() {
        // Possible position values are 'bottom-left', 'bottom-right', 'top-left', 'top-right'
        map.addControl(
          new MiniMap({
            id: "mapboxgl-minimap",
            width: "200px",
            height: "150px",
            //style: "mapbox://styles/mapbox/streets-v10",
            style: consts.mapBoxStyle,
            fillColor: "#f58220",
            zoomAdjust: null
          }),
          "bottom-right"
        );
        // map.loadImage('/img/icons/icon-red.png', function(error, image) {
        //     if (error) throw error;
        //     map.addImage('icon-1', image);
        // });
        _this.makePointImage(map, 1)
        map.loadImage('/img/icons/icon-benzhan.png', function(error, image) {
            if (error) throw error;
            map.addImage('icon-2', image);
        });
        // map.loadImage('/img/icons/icon-yellow.png', function(error, image) {
        //     if (error) throw error;
        //     map.addImage('icon-3', image);
        // });
        _this.makePointImage(map, 3)
        map.loadImage('/img/icons/icon-shuita.png', function(error, image) {
            if (error) throw error;
            map.addImage('icon-4', image);
        });
      });
      //记录地图设置
      map.on("move", function() {
        storeMapOptions({
          lng: map.getCenter().lng,
          lat: map.getCenter().lat,
          zoom: map.getZoom()
        });
      });
    },
    fillFigure(){
      //节点类型
      getOptions1().then(res => {
        let data = res.data;
        this.options[0].data = data;
        this.options[3].data = data;
      });
      //修复状态
      getOptions2().then(res => {
        let data = res.data;
        this.options[1].data = data;
      });
      //供电率
      getOptions3().then(res => {
        let data = res.data;
        this.options[2].data = data;
      });
      //功能状态
      getOptions4().then(res => {
        let data = res.data;
        this.options[4].data = data;
      });
    },
    fillCommonGeo(){
        let params = this.buildParams();
        getPipeGeo(params).then(res => {
          let data = res;
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addLineLayerToMap(this.map, 'pipeCommon', geoJson);    
        });
         getNodeGeo(params).then(res => {
          let data = res;
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addCircle2Map(this.map, 'nodeCommon', geoJson);    
        });
        
        
    },
    addBuildings2Map(map, geoJsonData, type) {
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      
      map.addSource(source_name, {
        type: "geojson",
        data: geoJsonData
      });
      map.addLayer({
        id: layer_id,
        type: "fill-extrusion",
        source: source_name,
        paint: {
          // See the Mapbox Style Specification for details on data expressions.
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions

          // Get the fill-extrusion-color from the source 'color' property.
          "fill-extrusion-color": "#789da5",

          // Get fill-extrusion-height from the source 'height' property.
          "fill-extrusion-height": ["get", "height"],

          // Get fill-extrusion-base from the source 'base_height' property.
          "fill-extrusion-base": ["get", "base_height"],

          // Make extrusions slightly opaque for see through indoor walls.
          "fill-extrusion-opacity": 0.8
        }
      });
    },
    makePointImage(map, type){
      let size = 150;
      switch(type){
        case 1: //红点
          size = 200;
        break;
        case 3:
          size = 100;
        break;
      }
      //point type
      let pulsingDot  = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // get rendering context for the map canvas when layer is added to the map
        onAdd: function() {
          var canvas = document.createElement("canvas");
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext("2d");
        },

        // called once before every frame where the icon will be used
        render: function() {
          var duration = 1000;
          var t = (performance.now() % duration) / duration;

          var radius = (size / 2) * 0.3;
          var outerRadius = (size / 2) * 0.7 * t + radius;
          var context = this.context;

          // draw outer circle
          if(type == 1) {
            context.clearRect(0, 0, this.width, this.height);
            context.beginPath();
            context.arc(
              this.width / 2,
              this.height / 2,
              outerRadius,
              0,
              Math.PI * 2
            );
            switch(type){
              case 1:
                context.fillStyle = "rgba(213, 83, 40," + (1 - t) + ")";
              break;
              case 3:
                context.fillStyle = "rgba(245, 194, 67, 1)";
                break;
            }
            
            context.fill();
          }

          // draw inner circle
          context.beginPath();
          context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
          switch(type){
            case 1:
              context.fillStyle = "rgba(213, 83, 40, 1)";
            break;
            case 3:
              context.fillStyle = "rgba(246, 194, 67, 1)";
            break;
          }
          
          context.strokeStyle = "white";
          context.lineWidth = 2 + 4 * (1 - t);
          context.fill();
          //context.stroke();
          // update this image's data with data from the canvas
          this.data = context.getImageData(0, 0, this.width, this.height).data;

          // continuously repaint the map, resulting in the smooth animation of the dot
          map.triggerRepaint();

          // return `true` to let the map know that the image was updated
          return true;
        }
      }
      map.addImage("icon-"+type, pulsingDot, { pixelRatio: 2 });
    },
    addPointLayerToMap(map,type, geojson) {
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      if(typeof geojson.features == 'undefined') return;
      //let geojson_url = consts.url_geojson_quake_history;
      if(!map.hasImage("dot-1")) {
        this.makePointImage(map, 1) //<4
        this.makePointImage(map, 2) //4-5
        this.makePointImage(map, 3) //5-6
        this.makePointImage(map, 4) //6-7
        this.makePointImage(map, 5) //>7
        
      }


      geojson.features.forEach((item,idx)=>{
        if(item.properties.level <= 4){
          item.properties.type = 1
        } else if(item.properties.level <= 5){
          item.properties.type = 2
        }else if(item.properties.level <= 6){
          item.properties.type = 3
        }else if(item.properties.level <= 7){
          item.properties.type = 4
        }else {
          item.properties.type = 5
        }
      })

      map.addSource(source_name, {
        type: "geojson",
        data: geojson
       }
      );
      map.addLayer({
        id: layer_id,
        type: "symbol",
        source: source_name,
        paint: {
          "text-color": "#ffffff"
        },
        layout: {
          "icon-image": ["concat", "dot-", ["get","type"]],
          "text-field": ["get", "region"],
          "text-size": 12,
          "text-offset": [0, -3],
          "text-anchor": "top"
        }
      });
    },
    addImageLayerToMap(map,type, geojson) {
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      if(typeof geojson.features == 'undefined') return;
      //let geojson_url = consts.url_geojson_quake_history;
      

      map.addSource(source_name, {
        type: "geojson",
        data: geojson
       }
      );
      map.addLayer({
        id: layer_id,
        type: "symbol",
        source: source_name,
        paint: {
          "text-color": "#ffffff",
          //"icon-opacity": 1,
        },
        layout: {
          "icon-image": ["concat", "icon-", ["get","type_id"]],
          "icon-size": 0.5,
          "text-field": ["get", "region"],
          "text-size": 12,
          "text-offset": [0, -3],
          "text-anchor": "top",
          "icon-allow-overlap": true,
          
        }
      });
    },
    addLineLayerToMap(map, type, geojson) {
      this.removeMapLayer(map,type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      let layer_common_center_id = this.getMapLayerId(type)+'_center';
      let linecolor = ''
      if(typeof geojson.features == 'undefined') return;
      
      let colorExpress = '';
      let lineWidth = consts.defaultLineWidth/2;
       if(type == 'pipeCommon'){
           //colorExpress = "#707170"
           colorExpress = "rgba(255,255,255,0.8)"
           lineWidth = consts.defaultLineWidth;
       } else {
        if(this.selects.data_category == 0) {
            geojson.features.forEach((item,idx)=>{
              for(let key in item.properties) {
                if(key.indexOf('day')>-1){
                  if(item.properties[key] == '正修') {
                    item.properties[key] = '正修-'+ item.properties.work_team_id
                  }
                }
              }
            })
            colorExpress = [
             "match",
            ["get", "day"+parseInt(this.recovery_days)],
            '未修',
            "#717471",
            '正修-1',
            "#be0001",
            '正修-2',
            "#fec20b",
            '正修',
            "#ff0012",
            '完好',
            "#0800ee",
            '-',
            "#707170",
            "#707170"
            ]

        } else {
            colorExpress = [
              "match",
              ["get", "material_name"],
              '焊接钢管',
              "#D55328",
              '球墨铸铁',
              "#70AD47",
              '灰口铸铁',
              "#F5C243",
              'PVC',
              "#62BFF5",
              "#62BFF5"
            ]
        }
       }
        map.addSource(source_name, {
          type: "geojson",
          data: geojson
        });
        //diameter material_name
        map.addLayer({
          id: layer_id,
          type: "line",
          source: source_name,
          "layout": {
            "line-join": "round",
            "line-cap": "round"
          },
            "paint": {
            "line-color": colorExpress,
            "line-width": lineWidth
          }
        });

        if(type == 'pipeCommon'){
          map.addLayer({
            id: layer_common_center_id,
            type: "line",
            source: source_name,
            "layout": {
              "line-join": "round",
              "line-cap": "round"
            },
              "paint": {
              "line-color": "#707170",
              "line-width": 4
            }
          });
        }
       
        //  if (map.getLayer(this.getMapLayerId('pipe')) && map.getLayer(this.getMapLayerId('pipeCommon'))  && map.getLayer(this.getMapLayerId('nodeCommon'))) {
        //      this.map.moveLayer(this.getMapLayerId('pipe'), this.getMapLayerId('nodeCommon'));
        //      this.map.moveLayer(this.getMapLayerId('pipeCommon'), this.getMapLayerId('nodeCommon'));
        //      this.map.moveLayer(this.getMapLayerId('pipeCommon'), this.getMapLayerId('pipe'));
        //  }
         this.setMapLayer()
         if(type == 'pipeCommon'){
          map.setPaintProperty(layer_id, 'line-width', [
            "interpolate",
            ["exponential", 0.5],
            ["zoom"],
            10, //缩放比例
            1, //值
            12,
            3, //值
            22,
            5
          ]);
          map.setPaintProperty(layer_common_center_id, 'line-width', [
            "interpolate",
            ["exponential", 0.5],
            ["zoom"],
            10, //缩放比例
            1, //值
            12,
            1, //值
            22,
            2
          ]);
         } else {
          //  map.setPaintProperty(layer_id, 'line-width', [
          //   "interpolate",
          //   ["exponential", 0.5],
          //   ["zoom"],
          //   10, //缩放比例
          //   1, //值
          //   12,
          //   1, //值
          //   22,
          //   2
          // ]);
         }

         
        

        
       
    },
    updateLineLayerToMap(layer_name){
      let layer_id = this.getMapLayerId(layer_name);
      if(!this.map.getLayer(layer_id)) return;
      let colorExpress = [];
      let widthExpress = [];
      if(this.selects.data_category == 0) {
        colorExpress = [
        "match",
            ["get", "day"+parseInt(this.recovery_days)],
            '未修',
            "#717471",
            '正修-1',
            "#be0001",
            '正修-2',
            "#fec20b",
            '正修',
            "#ff0012",
            '完好',
            "#0800ee",
            '-',
            // "rgba(255,255,255,0.8)",
            // "rgba(255,255,255,0.8)"
             "#707170",
            "#707170"
        ]
         let linewidth = consts.defaultLineWidth/2;
         widthExpress = [
        "match",
            ["get", "day"+parseInt(this.recovery_days)],
            '未修',
            linewidth,
            '正修-1',
            linewidth+1,
            '正修-2',
            linewidth+1,
            '完好',
            linewidth,
            '-',
            linewidth,
            linewidth
        ]
        //widthExpress = 4
        this.map.setPaintProperty(layer_id, 'line-width', widthExpress);
      } else {
        colorExpress = [
            "match",
            ["get", "material_name"],
            '焊接钢管',
            "#D55328",
            '球墨铸铁',
            "#70AD47",
            '灰口铸铁',
            "#F5C243",
            'PVC',
            "#62BFF5",
            "#62BFF5"
        ]
      }
      this.map.setPaintProperty(layer_id, 'line-color', colorExpress);
    },
    addBlockLayerToMap(map,type, geojson) {
      this.removeMapLayer(map,type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      let layer_id_stroke = layer_id+'_stroke';
      if(typeof geojson.features == 'undefined') return;
      
      geojson.features.forEach(function(item,idx){
          for(let i in item.properties){
              if(i.indexOf('day')>-1 && item.properties[i] == '-') {
                  item.properties[i] = 10000
              }
          }
      })
      map.addSource(source_name, {
        type: "geojson",
        data: geojson
      });
      map.addLayer({
        id: layer_id,
        type: "fill",
        source: source_name,
        'paint': {
          'fill-color': [
              "step",
              ["get", "day"+parseInt(this.recovery_days)],
              "#ffff00", 19.999,
              "#ffc000", 40.001,
              "#ed7d32", 60.001,
              "#ff1600", 80.001,
              "#c00d00", 100.001,
              "rgba(255,255,255,0)"
            ],
          'fill-opacity': 0.8
        }
      });
      map.addLayer({
        id: layer_id_stroke,
        type: "line",
        source: source_name,
        layout: {},
        paint: {
          "line-color": '#333333',
          "line-width": 1
        }
      });
      this.setMapLayer()
    },
    updateBlockLayerToMap(layer_name) {
      let layer_id = this.getMapLayerId(layer_name);
      if(!this.map.getLayer(layer_id)) return;
      let colorExpress = [];
      colorExpress = [
        "step",
              ["get", "day"+parseInt(this.recovery_days)],
              "#ffff00", 19.999,
              "#ffc000", 40.001,
              "#ed7d32", 60.001,
              "#ff1600", 80.001,
              "#c00d00", 100.001,
              "rgba(255,255,255,0)"
        ]
      this.map.setPaintProperty(layer_id, 'fill-color', colorExpress);
    },
    addCircle2Map(map,type, geoJsonData) {
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      let layer_common_center_id = this.getMapLayerId(type)+'_center';
      if(typeof geoJsonData.features == 'undefined') return;
      
     let colorExpress = '';
     let widthExpress = 4;
     let strokeWidth = 0;
       if(type == 'nodeCommon'){
           //colorExpress = "rgba(204,204,204,1)"
           colorExpress = "rgba(255,255,255,0.8)"
           widthExpress = 8;
           strokeWidth = 1
       } else {
        if(this.selects.data_category == 0) {
          let count = 0; //正修计数
          this.geoData1 = geoJsonData.features
          this.geoData1.forEach((item,idx)=>{
            if(item.properties["day"+parseInt(this.recovery_days)].indexOf('正修')>-1 ) {
              count++
            }
          })
          this.statistics.count1 = count
          geoJsonData.features.forEach((item,idx)=>{
            for(let key in item.properties) {
              if(key.indexOf('day')>-1){
                if(item.properties[key] == '正修') {
                  item.properties[key] = '正修-'+ item.properties.work_team_id
                }
              }
            }
          })
          let linewidth = 4;
          widthExpress = [
            "match",
                ["get", "day"+parseInt(this.recovery_days)],
                '未修',
                linewidth,
                '正修-1',
                linewidth+1,
                '正修-2',
                linewidth+1,
                '完好',
                linewidth,
                '-',
                linewidth,
                linewidth
            ]
            colorExpress = [
             "match",
            ["get", "day"+parseInt(this.recovery_days)],
            '未修',
            "#717471",
            '正修-1',
            "#be0001",
            '正修-2',
            "#fec20b",
            '完好',
            "#0800ee",
            '-',
            "#707170",
            "#707170"
            ]

        } else {
            colorExpress = [
              "match",
                ["get", "day"+parseInt(this.recovery_days)],
                '可用',
                "#73fdd6",
                '不可用',
                "#ff0012",
                "#707170"
            ]
        }
       }
      map.addSource(source_name, {
        type: "geojson",
        data: geoJsonData
      });
      map.addLayer({
        id: layer_id,
        type: "circle",
        source: source_name,
        paint: {
          // make circles larger as the user zooms from z12 to z22
          'circle-radius': widthExpress,
          // color circles by ethnicity, using a match expression
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-match
          'circle-color': colorExpress,
          'circle-stroke-color': '#333333',
          'circle-stroke-width': strokeWidth
        }
      });
      if(type == 'nodeCommon'){
        map.addLayer({
          id: layer_common_center_id,
          type: "circle",
          source: source_name,
          paint: {
            // make circles larger as the user zooms from z12 to z22
            'circle-radius': 4,
            // color circles by ethnicity, using a match expression
            // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-match
            'circle-color': '#707170',
            'circle-stroke-color': '#333333',
            'circle-stroke-width': 0
          }
        });
      }
    
   
    //  if (map.getLayer(this.getMapLayerId('pipe')) && map.getLayer(this.getMapLayerId('pipeCommon'))  && map.getLayer(this.getMapLayerId('nodeCommon'))) {
    //     this.map.moveLayer(this.getMapLayerId('pipe'), this.getMapLayerId('nodeCommon'));
    //     this.map.moveLayer(this.getMapLayerId('pipeCommon'), this.getMapLayerId('nodeCommon'));
    //     this.map.moveLayer(this.getMapLayerId('pipeCommon'), this.getMapLayerId('pipe'));
    // }
     this.setMapLayer()
     //setZoomLevel(this.map, layer_id, 'circle-radius')
    
      if(type == 'nodeCommon'){
          map.setPaintProperty(layer_id, 'circle-radius', [
            "interpolate",
            ["exponential", 0.5],
            ["zoom"],
            10, //缩放比例
            2, //值
            12,
            6, //值
            22,
            8
          ]);
          map.setPaintProperty(layer_common_center_id, 'circle-radius', [
            "interpolate",
            ["exponential", 0.5],
            ["zoom"],
            10, //缩放比例
            2, //值
            12,
            4, //值
            22,
            4
          ]);
         } else {
          //  map.setPaintProperty(layer_id, 'circle-radius', [
          //   "interpolate",
          //   ["exponential", 0.5],
          //   ["zoom"],
          //   10, //缩放比例
          //   2, //值
          //   12,
          //   4, //值
          //   22,
          //   4
          // ]);
         }

    },
    updateCircle2Map(layer_name){
      let layer_id = this.getMapLayerId(layer_name);
      if(!this.map.getLayer(layer_id)) return;
      let colorExpress = [];
      let widthExpress = [];
      
      if(this.selects.data_category == 0) {
        let count = 0; //正修计数
        this.geoData1.forEach((item,idx)=>{
          if(item.properties["day"+parseInt(this.recovery_days)].indexOf('正修')>-1 ) {
            count++
          }
        })
        this.statistics.count1 = count
        colorExpress = [
        "match",
            ["get", "day"+parseInt(this.recovery_days)],
            '未修',
            "#717471",
            '正修-1',
            "#be0001",
            '正修-2',
            "#fec20b",
            '完好',
            "#0800ee",
            '-',
            "#707170",
            "#707170"
        ]
        let linewidth = 4;
         widthExpress = [
        "match",
            ["get", "day"+parseInt(this.recovery_days)],
            '未修',
            linewidth,
            '正修-1',
            linewidth+1,
            '正修-2',
            linewidth+1,
            '完好',
            linewidth,
            '-',
            linewidth,
            linewidth
        ]
        //widthExpress = 4
        this.map.setPaintProperty(layer_id, 'circle-radius', widthExpress);
      } else {
        colorExpress = [
            "match",
            ["get", "day"+parseInt(this.recovery_days)],
            '可用',
            "#73fdd6",
            '不可用',
            "#ff0012",
            "#707170"
        ]
      }
      this.map.setPaintProperty(layer_id, 'circle-color', colorExpress);
      
    },
    setMapLayer(){

        if (this.map.getLayer(this.getMapLayerId('node')) &&  this.map.getLayer(this.getMapLayerId('block')) && this.map.getLayer(this.getMapLayerId('pipeCommon'))  && this.map.getLayer(this.getMapLayerId('nodeCommon'))) {
          if (this.map.getLayer('stroke')) {
            this.map.moveLayer(this.getMapLayerId('node'), 'stroke');
          }
          // this.map.moveLayer(this.getMapLayerId('block'), this.getMapLayerId('node'));
          // this.map.moveLayer(this.getMapLayerId('block')+'_stroke', this.getMapLayerId('node'));
          // this.map.moveLayer(this.getMapLayerId('pipeCommon'), this.getMapLayerId('node'));
          // this.map.moveLayer(this.getMapLayerId('nodeCommon'), this.getMapLayerId('node'));

          // this.map.moveLayer(this.getMapLayerId('block'), this.getMapLayerId('nodeCommon'));
          // this.map.moveLayer(this.getMapLayerId('block')+'_stroke', this.getMapLayerId('nodeCommon'));
          // this.map.moveLayer(this.getMapLayerId('pipeCommon'), this.getMapLayerId('nodeCommon'));

          // this.map.moveLayer(this.getMapLayerId('block'), this.getMapLayerId('pipeCommon'));
          // this.map.moveLayer(this.getMapLayerId('block')+'_stroke', this.getMapLayerId('pipeCommon'));

          // this.map.moveLayer(this.getMapLayerId('block'), this.getMapLayerId('block')+'_stroke');
          this.map.moveLayer(this.getMapLayerId('block'), this.getMapLayerId('node'));
          this.map.moveLayer(this.getMapLayerId('block')+'_stroke', this.getMapLayerId('node'));
          this.map.moveLayer(this.getMapLayerId('pipeCommon'), this.getMapLayerId('node'));
          this.map.moveLayer(this.getMapLayerId('pipeCommon')+'_center', this.getMapLayerId('node'));
          this.map.moveLayer(this.getMapLayerId('nodeCommon'), this.getMapLayerId('node'));
          this.map.moveLayer(this.getMapLayerId('nodeCommon')+'_center', this.getMapLayerId('node'));

          this.map.moveLayer(this.getMapLayerId('block'), this.getMapLayerId('nodeCommon')+'_center');
          this.map.moveLayer(this.getMapLayerId('block')+'_stroke', this.getMapLayerId('nodeCommon')+'_center');
          this.map.moveLayer(this.getMapLayerId('pipeCommon'), this.getMapLayerId('nodeCommon')+'_center');
          this.map.moveLayer(this.getMapLayerId('pipeCommon')+'_center', this.getMapLayerId('nodeCommon')+'_center');
          this.map.moveLayer(this.getMapLayerId('nodeCommon'), this.getMapLayerId('nodeCommon')+'_center');
          

          this.map.moveLayer(this.getMapLayerId('block'), this.getMapLayerId('nodeCommon'));
          this.map.moveLayer(this.getMapLayerId('block')+'_stroke', this.getMapLayerId('nodeCommon'));
          this.map.moveLayer(this.getMapLayerId('pipeCommon')+'_center', this.getMapLayerId('nodeCommon'));
          this.map.moveLayer(this.getMapLayerId('pipeCommon'), this.getMapLayerId('nodeCommon'));
          
          this.map.moveLayer(this.getMapLayerId('block'), this.getMapLayerId('pipeCommon')+'_center');
          this.map.moveLayer(this.getMapLayerId('block')+'_stroke', this.getMapLayerId('pipeCommon')+'_center');
          this.map.moveLayer(this.getMapLayerId('pipeCommon'), this.getMapLayerId('pipeCommon')+'_center');


          this.map.moveLayer(this.getMapLayerId('block'), this.getMapLayerId('pipeCommon'));
          this.map.moveLayer(this.getMapLayerId('block')+'_stroke', this.getMapLayerId('pipeCommon'));

          this.map.moveLayer(this.getMapLayerId('block'), this.getMapLayerId('block')+'_stroke');
        return;
      }
       if (this.map.getLayer(this.getMapLayerId('node')) && this.map.getLayer(this.getMapLayerId('pipeCommon'))  && this.map.getLayer(this.getMapLayerId('nodeCommon'))) {
          if (this.map.getLayer('stroke')) {
            this.map.moveLayer(this.getMapLayerId('node'), 'stroke');
          }
          // this.map.moveLayer(this.getMapLayerId('pipe'), this.getMapLayerId('node'));
          // this.map.moveLayer(this.getMapLayerId('pipeCommon'), this.getMapLayerId('node'));
          // this.map.moveLayer(this.getMapLayerId('nodeCommon'), this.getMapLayerId('node'));

          // this.map.moveLayer(this.getMapLayerId('pipe'), this.getMapLayerId('nodeCommon'));
          // this.map.moveLayer(this.getMapLayerId('pipeCommon'), this.getMapLayerId('nodeCommon'));

          // this.map.moveLayer(this.getMapLayerId('pipeCommon'), this.getMapLayerId('pipe'));

          this.map.moveLayer(this.getMapLayerId('pipeCommon'), this.getMapLayerId('node'));
          this.map.moveLayer(this.getMapLayerId('pipeCommon')+'_center', this.getMapLayerId('node'));
          this.map.moveLayer(this.getMapLayerId('nodeCommon'), this.getMapLayerId('node'));
          this.map.moveLayer(this.getMapLayerId('nodeCommon')+'_center', this.getMapLayerId('node'));


          this.map.moveLayer(this.getMapLayerId('nodeCommon'), this.getMapLayerId('nodeCommon')+'_center');
          this.map.moveLayer(this.getMapLayerId('pipeCommon'), this.getMapLayerId('nodeCommon')+'_center');
          this.map.moveLayer(this.getMapLayerId('pipeCommon')+'_center', this.getMapLayerId('nodeCommon')+'_center');


          this.map.moveLayer(this.getMapLayerId('pipeCommon'), this.getMapLayerId('nodeCommon'));
          this.map.moveLayer(this.getMapLayerId('pipeCommon')+'_center', this.getMapLayerId('nodeCommon'));


          this.map.moveLayer(this.getMapLayerId('pipeCommon'), this.getMapLayerId('pipeCommon')+'_center');
      }
    },
    showList() {
      this.show_list = !this.show_list;
    },
    donwloadList() {},
    showChart(city_id) {
      var self = this;
      var container = document.querySelector('.el-collapse-main');
      var box = document.getElementById("box-chart");
      this.myChart = this.$echarts.init(box);
      box.style.width = container.offsetWidth + "px";
      
      getChart1(city_id).then(res => {
        var chart_data = {};
        chart_data.seriesData = res.data.seriesData;
        chart_data.seriesData[0].data = Array.from(res.data.seriesData[0].data).reverse()
        chart_data.seriesData[1].data = Array.from(res.data.seriesData[1].data).reverse()
        chart_data.seriesData[2].data = Array.from(res.data.seriesData[2].data).reverse()
        chart_data.categoryData = Array.from(res.data.categoryData).reverse()
        
        var chart_name = "维修安排";
        chart_data.seriesData.forEach((item,idx) => {
          item.barWidth = 'auto';
          item.color = self.colors[2][idx];
        })
        console.log(chart_data.seriesData)
        var option = buildBarStackOption(chart_name, chart_data,{
          xAxis: {
            name: '恢复时间（天）',
            nameLocation: 'center',//坐标轴名称显示位置。
            nameGap: 30,
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '40',
            top: '60',
            containLabel: true
          },
          yAxis: {
            axisLabel: {
                interval:0
            }
          },
        });
        self.myChart.setOption(option);
       
      });


      
      var box2 = document.getElementById("box-chart2");
      this.myChart2 = this.$echarts.init(box2);
      box2.style.width = container.offsetWidth + "px";
      
      
      getChart2(city_id).then(res => {
        this.box2Data = res.data;
        this.updateChart()
       
      });

      var box3 = document.getElementById("box-chart3");
      this.myChart3 = this.$echarts.init(box3);
      box3.style.width = container.offsetWidth + "px";
      getChart3(city_id).then(res => {
        var chart_data = res.data;
        var chart_name = "电网功能的恢复曲线";
        var colors = ["#ff1523","#3d3bf9"]
  

        chart_data.seriesData.map((item,idx) => {
          item.color = colors[idx]
        })
        var option = buildlineOption(chart_name, chart_data, {
           grid: {
            left: '3%',
            right: '4%',
            bottom: '30',
            top: '80',
            containLabel: true
           },
           xAxis: {
            boundaryGap: false,
            show: true,
            name: '时间（天）',
            nameLocation: 'center',//坐标轴名称显示位置。
            nameGap: 30,
            axisLabel: {
                interval:9,//代表显示所有x轴标签显示
            }
          },
          yAxis: {
            name: '百分比',
            nameLocatiosn: 'end',//坐标轴名称显示位置。
           
          },
          'type':'waterRecovery'
          },true);
        this.myChart3.setOption(option);
        // this.myChart3.setOption({
        //     series: [{
        //         id: 'timeline',
        //         type: 'line',
        //         data: [[parseInt(this.recovery_days), 0], [parseInt(this.recovery_days), 1]]
        //     }]
        // });
        
       
      });
      
      this.myChart.resize(); 
      //self.myChart2.resize();
      this.myChart3.resize();

      document.querySelector('.key-decision').style.width = document.querySelector('#customStyle').offsetWidth +'px'

      window.onresize = function() {

           document.querySelector('.key-decision').style.width = document.querySelector('#customStyle').offsetWidth +'px'
        document.querySelectorAll('.chart-area').forEach((item,idx)=>{
          item.style.width = document.querySelector('.el-collapse').offsetWidth+'px';
        })
        self.myChart.resize(); 
        self.myChart2.resize(); 
        self.myChart3.resize(); 
      }
    },
    updateChart(){
        if(this.myChart.getOption() != undefined) {
          this.myChart.setOption({
              series: [{
                  id: 'timeline',
                  type: 'line',
                  data: [[parseInt(this.recovery_days), -100], [parseInt(this.recovery_days), 100]],
                  itemStyle: {
                    color: '#f96332'
                  },
                  lineStyle: {
                    width: 1
                  }
              }]
          });
        }

        var chart_data = JSON.parse(JSON.stringify(this.box2Data));
        var chart_name = "社区供电比率和影响人口比例统计";
        var colors = this.colors[5]

        //计算右上角统计
        let count3 = 0;
        let count3Length = 0;
        let count4 = 0;
        let count4Length = 0;
        chart_data.seriesData.forEach((item,idx)=> {
            item.data.forEach((subitem, subidx)=>{
              if(idx == 0) {
                count3 += subitem[parseInt(this.recovery_days)].replace('%','') - 0
                count3Length = item.data.length
              } else {
                count4 += subitem[parseInt(this.recovery_days)].replace('%','') - 0
                count4Length = item.data.length
              }
            })
            
        })
        this.statistics.count3 = (count3/count3Length).toFixed(2)
        this.statistics.count4 = (count4/count4Length).toFixed(2)

        
        
        for(let i = 0; i< chart_data.seriesData.length; i++) {
            chart_data.seriesData[i].color = colors[i]
            for(let j = 0; j< chart_data.seriesData[i].data.length; j++) {
                chart_data.seriesData[i].data[j] = chart_data.seriesData[i].data[j][parseInt(this.recovery_days)].replace('%','') - 0
            }
        }
        if(this.myChart2.getOption() != undefined) {
          var option = this.myChart2.getOption()
          //seriesData
          chart_data.seriesData.forEach((item,idx)=>{
            option.series[idx].data = item.data
          })
          this.myChart2.setOption(option);
        } else{
           var option = buildBarStackOption(chart_name, chart_data, {
            xAxis: {
              name: '百分比',
              nameLocation: 'center',//坐标轴名称显示位置。
              nameGap: 30,
            },
            "dataZoom": [
              { //Y轴固定,让内容滚动
                  type: 'slider',
                  show: true,
                  yAxisIndex: [0],
                  start: 80,
                  end: 100,//设置X轴刻度之间的间隔(根据数据量来调整)
                  zoomLock:false, //锁定区域禁止缩放(鼠标滚动会缩放,所以禁止)
                  zoomOnMouseWheel:false,                  
                  moveOnMouseMove:true, 
                  textStyle: {
                      color: "#ffffff"
                  },
              },
              {
                  type: 'inside',
                  yAxisIndex: [0],
                  start: 80,
                  end: 100,
                  zoomLock:false, //锁定区域禁止缩放
                  zoomOnMouseWheel:false,                  
                  moveOnMouseMove:true,
                  
              },
              ]
          }, false);
          this.myChart2.setOption(option);
          this.myChart2.resize();
        }
    },
     updateChart2(){
        this.myChart3.setOption({
              series: [{
                  id: 'timeline',
                  type: 'line',
                  data: [[parseInt(this.recovery_days), 0], [parseInt(this.recovery_days), 1]]
              }]
          });

         
          this.myChart3.dispatchAction({
              type:'hideTip'  
          })
          setTimeout(()=>{
           
            this.myChart3.dispatchAction({
              type: 'showTip',
              seriesIndex: 0,
              dataIndex: parseInt(this.recovery_days)
            })
          },10)

      
    },
    fillStreets(city_id) {
      getStreets(city_id).then(res => {
        let data = res.data;
        data.unshift({ id: 0, name: "街道" });
        this.selects.streets = data;
      });
    },
    fillCommunities(city_id, street_id) {
      getCommunities(city_id, street_id).then(res => {
        let data = res.data;
        data.unshift({ id: 0, name: "社区" });
        this.selects.communities = data;
      });
    },
    fillList1(city_id, page) {
      //let params = Object.assign({},this.buildParams());
      let params = this.buildParams();
      params.page = page;
      if(this.selects.data_category == 0) {
        
        getList1(params).then(res => {
          this.total1 = res.data.total;
          this.data1 = res.data.items;
        });
          
        
        getStatistics1(params).then(res => {
          //this.statistics.year = res.data.year;
        });
        //this.downloadUrl = consts.requestUrl + consts.exportWaterPipeDiam + parseJsonToString(params)
      } else {
        
        getList2(params).then(res => {
          this.total1 = res.data.total;
          this.data1 = res.data.items;
        });
        getStatistics2(params).then(res => {
          //this.statistics.year = res.data.year;
        });
        //this.downloadUrl = consts.requestUrl + consts.exportWaterPipeType + parseJsonToString(params)
      }
    },
    fillList2(city_id, page) {
      //let params = Object.assign({},this.buildParams());
      let params = this.buildParams();
      params.page = page;
      
      if(this.selects.data_category == 0) {
          //params.repair_status = (this.options[2].selected.length==0 || this.options[2].selected.length == this.options[2].data.length)?0: (this.options[2].selected.sort((a,b)=>a - b).join("-"));
        
      } else {
         params.type_ids = (this.options[3].selected.length==0 || this.options[3].selected.length == this.options[3].data.length)?0: (this.options[3].selected.sort((a,b)=>a - b).join("-"));
         

        getList3(params).then(res => {
          this.total2 = res.data.total;
          this.data2 = res.data.items;
        });
        getStatistics2(params).then(res => {
          //this.statistics.year = res.data.year;
        });
        //this.downloadUrl = consts.requestUrl + consts.exportWaterPipeType + parseJsonToString(params)
      }
    },
    fill2Map1(city_id) {
      //this.removeMapLayer('pipe')
      let params = this.buildParams();
      if(this.selects.data_category == 0) {
         this.removeMapLayer(this.map, 'block')
         //params.repair_status = (this.options[2].selected.length==0 || this.options[2].selected.length == this.options[2].data.length)?0: (this.options[2].selected.sort((a,b)=>a - b).join("-"));
        //节点
        getGeo1(params).then(res => {
          let data = res;
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addCircle2Map(this.map, 'node',geoJson);
        });

       
      } else {
        //this.removeMapLayer(this.map, 'pipe')
        getGeo2(params).then(res => {
          let data = res;
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addBlockLayerToMap(this.map, 'block',geoJson);
        });
      }
      
      
    },
    fill2Map2(city_id) {
      let params = this.buildParams();
      if(this.selects.data_category == 0) {
       
        // params.repair_status = (this.options[5].selected.length==0 || this.options[5].selected.length == this.options[5].data.length)?0: (this.options[5].selected.sort((a,b)=>a - b).join("-"));
       
        // //节点
        // getGeo2(params).then(res => {
        //   let data = res;
        //   let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        //   this.addCircle2Map(this.map, 'node',geoJson);
          
        // });
      } else {
        //节点
        
        params.type_ids = (this.options[3].selected.length==0 || this.options[3].selected.length == this.options[3].data.length)?0: (this.options[3].selected.sort((a,b)=>a - b).join("-"));
        

        getGeo3(params).then(res => {
          let data = res;
          let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
          this.addCircle2Map(this.map, 'node',geoJson);
          
        });
      }
      
      
    },
    fillBuilding2Map(city_id,type) {
      let params = this.buildParams();
      getBasicBuildingGeojson(params).then(res => {
        let data = res;
        let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        this.addBuildings2Map(this.map, geoJson, type);
      });
    },
    reloadData() {
      this.fillList1(this.city_id, 1);
      this.fillList2(this.city_id, 1);
      
      
      this.fill2Map1(this.city_id);
      this.fill2Map2(this.city_id);

      if(this.selects.data_category == 0) {
          this.activeTab = '节点'
      } else {
          this.activeTab = '社区'
      }

      let params = Object.assign({},this.buildParams());
      if(this.activeTab == '节点') {
        if(this.selects.data_category == 0) {
            this.downloadUrl = consts.requestUrl + consts.exportExportRepairsNode + parseJsonToString(params)
        } else {
            this.downloadUrl = consts.requestUrl + consts.exportExportFunctionNode + parseJsonToString(params)
        }
      }else if (this.activeTab == '社区') {
         this.downloadUrl = consts.requestUrl + consts.exportExportFunctionArea + parseJsonToString(params)
      }
      this.setTipContent()
      
    },
    setTipContent() {
      let html = '';
      if(this.selects.data_category == 0) {
        html = consts.tipsElectricRecovery1
      } else {
        html = consts.tipsElectricRecovery2
      }
      document.getElementById('left-tip-content').innerHTML = html
    },
    buildParams() {
      let params = { city_id: this.city_id };
      params.page_size = 10;

      params.street_id = this.selects.street;
      params.community_id = this.selects.community;

      
      params.type_ids = (this.options[0].selected.length==0 || this.options[0].selected.length == this.options[0].data.length)?0: (this.options[0].selected.sort((a,b)=>a - b).join("-"));
      params.repair_status = (this.options[1].selected.length==0 || this.options[1].selected.length == this.options[1].data.length)?0: (this.options[1].selected.sort((a,b)=>a - b).join("-"));
      
      params.demand_rates = (this.options[2].selected.length==0 || this.options[2].selected.length == this.options[2].data.length)?0: (this.options[2].selected.sort((a,b)=>a - b).join("-"));
     
     
     
    //  params.type_ids1 = (this.options[3].selected.length==0 || this.options[3].selected.length == this.options[3].data.length)?0: (this.options[3].selected.sort((a,b)=>a - b).join("-"));
      
      params.function_status = (this.options[4].selected.length==0 || this.options[4].selected.length == this.options[4].data.length)?0: (this.options[4].selected.sort((a,b)=>a - b).join("-"));
      
  
     
     params.in_day = this.recovery_days

      return params;
    },
    changePage1: function(value) {
        //节点
      this.fillList1(this.city_id, value);
    },
    changePage2: function(value) {
        //管道
      this.fillList2(this.city_id, value);
    },
    getMapLayerId(type) {
      let layer_id = type + "-layer";
      return layer_id;
    },
    getMapSourceName(type) {
      let source_name = type + "-data";
      return source_name;
    },
    removeMapLayer(map, type) {
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      var layer = map.getLayer(layer_id);
      let layer_point_id = layer_id + "point";
      //描边
      var layer_stroke = map.getLayer(layer_id+'_stroke');
      //热点图的热点层
      if (map.getLayer(layer_point_id)) {
        map.removeLayer(layer_point_id);
      }
      if(layer_stroke){
          map.removeLayer(layer_id+'_stroke');
        }
      if (layer) {
        map.removeLayer(layer_id);
      }
      if(map.getSource(source_name)) {
        map.removeSource(source_name);
      } 
    },
    clearAll(idx) {
        if(idx == 'street') {
            this.selects.street = 0
        } else if(idx == 'community') {
            this.selects.community = 0;
        } else {
            this.options[idx].selected = [];
        }
        this.reloadData();
    },
    playTimeline() {
      clearInterval(this.itv)
      this.playing = !this.playing;
      if(this.playing) {
        this.itv = setInterval(()=>{
          this.recovery_days++
          if(this.recovery_days >  25) this.recovery_days = 0;
        },1000)
      }
    },
    updateDays () {
      //施工天数
      if(this.selects.data_category == 0) {
        //更新地图paint
        //this.fillBlockmapLayer(this.city_id, 'communities_plan_'+this.worker_number, 'block');
        this.updateLineLayerToMap('pipe');
        this.updateCircle2Map('node');
      } else if (this.selects.data_category == 1) {
        //更新地图paint
        //this.updateBuildingmapLayer('room-extrusion')
        //this.fillBuildingsMap(this.city_id, this.color_category);
        this.updateBlockLayerToMap('block');
        this.updateCircle2Map('node');
      } 
      this.fillList1(this.city_id, 1);
      this.fillList2(this.city_id, 1);
      this.updateChart()
      this.updateChart2()
    },  
  }
};
</script>
<style lang="scss" scoped>
/deep/.show_list_record .nav {
  width: 100%;
}
.column  {
    margin-right: 10px;
}
.column .item {
    text-align: center;
}
.column-2 {
    margin-right: 20px;
}
.column-2 .item{
    text-align: left;
}
.column-3 .item{
    text-align: left;
}
.subtitle {
    padding: 10px;
    margin: 0;
}
.subtitle:nth-of-type(1),.subtitle:nth-of-type(3) {
    padding-top: 0;
}
.bold-line {
  border-top: 1px solid #fff!important;
  border-bottom: 1px solid #fff!important;
  height: 6px!important;
}
.bold-circle {
  border: 1px solid #fff!important;
  width: 15px!important;
  height: 15px!important;
}



.right-block {
  height: calc(100vh - 360px);
  // min-height: 350px;
  overflow: hidden;
  .tab-pane   {
    height: 50vh;
    overflow: auto; 
    overflow-scrolling:touch; 
    -webkit-overflow-scrolling:touch;
  }
}
.silide-wrapper {
  width: 90%;
  margin: 0 auto;
}
.decision-wrapper {
  padding: 0;  
}
.decision-block {
  overflow: hidden;
  padding-bottom: 30px;
}
.slide-start {
  float: left;
  margin: 10px 0 0 20px;
}
.slide-end {
  float: right;
  margin: 10px 20px 0 0;
}
.demonstration {
  padding: 10px 0;
  display: block;
  float: left;
}
.tab-scroller {
  height: 100%;
  min-height: 350px;
  overflow-y: auto;
}

.msg-percent {
  padding: 10px 0 0 0;  
  float: right;
}



.recovery_days {
    position: absolute;
    bottom: 70px;
    left: 370px;
    //transform: translate(-45%);
    color: #fff;
    width: calc(100% - 600px);
    // height: 100px;
    padding: 20px 20px 50px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .recovery_lable {
      // float: left;
      margin: 0;
      // margin: -10px 0 0 0;
    }
    .bar {
      // float: left;
      width: 87%;
    }
    .start_day {
      position: absolute;
      left: 90px;
      top: 40px;
    }
    .end_day {
      // float: left;
      position: absolute;
      right: 20px;
      top: 40px;
      // position: absolute;
      // right: -50px;
      // top: -11px;
    }
    .timeline-controller {
      // position: relative;
      // top: -10px;
      // display: inline-block;
      // float: left;
     position: absolute;
     left: 55%;
     bottom: 15px;
     transform: translate(-50%);
    }
    .timeline-controller i {
      width: 100%;
      font-size: 24px;
      margin-left: 10px;
    }
}

#customStyle {
  padding-top: 240px!important;
}
.key-decision {
  height: 240px!important;
}
.key-decision {
  .el-collapse {
    padding: 0;
    margin: 10px 0;
  }
  /deep/ .el-collapse-item__content {
    padding: 10px 0!important;
  }
}
</style>
