import request from '@/util/request';

export function byStruct(city_id) {
    return request({
      url: 'api/chart/building-struct?city_id='+city_id,
      method: 'get'
    })
}

export function byCategory(city_id) {
    return request({
      url: 'api/chart/building-category?city_id='+city_id,
      method: 'get'
    })
}
export function byCategoryNature(city_id) {
    return request({
      url: 'api/chart/building-nature?city_id='+city_id,
      method: 'get'
    })
}


export function byQuakeLevel(city_id) {
    return request({
      url: 'api/chart/building-quake-level?city_id='+city_id,
      method: 'get'
    })
}

export function byBuildYear(city_id) {
    return request({
      url: 'api/chart/building-build-year?city_id='+city_id,
      method: 'get'
    })
}

export function byReBuildCost(city_id) {
    return request({
      url: 'api/chart/building-rebuild-cost?city_id='+city_id,
      method: 'get'
    })
}

export function hospitalCategory(city_id) {
  return request({
    url: 'api/hospitals/show?city_id='+city_id,
    method: 'get'
  })
}

export function hideawayPerson(city_id) {
  return request({
    url: 'api/hideaways/show?city_id='+city_id,
    method: 'get'
  })
}

export function warehouseArea(city_id) {
  return request({
    url: 'api/warehouses/show?city_id='+city_id,
    method: 'get'
  })
}

export function quakeHistoryLevel() {
  return request({
    url: 'api/chart/quake-history-level',
    method: 'get'
  })
}

export function quakeHistoryYear() {
  return request({
    url: 'api/chart/quake-history-year',
    method: 'get'
  })
}

export function quakeHistory() {
  return request({
    url: 'api/quakes/show',
    method: 'get'
  })
}

export function quakeChasm() {
  return request({
    url: 'api/quake-chasms/show',
    method: 'get'
  })
}


export function quakeRegion() {
  return request({
    url: 'api/quake-regions/show',
    method: 'get'
  })
}



export function byPGA(city_id) {
  return request({
    url: 'api/chart/building-by-pga?city_id='+city_id,
    method: 'get'
  })
  /*
  return new Promise(function(resolve, reject){
    //柱状图
    let data = {"code":0,"data":[
      {"name":"小于0.1","value":200},
      {"name":"0.1-0.2","value":3000},
      {"name":"0.2-0.3","value":433},
      {"name":"0.3-0.4","value":526},
      {"name":"大于0.4","value":344}
    ]};
    resolve(data);
  })
  */
}

export function byStructLoss(city_id) {
  return request({
    url: 'api/structural-damages/show?city_id='+city_id,
    method: 'get'
  })
  /*
  return new Promise(function(resolve, reject){
    //stack柱状图 
    let data = {"code":0,"data":{
        categoryData: ["清波社区","紫阳街道","湖滨街道","小营街道","南星街道","望江街道"],
        seriesData: [
          {
            "name": "没有",
            data: [320, 302, 301, 324, 30, 330]
          },
          {
            "name": "轻微",
            data: [360, 302, 301, 334, 390, 330]
          },
          {
            "name": "中等",
            data: [320, 332, 101, 334, 390, 330]
          },
          {
            "name": "严重",
            data: [320, 302, 301, 334, 300, 330]
          },
          {
            "name": "完全",
            data: [360, 302, 201, 334, 390, 330]
          },
        ],
      },
    };
    resolve(data);
  })
  */
}



export function byUnStructLoss(city_id) {
  return request({
    url: 'api/non-structural-damages/show?city_id='+city_id,
    method: 'get'
  })
  /*
  return new Promise(function(resolve, reject){
    //stack柱状图 
    let data = {"code":0,"data":{
        categoryData: ["清波社区","紫阳街道","湖滨街道","小营街道","南星街道","望江街道"],
        seriesData: [
          {
            "name": "没有",
            data: [66, 302, 301, 324, 30, 330]
          },
          {
            "name": "轻微",
            data: [77, 302, 301, 334, 390, 330]
          },
          {
            "name": "中等",
            data: [99, 332, 101, 334, 390, 330]
          },
          {
            "name": "严重",
            data: [320, 302, 301, 334, 300, 330]
          },
          {
            "name": "完全",
            data: [33, 302, 201, 334, 390, 330]
          },
        ],
      },
    };
    resolve(data);
  })
  */
}

export function byEconomicLoss(city_id) {
  return request({
    url: 'api/economic-losses/show?city_id='+city_id,
    method: 'get'
  })
  /*
  return new Promise(function(resolve, reject){
    //柱状图
    let data = {"code":0,"data":[
      {"name":"清波社区","value":200},
      {"name":"紫阳街道","value":3000},
      {"name":"湖滨街道","value":433},
      {"name":"小营街道","value":526},
      {"name":"南星街道","value":344},
      {"name":"望江街道","value":344}
    ]};
    resolve(data);
  })
  */
}
export function byEconomicLossRate(city_id) {
  return request({
    url: 'api/economic-loss-rates/show?city_id='+city_id,
    method: 'get'
  })
  /*
  return new Promise(function(resolve, reject){
    //柱状图
    let data = {"code":0,"data":[
      {"name":"清波社区","value":23},
      {"name":"紫阳街道","value":30},
      {"name":"湖滨街道","value":43},
      {"name":"小营街道","value":52},
      {"name":"南星街道","value":44},
      {"name":"望江街道","value":34}
    ]};
    resolve(data);
  })*/
}

export function byDeathMorning(city_id) {
  return request({
    url: 'api/chart/death-morning?city_id='+city_id,
    method: 'get'
  })
  /*
  return new Promise(function(resolve, reject){
    //stack柱状图 
    let data = {"code":0,"data":{
      categoryData: ["清波社区","紫阳街道","湖滨街道","小营街道","南星街道","望江街道"],
      seriesData: [
        {
          "name": "轻微",
          data: [16, 302, 301, 324, 30, 330]
        },
        {
          "name": "中等",
          data: [99, 312, 101, 334, 390, 330]
        },
        {
          "name": "严重",
          data: [20, 32, 301, 334, 300, 330]
        },
        {
          "name": "死亡",
          data: [3, 32, 201, 334, 390, 320]
        },
      ],
    },
  };
    resolve(data);
  })
  */
}

export function byDeathAfternoon(city_id) {
  return request({
    url: 'api/chart/death-afternoon?city_id='+city_id,
    method: 'get'
  })
  /*
  return new Promise(function(resolve, reject){
    //stack柱状图 
    let data = {"code":0,"data":{
      categoryData: ["清波社区","紫阳街道","湖滨街道","小营街道","南星街道","望江街道"],
      seriesData: [
        {
          "name": "轻微",
          data: [66, 42, 301, 324, 30, 330]
        },
        {
          "name": "中等",
          data: [99, 332, 141, 334, 390, 330]
        },
        {
          "name": "严重",
          data: [320, 302, 301, 334, 300, 330]
        },
        {
          "name": "死亡",
          data: [33, 302, 201, 334, 390, 330]
        },
      ],
    },
  };
    resolve(data);
  })
  */
}

export function byTenants(city_id) {
  return request({
    url: 'api/chart/leave-home?city_id='+city_id,
    method: 'get'
  })
  /*
  return new Promise(function(resolve, reject){
    //柱状图 
    let data = {"code":0,"data":[
      {"name":"清波社区","value":200},
      {"name":"紫阳街道","value":1900},
      {"name":"湖滨街道","value":433},
      {"name":"小营街道","value":1226},
      {"name":"南星街道","value":844},
      {"name":"望江街道","value":344}
    ]};
    resolve(data);
  })
  */
}
export function byTenantsRate(city_id) {
  return request({
    url: 'api/chart/leave-home-rate?city_id='+city_id,
    method: 'get'
  })
  /*
  return new Promise(function(resolve, reject){
    //柱状图 
    let data = {"code":0,"data":[
      {"name":"清波社区","value":20},
      {"name":"紫阳街道","value":40},
      {"name":"湖滨街道","value":30},
      {"name":"小营街道","value":26},
      {"name":"南星街道","value":44},
      {"name":"望江街道","value":64}
    ]};
    resolve(data);
  })
  */
}


export function byFunctionLoss(city_id) {
  return request({
    url: 'api/functional-status/show?city_id='+city_id,
    method: 'get'
  })
  /*
  return new Promise(function(resolve, reject){
    //stack柱状图 
    let data = {"code":0,"data":{
      categoryData: ["清波社区","紫阳街道","湖滨街道","小营街道","南星街道","望江街道"],
      seriesData: [
        {
          "name": "RE ",
          data: [0.1, 0.2, 0.3, 0.2, 0.2, 0.1]
        },
        {
          "name": "RU",
          data: [0.2, 0.1, 0.2, 0.2, 0.2, 0.1]
        },
        {
          "name": "RO",
          data: [0.5, 0.2, 0.1, 0.2, 0.2, 0.1]
        },
        {
          "name": "BF",
          data: [0.1, 0.4, 0.2, 0.2, 0.3, 0.6]
        },
        {
          "name": "FF",
          data: [0.1, 0.1, 0.2, 0.2, 0.1, 0.1]
        },
      ],
    },
  };
    resolve(data);
  })
  */
}
export function byRisk(city_id) {
  return request({
    url: 'api/chart/risk-index?city_id='+city_id,
    method: 'get'
  })
  /*
  return new Promise(function(resolve, reject){
    //stack柱状图 
    let data = {"code":0,"data":{
      categoryData: ["清波社区","紫阳街道","湖滨街道","小营街道","南星街道","望江街道"],
      seriesData: [
        {
          "name": "0-0.2 ",
          data: [0.1, 0.2, 0.3, 0.2, 0.2, 0.1]
        },
        {
          "name": "0.2-0.4",
          data: [0.2, 0.1, 0.2, 0.2, 0.2, 0.1]
        },
        {
          "name": "0.4-0.6",
          data: [0.5, 0.2, 0.1, 0.2, 0.2, 0.1]
        },
        {
          "name": "0.6-0.8",
          data: [0.1, 0.4, 0.2, 0.2, 0.3, 0.6]
        },
        {
          "name": "0.8-0.9",
          data: [0.1, 0.1, 0.2, 0.2, 0.1, 0.1]
        },
        {
          "name": "0.9-1",
          data: [0.1, 0.1, 0.2, 0.2, 0.1, 0.1]
        },
      ],
    },
  };
    resolve(data);
  })
  */
}

export function byBeforeReinForce(city_id) {
  return request({
    url: 'api/reinforcement-priorities/street?city_id='+city_id,
    method: 'get'
  })
  // return new Promise(function(resolve, reject){
  //   //柱状图 
  //   let data = {"code":0,"data":[
  //     {"name":"高","value":200},
  //     {"name":"中","value":1900},
  //     {"name":"低","value":433},
  //   ]};
  //   resolve(data);
  // })
}

export function byAfterReinForce(city_id) {
  return request({
    url: 'api/reinforcement-effects/show?city_id='+city_id,
    method: 'get'
  })
  // return new Promise(function(resolve, reject){
  //   //线图
    
  //   let data = {"code":0,"data":{
  //     categoryData: ["加固10%建筑","加固25%建筑","加固50%建筑","加固75%建筑","加固100%建筑"],
  //     seriesData: [
  //       {
  //         "name": "灾后恢复时间(%)",
  //         data: [0.1, 0.2, 0.3, 0.2, 0.2]
  //       },  
  //       {
  //         "name": "经济损失(%)",
  //         data: [0.2, 0.1, 0.2, 0.2, 0.2]
  //       },
  //       {
  //         "name": "人员伤亡(%)",
  //         data: [0.5, 0.2, 0.1, 0.2, 0.2]
  //       },
  //       {
  //         "name": "离家住户(%)",
  //         data: [0.1, 0.4, 0.2, 0.2, 0.3]
  //       },
  //     ]
  //   }}
  //   resolve(data);
  // })
}

export function byAfterReinForceBuilding(city_id) {
  return request({
    url: 'api/reinforcement-effects/category?city_id='+city_id,
    method: 'get'
  })
  // return new Promise(function(resolve, reject){
  //   //线图
    
  
  //   let data = {"code":0,"data":{
  //     categoryData: ["加固10%建筑","加固25%建筑","加固50%建筑","加固75%建筑","加固100%建筑"],
  //     seriesData: [
  //       {
  //         "name": "居住建筑",
  //         data: [0.1, 0.2, 0.3, 0.2, 0.2]
  //       },  
  //       {
  //         "name": "商用建筑",
  //         data: [0.2, 0.1, 0.2, 0.2, 0.2]
  //       },
  //       {
  //         "name": "行政单位",
  //         data: [0.5, 0.2, 0.1, 0.2, 0.2]
  //       },
  //       {
  //         "name": "离家住户",
  //         data: [0.1, 0.4, 0.2, 0.2, 0.3]
  //       },
  //     ]
  //   }}
  //   resolve(data);
  // })
}

export function byAfterReinForcePercent(city_id) {
  return request({
    url: 'api/reinforcement-effects/effect?city_id='+city_id,
    method: 'get'
  })
  // return new Promise(function(resolve, reject){
  //   //线图
    
  
  //   let data = {"code":0,"data":{
  //     categoryData: ["加固10%建筑","加固25%建筑","加固50%建筑","加固75%建筑","加固100%建筑"],
  //     seriesData: [
  //       {
  //         "name": "居住建筑",
  //         data: [0.1, 0.2, 0.3, 0.2, 0.2]
  //       },  
  //       {
  //         "name": "商用建筑",
  //         data: [0.2, 0.1, 0.2, 0.2, 0.2]
  //       },
  //       {
  //         "name": "行政单位",
  //         data: [0.5, 0.2, 0.1, 0.2, 0.2]
  //       },
  //       {
  //         "name": "离家住户",
  //         data: [0.1, 0.4, 0.2, 0.2, 0.3]
  //       },
  //     ]
  //   }}
  //   resolve(data);
  // })
}

export function peopleByAgeRange(city_id) {
  return request({
    url: 'api/community-populations/age?city_id='+city_id,
    method: 'get'
  })
}

export function peopleByStreet(city_id) {
  return request({
    url: 'api/community-populations/show?city_id='+city_id,
    method: 'get'
  })
}

export function functionRecoveryDays(city_id) {
  return request({
    url: 'api/functional-recovery-times/show?city_id='+city_id,
    method: 'get'
  })
//   return new Promise(function(resolve, reject){
//     //stack柱状图 
//     let data = {"code":0,"data":{
//       categoryData: ["街道1","街道2","街道3","街道4","街道5","街道6"],
//       seriesData: [
//         {
//           "name": "0-20周",
//           data: [200, 400, 20, 50, 39,70]
//         },
//         {
//           "name": "20-40周",
//           data: [500, 100, 450, 200, 39,70]
//         },
//         {
//           "name": "40-60周",
//           data: [400, 400, 20, 100, 39,70]
//         },
//         {
//           "name": "60-80周",
//           data: [100, 100, 450, 400, 39,70]
//         },
//         {
//           "name": "80-100周",
//           data: [40, 400, 20, 200, 39,70]
//         },
//         {
//           "name": "100-149周",
//           data: [50, 100, 450, 150, 39,70]
//         },
//       ],
//     },
//   };
//   resolve(data);
// })  
}

export function byShelterUsage(city_id, params) {
  return request({
    url: 'api/shelters/decision?city_id='+city_id+'&decision='+params,
    method: 'get'
  })
  // return new Promise(function(resolve, reject){
  //     //stack柱状图 
  //     let data = {"code":0,"data":{
  //       categoryData: ["避难所1","避难所2","避难所3","避难所4"],
  //       seriesData: [
  //         {
  //           "name": "使用率",
  //           data: [1900, 1200, 1200, 1600]
  //         },
  //         {
  //           "name": "空置率",
  //           data: [100, 300, 300, 200]
  //         },
  //       ],
  //     },
  //   };
  //   resolve(data);
  // })
}


export function byRescueUsage(city_id, plan_id) {
  return new Promise(function(resolve, reject){
      //stack柱状图 
      let data = {"code":0,"data":{
        categoryData: ["避难所1","避难所2","避难所3","避难所4"],
        seriesData: [
          {
            "name": "使用率",
            data: [1900, 1200, 1200, 1600]
          },
          {
            "name": "空置率",
            data: [100, 300, 300, 200]
          },
        ],
      },
    };
    resolve(data);
  })
}


export function byHospitalUsage(city_id, params, type) {
  if(type == 'serious') {
    return request({
      url: 'api/serious-injuries/decision?city_id='+city_id+'&decision='+params,
      method: 'get'
    })
  } else {
    return request({
      url: 'api/minor-injuries/decision?city_id='+city_id+'&decision='+params,
      method: 'get'
    })
  }
  // return new Promise(function(resolve, reject){
  //     //stack柱状图 
  //     let data = {"code":0,"data":{
  //       categoryData: ["医疗点1","医疗点2","医疗点3","医疗点4"],
  //       seriesData: [
  //         {
  //           "name": "使用率",
  //           data: [1900, 1200, 1200, 1600]
  //         },
  //         {
  //           "name": "空置率",
  //           data: [100, 300, 300, 200]
  //         },
  //       ],
  //     },
  //   };
  //   resolve(data);
  // })
}

//小区修复施工安排-甘特
export function byRecoveryPlan(city_id, worker_number) {
  return request({
    url: 'api/community-restorations/decision?city_id='+city_id+'&type='+(parseInt(worker_number)==20000?1:2),
    method: 'get'
  })
  // return new Promise(function(resolve, reject){
  //     //stack柱状图 
  //     let data = {};
  //     if(worker_number == 20000) {
  //       data = {"code":0,"data":{
  //         categoryData: ["小区1","小区2","小区3","小区4"],
  //         seriesData: [
  //           {
  //             "name": "未施工",
  //             data: [2, 200, 50, 100]
  //           },
  //           {
  //             "name": "施工中",
  //             data: [200, 100, 50, 100]
  //           },
  //         ],
  //       }}
  //     } else if(worker_number == 50000) {
  //       data = {"code":0,"data":{
  //         categoryData: ["小区1","小区2","小区3","小区4"],
  //         seriesData: [
  //           {
  //             "name": "未施工",
  //             data: [2, 100, 20, 20]
  //           },
  //           {
  //             "name": "施工中",
  //             data: [100, 50, 50, 70]
  //           },
  //         ],
  //       }
  //       }
  //     }
  //   resolve(data);
  // })
}



//2.3.4 街道中各修复状态小区总数
export function byCommunitiesTotal(city_id, worker_number, day) {
  return request({
    url: 'api/community-restorations/decision-street?city_id='+city_id+'&type='+(parseInt(worker_number)==20000?1:2)+'&in_day='+parseInt(day),
    method: 'get'
  })

  // return new Promise(function(resolve, reject){
  //     //stack柱状图 
  //     let data = {};
  //     if(worker_number == 20000) {
  //       data = {"code":0,"data":{
  //         categoryData: ["街道1","街道2","街道3","街道4"],
  //         seriesData: [
  //           {
  //             "name": "未修",
  //             data: [200, 100, 50, 100]
  //           },
  //           {
  //             "name": "正修",
  //             data: [10, 60, 50, 100]
  //           },
  //           {
  //             "name": "已修",
  //             data: [50, 100, 50, 100]
  //           },
  //         ],
  //       }}
  //     } else if(worker_number == 50000) {
  //       data = {"code":0,"data":{
  //         categoryData: ["街道1","街道2","街道3","街道4"],
  //         seriesData: [
  //           {
  //             "name": "未修",
  //             data: [2, 200, 50, 100]
  //           },
  //           {
  //             "name": "正修",
  //             data: [200, 100, 50, 100]
  //           },
  //           {
  //             "name": "已修",
  //             data: [200, 100, 50, 100]
  //           },
  //         ],
  //       }
  //       }
  //     }
  //   resolve(data);
  // })
}

//2.3.4 图3 曲线
export function byBuildingRecoveryPlan(city_id,worker_number) {
  return request({
    url: 'api/building-restorations/decision?city_id='+city_id+'&type='+(parseInt(worker_number)==20000?1:2),
    method: 'get'
  })
  // return new Promise(function(resolve, reject){
  //   //线图
    
  //   let data = {"code":0,"data":{
  //     categoryData: ["Day1","Day2","Day3","Day4","Day5"],
  //     seriesData: [
  //       {
  //         "name": "未修",
  //         data: [0.1, 0.2, 0.3, 0.2, 0.2]
  //       },  
  //       {
  //         "name": "正修",
  //         data: [0.2, 0.1, 0.2, 0.2, 0.2]
  //       },
  //       {
  //         "name": "已修",
  //         data: [0.5, 0.2, 0.1, 0.2, 0.2]
  //       },
  //     ]
  //   }}
  //   resolve(data);
  // })
}

//2.3.4 图4 街道中各修复状态建筑总数
export function byRecoveryBuildingTotal(city_id, worker_number, day) {
  return request({
    url: 'api/building-restorations/decision-street?city_id='+city_id+'&type='+(parseInt(worker_number)==20000?1:2)+'&in_day='+parseInt(day),
    method: 'get'
  })
}

//2.3.4 当前小区施工工人数
export function byRecoveryWorkerTotal(city_id, worker_number, day) {
  return request({
    url: 'api/maintenance-resources/decision?city_id='+city_id+'&type='+(parseInt(worker_number)==20000?1:2)+'&in_day='+parseInt(day),
    method: 'get'
  })
}


//2.3.5 建筑群落功能状态恢复曲线
export function byBuildingFunctionRecovery(city_id) {
  return request({
    url: 'api/functional-recovery-deductions/show?city_id='+city_id,
    method: 'get'
  })
}

//2.3.5 街道建筑功能恢复统计
export function byStreetFunctionRecovery(city_id, week) {
  return request({
    url: 'api/functional-recovery-deductions/show-street?city_id='+city_id+'&in_week='+week,
    method: 'get'
  })
}
