<template>
  <div class="row" style="margin:0 -30px;">
    <div class="col-md-9" style="padding:0">
      <card class="card-map">
        <!-- <div class="mask">
          <img src="img/loading.png" class="loading">
        </div>-->
        <div class="map-wrapper">
          <div id="map"></div>  
        </div>
        <menu-map :map="map" :mapDefault="mapdefaultSetting"></menu-map>
        <div class="recovery_days">
          <p class="recovery_lable">修复时间</p>
          <div class="timeline-controller" @click="playTimeline">
            <i class="el-icon-video-play" v-if="!playing"></i>
            <i class="el-icon-video-pause" v-if="playing" ></i>
          </div>
          <div class="bar">
            <slider v-model="recovery_days" :options="{step: 1}" :range="{'min': [1], 'max': [309]}"></slider> 
          </div>
           <p class="start_day">1天</p>
          <p class="end_day">309天</p>

        </div>
        <div class="map_data_msg" v-show="selects.data_category == 'communities_recovery'">
          <div class="map_data_legend">小区修复状态</div>
          <div class="map_data_msg_wrapper">
            <div
              class="item"
              v-for="(item,index) in options[0].data"
              :key="index"
            >
              <span :style="'background: '+colors['communities'][index]"></span>
              {{item.name}}
            </div>
          </div>
        </div> 
        <div class="map_data_msg" v-show="selects.data_category == 'building_recovery'">
          <div class="map_data_legend">建筑修复状态</div>
          <div class="map_data_msg_wrapper">
            <div
              class="item"
              v-for="(item,index) in options[1].data"
              :key="index"
            >
              <span :style="'background: '+colors['building_recovery'][index]"></span>
              {{item.name}}
            </div>
          </div>
        </div>
        <div class="map_data_msg" v-show="selects.data_category == 'resource_distribution'">
          <div class="map_data_legend">施工工人数</div>
          <div class="map_data_msg_wrapper">
            <div
              class="item"
              v-for="(item,index) in selects.resource_distributionOptions"
              :key="index"
            >
              <span :style="'background: '+item.color+''"></span>
              {{item.name}}
            </div>
          </div>
        </div>
        <div class="show_list">
          <a class="up_show" v-on:click="showList">
            <i
              class="now-ui-icons"
              :class="[ show_list ? 'arrows-1_minimal-down':'arrows-1_minimal-up']"
            ></i>
          </a>
          <a class="download ml-auto" :href="downloadUrl">
            <i class="now-ui-icons arrows-1_cloud-download-93"></i>
          </a>
          <span>
            数据列表
            <i>{{total}}</i>
          </span>
        </div>
        <div
          class="show_list_record"
          v-show="show_list"
          :style="{'margin-top':-(data.length>0?140+data.length*38: 0)+'px'}"
          ref="show_list_record"
        >
          <el-table :data="data" v-show="selects.data_category == 'communities_recovery'">
            <el-table-column label="小区ID" property="village_id"></el-table-column>
            <el-table-column label="街道" property="street_name"></el-table-column>
            <el-table-column label="社区" property="community_name"></el-table-column>
            <el-table-column label="施工开始时间" property="begin_day"></el-table-column>
            <el-table-column label="施工结束时间" property="end_day"></el-table-column>
            <el-table-column label="当前修复状态" property="repair_status"></el-table-column>
            <el-table-column label="最多施工工人数" property="number"></el-table-column>
            <el-table-column label="当前施工工人数" property="work_number"></el-table-column>
          </el-table>

          <el-table :data="data" v-show="selects.data_category == 'building_recovery'">
            <el-table-column label="建筑ID" property="building_id"></el-table-column>
            <el-table-column label="街道" property="street_name"></el-table-column>
            <el-table-column label="社区" property="community_name"></el-table-column>
            <el-table-column label="灾后破坏状态" property="damage_status"></el-table-column>
            <el-table-column label="施工开始时间" property="begin_day"></el-table-column>
            <el-table-column label="施工结束时间" property="end_day"></el-table-column>
            <el-table-column label="当前修复状态" property="repair_status"></el-table-column>
            <el-table-column label="最多施工工人数" property="number"></el-table-column>
            <el-table-column label="当前施工工人数" property="work_number"></el-table-column>
          </el-table>
          
          <el-table :data="data" v-show="selects.data_category == 'resource_distribution'">
            <el-table-column label="小区ID" property="village_id"></el-table-column>
            <el-table-column label="街道" property="street_name"></el-table-column>
            <el-table-column label="社区" property="community_name"></el-table-column>
            <el-table-column label="施工开始时间" property="begin_day"></el-table-column>
            <el-table-column label="施工结束时间" property="end_day"></el-table-column>
            <el-table-column label="当前修复状态" property="repair_status"></el-table-column>
            <el-table-column label="最多施工工人数" property="number"></el-table-column>
            <el-table-column label="当前施工工人数" property="work_number"></el-table-column>
          </el-table>

          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class>
              <p class="card-category">显示： {{from + 1}} - {{to}} 总数： {{total}}</p>
            </div>
            <n-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              v-on:input="changePage"
            ></n-pagination>
          </div>
        </div>
      </card>
    </div>
    <div class="col-md-3 hasDecision" id="customStyle">
      <div class="col-md-12 clearfix key-decision" >
        <el-select
          class="select-default"
          size="large"
          placeholder="数据集类型选择"
          v-model="selects.data_category"
          v-on:change="reloadData"
        >
          <el-option
            v-for="option in selects.data_categories"
            class="select-primary"
            :value="option.id"
            :label="option.name"
            :key="option.id"
          ></el-option>
        </el-select>
        
        <el-collapse v-model="activeNameskey">
          <el-collapse-item title="决策信息" name="1">
            <div class="col-md-12 clearfix decision-wrapper">
              <div class="decision-block">
                <span class="demonstration">施工工人数量</span>
              </div>
              <slider class="silide-wrapper" v-model="worker_number" :options="{step: 30000}" :range="{'min': [20000], 'max': [50000]}"></slider>
              <p class="slide-start">20000</p>
              <p class="slide-end">50000</p>
            </div>
          </el-collapse-item>
        </el-collapse>

        <!-- <div class="col-md-12 clearfix decision-wrapper" style="margin: 0 auto;">
           
            <div class="decision-block">
              <span class="demonstration">施工工人数量</span>
            </div>
             <slider class="silide-wrapper" v-model="worker_number" :options="{step: 30000}" :range="{'min': [20000], 'max': [50000]}"></slider>
            <p class="slide-start">20000</p>
            <p class="slide-end">50000</p>
          </div> -->
      </div>
      <el-collapse v-model="activeNames"  class="el-collapse-main">
        <!-- <el-collapse-item title="一致性 Consistency" name="1">
          <form>
            <div class="input-group no-border">
              <fg-input
                placeholder="输入关键字..."
                v-on:keyup.enter="reloadData"
                v-model="keyword"
                addon-right-icon="now-ui-icons ui-1_zoom-bold"
              ></fg-input>
              <div class="form-text" style="color:#999;margin-left:20px;">输入 建筑、街道、社区关键字</div>
            </div>
          </form>
        </el-collapse-item> -->
        <el-collapse-item title="信息图表" name="2">
          <div class="col-md-12" v-show="selects.data_category == 'communities_recovery'">
            <div class="chart-wrapper">
              <div class="chart-area" id="box-chart" style="height:400px"></div>
            </div>
              <div class="chart-area" id="box-chart2" style="height:300px"></div>
          </div>
          <div class="col-md-12" v-show="selects.data_category == 'building_recovery'">
            <div class="chart-area" id="box-chart3" style="height:300px"></div>
            <div class="chart-area" id="box-chart4" style="height:300px"></div>
          </div>
          <div class="col-md-12" v-show="selects.data_category == 'resource_distribution'">
            <div class="chart-area" id="box-chart5" style="height:400px"></div>
          </div>
        </el-collapse-item>
        <el-collapse-item title="数据筛选" name="4">
          <div class="col-md-12">
              <el-select
                class="select-default"
                size="large"
                placeholder="街道"
                v-model="selects.street"
                v-on:change="reloadData('street')"
              >
                <el-option
                  v-for="option in selects.streets"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id"
                ></el-option>
              </el-select>
            </div>

            <div class="col-md-12">
              <el-select
                class="select-default"
                size="large"
                placeholder="社区"
                v-model="selects.community"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="option in selects.communities"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id"
                ></el-option>
              </el-select>
            </div>

            <div class="col-md-12" v-show="selects.data_category == 'building_recovery'">
              <el-select
                class="select-default"
                size="large"
                placeholder="结构类型"
                v-model="selects.struct"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="(option, index) in selects.structs"
                  class="select-primary"
                  :value="index"
                  :label="option"
                  :key="index"
                ></el-option>
              </el-select>
            </div>

            <div class="col-md-12" v-show="selects.data_category == 'building_recovery'">
              <el-select
                class="select-default"
                size="large"
                placeholder="功能类型"
                v-model="selects.category"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="(option, index) in selects.categories"
                  class="select-primary"
                  :value="index"
                  :label="option"
                  :key="index"
                ></el-option>
              </el-select>
            </div>

            <div class="col-md-12" v-show="selects.data_category == 'building_recovery'">
              <el-select
                class="select-default"
                size="large"
                placeholder="抗震设防烈度"
                v-model="selects.quakeLevel"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="option in selects.quakeLevels"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id"
                ></el-option>
              </el-select>
            </div>

            <div class="col-md-12" v-show="selects.data_category == 'building_recovery'">
              <el-select
                class="select-default"
                size="large"
                placeholder="修建年代"
                v-model="selects.buildYear"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="(option,index) in selects.buildYears"
                  class="select-primary"
                  :value="option"
                  :label="option"
                  :key="index"
                ></el-option>
              </el-select>
            </div>

            <div class="col-md-12" v-show="selects.data_category == 'communities_recovery'">
              <el-select
                class="select-default"
                size="large"
                multiple=""
                :placeholder="options[0].name"
                v-model="options[0].selected"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="(option,index) in options[0].data"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="index"
                ></el-option>
              </el-select>
            </div>
            <div class="col-md-12" v-show="selects.data_category == 'building_recovery'">
              <el-select
                class="select-default"
                size="large"
                multiple=""
                :placeholder="options[1].name"
                v-model="options[1].selected"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="(option,index) in options[1].data"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="index"
                ></el-option>
              </el-select>
            </div>
            <!-- <div class="col-md-12" v-show="selects.data_category == 'resource_distribution'">
              <el-select
                class="select-default"
                size="large"
                multiple=""
                placeholder="施工工人数"
                v-model="selects.resource_distribution"
                v-on:change="reloadData"
              >
                <el-option
                  v-for="(option,index) in selects.resource_distributionOptions"
                  class="select-primary"
                  :value="option.id"
                  :label="option.name"
                  :key="index"
                ></el-option>
              </el-select>
            </div> -->

        </el-collapse-item>
      </el-collapse>


    </div>
    <div class="key-infos">
      <div class="key-info" v-show="selects.data_category == 'communities_recovery'">
        总工期{{statistics.communities_recovery.count}}天，当前{{communitiesRecoveryCount}}小区正在施工
      </div>
      <div class="key-info" v-show="selects.data_category == 'building_recovery'">
        总工期{{statistics.building_recovery.count}}天，当前{{buildingRecoveryCount}}建筑正在施工
      </div>
      <div class="key-info" v-show="selects.data_category == 'resource_distribution'">
        总工期{{statistics.resource_distribution.count}}天，当前{{communitiesResourceCount}}工人正在施工
      </div>
     
    </div>
  </div>
</template>
<script>
import mapboxgl from "mapbox-gl";
import MenuMap from "src/components/Menumap";
import MiniMap from "src/mapbox/mapboxgl-minimap.js";
import {Slider,  TabPane, Tabs, Switch } from "src/components";
import geobuf from "geobuf";
import Pbf from "pbf";
import { Radio } from "src/components/index";
import { Checkbox } from "src/components/index";
import {Input, Button, Select, Option, Collapse, CollapseItem, Table, TableColumn } from "element-ui";
import { consts } from "src/util/consts.js";
import {
  getCategories,
  getRecoveryCommunitiesBlockGeo as getBlockGeo,
  getRecoveryBuildingOutlineGeojson as getBuilingGeo,
  getRecoveryStreet as getRestorationStreets,
  getRecoveryCommunities as getRestorationCommunities,
  getRecoveryCommunityOptions as getOptions1,
  getRecoveryCommunityList as getList1,
  getRecoveryCommunityGeojson as getGeo1,
  getRecoveryCommunityStatistics as getStatistics1,
  getRecoveryCommunityChart as getChart1,
  getRecoveryBuildingOptions as getOptions2,
  getRecoveryBuildingList as getList2,
  getRecoveryBuildingGeojson as getGeo2,
  getRecoveryBuildingStatistics as getStatistics2,
  getRecoveryBuildingChart as getChart2,
  getRecoveryBuildingTotalChart as getChart4,
  getRecoveryResourceList as getList3,
  getRecoveryResourceGeojson as getGeo3,
  getRecoveryResourceStatistics as getStatistics3,
  getRecoveryResourceChart as getChart3
  
} from "src/api/building.js";

import {
  buildBarOption,
  buildBarYOption,
  buildBarStackOption,
  buildlineOption,
  buildPieWhiteOption
} from "src/util/chart.js";
import {
  getBuildYearRange,
  storeMapOptions,
  getMapOptions,
  parseJsonToString
} from "src/util/common.js";
import { Pagination as NPagination } from "src/components";
import {
  getPeopleStat,
  getGenderStat,
  getHideaways,
  getHospitals,
  getWarehouses
} from "src/api/cityinfo.js";

import * as THREE from "three";
//import geoJsonBasic from "./GeoJson-basic"; // 基本建筑数据from2.2-2.3
//import geoJson231 from "./GeoJson-2.3.1"; // 基本建筑数据from2.3.1

export default {
  components: {
    MenuMap,
    Slider,
    TabPane,
    Tabs,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    NPagination,
    [Switch.name]: Switch,
    Checkbox,
    Radio
  },
  data() {
    return {
      activeNameskey: ['1'],
      activeNames: ['2','3','4'],
      activeName: "first",
      tabs: {
        activeName: "信息图表",
        vertical: "Options",
        verticalPills: "Home",
        pageCategories: "Messages"
      },
      selects: {
        street: 0,
        streets: [{ id: 0, name: "街道" }],
        community: 0,
        communities: [{ id: 0, name: "社区" }],
        struct: 0,
        structs: consts.buildingStructs,
        communities_recovery: [],
        communities_recoveryOptions: [
          { id: 1, name: "未修", color: "#f2f2f2" },
          { id: 2, name: "正修", color: "#4caeeb" },
          { id: 3, name: "完好", color: "#4d71be" }
        ],
        building_recovery: [],
        building_recoveryOptions: [
          { id: 1, name: "未修", color: "#f2f2f2" },
          { id: 2, name: "正修", color: "#F5C243" },
          { id: 3, name: "完好", color: "#D55328" }
        ],
        resource_distribution: 0,
        resource_distributionOptions: [
          { id: 1, name: "0-100人", color: "#7daa54" },
          { id: 2, name: "100-200人", color: "#f6c143" },
          { id: 3, name: "200-500人", color: "#ec3424" }
        ],
        category: 0,
        categories: consts.buildingCategories,
        quakeLevel: 0,
        quakeLevels: [
          { id: 0, name: "抗震设防烈度" },
          { id: 6, name: 6 },
          { id: 7, name: 7 },
          { id: 8, name: 8 }
        ],
        buildYear: '修建年代',
        buildYears: consts.buildingBuildYears,
        data_category: 'communities_recovery',
        data_categories: [
          { id: 'communities_recovery', name: "小区修复次序" },
          { id: 'building_recovery', name: "建筑修复次序" },
          { id: 'resource_distribution', name: "维修资源分配" }
        ]
      },
      keyword: "",
      total: 0,
      data: [],
      page: 1,
      map: {},
      color_category: "recovery",
      city_id: localStorage.getItem("city_id") || 1,
      show_list: false,
      show_list_height: 0,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 20
      },
      switchs: {
        people: true,
        gender: true,
        hideaway: true,
        hospital: true,
        warehouse: true
      },
      last_updated: consts.last_updated,
      riskData: [//立柱叠加
        { id: 1, name: "低", color: "#ffe699", height: '15' },
        { id: 2, name: "中", color: "#ffe699", height: '20'},
        { id: 3, name: "高", color: "#ffe699",height: '25' }
      ],
      colors: {
        'communities_recovery': ["rgba(0,0,0,0)", new this.$echarts.graphic.LinearGradient(
                0, 0, 1, 0,
                [
                    {offset: 0, color: '#b62f1b'},
                    {offset: 0.5, color: '#e8c146'},
                    {offset: 1, color: '#52ad5a'}
                ])],
        'building_recovery': ["#f2f2f2", "#F5C243", "#D55328"],
        'building_type': ['#fd0047','#fd00b9','#7700fd','#0030fd','#00b9fd','#06fd00','#fafd00','#fd4d00'],
        'communities': ["#f2f2f2", "#4caeeb", "#4d71be"],
      },
      risk: false,
      worker_number: 20000, //工人数量
      recovery_days: 1, //修复时间
      playing: false,
      itv: null,
      statistics: {
        communities_recovery: {
          "count": '',
        },
        building_recovery: {
          "total_days": '',
          "count": '',
        },
        resource_distribution: {
          "count": '',
        }

      },
      mapdefaultSetting: {},
      downloadUrl: '',
      options: [
        {name: '小区修复状态',selected:[],data: []},
        {name: '建筑修复状态',selected:[],data: []},
      ],
      communitiesGeo: [],
      communitiesRecoveryCount: 0,
      communitiesResourceCount: 0,
      buildingRecoveryCount: 0,
    };
  },
  watch: {
    worker_number(val){
      //this.worker_number = parseInt(val)
      this.reloadPlan(true)
    },
    recovery_days(val){
      //this.recovery_days = parseInt(val)
      //防止过多请求
      //  if((Date.now() - this.slide_last_visit)<1000) {
      //    return;
      //  }
      this.updateDays(val)
      //this.slide_last_visit = Date.now();
    },
    "selects.street"(val) {
      //街道切换选社区
      this.fillCommunities(this.city_id, val);
    }
  },
  mounted() {
    this.fillStreets(this.city_id);
    //this.fillCommunities(this.city_id, 0);
    this.showChart(this.city_id);
    
    //this.fillBuildings(this.city_id, 1);
    this.initMap(this.city_id);
    this.fillFigure()
    //this.fetchReinforceData(this.city_id);
    
    //this.fillBuildingsMap(this.city_id, this.color_category);
    //this.addPointLayerToMap(this.map, "hospital");
    //this.addPointLayerToMap(this.map, "hideaway");
    //this.addPointLayerToMap(this.map, "warehouse");
    //this.loadData(this.data_type);
  },

  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    }
  },
  methods: {
    // 初始化
    initMap(city_id) {
      let self = this;
      this.mapdefaultSetting = {
        center: consts.mapCenter[this.city_id-1],
        zoom: consts.mapDefaultZoom,
        pitch: 0,
        city_id: this.city_id
      }
      mapboxgl.accessToken = consts.mapboxAccessToken;
      let map_options = getMapOptions();
      //console.log(map_options);
      let zoom = map_options ? map_options.zoom : 12;
      let center = map_options
        ? [map_options.lng, map_options.lat]
        : consts.mapCenter[city_id - 1];
      var map = new mapboxgl.Map({
        container: "map",
        style: consts.mapBoxStyle,
        //center: [-87.61694, 41.86625],
        center: center,
        zoom: zoom,
        pitch: 0,
        bearing: 0,
        attributionControl: false
      });
      this.map = map;
      //定位图标
      map.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl
        })
      );
      this.map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: true
        })
      );
      map.on("load", function(){
        
        //self.reloadPlan();
      })
      //控制图层
      var nav = new mapboxgl.NavigationControl();
      this.map.addControl(nav, "top-right");
      //全屏按钮
      this.map.addControl(
        new mapboxgl.FullscreenControl({
          container: document.querySelector("body")
        })
      );
      //小地图
      //https://github.com/brendanmatkin/mapboxgl-minimap
      map.on("style.load", function() {
        //self.fillCommonGeo();
        self.fillBuildingOutlineGeo()
        self.reloadData()
        // Possible position values are 'bottom-left', 'bottom-right', 'top-left', 'top-right'
        map.addControl(
          new MiniMap({
            id: "mapboxgl-minimap",
            width: "200px",
            height: "150px",
            //style: "mapbox://styles/mapbox/streets-v10",
            style: consts.mapBoxStyle,
            fillColor: "#f58220",
            zoomAdjust: null
          }),
          "bottom-right"
        );
      });
      //记录地图设置
      map.on("move", function() {
        storeMapOptions({
          lng: map.getCenter().lng,
          lat: map.getCenter().lat,
          zoom: map.getZoom()
        });
      });
    },
    fillFigure(){
      getOptions1().then(res => {
        let data = res.data;
        this.options[0].data = data;
      });
      getOptions2().then(res => {
        let data = res.data;
        this.options[1].data = data;
      });
    },
    fillCommonGeo(){
        let params = this.buildParams();
        if(!!this.blockCommonGeo) {
          this.addBlockmapLayer(this.map, 'blockCommon', this.blockCommonGeo);   
        } else {
          getBlockGeo(params).then(res => {
            let data = res;
            let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
            this.blockCommonGeo = geoJson
            this.addBlockmapLayer(this.map, 'blockCommon', geoJson);  
          });
        }
    },
    fillBuildingOutlineGeo(){
      let _this = this;
      let params = this.buildParams();
      getBuilingGeo(params).then(res => {
        let data = res;
        let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        _this.buildingCommonGeo = geoJson
        
        //console.log(this.buildingCommonGeo)
      });
    },
    showList() {
      this.show_list = !this.show_list;
    },
    donwloadList() {},
    showChart() {
      var _this = this;
      
      var container = document.querySelector('.el-collapse-main ');
      var box = document.getElementById("box-chart");
      box.style.width = container.offsetWidth + "px";
      this.myChart = this.$echarts.init(box);
      //根据人数切换
      getChart1(this.city_id, this.worker_number).then(res=>{
        var chart_data = res.data;
        var chart_name = "小区修复施工安排";
        chart_data.seriesData.forEach((item,idx) => {
          item.barWidth = 'auto';
          item.color = _this.colors['communities_recovery'][idx];
        })
        var option = buildBarStackOption(chart_name, chart_data,{
          xAxis: {
            max: 309,
            name: '恢复时间（天）',
            nameLocation: 'center',//坐标轴名称显示位置。
            nameGap: 30,
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '40',
            top: '60',
            containLabel: true
          },
          yAxis: {
            // axisLabel: {
            //     interval:0
            // }
          },
          "dataZoom": [
            { //Y轴固定,让内容滚动
                type: 'slider',
                show: true,
                yAxisIndex: [0],
                start: 95,
                end: 100,//设置X轴刻度之间的间隔(根据数据量来调整)
                zoomLock:false, //锁定区域禁止缩放(鼠标滚动会缩放,所以禁止)
                zoomOnMouseWheel:false,                  
                moveOnMouseMove:true, 
                textStyle: {
                    color: "#ffffff"
                },
            },
            {
                type: 'inside',
                yAxisIndex: [0],
                start: 95,
                end: 100,
                zoomLock:false, //锁定区域禁止缩放
                zoomOnMouseWheel:false,                  
                moveOnMouseMove:true,
                
            },
            ]
        });
        this.myChart.setOption(option);
      });

      var box2 = document.getElementById("box-chart2");
      box2.style.width = container.offsetWidth + "px";
      this.myChart2 = this.$echarts.init(box2);
      //会随时间人数动态更新
      // byCommunitiesTotal(this.city_id, this.worker_number, this.recovery_days).then(res=>{
      //   var chart_data = res.data;
      //   var chart_name = "街道中各修复状态小区总数";
      //   chart_data.seriesData.map((item,idx) => {
      //     //return item.color = this.colors['building_recovery'][idx]
      //     return item.color = this.selects.communities_recoveryOptions[idx].color
          
      //   })
      //   var option = buildBarStackOption(chart_name, chart_data);
      //   this.myChart2.setOption(option);
      // });

      //根据人数切换
      var box3 = document.getElementById("box-chart3");
      box3.style.width = container.offsetWidth + "px";
      this.myChart3 = this.$echarts.init(box3);
      getChart2(this.city_id, this.worker_number).then(res=>{
        var chart_data = res.data;
        var chart_name = "建筑群落建筑状态曲线";
        chart_data.seriesData.map((item,idx) => {
          return item.color = _this.colors['building_recovery'][idx]
        })
        var option = buildlineOption(chart_name, chart_data,{
          xAxis: {
            show: true,
            name: '恢复时间（天）',
            nameLocation: 'center',//坐标轴名称显示位置。
            nameGap: 30,
            axisLabel: {
              interval:49,//代表显示所有x轴标签显示
            }
          },
          yAxis: {
            name: '不同修复状态建筑比例',
            nameLocation: 'end',
            nameTextStyle: {
              padding: [0,0,0,80]
            }
          },
          type:'recovery'}, true);
        this.myChart3.setOption(option);
        this.myChart3.setOption({
              series: [{
                  id: 'timeline',
                  type: 'line',
                  data: [[parseInt(this.recovery_days), 0], [parseInt(this.recovery_days), 1]]
              }]
          });
      }); 

      var box4 = document.getElementById("box-chart4");
      box4.style.width = container.offsetWidth + "px";
      this.myChart4 = this.$echarts.init(box4);
      //会随时间人数动态更新
      getChart4(this.city_id, this.worker_number, this.recovery_days).then(res=>{
        this.chart4Data = res.data;
        this.showChart4()
      });

      var box5 = document.getElementById("box-chart5");
      box5.style.width = container.offsetWidth + "px";
      this.myChart5 = this.$echarts.init(box5);
      //会随时间人数动态更新
      getChart3(this.city_id, this.worker_number, this.recovery_days).then(res=>{
        var chart_data = res.data;
        this.communitiesResourceCount = 0;
        chart_data.forEach((item,idx)=>{
          this.communitiesResourceCount+=item.value;
        })
        var chart_name = "当前小区施工工人数";
        var option = buildBarYOption(chart_name, chart_data, 'name',{
            "dataZoom": [
            { //Y轴固定,让内容滚动
                type: 'slider',
                show: true,
                yAxisIndex: [0],
                start: 95,
                end: 100,//设置X轴刻度之间的间隔(根据数据量来调整)
                zoomLock:false, //锁定区域禁止缩放(鼠标滚动会缩放,所以禁止)
                zoomOnMouseWheel:false,                  
                moveOnMouseMove:true, 
                textStyle: {
                    color: "#ffffff"
                },
            },
            {
                type: 'inside',
                yAxisIndex: [0],
                start: 95,
                end: 100,
                zoomLock:false, //锁定区域禁止缩放
                zoomOnMouseWheel:false,                  
                moveOnMouseMove:true,
                
            },
            ]
        });
        this.myChart5.setOption(option);
      });
       document.querySelector('.key-decision').style.width = document.querySelector('#customStyle').offsetWidth +'px'
      window.onresize = function() {
         document.querySelector('.key-decision').style.width = document.querySelector('#customStyle').offsetWidth +'px'
        document.querySelectorAll('.chart-area').forEach((item,idx)=>{
          item.style.width = document.querySelector('.el-collapse-main  ').offsetWidth-15+'px';
        })
        _this.myChart.resize(); 
        _this.myChart2.resize(); 
        _this.myChart3.resize(); 
        _this.myChart4.resize(); 
        _this.myChart5.resize(); 
      }

      // byCommunitiesTotal(city_id, this.worker_number).then(res=>{
      //   var chart_data = res.data;
      //   var chart_name = "小区修复施工安排(天)";
      //   chart_data.seriesData.map((item,idx) => {
      //     return item.color = _this.colors['communities_recovery'][idx]
      //   })
      //   var option = buildBarStackOption(chart_name, chart_data,{
      //     xAxis: {
      //       max: 309
      //     }
      //   });
      //   this.myChart2.setOption(option);
      // });

      // var box2 = document.getElementById("box-chart2");
      // box2.style.width = container.offsetWidth - 30 + "px";
      // var myChart2 = this.$echarts.init(box2);
      // byAfterReinForce(city_id).then(res=>{
      //   var chart_data = res.data;
      //   //mock data
      //   chart_data = {categoryData:[],seriesData:[{
      //     "name": "灾后恢复时间",
      //     data: []
      //   },  
      //   {
      //     "name": "经济损失",
      //     data: []
      //   },
      //   {
      //     "name": "人员伤亡",
      //     data: []
      //   },
      //   {
      //     "name": "离家住户",
      //     data: []
      //   }]};
      //   _this.reinforce_percent_list.forEach((item,idx) => {
      //     chart_data.categoryData.push("加固"+item['百分数']+"%建筑")
      //     chart_data.seriesData[0].data.push(item['恢复时间'])
      //     chart_data.seriesData[1].data.push(item['经济损失'])
      //     chart_data.seriesData[2].data.push(item['人员伤亡'])
      //     chart_data.seriesData[3].data.push(item['离家住户'])
      //   });
      //   var chart_name = "加固比例";
      //   chart_data.seriesData.map((item,idx) => {
      //     return item.color = this.colors[['after_reinforce']][idx]
      //   })
      //   var option = buildlineOption(chart_name, chart_data);
      //   myChart2.setOption(option);
      // });


      //根据百分比动态改变
      //建筑类型柱状图
      // var box3 = document.getElementById("map-chart");
      // this.mapchart1 = this.$echarts.init(box3);  
      // //灾后恢复时间饼图     
      // var box4 = document.getElementById("map-chart2");
      // this.mapchart2 = this.$echarts.init(box4);    
      // //经济损失饼图
      // var box5 = document.getElementById("map-chart3");
      // this.mapchart3 = this.$echarts.init(box5);
      // //人口伤亡
      // var box6 = document.getElementById("map-chart4");
      // this.mapchart4 = this.$echarts.init(box6);
      // //离家住户
      // var box7 = document.getElementById("map-chart5");
      // this.mapchart5 = this.$echarts.init(box7);
    },
    showChart2(){
      var container = document.querySelector('.el-collapse-main');
      var box2 = document.getElementById("box-chart2");
      box2.style.width = container.offsetWidth + "px";
      this.myChart2 = this.$echarts.init(box2);
      this.communitiesRecoveryCount = 0;
      //categoryData: (6) ["南星街道", "小营街道", "望江街道", "清波街道", "湖滨街道", "紫阳街道"]
      //seriesData: (3) [{…}, {…}, {…}]
      //{"code":0,"data":{"categoryData":["南星街道","小营街道","望江街道","清波街道","湖滨街道","紫阳街道"],"seriesData":[{"name":"未修","data":[63,40,55,21,25,39]},{"name":"正修","data":[9,8,6,5,1,13]},{"name":"已修","data":[0,0,0,0,0,0]}]}}
      var tmp = {}
      var chart_data = {
        categoryData: [],
        seriesData: [{"name":"未修","data":[]},{"name":"正修","data":[]},{"name":"完好","data":[]}]
      }
      this.communitiesGeo.forEach((item,idx)=>{
        // if("data" in tmp[item.properties.street_name]) {
        //   tmp[item.properties.street_name].data.push(item.properties)
        // } else {
        //   tmp[item.properties.street_name].data = []
        // }
        if(item.properties.street_name in tmp) {
          tmp[item.properties.street_name].data.push(item.properties)
        } else {
          tmp[item.properties.street_name] = {"data":[],"未修":0,"正修":0,"完好":0}
          tmp[item.properties.street_name].data.push(item.properties)
          chart_data.categoryData.push(item.properties.street_name)
        }
      })
      
      for(let key in tmp) {
        tmp[key]['data'].forEach((item,idx)=>{
          if(item["day"+parseInt(this.recovery_days)] == '未修'){
            tmp[key]['未修']++
          } else if(item["day"+parseInt(this.recovery_days)] == '正修'){
            tmp[key]['正修']++
            this.communitiesRecoveryCount++;
          } else if(item["day"+parseInt(this.recovery_days)] == '完好'){
            tmp[key]['完好']++
          }
          
        })
      }
      for(let key in tmp) {
        chart_data.seriesData[0].data.push(tmp[key]['未修'])
        chart_data.seriesData[1].data.push(tmp[key]['正修'])
        chart_data.seriesData[2].data.push(tmp[key]['完好'])
      }
      
      //console.log(tmp,chart_data)
      if(this.myChart2.getOption() == undefined) {
        var chart_name = "街道中各修复状态小区总数";
        chart_data.seriesData.map((item,idx) => {
          return item.color = this.selects.communities_recoveryOptions[idx].color
        })
        var option = buildBarStackOption(chart_name, chart_data);
        this.myChart2.setOption(option);
      } else {
        let option = this.myChart2.getOption();
        option.series[0].data =  chart_data.seriesData[0].data;
        option.series[1].data =  chart_data.seriesData[1].data;
        option.series[2].data =  chart_data.seriesData[2].data;
          
        this.myChart2.setOption(option);

      }
      
      
  
      
    },
    showChart4(){
      let chart_data = JSON.parse(JSON.stringify(this.chart4Data));
      var chart_name = "街道中各修复状态建筑总数";
      this.buildingRecoveryCount = 0;
      
      chart_data.seriesData.map((item,idx) => {
        item.color = this.colors['building_recovery'][idx]
        item.data = item.data[parseInt(this.recovery_days)-1]
        
      })
      chart_data.seriesData[1].data.forEach((item,idx)=>{
        this.buildingRecoveryCount += item;
      })
      var option = buildBarStackOption(chart_name, chart_data);
      this.myChart4.setOption(option);

    },
    fillStreets(city_id) {
      getRestorationStreets(city_id).then(res => {
        let data = res.data;
        data.unshift({ id: 0, name: "街道" });
        this.selects.streets = data;
      });
    },
    fillCommunities(city_id, street_id) {
      getRestorationCommunities(city_id, street_id).then(res => {
        let data = res.data;
        data.unshift({ id: 0, name: "社区" });
        this.selects.community = 0;
        this.selects.communities = data;
      });
    },
    fillPeopleData(page) {
      let params = this.buildParams();
      params.page = page;
      getPeopleStat(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillGenderData(page) {
      let params = this.buildParams();
      params.page = page;
      getGenderStat(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillHospitalsData(city_id, page) {
      let params = this.buildParams();
      params.page = page;
      getHospitals(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillHideawaysData(page) {
      let params = this.buildParams();
      params.page = page;
      getHideaways(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillWarehousesData(page) {
      let params = this.buildParams();
      params.page = page;
      getWarehouses(params).then(res => {
        this.total = res.data.total;
        this.data = res.data.items;
      });
    },
    fillBuildings(city_id, page, isWorkerUpdate) {
      let params = this.buildParams();
      params.page = page;
      params.page_size = 10;
      if(this.selects.data_category == 'communities_recovery') {
        getList1(params).then(res => {
          this.total = res.data.total;
          this.data = res.data.items;
        });
        if(!!isWorkerUpdate || this.statistics.communities_recovery.count == '') {
           getStatistics1(params).then(res => {
            this.statistics.communities_recovery = res.data;
          });
        }
       
        this.downloadUrl = consts.requestUrl + consts.exportRecoveryCommunitiesNew + parseJsonToString(params)
      } else if(this.selects.data_category == 'building_recovery'){
        getList2(params).then(res => {
          this.total = res.data.total;
          this.data = res.data.items;
        });
        getStatistics2(params).then(res => {
          this.statistics.building_recovery.total_days = res.data.total_days;
          this.statistics.building_recovery.count = res.data.count;
        });
        this.downloadUrl = consts.requestUrl + consts.exportRecoveryBuilding + parseJsonToString(params)
      } else if(this.selects.data_category == 'resource_distribution'){
        getList3(params).then(res => {
          this.total = res.data.total;
          this.data = res.data.items;
        });
        if(!!isWorkerUpdate || this.statistics.resource_distribution.count == '') {
           getStatistics3(params).then(res => {
            this.statistics.resource_distribution = res.data;
          });
        }
        this.downloadUrl = consts.requestUrl + consts.exportResourceDistributionNew + parseJsonToString(params)
      }
      
    },
    fillBuildingsMap(city_id, color_category) {
      let _this = this;
      let params = this.buildParams();
      getGeo2(params).then(data => {
        //let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        //let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        let geoJson = data;
        geoJson.features.forEach((item,idx)=>{
          _this.buildingCommonGeo.features.forEach((subitem,subidx)=>{
              if(subitem.properties.id == item.properties.id) {
                item.geometry = subitem.geometry
              }
          })
        })
        //console.log(geoJson)
        this.addBuildings2Map(this.map, geoJson);
      });

      //params.color_category = color_category;
      // getGeoJson(params).then(res => {
      //   let geoJson = res.data;
      //   this.addBuildings2Map(this.map, geoJson);
      // });
      //临时
      // if(!this['building_plan_'+parseInt(this.worker_number)]) {
      //   fetch('/geojson/GeoJson-'+parseInt(this.worker_number)+'-building-plan.json').then(res => res.json())
      //   .then(json => {
      //     let geoJson = json;
      //     this['building_plan_'+parseInt(this.worker_number)] = geoJson;
      //     this.cur_building_plan = this['building_plan_'+parseInt(this.worker_number)];
      //     this.addBuildings2Map(this.map, geoJson);
      //     this.updateChart();  //第一次加载第二个图
      //   })
      // } else {
      //   this.cur_building_plan = this['building_plan_'+parseInt(this.worker_number)]
      //   this.addBuildings2Map(this.map, this.cur_building_plan);
      //   this.updateChart();  //第一次加载第二个图
      // }
      
      
      
      
      
    },
    fillBlockmapLayer(city_id, type, layer_name, isWorkerUpdate){
      if(!layer_name) layer_name = type;
      let params = this.buildParams();
      //params.color_category = type;
      getGeo1(params).then(res => {
        //let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        let data = res;
        let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        this.addBlockmapLayer(this.map, layer_name, geoJson, isWorkerUpdate);
      });
    
    },
    updateChart(){
      if(this.selects.data_category == 'communities_recovery') {
        // byCommunitiesTotal(this.city_id, this.worker_number, parseInt(this.recovery_days)).then(res=>{
        //   var chart_data = res.data;
        //   var chart_name = "街道中各修复状态小区总数";
        //   chart_data.seriesData.map((item,idx) => {
        //     return item.color = this.selects.communities_recoveryOptions[idx].color
        //   })
        //   var option = buildBarStackOption(chart_name, chart_data);
        //   this.myChart2.setOption(option);
        // });
        this.showChart2()
      } else if(this.selects.data_category == 'building_recovery') {
        // byRecoveryBuildingTotal(this.city_id, this.worker_number, this.recovery_days).then(res=>{
        //   var chart_data = res.data;
        //   var chart_name = "街道中各修复状态建筑总数";
        //   chart_data.seriesData.map((item,idx) => {
        //     return item.color = this.colors['building_recovery'][idx]
        //   })
        //   var option = buildBarStackOption(chart_name, chart_data);
        //   this.myChart4.setOption(option);

          
        // });
       
        this.showChart4();

        this.myChart3.setOption({
            series: [{
                id: 'timeline',
                type: 'line',
                data: [[parseInt(this.recovery_days), 0], [parseInt(this.recovery_days), 1]]
            }]
        });

        this.myChart3.dispatchAction({
            type:'hideTip'
        })
        setTimeout(()=>{
          this.myChart3.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: parseInt(this.recovery_days)-1
          })
        },10)
      }  else if (this.selects.data_category == 'resource_distribution') {
        getChart3(this.city_id, this.worker_number, this.recovery_days).then(res=>{
          var chart_data = res.data;
          this.communitiesResourceCount = 0;
          chart_data.forEach((item,idx)=>{
            this.communitiesResourceCount+=item.value;
          })
          let option = this.myChart5.getOption();
          option.series[0].data =  chart_data;
          
          this.myChart5.setOption(option);
        });
      }

      
    },
    getBuidingNameByType(type){
      let name = ''
      switch(type) {
        case '功能类型1': 
          name = "居住建筑";
        break;
        case '功能类型2': 
          name = "商用建筑";
        break;
        case '功能类型3': 
          name = "行政单位";
        break;
        case '功能类型4': 
          name = "交通出行";
        break;
        case '功能类型5': 
          name = "教育机构";
        break;
        case '功能类型6':
          name = "医疗机构";
        break;
        case '功能类型7':
          name = "旅游景点";
        break;
        case '功能类型8':
          name = "工业建筑";
        break;  
      }
      return name;
    },
    reloadPlan(isWorkerUpdate) {
      
      //修改施工人数
      if(this.selects.data_category == 'communities_recovery') {
        //更新地图
        this.fillCommonGeo();
        this.fillBlockmapLayer(this.city_id, 'communities_plan_'+this.worker_number, 'block',isWorkerUpdate);
        
      } else if(this.selects.data_category == 'building_recovery') {
        //建筑修复次序
        this.fillBuildingsMap(this.city_id, this.color_category);
      } else if(this.selects.data_category == 'resource_distribution') {
        this.fillHeatMap(this.city_id,'workernumber');
      }
      //更新图表
      this.showChart();
      //更新列表
      this.pagination.currentPage = 1;
      this.fillBuildings(this.city_id, 1, isWorkerUpdate);

    },
    updateDays () {
      //施工天数
      if(this.selects.data_category == 'communities_recovery') {
        //更新地图paint
        //this.fillBlockmapLayer(this.city_id, 'communities_plan_'+this.worker_number, 'block');
        this.updateBlockmapLayer('block');
      } else if (this.selects.data_category == 'building_recovery') {
        //更新地图paint
        this.updateBuildingmapLayer('room-extrusion')
        //this.fillBuildingsMap(this.city_id, this.color_category);

      } else if (this.selects.data_category == 'resource_distribution') {
        //更新地图paint
        //this.fillHeatMap(this.city_id,'workernumber');
        this.updateHeatmapLayer('workernumber')
      }
      this.updateChart()
      this.fillBuildings(this.city_id, this.pagination.currentPage);
    },  
    fillHeatMap(city_id, type) {
      let params = this.buildParams();
      params.color_category = this.selects.data_category;
      getGeo3(params).then(data => {
        //let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
        this.addHeatmapLayer(this.map, type, geoJson);
      });
      // if(!this['communities_workers_'+parseInt(this.worker_number)]) {
      //   //fetch
      //   fetch('/geojson/GeoJson-'+parseInt(this.worker_number)+'-commuity-workers.json').then(res => res.json())
      //   .then(json => {
      //     let geoJson = json;
      //     this['communities_workers_'+parseInt(this.worker_number)] = geoJson;
      //     this.cur_communities_workers = this['communities_workers_'+parseInt(this.worker_number)];
      //     this.addHeatmapLayer(this.map, type, this.cur_communities_workers);
      //     this.updateChart();  //第一次加载第二个图
      //   })
      // } else {
      //   this.cur_communities_workers = this['communities_workers_'+parseInt(this.worker_number)]
      //   this.addHeatmapLayer(this.map, type,this.cur_communities_workers);
      //   this.updateChart(); //第一次加载第二个图
      // }

      //临时
      //this.addHeatmapLayer(this.map, type);
    },
    reloadData(type) {
      if(type == 'street') {
        this.selects.community = 0;
      }
      this.setTipContent()
      //去掉地图图层
      this.removeMapLayer(this.map, 'block')
      this.removeMapLayer(this.map, 'blockCommon')
      this.removeMapLayer(this.map, 'room-extrusion')
      this.removeMapLayer(this.map, 'workernumber')

      this.reloadPlan();
      //this.fillBuildings(this.city_id, 1);
      //this.fillBuildingsMap(this.city_id, this.color_category);
    },
    setTipContent() {
      let html = ''
      if(this.selects.data_category == 'building_recovery') {
        html = consts.tipsRecoveryBuildingNew
      } else if(this.selects.data_category == 'communities_recovery') {
        html = consts.tipsRecoveryCommunitiesNew
      } else if(this.selects.data_category == 'resource_distribution'){
        html = consts.tipsResourceDistributionNew
      }
      document.getElementById('left-tip-content').innerHTML = html
    },
    buildParams() {
      let params = { city_id: this.city_id };
      params.street_id = this.selects.street;
      params.community_id = this.selects.community;
      //params.keyword = this.keyword;
      params.type = this.worker_number == 20000? 1:2;
      params.in_day = parseInt(this.recovery_days);
      
      if(this.selects.data_category == 'building_recovery'){
        params.struct_id = this.selects.struct;
        params.category_id = this.selects.category;
        params.quake_level = this.selects.quakeLevel;
        params.start_build_year = 0;
        params.end_build_year = 0;
        if(this.selects.buildYear != '修建年代'){
          params.start_build_year = this.selects.buildYear.split('-')[0];
          params.end_build_year = this.selects.buildYear.split('-')[1];
        }
      }

      if(this.selects.data_category == 'communities_recovery') {
        params.repair_status = (this.options[0].selected.length==0 || this.options[0].selected.length == this.options[0].data.length)?0: (this.options[0].selected.sort((a,b)=>a - b).join("-"));
      }
      if(this.selects.data_category == 'building_recovery') {
        params.repair_status = (this.options[1].selected.length==0 || this.options[1].selected.length == this.options[1].data.length)?0: (this.options[1].selected.sort((a,b)=>a - b).join("-"));
        
        //params.status_ids = this.selects.building_recovery.join(',');
      }
      // if(this.selects.data_category == 'resource_distribution') {
      //   params.status_ids = this.selects.communities_recovery.join(',');
      // }

     
      
      
      return params;
    },
    changePage: function(value) {
      this.fillBuildings(this.city_id, value);
    },
    addBuildings2Map(map, geoJsonData) {
      
      this.removeMapLayer(map, 'room-extrusion');
      let layer_id = this.getMapLayerId('room-extrusion');
      let source_name = this.getMapSourceName('room-extrusion');
      // let layer_id = "room-extrusion";
      // let source_name = "geojson";
      // var layer = map.getLayer(layer_id);
      // if (layer) {
      //   map.removeLayer(layer_id);
      //   map.removeSource(source_name);
      // }
      if(typeof geoJsonData.features == 'undefined') return;
      
      map.addSource(source_name, {
        type: "geojson",
        data: geoJsonData
      });
      let colorExpress = ["get", "color"];
      let heightExpress = ["get", "height"];
          colorExpress = ["match",
          ["get", "d"+parseInt(this.recovery_days)],
         '1',
          "#f2f2f2",
          '2',
          "#F5C243",
          '3',
          "#D55328",
          '-',
          "rgba(255,255,255,0)",
          "rgba(255,255,255,0)"
        ]

        // colorExpress = ["match",
        //   ["get", "status"],
        //  '未修',
        //   "#f2f2f2",
        //   '正修',
        //   "#F5C243",
        //   '完好',
        //   "#D55328",
        //   "#f2f2f2"
        // ]
      
      map.addLayer({
        id: layer_id,
        type: "fill-extrusion",
        source: source_name,
        //'source': {
        // GeoJSON Data source used in vector tiles, documented at
        // https://gist.github.com/ryanbaumann/a7d970386ce59d11c16278b90dde094d
        //  'type': 'geojson',
        //  'data':geoJsonData,
        //'data': "http://api.urban.test/api/buildings-geo-json?struct_id=2&category_id=1&street_id=1"
        //'data': "https://docs.mapbox.com/mapbox-gl-js/assets/indoor-3d-map.geojson"
        //},
        paint: {
          // See the Mapbox Style Specification for details on data expressions.
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions

          // Get the fill-extrusion-color from the source 'color' property.
          "fill-extrusion-color": colorExpress,

          // Get fill-extrusion-height from the source 'height' property.
          "fill-extrusion-height": heightExpress,

          // Get fill-extrusion-base from the source 'base_height' property.
          "fill-extrusion-base": ["get", "base_height"],

          // Make extrusions slightly opaque for see through indoor walls.
          "fill-extrusion-opacity": 0.8
        }
      });
    },
    addHeatmapLayer(map, type, geoJsonData) {
      // if(this.maptype != 'heatmap') {
      //   return;
      // }
      //if(typeof geoJsonData !="undefined") return;
      this.removeMapLayer(map, type);
      
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      let layer_point_id = layer_id + "point";
      //let get_param = 'number';
      let get_param = "day"+(parseInt(this.recovery_days));
      // Add a geojson point source.
      // Heatmap layers also work with a vector tile source.
      
      map.addSource(source_name, {
        type: "geojson",
        //data: "https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson"
        data: geoJsonData
        //data: "./geojson/GeoJson-2.2.2-3.geojson"
      });

      map.addLayer(
        {
          id: layer_id,
          type: "heatmap",
          source: source_name,
          maxzoom: 15,
          paint: {
            // Increase the heatmap weight based on frequency and property magnitude
            "heatmap-weight": [
              "interpolate",
              ["linear"],
              ["get", get_param],
              0, 0,
              500, 1
            ],
            // Increase the heatmap color weight weight by zoom level
            // heatmap-intensity is a multiplier on top of heatmap-weight
            "heatmap-intensity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              0,
              1,
              18,
              4
            ],
            // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
            // Begin color ramp at 0-stop with a 0-transparancy color
            // to create a blur-like effect.
            "heatmap-color": [
              "interpolate",
              ["linear"],
              ["heatmap-density"],
              0,
              "rgba(0, 0, 255, 0)",
              0.3,
              "#7daa54",
              0.6,
              "#f6c143",
              1,
              "#ec3424"
            ],
            // Adjust the heatmap radius by zoom level
            "heatmap-radius": [
              "interpolate",
              ["linear"],
              ["zoom"],
              0,
              1,
              15,
              20//热点大图半径
            ],
            // Transition from heatmap to circle layer by zoom level
            "heatmap-opacity": ["interpolate", ["linear"], ["zoom"], 9, 1, 15, 0.2]
          }
        },
        "waterway-label"
      );

      map.addLayer(
        {
          id: layer_point_id,
          type: "circle",
          source: source_name,
          minzoom: 13,
          paint: {
            // Size circle radius by earthquake magnitude and zoom level
            "circle-radius": [
              "interpolate",
              ["linear"],
              ["zoom"],
              13,
              ["interpolate", ["linear"], ["get", get_param], 0, 1, 500, 5],
              15,
              ["interpolate", ["linear"], ["get", get_param], 0, 5, 500, 20]
            ],
            // Color circle by earthquake magnitude
            "circle-color": [
              "interpolate",
              ["linear"],
              ["get", get_param],
              0,
              "rgba(0, 0, 255, 0)",
              100,
              "#7daa54",
              200,
              "#f6c143",
              500,
              "#ec3424"
            ],
            //"circle-stroke-color": "white",
            //"circle-stroke-width": 1,
            // Transition from heatmap to circle layer by zoom level
            "circle-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              12,
              0,
              16,
              1
            ]
          }
        },
        "waterway-label"
      );
    },
    addPointLayerToMap(map, type) {
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      let geojson_url,
        icon = "";
      switch (type) {
        case "hospital":
          geojson_url = consts.url_geojson_hospital;
          icon = "hospital-15";
          break;
        case "hideaway":
          geojson_url = consts.url_geojson_hideaway;
          icon = "school-15";
          break;
        case "warehouse":
          geojson_url = consts.url_geojson_warehouse;
          icon = "parking-15";
          break;
      }
      if (geojson_url == "") {
        return;
      }
      var styleLoadStatus = map.isStyleLoaded();
      if (styleLoadStatus) {
        map.addSource(source_name, {
          type: "geojson",
          data: geojson_url
        });
        map.addLayer({
          id: layer_id,
          type: "symbol",
          source: source_name,
          layout: {
            "icon-image": icon,
            "text-field": "{name}",
            //"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-offset": [0, 0.6],
            "text-anchor": "top"
          }
        });
        return;
      }
      map.on("style.load", function() {
        map.addSource(source_name, {
          type: "geojson",
          data: geojson_url
        });
        map.addLayer({
          id: layer_id,
          type: "symbol",
          source: source_name,
          layout: {
            "icon-image": icon,
            "text-field": "{name}",
            //"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-offset": [0, 0.6],
            "text-anchor": "top"
          }
        });
      });
    },
    addBlockmapLayer(map, type, geoJsonData, isWorkerUpdate) {
      let _this = this;
      this.removeMapLayer(map, type);
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      let layer_id_stroke = layer_id+'_stroke';
      if(typeof geoJsonData.features == 'undefined') return;
      
      
      let colorExpress = [];
      if(type == "blockCommon") {
        colorExpress = "rgba(255,255,255,0.3)";
      } else {
        if(this.selects.data_category == 'communities_recovery') {
          if(!!isWorkerUpdate || this.communitiesGeo.length == 0) {
            this.communitiesGeo = geoJsonData.features;
            this.showChart2();
          }
        
          
          // colorExpress = ["interpolate",["linear"],["get", "day"+parseInt(this.recovery_days)],
          //     1.2, "#f2f2f2",
          //     2.2, "#4caeeb",
          //     3.2, "#4d71be",
          //     4, "rgba(0,0,0,0)"
          //   ]
          colorExpress = ["match",
            ["get", "day"+parseInt(this.recovery_days)],
            '未修',
            "#f2f2f2",
            '正修',
            "#4caeeb",
            '完好',
            "#4d71be",
            '-',
            "rgba(255,255,255,0)",
            "rgba(255,255,255,0)"
          ]
        };
      }
      
      map.addSource(source_name, {
        type: "geojson",
        data: geoJsonData
        //data: "./geojson/GeoJson-block.geojson"
      });
      map.addLayer({
        id: layer_id,
        type: "fill",
        source: source_name,
        layout: {},
        paint: {
          "fill-color": colorExpress,
          "fill-opacity": 1
        }
      });

      //描边
      map.addLayer({
        id: layer_id_stroke,
        type: "line",
        source: source_name,
        layout: {},
        paint: {
          "line-color": '#000000',
          "line-width": 1
        }
      });
    },
    updateBlockmapLayer(layer_name){
      let layer_id = this.getMapLayerId(layer_name);
      if(!this.map.getLayer(layer_id))return;
      let colorExpress = [];
      // colorExpress = ["interpolate",["linear"],["get", "day"+parseInt(this.recovery_days)],
      //      1.2, "#f2f2f2",
      //       2.2, "#4caeeb",
      //       3.2, "#4d71be",
      //       4, "rgba(0,0,0,0)"
      //   ]
      colorExpress = ["match",
          ["get", "day"+parseInt(this.recovery_days)],
          '未修',
          "#f2f2f2",
          '正修',
          "#4caeeb",
          '完好',
          "#4d71be",
          '-',
          "rgba(255,255,255,0)",
          "rgba(255,255,255,0)"
        ];
      
      this.map.setPaintProperty(layer_id, 'fill-color', colorExpress);
    },
    updateBuildingmapLayer(layer_name){
      let layer_id = this.getMapLayerId(layer_name);
      if(!this.map.getLayer(layer_id))return;
      let colorExpress = [];
      // colorExpress = ["interpolate",["linear"],["get", "Day"+parseInt(this.recovery_days)],
      //     0, "#f2f2f2",
      //     0.8, "#4caeeb",
      //     1.2, "#4d71be",
      //     2, "rgba(0,0,0,0)"
      //   ]
       colorExpress = ["match",
          ["get", "d"+parseInt(this.recovery_days)],
          '1',
          "#f2f2f2",
          '2',
          "#F5C243",
          '3',
          "#D55328",
          '-',
          "rgba(255,255,255,0)",
          "rgba(255,255,255,0)"
        ]
      this.map.setPaintProperty(layer_id, 'fill-extrusion-color', colorExpress);
    },
    updateHeatmapLayer(layer_name){
      let layer_id = this.getMapLayerId(layer_name);
      if(!this.map.getLayer(layer_id))return;
      let layer_point_id = layer_id + "point";
      let heatmapWeight = [];
      let get_param = "day"+parseInt(this.recovery_days)
      heatmapWeight = ["interpolate",["linear"],["get", get_param],
          0, 0,
          500, 1
        ];
      let circleRadius = [];
      circleRadius = [
              "interpolate",
              ["linear"],
              ["zoom"],
              13,
              ["interpolate", ["linear"], ["get", get_param], 0, 1, 500, 5],
              15,
              ["interpolate", ["linear"], ["get", get_param], 0, 5, 500, 20]
            ]
      let circleColor = [];
      circleColor = [
              "interpolate",
              ["linear"],
              ["get", get_param],
              0,
              "rgba(0, 0, 255, 0)",
              100,
              "#7daa54",
              200,
              "#f6c143",
              500,
              "#ec3424"
            ]
      
      this.map.setPaintProperty(layer_id, 'heatmap-weight', heatmapWeight);
      this.map.setPaintProperty(layer_point_id, 'circle-radius', circleRadius);
      this.map.setPaintProperty(layer_point_id, 'circle-color', circleColor);
    },
    toggleMapLayer(type) {
      this.fillBuildingsMap(this.city_id, this.color_category);
    },
    handelMapLayer(type) {
      //console.log(type);
      this.selects.street = 0;
      this.selects.community = 0;
      this.loadData(type);
      let types = ["hospital", "hideaway", "warehouse"];
      types.forEach(value => {
        if (value == type) {
          this.addPointLayerToMap(this.map, value);
        } else {
          this.removeMapLayer(this.map, value);
        }
      });
    },
    getMapLayerId(type) {
      let layer_id = type + "-layer";
      return layer_id;
    },
    getMapSourceName(type) {
      let source_name = type + "-data";
      return source_name;
    },
    removeMapLayer(map, type) {
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      var layer = map.getLayer(layer_id);
      let layer_point_id = layer_id + "point";
      //描边
      var layer_stroke = map.getLayer(layer_id+'_stroke');
      //热点图的热点层
      if (map.getLayer(layer_point_id)) {
        map.removeLayer(layer_point_id);
      }
      if(layer_stroke){
          map.removeLayer(layer_id+'_stroke');
        }
      if (layer) {
        map.removeLayer(layer_id);
      }
      if(map.getSource(source_name)) {
        map.removeSource(source_name);
      } 
    },
    removeHeatmapLayer(map, type) {
      let source_name = this.getMapSourceName(type);
      let layer_id = this.getMapLayerId(type);
      let layer_point_id = layer_id + "point";
      var layer = map.getLayer(layer_id);
      if (layer) {
        map.removeLayer(layer_id);
        map.removeLayer(layer_point_id);
        map.removeSource(source_name);
      }
    },
    loadData(type, page = 1) {
      switch (type) {
        case "people":
          this.fillPeopleData(page);
          break;
        case "gender":
          this.fillGenderData(page);
          break;
        case "hospital":
          this.fillHospitalsData(page);
          break;
        case "hideaway":
          this.fillHideawaysData();
          break;
        case "warehouse":
          this.fillWarehousesData();
          break;
      }
    },
    playTimeline() {
      clearInterval(this.itv)
      this.playing = !this.playing;
      if(this.playing) {
        this.itv = setInterval(()=>{
          this.recovery_days++
          if(this.recovery_days >=  309) this.recovery_days = 1;
        },1000)
      }
    }
  }
};
</script>



<style lang="scss" scoped>
.card-map {
  min-height: 350px;
  height: calc(100vh - 48px);
  margin-bottom: 0;

  position: relative;
  .map-wrapper{
    width: 100%;
    height: calc(100vh - 100px);
    position: relative;
  }
  #map {
    width: 100%;
    height: calc(100vh - 100px);
  }
  .map_data_msg {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    // color: #02f4fe;
    color: #fff;
    padding: 10px;
    width: auto;
    .map_data_legend {
      margin-bottom: 5px;
    }
    .map_data_msg_wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .item {
        width: 100px;
        line-height: 15px;
        padding: 5px 0;
      }
      .item span {
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 3px;
        vertical-align: middle;
        margin-left: 0;
      }
      .map_data_msg_inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 10px;
      }
    }
    &.map_data_chart {
      width: 75%;
      bottom: 80px;
      background: rgba(255, 255, 255, 0);
      .map_data_chart_inner {
        width: 100%;
        display: flex;
      }
      .map-chart-area { 
        width: 10vw;
        height: 200px;  
      }
      #map-chart {
        width: 15vw;
        height: 200px;
      }
    }
  }
}

.overlap_layer_desc {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .item {
    line-height: 15px;
    padding: 10px 10px 10px 0;
  }
  .item span {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 3px;
    vertical-align: top;
    margin-left: 0;
  }
  .map_data_msg_inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 10px;
  }
}

.main-panel {
  // height: calc(100vh - 130px);
  min-height: 350px;
}


.right-block {
  height: calc(100vh - 360px);
  // min-height: 350px;
  overflow: hidden;
  .tab-pane   {
    height: 50vh;
    overflow: auto; 
    overflow-scrolling:touch; 
    -webkit-overflow-scrolling:touch;
  }
}
.silide-wrapper {
  width: 76%;
  margin: 0 auto;
}
.decision-wrapper {
  padding: 0;  
}
.decision-block {
  overflow: hidden;
  padding-bottom: 30px;
}
.slide-start {
  float: left;
  margin: 10px 0 0 20px;
}
.slide-end {
  float: right;
  margin: 10px 20px 0 0;
}
.demonstration {
  padding: 10px 0;
  display: block;
  float: left;
}
.tab-scroller {
  height: 100%;
  min-height: 350px;
  overflow-y: auto;
}

.msg-percent {
  padding: 10px 0 0 0;  
  float: right;
}


.show_list {
  width: 100%;
  height: 50px;
  padding-top: 6px;

  // margin-top: 8px;
}
.show_list span {
  height: 32px;
  line-height: 36px;
  margin-left: 32px;
  font-size: 14px;
}
.show_list span i {
  height: 16px;
  padding: 2px 12px;
  background: #ffb236;
  margin: 0 4px;
  border-radius: 16px;
}
.show_list .up_show {
  display: block;
  float: left;
  font-size: 32px;
  cursor: pointer;
}
.show_list .download {
  display: block;
  float: right;
  font-size: 32px;
  cursor: pointer;
}
.footer {
  padding-top: 0;
}
.extended-forms .el-select {
  width: 100%;
  margin-bottom: 30px;
}

.extended-forms .progress {
  margin-bottom: 30px;
}
@import url("https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css");
.coordinates {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 5px 10px;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  display: none;
}
ul.nav__items > li:last-of-type a {
  margin-right: 0 !important;
}
.card-body .tab-content .tab-scroller {
  min-height: auto;
}
// .card-map .card-body {
//   padding: 0;
// }
.chart-area {
  height: 400px;
}
.chart-wrapper {
  width: calc(100% + 15px);
  height: 400px;
  // overflow-y: auto;
  margin-bottom: 40px;
  .chart-area {
    margin-left: 0;
  }
}
.recovery_days {
    position: absolute;
    bottom: 70px;
    left: 370px;
    //transform: translate(-45%);
    color: #fff;
    width: calc(100% - 600px);
    // height: 100px;
    padding: 20px 20px 50px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .recovery_lable {
      // float: left;
      margin: 0;
      // margin: -10px 0 0 0;
    }
    .bar {
      // float: left;
      width: 87%;
    }
    .start_day {
      position: absolute;
      left: 90px;
      top: 40px;
    }
    .end_day {
      // float: left;
      position: absolute;
      right: 20px;
      top: 40px;
      // position: absolute;
      // right: -50px;
      // top: -11px;
    }
    .timeline-controller {
      // position: relative;
      // top: -10px;
      // display: inline-block;
      // float: left;
     position: absolute;
     left: 55%;
     bottom: 15px;
     transform: translate(-50%);
    }
    .timeline-controller i {
      width: 100%;
      font-size: 24px;
      margin-left: 10px;
    }
}
#customStyle {
  padding-top: 240px!important;
}
.key-decision {
  height: 240px!important;
}
.key-decision {
  .el-collapse {
    padding: 0;
    margin: 10px 0;
  }
  /deep/ .el-collapse-item__content {
    padding: 10px 0!important;
  }
}
</style>
