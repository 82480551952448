<template>
    <div class="row" style="margin:0 -30px;">
        <div class="col-md-9" style="padding:0">
            <card class="card-map">
                <div id="map"></div>
                <menu-map :map="map" :mapDefault="mapdefaultSetting" showDisaster="false" showWeather="true"
                    :marginTop="marginTop"></menu-map>
                <div class="radio-group">
                    <div v-for="(item, idx) in types" @click="changeType(item.id)" :key="idx">
                        <label :for='item.id'><input type="radio" :id="item.id" :value="item.id" v-model="curType">
                            {{ item.value }}</label>
                    </div>
                </div>
                <div class="opts">
                    <div class="opt-title">备选最优方案</div>
                    <div class="opt-list">
                        <div class="opt-item" :class="planId == 1 ? 'cur' : ''" @click="choosePlan(1)">新增消防人员指数总和最大
                        </div>
                        <div class="opt-item" :class="planId == 2 ? 'cur' : ''" @click="choosePlan(2)">新增消防装备指数总和最大
                        </div>
                    </div>
                </div>
                <div class="map_data_msg" v-if="mapinfo">
                    <div class="map_data_legend">{{ mapinfo.title }}</div>
                    <div class="map_data_msg_wrapper map_data_msg_wrapper_vertical">
                        <div class="item" v-for="(item, index) in mapinfo.names" :key="index">
                            <span :style="'background: ' + mapinfo.colors[index] + ''"></span>
                            {{ item }}
                        </div>
                    </div>
                </div>
                <div class="show_list">
                    <a class="up_show" v-on:click="showList">
                        <i class="now-ui-icons" :class="[show_list ? 'arrows-1_minimal-down' : 'arrows-1_minimal-up']"></i>
                    </a>
                    <a class="download ml-auto" :href="downloadUrl">
                        <i class="now-ui-icons arrows-1_cloud-download-93"></i>
                    </a>
                    <span>
                        数据列表
                        <!-- <i>{{total}}</i> -->
                    </span>
                </div>
                <div class="show_list_record tabCount2 show_fire_list_record" v-show="show_list" ref="show_list_record">
                    <el-table :data="data" max-height="600" :span-method="objectSpanMethod" empty-text="暂无数据！">
                        <el-table-column v-for="(item, index) in listheader" min-width="150" :label="item" :key="index">
                            <template slot-scope="scope">
                                <span>{{ scope.row[index] }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </card>
        </div>
        <div class="col-md-3" id="customStyle">
            <el-collapse v-model="activeNames">
                <el-collapse-item title="信息图表" name="2">
                    <div class="col-md-12">
                        总投入费用W <el-input v-model="cost_amount" placeholder="请输入内容" class="cost_amount"
                            @change="stationNumChange"></el-input> 万元
                    </div>
                    <div class="col-md-12">
                        <div class="chart-area" id="box-chart" style="height:300px" v-show="!hideChart"></div>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
        <div class="key-infos">
        </div>
    </div>
</template>
<script>
import mapboxgl from "mapbox-gl";
import MenuMap from "src/components/menumapFire3";
import SearchArea from "src/components/SearchFireStation";
import geobuf from "geobuf";
import Pbf from "pbf";
import MiniMap from "src/mapbox/mapboxgl-minimap.js";
import { TabPane, Tabs, Switch } from "src/components";
import { Radio } from 'src/components/index';
import { Input, Button, Select, Option, Cascader, Collapse, CollapseItem, Table, TableColumn, Slider, Loading } from "element-ui";
import { consts } from "src/util/consts.js";
import {
    getDecisionOptimizationBalancesChart as getChart1,
    getDecisionOptimizationBalancesChart2 as getChart2,
    getDecisionOptimizationBalancesList as getList1,

    //getBasicFullList as getList1,
    //getFireStationList as getList2,
    getFireDivisionsGridsGeo as getGeo1, //底图
    getFireDivisionsStationGeo as getGeo2, //消防站点
    getFireStationGeoNew as getStrokeGeoJson, //消防站轮廓
    getBasicExport as getExport,
    getDecisionOptimizationBalancesInfo as getInfo,
    getDecisionOptimizationBalancesShowMap as getMapInfo,
    getDecisionOptimizationBalancesMapData as getMapData, //最优点
    getDecisionOptimizationBalancesMapGrid as getMapGrid,
    getDecisionLayoutOptimizationBalancesDefaultAamount,
    getDecisionLayoutOptimizationBalancesComputeAamount
} from "src/api/fire.js";
import { getHideawaylGeo } from 'src/api/cityinfo.js';
import { buildPieOption, buildBarYOption, buildBarStackYValueOption } from "src/util/chart.js";
import { storeMapOptions, getMapOptions, parseJsonToString } from "src/util/common.js";
import { Pagination as NPagination } from "src/components";

import * as THREE from "three";
import { RGBADepthPacking } from "three";

export default {
    components: {
        MenuMap,
        SearchArea,
        TabPane,
        Tabs,
        [Cascader.name]: Cascader,
        [Collapse.name]: Collapse,
        [CollapseItem.name]: CollapseItem,
        [Input.name]: Input,
        [Button.name]: Button,
        [Option.name]: Option,
        [Select.name]: Select,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        NPagination,
        [Switch.name]: Switch,
        [Slider.name]: Slider,
        Radio
    },
    data() {
        return {
            mid: 0, //模块id
            types: [], //左上角切换radio
            curType: 0,
            activeNames: ['2', '3'],
            activeName: "first",
            selects: {
                town_id: 1,
                street_id: 0,
                ids: {},
                filter_street_id: 0,//地图筛选的street_id
            },
            props: { multiple: true },
            options1: [],
            options2: [],
            keyword: "",
            total: 0,
            data: [],
            data2: [],
            data3: [],
            page: 1,
            map: {},
            city_id: localStorage.getItem("city_id") || 1,
            show_list: false,
            show_list_height: 0,
            pagination: {
                perPage: 10,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
                total: 20
            },
            last_updated: consts.last_updated,
            mapdefaultSetting: {},
            statistics: {
            },
            downloadUrl: '',
            options: [
                { name: '用电量', selected: [], data: [] },
            ],
            mapinfo: null,
            listheader: [],
            listheader2: [],
            listheader3: [],
            tabCount: 1,
            tab2Mid: 1,
            activeTab: '表1',
            marginTop: 80,
            planId: 1, //默认第一种方案
            stationNumMax: 9,
            stationNumMin: 3,
            cost_amount: 20000, //总投入费用
            grid_ids: [], //推荐的网格ids
            custom_grid_ids: [], //自选的网格ids
            hideChart: false,
        };
    },

    mounted() {
        let _this = this;
        //localStorage.setItem('curStroke', 'street');
        //this.fillStreets(this.city_id);
        this.mid = 1;
        this.curType = 4;
        this.setTipContent()
        this.showChart(this.city_id);

        this.initMap(this.city_id);
        this.map.on('style.load', function () {
            getDecisionLayoutOptimizationBalancesDefaultAamount(_this.buildParams()).then((res) => {
                _this.cost_amount = res.data.amount
                getMapInfo(_this.buildParams()).then(res => {
                    _this.mapinfo = res.data;
                    _this.reloadData()
                });
            })


        })
        // this.map.on("click", (e) => {
        //     var features = this.map.queryRenderedFeatures(e.point, {
        //       layers: ["block-layer","points"],
        //     });
        //     if(!features.length) return;
        //     let pointscount = 0;
        //     let blockcount = 0;
        //     features.forEach((item,idx)=>{
        //       if(item.layer.id=='points') {
        //         pointscount++
        //       } else if(item.layer.id=='block-layer'){
        //         blockcount++
        //       }
        //     })
        //     if(pointscount >=1 && blockcount>=1) {
        //       //点和区都点了，触发点
        //       this.mapClickEventNew(features[0])
        //     } else if(pointscount >= 1){
        //       this.mapClickEventNew(features[0])
        //     } else if(blockcount >= 1) {
        //       this.mapClickEvent2New(features[0])
        //     }

        // });
    },
    computed: {
        to() {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        // street (){
        //   return this.selects.street
        // }
    },
    watch: {

        "selects.street"(val) {
            //街道切换选社区
            this.fillCommunities(this.city_id, val);
        },
    },
    methods: {
        // 初始化
        initMap(city_id) {
            this.mapdefaultSetting = {
                center: consts.mapCenter[this.city_id - 1],
                zoom: consts.mapDefaultZoom,
                pitch: 0,
                city_id: this.city_id
            }
            mapboxgl.accessToken = consts.mapboxAccessToken;
            var origin = [-95.97299, 36.15031, 0];
            let map_options = getMapOptions();
            //console.log(map_options);
            let zoom = map_options ? map_options.zoom : 12;
            let center = map_options ? [map_options.lng, map_options.lat] : consts.mapCenter[city_id - 1];
            //console.log(center);
            var map = new mapboxgl.Map({
                container: "map",
                style: consts.mapBoxStyle,
                //center: [-96, 37.8],
                center: center,
                zoom: zoom,
                pitch: 0,
                bearing: 0,
                attributionControl: false
            });
            this.map = map;
            //定位图标
            map.addControl(
                new MapboxGeocoder({
                    accessToken: mapboxgl.accessToken,
                    mapboxgl: mapboxgl
                })
            );
            this.map.addControl(new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                trackUserLocation: true
            }));
            //控制图层
            var nav = new mapboxgl.NavigationControl();
            this.map.addControl(nav, 'top-right');
            //全屏按钮
            this.map.addControl(new mapboxgl.FullscreenControl({
                container: document.querySelector('body')
            })
            );
            //小地图
            //https://github.com/brendanmatkin/mapboxgl-minimap
            map.on("style.load", function () {
                // Possible position values are 'bottom-left', 'bottom-right', 'top-left', 'top-right'
                map.addControl(new MiniMap({
                    id: "mapboxgl-minimap",
                    width: "200px",
                    height: "150px",
                    //style: "mapbox://styles/mapbox/streets-v10",
                    style: consts.mapBoxStyle,
                    fillColor: "#f58220",
                    zoomAdjust: null
                }),
                    'bottom-right');
            });

            //记录地图设置
            map.on('move', function () {
                storeMapOptions({
                    lng: map.getCenter().lng,
                    lat: map.getCenter().lat,
                    zoom: map.getZoom()
                });
            });

        },
        showList() {
            this.show_list = !this.show_list;
        },
        donwloadList() { },
        showChart(city_id) {
            var _this = this;
            var container = document.querySelector('.el-collapse');
            var box = document.getElementById("box-chart");
            var myChart = this.$echarts.init(box);
            this.myChart = myChart
            box.style.width = container.offsetWidth + "px";



            getChart1({
                "city_id": this.city_id,
                "town_id": 1,
                "module_id": this.mid,
                //"grid_count": this.grid_count,
                "amount": this.cost_amount,
            }).then(res => {
                this.chart_basic_data = res.data;
            });


            this.myChart.resize();

            window.onresize = function () {
                document.querySelectorAll('.chart-area').forEach((item, idx) => {
                    item.style.width = document.querySelector('.el-collapse').offsetWidth + 'px';
                })
                _this.myChart.resize();

            }
        },
        updateChart() {
            getChart1({
                "city_id": this.city_id,
                "town_id": 1,
                "module_id": this.mid,
                //"grid_count": this.grid_count,
                //"type_id": this.planId,
                "amount": this.cost_amount,
            }).then(res => {
                if (res.code == 100) {
                    this.hideChart = true;
                    return;
                }
                this.hideChart = false;
                this.chart_basic_data = res.data;


                getChart2({
                    "city_id": this.city_id,
                    "town_id": 1,
                    "module_id": this.mid,
                    //"grid_ids": this.grid_ids,
                    "type_id": this.planId,
                    "amount": this.cost_amount,
                }).then(res => {
                    var chart_data = this.chart_basic_data.data;
                    var chart_name = this.chart_basic_data.title;
                    var chart_colors = this.chart_basic_data.colors;
                    var chart_values = this.chart_basic_data.values;
                    var new_chart_data = []
                    var option = {
                        grid: {
                            left: '0',
                            right: '65',
                            bottom: '2%',
                            top: '30',
                            containLabel: true
                        },
                        title: {
                            text: chart_name,
                            left: 'left',
                            textStyle: {
                                fontSize: 12,
                                color: "#fff"
                            }
                        },
                        tooltip: {
                            show: false,
                            trigger: 'item',
                            formatter: '{b}:{c} '
                        },
                        yAxis: {
                            type: 'category',
                            nameLocation: 'end',//坐标轴名称显示位置。
                            axisLine: {
                                lineStyle: {
                                    color: "#fff"
                                }
                            },
                            axisLabel: {//坐标轴刻度标签的相关设置。
                                textStyle: {
                                    color: "#fff"
                                }
                            },
                            data: []
                        },
                        xAxis: {
                            type: 'value',
                            max: 100,
                            axisLine: {
                                lineStyle: {
                                    color: "#fff"
                                }
                            },
                            axisLabel: {
                                textStyle: {
                                    fontSize: 12,
                                    color: "#fff"
                                },
                                formatter: "{value}%",
                                max: 100,
                            }
                        },
                        series: [
                            {
                                itemStyle: {
                                    normal: {
                                        label: {
                                            show: true, position: 'right', textStyle: { color: '#ffffff', fontSize: 10 }, formatter: function (params) {
                                                return params.data.realValue + '\n(' + params.value.toFixed(2) + '%)'
                                            }
                                        }
                                    }
                                },
                                z: -1,
                                barGap: '-100%',
                                data: [],
                                type: 'bar',
                                barWidth: 20,
                            },
                            {
                                itemStyle: {
                                    normal: {
                                        label: {
                                            show: true, position: 'insideLeft', textStyle: { color: '#ffffff', fontSize: 10 }, formatter: function (params) {
                                                return params.data.realValue + ' (' + params.value.toFixed(2) + '%)'
                                            }
                                        }
                                    }
                                },
                                data: [],
                                type: 'bar',
                                barWidth: 20,
                            }
                        ]
                    };



                    chart_data.forEach(item => {
                        option.yAxis.data.push(item.name.replace("+", "+\n").replace("×", "×\n").replace("行车到场时长缩短值（秒）", "行车到场时长缩短值\n（秒）"))
                        //底部柱状图
                        option.series[0]['data'].push({
                            value: 100,
                            realValue: item.value,
                            itemStyle: {
                                color: 'rgba(255,255,255,0.5)',
                            },
                        })
                        //上层柱状图
                        let color = chart_colors[0]
                        let val = res.data[item.column] / item.value
                        chart_values.forEach((subitem, subidx) => {
                            if (val > subitem) {
                                color = chart_colors[subidx + 1]
                            }
                        })
                        option.series[1]['data'].push({
                            value: res.data[item.column] / item.value * 100,
                            realValue: res.data[item.column],
                            itemStyle: {
                                color: color,
                            },
                        })
                    })

                    option.tooltip.position = function (p) { //其中p为当前鼠标的位置
                        return [p[0] - 40, p[1] + 10];
                    }

                    this.myChart.setOption(option);


                });
            });
        },
        handleChange1(value) {
            //右侧下拉change1
            this.selects.town_id = 1
            this.selects.street_id = value[2] || 0
            this.reloadData();

        },
        handleChange2(value) {
            //右侧下拉change2
            let _this = this;
            this.selects.ids = {}
            value.forEach((item, idx) => {
                if (typeof _this.selects.ids[item[2]] == 'undefined') {
                    _this.selects.ids[item[2]] = '';
                }
                _this.selects.ids[item[2]] += item[3] + '-'
            })
            this.reloadData();
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            let returnobj = null;
            let returnobj2 = null;
            let _this = this;
            if (columnIndex == 0) {
                let hasColspan = false;
                this.col1IdxArr.forEach((item, idx) => {
                    if (!hasColspan) {
                        returnobj = {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                    if (rowIndex == item) {
                        hasColspan = true;
                        returnobj = {
                            rowspan: _this.col1Arr[idx],
                            colspan: 1
                        };
                    }
                })
                return returnobj;

            }
            if (columnIndex == 1 || columnIndex == 2) {
                let hasColspan = false;
                this.col2IdxArr.forEach((item, idx) => {
                    if (!hasColspan) {
                        returnobj2 = {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                    if (rowIndex == item) {
                        hasColspan = true;
                        returnobj2 = {
                            rowspan: _this.col2Arr[idx],
                            colspan: 1
                        };
                    }
                })
                return returnobj2;
            }

        },
        fillList1(city_id, page) {
            let _this = this;
            let params = this.buildParams(this.planId);
            //params.page = page;
            this.col1Arr = []; //第一列数量和
            this.col2Arr = []; //第二列数量和
            this.col1IdxArr = []; //第一列序号
            this.col2IdxArr = []; //第二列序号
            this.col1Last = ''; //第一行最后一项
            this.col2Last = ''; //第二行最后一项
            getList1(params).then(res => {
                //this.data = res.data;
                let header = res.data.header;
                let body = res.data.body;
                let data = [];
                if (body) {
                    body.forEach((item, idx) => {
                        let newitem = {}
                        item.forEach((subitem, subidx) => {
                            newitem[subidx] = subitem
                        })
                        data.push(newitem)
                    })
                }

                this.listheader = header;
                this.data = data;


                this.data.forEach((item, idx) => {
                    if (item['0'] == _this.col1Last) {
                        _this.col1Arr[_this.col1Arr.length - 1]++;
                    } else {
                        _this.col1Arr.push(1);
                        _this.col1IdxArr.push(idx);
                        _this.col1Last = item['0']
                    }

                    if (item['1'] == _this.col2Last) {
                        _this.col2Arr[_this.col2Arr.length - 1]++;
                    } else {
                        _this.col2Arr.push(1);
                        _this.col2IdxArr.push(idx);
                        _this.col2Last = item['1']
                    }

                })


            });





            this.downloadUrl = consts.requestUrl + consts.exportFire2DecisionOptimizationBalance + parseJsonToString(params)

        },
        fill2Map(city_id) {
            let params = this.buildParams();
            getMapGrid(params).then(res => {
                getGeo1(params).then(res2 => {
                    let data = res2;
                    let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
                    this.gridGeoJson = geoJson;
                    this.addBlockmapLayer(this.map, 'block', geoJson, res.data.map_data);
                    this.fillBuildingsMap();
                    this.fillPointLayer()
                    this.choosePlan(1)

                });
            });
        },
        fillCommunities(city_id, street_id) {
            if (this.selects.data_category == 'people') {
                getCommunities(city_id, street_id).then(res => {
                    let data = res.data;
                    data.unshift({ id: 0, name: "社区" });
                    this.selects.communities = data;
                });
            } else if (this.selects.data_category == 'hideaway') {
                getCommunitiesHideaway(city_id, street_id).then(res => {
                    let data = res.data;
                    data.unshift({ id: 0, name: "社区" });
                    this.selects.communities = data;
                });
            } else if (this.selects.data_category == 'hospital') {
                getCommunitiesHospital(city_id, street_id).then(res => {
                    let data = res.data;
                    data.unshift({ id: 0, name: "社区" });
                    this.selects.communities = data;
                });
            } else if (this.selects.data_category == 'warehouse') {
                getCommunitiesWarehouse(city_id, street_id).then(res => {
                    let data = res.data;
                    data.unshift({ id: 0, name: "社区" });
                    this.selects.communities = data;
                });
            }

        },
        addStrokemapLayer(map, type, geojson) {
            let _this = this;
            this.removeMapLayer(map, type);
            let source_name = this.getMapSourceName(type);
            this.source_name = source_name
            let layer_id = this.getMapLayerId(type);
            if (typeof geojson.features == 'undefined') return;



            map.addSource(source_name, {
                type: "geojson",
                data: geojson,
            });
            //描边
            map.addLayer({
                id: layer_id,
                type: "line",
                source: source_name,
                layout: {
                    "line-join": "round",
                    "line-cap": "round"
                },
                paint: {
                    "line-color": "#ffffff",
                    "line-width": 3,
                    "line-dasharray": [2, 4]
                }
            });


        },
        addBlockmapLayer(map, type, geojson, mapdata) {
            let _this = this;
            this.removeMapLayer(map, type);
            let source_name = this.getMapSourceName(type);
            this.source_name = source_name
            let layer_id = this.getMapLayerId(type);
            if (typeof geojson.features == 'undefined') return;
            //map.off('click', layer_id, this.mapClickEvent2);

            //let pattenGeojson = Object.assign({}, geojson) //过滤出显示斜线的数据
            //pattenGeojson.features = [];
            geojson.features.forEach((item, idx) => {
                item.properties.value = mapdata[item.properties.id]
                // if(item.properties.value == 4) {
                //   pattenGeojson.features.push(item)
                // }

            })



            let layer_id_stroke = layer_id + '_stroke';
            map.addSource(source_name, {
                type: "geojson",
                data: geojson,
                generateId: true
            });

            //填充的geo source
            //  map.addSource('pattern-source', {
            //   type: "geojson",
            //   data: pattenGeojson,
            //   generateId: true
            // });

            let colorExpress = '';
            let strokeColor = '#000000'
            let fillOpacity = 1;
            colorExpress = [
                "match",
                ["get", "value"],
                //4, "#e3d8d2",
            ]

            this.mapinfo.values.forEach((item, idx) => {
                colorExpress.push(item, _this.mapinfo.colors[idx])
            })

            colorExpress.push("rgba(255,255,255,0)")
            map.addLayer({
                id: layer_id,
                type: "fill",
                source: source_name,
                layout: {},
                paint: {
                    "fill-color": colorExpress,
                    "fill-opacity": fillOpacity,
                }
            });

            //   map.loadImage(
            //     '/img/patten.png',
            //     (err, image) => {
            //         // Throw an error if something goes wrong.
            //         if (err) throw err;

            //         // Add the image to the map style.
            //         map.addImage('pattern', image);

            //         // Create a new layer and style it using `fill-pattern`.
            //         map.addLayer({
            //             'id': 'pattern-layer',
            //             'type': 'fill',
            //             'source': 'pattern-source',
            //             'paint': {
            //                 'fill-pattern': 'pattern'
            //             }
            //         });
            //     }
            // );


            //描边
            map.addLayer({
                id: layer_id_stroke,
                type: "line",
                source: source_name,
                layout: {},
                paint: {
                    "line-color": strokeColor,
                    "line-width": 1
                }
            });

            this.hoveredStateId = null;
            //map.on('click', layer_id, this.mapMouseClickBlock);
            // map.on('mouseenter', layer_id, this.mapMouseEnterBlock);
            // map.on('mousemove', layer_id, this.mapMouseMoveBlock);
            // map.on('mouseleave', layer_id, this.mapMouseLeaveBlock);


        },
        fillBuildingsMap() {
            let _this = this;
            let params = this.buildParams();
            //params.color_category = 'struct';
            getStrokeGeoJson(params).then(res => {
                //加载轮廓
                let data = res;
                _this.strokegeoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
                _this.addStrokemapLayer(_this.map, 'stationstroke', _this.strokegeoJson);
                //    getGeoJson(params).then(res => {
                //     let data = res;
                //     let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
                //     this.addPointLayerToMap(this.map, geoJson); //消防站的点
                // });
            });

        },
        reloadData(type) {

            this.fill2Map(this.city_id);
        },
        setTipContent() {
            let _this = this;
            let html = ''
            getInfo({
                "module_id": this.mid,
            }).then(res => {
                document.getElementById('left-tip-content').innerHTML = res.data.info;
                this.types = res.data.types
            });
        },
        buildParams(type_id) {
            let params = { city_id: this.city_id };
            params.town_id = this.selects.town_id;
            if (this.selects.filter_street_id === 0) {
                //地图没有筛选街道,用select的街道
                params.street_id = this.selects.street_id;
            } else {
                //地图点击了街道
                params.street_id = this.selects.filter_street_id;
            }
            //params.street_id = this.selects.street_id;
            //params.module_id = this.mid;
            if (type_id) {
                params.type_id = type_id;
            } else {
                params.type_id = 1;
            }
            params.amount = this.cost_amount
            params.module_id = this.mid;

            Object.assign(params, {

            }, this.selects.ids)
            //Object.assign(params, this.selects.ids)

            return params;
        },
        changePage: function (value) {
            if (value != this.page) {
                this.loadData(this.selects.data_category, value);
            }
            this.page = value;
        },
        fillPointLayer() {
            let params = this.buildParams();
            getGeo2(params).then(res2 => {
                let data = res2;
                let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
                this.addFirestationPointLayerToMap(geoJson); //消防站的点
            });

            // getStreetGeo(params).then(data => {
            //   let geoJson = geobuf.decode(new Pbf(data)); // 对GeoBuf解码
            //   //this.addBlockmapLayer(this.map,'region')
            //   this.addStreetLayerToMap(this.map, geoJson);
            // });

        },
        makePointImage(map, planType) {
            //planType 1默认最优 红 2用户自定义 蓝
            let size = 180;


            //point type
            let pulsingDot = {
                width: size,
                height: size,
                data: new Uint8Array(size * size * 4),

                // get rendering context for the map canvas when layer is added to the map
                onAdd: function () {
                    var canvas = document.createElement("canvas");
                    canvas.width = this.width;
                    canvas.height = this.height;
                    this.context = canvas.getContext("2d");
                },

                // called once before every frame where the icon will be used
                render: function () {
                    var duration = 1000;
                    var t = (performance.now() % duration) / duration;

                    var radius = (size / 2) * 0.3;
                    var outerRadius = (size / 2) * 0.7 * t + radius;
                    var context = this.context;

                    // draw outer circle
                    context.clearRect(0, 0, this.width, this.height);
                    context.beginPath();
                    context.arc(
                        this.width / 2,
                        this.height / 2,
                        outerRadius,
                        0,
                        Math.PI * 2
                    );
                    if (planType == 1) {
                        context.fillStyle = "rgba(209, 90, 81," + (1 - t) + ")";
                    } else {
                        context.fillStyle = "rgba(130, 211, 248," + (1 - t) + ")";
                    }

                    //context.fillStyle = "rgba(255, 255, 255," + (1 - t) + ")";

                    context.fill();

                    // draw inner circle
                    context.beginPath();
                    context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
                    // switch(type){
                    //   case 1:
                    //     context.fillStyle = "rgba(255, 192, 0, 1)";
                    //   break;
                    //   case 2:
                    //     context.fillStyle = "rgba(237, 125, 49, 1)";
                    //   break;
                    //   case 3:
                    //     context.fillStyle = "rgba(255, 100, 100, 1)";
                    //   break;
                    // }
                    //context.fillStyle = "rgba(3, 116, 253, 1)";

                    if (planType == 1) {
                        context.fillStyle = "rgba(209, 90, 81, 1";
                    } else {
                        context.fillStyle = "rgba(130, 211, 248, 1";
                    }
                    //context.fillStyle = item.properties.color_type;

                    context.strokeStyle = "white";
                    context.lineWidth = 2 + 4 * (1 - t);
                    context.fill();
                    //context.stroke();
                    // update this image's data with data from the canvas
                    this.data = context.getImageData(0, 0, this.width, this.height).data;

                    // continuously repaint the map, resulting in the smooth animation of the dot
                    map.triggerRepaint();

                    // return `true` to let the map know that the image was updated
                    return true;
                }
            }
            map.addImage("dot-" + planType, pulsingDot, { pixelRatio: 2 });
        },
        makePointStationImage(map, type) {
            let size = 180;
            switch (type) {
                case 1:
                    size = 190;
                    break;
                case 2:
                    size = 150;
                    break;
                case 3:
                    size = 110;
                    break;
            }
            //point type
            let pulsingDot = {
                width: size,
                height: size,
                data: new Uint8Array(size * size * 4),

                // get rendering context for the map canvas when layer is added to the map
                onAdd: function () {
                    var canvas = document.createElement("canvas");
                    canvas.width = this.width;
                    canvas.height = this.height;
                    this.context = canvas.getContext("2d");
                },

                // called once before every frame where the icon will be used
                render: function () {
                    var duration = 1000;
                    var t = (performance.now() % duration) / duration;

                    var radius = (size / 2) * 0.3;
                    var outerRadius = (size / 2) * 0.7 * t + radius;
                    var context = this.context;

                    // draw outer circle
                    context.clearRect(0, 0, this.width, this.height);
                    context.beginPath();
                    context.arc(
                        this.width / 2,
                        this.height / 2,
                        outerRadius,
                        0,
                        Math.PI * 2
                    );
                    // switch(type){
                    //   case 1:
                    //     context.fillStyle = "rgba(255, 192, 0," + (1 - t) + ")";
                    //   break;
                    //   case 2:
                    //     context.fillStyle = "rgba(237, 125, 49," + (1 - t) + ")";
                    //   break;
                    //   case 3:
                    //     context.fillStyle = "rgba(255, 0, 0," + (1 - t) + ")";
                    //   break;
                    // }
                    context.fillStyle = "rgba(3, 116, 253," + (1 - t) + ")";

                    context.fill();

                    // draw inner circle
                    context.beginPath();
                    context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
                    // switch(type){
                    //   case 1:
                    //     context.fillStyle = "rgba(255, 192, 0, 1)";
                    //   break;
                    //   case 2:
                    //     context.fillStyle = "rgba(237, 125, 49, 1)";
                    //   break;
                    //   case 3:
                    //     context.fillStyle = "rgba(255, 100, 100, 1)";
                    //   break;
                    // }
                    //context.fillStyle = "rgba(3, 116, 253, 1)";
                    context.fillStyle = "rgba(3, 116, 253, 1)";

                    context.strokeStyle = "white";
                    context.lineWidth = 2 + 4 * (1 - t);
                    context.fill();
                    //context.stroke();
                    // update this image's data with data from the canvas
                    this.data = context.getImageData(0, 0, this.width, this.height).data;

                    // continuously repaint the map, resulting in the smooth animation of the dot
                    map.triggerRepaint();

                    // return `true` to let the map know that the image was updated
                    return true;
                }
            }
            map.addImage("dot-station-" + type, pulsingDot, { pixelRatio: 2 });
        },
        addFirestationPointLayerToMap(geojson) {
            //let type = this.selects.data_category
            let map = this.map;
            var layer = map.getLayer('points');
            if (layer) {
                map.removeLayer('points');
                map.removeSource('points');
            }

            if (typeof geojson.features == "undefined") return;
            //map.off('click', 'points', this.mapClickEvent);
            let imgIcon = [];
            if (!map.hasImage("dot-station-1")) {
                this.makePointStationImage(map, 1) //1000-2000
                this.makePointStationImage(map, 2) //2000-3000
                this.makePointStationImage(map, 3) //>3000

            }
            geojson.features.forEach((item, idx) => {
                if (item.properties.type == "一级站") {
                    item.properties.newtype = 1
                } else if (item.properties.type == "二级站") {
                    item.properties.newtype = 2
                } else if (item.properties.type == "小型站") {
                    item.properties.newtype = 3
                }
            })
            imgIcon = ["concat", "dot-station-", ["get", "newtype"]];



            map.addSource("points", {
                type: "geojson",
                data: geojson,
            }
            );
            map.addLayer({
                id: "points",
                type: "symbol",
                source: "points",
                paint: {
                    "text-color": "#000"
                },
                layout: {
                    "icon-image": imgIcon,
                    "icon-size": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        5, //zoom
                        0, //icon-size
                        10,
                        1
                    ],
                    "text-size": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        5, //zoom
                        0, //font-size
                        10,
                        12
                    ],
                    "text-field": ["get", "name"],
                    "text-offset": [0, -3],
                    "text-anchor": "top",
                    "icon-allow-overlap": true,
                    "text-allow-overlap": true
                }
            });
            //map.on('click', 'points', this.mapClickEvent);
            // map.on('mousemove', 'points', this.mapMouseEnter);
            // map.on('mouseleave', 'points', this.mapMouseLeave);
        },
        addPointLayerToMap(curGrids) {

            let map = this.map;
            var layer = map.getLayer('points' + this.planType);
            if (layer) {
                map.removeLayer('points' + this.planType);
                map.removeSource('points' + this.planType);
            }
            let geojson = { type: 'FeatureCollection', features: [] };
            curGrids.forEach((item, idx) => {
                let curGrid = item;
                curGrid.properties.type = this.planType
                geojson.features.push({
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [curGrid.properties.lng, curGrid.properties.lat],
                    },
                    properties: curGrid.properties
                })
            })


            let imgIcon = [];
            if (!map.hasImage("dot-" + this.planType)) {
                this.makePointImage(map, this.planType)
            }
            imgIcon = ["concat", "dot-", ["get", "type"]];

            map.addSource("points" + this.planType, {
                type: "geojson",
                data: geojson,
            }
            );
            map.addLayer({
                id: "points" + this.planType,
                type: "symbol",
                source: "points" + this.planType,
                paint: {
                    "text-color": "#ffffff"
                },
                layout: {
                    "icon-image": imgIcon,
                    "icon-size": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        5, //zoom
                        0, //icon-size
                        10,
                        1
                    ],
                    "icon-allow-overlap": true,
                }
            });
            map.off('mouseenter', 'points' + this.planType, this.mapMouseEnter);
            map.off('mouseleave', 'points' + this.planType, this.mapMouseLeave);
            map.on('mouseenter', 'points' + this.planType, this.mapMouseEnter);
            map.on('mouseleave', 'points' + this.planType, this.mapMouseLeave);
        },
        mapMouseEnter(e) {
            let _this = this;
            if (this.popup) {
                this.popup.remove()
            }
            this.popup = new mapboxgl.Popup({ closeOnClick: false, className: 'map-popup' })
                .setLngLat(e.features[0].geometry.coordinates)
                .setHTML((e.features[0].properties.type == 1 ? '最佳建设备选点：' : '当前建设备选点：') + '<br>' + e.features[0].properties.name)
                .addTo(this.map);


        },
        mapMouseLeave(e) {
            if (this.popup) {
                this.popup.remove()
            }
        },
        mapClickEvent(e, stationId) {
            //点击消防站
            let _this = this;
            if (!!e && e.features.length == 0) {
                return;
            }
            if (!stationId) {
                stationId = e.features[0].properties.id;
            }
            var layer = this.map.getLayer('staionstroke');
            if (layer) {
                this.map.removeLayer('staionstroke');
                this.map.removeSource('staionstroke');
            }
            if (this.stationId == stationId) {
                this.stationId = -1;
                return;
            }
            let newfeatures = this.strokegeoJson.features.filter((item, idx) => {
                return item.properties.id == stationId
            });
            this.stationId = stationId;
            let curStrokeGeoJson = {};
            Object.assign(curStrokeGeoJson, this.strokegeoJson);
            curStrokeGeoJson.features = newfeatures;


            this.map.addSource("staionstroke", {
                type: "geojson",
                data: curStrokeGeoJson,
            });
            this.map.addLayer({
                id: 'staionstroke',
                type: "line",
                source: 'staionstroke',
                layout: {
                    "line-join": "round",
                    "line-cap": "round"
                },
                paint: {
                    "line-color": "#ffffff",
                    "line-width": 3,
                    "line-dasharray": [2, 4]
                }
            });



        },
        mapClickEvent2(e) {
            let _this = this;
            if (e.features.length > 0) {
                if (_this.hoveredStateId !== null) {
                    _this.map.setFeatureState(
                        { source: _this.source_name, id: _this.hoveredStateId },
                        { hover: false }
                    );
                }
                if (e.features[0].id == _this.hoveredStateId) {
                    _this.hoveredStateId = null
                    _this.selects.filter_street_id = 0
                    _this.fillList1()
                    return
                };

                _this.hoveredStateId = e.features[0].id;
                _this.map.setFeatureState(
                    { source: _this.source_name, id: _this.hoveredStateId },
                    { hover: true }
                );
                //筛选列表
                _this.selects.filter_street_id = e.features[0].properties.street_id
                _this.fillList1()

            }
        },
        mapClickEventNew(feature, stationId) {
            //点击消防站
            let _this = this;

            if (!stationId) {
                stationId = feature.properties.id;
            }
            var layer = this.map.getLayer('staionstroke');
            if (layer) {
                this.map.removeLayer('staionstroke');
                this.map.removeSource('staionstroke');
            }
            if (this.stationId == stationId) {
                this.stationId = -1;
                return;
            }
            let newfeatures = this.strokegeoJson.features.filter((item, idx) => {
                return item.properties.id == stationId
            });
            this.stationId = stationId;
            let curStrokeGeoJson = {};
            Object.assign(curStrokeGeoJson, this.strokegeoJson);
            curStrokeGeoJson.features = newfeatures;


            this.map.addSource("staionstroke", {
                type: "geojson",
                data: curStrokeGeoJson,
            });
            this.map.addLayer({
                id: 'staionstroke',
                type: "line",
                source: 'staionstroke',
                layout: {
                    "line-join": "round",
                    "line-cap": "round"
                },
                paint: {
                    "line-color": "#ffffff",
                    "line-width": 3,
                    "line-dasharray": [2, 4]
                }
            });



        },
        mapClickEvent2New(feature) {
            let _this = this;
            if (_this.hoveredStateId !== null) {
                _this.map.setFeatureState(
                    { source: _this.source_name, id: _this.hoveredStateId },
                    { hover: false }
                );
            }
            if (feature.id == _this.hoveredStateId) {
                _this.hoveredStateId = null
                _this.selects.filter_street_id = 0
                _this.fillList1()
                return
            };

            _this.hoveredStateId = feature.id;
            _this.map.setFeatureState(
                { source: _this.source_name, id: _this.hoveredStateId },
                { hover: true }
            );
            //筛选列表
            _this.selects.filter_street_id = feature.properties.street_id
            _this.fillList1()

        },
        addStreetLayerToMap(map, geojson) {
            //let type = this.selects.data_category

            var layer = map.getLayer('streetpoints');
            if (layer) {
                map.removeLayer('streetpoints');
                map.removeSource('streetpoints');
            }

            if (typeof geojson.features == "undefined") return;


            map.addSource("streetpoints", {
                type: "geojson",
                data: geojson
            }
            );
            map.addLayer({
                id: "streetpoints",
                type: "symbol",
                source: "streetpoints",
                paint: {
                    "text-color": "#ffffff"
                },
                layout: {
                    //"icon-image": imgIcon,
                    "text-field": ["get", "name"],
                    "text-size": 12,
                    "text-offset": [0, -3],
                    "text-anchor": "top",
                    "icon-allow-overlap": true,
                    "text-allow-overlap": true
                }
            });
        },
        getMapLayerId(type) {
            let layer_id = type + '-layer';
            return layer_id;
        },
        getMapSourceName(type) {
            let source_name = type + "-data";
            return source_name;
        },
        removeMapLayer(map, type) {
            let source_name = this.getMapSourceName(type);
            let layer_id = this.getMapLayerId(type);
            if (type == 'streetpoints') {
                source_name = 'streetpoints';
                layer_id = 'streetpoints'
            }
            var layer = map.getLayer(layer_id);
            let layer_point_id = layer_id + "point";
            //描边
            var layer_stroke = map.getLayer(layer_id + '_stroke');
            if (layer_stroke) {
                map.removeLayer(layer_id + '_stroke');
            }
            if (layer) {
                map.removeLayer(layer_id);
            }
            if (map.getSource(source_name)) {
                map.removeSource(source_name);
            }
        },
        // changeRegin(type){
        //   //切换街道社区
        //   if(type == 'street'){
        //     //街道
        //     this.$router.push({
        //       path: '/fire/building/history/alarm'
        //     })
        //   } else if(type=='community') {
        //     //社区
        //     this.$router.push({
        //       path: '/fire/building/tp?mid='+this.mid
        //     })
        //   }
        // },
        changeType(val) {
            //切换左上角列表
            if (val == 1) {
                this.$router.push({ path: '/fire/building/decisionlayoutOptimization/3' })
            } else if (val == 2) {
                this.$router.push({ path: '/fire/building/decisionlayoutMoreOptimization/4' })
            } else if (val == 3) {
                this.$router.push({ path: '/fire/building/decisionlayoutFullCoverOptimization/5' })
            } else if (val == 4) {
                this.$router.push({ path: '/fire/building/decisionlayoutBalanceOptimization/6' })
            }
        },
        choosePlan(id) {
            let _this = this;
            this.planType = 1;
            if (!!id) {
                this.planId = id  //id是最优方案id
            }
            this.fillList1()
            let params = this.buildParams(this.planId);
            let curGrids = [];
            getMapData(params).then(res => {
                this.grid_ids = res.data.grid_ids;
                this.grid_count = res.data.grid_count;
                this.gridGeoJson.features.forEach((item, idx) => {
                    if (this.grid_ids.split(',').indexOf(item.properties.id + '') >= 0) {
                        curGrids.push(item)
                    }
                })
                //planType 1默认最优 2用户自定义
                this.updateChart()
                this.addPointLayerToMap(curGrids); //消防站的点
            })
        },
        stationNumChange() {
            let loadingInstance1 = Loading.service({ fullscreen: true })
            getDecisionLayoutOptimizationBalancesComputeAamount(this.buildParams()).then((res) => {
                loadingInstance1.close()
                this.choosePlan()
            })

            //清除自选的点
            //this.removePointLayer()
        },
        removePointLayer() {
            this.custom_grid_ids = [];
            let map = this.map;
            var layer = map.getLayer('points2');
            if (layer) {
                map.removeLayer('points2');
                map.removeSource('points2');
            }
        }


    },

};
</script>
<style lang="scss" >
.el-table__row td {
    border-right: 1px solid #eee;
}

.el-table__body-wrapper {
    background: #394761;
}

.options1 .el-cascader .el-input__inner {
    height: 50px;
}

/*.el-table__body-wrapper::-webkit-scrollbar{z-index:11;width:6px}
    .el-table__body-wrapper::-webkit-scrollbar:horizontal{height:6px}
    .el-table__body-wrapper::-webkit-scrollbar-thumb{border-radius:5px;width:6px;background:#b4bccc}
    .el-table__body-wrapper::-webkit-scrollbar-corner,.el-table__body-wrapper::-webkit-scrollbar-track{background:#fff}
    .el-table__body-wrapper::-webkit-scrollbar-track-piece{background:#fff;width:6px}*/
.el-loading-mask {
    background-color: rgba(0, 0, 0, 0.5) !important;

}

.el-loading-spinner .path {
    stroke: #409EFF !important;
}
</style>
<style lang="scss" scoped>
.card-map {
    min-height: 350px;
    height: calc(100vh - 48px);
    margin-bottom: 0;

    position: relative;

    .map-wrapper {
        width: 100%;
        height: calc(100vh - 100px);
        position: relative;

        &>* {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    #map {
        width: 100%;
        height: calc(100vh - 100px);
    }

    .radio-group {
        position: absolute;
        left: 10px;
        top: 10px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 5px;
        padding: 10px;
    }

    .radio-group div {
        padding: 0 10px;
    }

    .radio-group div input {
        vertical-align: -1px;
        padding-right: 2px;
    }

    .map_data_msg {
        position: absolute;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 5px;
        // color: #02f4fe;
        color: #fff;
        padding: 10px;
        width: auto;

        .map_data_legend {
            margin-bottom: 5px;
        }

        .map_data_msg_wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .item {
                // width: 80px;
                line-height: 15px;
                padding: 5px 0;
            }

            .item span {
                display: inline-block;
                width: 15px;
                height: 15px;
                border-radius: 3px;
                vertical-align: middle;
                margin-left: 0;
            }

            .item-circle span {
                display: inline-block;
                width: 14px;
                height: 14px;
                border-radius: 100%;
                vertical-align: middle;
                margin-left: 0;
                position: relative;
                margin-right: 7px;
            }

            .item-circle span:after {
                content: '';
                position: absolute;
                width: 24px;
                height: 24px;
                border-radius: 100%;
                border: 1px solid rgba(255, 255, 255, 0.5);
                left: -5px;
                top: -5px;
            }

            .map_data_msg_inner {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding-left: 10px;
            }
        }

        .map_data_msg_wrapper_vertical {
            flex-direction: column;

            .item {
                width: 130px;
            }
        }
    }
}

.main-panel {
    // height: calc(100vh - 130px);
    min-height: 350px;
}

.right-block {
    height: calc(100vh - 290px);
    min-height: 350px;
    overflow: hidden;
}

.tab-scroller {
    height: calc(100vh - 470px);
    min-height: 350px;
    overflow-y: auto;
}

.show_list {
    width: 100%;
    height: 50px;
    margin-top: 8px;
}

.show_list span {
    height: 32px;
    line-height: 36px;
    margin-left: 32px;
    font-size: 14px;
}

.show_list span i {
    height: 16px;
    padding: 2px 12px;
    background: #ffb236;
    margin: 0 4px;
    border-radius: 16px;
}

.show_list .up_show {
    display: block;
    float: left;
    font-size: 32px;
    cursor: pointer;
}

.show_list .download {
    display: block;
    float: right;
    font-size: 32px;
    cursor: pointer;
}

.footer {
    padding-top: 0;
}

.extended-forms .el-select {
    width: 100%;
    margin-bottom: 30px;
}

.extended-forms .progress {
    margin-bottom: 30px;
}

@import url("https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css");

.coordinates {
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    position: absolute;
    bottom: 10px;
    left: 10px;
    padding: 5px 10px;
    margin: 0;
    font-size: 11px;
    line-height: 18px;
    border-radius: 3px;
    display: none;
}

ul.nav__items>li:last-of-type a {
    margin-right: 0 !important;
}

.card-body .tab-content .tab-scroller {
    min-height: auto;
}

.chart-area {
    width: 100%;
}

.right-block .tab-pane {
    height: calc(100vh - 360px);
}

.el-table {
    position: absolute;
    bottom: 106px;
}

.tabCount2 .el-table {
    position: static;

}

/deep/.show_list_record .nav {
    width: 100%;
}



.opts {
    /*width: 300px;*/
    width: 200px;
    height: 200px;
    position: absolute;
    left: 14px;
    top: 400px;
    color: #fff;
    font-size: 12px;
    text-align: center;

    /*line-height: 30px;
      transform: scale(0.66);
      transform-origin: 0% 0%;*/
    .opt-title {
        background: rgba(64, 128, 133, 0.6);
        padding: 5px 0;
    }

    .opt-list {
        background: rgba(14, 58, 86, 0.6);
    }

    .opt-item {
        border-top: 1px solid transparent;
        border-bottom: 1px solid #333;
        padding: 5px 0;

        &.cur {
            border: 1px solid #fff;
        }
    }
}

.el-slider {
    width: 80%;
    margin: 0 auto;
}

.tooltip {
    /*width: auto;
      background: red;*/
}

.cost_amount {
    width: 150px;
    margin: 0 20px;
}
</style>
  